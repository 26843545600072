<template>
  <div>
    <header class="board-header">
      <div class="grid-header">
        <h1 v-if="title && showHeader" class="header__title">{{ title }}</h1>
      </div>
      <slot class="grid-aux" name="aux-section" />
      <FilterButton class="grid-filters" v-if="$slots.filters" />
    </header>

    <main class="main main-board">
      <slot name="main">
        <div v-cloak class="container-fluid">
          <slot name="applied-filters" />

          <div id="responsive-table" ref="responsive-table" class="responsive-table">
            <table class="board-table">
              <thead>
                <slot name="table-header" />
              </thead>

              <tbody>
                <slot name="table-rows">
                  <EmptyTable v-if="!loadingPage" />
                </slot>
                <LoadingTableFooter v-if="loadingPage" data-test="loader" />
              </tbody>
            </table>
          </div>
        </div>
      </slot>
    </main>

    <slot :listElement="$refs['responsive-table']" name="pagination" />

    <slot name="footer" />

    <slot name="filters" />
  </div>
</template>

<script>
import FilterButton from './FilterButton'
import EmptyTable from './EmptyTable'
import LoadingTableFooter from './LoadingTableFooter'
import { MICRO_FRONTEND_MODE } from '@/config'

export default {
  components: {
    FilterButton,
    EmptyTable,
    LoadingTableFooter
  },
  props: {
    loadingPage: {
      type: Boolean,
      default: true
    },
    title: { type: String, default: '' }
  },
  data() {
    return {
      showHeader: !MICRO_FRONTEND_MODE
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/partials/header';

.board-table {
  width: 100%;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 15px;
  font-size: 0.7rem;
  margin-bottom: 50px;
}

.board-header {
  background: $color-layout-white;
  border-bottom: 1px solid $color-neutral-lightest;
  align-items: center;
  position: sticky;
  width: 100%;
  z-index: 5;
  top: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr 80px;
  grid-template-rows: auto 1fr;
}

.grid-header {
  grid-area: 1 / 1 / 2 / 3;

  .header__title {
    width: 100%;
    border-bottom: 1px solid $color-neutral-lightest;
    margin: 0;
    padding: 16px;
  }
}

.grid-aux {
  grid-area: 2 / 1 / 3 / 2;
}

.grid-filters {
  grid-area: 2 / 2 / 3 / 3;
  box-sizing: border-box;
  min-height: $header-height;
}

.main-board {
  padding: 25px 0 2rem;
}
</style>




















import { defineComponent, ref, computed, onMounted, PropType } from '@vue/composition-api'

import useCurrentUser from '@/compositions/useCurrentUser'
import { CarrierSuggestionsPreferencesService } from './CarrierSuggestionsPreferencesService'

import CarrierSuggestionsPreferencesTable from './CarrierSuggestionsPreferencesTable.vue'
import Filters from './Filters.vue'
import { Order, PreferencesSuggestion } from '../types'

export default defineComponent({
  components: {
    CarrierSuggestionsPreferencesTable,
    Filters
  },
  props: {
    order: { type: Object as PropType<Order>, required: true },
    canSendOffers: { type: Boolean, default: true }
  },
  setup(props) {
    const { currentUserId } = useCurrentUser()

    const loading = ref(false)
    const suggestions = ref<PreferencesSuggestion[]>([])
    const filters = ref({
      myCarriers: false,
      hideCarriersWithCM: false
    })

    const loadCarrierSuggestions = async () => {
      suggestions.value = []

      if (props.order) {
        loading.value = true
        try {
          const service = new CarrierSuggestionsPreferencesService()
          const res = await service.search(props.order.id)
          suggestions.value = res
        } finally {
          loading.value = false
        }
      }
    }

    const filteredSuggestions = computed(() => {
      return suggestions.value.filter(order => {
        if (filters.value.myCarriers) {
          const contactOwnerId = order?.contact?.owner?.contactOwnerId

          return contactOwnerId === currentUserId.value
        } else if (filters.value.hideCarriersWithCM) {
          const contactOwnerId = order?.contact?.owner?.contactOwnerId
          const isContactOwnerActive = order?.contact?.isActive

          return !contactOwnerId || !isContactOwnerActive
        } else {
          return true
        }
      })
    })

    onMounted(loadCarrierSuggestions)

    return {
      currentUserId,
      loading,
      filters,
      filteredSuggestions
    }
  }
})



























import { i18n, setLanguage } from '@/plugins/i18n'
import useCurrentUser from '@/compositions/useCurrentUser'
import { defineComponent, computed } from '@vue/composition-api'
import { AVAILABLE_TRANSLATIONS } from '@/config'

export default defineComponent({
  setup() {
    const { changeCurrentUserLanguage } = useCurrentUser()

    const currentLanguage = computed(() => i18n.locale)

    const currentLanguageLabel = computed(
      () => `components/language-dropdown-field-languange-${i18n.locale}`
    )

    const langs = computed(() =>
      AVAILABLE_TRANSLATIONS.map(locale => ({
        label: `components/language-dropdown-field-languange-${locale}`,
        value: locale
      })).reverse()
    )

    const handleLanguageChange = language => {
      Promise.all([changeCurrentUserLanguage(language), setLanguage(language)]).then(() =>
        window.location.reload()
      )
    }

    return {
      currentLanguage,
      currentLanguageLabel,
      langs,
      handleLanguageChange
    }
  }
})

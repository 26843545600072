import { runJsonGet } from '@/models/backend-client'
import { SHIPMENT_ROUTING_API_URL, USER_MANAGEMENT_API_AUDIENCE } from '@/config'
const SHIPMENT_ROUTING_API_SCOPE = 'shipment-routes:read'
import logger from '@/shell/console-logger'
import { AxiosResponse } from 'axios'
import {
  ShipmentRoutes,
  ShipmentRoutesWithArray
} from '@/modules/shipment/types/shipment'

export const fetchShipmentRoutes = async (
  shipmentId: string
): Promise<ShipmentRoutesWithArray> => {
  const customHeaders = { 'x-client-section': 'com.sennder.ingestion' }

  try {
    const response = (await runJsonGet(
      `${SHIPMENT_ROUTING_API_URL}/api/shipment-routes`,
      { shipment_id: shipmentId },
      { apiGatewayAuthorization: true, customHeaders },
      USER_MANAGEMENT_API_AUDIENCE,
      SHIPMENT_ROUTING_API_SCOPE
    )) as AxiosResponse<ShipmentRoutes>

    return response?.data || { shipment_routes: [] }
  } catch (error) {
    logger.error('Error fetching shipment routes:', error)
    return { shipment_routes: [] }
  }
}

import { OPERATIONS_BACKEND_URL } from '@/config'
import Model from '@/models/model'
import usePersons from '@/compositions/usePersons'
const { personsData } = usePersons()
export default class Person extends Model {
  static get NAME() {
    return 'persons'
  }

  static get URL() {
    return `${OPERATIONS_BACKEND_URL}/accounts/persons/`
  }

  static getPerson(personId) {
    return personsData?.value?.find(p => p.id === personId)
  }

  static getPersonInitials(personId) {
    const person = Person.getPerson(personId)
    if (person) return person.initials
  }

  get fullName() {
    return `${this.first_name} ${this.last_name}`
  }
}

/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Model from './model'
import { OPERATIONS_BACKEND_URL } from '../config'

export default class CompanyAddress extends Model {
  static get NAME() {
    return 'company_addresses'
  }
  static get URL() {
    return `${OPERATIONS_BACKEND_URL}/ordering/company_addresses/`
  }

  get formatted() {
    return this.$state.addresses[this.address_id] != null
      ? this.$state.addresses[this.address_id].formatted
      : undefined
  }

  get address() {
    return this.$state.addresses[this.address_id]
  }

  get formatted_company_address() {
    if (this.description) {
      return `${this.formatted},  ${this.description}`
    } else {
      return this.formatted
    }
  }
}

import Hotjar from '@hotjar/browser'

import { HOTJAR_SITE_ORGID, MICRO_FRONTEND_MODE } from '@/config'
import { monitorError } from './monitoring'

const HOTJAR_VERSION = 6

interface HotjarUserData {
  id: string
  context?: Record<string, string>
}

export function initHotjar() {
  try {
    if (!HOTJAR_SITE_ORGID || MICRO_FRONTEND_MODE) return

    Hotjar.init(Number(HOTJAR_SITE_ORGID), HOTJAR_VERSION)
  } catch (error) {
    // We don't expect this to fail
    monitorError(error)
  }
}

export function identifyHotjarUser(userData: HotjarUserData) {
  try {
    if (!HOTJAR_SITE_ORGID || MICRO_FRONTEND_MODE) return

    const { id, context = {} } = userData

    Hotjar.identify(id, context)
  } catch (error) {
    // We don't expect this to fail
    monitorError(error)
  }
}

export function trackHotjarEvent(event: string) {
  try {
    if (!HOTJAR_SITE_ORGID || MICRO_FRONTEND_MODE) return

    Hotjar.event(event)
  } catch (error) {
    // We don't expect this to fail
    monitorError(error)
  }
}

import { OPERATIONS_BACKEND_URL, ASSIGNMENTS_API_URL } from '@/config'
import { runJsonPost, runJsonPut, runJsonGet } from '@/models/backend-client'
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase
} from '@/services/utils/casing'

export const fetchOrders = async (filters, offset, pageSize, transaction) => {
  const result = await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/order-query/search`,
    { ...filters, offset, pageSize },
    { transaction }
  )

  return transformKeysToCamelCase(result.data)
}

export const getCounts = async ({ query }) => {
  const result = await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/order-query/counters`,
    query
  )
  return transformKeysToCamelCase(result.data)
}

export const fetchTeamLeads = async () => {
  const result = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/pm-accountability/queries/all-teams`
  )
  return transformKeysToCamelCase(result.data)
}

export const assignAccountManagers = async (
  orderIds,
  seniorAccountManagerId,
  accountManagerId
) => {
  runJsonPut(
    `${OPERATIONS_BACKEND_URL}/ordering-account-operation/commands/assign-account-managers`,
    {
      order_ids: orderIds,
      senior_account_manager_id: seniorAccountManagerId,
      junior_account_manager_id: accountManagerId
    }
  )
}

export const assignTeamLeads = async (orderIds, teamId) => {
  return runJsonPost(`${OPERATIONS_BACKEND_URL}/pm-accountability/commands/assign-team`, {
    order_ids: orderIds,
    team_id: teamId
  })
}

export const fetchAssignedTeamLeads = async () => {
  const result = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/pm-accountability/queries/assigned-team-leads`
  )
  return transformKeysToCamelCase(result.data)
}

export const assignOrdersToCarrier = async ({
  shipmentIds,
  carrierId,
  contactId,
  validityHours,
  baseCost = null
}) => {
  const response = await runJsonPost(
    `${ASSIGNMENTS_API_URL}/api/assignments/assign-carrier`,
    transformKeysToSnakeCase({
      shipmentIds,
      carrierId,
      contactId,
      validityHours,
      baseCost,
      type: 'MANUAL'
    }),
    { apiGatewayAuthorization: true },
    'https://api.cloud.sennder.com/carrier-assign',
    'carrier_assignment:create'
  )

  return transformKeysToCamelCase(response.data).assignmentIds
}

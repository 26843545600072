/**
 * This file looks a bit insane now, but we *need* to reach env variables with static names
 * (i.e. process.env.ENV_NAME) instead of dynamics (i.e. process.env.[key]) because our current webpack config doesn't support dynamic keys.
 * At the same time, we have some Cypress tests which rely on env vars, so we need to try to get them when process.env is not available for Cypress.
 */
const readCypressVar = (key: string) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  globalThis.Cypress ? globalThis.Cypress.env(key) : null

export const OPERATIONS_BACKEND_URL =
  process.env.VUE_APP_OPERATIONS_BACKEND_URL ||
  readCypressVar('VUE_APP_OPERATIONS_BACKEND_URL')

export const AUTH0_MOTHERSHIP_AUDIENCE =
  process.env.VUE_APP_AUTH0_MOTHERSHIP_AUDIENCE ||
  readCypressVar('VUE_APP_AUTH0_MOTHERSHIP_AUDIENCE')

export const VUE_APP_MOCKLAUNCHDARKLY =
  process.env.VUE_APP_MOCKLAUNCHDARKLY || readCypressVar('VUE_APP_MOCKLAUNCHDARKLY')

export const MOCK_SERVICE_MOTHERSHIP_PORT =
  process.env.VUE_APP_MOCK_SERVICE_MOTHERSHIP_PORT ||
  readCypressVar('VUE_APP_MOCK_SERVICE_MOTHERSHIP_PORT')

export const ORCAS_URL =
  process.env.VUE_APP_ORCAS_URL || readCypressVar('VUE_APP_ORCAS_URL')

export const PENGUINS_API_URL =
  process.env.VUE_APP_PENGUINS_API_URL || readCypressVar('VUE_APP_PENGUINS_API_URL')

export const CONTRACT_MANAGEMENT_API_URL =
  process.env.VUE_APP_CONTRACT_MANAGEMENT_API_URL ||
  readCypressVar('VUE_APP_CONTRACT_MANAGEMENT_API_URL')

export const CARRIER_PROFILE_API_URL =
  process.env.VUE_APP_CARRIER_PROFILE_API_URL ||
  readCypressVar('VUE_APP_CARRIER_PROFILE_API_URL')

export const CARRIER_PROFILE_API_AUDIENCE =
  process.env.VUE_APP_CARRIER_PROFILE_API_AUDIENCE ||
  readCypressVar('VUE_APP_CARRIER_PROFILE_API_AUDIENCE')

export const CARRIER_FIN_DOCS_API_URL =
  process.env.VUE_APP_CARRIER_FIN_DOCS_API_URL ||
  readCypressVar('VUE_APP_CARRIER_FIN_DOCS_API_URL')

export const CHARTERING_OFFICE_API_URL =
  process.env.VUE_APP_CHARTERING_OFFICE_API_URL ||
  readCypressVar('VUE_APP_CHARTERING_OFFICE_API_URL')

export const CHARTERING_OFFICE_API_AUDIENCE =
  process.env.VUE_APP_CHARTERING_OFFICE_API_AUDIENCE ||
  readCypressVar('VUE_APP_CHARTERING_OFFICE_API_AUDIENCE')

export const SHIPMENT_API_URL =
  process.env.VUE_APP_SHIPMENT_API_URL || readCypressVar('VUE_APP_SHIPMENT_API_URL')

export const OPERATORS_API_URL =
  process.env.VUE_APP_OPERATORS_API_URL || readCypressVar('VUE_APP_OPERATORS_API_URL')

export const OPERATORS_API_AUDIENCE =
  process.env.VUE_APP_OPERATORS_API_AUDIENCE ||
  readCypressVar('VUE_APP_OPERATORS_API_AUDIENCE')

export const SHIPMENT_INTERNAL_COMM_API_URL =
  process.env.VUE_APP_SHIPMENT_INTERNAL_COMM_API_URL ||
  readCypressVar('VUE_APP_SHIPMENT_INTERNAL_COMM_API_URL')

export const SENNTEAMS_API_URL =
  process.env.VUE_APP_SENNTEAMS_API_URL || readCypressVar('VUE_APP_SENNTEAMS_API_URL')

export const ACCOUNTABILITY_API_URL =
  process.env.VUE_APP_ACCOUNTABILITY_API_URL ||
  readCypressVar('VUE_APP_ACCOUNTABILITY_API_URL')

export const SHIPMENT_ACTIVITY_COMMENTS_URL =
  process.env.VUE_APP_SHIPMENT_ACTIVITY_COMMENTS_URL ||
  readCypressVar('VUE_APP_SHIPMENT_ACTIVITY_COMMENTS_URL')

export const ACTIVITY_API_URL =
  process.env.VUE_APP_ACTIVITY_API_URL || readCypressVar('VUE_APP_ACTIVITY_API_URL')

export const TRANSIT_API_URL =
  process.env.VUE_APP_TRANSIT_API_URL || readCypressVar('VUE_APP_TRANSIT_API_URL')

export const SHIPPER_PROFILE_API_URL =
  process.env.VUE_APP_SHIPPER_PROFILE_API_URL ||
  readCypressVar('VUE_APP_SHIPPER_PROFILE_API_URL')

export const ASSIGNMENTS_API_URL =
  process.env.VUE_APP_ASSIGNMENTS_API_URL || readCypressVar('VUE_APP_ASSIGNMENTS_API_URL')

export const ASSIGNMENTS_API_AUDIENCE =
  process.env.VUE_APP_ASSIGNMENTS_API_AUDIENCE ||
  readCypressVar('VUE_APP_ASSIGNMENTS_API_AUDIENCE')

export const MATCHING_API_URL =
  process.env.VUE_APP_MATCHING_API_URL || readCypressVar('VUE_APP_MATCHING_API_URL')

export const FACILITY_PROFILE_API_URL =
  process.env.VUE_APP_FACILITY_PROFILE_API_URL ||
  readCypressVar('VUE_APP_FACILITY_PROFILE_API_URL')

export const FACILITY_PROFILE_API_AUDIENCE =
  process.env.VUE_APP_FACILITY_PROFILE_API_AUDIENCE ||
  readCypressVar('VUE_APP_FACILITY_PROFILE_API_AUDIENCE')

export const SHIPMENT_ROUTING_API_URL =
  process.env.VUE_APP_SHIPMENT_ROUTING_API_URL ||
  readCypressVar('VUE_APP_SHIPMENT_ROUTING_API_URL')

export const USER_MANAGEMENT_API_AUDIENCE =
  process.env.VUE_APP_AUTH0_USER_MANAGEMENT_AUDIENCE ||
  readCypressVar('VUE_APP_AUTH0_USER_MANAGEMENT_AUDIENCE')

export const MATCHING_API_AUDIENCE =
  process.env.VUE_APP_MATCHING_API_AUDIENCE ||
  readCypressVar('VUE_APP_MATCHING_API_AUDIENCE')

export const DESIGNATED_OPERATORS_API_URL =
  process.env.VUE_APP_DESIGNATED_OPERATORS_API_URL ||
  readCypressVar('VUE_APP_DESIGNATED_OPERATORS_API_URL')

export const WORKFLOW_INTERFACE_API_URL =
  process.env.VUE_APP_WORKFLOW_INTERFACE_API_URL ||
  readCypressVar('VUE_APP_WORKFLOW_INTERFACE_API_URL')

export const USER_MANAGEMENT_API_URL =
  process.env.VUE_APP_USER_MANAGEMENT_API_URL ||
  readCypressVar('VUE_APP_USER_MANAGEMENT_API_URL')

export const SEARCH_API_URL =
  process.env.VUE_APP_SEARCH_API_URL || readCypressVar('VUE_APP_SEARCH_API_URL')

export const ORDER_INGESTION_API_URL =
  process.env.VUE_APP_ORDER_INGESTION_API_URL ||
  readCypressVar('VUE_APP_ORDER_INGESTION_API_URL')

export const TRANSLATIONS_URL =
  process.env.VUE_APP_TRANSLATIONS_URL || readCypressVar('VUE_APP_TRANSLATIONS_URL')

export const ORDERS_MATCHING_URL = `${OPERATIONS_BACKEND_URL}/orders-matching`

export const TRANSPORT_REQUEST_ACTION_URL = `${ORDERS_MATCHING_URL}/transport_request_actions`

export const INFRASTRUCTURE_URL = `${OPERATIONS_BACKEND_URL}/infrastructure`

export const CARRIER_CONFIRMATION_EMAIL_API_URL =
  process.env.VUE_APP_CARRIER_CONFIRMATION_EMAIL_API_URL ||
  readCypressVar('VUE_APP_CARRIER_CONFIRMATION_EMAIL_API_URL')

export const EXCHANGE_RATES_API_URL =
  process.env.VUE_APP_EXCHANGE_RATES_API_URL ||
  readCypressVar('VUE_APP_EXCHANGE_RATES_API_URL')

export const USE_TRANSLATION_SERVICE =
  process.env.VUE_APP_USE_TRANSLATION_SERVICE ||
  readCypressVar('VUE_APP_USE_TRANSLATION_SERVICE')

export const AVAILABLE_TRANSLATIONS = ['en', 'de']

export const COST_CENTRE_ENABLED =
  (process.env.VUE_APP_COST_CENTRE_ENABLED ||
    readCypressVar('VUE_APP_COST_CENTRE_ENABLED')) === 'true'

export const DOCUMENT_UPLOAD_ENABLED =
  (process.env.VUE_APP_DOCUMENT_UPLOAD_ENABLED ||
    readCypressVar('VUE_APP_DOCUMENT_UPLOAD_ENABLED')) === 'true'

export const COST_CENTRE_URL =
  process.env.VUE_APP_COST_CENTRE_SERVICE_URL ||
  readCypressVar('VUE_APP_COST_CENTRE_SERVICE_URL')

export const SHIPPER_INVOICES_UPLOAD_ENABLED =
  (process.env.VUE_APP_SHIPPER_INVOICES_UPLOAD_ENABLED ||
    readCypressVar('VUE_APP_SHIPPER_INVOICES_UPLOAD_ENABLED')) === 'true'

export const FLEET_SERVICE_URL =
  process.env.VUE_APP_FLEET_SERVICE_URL || readCypressVar('VUE_APP_FLEET_SERVICE_URL')

export const POD_UPLOAD_SERVICE_URL =
  process.env.VUE_APP_POD_UPLOAD_SERVICE_URL ||
  readCypressVar('VUE_APP_POD_UPLOAD_SERVICE_URL')

export const OWN_CARRIER_ASSET_IDS = (
  process.env.VUE_APP_OWN_CARRIER_ASSET_IDS ||
  readCypressVar('VUE_APP_OWN_CARRIER_ASSET_IDS') ||
  ''
).split(',')

export const E2E_MODE = process.env.VUE_APP_E2E_MODE || readCypressVar('VUE_APP_E2E_MODE')

export const LAUNCH_DARKLY_MOCK_URL =
  process.env.VUE_APP_LAUNCH_DARKLY_MOCK_URL ||
  readCypressVar('VUE_APP_LAUNCH_DARKLY_MOCK_URL')

export const NODE_ENV = process.env.NODE_ENV || readCypressVar('NODE_ENV')

export const GOOGLE_MAPS_API_KEY =
  process.env.VUE_APP_GOOGLE_MAPS_API_KEY || readCypressVar('VUE_APP_GOOGLE_MAPS_API_KEY')

export const DATADOG_APP_ID =
  process.env.VUE_APP_DATADOG_RUM_APP_ID || readCypressVar('VUE_APP_DATADOG_RUM_APP_ID')

export const DATADOG_TOKEN =
  process.env.VUE_APP_DATADOG_TOKEN || readCypressVar('VUE_APP_DATADOG_TOKEN')

export const DATADOG_ENV =
  process.env.VUE_APP_DATADOG_ENV || readCypressVar('VUE_APP_DATADOG_ENV')

export const PERMISSIONS_SERVICE_URL =
  process.env.VUE_APP_PERMISSIONS_SERVICE_URL ||
  readCypressVar('VUE_APP_PERMISSIONS_SERVICE_URL')

export const ACCEPT_NOW_DISABLED_SHIPPER_IDS = (
  process.env.VUE_APP_ACCEPT_NOW_DISABLED_SHIPPER_IDS ||
  readCypressVar('VUE_APP_ACCEPT_NOW_DISABLED_SHIPPER_IDS') ||
  ''
)
  .split(',')
  .map(Number)

// TODO: move to OctopusPaymentsWidget
export const SETTLEMENT_API_URL =
  process.env.VUE_APP_SETTLEMENT_API_URL || readCypressVar('VUE_APP_SETTLEMENT_API_URL')

export const AMPLITUDE_API_KEY =
  process.env.VUE_APP_AMPLITUDE_API_KEY || readCypressVar('VUE_APP_AMPLITUDE_API_KEY')

export const HOTJAR_SITE_ORGID =
  process.env.VUE_APP_HOTJAR_SITE_ORGID || readCypressVar('VUE_APP_HOTJAR_SITE_ORGID')

export const MICRO_FRONTEND_MODE =
  process.env.MICRO_FRONTEND_MODE || readCypressVar('MICRO_FRONTEND_MODE')

export const CLAIMS_API_URL =
  process.env.VUE_APP_CLAIMS_API_URL || readCypressVar('VUE_APP_CLAIMS_API_URL')

export const SENN_TMS_URL =
  process.env.VUE_APP_SENN_TMS_URL || readCypressVar('VUE_APP_SENN_TMS_URL')

<template>
  <div class="paragraph">
    <div class="status-label">{{ $t('ordering/execution-transport-status-label') }}</div>
    <div :class="cssClass" class="transport-status subtitle" data-test="transport-status">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: { type: String, required: true },
    cssClass: { type: String, required: true }
  }
}
</script>

<style lang="scss" scoped>
.status-label {
  color: $color-neutral-main;
  margin-bottom: 4px;
}

.transport-status {
  &.on-the-way {
    color: $color-primary-main;
  }

  &.completed {
    color: $color-success-main;
  }

  &.cancelled {
    color: $color-error-main;
  }
}
</style>

<template>
  <BoardTableRow types="empty">
    <td colspan="100">
      <img src="@/assets/images/empty_state.svg" class="empty-image" />
      <h5 class="empty-text">{{ $t('boards/empty-table-message') }}</h5>
    </td>
  </BoardTableRow>
</template>

<script>
import BoardTableRow from './BoardTableRow'

export default {
  components: { BoardTableRow }
}
</script>

<style lang="scss" scoped>
.empty-image {
  max-width: 180px;
  display: inline-block;
}

.empty-text {
  color: $color-neutral-main;
}
</style>

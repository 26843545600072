<template>
  <div>
    <div class="plankton-grid">
      <TextInputFilter
        v-if="isUsed('referenceNumber') && $route.name !== 'es-execution-board'"
        filterName="referenceNumber"
        :title="$t('filters/reference-filters-customer-ref-field-title')"
        data-test="reference-number-filter"
        class="plankton-cell"
        span="12"
      />
      <TextInputFilter
        v-if="isUsed('transferCode') && $route.name !== 'es-execution-board'"
        filterName="transferCode"
        :title="$t('filters/reference-filters-transfer-code-field-title')"
        data-test="transfer-code-filter"
        class="plankton-cell"
        span="12"
      />

      <TextInputFilter
        v-if="isUsed('licensePlateNumber')"
        filterName="licensePlateNumber"
        :title="$t('filters/reference-filters-license-field-title')"
        :transformer="transformLicensePlate"
        data-test="license-plate-number-filter"
        class="plankton-cell"
        span="12"
      />
    </div>
    <div class="plankton-grid">
      <SennderReferenceFilter
        v-if="isUsed('idsForStaff') && $route.name !== 'es-execution-board'"
        filterName="idsForStaff"
        data-test="id-for-staff-filter"
        class="plankton-cell"
        span="24"
        :title="$t('filters/reference-filters-sennder-ref-field-title')"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import TextInputFilter from '../TextInputFilter/TextInputFilter'
import { cleanLicensePlate } from '@/modules/common/filters/license-plate'
import SennderReferenceFilter from './SennderReferenceFilter'

export default {
  components: { TextInputFilter, SennderReferenceFilter },

  props: {
    fields: {
      type: Array,
      default: () => [
        'referenceNumber',
        'transferCode',
        'idsForStaff',
        'licensePlateNumber'
      ]
    }
  },
  methods: {
    isUsed(filterName) {
      return this.fields.includes(filterName)
    },

    transformLicensePlate(licensePlate) {
      return cleanLicensePlate(licensePlate)
    }
  }
}
</script>

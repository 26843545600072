import { Ref, onUnmounted } from '@vue/composition-api'
import H from '@here/maps-api-for-javascript'
import debounce from 'lodash/debounce'

interface GeoPoint {
  lat: number
  lng: number
}

type MapParams = [
  H.map.layer.TileLayer | null,
  { zoom: number; center: GeoPoint; engineType?: H.Map.EngineType }
]

const doesBrowserSupportWebGL = (): boolean => {
  // Create canvas element. The canvas is not added to the
  // document itself, so it is never displayed in the
  // browser window.
  const canvas = document.createElement('canvas')

  // Get WebGLRenderingContext from canvas element.
  const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')

  return Boolean(gl && gl instanceof WebGLRenderingContext)
}

export default (
  mapContainer: Ref<HTMLElement>,
  center: GeoPoint
): {
  map: H.Map
  ui: H.ui.UI
  router: H.service.RoutingService | H.service.RoutingService8
} => {
  const platform = new H.service.Platform({
    apikey: process.env.VUE_APP_HEREMAPS_API_KEY || ''
  })

  // Obtain the default map types from the platform object
  const maptypes = platform.createDefaultLayers()

  const webGlMapParams: MapParams = [
    maptypes.vector.normal.map,
    {
      zoom: 10,
      center
    }
  ]

  const canvasMapParams: MapParams = [
    maptypes.raster.normal.map,
    {
      zoom: 10,
      center,
      engineType: H.Map.EngineType.P2D
    }
  ]

  const mapParams = doesBrowserSupportWebGL() ? webGlMapParams : canvasMapParams

  // Instantiate (and display) a map object:
  const map = new H.Map(mapContainer.value, ...mapParams)

  const router = platform.getRoutingService()

  const ui = H.ui.UI.createDefault(map, maptypes) as H.ui.UI

  const resizeMapDebounced = debounce(() => {
    map.getViewPort().resize()
  }, 100)

  const mapResizeObserver = new ResizeObserver(resizeMapDebounced)

  mapResizeObserver.observe(mapContainer.value)
  window.addEventListener('resize', resizeMapDebounced)

  onUnmounted(() => {
    mapResizeObserver.unobserve(mapContainer.value)
    window.removeEventListener('resize', resizeMapDebounced)
  })

  return { map, ui, router }
}

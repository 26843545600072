import _ from 'lodash'
import {
  formatDateWithTz,
  formatTimeWithTz,
  getTimezoneAcronym
} from '@/global-setup/filters'

const DONE = 'DONE'
const ESTIMATED = 'ESTIMATED'
const SCHEDULED = 'SCHEDULED'
const NOT_YET_DISPATCHED = 'NOT_YET_DISPATCHED'
const UNDEFINED_STATE = 'UNDEFINED'

// STEP TYPES:
export const PICKUP_ARRIVAL_TYPE = 'PICKUP_ARRIVAL'
export const PICKUP_DEPARTURE_TYPE = 'PICKUP_DEPARTURE'
export const DROPOFF_ARRIVAL_TYPE = 'DROPOFF_ARRIVAL'
export const DROPOFF_DEPARTURE_TYPE = 'DROPOFF_DEPARTURE'

class StepState {
  constructor(order) {
    this.order = order
  }

  get _state() {
    if (_.get(this.step, 'is_done')) return DONE
    if (_.get(this.step, 'estimated_time_formatted') && this.hasGeolocationTracker)
      return ESTIMATED
    if (this.step) return SCHEDULED
    if (this.unDispatchedDatetime) return NOT_YET_DISPATCHED
    return UNDEFINED_STATE
  }

  get done() {
    return this._state === DONE
  }

  get estimated() {
    return this._state === ESTIMATED
  }

  get scheduled() {
    return this._state === SCHEDULED
  }

  get notYetDispatched() {
    return this._state === NOT_YET_DISPATCHED
  }

  get notRegistered() {
    return this._state === UNDEFINED_STATE
  }

  get tooltip() {
    if (this.done) return 'Actual'
    if (this.estimated) return 'Estimated'
    if (this.scheduled) return 'Scheduled'
    if (this.notYetDispatched) return 'Scheduled'
    return ''
  }

  get icon() {
    if (this.done) return 'done_all'
    if (this.estimated) return 'update'
    if (this.scheduled) return 'event'
    if (this.notYetDispatched) return 'event'
    return ''
  }

  get hasGeolocationTracker() {
    return this.order.transferDetail.hasGeoTracker
  }
}

export class PickupArrivalStepState extends StepState {
  get step() {
    return _.get(this.order, 'transferDetail.pickup_arrival_step')
  }

  get delay() {
    return this.order.transferDetail.pickupArrivalDelay
  }

  get unDispatchedDatetime() {
    return _.get(this.order, 'pickupStartDateTime')
  }
}

export class PickupDepartureStepState extends StepState {
  get step() {
    return _.get(this.order, 'transferDetail.pickup_departure_step')
  }

  get delay() {
    return this.order.transferDetail.pickupDepartureDelay
  }

  get unDispatchedDatetime() {
    const isoTimestamp = this.order?.pickup_end ?? ''
    const timezone = this.order?.originAddress?.timezone ?? ''

    if (!isoTimestamp || !timezone) {
      return undefined
    }

    return formatStepDateTime(isoTimestamp, timezone)
  }
}

export class DropoffArrivalStepState extends StepState {
  get step() {
    return _.get(this.order, 'transferDetail.dropoff_arrival_step')
  }

  get delay() {
    return this.order.transferDetail.dropoffArrivalDelay
  }

  get unDispatchedDatetime() {
    const isoTimestamp = this.order?.delivery_start ?? ''
    const timezone = this.order?.destinationAddress?.timezone ?? ''

    if (!isoTimestamp || !timezone) {
      return undefined
    }

    return formatStepDateTime(isoTimestamp, timezone)
  }
}

export class DropoffDepartureStepState extends StepState {
  get step() {
    return _.get(this.order, 'transferDetail.dropoff_departure_step')
  }

  get delay() {
    return this.order.transferDetail.dropoffDepartureDelay
  }

  get unDispatchedDatetime() {
    return _.get(this.order, 'deliveryEndDateTime')
  }
}

export const stateFor = (type, order) => {
  if (type === DROPOFF_ARRIVAL_TYPE) return new DropoffArrivalStepState(order)
  if (type === PICKUP_DEPARTURE_TYPE) return new PickupDepartureStepState(order)
  if (type === PICKUP_ARRIVAL_TYPE) return new PickupArrivalStepState(order)
  if (type === DROPOFF_DEPARTURE_TYPE) return new DropoffDepartureStepState(order)
  throw new Error(type + ' is not a valid delay cell type')
}

export const validateDelayCellType = value => {
  return [
    DROPOFF_ARRIVAL_TYPE,
    PICKUP_DEPARTURE_TYPE,
    PICKUP_ARRIVAL_TYPE,
    DROPOFF_DEPARTURE_TYPE
  ].includes(value)
}

export const formatStepDateTime = (isoTimestamp, timezone) => {
  const timezoneAcronym = getTimezoneAcronym(isoTimestamp, timezone)
  const dotsDateTZ = formatDateWithTz(isoTimestamp, timezone)
  const dotsTimeTZ = formatTimeWithTz(isoTimestamp, timezone)

  return `${dotsDateTZ} ${dotsTimeTZ} ${timezoneAcronym}`
}

<template>
  <span
    v-tooltip.bottom="tooltipText"
    :data-test="dataTest"
    :class="{ ellipsis, block, bold, subtitle }"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    tooltipText: { type: String, default: null },
    dataTest: { type: String, default: null },
    ellipsis: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
    bold: { type: Boolean, default: false },
    subtitle: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
.ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // Do not set display: block for this class otherwise the tooltip's arrow position will be at the end of the text
  &::after {
    content: '';
    display: block;
  }
}

.block {
  display: block;
  min-width: 80px;
}

.bold {
  color: #4b4b4b;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.subtitle {
  color: #888;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
</style>

import { getTransitRampUpStatusByShipmentId } from '@/services/transit-service'
import { onMounted, onUnmounted, Ref, ref } from '@vue/composition-api'
import useTransit from '@/compositions/transit/useTransit'
import {
  polling,
  PollingCompletionReason,
  PollingResult
} from '@sennder/senn-node-toolkit'
import { TransitRampUpStatus } from '@/services'
import logger from '@/shell/console-logger'

interface TransitRampUpState {
  isLoading: boolean
  isError: boolean
  loadMF: boolean
}

/** SOT to check if MF for ExecutionStage should be loaded or not **/
export default (
  workflowManaged: boolean | undefined
): {
  transitRampUp: Ref<TransitRampUpState>
} => {
  const { getShipmentID } = useTransit()

  const transitRampUp = ref<TransitRampUpState>({
    isLoading: true,
    isError: false,
    loadMF: false
  })
  let stopPollingFn = () => {}

  const loadOnMount = async () => {
    if (!workflowManaged) {
      transitRampUp.value.isLoading = false
      return
    }

    const shipmentId = await getShipmentID()
    if (shipmentId === null) {
      transitRampUp.value.isLoading = false
      return
    }

    const checkRampUp: () => Promise<TransitRampUpStatus> = async () => {
      return await getTransitRampUpStatusByShipmentId(shipmentId)
    }

    const { stopPolling } = polling<TransitRampUpStatus>(checkRampUp, {
      timeout: 15000,
      stopCallback: (result: PollingResult<TransitRampUpStatus>) =>
        result.type == 'ok' && result.payload.isRampedUp !== null,
      onPollingCompleted: (
        results: PollingResult<TransitRampUpStatus>[],
        reason: PollingCompletionReason
      ): void => {
        pollingCompletionHandlers[reason](results)
        transitRampUp.value.isLoading = false
      }
    })

    stopPollingFn = stopPolling
  }

  const pollingCompletionHandlers = {
    error: (results: PollingResult<TransitRampUpStatus>[]): void => {
      logger.error(`[Transit] Polling failed after ${results.length} attempts`, results)
      transitRampUp.value.isError = true
    },
    timeout: (results: PollingResult<TransitRampUpStatus>[]): void => {
      logger.error(
        `[Transit] Polling stopped by timeout after ${results.length} attempts`,
        results
      )
      transitRampUp.value.isError = true
    },
    condition: (results: PollingResult<TransitRampUpStatus>[]): void => {
      const lastResult = results[results.length - 1].payload
      transitRampUp.value.loadMF = lastResult.isRampedUp
    },
    'stopped-by-user': () => {}
  }

  onMounted(loadOnMount)

  onUnmounted(() => {
    stopPollingFn()
  })

  return {
    transitRampUp
  }
}

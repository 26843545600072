import _ from 'lodash'
import Model from '@/models/model'
import { OPERATIONS_BACKEND_URL } from '@/config'

export default class GeolocationTracker extends Model {
  static get NAME() {
    return 'trackers'
  }
  static get URL() {
    return `${OPERATIONS_BACKEND_URL}/tracking/devices/`
  }

  get locations() {
    if (this.locations_ids != null) {
      return _.sortBy(
        Array.from(this.locations_ids).map(id => this.$state.locations[id]),
        'time'
      )
    } else {
      return []
    }
  }
}

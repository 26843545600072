export const BOARD_PAGINATION_LIMIT = 40

export const BOARD_FILTER_ORDER_STATES = [
  'ALL_STATES',
  'NEW',
  'REGISTERED',
  'CARRIER_LOCKED',
  'DISPATCHED',
  'EXECUTED',
  'OPERATIONS_COMPLETED',
  'COMPLETED'
]

import DatadogMonitor from '@sennder/senn-node-frontend-loggers/lib/datadog/DatadogMonitor'
import { DATADOG_APP_ID, DATADOG_ENV, DATADOG_TOKEN, MICRO_FRONTEND_MODE } from '@/config'
import consoleLogger from '@/shell/console-logger'
import { isProductionUrl, isUAT } from '@/controllers/environment-detection'

const getTracesMatch = () => {
  if (isProductionUrl()) {
    return 'https://api.cloud.sennder.com'
  }

  if (isUAT()) {
    return 'https://api.uat.cloud.sennder.com'
  }

  return 'https://api.dev.cloud.sennder.com'
}

const SITE = 'datadoghq.eu'
const SERVICE = 'octopus-shell'
/* eslint-disable no-console */
const consoleMonitor: Pick<DatadogMonitor, 'sendError' | 'setUser'> = {
  sendError(error, context) {
    consoleLogger.error(error, context)
  },
  setUser() {
    consoleLogger.debug('Setting user for Datadog RUM')
  }
}

let datadogMonitor = consoleMonitor

if (DATADOG_APP_ID && DATADOG_TOKEN && !MICRO_FRONTEND_MODE) {
  try {
    datadogMonitor = new DatadogMonitor(
      DATADOG_APP_ID,
      DATADOG_TOKEN,
      SERVICE,
      SITE,
      DATADOG_ENV,
      '1.0.0',
      {
        trackResources: true,
        trackLongTasks: true,
        traceSampleRate: 20,
        allowedTracingUrls: isProductionUrl()
          ? undefined // Disable prod tracing until we test it in dev and uat
          : [{ match: getTracesMatch(), propagatorTypes: ['datadog'] }]
      }
    )
  } catch (error) {
    consoleLogger.debug('Datadog init failed.', error)
  }
}

/**
 * @deprecated
 * DO NOT USE IT
 * This Datadog instance will be active only for some time to preserve Datadog support in octopus.sennder.it.
 */
export default datadogMonitor

import _ from 'lodash'

export const countryCodeByCountry = {
  Albania: 'AL',
  Austria: 'AT',
  Belarus: 'BY',
  Belgium: 'BE',
  Bulgaria: 'BG',
  Croatia: 'HR',
  Czechia: 'CZ',
  Denmark: 'DK',
  Estonia: 'EE',
  Finland: 'FI',
  France: 'FR',
  Germany: 'DE',
  Greece: 'GR',
  Hungary: 'HU',
  Iceland: 'IS',
  Ireland: 'IE',
  Italy: 'IT',
  Latvia: 'LV',
  Liechtenstein: 'LI',
  Lithuania: 'LT',
  'Luxembourg ': 'LU',
  Malta: 'MT',
  Moldova: 'MD',
  Monaco: 'MC',
  Netherlands: 'NL',
  Norway: 'NO',
  Poland: 'PL',
  Portugal: 'PT',
  Romania: 'RO',
  'Russian Federation': 'RU',
  Serbia: 'RS',
  Slovakia: 'SK',
  Slovenia: 'SI',
  Spain: 'ES',
  Sweden: 'SE',
  Switzerland: 'CH',
  Turkey: 'TR',
  Ukraine: 'UA',
  'United Kingdom': 'GB'
}
export const countryByCode = _.invert(countryCodeByCountry)
export const countryCodes = Object.values(countryCodeByCountry).sort()

export const buildCountrySelectOptions = () => {
  return Object.entries(countryCodeByCountry).map(entries => ({
    id: entries[1],
    label: entries[0]
  }))
}

export const getCountryOption = code => {
  const countryName = countryByCode[code]
  if (!countryName) return null
  return { id: code, label: countryName }
}

<template>
  <div class="arrow" :style="{ 'min-width': `${width - 10}px` }">
    <div class="above">
      <slot name="above" />
    </div>
    <div class="below">
      <slot name="below" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: { type: Number, default: 50 }
  }
}
</script>

<style lang="scss" scoped>
.arrow {
  display: flex;
  flex-direction: column;
  background: url('./arrow.svg') no-repeat;
  background-position-y: 50%;
  background-position-x: 100%;
  min-height: 8px;
  font-size: 12px;
  height: 100%;
  align-items: stretch;
}

.above {
  color: $color-neutral-main;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 50%;
  padding: 0;
  padding-bottom: 3px;
  margin: 0;
  line-height: 1em;
}

.below {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 50%;
  padding: 0;
  padding-top: 5px;
  line-height: 1em;
}
</style>

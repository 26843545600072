import { OPERATIONS_BACKEND_URL, E2E_MODE } from '@/config'
import { getCommonHeaders } from '@/services/auth-service'
import { prepareBackendErrorJsonMessageIfNeeded } from '@/global-setup/global-error-handler'
import axios from 'axios'
import qs from 'qs'
import packageInfo from '../../package.json'
import { getAuthHeader, SINGLE_AUDIENCE } from '@/services/auth-service'

const configGraphQLPost = async (config = {}, audience, scope) => {
  return {
    method: 'post',
    headers: {
      'Content-Type': 'application/graphql',
      Authorization: await getAuthHeader({
        audience,
        scope
      }),
      'X-Client-Name': packageInfo.name,
      'X-Client-Version': packageInfo.version
    },
    ...config
  }
}

const configJSON = async (config = {}, audience, scope) => {
  const additionnalHeaders = {}

  //E2E additional parameters
  if (E2E_MODE) {
    additionnalHeaders.user = 'accountmanager@sennder.com'
    additionnalHeaders.tenant = 'sennder'
  }

  let authorization
  if (audience === SINGLE_AUDIENCE) {
    const commonHeaders = await getCommonHeaders()
    authorization = commonHeaders.Authorization
    delete commonHeaders['Authorization']
    Object.assign(additionnalHeaders, commonHeaders)
  } else {
    authorization = await getAuthHeader({
      audience,
      scope
    })
  }

  return {
    headers: {
      'Content-Type': 'application/json',
      authorization,
      'X-Client-Name': packageInfo.name,
      'X-Client-Version': packageInfo.version,
      ...additionnalHeaders
    },
    ...config
  }
}

const configJsonWithApiGateway = async (
  config = {},
  audience,
  scope,
  customHeaders = {}
) => {
  const additionnalHeaders = {}

  //E2E additional parameters
  if (E2E_MODE) {
    additionnalHeaders.user = 'accountmanager@sennder.com'
    additionnalHeaders.tenant = 'sennder'
  }
  return {
    headers: {
      'Content-Type': 'application/json',
      'authorization-host': OPERATIONS_BACKEND_URL,
      authorization: await getAuthHeader({
        audience,
        scope
      }),
      ...additionnalHeaders,
      ...customHeaders
    },
    ...config
  }
}

const configCSVPost = async (config = {}, audience, scope) => {
  return {
    method: 'post',
    headers: {
      'Content-Type': 'text/csv',
      Authorization: await getAuthHeader({
        audience,
        scope
      }),
      'X-Client-Name': packageInfo.name,
      'X-Client-Version': packageInfo.version
    },
    ...config
  }
}

export async function runQuery(url, query, options = {}) {
  url = OPERATIONS_BACKEND_URL + '/graphql'
  const config = await configGraphQLPost()
  config.url = url
  config.data = query
  return run(config, options)
}

export async function runLogin(url, data) {
  const result = await runUnauthenticatedJsonPost(url, data)
  return result.data
}

export function runUnauthenticatedJsonPost(url, data) {
  return run({
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    url,
    data
  })
}

export async function runFileGet(url, query, options = {}, audience, scope) {
  if (query) url += '?' + qs.stringify(query, { arrayFormat: 'brackets' })
  const config = await configJSON(
    { method: 'get', responseType: 'blob', url },
    audience,
    scope
  )
  return run(config, options)
}

export async function runFilePost(url, data, options = {}, audience, scope) {
  const { headers } = options
  const config = await configJsonWithApiGateway(
    { method: 'post', responseType: 'blob', url, data },
    audience,
    scope,
    headers
  )
  return run(config, options)
}

export async function runJsonGet(url, query, options = {}, audience, scope) {
  const { apiGatewayAuthorization, customHeaders = {} } = options
  if (query) {
    url += '?' + qs.stringify(query, { arrayFormat: options.arrayFormat || 'brackets' })
  }
  const configParams = { method: 'get', url }
  const config = apiGatewayAuthorization
    ? await configJsonWithApiGateway(configParams, audience, scope, customHeaders)
    : await configJSON(configParams, audience, scope)
  return run(config, options)
}

export async function runJsonPost(url, data, options = {}, audience, scope) {
  const config = await configJSON({ method: 'post', url, data }, audience, scope)
  return run(config, options)
}

export async function runJsonPatch(url, data, options = {}, audience, scope) {
  const config = await configJSON({ method: 'patch', url, data }, audience, scope)
  return run(config, options)
}

export async function runJsonPut(url, data, options = {}, audience, scope) {
  const { apiGatewayAuthorization, customHeaders = {} } = options
  const configParams = { method: 'put', url, data }
  const config = apiGatewayAuthorization
    ? await configJsonWithApiGateway(configParams, audience, scope, customHeaders)
    : await configJSON(configParams, audience, scope)

  return run(config, options)
}

export async function runJsonDelete(url, data, options = {}, audience, scope) {
  const config = await configJSON({ method: 'delete', url, data }, audience, scope)
  return run(config, options)
}

export async function uploadCsv(url, formData, options = {}, audience, scope) {
  const config = await configCSVPost({ url, data: formData }, audience, scope)
  return run(config, options)
}

function run(config, { transaction } = {}) {
  if (transaction) {
    config.cancelToken = new axios.CancelToken(c =>
      transaction.registerServiceCall(config.url, config.method, c)
    )
  }

  //We globally intercept all network errors in initNetworkGlobalErrorHandling
  return axios(config).catch(error => {
    if (axios.isCancel(error)) return
    prepareBackendErrorJsonMessageIfNeeded(error)
    throw error
  })
}

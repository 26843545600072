export enum Currency {
  EUR = 'EUR',
  PLN = 'PLN'
}

export interface ICurrencyExchangeRate {
  base: Currency
  rates: {
    [key in Currency]: number
  }
  date: string
}

import { computed, Ref } from '@vue/composition-api'
import last from 'lodash/last'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { StepType } from '@/services/enums'
import { TransferStep } from '@/services'

interface StepTimeData {
  index: number
  timestamp: string
}

export default (steps: Ref<TransferStep[]>) => {
  const minutesTillScheduledStart = computed<number>(() => {
    if (!steps.value.length) return NaN
    const currentDatetime = new Date(Date.now()).toUTCString()
    const scheduledTime = moment(steps.value[0].scheduledTime)
    return scheduledTime.diff(currentDatetime, 'minutes')
  })

  const isFirstStepDone = computed<boolean>(() => steps.value[0]?.isDone)

  const arrivedToLastStop = computed<boolean>(
    () => steps.value[steps.value.length - 2]?.isDone
  )

  const isLastStepDone = computed<boolean>(() => last(steps.value)?.isDone)

  const firstStepScheduledTime = computed<string>(() =>
    steps.value ? steps.value[0]?.scheduledTime : ''
  )

  const lastStepScheduledTime = computed<string>(() => last(steps.value)?.scheduledTime)

  const willTransportStartLater = computed<boolean>(
    () => minutesTillScheduledStart.value > 120
  )

  const nextStep = computed<TransferStep>(() => steps.value.find(step => !step.isDone))

  const nextArrivalStep = computed<TransferStep>(() =>
    steps.value.find(step => !step.isDone && step.type === StepType.ARRIVAL)
  )

  const nextDepartureStep = computed<TransferStep>(() =>
    steps.value.find(step => !step.isDone && step.type === StepType.DEPARTURE)
  )

  const nextDepartureStepEstimatedTime = computed<string>(
    () => steps.value[steps.value.indexOf(nextArrivalStep.value) + 1].estimatedTime
  )

  const nextDepartureStepScheduledTime = computed<string>(
    () => steps.value[steps.value.indexOf(nextArrivalStep.value) + 1].scheduledTime
  )

  const isDepartureScheduledTimeInTheFuture = computed<boolean>(
    () =>
      nextDepartureStep.value && moment().isBefore(nextDepartureStep.value.scheduledTime)
  )

  const isArrivalEstimatedTimeInTheFuture = computed<boolean>(
    () => nextArrivalStep.value && moment().isBefore(nextArrivalStep.value.estimatedTime)
  )

  const currentStopIndex = computed<number>(() =>
    Math.floor(steps.value.findIndex(step => !step.isDone) / 2)
  )

  const stopsCompletion = computed<boolean[]>(() => {
    return steps.value
      .filter((_step, index) => index % 2 === 0)
      .map(step => step?.isDone && steps.value[steps.value.indexOf(step) + 1]?.isDone)
  })

  const setStepsCompletion = (
    markedStepAsDone: number,
    isDone: boolean,
    lastIsDoneIndex: number | null = null
  ): void => {
    const newSteps = cloneDeep(steps.value)
    let idx = 0
    if (!lastIsDoneIndex) idx = 0
    if (lastIsDoneIndex === 0) idx = lastIsDoneIndex
    if (lastIsDoneIndex > 0) idx = lastIsDoneIndex + 1
    for (let stepIndex = idx; stepIndex <= markedStepAsDone; stepIndex++) {
      newSteps[stepIndex].isDone = isDone
      newSteps[stepIndex].hasUnsavedStepDone = true
    }
    steps.value = newSteps
  }

  const findLastIndexMarkedAsCompleted = (): number | null => {
    let lastIndexIsDone = null
    steps.value.forEach((step, index) => {
      if (step.isDone === true) lastIndexIsDone = index
    })
    return lastIndexIsDone
  }

  const setStepActualTime = ({ index, timestamp }: StepTimeData): void => {
    const newSteps = cloneDeep(steps.value)
    newSteps[index].actualTime = timestamp
    newSteps[index].hasUnsavedActualTime = true
    steps.value = newSteps
  }

  return {
    isFirstStepDone,
    arrivedToLastStop,
    isLastStepDone,
    firstStepScheduledTime,
    lastStepScheduledTime,
    willTransportStartLater,
    nextStep,
    nextArrivalStep,
    nextDepartureStepScheduledTime,
    nextDepartureStepEstimatedTime,
    currentStopIndex,
    isArrivalEstimatedTimeInTheFuture,
    isDepartureScheduledTimeInTheFuture,
    stopsCompletion,
    setStepsCompletion,
    setStepActualTime,
    findLastIndexMarkedAsCompleted
  }
}

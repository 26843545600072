<template>
  <Dialog
    ref="confirmNewPriceModal"
    :show="isOpen"
    :lazy="true"
    :inPlace="true"
    :loading="inProgressRequest"
    v-bind="$attrs"
    :title="$t('ordering-share-header/confirm-new-price-modal-dialog-title')"
    :cancelButtonText="
      $t('ordering-share-header/confirm-new-price-modal-dialog-cancel-btn')
    "
    :confirmButtonText="
      $t('ordering-share-header/confirm-new-price-modal-dialog-save-btn')
    "
    class="dialog-wrapper"
    @close="close"
    @confirm="onConfirm"
    @cancel="close"
  >
    <p class="subtitle">
      {{ $t('ordering-share-header/confirm-new-price-modal-sub-title') }}
    </p>
    <div class="info-wrapper">
      <div class="title">{{ $t('confirm-new-price-modal-label-price') }}</div>
      <div class="old-value">{{ formatMoneyWithDashForNull(oldPrice) }}</div>
      <img src="@/assets/svgs/thin_arrow_right.svg" alt="---->" />
      <div class="new-value">{{ formatMoneyWithDashForNull(newPrice) }}</div>
    </div>
    <div class="info-wrapper">
      <div class="title">{{ $t('confirm-new-price-modal-label-cost') }}</div>
      <div class="old-value">{{ formatMoneyWithDashForNull(oldCost) }}</div>
      <img src="@/assets/svgs/thin_arrow_right.svg" alt="---->" />
      <div class="new-value">{{ formatMoneyWithDashForNull(newCost) }}</div>
    </div>
    <div class="info-wrapper">
      <div class="title">{{ $t('confirm-new-price-modal-label-distance') }}</div>
      <div class="old-value">
        {{ oldDistance }} {{ $t('confirm-new-price-modal-label-distance-unit') }}
      </div>
      <img src="@/assets/svgs/thin_arrow_right.svg" alt="---->" />
      <div class="new-value">
        {{ newDistance }}{{ $t('confirm-new-price-modal-label-distance-unit') }}
      </div>
    </div>
  </Dialog>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { formatMoneyWithDashForNull } from '@/global-setup/filters'

export default defineComponent({
  props: {
    newPriceCorrection: { type: Number, default: 0 },
    oldPrice: { type: Number, default: 0 },
    newCostCorrection: { type: Number, default: 0 },
    oldCost: { type: Number, default: 0 },
    oldDistance: { type: Number, default: 0 },
    newDistance: { type: Number, default: 0 },
    isOpen: { type: Boolean, default: false },
    close: { type: Function, required: true },
    confirm: { type: Function, required: true }
  },
  setup(props) {
    const inProgressRequest = ref(false)
    const newPrice = computed(() => {
      return props.oldPrice + props.newPriceCorrection
    })

    const newCost = computed(() => {
      return props.oldCost + props.newCostCorrection
    })

    const onConfirm = async () => {
      inProgressRequest.value = true
      await props.confirm()
      inProgressRequest.value = false
    }

    return {
      inProgressRequest,
      newPrice,
      newCost,
      onConfirm,
      formatMoneyWithDashForNull
    }
  }
})
</script>

<style scoped lang="scss">
.dialog-wrapper {
  &::v-deep .modal-dialog {
    width: 460px;
    margin-top: 150px;
  }

  &::v-deep .modal-content .modal-body {
    margin-top: 0;
    position: relative;
  }
}

.info-wrapper {
  margin: 8px auto;
  width: 360px;
  display: flex;
  padding: 16px;
  border: 1px solid $color-layout-divider;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
}

.title {
  width: 80px;
}

.old-value {
  text-align: right;
  width: 100px;
  padding-right: 16px;
}

.new-value {
  color: $color-secondary-main;
  text-align: right;
  width: 100px;
  padding-left: 16px;
}
</style>

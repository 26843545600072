import { mapShipmentV3DataToOrderStops } from '@/modules/shipment/helpers/shipmentEditHelpers'

const getters = {
  computedOrderFromShipmentV3(state, getters, rootState) {
    return mapShipmentV3DataToOrderStops(
      state.shipmentV3.shipment,
      rootState.ordering.order
    )
  }
}

export default getters

import { ref, Ref } from '@vue/composition-api'
import { fetchOrderPaymentInformation } from '@/modules/ordering/legacy/services/finance-service'
import { OrderPaymentInformation } from '@/modules/ordering/legacy/services/types'
import * as orderingService from '@/services/ordering-service'

const paymentInfo: Ref<OrderPaymentInformation> = ref(null)
export default (orderId: Ref<number>) => {
  const fetchPaymentInformation = async (): Promise<void> => {
    paymentInfo.value = await fetchOrderPaymentInformation(orderId.value)
  }

  const closeCustomerPrice = async (orderId: number) => {
    await orderingService.closeCustomerPrice(orderId)
    await fetchPaymentInformation()
  }

  const closeCarrierCost = async (orderId: number) => {
    await orderingService.closeCarrierCost(orderId)
    await fetchPaymentInformation()
  }

  return {
    paymentInfo,
    fetchPaymentInformation,
    closeCustomerPrice,
    closeCarrierCost
  }
}

<template>
  <Dialog
    :show="visible"
    :title="$t('finance-board/download-carrier-documents-modal-title')"
    data-test="download-carrier-documents-dialog"
    @close="close"
    inPlace
  >
    <div
      v-if="errorMessage"
      class="error-message"
      data-test="carrier-documents-error-message"
    >
      <AlertMessage type="error">{{ errorMessage }}</AlertMessage>
    </div>
    {{ $tc('finance-board/download-carrier-documents-modal-description', ordersAmount) }}.
    <template #footer>
      <Button
        category="tertiary"
        :text="$t('finance-board/download-carrier-documents-modal-cancel-label')"
        @click="close"
      />
      <Button
        :materialIcon="isFetching ? 'autorenew' : null"
        :isIconRotating="isFetching"
        :disabled="isDownloadButtonDisabled"
        :text="
          $t(
            isFetching
              ? 'finance-board/download-carrier-documents-modal-loading-label'
              : 'finance-board/download-carrier-documents-modal-confirm-label'
          )
        "
        category="primary"
        data-test="carrier-documents-download-button"
        @click="download"
      />
    </template>
  </Dialog>
</template>

<script>
import { downloadCarrierDocuments } from '@/services/document-generation-service'

export default {
  props: {
    orderIds: {
      type: Array,
      required: true,
      validator: orderIds =>
        Array.isArray(orderIds) && orderIds.every(orderId => typeof orderId === 'number')
    }
  },
  data: () => ({
    visible: false,
    isFetching: false,
    errorMessage: ''
  }),
  computed: {
    isDownloadButtonDisabled() {
      return Boolean(this.isFetching || this.errorMessage)
    },
    ordersAmount() {
      return this.orderIds.length
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    close() {
      this.visible = false
      this.errorMessage = ''
    },
    async download() {
      this.isFetching = true
      try {
        await downloadCarrierDocuments({
          order_ids: this.orderIds
        })
        this.close()
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error)
      } finally {
        this.isFetching = false
      }
    },
    getErrorMessage(error) {
      if (error.errorCode === 'NOT_APPROVED_PROOF_OF_DELIVERIES') {
        return this.$t(
          'finance-board/download-carrier-documents-modal-error-not-approved-pods',
          {
            orderIds: error.errorData.orderIds.join(', ')
          }
        )
      }
      if (error.errorCode === 'ORDERS_BELONG_TO_DIFFERENT_CUSTOMERS') {
        return this.$t(
          'finance-board/download-carrier-documents-modal-error-orders-from-different-shippers'
        )
      }
      if (error.errorCode === 'ORDER_NOT_FOUND') {
        return this.$t(
          'finance-board/download-carrier-documents-modal-error-order-not-found',
          {
            orderId: error.errorData.orderId
          }
        )
      }
    }
  }
}
</script>

<style scoped>
.error-message {
  margin-bottom: 15px;
}
</style>

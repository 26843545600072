export enum StopoverType {
  LOADING = 'LOADING',
  UNLOADING = 'UNLOADING'
}

export enum StepType {
  DEPARTURE = 'DEPARTURE',
  ARRIVAL = 'ARRIVAL'
}

export enum OperatorRole {
  SeniorAccountManager = 'SENIOR_ACCOUNT_MANAGER',
  AccountManager = 'ACCOUNT_MANAGER',
  SpotBidder = 'SPOT_BIDDER'
}

export enum ActionValidationErrorCodes {
  LATEST_LOADING_CARRIER_WAITING = 'latest_loading_carrier_waiting',
  LATEST_UNLOADING_REJECTED = 'latest_unloading_rejected',
  HAS_NO_LOADING_OR_UNLOADING = 'has_no_loading_or_unloading',
  HAS_NO_COLLECTED_ACTIONS = 'has_no_collected_actions',
  NO_SUCCESSFUL_PRECEEDING_LOADING = 'no_successful_preceeding_loading',
  CARRIER_WAITING_IN_THE_MIDDLE = 'carrier_waiting_in_the_middle',
  CARRIER_WAITING_ON_CLOSE_ATTEMPT = 'carrier_waiting_on_close_attempt',
  ALL_LOADINGS_REJECTED = 'all_loadings_rejected',
  ALL_UNLOADINGS_REJECTED = 'all_unloadings_rejected',
  ARRIVAL_TIMESTAMP_MISSING = 'arrival_timestamp_missing',
  DEPARTURE_TIMESTAMP_MISSING = 'departure_timestamp_missing'
}

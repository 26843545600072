<template>
  <div class="similar-lanes-tab">
    <Filters
      :myCarriers.sync="filters.myCarriers"
      :hideCarriersWithCM.sync="filters.hideCarriersWithCM"
      data-test="similar-lanes-filters"
    />

    <PriceHistoryChart
      v-if="!loading && priceHistory.length > 0"
      class="chart"
      :history="priceHistory"
      @selected="highlightSuggestionRow($event)"
      data-test="similar-lanes-chart"
    />
    <CarrierSuggestionsSimilarTable
      ref="suggestions"
      :orderId="order.id"
      :suggestions="filteredSuggestions"
      :canSendOffers="canSendOffers"
      :loading="loading"
      @selection-changed="$emit('selection-changed', $event)"
      data-test="similar-lanes-table"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import moment from 'moment'

import { Timestamp } from '@sennder/plankton'
import { DEFAULT_TIMEZONE } from '@/modules/ordering/constants'

import { fetchCarrierSuggestions } from '@/services/suggestions-service'
import { fetchCarriersKpi } from '@/services/ordering-service'
import useCurrentUser from '@/compositions/useCurrentUser'

import PriceHistoryChart from './PriceHistoryChart'
import CarrierSuggestionsSimilarTable from './CarrierSuggestionsSimilarTable.vue'
import Filters from './Filters.vue'

export default defineComponent({
  components: {
    PriceHistoryChart,
    CarrierSuggestionsSimilarTable,
    Filters
  },
  props: {
    order: { type: Object, required: true },
    stops: { type: Array, required: true },
    canSendOffers: { type: Boolean, default: true }
  },
  setup() {
    const { currentUserId } = useCurrentUser()
    return { currentUserId }
  },
  data() {
    return {
      loading: false,
      suggestions: [],
      filters: {
        myCarriers: false,
        hideCarriersWithCM: false
      }
    }
  },
  computed: {
    priceHistory() {
      return this.filteredSuggestions.map(suggestion => ({
        date: moment(
          suggestion.mostRecentTrip.date + ' ' + suggestion.mostRecentTrip.time,
          'DD.MM.YYYY HH:mm'
        ).format(),
        price: suggestion.baseCost
      }))
    },
    filteredSuggestions() {
      return this.suggestions.filter(order => {
        const owner = order?.carrierContactId

        if (this.filters.myCarriers) {
          return owner === this.currentUserId
        } else if (this.filters.hideCarriersWithCM) {
          return owner === null
        } else {
          return true
        }
      })
    }
  },
  async mounted() {
    await this.loadCarrierSuggestions()
  },
  methods: {
    async loadCarrierSuggestions() {
      this.suggestions = []
      if (this.order) {
        this.loading = true
        try {
          const suggestions = await fetchCarrierSuggestions(this.order.idForStaff)
          this.suggestions = Object.entries(suggestions).map(([, suggestion]) =>
            this.mapSuggestionForView(suggestion)
          )
          if (this.suggestions.length > 0) {
            await this.loadCarrierKPIs()
          }
        } finally {
          this.loading = false
        }
      }
    },
    mapSuggestionForView(suggestion) {
      const result = {
        ...suggestion,
        lines: [],
        mostRecentTrip: new Timestamp(suggestion.mostRecentTrip, DEFAULT_TIMEZONE)
      }

      const parseAndAddAddressLine = line => {
        if (line) {
          const address = line.split(',')
          result.lines.push({
            postal: address[0],
            city: address[1],
            country: address[2]
          })
        }
      }
      parseAndAddAddressLine(suggestion.lineOriginAddress)
      parseAndAddAddressLine(suggestion.lineDestinationAddress)

      return result
    },
    async loadCarrierKPIs() {
      const carrierIDs = this.suggestions.map(suggestion => suggestion.id)
      const kpis = await fetchCarriersKpi(
        this.order.id,
        carrierIDs,
        this.order.customerId,
        this.stops[0].warehouseAddress.companyAddressId,
        this.stops[this.stops.length - 1].warehouseAddress.companyAddressId
      )
      kpis.forEach(kpi => {
        const suggestion = this.suggestions.find(s => s.id === kpi.carrierId)
        if (suggestion) {
          suggestion.pickupPerformance = kpi.onTimePickupPercentage
          suggestion.deliveryPerformance = kpi.onTimeDeliveryPercentage
        }
      })
    },
    highlightSuggestionRow(index) {
      this.$refs.suggestions.highlightRow(index)
    }
  }
})
</script>

<style lang="scss" scoped>
.similar-lanes-tab {
  display: flex;
  flex-direction: column;
}

.filters {
  margin: 0 0 20px;
}
</style>

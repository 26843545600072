



























































import { sidebarIsVisible } from '@/modules/order-activity-sidebar'
import { computed, defineComponent } from '@vue/composition-api'
import AccountManagerTypeAheadField from '@/modules/common/components/AccountManagerTypeAheadField.vue'
import PartnerManagerTypeAheadField from './PartnerManagerTypeAheadField.vue'
import SpotBidderTypeAheadField from '@/modules/common/components/SpotBidderTypeAheadField.vue'
import useStore from '@/compositions/useStore'
import useCurrentUser from '@/compositions/useCurrentUser'
import { hasHadState } from '@/modules/common/order-states'
import { REGULARITY_TYPE } from '@/modules/common/order-states'
import { OrderOperator } from '@/services'
import { OrderState } from '@/modules/common/order-states'

import { PropType } from 'vue'
export default defineComponent({
  components: {
    AccountManagerTypeAheadField,
    PartnerManagerTypeAheadField,
    SpotBidderTypeAheadField
  },
  props: {
    accountManager: { type: Object as PropType<OrderOperator>, default: null },
    juniorAccountManager: { type: Object as PropType<OrderOperator>, default: null },
    partnerManager: { type: Object as PropType<OrderOperator>, default: null },
    spotBidder: { type: Object as PropType<OrderOperator>, default: null },
    accountManagerDisabled: { type: Boolean, default: false },
    partnerManagerDisabled: { type: Boolean, default: false },
    displaySpotBidderErrorMandatory: { type: Boolean, default: false },
    orderState: { type: String as PropType<OrderState>, default: null },
    orderRegularity: { type: String, default: null },
    // TODO temporary props to disable all inputs (for the Planning tab)
    isDisabledInputs: { type: Boolean, default: false }
  },
  setup(props) {
    const store = useStore()

    const { currentUserIsAccountManager, currentUserIsPartnerManager } = useCurrentUser()

    const assignAccountManagerToOrder = async (accountManager: {
      id: number
      firstName: string
      lastName: string
      fullName: string
      email: string
    }) => {
      if (accountManager) {
        await store.dispatch('ordering/assignAccountManagers', {
          accountManager: accountManager
        })
      }
    }
    const assignJuniorAccountManagerToOrder = async (juniorAccountManager: {
      id: number
      firstName: string
      lastName: string
      fullName: string
      email: string
    }) => {
      if (juniorAccountManager) {
        await store.dispatch('ordering/assignAccountManagers', {
          juniorAccountManager: juniorAccountManager
        })
      }
    }

    const assignSpotBidderToOrder = async (spotBidder: OrderOperator) => {
      if (spotBidder) {
        await store.dispatch('ordering/assignSpotBidder', {
          spotBidder
        })
      }
    }

    const assignPartnerManagerToOrder = async (partnerManager: OrderOperator) => {
      if (partnerManager) {
        await store.dispatch('ordering/assignPartnerManager', {
          partnerManager
        })
      }
    }

    const spotFieldIsLocked = computed(() => {
      return hasHadState(props.orderState, 'OPERATIONS_COMPLETED')
    })

    const partnerManagerFieldIsLocked = computed(() => {
      return (
        hasHadState(props.orderState, 'OPERATIONS_COMPLETED') ||
        hasHadState(props.orderState, 'EXECUTED')
      )
    })

    const shouldDisplaySpotBidderField = computed(() => {
      return (
        (props.orderRegularity === REGULARITY_TYPE.SCALED_SPOT ||
          props.orderRegularity === REGULARITY_TYPE.SPOT) &&
        (currentUserIsAccountManager.value || currentUserIsPartnerManager.value)
      )
    })
    return {
      sidebarIsVisible,
      assignAccountManagerToOrder,
      assignJuniorAccountManagerToOrder,
      assignSpotBidderToOrder,
      assignPartnerManagerToOrder,
      currentUserIsAccountManager,
      shouldDisplaySpotBidderField,
      spotFieldIsLocked,
      partnerManagerFieldIsLocked
    }
  }
})

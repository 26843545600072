import { runJsonGet } from '@/models/backend-client'
import { OPERATIONS_BACKEND_URL } from '@/config'
import { transformKeysToCamelCase } from './utils/casing'

export const orderCsvUploadStatus = async uploadId => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/order-csv-import/queries/csv-upload-status/${uploadId}`
  )
  return transformKeysToCamelCase(response.data)
}

export const checkUploadStatus = async (uploadId, url) => {
  const response = await runJsonGet(`${OPERATIONS_BACKEND_URL}${url}/${uploadId}`)
  return transformKeysToCamelCase(response.data)
}

import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'
import { createFilters } from '@/modules/filters'

import { currentWeekSaturday, lastSunday } from '@/controllers/formatted-relative-dates'

export default {
  namespaced: true,
  state: () => ({ orders: [] }),
  getters,
  mutations,
  actions,
  modules: {
    filters: createFilters({
      earliestPickup: lastSunday(),
      latestPickup: currentWeekSaturday(),
      orderStates: ['OPERATIONS_COMPLETED'],
      hasCarrierInvoices: false
    })
  }
}

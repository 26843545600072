<template>
  <form
    :class="{
      filters: true,
      paragraph: true,
      'filters--core': isMicrofrontend
    }"
    data-test="filters"
    @submit.prevent="applyFilters"
    :style="{
      top: topOffset + 'px',
      height: dynamicHeight + 'px'
    }"
  >
    <header class="filters__header">
      <h3 class="filters__title">{{ $t('filters/board-filters-title') }}</h3>
      <button
        type="button"
        class="filters__close-btn"
        data-test="collapse-filters"
        @click="collapseFiltersArea"
      >
        <i class="material-icons">close</i>
      </button>
    </header>
    <!--This slot is injected with the component declaring the filter content of each board-->
    <!--(FinanceFiltersContent, ExecutionFiltersContent...)-->
    <slot />

    <footer class="filters__footer">
      <Button
        :disabled="loadingPage"
        :loading="loadingPage"
        category="link"
        type="button"
        :text="$t('filters/board-filters-clear-filters-label')"
        materialIcon="close"
        data-test="clear-filters"
        @click="resetFilters"
      />
      <Button
        :disabled="loadingPage"
        :loading="loadingPage"
        category="primary"
        :text="$t('filters/board-filters-apply-filter-button-label')"
        type="submit"
        data-test="apply-filters"
      />
    </footer>
  </form>
</template>

<script>
import { mapMutations } from 'vuex'
import events from '../events'
import { trackEvent } from '@/analytics/amplitude'
import { monitorError } from '@/analytics/monitoring'
import { MICRO_FRONTEND_MODE } from '@/config'

export default {
  props: {
    loadingPage: { type: Boolean, required: true }
  },
  data() {
    return {
      topOffset: 0,
      dynamicHeight: null,
      headerObserver: null,
      isMicrofrontend: !!MICRO_FRONTEND_MODE
    }
  },
  watch: {
    '$route.path': {
      handler() {
        this.collapseFiltersArea()
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.collapseFiltersArea()
  },
  mounted() {
    if (MICRO_FRONTEND_MODE) {
      /**
       * [HACK ALERT]
       * subscribe to the sennTMS header and calculate sidebar width accordingly
       * we need it because the sidebar is positioned as fixed
       * other ways to fix it require some level of re-implementation of scrolling logic in the boards
       */
      try {
        const shellHeader =
          document.querySelector('dsf-header') ||
          document.querySelector('orchestrated-layout')

        this.headerObserver = this.createHeaderObserver()
        this.headerObserver?.observe(shellHeader)
      } catch (e) {
        monitorError(new Error('Not possible to detect header height'))
      }
    }
  },
  unmounted() {
    if (MICRO_FRONTEND_MODE) {
      try {
        const shellHeader =
          document.querySelector('dsf-header') ||
          document.querySelector('orchestrated-layout')

        this.headerObserver?.unobserve(shellHeader)
      } catch (e) {
        monitorError(new Error('Not possible to detect header height'))
      }
    }
  },
  methods: {
    ...mapMutations(['collapseFiltersArea']),
    applyFilters() {
      this.$emit('applyFilters')
      trackEvent(events.FILTERS_APPLY, {
        boardName: this.storeName,
        filtersType: 'boardFilters'
      })
    },
    resetFilters() {
      this.$emit('resetFilters')
      this.$emit('applyFilters')
      trackEvent(events.FILTERS_RESET, {
        boardName: this.storeName,
        filtersType: 'boardFilters'
      })
    },
    createHeaderObserver() {
      return new ResizeObserver(entries => {
        const headerHeight = entries?.[0]?.borderBoxSize?.[0]?.blockSize
        this.topOffset = headerHeight
        this.dynamicHeight = document.body.clientHeight - headerHeight
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/modules/filters/styles/filters';

.filters {
  height: 100vh;
  position: fixed;
  right: 0;
  width: $filters-width;
  display: flex;
  flex-direction: column;
  z-index: 10;
  overflow-y: auto;
  background: $color-layout-white;
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.15),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  transform: translateX(#{$filters-width});
  transition: 0.2s top linear, 0.2s bottom linear, 0.35s width ease, 0.35s transform ease;

  &--core {
    box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  }

  &-open & {
    transform: translateX(0);
  }

  &__header {
    padding: 16px;
    border-bottom: 1px solid $color-neutral-lightest;
    height: $header-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    margin: 0;
    color: $color-neutral-darkest;
  }

  &__close-btn {
    background: transparent;
    border: none;
    padding: 0;
    color: $color-neutral-lighter;
    height: 30px;
    width: 30px;
    transition: all 250ms ease;

    i {
      line-height: 30px;
    }

    &:hover {
      border-radius: 50%;
      background: $color-neutral-lightest;
    }
  }

  &__footer {
    height: $filter-footer-height;
    padding: 16px;
    border-top: 1px solid $color-neutral-lightest;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>

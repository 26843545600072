/*
 * simple class that represents a transaction. For each business request that needs to be
 * canceled in case of a new request, we create a transaction that provides a cancel method.
 * This transaction is passed down to the backend-client.js methods that execute the Axios request.
 * Before executing the request we register the cancel callback in the transaction object so
 * it can be canceled from the client calling the service.
 *
 * The main advantage is that we can register multiple requests under a single transaction,
 * which helps to implement cancel operations that involves multiple requests.
 *
 * The only drawback is that we have to manually pass the transaction object down
 * to the backend-client module. There is room for improvements in case we need
 * a more elaborated solution.
 * */
export default class ServiceTransaction {
  constructor() {
    this._serviceCalls = []
  }

  cancel() {
    this._serviceCalls.forEach(s =>
      s.cancelFn(`Request has been cancelled ${s.url} ${s.method}`)
    )
  }

  registerServiceCall(url, method, cancelFn) {
    this._serviceCalls.push({ url, method, cancelFn })
  }
}




































import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { TemperatureRange } from './types'
import { FormFieldValidators } from '@/modules/ordering/types'
import { i18n } from '@/plugins/i18n'

export default defineComponent({
  props: {
    value: { type: Object as PropType<TemperatureRange>, required: true },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    requiredErrorMessagePrefix: { type: String, default: '' }
  },
  setup(props, { emit }) {
    const lowestTemperatureValidators: FormFieldValidators<TemperatureRange> = computed(
      () => [
        {
          isInvalid: value => props.required && isEmpty(value),
          errorMessage: i18n.t(
            'ordering/common-temperature-range-field-lowest-temp-not-empty',
            { errorMessage: props.requiredErrorMessagePrefix }
          ),
          inline: false
        },
        {
          isInvalid: value => {
            if (isNil(value) || isNil(props.value.max)) return false
            return Number(value) > Number(props.value.max)
          },
          errorMessage: i18n.t(
            'ordering/common-temperature-range-field-lowest-temp-not-higher'
          ),
          inline: false
        }
      ]
    )

    const highestTemperatureValidators: FormFieldValidators<TemperatureRange> = computed(
      () => [
        {
          isInvalid: value => props.required && isEmpty(value),
          errorMessage: i18n.t(
            'ordering/common-temperature-range-field-highest-temp-not-empty',
            { errorMessage: props.requiredErrorMessagePrefix }
          ),
          inline: false
        }
      ]
    )

    const emitUpdate = (field: keyof TemperatureRange, newValue: TemperatureRange) => {
      if (props.value[field] !== Number(newValue))
        emit('input', { ...props.value, [field]: newValue })
    }

    return {
      lowestTemperatureValidators,
      highestTemperatureValidators,
      get,
      emitUpdate
    }
  }
})

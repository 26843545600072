import { createFilter } from './filter'

const emptyValue = false

/**
 * @typedef {boolean=} FilterValue
 * @typedef {import('./filter').UrlQueryFragment} UrlQueryFragment
 */

/**
 * @param {string} name - name for API/GraphQL key, URL and apply filter title
 * @param {FilterValue} initialValue
 * @param {string} updateName - name for getting/setting filter
 * @param {string} filterLabel - label for appliedFilters
 * @returns {any} - vuex module instance
 */
export const createBooleanFilter = (
  name,
  initialValue = emptyValue,
  updateName,
  filterLabel
) => {
  const filter = createFilter({ name, initialValue, emptyValue, updateName, filterLabel })

  filter.actions = {
    ...filter.actions,
    /**
     * @param {*} store
     * @param {UrlQueryFragment} urlQueryFragment
     */
    setValueFromUrl({ state, commit }, urlQueryFragment) {
      const urlValue = urlQueryFragment[state.urlParamName]
      if (urlValue) {
        const booleanValue = urlValue === 'true'
        commit('update', booleanValue)
      } else if (urlValue === null) commit('update', emptyValue)
    }
  }

  return filter
}

import {
  changeUserPassword,
  fetchPasswordResetTokenIsValid,
  postPasswordResetRequestById,
  postPasswordResetRequestByEmail
} from '@/modules/auth/services'
import User from '@/models/user'
import moment from 'moment-timezone'
import * as loginGoogleService from '@/services/login-google-service'
import * as userService from '@/services/user-service'
import { identifyAmplitudeUser } from '@/analytics/amplitude'
import { identifyHotjarUser } from '@/analytics/hotjar'

export const login = async (store, { email, password }) => {
  await User.login({ email, password })
  await fetchCurrentUser(store, true)
}

export const fetchCurrentUser = async (store, onLogin = false) => {
  if (store.getters.isLoggedIn) return // If we have the user already, don't fetch again

  const currentUser = await User.me()

  if (!currentUser?.id) return // handling /accounts/users/me 401 error

  // User id is a numeric value
  identifyAmplitudeUser({ userId: currentUser.id.toString(), ...currentUser }, onLogin)

  identifyHotjarUser({
    id: currentUser.id.toString()
  })

  moment.tz.setDefault(currentUser.timezone)

  store.commit('updateCurrentUser', currentUser)
  if (store.getters.currentUserIsStaff) {
    const level = await userService.fetchUserLevel()
    store.commit('updateCurrentUserLevel', level)
  }
}

export const logout = async () => {
  try {
    await loginGoogleService.logout()
  } finally {
    await User.logout()
  }
}

export async function resetUserPassword(
  store,
  { uid, token, newPassword, confirmedPassword }
) {
  await changeUserPassword({ uid, token, newPassword, confirmedPassword })
}

export const sendForgotPasswordLinkEmail = async (store, email) => {
  await postPasswordResetRequestByEmail(email)
}

export const requestPasswordResetLinkById = async (store, userId) => {
  await postPasswordResetRequestById(userId)
}

export const isPasswordResetLinkValid = async (store, { personId, token }) => {
  return await fetchPasswordResetTokenIsValid(personId, token)
}

export const loginWithGoogle = async store => {
  const token = await loginGoogleService.getAuthToken()
  if (!token) return
  await User.loginWithGoogle(token)
  await fetchCurrentUser(store)
}

export const navigateToGoogleSignin = async (store, { callbackUri }) => {
  loginGoogleService.loginInGoogleWebsite(callbackUri)
}

import { OPERATIONS_BACKEND_URL } from '../config'
import Model from './model'

export default class OrderAssignment extends Model {
  static get NAME() {
    return 'order_assignments'
  }
  static get URL() {
    return `${OPERATIONS_BACKEND_URL}/ordering/order_assignments/`
  }

  get carrier() {
    return this.$state.carriers[this.carrier_id]
  }
}

import { computed, ComputedRef, Ref } from '@vue/composition-api'
import uniq from 'lodash/uniq'
import invert from 'lodash/invert'
import getAlphabetEquivalent from '@/compositions/transfer/useStopLetter'
import { TransferStep, TransferStopover, OrderStop } from '@/services'

interface StopLettersComposition {
  multipleStopLetters: Ref<string[]>
  addressIdsLettersMap: Ref<{ [key: number]: string }>
  warehouseIdsOptions: Ref<{ id: number; letter: string }[]>
}

type OrderData = (TransferStopover | TransferStep | OrderStop)[]

export default (orderData: Ref<OrderData>): StopLettersComposition => {
  const isStepArray = (transferData: OrderData): transferData is TransferStep[] => {
    return (transferData as TransferStep[])[0]?.scheduledTime !== undefined
  }

  const stopsOrSteps = computed(() =>
    isStepArray(orderData.value)
      ? orderData.value.filter((_item: TransferStep, index: number) => index % 2 === 0)
      : orderData.value
  )

  const filterWarehouseData = (propKey: string): number[] =>
    stopsOrSteps.value
      .filter(stopOrStep => Number.isInteger(stopOrStep[propKey])) // remove broken steps
      .map(stopOrStep => stopOrStep[propKey])

  const warehouseIndices: ComputedRef<number[]> = computed(() =>
    filterWarehouseData('warehouseIndex')
  )
  const warehouseAddressIds: ComputedRef<number[]> = computed(() =>
    filterWarehouseData('warehouseAddressId')
  )

  const multipleStopLetters = computed(() => {
    const indicesHashMap = invert({ ...uniq(warehouseIndices.value) })
    return warehouseIndices.value.map((id: number) =>
      getAlphabetEquivalent(indicesHashMap[id])
    )
  })

  const warehouseIdsOptions = computed(() => {
    return warehouseAddressIds.value.map((addressId: number, index) => ({
      id: addressId,
      letter: getAlphabetEquivalent(warehouseIndices.value[index])
    }))
  })

  const addressIdsLettersMap = computed(() => {
    return warehouseIdsOptions.value.reduce(
      (acc, val) => ({ [val.id]: val.letter, ...acc }),
      {}
    )
  })

  return { multipleStopLetters, addressIdsLettersMap, warehouseIdsOptions }
}

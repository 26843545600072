import { reactive, watch, computed } from '@vue/composition-api'
import { lazyRef } from '@/composables'

import {
  queryPalletExchangeAddresses,
  queryPalletExchangeConfiguration,
  updatePalletExchangeConfiguration
} from '@/services/pallet-exchange-service'

import {
  PALLET_EXCHANGE_TYPES,
  PALLET_EXCHANGE_NAMES
} from '@/modules/ordering/constants'

export const usePalletExchangeAddresses = lazyRef([], queryPalletExchangeAddresses)

/** Pallet exchange configuration singleton */
const config = reactive({
  /** @type {String?} */
  type: null,
  /** @type {Number?} */
  addressId: null
})

/**
 * Reset entire configuration to initial values
 */
const reset = () => {
  config.type = null
  config.addressId = null
}

/**
 * Load configuration for the given orderId
 *
 * @param orderId {Number} order id
 */
const load = async orderId => {
  if (orderId) {
    try {
      const { palletExchangeType, address } = await queryPalletExchangeConfiguration(
        orderId
      )
      config.type = palletExchangeType
      config.addressId = address
    } catch (e) {
      reset()
      throw e
    }
  } else {
    reset()
  }
}

/**
 * Save current configuration for the given orderId
 *
 * @param orderId {Number} order id
 */
const save = async orderId => {
  await updatePalletExchangeConfiguration(
    orderId,
    config.type,
    config.type === PALLET_EXCHANGE_TYPES.DPL_STANDORT ? config.addressId : null
  )
}

/**
 * Connects the given order object with configuration so that when the order changes
 * the configuration is automatically reloaded. The reason the whole object is expected
 * here is that this is the thing that acutally changes as opposed to orderId which may
 * or may not change when the order is being (re)loaded.
 *
 * Please note that the dependant order object needs to be provided via a method
 * so that the reactivity is retained. Props object given to setup method is
 * a reactive instance.
 *
 * Example usage:
 *
 * ```
 *   setup(props) {
 *     usePalletExchangeConfiguration()
 *       .connectConfigWithOrder(() => props.order)
 *   }
 * ```
 *
 * @param {Function} order function returning the order id
 */
const connectConfigWithOrder = orderId => {
  watch(
    orderId,
    () => {
      load(orderId())
    },
    { immediate: true }
  )
}

/**
 * Boolean that determines if the given config.type requires an address
 */
const isTypeWithAddress = computed(
  () => config.type === PALLET_EXCHANGE_TYPES.DPL_STANDORT
)

/**
 * String representation of config.type
 */
const typeStr = computed(() => PALLET_EXCHANGE_NAMES[config.type])

/**
 * String representation of config.addressId
 */
const addressStr = computed(() => {
  const addresses = usePalletExchangeAddresses()
  if (isTypeWithAddress.value) {
    const compareAddress = addr => addr.id == config.addressId
    return addresses.value.find(compareAddress)?.formattedAddress
  } else {
    return null
  }
})

export const usePalletExchangeConfiguration = () => {
  return {
    config,
    isTypeWithAddress,
    typeStr,
    addressStr,
    reset,
    load,
    save,
    connectConfigWithOrder
  }
}

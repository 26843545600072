import { getSorted } from '@/modules/boards'

export const sortedOrders = state => getSorted(state.orders, ['id'])

export const selectedOrders = (state, getters) =>
  getters.sortedOrders.filter(order => order.selected)

export const selectedOrdersIds = (state, getters) =>
  getters.selectedOrders.map(order => order.id)

export const selectedForStaffIds = (state, getters) =>
  getters.selectedOrders.map(order => order.id_for_staff)

export const selectedOrdersCount = (state, getters) => getters.selectedOrders.length

export const totalOrdersCount = state => state.orders.length

export const selectedTotalCustomerNet = (state, getters) =>
  getters.selectedOrders.reduce((sum, order) => sum + order.total_net_price, 0)

export const selectedTotalCarrierNet = (state, getters) =>
  getters.selectedOrders.reduce((sum, order) => sum + order.total_net_cost, 0)

import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'
import { IShipmentDraftPayloadData, IShipment } from '../types/shipment-draft'

export const shipmentDraftPayloadFactory: (
  params: IShipmentDraftPayloadData
) => IShipment = params => {
  const { regularity, ...rest } = params
  const finalRegularity = regularity || 'REGULAR'

  return {
    loads: [
      {
        load_requirements: {
          tracking_requirement: {
            tracking_id_for_shipper: rest.tracking_id_for_shipper
          }
        }
      }
    ],
    financials: {
      surcharges: [],
      note: ''
    },
    shipper: {
      shipper_id: rest.shipper_id,
      contact_id: rest.contact_id,
      contract: {
        type: finalRegularity
      }
    },
    reference: rest.reference,
    shipment_meta: {},
    shipment_requirements: {
      arrival_notification: false,
      direct_delivery: false
    },
    id: rest.id
  }
}

export const updatedShipmentFactory: (
  shipment: IShipment,
  params: IShipmentDraftPayloadData
) => IShipment = (shipment, params) => {
  const updatedLoads = cloneDeep(shipment.loads).map(load => {
    const updatedLoadingRequirements = load.load_requirements.loading_requirements.map(
      req => omit(cloneDeep(req), 'id')
    )

    const updatedUnloadingRequirements =
      load.load_requirements.unloading_requirements.map(req => omit(cloneDeep(req), 'id'))

    return {
      ...load,
      load_requirements: {
        ...load.load_requirements,
        loading_requirements: updatedLoadingRequirements,
        unloading_requirements: updatedUnloadingRequirements,
        tracking_requirement: {
          ...load.load_requirements.tracking_requirement,
          tracking_id_for_shipper: params.tracking_id_for_shipper || undefined
        }
      }
    }
  })

  return {
    ...shipment,
    loads: updatedLoads,
    reference: params.reference || undefined,
    shipper: {
      shipper_id: params.shipper_id || undefined,
      contact_id: params.contact_id || undefined,
      contract: {
        type: params.regularity
      }
    }
  }
}

import moment from 'moment-timezone'
import { formatUtcByTimezone } from '@/modules/shipment/utils/date-formats'
import { fetchFacilityProfile } from '@/services/facility-profile-service'
import { useShipment } from '@/modules/shipment/compositions/useShipment'
import { store } from '@/models/store'

const LOADING_TYPE = 'LOADING'
const UNLOADING_TYPE = 'UNLOADING'
const KG_TO_TONNE_CONVERSION_FACTOR = 0.001
const METRE_TO_CM_CONVERSION_FACTOR = 100
const TONNE_FIXED_POINT_FORMAT = 4

let warehouseIndexCounter = {}
let indexCounter = 0
let warehouseIndex = 1

const stopIsOvernightLoad = (startDate, endDate, timeZone) => {
  const dateFormat = 'YYYY-MM-DD'

  const arrivalToWarehouseDate = moment.tz(startDate, timeZone).format(dateFormat)
  const departureFromWarehouseDate = moment.tz(endDate, timeZone).format(dateFormat)

  return moment(departureFromWarehouseDate).isAfter(arrivalToWarehouseDate)
}

const enrichFacilityProfile = async facilityProfileId => {
  try {
    const facilityProfileResponse = await fetchFacilityProfile(facilityProfileId)
    const { profile } = facilityProfileResponse
    const { address } = profile.location

    const warehouseAddress = {
      profileName: profile.name,
      description: address.WH_code || '',
      fullAddress: address.formatted,
      id: address.id,
      timezone: profile.location.time_zone,
      zipCode: address.postal_code,
      countryCode: address.country_code,
      city: address.locality,
      route: address.street,
      streetNumber: address.street_number,
      latitude: address.coordinates.latitude,
      longitude: address.coordinates.longitude
    }
    return warehouseAddress
  } catch (error) {
    /* eslint-disable no-console */
    console.error('Error fetching facility profile:', error)
    return {}
  }
}

const enrichWarehouseAddress = async warehouseAddressId => {
  try {
    const warehouseAddressResponse = await useShipment().fetchWarehouseDetailsById(
      warehouseAddressId
    )
    const { address } = warehouseAddressResponse

    const warehouseAddress = {
      profileName: warehouseAddressResponse.company,
      companyAddressId: warehouseAddressResponse.warehouse_address_id,
      description: warehouseAddressResponse.description,
      fullAddress: `${address.formatted}, ${warehouseAddressResponse.description}`,
      id: address.id,
      timezone: address.timezone,
      zipCode: address.postal_code,
      countryCode: address.country_code,
      city: address.locality,
      route: address.route,
      streetNumber: address.street_number,
      latitude: address.latitude,
      longitude: address.longitude
    }
    return warehouseAddress
  } catch (error) {
    /* eslint-disable no-console */
    console.error('Error fetching warehouse address details:', error)
    return {}
  }
}

const processRequirements = async (requirements, stopType) => {
  let warehouseAddress = null
  let currentFacilityProfileId = null

  return Promise.all(
    requirements.map(async requirement => {
      if (requirement?.facility_profile_id) {
        currentFacilityProfileId = requirement.facility_profile_id
        warehouseAddress = await enrichFacilityProfile(currentFacilityProfileId)
      } else {
        currentFacilityProfileId = requirement.mothership_company_address_id
        warehouseAddress = await enrichWarehouseAddress(currentFacilityProfileId)
      }

      return {
        ...requirement,
        index: indexCounter++,
        stopoverType: stopType,
        warehouseAddress: warehouseAddress,
        arrivalToWarehouse: requirement.start,
        departureFromWarehouse: requirement.end,
        notes: requirement.note,
        startDate: formatUtcByTimezone(
          requirement.start,
          warehouseAddress.timezone,
          'DD.MM.YYYY'
        ),
        startTime: formatUtcByTimezone(
          requirement.start,
          warehouseAddress.timezone,
          'HH:mm'
        ),
        endDate: formatUtcByTimezone(
          requirement.end,
          warehouseAddress.timezone,
          'DD.MM.YYYY'
        ),
        endTime: formatUtcByTimezone(requirement.end, warehouseAddress.timezone, 'HH:mm'),
        referenceNumber: requirement.reference,
        isOvernightLoad: stopIsOvernightLoad(
          requirement.start,
          requirement.end,
          warehouseAddress.timezone
        )
      }
    })
  )
}

export const getParsedShipmentStopoversForOrderSidebar = async (shipment, routeLegs) => {
  const loadingRequirements = shipment.loads[0].requirements.loading
  const unloadingRequirements = shipment.loads[0].requirements.unloading

  const processedLoading = await processRequirements(loadingRequirements, LOADING_TYPE)
  const processedUnloading = await processRequirements(
    unloadingRequirements,
    UNLOADING_TYPE
  )
  const processedLoadResults = [processedLoading, processedUnloading]

  const sortedRequirements = processedLoadResults.flat().sort((stopA, stopB) => {
    const dateA = Date.parse(stopA.start)
    const dateB = Date.parse(stopB.start)

    return dateA - dateB
  })

  sortedRequirements.map((requirement, index) => {
    const orderStop = store.getters['ordering/orderStops'][index]
    const routeLeg = routeLegs[index]
    const addressId =
      requirement.facility_profile_id ?? requirement.mothership_company_address_id

    if (addressId && !warehouseIndexCounter[addressId]) {
      warehouseIndexCounter[addressId] = warehouseIndex++
    }

    requirement.warehouseIndex = warehouseIndexCounter[addressId]
    // Timeslots are sourced from order, while the rest of the fields are coming from shipment v3.
    requirement.startDate = orderStop.startDate
    requirement.startTime = orderStop.startTime
    requirement.endDate = orderStop.endDate
    requirement.endTime = orderStop.endTime
    requirement.distanceToNext = routeLeg?.estimated_length_in_km || null

    return requirement
  })

  return sortedRequirements
}

export const getParsedShipmentStopovers = shipment => {
  const dateFormat = 'DD.MM.YYYY'
  const timeFormat = 'HH:mm'
  const loadingRequirements = shipment.loads[0].load_requirements.loading_requirements
  const unloadingRequirements = shipment.loads[0].load_requirements.unloading_requirements

  const combinedRequirements = [
    ...loadingRequirements.map(requirement => ({
      ...requirement,
      type: LOADING_TYPE,
      warehouseAddress: {
        ...requirement.warehouseAddress,
        fullAddress: requirement.warehouseAddress?.formatted
      },
      stopoverType: LOADING_TYPE,
      startDate: formatUtcByTimezone(
        requirement.loading_window_open_at,
        requirement.warehouseAddress.timezone,
        dateFormat
      ),
      startTime: formatUtcByTimezone(
        requirement.loading_window_open_at,
        requirement.warehouseAddress.timezone,
        timeFormat
      ),
      endDate: formatUtcByTimezone(
        requirement.loading_window_closed_at,
        requirement.warehouseAddress.timezone,
        dateFormat
      ),
      endTime: formatUtcByTimezone(
        requirement.loading_window_closed_at,
        requirement.warehouseAddress.timezone,
        timeFormat
      ),
      referenceNumber: requirement.reference,
      isOvernightLoad: stopIsOvernightLoad(
        requirement.loading_window_open_at,
        requirement.loading_window_closed_at,
        requirement.warehouseAddress.timezone
      )
    })),
    ...unloadingRequirements.map(requirement => ({
      ...requirement,
      type: UNLOADING_TYPE,
      warehouseAddress: {
        ...requirement.warehouseAddress,
        fullAddress: requirement.warehouseAddress?.formatted
      },
      stopoverType: UNLOADING_TYPE,
      startDate: formatUtcByTimezone(
        requirement.unloading_window_open_at,
        requirement.warehouseAddress.timezone,
        dateFormat
      ),
      startTime: formatUtcByTimezone(
        requirement.unloading_window_open_at,
        requirement.warehouseAddress.timezone,
        timeFormat
      ),
      endDate: formatUtcByTimezone(
        requirement.unloading_window_closed_at,
        requirement.warehouseAddress.timezone,
        dateFormat
      ),
      endTime: formatUtcByTimezone(
        requirement.unloading_window_closed_at,
        requirement.warehouseAddress.timezone,
        timeFormat
      ),
      referenceNumber: requirement.reference,
      isOvernightLoad: stopIsOvernightLoad(
        requirement.unloading_window_open_at,
        requirement.unloading_window_closed_at,
        requirement.warehouseAddress.timezone
      )
    }))
  ]

  return combinedRequirements.sort((stopA, stopB) => {
    const dateA = Date.parse(
      stopA.loading_window_open_at || stopA.unloading_window_open_at
    )
    const dateB = Date.parse(
      stopB.loading_window_open_at || stopB.unloading_window_open_at
    )

    return dateA - dateB
  })
}

const handleMeasurement = (value, multiplier = 1, fixedPoint = 0) => {
  return typeof value === 'number'
    ? Number(value * multiplier).toFixed(fixedPoint)
    : value
}

// we should merge order with shipment v3 data as shipment v3 cannot replace all the order fields:
export const mapShipmentV3DataToOrderStops = (shipment, order) => {
  return {
    ...order,
    loadDescription: shipment?.loads?.[0]?.description,
    sealable: shipment?.requirements?.vehicle?.securing?.sealable,
    codeXl: shipment?.loads?.[0]?.requirements?.certification?.code_xl,
    paletExchange: shipment?.loads?.[0]?.requirements?.pallet_exchange?.required,
    arrivalNotification: shipment?.mothership_compatibility?.arrival_notification,
    directTransfer: shipment?.mothership_compatibility?.direct_delivery,
    dockLoading: shipment?.mothership_compatibility?.direct_delivery,
    sideLoading: shipment?.mothership_compatibility?.direct_delivery,
    topLoading: shipment?.mothership_compatibility?.direct_delivery,

    loadUnitType: shipment?.loads?.[0]?.pallet_type,
    loadQuantity: shipment?.loads?.[0]?.quantity,
    loadHeight: handleMeasurement(
      shipment?.loads?.[0]?.height_in_meters,
      METRE_TO_CM_CONVERSION_FACTOR
    ),
    loadLength: handleMeasurement(
      shipment?.loads?.[0]?.length_in_meters,
      METRE_TO_CM_CONVERSION_FACTOR
    ),
    loadWidth: handleMeasurement(
      shipment?.loads?.[0]?.width_in_meters,
      METRE_TO_CM_CONVERSION_FACTOR
    ),
    loadWeight: handleMeasurement(
      shipment?.loads?.[0]?.weight_in_kg,
      KG_TO_TONNE_CONVERSION_FACTOR,
      TONNE_FIXED_POINT_FORMAT
    ),
    isTemperatureControlled: !!(
      shipment?.loads?.[0]?.requirements?.temperature?.minimum_degrees_celsius ||
      shipment?.loads?.[0]?.requirements?.temperature?.maximum_degrees_celsius
    ),
    loadMaximumTemperature:
      shipment?.loads?.[0]?.requirements?.temperature?.maximum_degrees_celsius,
    loadMinimumTemperature:
      shipment?.loads?.[0]?.requirements?.temperature?.minimum_degrees_celsius,
    trackingIdForShipper: shipment?.requirements?.tracking_id_for_shipper,
    isCancelled: shipment?.canceled,
    regularity: shipment?.mothership_compatibility?.contract_type,
    referenceNumber: shipment?.reference,
    unstructuredRequirements: shipment?.requirements?.unstructured_requirements,
    cleanliness: shipment?.loads?.[0]?.requirements?.certification?.cleanliness,
    adrClasses:
      shipment?.loads?.[0]?.requirements?.certification?.dangerous_goods?.adr_classes,
    limitedQuantity:
      shipment?.loads?.[0]?.requirements?.certification?.dangerous_goods
        ?.limited_quantity,
    allowedVehicles:
      shipment?.requirements?.vehicle?.acceptable_replacement_vehicle_types,
    preferredVehicleType: shipment?.requirements?.vehicle?.preferred_vehicle_type,
    fuelType: shipment?.requirements?.vehicle?.fuel_type,
    liftingRoof: shipment?.requirements?.vehicle?.lifting_roof,
    tailLift: shipment?.requirements?.vehicle?.tail_lift,
    trailerExchangeProhibited:
      shipment?.requirements?.vehicle?.trailer_exchange_prohibited,
    forkLift: shipment?.requirements?.vehicle?.forklift,
    doubleDriver: shipment?.requirements?.vehicle?.double_driver,
    minimumHeight: shipment?.requirements?.vehicle?.minimum_height,
    tirCable: shipment?.requirements?.vehicle?.securing?.tir_cable,
    sideboards: shipment?.requirements?.vehicle?.securing?.sideboards_count,
    cornerEdgeProtectors:
      shipment?.requirements?.vehicle?.securing?.corner_edge_protectors_count,
    antiSlipMats: shipment?.requirements?.vehicle?.securing?.anti_slip_mats_count,
    strapsBelts: shipment?.requirements?.vehicle?.securing?.straps_belts_count,
    bars: shipment?.requirements?.vehicle?.securing?.bars_count,
    chains: shipment?.requirements?.vehicle?.securing?.chains_count
  }
}

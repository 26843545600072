<template>
  <div class="diesel-floater-upload-section upload-csv-container">
    <div class="upload-csv-container">
      <div class="subsection-title">
        {{ $t('boards/upload-diesel-floater-csv-title') }}
      </div>
      <div class="additional-title">
        {{ $t('boards/upload-diesel-floater-csv-description') }}
      </div>
      <AlertMessage v-if="hasError" type="error" class="status-block">
        {{ uploadErrorMessage }}
        <a
          v-if="!uploadGeneralError"
          href="#"
          class="download-error-report"
          @click="downloadErrorReport"
        >
          {{ $t('boards/upload-diesel-floater-csv-see-errors-label') }}
        </a>
      </AlertMessage>
      <AlertMessage v-if="isSuccess" type="success" class="status-block">
        {{ $t('boards/upload-diesel-floater-csv-success') }}
      </AlertMessage>
      <div class="diesel-upload-section">
        <MainUpload
          :urlString="url"
          :buttonText="$t('boards/upload-diesel-floater-csv-upload-file-label')"
          @setGeneralError="setGeneralError"
          @uploadErrorEvent="setUploadError"
          @uploadProcessed="handleUploadProcessed"
          @clearUpload="clearUpload"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MainUpload from '@/modules/upload/components/upload-csv/MainUpload'
import generateErrorReport from '@/modules/upload/components/upload-csv/generate-error-report'
import {
  NOT_STARTED_STATUS,
  SUCCESS_STATUS,
  ERROR_STATUS
} from '@/modules/upload/components/upload-csv/upload-statuses'

export default {
  name: 'UploadDieselFloaterCsv',
  components: { MainUpload },
  data() {
    return {
      url: '/order-csv-import/commands/upload-diesel-floater-csv',
      uploadGeneralError: false,
      uploadErrorMessage: null,
      validationErrors: null,
      uploadStatus: NOT_STARTED_STATUS
    }
  },
  computed: {
    hasError() {
      return this.uploadStatus === ERROR_STATUS
    },
    isSuccess() {
      return this.uploadStatus === SUCCESS_STATUS
    }
  },
  methods: {
    handleUploadProcessed() {
      this.uploadStatus = SUCCESS_STATUS
    },
    handleAddedFile({ status, upload: { filename }, dropzone }) {
      this.clearUpload()

      this.uploadFilename = filename
      this.dropzoneRef = dropzone

      if (status === 'added') {
        this.uploadFileAdded = true
      }
    },
    setUploadError(errorObj) {
      this.uploadErrorMessage = errorObj.errorMessage
      this.ordersCreated = 0
      this.ordersAssigned = 0
      this.uploadStatus = ERROR_STATUS
      this.validationErrors = errorObj.validationErrors
    },
    setGeneralError(errorObj) {
      this.setUploadError(errorObj)
      this.uploadGeneralError = true
    },
    clearUpload() {
      this.uploadStatus = NOT_STARTED_STATUS
      this.uploadGeneralError = false
      this.uploadErrorMessage = null
      this.validationErrors = null
    },
    downloadErrorReport() {
      generateErrorReport(document, this.validationErrors)
    }
  }
}
</script>
<style scoped lang="scss">
.diesel-floater-upload-section {
  margin: 15px 15px 0;
  padding: 30px 25px;
  border-radius: 5px;
  color: $color-neutral-darkest;

  .upload-csv-container {
    background-color: $color-layout-white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    margin: 0 auto;
    font-size: 0.8rem;
    width: 661px;
    border-radius: 3px;
    padding: 24px;
  }

  .subsection-title {
    padding-bottom: 30px;
    font-size: 22px;
  }

  .additional-title {
    padding-bottom: 30px;
    color: $color-neutral-main;
    font-size: 14px;
  }

  .uploader-title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 15px;
  }

  .file-to-upload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    height: 51px;
    width: 613px;
    border: 1px solid $color-layout-divider;
    border-radius: 4px;
    background-color: $color-layout-white;
  }

  .file-to-upload-filename {
    display: flex;
    align-items: center;
  }

  .file-to-upload-filename > span {
    padding-right: 8px;
  }
}

.status-block {
  margin-bottom: 30px;
}
</style>

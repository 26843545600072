import { DateTimeFormats } from 'vue-i18n'

export const dateTimeFormats = {
  en: {
    date: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  it: {
    date: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  fr: {
    date: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  de: {
    date: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  pl: {
    date: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  es: {
    date: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  ru: {
    date: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  pt: {
    date: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  }
} as DateTimeFormats

<template>
  <Dialog
    :show="visible"
    :title="$t('finance-board/diesel-floater-modal-title')"
    @close="close"
    inPlace
  >
    <div
      v-if="errorMessage"
      class="error-message"
      data-test="diesel-floater-error-message"
    >
      <AlertMessage type="error">
        {{ errorMessage }}
      </AlertMessage>
    </div>
    <p class="paragraph">
      {{ $t('finance-board/diesel-floater-modal-description') }}
    </p>
    <Form ref="form" data-test="diesel-floater-form" @validated-submit="handleFormSubmit">
      <div class="plankton-grid">
        <div class="plankton-cell" span="10">
          <CustomerTypeAheadField
            v-model="customerCompany"
            :title="$t('finance-board/diesel-floater-modal-shipper-field-title')"
            closedLiveOnly
            required
            class="customer-input"
            data-test="client-company-name-select"
          />
        </div>
        <div class="plankton-cell" span="4">
          <NumericInputField
            v-model="dieselFloaterPercent"
            :inputValidators="percentageValidators"
            allowNegative
            fullWidth
            :title="$t('finance-board/diesel-floater-modal-percentage-field-title')"
            textIcon="%"
            placeholder="0.00"
            data-test="diesel-floater-percentage-input"
          />
        </div>
        <div class="plankton-cell" span="6">
          <DropdownField
            v-model="monthHandler"
            :options="months"
            :allow-deselect-option="false"
            :allow-empty="false"
            fullWidth
            optionLabel="value"
            optionKey="id"
            :title="$t('finance-board/diesel-floater-modal-month-field-title')"
            data-test="diesel-floater-month-selector"
          />
        </div>
        <div class="plankton-cell" span="4">
          <DropdownField
            v-model="yearHandler"
            :options="years"
            :allow-deselect-option="false"
            :allow-empty="false"
            fullWidth
            :title="$t('finance-board/diesel-floater-modal-year-field-title')"
            data-test="diesel-floater-year-selector"
          />
        </div>
      </div>
    </Form>
    <template #footer>
      <Button
        category="tertiary"
        :text="$t('finance-board/diesel-floater-modal-cancel-label')"
        @click="close"
      />
      <Button
        :materialIcon="isFetching ? 'autorenew' : null"
        :isIconRotating="isFetching"
        :disabled="isButtonDisabled"
        :text="
          isFetching
            ? $t('finance-board/diesel-floater-modal-loading-label')
            : $t('finance-board/diesel-floater-modal-confirm-label')
        "
        category="primary"
        data-test="diesel-floater-set-button"
        @click="$refs.form.submit()"
      />
    </template>
  </Dialog>
</template>

<script>
import CustomerTypeAheadField from '@/components/CustomerTypeAheadField'
import { formatJSONError } from '@/global-setup/global-error-handler'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import { runJsonPost } from '@/models/backend-client'
import { SETTLEMENT_API_URL } from '@/config'

import moment from 'moment'

import range from 'lodash/range'
import last from 'lodash/last'
import { financeService } from '@sennder/finance-frontend'

const months = moment.months().map((month, id) => ({ id, value: month }))
const years = range(2017, 2026)

const currentMonth = moment().month()
const currentYear = moment().year()

export default {
  components: { CustomerTypeAheadField },
  data: () => ({
    visible: false,
    isFetching: false,
    errorMessage: '',
    customerCompany: null,
    dieselFloaterPercent: null,
    month: currentMonth,
    year: years.includes(currentYear) ? currentYear : last(years)
  }),
  computed: {
    isButtonDisabled() {
      return Boolean(this.isFetching || this.errorMessage)
    },
    monthHandler: {
      get() {
        return months.find(month => month.id === this.month)
      },
      set({ id }) {
        if (typeof id === 'number') {
          this.month = id
        }
      }
    },
    yearHandler: {
      get() {
        return this.year
      },
      set(year) {
        if (typeof year === 'number') {
          this.year = year
        }
      }
    },
    months() {
      return months
    },
    years() {
      return years
    },
    percentageValidators() {
      return [
        {
          errorMessage: this.$t(
            'finance-board/diesel-floater-modal-percentage-required-message'
          ),
          isInvalid: value => value === '',
          inline: false
        }
      ]
    },
    isSettlementEndpointEnabled() {
      const { isActive } = useFeatureFlag()

      return isActive('ENABLE_FINANCE-UPLOAD-BUTTON-AR').value
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    close() {
      this.visible = false
      this.resetForm()
    },
    async handleFormSubmit($event) {
      if ($event.hasErrors) return
      this.isFetching = true
      try {
        /**
         * TODO: Temporary workaround
         * We need to move the whole modal to OctopusPaymentsWidget.
         */
        if (this.isSettlementEndpointEnabled) {
          await runJsonPost(
            `${SETTLEMENT_API_URL}/api/shipper-price/diesel-floater`,
            {
              company_id: this.customerCompany.id,
              percentage: this.dieselFloaterPercent,
              month: this.month + 1, // January must be 1, not 0,
              year: this.year
            },
            {},
            'https://api.cloud.sennder.com/settlement',
            'shipper-price:write'
          )
        } else {
          financeService.submitDieselFloater({
            companyId: this.customerCompany.id,
            percentage: this.dieselFloaterPercent,
            month: this.month + 1, // January must be 1, not 0
            year: this.year
          })
        }
        this.close()
      } catch (error) {
        this.errorMessage = formatJSONError(error)
      } finally {
        this.isFetching = false
      }
    },
    resetForm() {
      this.errorMessage = ''
      this.customerCompany = null
      this.dieselFloaterPercent = null
      this.month = currentMonth
      this.year = currentYear
    }
  }
}
</script>
<style lang="scss" scoped>
.customer-input {
  width: 100%;
}
</style>





















import { defineComponent } from '@vue/composition-api'
import { useModal } from '@/compositions'

export default defineComponent({
  setup(_props, context) {
    const submit = () => context.emit('confirm')

    const modal = useModal({
      onConfirm: submit
    })

    const show = () => modal.open()

    return {
      modal,
      show,
      submit
    }
  }
})

<template>
  <div>
    <DropdownField
      id="pdf-language"
      :options="languagesOptions"
      v-model="languageValue"
      v-bind="$attrs"
      searchable
      data-test="select-pdf-language"
      title=""
      optionLabel="name"
      optionKey="id"
    />
  </div>
</template>

<script>
import { PDF_LANGUAGES } from './languages'
import { makeOptions } from '@/controllers/options-formatter'

export default {
  props: {
    value: { type: null, required: true }
  },
  computed: {
    languageValue: {
      get() {
        return this.value
          ? this.languagesOptions.find(option => this.value === option.id)
          : ''
      },
      set(value) {
        this.$emit('input', value?.id || null)
      }
    },
    languagesOptions() {
      return makeOptions(PDF_LANGUAGES)
    }
  }
}
</script>

<template>
  <Dialog
    class="next-loads-dialog"
    :show="visible"
    title="Next loads"
    @close="close"
    :width="600"
    :inPlace="true"
  >
    <p class="paragraph info">
      Below you can find a list of possible next loads suggested based on the load you are
      currently viewing.
    </p>
    <div class="next-loads">
      <CurrentOrder :order="order" />
      <NextLoads :nextLoads="order.nextLoads" />
    </div>
  </Dialog>
</template>

<script>
import CurrentOrder from './CurrentOrder.vue'
import NextLoads from './NextLoads.vue'

import { getNextLoadsForOrder } from '@/services/next-loads-suggestions'

export default {
  components: {
    CurrentOrder,
    NextLoads
  },
  data() {
    return {
      order: { nextLoads: [] },
      visible: false
    }
  },
  methods: {
    async showModal(orderId) {
      if (this.resolve) this.resolve(false)

      this.order = await getNextLoadsForOrder(orderId)
      this.order.nextLoads.sort(
        (o1, o2) =>
          (o1.order?.distance || Number.MAX_VALUE) -
          (o2.order?.distance || Number.MAX_VALUE)
      )

      return new Promise(resolve => {
        this.resolve = resolve
        this.visible = true
      })
    },
    close() {
      this.visible = false
      this.resolve && this.resolve(false)
      this.resolve = null
    }
  }
}
</script>

<style lang="scss" scoped>
.next-loads-dialog {
  &::v-deep .modal-dialog {
    text-align: left;
    min-width: 950px;
    align-self: center;
  }
}

.info {
  text-align: left;
  font-size: 15px;
  line-height: 24px;
  color: $color-neutral-main;
}

.next-loads {
  display: flex;
  gap: 16px;
  font-size: 12px;
}
</style>

<template>
  <form class="quick-filters" data-test="quick-filters" @submit.prevent="applyFilters">
    <slot />

    <div class="quick-filters-actions">
      <Button
        :disabled="loadingPage"
        :loading="loadingPage"
        category="tertiary"
        materialIcon="close"
        type="button"
        data-test="clear-filters"
        class="clear-filters-btn"
        @click="resetFilters"
      />
      <Button
        :disabled="loadingPage"
        :loading="loadingPage"
        category="primary"
        :text="$t('filters/quick-filters-apply-label')"
        type="submit"
        data-test="apply-filters"
        class="apply-filters-btn"
      />
    </div>
  </form>
</template>

<script>
import events from '../events'
import { trackEvent } from '@/analytics/amplitude'

export default {
  props: {
    loadingPage: { type: Boolean, required: true }
  },
  methods: {
    applyFilters() {
      this.$emit('applyFilters')
      trackEvent(events.FILTERS_APPLY, {
        boardName: this.storeName,
        filtersType: 'quickFilters'
      })
    },
    resetFilters() {
      this.$emit('resetFilters')
      this.$emit('applyFilters')
      trackEvent(events.FILTERS_RESET, {
        boardName: this.storeName,
        filtersType: 'quickFilters'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.quick-filters {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: $color-layout-white;
  padding: 12px 16px 16px;

  &-actions {
    display: flex;
    align-items: flex-start;
    margin-top: 25px;
    justify-content: space-between;
  }

  .clear-filters-btn {
    color: $color-neutral-main;
    margin: 0 8px;
  }
}
</style>

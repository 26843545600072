import Address from '@/models/address'
import Carrier from '@/models/carrier'
import Charge from '@/models/charge'
import ChargeCorrection from '@/models/charge-correction'
import CompanyAddress from '@/models/company_address'
import Contact from '@/models/contact'
import Customer from '@/models/customer'
import { CarrierExtraCharge, CustomerExtraCharge } from '@/models/extra_charge'
import GeolocationTracker from '@/models/geolocation_tracker'
import { CarrierInvoice } from '@/models/invoices'
import MarketplaceLoad from '@/models/marketplace-load'
import Note from '@/models/note'
import Order from '@/models/order'
import OrderGroupLog from '@/models/order-group-log'
import OrderGroup from '@/models/order_group'
import OrdersAggregation from '@/models/orders_aggregation'
import Person from '@/models/person'
import Step from '@/models/step'
import TemporaryDriver from '@/models/temporary_driver'
import TrackedGeolocation from '@/models/tracked_geolocation'
import Transfer from '@/models/transfer'
import TransportRequest from '@/models/transport-request'
import OrderAssignment from '@/models/order-assignment'
import User from '@/models/user'

export const getModels = () => ({
  order_groups: OrderGroup,
  orders: Order,
  customer_extra_charges: CustomerExtraCharge,
  carrier_extra_charges: CarrierExtraCharge,
  carriers: Carrier,
  customers: Customer,
  company_addresses: CompanyAddress,
  contacts: Contact,
  addresses: Address,
  transfers: Transfer,
  persons: Person,
  users: User,
  notes: Note,
  logs: OrderGroupLog,
  temporary_drivers: TemporaryDriver,
  trackers: GeolocationTracker,
  steps: Step,
  orders_aggregation: OrdersAggregation,
  locations: TrackedGeolocation,
  marketplace_loads: MarketplaceLoad,
  carrier_invoices: CarrierInvoice,
  transport_requests: TransportRequest,
  order_assignments: OrderAssignment,
  charges: Charge,
  charge_corrections: ChargeCorrection
})

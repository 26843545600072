import { render, staticRenderFns } from "./CarrierContract.vue?vue&type=template&id=51958154&scoped=true&"
import script from "./CarrierContract.vue?vue&type=script&lang=ts&"
export * from "./CarrierContract.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51958154",
  null
  
)

export default component.exports
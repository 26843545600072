
























import { defineComponent, ref, computed } from '@vue/composition-api'
import { trackEvent } from '@/analytics/amplitude'
import { Button } from '@sennder/plankton'
import { generateDriverAppTrackingLink } from '@/services/order-tracking-service'
import { i18n } from '@/plugins/i18n'

export default defineComponent({
  components: {
    Button
  },
  props: {
    idForStaff: { type: String, required: true }
  },
  setup(props) {
    const trackingLink = ref<string | null>(null)
    const isOpen = ref(false)
    const isLoading = ref(false)

    const getTrackingLink = async () => {
      try {
        const { dynamicLink } = await generateDriverAppTrackingLink(props.idForStaff)
        trackingLink.value = dynamicLink
        trackEvent('octopus/execution-stage/mouseover/copy-link', { link: dynamicLink })
        return dynamicLink
      } catch (e) {
        return i18n.t('ordering/execution-driver-app-link-error')
      }
    }

    const tooltipOptions = computed(() => ({
      content: !trackingLink.value ? getTrackingLink : trackingLink.value,
      loadingContent: i18n.t('ordering/execution-driver-app-link-loading-text'),
      trigger: 'hover'
    }))

    const writeToClipboard = async () => {
      isOpen.value = true
      if (!trackingLink.value) {
        try {
          isLoading.value = true
          await getTrackingLink()
        } finally {
          isLoading.value = false
        }
      }

      await navigator.clipboard.writeText(trackingLink.value)
      await setTimeout(() => {
        isOpen.value = false
      }, 500)
    }

    return {
      isOpen,
      isLoading,
      tooltipOptions,
      writeToClipboard
    }
  }
})

import { query } from '@/graphql'

import { Suggestion, OrderMetrics } from '../../types'

interface Response {
  orders: Recommendations[]
}

interface Recommendations {
  orderId: number
  orderMetrics: OrderMetrics
  predictedCosts: {
    expectedCost: number
  }
  recommendations: Suggestion[]
}

const ORDERS_BY_RECOMMENDED = `
  query CarrierRecommendations($orderId: Int) {
    orders(where: { order_id: { _eq: $orderId }}) {
      order_id
      orderMetrics: relation_code_metrics {
        averageCost: avg_base_cost
        loadsCompleted: loads_completed
      }
      predicted_costs {
        expectedCost: suggested_base_cost_octopus
      }
      recommendations: carrier_recommendations(limit: 5, order_by: {score: desc}) {
        id: carrier_id
        score
        euro_per_km
        carrier_data {
          reason_for_blacklist
        }
        availableTrucks: capacity_matches {
          source_system
          pickup_start
        }
        contacts: carrier_contacts {
          carrierContactId: carrier_contact_id
          email
          first_name
          last_name
          phone
          isPrimary: is_primary_contact
          is_active
          owner: contact_owner {
            contactOwnerId: user_ptr_id
            first_name
            last_name
            phone
          }
        }
        callsMade: phone_calls {
          user {
            first_name
            last_name
            phone
          }
        }
        loadCoverage: gps_coverage
        loadVolume: loads_completed
        offers_sent {
          sender_id
          was_successful
        }
        carrier_metrics {
          otd: on_time_delivery
          otp: on_time_pickup
          trackingRate: tracking_rate
        }
        carrier_information {
          gpsCo3: gps_integrated_with_co3
          gpsP44: gps_integrated_with_p44
          gpsSennder: gps_integrated_with_sennder_app
          name
        }
      }
    }
  }
`

export class CarrierSuggestionsRecommendedService {
  private endpoint = ''
  private headers = {}

  constructor(
    url = process.env.VUE_APP_DATA_SERVICES_URL,
    key = process.env.VUE_APP_DATA_SERVICES_KEY
  ) {
    this.endpoint = url as string
    this.headers = { Authorization: key }
  }

  async search(orderId: number): Promise<(number | OrderMetrics | Suggestion[])[]> {
    const response = await query<Response>(
      this.endpoint,
      this.headers,
      ORDERS_BY_RECOMMENDED,
      {
        orderId
      }
    )
    const orderMetrics = response?.orders?.[0]?.orderMetrics ?? {}
    const expectedCost = response?.orders?.[0]?.predictedCosts?.expectedCost
    const recommendations = response?.orders?.[0]?.recommendations ?? []
    recommendations.forEach(recommendation => {
      recommendation.contacts = recommendation.contacts.filter(
        contact => contact.isPrimary
      )
    })

    return [orderMetrics, expectedCost, recommendations]
  }
}

import { query } from '@/graphql'

import { PreferencesSuggestion } from '../types'

interface Recommendations {
  recommendations: PreferencesSuggestion[]
}

const ORDERS_BY_PREFERENCES = `
  query CarrierPreferenceMatches($orderId: Int) {
    recommendations: preference_matches(args: {search_order_id: $orderId}, limit: 5) {
      carrier_company_id
      order_id
      ranking
      carrier_company {
        carrier_tier
        name
        gpsCo3: gps_integrated_with_co3
        gpsP44: gps_integrated_with_p44
        gpsSennder: gps_integrated_with_sennder_app
      }
      carrierContactId: contact_id
      contact {
        phone
        first_name
        last_name
        email
        is_active
        owner: contact_owner {
          contactOwnerId: user_ptr_id
          first_name
          last_name
          phone
        }
      }
      offers_sent {
        sender_id
        wasSuccessful: was_successful
      }
      carrier_metrics {
        otd: on_time_delivery
        otp: on_time_pickup
        trackingRate: tracking_rate
      }
      callsMade: phone_calls {
        user {
          first_name
          last_name
          phone
        }
      }
      preferences {
        origin_zip_code
        origin_country_code
        destination_country_code
        destination_zip_code
        vehicle_types
        reason_for_blacklist
      }
    }
  }
`

export class CarrierSuggestionsPreferencesService {
  private endpoint = ''
  private headers = {}

  constructor(
    url = process.env.VUE_APP_DATA_SERVICES_URL as string,
    key = process.env.VUE_APP_DATA_SERVICES_KEY
  ) {
    this.endpoint = url
    this.headers = { Authorization: key }
  }

  async search(orderId: number) {
    const response = await query<Recommendations>(
      this.endpoint,
      this.headers,
      ORDERS_BY_PREFERENCES,
      {
        orderId
      }
    )
    return response?.recommendations ?? []
  }
}

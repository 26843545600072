import { Currency, ICurrencyExchangeRate } from '../models'

export interface ICommonState {
  exchangeRates: {
    [key in Currency]?: ICurrencyExchangeRate
  }
}

export const getInitialState = (): ICommonState => ({
  exchangeRates: {}
})

export default getInitialState

import { ACCOUNTABILITY_API_URL } from '@/config'
import { SINGLE_AUDIENCE } from '@/services/auth-service'
import { runJsonPost } from '@/models/backend-client'
import logger from '@/shell/console-logger'

export enum ACCOUNTABILITY_ENTITY_TYPES {
  TRANSPORT_OFFER = 'transport_offer'
}

interface Entity {
  entity_id: string
  entity_type: ACCOUNTABILITY_ENTITY_TYPES
}

interface EntityAccountableTeam {
  entity_id: string
  entity_type: ACCOUNTABILITY_ENTITY_TYPES
  team_id: string
}

export const assignAccountableTeams = async (
  entities: Array<Entity>,
  teamId: string
): Promise<void> => {
  const url = `${ACCOUNTABILITY_API_URL}/api/teams/assign`

  logger.debug(
    `Calling accountability service API with PATH: ${url} with entities (${entities}), team_id (${teamId})`
  )
  try {
    await runJsonPost(
      url,
      {
        entities: entities,
        team_id: teamId
      },
      { apiGatewayAuthorization: true },
      SINGLE_AUDIENCE
    )
  } catch (error) {
    logger.warn(
      `accountability service API failed to assign accountable team for ${entities}: ${error}`
    )
  }
}

export const fetchAccountableTeams = async (
  entities: Array<Entity>
): Promise<{ [p: string]: string } | null> => {
  const url = `${ACCOUNTABILITY_API_URL}/api/teams/retrieve`

  logger.debug(
    `Calling fetch accountability service API with PATH: ${url} with entities (${entities})`
  )
  try {
    const response = await runJsonPost(
      url,
      entities,
      { apiGatewayAuthorization: true },
      SINGLE_AUDIENCE
    )

    if (response && response.status === 200 && response.data.length) {
      return Object.fromEntries(
        response.data.map((entity: EntityAccountableTeam) => [
          entity.entity_id,
          entity.team_id
        ])
      )
    }
  } catch (error) {
    logger.warn(`Accountability service failed for entities (${entities}) : ${error}`)
  }
  return null
}

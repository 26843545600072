import { Store as VuexStore, Module, CommitOptions, DispatchOptions } from 'vuex'

import state, { ICommonState } from './state'
import { mutations, MutationsType } from './mutations'
import { actions, ActionsType } from './actions'
import { getters, GettersType } from './getters'

type Namespaced<T, N extends string> = {
  [P in keyof T & string as `${N}/${P}`]: T[P]
}

export type NamespacedGetters = Namespaced<GettersType, 'common'>
export type NamespacedActions = Namespaced<ActionsType, 'common'>
export type NamespacedMutations = Namespaced<MutationsType, 'common'>

export type CommonStore<S = ICommonState> = Omit<
  VuexStore<S>,
  'getters' | 'commit' | 'dispatch'
> & {
  commit<
    K extends keyof NamespacedMutations,
    P extends Parameters<NamespacedMutations[K]>[1]
  >(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<NamespacedMutations[K]>
} & {
  dispatch<K extends keyof NamespacedActions>(
    key: K,
    payload: Parameters<NamespacedActions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<NamespacedActions[K]>
} & {
  getters: {
    [K in keyof NamespacedGetters]: ReturnType<NamespacedGetters[K]>
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const store: Module<ICommonState, any> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
export const makeCommonNamespace = (action: string) => `common/${action}`

export { ICommonState } from './state'
export { Actions } from './actions'
export { Getters } from './getters'

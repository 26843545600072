









import { defineComponent } from '@vue/composition-api'
import { placeholderIfEmpty } from '@/global-setup/filters'
import LabeledText from '@/modules/common/components/LabeledText.vue'

export default defineComponent({
  components: {
    LabeledText
  },
  props: {
    loadDescription: {
      type: String,
      default: '-'
    }
  },
  setup() {
    return { LabeledText, placeholderIfEmpty }
  }
})

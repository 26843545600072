import { OPERATIONS_BACKEND_URL } from '@/config'
import Model from '@/models/model'
import filter from 'lodash/filter'

class ExtraCharge extends Model {
  constructor(params, state) {
    super(params, state)
    // This fields need to be declared to be reactive
    // (as we use them in forms like ExtraCharges.vue)
    this.amount = this.amount || null
    this.summary = this.summary || null
  }

  get charge() {
    return this.$state.charges[this.amount_charge_id]
  }

  get chargeCorrections() {
    return filter(this.$state.charge_corrections, correction => {
      if (this.charge && this.charge.charge_corrections_ids)
        return this.charge.charge_corrections_ids.includes(correction.id)
    })
  }

  get initialChargeAmount() {
    if (this.charge) return this.charge.initial_amount
    // This won't be needed when we migrate amount_charge_id property to all extra-charges
    // (incoming finance ticket)
    return this.amount
  }
}

export class CarrierExtraCharge extends ExtraCharge {
  static get NAME() {
    return 'carrier_extra_charges'
  }

  static get URL() {
    return `${OPERATIONS_BACKEND_URL}/ordering/carrier_extra_charges/`
  }

  get isPreDispatchCharge() {
    return this.order_state == 'ADDED_IN_PRE_DISPATCH'
  }
}

export class CustomerExtraCharge extends ExtraCharge {
  static get NAME() {
    return 'customer_extra_charges'
  }

  static get URL() {
    return `${OPERATIONS_BACKEND_URL}/ordering/customer_extra_charges/`
  }

  get isPreSaleCharge() {
    return this.order_state == 'ADDED_IN_PRE_SALE'
  }
}

export function buildExtraCharge(extraChargeType, payload) {
  return extraChargeType === 'CUSTOMER'
    ? new CustomerExtraCharge(payload)
    : new CarrierExtraCharge(payload)
}

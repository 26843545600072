import { runJsonGet, runJsonPost } from '@/models/backend-client'
import { OPERATIONS_BACKEND_URL } from '@/config'
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase
} from '@/services/utils/casing'

export const fetchCarrierSuggestions = async orderIdForStaff => {
  const result = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/suggestions/carrier/${orderIdForStaff}`
  )
  return transformKeysToCamelCase(result.data)
}

/**
 * Offer an order to carriers by their ids in selected language
 * @param {Object} data
 * @param {number} data.orderId
 * @param {Array}  data.contactIdsList
 * @param {String} data.templateLanguage
 */
export const postOfferToCarriersViaEmail = async ({
  orderId,
  contactIdsList,
  templateLanguage
}) => {
  const url = `${OPERATIONS_BACKEND_URL}/suggestions/carrier/offer_order/${orderId}`
  return runJsonPost(
    url,
    transformKeysToSnakeCase({
      contactIdsList,
      templateLanguage
    })
  )
}

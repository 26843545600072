import moment from 'moment'
import getAlphabetEquivalent from '@/compositions/transfer/useStopLetter'
import isEmpty from 'lodash/isEmpty'
import { TransferStep } from '@/services'
import { Ref } from '@vue/composition-api'

export default (steps: Ref<TransferStep[]>) => {
  const _steps = steps.value

  const errors = []

  const errorMessages = {
    arrivalAfterDeparture: 'Arrival is set after departure.',
    future: 'Arrival time or departure too far in the future.',
    unsetArrival: 'Cannot unset arrival date/time',
    unsetDeparture: 'Cannot unset departure date/time',
    inconsistentTimestamps: 'The timestamps are not in sequential order'
  }

  const isArrivalAfterDeparture = (arrival: TransferStep, departure: TransferStep) => {
    if (Boolean(arrival) && Boolean(departure)) {
      const t1 = moment(arrival.actualTime)
      const t2 = moment(departure.actualTime)
      return t2.isBefore(t1)
    }
    return false
  }

  const isFutureTime = (transferStep: TransferStep) => {
    const t1 = moment(transferStep?.actualTime)
    const t2 = moment().utc()
    return t1.diff(t2, 'minutes') >= 5
  }

  const unSet = (transferStep: TransferStep) => {
    if (!isEmpty(transferStep)) return transferStep.wasSet && !transferStep.actualTime
    return false
  }

  const areActualTimestampsInSequentialOrder = (transfer: TransferStep[]) => {
    const actualTimestamps: number[] = transfer
      .filter(step => step.actualTime !== null)
      .map(step => new Date(step.actualTime).valueOf())
    return (
      actualTimestamps.length === 0 ||
      actualTimestamps.every((ts, i, arr) => i === 0 || ts > arr[i - 1])
    )
  }

  if (!areActualTimestampsInSequentialOrder(_steps))
    errors.push(errorMessages.inconsistentTimestamps)

  for (let i = 0; i < _steps.length / 2; i++) {
    const arrival = _steps[i * 2]
    const departure = _steps[i * 2 + 1]
    const alphabet = getAlphabetEquivalent(i)

    const validationChecks = [
      {
        condition: isArrivalAfterDeparture(arrival, departure),
        message: `Stop ${alphabet} &ndash; ${errorMessages.arrivalAfterDeparture}`
      },
      {
        condition: isFutureTime(arrival) || isFutureTime(departure),
        message: `Stop ${alphabet} &ndash; ${errorMessages.future}`
      },
      {
        condition: unSet(arrival),
        message: `Stop ${alphabet} &ndash; ${errorMessages.unsetArrival}`
      },
      {
        condition: unSet(departure),
        message: `Stop ${alphabet} &ndash; ${errorMessages.unsetDeparture}`
      }
    ]

    validationChecks.forEach(validation => {
      if (validation.condition === true) errors.push(validation.message)
    })
  }

  return errors
}







































































































































































































import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import TemperatureRangeField from '@/modules/ordering/components/shared/TemperatureRangeField.vue'
import { FORMATTED_VEHICLE_TYPES, LOAD_TYPES } from '@sennder/octopus-constants'
import { computed, defineComponent, ref } from '@vue/composition-api'
import { TemperatureRange } from '../shared/types'
import { i18n } from '@/plugins/i18n'

export default defineComponent({
  components: {
    TemperatureRangeField
  },
  props: {
    allowedVehicleTypes: { type: Array, default: () => [] },
    vehicleType: { type: String, default: null },
    loadQuantity: { type: Number, default: null },
    loadUnitType: { type: String, default: null },
    loadWeight: { type: Number, default: null },
    loadLength: { type: Number, default: null },
    loadHeight: { type: Number, default: null },
    loadWidth: { type: Number, default: null },
    loadMinimumTemperature: { type: Number, default: null },
    loadMaximumTemperature: { type: Number, default: null },
    loadDescription: { type: String, default: null },
    sealable: { type: Boolean, default: false },
    codeXl: { type: Boolean, default: false },
    needsPalletExchange: { type: Boolean, default: false },
    arrivalNotification: { type: Boolean, default: false },
    directTransfer: { type: Boolean, default: false },
    dockLoading: { type: Boolean, default: false },
    sideLoading: { type: Boolean, default: false },
    topLoading: { type: Boolean, default: false },
    isTemperatureControlled: { type: Boolean, default: false },
    orderId: { type: Number, required: true },
    orderState: { type: String, required: true }
  },
  setup(props, { emit }) {
    const palletExchangeForm = ref(null)

    const vehicleTypeValidators = computed(() => [
      {
        isInvalid: isEmpty,
        errorMessage: i18n.t('ordering/load-section-vehicle-type-not-empty'),
        inline: false
      },
      {
        isInvalid: (option: typeof FORMATTED_VEHICLE_TYPES) => {
          if (isEmpty(option)) return false
          return (
            props.isTemperatureControlled &&
            (option.length !== 1 || option[0].value !== 'TRUCK_40_FRIGO')
          )
        },
        errorMessage: i18n.t(
          'ordering/load-section-when-temp-control-selected-frigo-only'
        ),
        inline: false
      }
    ])

    const lowestTemperatureValidators = computed(() => [
      {
        isInvalid: (value: number) => props.isTemperatureControlled && isEmpty(value),
        errorMessage: i18n.t(
          'ordering/load-section-when-temp-control-selected-lowest-temp-not-empty'
        ),
        inline: false
      },
      {
        isInvalid: (value: number) => {
          if (isNil(value) || isNil(props.loadMaximumTemperature)) return false
          return value > props.loadMaximumTemperature
        },
        errorMessage: i18n.t(
          'ordering/load-section-lowest-temp-cannot-be-higher-than-highest-temp'
        ),
        inline: false
      }
    ])

    const highestTemperatureValidators = computed(() => [
      {
        isInvalid: (value: number) => props.isTemperatureControlled && isEmpty(value),
        errorMessage: i18n.t(
          'ordering/load-section-when-temp-control-selected-highest-temp-not-empty'
        ),
        inline: false
      }
    ])

    const loadTypeSelected = computed<typeof LOAD_TYPES[number]>({
      get() {
        return find(LOAD_TYPES, { value: props.loadUnitType })
      },
      set(option: typeof LOAD_TYPES[number] | null) {
        emit('update:loadUnitType', option?.value || null)
      }
    })

    const legacyLoadType = computed<string | null>(() =>
      loadTypeSelected.value ? loadTypeSelected.value?.label : null
    )

    const allowedVehicleTypesSelected = computed<typeof FORMATTED_VEHICLE_TYPES>({
      get() {
        if (!props.allowedVehicleTypes) return []
        else {
          return props.allowedVehicleTypes.map(type =>
            FORMATTED_VEHICLE_TYPES.find(formattedType => formattedType.value === type)
          )
        }
      },
      set(value: typeof FORMATTED_VEHICLE_TYPES) {
        emit(
          'update:allowedVehicleTypes',
          value.map(type => type.value)
        )
      }
    })

    const submitPalletExchange = async (): Promise<{
      orderId: number
      allowedCases: number[]
      equipmentType?: string
      quantity?: number
    } | null> => await palletExchangeForm.value?.submit()

    const emitTemperatureUpdate = ({ min, max }: TemperatureRange): void => {
      emit('update:loadMinimumTemperature', min)
      emit('update:loadMaximumTemperature', max)
    }

    return {
      LOAD_TYPES,
      FORMATTED_VEHICLE_TYPES,
      vehicleTypeValidators,
      lowestTemperatureValidators,
      highestTemperatureValidators,
      loadTypeSelected,
      legacyLoadType,
      allowedVehicleTypesSelected,
      palletExchangeForm,
      submitPalletExchange,
      emitTemperatureUpdate
    }
  }
})

<template>
  <BoardTableRow types="header">
    <th>
      <Checkbox v-model="checkedHandler" :disabled="loadingPage" data-test="check-all" />
    </th>
    <th>{{ $t('finance-board/finance-board-header-sennder-ref') }}</th>
    <th>{{ $t('finance-board/finance-board-header-customer-ref') }}</th>
    <th>{{ $t('finance-board/finance-board-header-customer') }}</th>
    <th>{{ $t('finance-board/finance-board-header-carrier') }}</th>
    <th>{{ $t('finance-board/finance-board-header-origin') }}</th>
    <th>{{ $t('finance-board/finance-board-header-destination') }}</th>
    <th>{{ $t('finance-board/finance-board-header-pickup') }}</th>
    <th>
      {{ $t('finance-board/finance-board-header-base') }}
      <br />
      {{ $t('finance-board/finance-board-header-price-cost') }}
    </th>
    <th>
      {{ $t('finance-board/finance-board-header-surcharge-net') }}
      <br />
      {{ $t('finance-board/finance-board-header-revenue-cost') }}
    </th>
    <th>
      {{ $t('finance-board/finance-board-header-extra') }}
      <br />
      {{ $t('finance-board/finance-board-header-price-cost') }}
    </th>
    <th>
      {{ $t('finance-board/finance-board-header-total-net') }}
      <br />
      {{ $t('finance-board/finance-board-header-revenue-cost') }}
    </th>
    <th>
      {{ $t('finance-board/finance-board-header-vat') }}
      <br />
      {{ $t('finance-board/finance-board-header-customer-carrier') }}
    </th>
    <th>{{ $t('finance-board/finance-board-header-discount') }}</th>
    <th>
      {{ $t('finance-board/finance-board-header-total-gross') }}
      <br />
      {{ $t('finance-board/finance-board-header-revenue-cost') }}
    </th>
    <th>{{ $t('finance-board/finance-board-header-billed') }}</th>
  </BoardTableRow>
</template>

<script>
import { BoardTableRow } from '@/modules/boards'

export default {
  components: { BoardTableRow },
  props: {
    loadingPage: {
      type: Boolean,
      default: true
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    checkedHandler: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>








































































































import { monitorError } from '@/analytics/monitoring'
import logger from '@/shell/console-logger'
import LoginByEmailForm from './LoginByEmailForm.vue'
import { mapGetters, mapActions } from 'vuex'
import get from 'lodash/get'
import RenderWithTheme from '@/modules/common/components/RenderWithTheme.vue'
import RenderWithLaunchDarkly from '@/components/RenderWithLaunchDarkly.vue'
import {
  isPosteTheme,
  isProductionOrStagingUrl
} from '@/controllers/environment-detection'
import WithThemeTitle from '@/modules/common/components/WithThemeTitle.vue'
import { defineComponent } from '@vue/composition-api'
import { trackEvent } from '@/analytics/amplitude'
import { events, trackingModule } from './events'
import { getInstance } from '@sennder/auth'

import Vue from 'vue'

// LocalStorage key used to save the redirect path when log in using Google.
const REDIRECT_PATH_STORAGE_KEY = 'redirectAfterLoginRoute'

// TODO: Replace message keys with proper keys for each specific error once the messages are agreed with product team
const AUTH0_ERRORS = {
  // TODO: Remove this entry once the Auth0 rules returning this error are modified
  'Email domain not allowed': {
    jsonError: false,
    messageKey: 'auth/wrong-email-domain-error-message'
  },
  EMAIL_DOMAIN_NOT_ALLOWED: {
    jsonError: true,
    messageKey: 'auth/wrong-email-domain-error-message'
  },
  INTERNATIONAL_OPERATOR_EMAIL_DOMAIN_NOT_ALLOWED: {
    jsonError: true,
    messageKey: 'auth/wrong-email-domain-error-message'
  },
  MOTHERSHIP_GENERIC_SERVER_ERROR: {
    jsonError: true,
    messageKey: 'auth/auth-login-error-message'
  },
  MOTHERSHIP_ACCOUNT_NOT_FOUND: {
    jsonError: true,
    messageKey: 'auth/auth-login-error-message'
  },
  MOTHERSHIP_NOT_OK_STATUS_CODE: {
    jsonError: true,
    messageKey: 'auth/auth-login-error-message'
  },
  MOTHERSHIP_UNAUTHORIZED_USER: {
    jsonError: true,
    messageKey: 'auth/auth-login-error-message'
  },
  SERVER_ERROR: {
    jsonError: true,
    messageKey: 'auth/auth-login-error-message'
  },
  UMS_SERVER_ERROR: {
    jsonError: true,
    messageKey: 'auth/auth-login-error-message'
  }
}
const auth0ErrorRegex = new RegExp(Object.keys(AUTH0_ERRORS).join('|'))
const AUTH0_AUTO_LOGOUT_TIMEOUT = 3000

export default defineComponent({
  components: {
    WithThemeTitle,
    LoginByEmailForm,
    RenderWithTheme,
    RenderWithLaunchDarkly
  },
  beforeRouteEnter(to, from, next) {
    // if we logged out of Octopus from some page
    let redirectPath = from.fullPath
    // if we originally visited some other page and were redirected to login
    if (to?.query?.path) redirectPath = to?.query?.path as string
    next(
      vm =>
        ((
          vm as Vue & {
            redirectAfterLoginRoute: string
          }
        ).redirectAfterLoginRoute = redirectPath)
    )
  },
  data() {
    return {
      loginByEmailFormHidden: true,
      errorMessage: null,
      showContent: false,
      redirectAfterLoginRoute: null,
      waitingForAuth0ToCompleteLogin: false,
      autoLogoutTimeoutId: null
    }
  },
  computed: {
    ...mapGetters(['currentUserIsCallCenter', 'currentUserCanLogin']),
    isPosteTheme() {
      return isPosteTheme
    }
  },
  watch: {
    currentUserCanLogin(value) {
      if (value) this.redirectToPreviousPath()
    }
  },
  mounted() {
    this.$store.commit('resetCurrentUser')
    trackEvent(events.LOGIN_PAGE_VIEW, {
      module: trackingModule,
      section: null
    })
  },
  unmounted() {
    if (this.autoLogoutTimeoutId) clearTimeout(this.autoLogoutTimeoutId)
  },
  async created() {
    if (this.hasGoogleAuthorizationHash()) return this.tryToLoginWithGoogle()
    this.showContent = true
    this.resetRedirectPathStorage()
  },
  methods: {
    ...mapActions([
      'login',
      'logout',
      'loginWithGoogle',
      'navigateToGoogleSignin',
      'fetchCurrentUser'
    ]),
    showLoginByEmailForm() {
      this.loginByEmailFormHidden = false
    },
    async loginWithEmail({ email, password }) {
      try {
        await this.login({ email, password })
        this.logoutIfHasNotPermission()
      } catch (error) {
        this.errorMessage = this.$t('auth/login-error-msg-login-failed')
      }
    },
    async tryToLoginWithGoogle() {
      try {
        await this.loginWithGoogle()
      } catch (error) {
        this.showContent = true
        this.errorMessage = get(
          JSON.parse(error || null),
          ['non_field_errors', 0],
          'Login failed!'
        )
      }
    },
    logoutIfHasNotPermission() {
      if (this.currentUserCanLogin) {
        this.redirectToPreviousPath()
      } else {
        this.errorMessage = this.$t('auth/login-error-msg-no-permission')
        this.logout()
      }
    },
    hasGoogleAuthorizationHash() {
      return location.hash.includes('www.googleapis.com')
    },
    navigateToGoogle() {
      localStorage.setItem(REDIRECT_PATH_STORAGE_KEY, this.redirectAfterLoginRoute)
      this.navigateToGoogleSignin(location.origin + location.pathname)
    },
    redirectToPreviousPath() {
      if (this.currentUserIsCallCenter) this.octopusRouter.goToCallCenterBoard()
      else {
        this.$router.replace(
          localStorage.getItem(REDIRECT_PATH_STORAGE_KEY) || this.redirectAfterLoginRoute
        )
      }
    },
    resetRedirectPathStorage() {
      localStorage.removeItem(REDIRECT_PATH_STORAGE_KEY)
    },
    async logoutWithAuth0() {
      /**
       * Perform full logout to clean up all Auth0 cookies
       */
      await this.$auth.logout()
    },
    logoutOnKnownAuth0Error(error: string) {
      const matchedAuth0error: RegExpMatchArray | null = error.match(auth0ErrorRegex)
      if (matchedAuth0error) {
        const auth0errorConfig = AUTH0_ERRORS[matchedAuth0error[0]]
        let errorWarning = error
        /**
         * If the error is a json error. It would be a stringified json object with this type definition:
         * {
         *  code: String;
         *  description: String;
         * }
         *
         * The error will be parsed, and the description will be logged.
         */
        if (auth0errorConfig.jsonError) {
          try {
            const auth0Error = JSON.parse(error)
            errorWarning = auth0Error.description
          } catch (error) {
            monitorError(error)
          }
        }

        logger.warn(
          `[LOGIN] Auth0 logout triggered because of a known Auth0 error: ${errorWarning}`
        )

        this.errorMessage = this.$t(auth0errorConfig.messageKey)

        this.autoLogoutTimeoutId = setTimeout(
          async function () {
            await this.logoutWithAuth0()
          }.bind(this),
          AUTH0_AUTO_LOGOUT_TIMEOUT
        )

        return true
      }

      return false
    },
    async loginWithAuth0() {
      localStorage.setItem(REDIRECT_PATH_STORAGE_KEY, this.redirectAfterLoginRoute)

      try {
        this.waitingForAuth0ToCompleteLogin = true
        const auth0Instance = getInstance()
        await this.$auth.loginWithPopup()
        let isAuthenticated = await this.$auth.auth0Client.isAuthenticated()
        if (!isAuthenticated) {
          /**
           * We need to fully reset Auth0 session in case the error is a known Auth0 error.
           * Otherwise, Auth0 will stay logged with the wrong domain and will attempt to authenticate user with every time.
           */
          const isKnownAuth0Error =
            auth0Instance?.error?.error_description &&
            this.logoutOnKnownAuth0Error(auth0Instance.error.error_description)

          if (!isKnownAuth0Error) {
            this.errorMessage = this.$t('auth/auth-login-error-message')
            this.waitingForAuth0ToCompleteLogin = false
          }
        } else {
          await this.fetchCurrentUser(true)
          trackEvent(events.SUCCESSFULL_LOGIN, {
            module: trackingModule,
            section: null
          })
        }
      } catch (e) {
        logger.error('Authorization Error: ', e)

        if (isProductionOrStagingUrl) {
          monitorError(e)
        }
        this.waitingForAuth0ToCompleteLogin = false
      }
      this.redirectToPreviousPath()
    }
  }
})

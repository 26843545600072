<template>
  <InputField v-model="filterHandler" v-bind="$attrs" fullWidth />
</template>

<script>
export default {
  inject: ['getFilterValue', 'setFilterValue'],
  props: {
    filterName: { type: String, required: true },
    transformer: { type: Function, default: null }
  },
  computed: {
    filterHandler: {
      get() {
        return this.getFilterValue(this.filterName)
      },
      set(value) {
        // The user input transformation function is executed if possible
        if (typeof this.transformer === 'function') {
          value = this.transformer(value)
        }
        this.setFilterValue(this.filterName, value)
        this.$forceUpdate()
      }
    }
  }
}
</script>

<template>
  <a :href="`tel:${phone}`" class="phone-link" v-on="$listeners">
    <img class="icon" src="./assets/phone.svg" />
    <div class="content paragraph">
      <slot />
    </div>
  </a>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    phone: { type: String, default: '+49000000' }
  }
})
</script>

<style lang="scss" scoped>
.phone-link {
  color: $color-neutral-darkest;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.icon {
  padding: 0;
  margin: 0;
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
</style>

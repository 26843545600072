<template>
  <DropdownField
    v-model="filterHandler"
    :options="['SPOT', 'REGULAR', 'RECURRING', 'SCALED_SPOT']"
    :close-on-select="false"
    :clear-on-select="true"
    multiple
    searchable
    :title="$t('filters/contract-type-filter-title')"
    data-test="contract-filter"
    fullWidth
  />
</template>

<script>
export default {
  inject: ['getFilterValue', 'setFilterValue'],
  computed: {
    filterHandler: {
      get() {
        return this.getFilterValue('contractTypes')
      },
      set(payload) {
        this.setFilterValue('contractTypes', payload)
        this.$emit('applyFilters')
      }
    }
  }
}
</script>


import { computed, defineComponent, onBeforeUnmount } from '@vue/composition-api'
import useFeatureFlag from '@/compositions/useFeatureFlag'

export default defineComponent({
  props: {
    hasPermission: {
      type: String,
      default: ''
    },
    doesNotHavePermission: {
      type: String,
      default: ''
    }
  },
  setup(props, { slots }) {
    const { isActive, removeListener } = useFeatureFlag()
    const isPermissionEnabled = computed(
      () => !props.hasPermission || isActive(props.hasPermission).value
    )
    const isPermissionDisabled = computed(
      () => !props.doesNotHavePermission || !isActive(props.doesNotHavePermission).value
    )
    const displayContent = computed(
      () => isPermissionEnabled.value && isPermissionDisabled.value
    )

    onBeforeUnmount(() => {
      if (props.hasPermission) removeListener(props.hasPermission)
      if (props.doesNotHavePermission) removeListener(props.doesNotHavePermission)
    })

    return () => {
      if (slots.default?.().length > 1)
        throw Error(
          'Please add just one element to the RenderWithLaunchDarkly component slot'
        )

      return displayContent.value ? slots.default?.() : null
    }
  }
})

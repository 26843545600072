import Person from '@/models/person'
import { humanizeIsoDateTimeWithDots } from '@sennder/plankton'
import Model from '@/models/model'

export default class MarketplaceLoad extends Model {
  static get NAME() {
    return 'marketplace_loads'
  }

  get humanizedCreationDate() {
    return humanizeIsoDateTimeWithDots(this.created)
  }

  get creatorInitials() {
    return Person.getPersonInitials(this.$state, this.creator_id)
  }
}

import {
  OPERATIONS_BACKEND_URL,
  SHIPMENT_API_URL,
  SHIPPER_PROFILE_API_URL,
  WORKFLOW_INTERFACE_API_URL,
  SETTLEMENT_API_URL
} from '@/config'
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from '@/global-setup/filters'
import { runJsonGet, runJsonPost, runJsonPut } from '@/models/backend-client'
import { OrderState } from '@/modules/common/order-states'
import { AlertModal, Timestamp } from '@sennder/plankton'
import { AxiosResponse } from 'axios'
import get from 'lodash/get'
import omit from 'lodash/omit'
import moment from 'moment-timezone'
import { ORDER_SOURCE_OCTOPUS, STATE_TRANSITIONS } from '../modules/ordering/constants'
import {
  CalculatedPrice,
  CarrierKpi,
  CarrierTrackingRate,
  HereMapsParameters,
  IFeasibleEntity,
  IShipperProfileResponse,
  OrderCreation,
  OrderCreationResponse,
  OrderCustomerUpdate,
  OrderDetail,
  OrderDetailWithCustomerCompany,
  OrderDistanceTransitTime,
  OrderStateTransition,
  OrderStop,
  OrderStopFetch,
  OrderStopToEdit,
  TourExecutionStatus,
  TransferDetail,
  UUID
} from './index'
import { transformKeysToCamelCase, transformKeysToSnakeCase } from './utils/casing'
import { MothershipOperatorRole } from '@/services'

import { isSennderTheme } from '@/controllers/environment-detection'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import { getTourExecution, markAsExecuted } from '@/services/transit-service'
import logger from '@/shell/console-logger'
import Swal from 'sweetalert2'
import { trackEvent } from '@/analytics/amplitude'
import {
  errorSource,
  events,
  submodule,
  trackingModule
} from '@/modules/ordering/components/execution/events/events'
import {
  EndTourEventErrorType,
  EndTourEventType
} from '@/modules/ordering/components/execution/events/type'
import { ActionValidationErrorCodes } from './enums'

const UPDATE_ORDER_COMMAND_BY_STATE = {
  NEW: 'update-new-order',
  REGISTERED: 'update-registered-order',
  CARRIER_LOCKED: 'update-registered-order'
}

const END_TOUR_VALIDATION_ERROR = {
  code: 'end_tour_validation_error',
  message: {
    facility_actions: `To end the tour, at least one loading should be
    Loaded Successfully and <b>All</b> the Unloading(s)
    should be Unloaded Successfully or Partially Rejected.`,
    timestamps: `This shipper expects mandatory timestamps.
     The tour you are trying to execute has missing timestamps.`
  }
}

// carrierId, creatorId, carrierContactId, responderId

export const closeCustomerPrice = async (orderId: number): Promise<void> => {
  await runJsonPost(`${SETTLEMENT_API_URL}/api/shipper-price/order/${orderId}/close`)
}

export const closeCarrierCost = async (orderId: number): Promise<void> => {
  await runJsonPost(`${SETTLEMENT_API_URL}/api/carrier-cost/order/${orderId}/close`)
}

export const sendDispatchConfirmation = async (
  orderIds: number[],
  language: string
): Promise<void> => {
  const payload = transformKeysToSnakeCase({ orderIds, language })

  const url = process.env.VUE_APP_CA_CONFIRMATION_EMAIL_API_URL
  const audience = 'https://api.cloud.sennder.com/ca-confirmation-email'
  const scope = 'confirmation-email:trigger'

  await runJsonPost(`${url}/v1/confirmation-email`, payload, {}, audience, scope)
}

export async function createOrder(order: OrderCreation): Promise<OrderCreationResponse> {
  const orderWithSource = {
    ...order,
    source: ORDER_SOURCE_OCTOPUS
  }

  const { data = {} } = (await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/ordering/commands/create-order`,
    transformKeysToSnakeCase(orderWithSource)
  )) as AxiosResponse<unknown>

  return transformKeysToCamelCase<OrderCreationResponse>(data)
}

export async function copyOrder(orderId: number): Promise<OrderCreationResponse> {
  const response = (await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/ordering/commands/copy-order`,
    {
      order_id: orderId,
      source: ORDER_SOURCE_OCTOPUS
    }
  )) as AxiosResponse<unknown>

  return transformKeysToCamelCase<OrderCreationResponse>(response.data)
}

export const updateOrderCustomerInformation = async (
  orderId: number,
  {
    customerCompanyId,
    referenceNumber,
    customerContactId,
    trackingIdForShipper
  }: OrderCustomerUpdate
): Promise<void> => {
  await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/ordering/commands/update-order-customer-information`,
    transformKeysToSnakeCase({
      orderId,
      customerCompanyId,
      referenceNumber,
      customerContactId,
      trackingIdForShipper
    })
  )
}

export const fetchOrder = async (
  orderId: number
): Promise<OrderDetailWithCustomerCompany> => {
  const response = (await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/get-order`,
    transformKeysToSnakeCase({ orderId })
  )) as AxiosResponse<unknown>
  const order = transformKeysToCamelCase<OrderDetail>(response.data)
  return { ...order, customerCompany: { id: order.customerId, name: order.customerName } }
}

const isOvernightLoad = stop => {
  const dateFormat = 'YYYY-MM-DD'
  const warehouseTz = stop.warehouseAddress.timezone

  const arrivalToWarehouseDate = moment
    .tz(stop.arrivalToWarehouse, warehouseTz)
    .format(dateFormat)
  const departureFromWarehouseDate = moment
    .tz(stop.departureFromWarehouse, warehouseTz)
    .format(dateFormat)

  return moment(departureFromWarehouseDate).isAfter(arrivalToWarehouseDate)
}

const transformInboundStop = (stop: OrderStopFetch): OrderStop => {
  const tz = stop.warehouseAddress.timezone
  const result = {
    ...omit(stop, ['arrivalToWarehouse', 'departureFromWarehouse']),
    arrivalToWarehouse: new Timestamp(stop.arrivalToWarehouse, tz),
    departureFromWarehouse: new Timestamp(stop.departureFromWarehouse, tz),
    startDate: stop.arrivalToWarehouse
      ? new Timestamp(stop.arrivalToWarehouse, tz).date
      : '',
    endDate: stop.departureFromWarehouse
      ? new Timestamp(stop.departureFromWarehouse, tz).date
      : '',
    startTime: stop.arrivalToWarehouse
      ? new Timestamp(stop.arrivalToWarehouse, tz).time
      : '',
    endTime: stop.departureFromWarehouse
      ? new Timestamp(stop.departureFromWarehouse, tz).time
      : '',
    isOvernightLoad: isOvernightLoad(stop)
  } as OrderStop

  if (stop.contactPerson) {
    result.contactPerson = {
      ...stop.contactPerson,
      name: `${stop.contactPerson.firstName || ''} ${stop.contactPerson.lastName}`
    }
  }

  return result
}

export const fetchOrderStops = async (orderId: number): Promise<OrderStop[]> => {
  const response = (await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/get-order-stops`,
    transformKeysToSnakeCase({ orderId })
  )) as AxiosResponse<unknown>
  if (!response.data || !Array.isArray(response.data)) {
    return []
  }
  return transformKeysToCamelCase<OrderStopFetch[]>(response.data).map(
    transformInboundStop
  )
}

export const updateOrder = async (
  id: number,
  state: OrderState,
  changes: Partial<OrderDetail>
): Promise<void> => {
  const command = UPDATE_ORDER_COMMAND_BY_STATE[state]
  await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/ordering/commands/${command}`,
    transformKeysToSnakeCase({ ...changes, id })
  )
}

export const checkFeasibilityWorkflowStatus = async (
  entity: IFeasibleEntity,
  workflowName: string
): Promise<unknown> => {
  const response = await runJsonPost(
    `${WORKFLOW_INTERFACE_API_URL}/feasibility/workflow`,
    transformKeysToSnakeCase({ workflowName, entity }),
    {},
    'https://api.cloud.sennder.com/workflow-interface',
    'feasibility-workflow:post'
  )

  return response
}

export const assignAccountManagers = async (
  orderIds: number[],
  seniorAccountManagerId: number,
  juniorAccountManagerId: number
) => {
  runJsonPut(
    `${OPERATIONS_BACKEND_URL}/ordering-account-operation/commands/assign-account-managers`,
    transformKeysToSnakeCase({ orderIds, seniorAccountManagerId, juniorAccountManagerId })
  )
}

export const assignOperators = async (
  orderIds: number[],
  operatorId: number,
  role: MothershipOperatorRole
) => {
  runJsonPut(
    `${OPERATIONS_BACKEND_URL}/ordering-designated-operator/views/assign-operators`,
    transformKeysToSnakeCase({ orderIds, operatorId, role })
  )
}

export const createUtcFormat = (date: string, time: string): string =>
  moment(`${date} ${time}`, `${DATE_FORMAT} ${TIME_FORMAT}`).utc().format()

export const createUtcByWarehouseTimezone = (
  date: string,
  time: string,
  tz: string
): string => {
  return moment.tz(`${date} ${time}`, DATE_TIME_FORMAT, tz).utc().format()
}

const getDepartureFromWarehouse = stop => {
  if (stop.isOvernightLoad === false) {
    return stop.startDate && stop.endTime
      ? createUtcByWarehouseTimezone(
          stop.startDate,
          stop.endTime,
          stop.warehouseAddress.timezone
        )
      : null
  }
  return stop.endDate && stop.endTime
    ? createUtcByWarehouseTimezone(
        stop.endDate,
        stop.endTime,
        stop.warehouseAddress.timezone
      )
    : null
}

const getArrivalToWarehouse = stop => {
  return stop.startDate && stop.startTime
    ? createUtcByWarehouseTimezone(
        stop.startDate,
        stop.startTime,
        stop.warehouseAddress.timezone
      )
    : null
}

const transformOutboundStop = (stop: OrderStop): OrderStopToEdit => ({
  ...omit(stop, [
    'startDate',
    'endDate',
    'startTime',
    'endTime',
    'warehouseAddress',
    'contactPerson',
    'customerCompany',
    'isOvernightLoad'
  ]),
  arrivalToWarehouse: getArrivalToWarehouse(stop),
  departureFromWarehouse: getDepartureFromWarehouse(stop),
  contactPersonId: get(stop, 'contactPerson.id', null),
  companyId: get(stop, 'customerCompany.id', null)
})

export const updateStops = async (
  orderId: number,
  orderVersion: number,
  stops: OrderStop[]
): Promise<void> => {
  await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/ordering/commands/save-order-stopovers`,
    transformKeysToSnakeCase({
      orderId,
      orderVersion,
      orderStops: stops.map(transformOutboundStop)
    })
  )
}

export const saveOrderVariation = async ({
  order,
  deleteStopoverIds,
  saveStopovers,
  updatedPrice,
  updatedCost
}: {
  order: Partial<OrderDetail>
  deleteStopoverIds: number[]
  saveStopovers: OrderStop[]
  updatedPrice: number
  updatedCost: number
}): Promise<void> => {
  await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/ordering/commands/save-order-variation`,
    transformKeysToSnakeCase({
      order,
      deleteStopoverIds,
      saveStopovers: saveStopovers.map(transformOutboundStop),
      updatedPrice,
      updatedCost
    })
  )
}

export const deleteAllStops = async (orderId: number): Promise<void> => {
  await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/ordering/commands/delete-all-stopovers-for-order`,
    transformKeysToSnakeCase({
      orderId
    })
  )
}

export const deleteStops = async (
  stopIds: number[],
  orderVersion: number
): Promise<void> => {
  await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/ordering/commands/delete-order-stopovers`,
    transformKeysToSnakeCase({ stopoverIds: stopIds, orderVersion })
  )
}

export const registerOrder = async (orderId: number): Promise<void> => {
  await executeTransition(orderId, STATE_TRANSITIONS.REGISTER)
}

export const lockCarrierForOrder = async (orderId: number): Promise<void> => {
  await executeTransition(orderId, STATE_TRANSITIONS.LOCK_CARRIER)
}

export const dispatchOrder = async (orderId: number): Promise<void> => {
  await executeTransition(orderId, STATE_TRANSITIONS.DISPATCH)
}

const extractUniqueErrorCodes = (errors: string): string => {
  const uniqueCodes = new Set<string>()
  JSON.parse(errors).forEach(
    (item: { code: ActionValidationErrorCodes; stop_execution_id: UUID }) =>
      uniqueCodes.add(item.code)
  )
  return Array.from(uniqueCodes).join(', ')
}

export const getEventProps = async ({
  featureFlag,
  orderId,
  error
}: {
  featureFlag: boolean
  orderId: number
  error?: EndTourEventErrorType
}) => {
  const order = await fetchOrder(orderId)
  const sennderRef = order.idForStaff
  const { isActive } = useFeatureFlag()

  const isTransitFacilityActionsEnabled = isActive('transit-facility-actions').value

  let props: EndTourEventType = {
    sennderRef,
    module: trackingModule,
    submodule: submodule,
    facilityActionRequired: isTransitFacilityActionsEnabled || featureFlag
  }

  if (error) {
    const { errorValues } = error
    const uniqueErrorCodes = extractUniqueErrorCodes(errorValues)
    error.errorValues = uniqueErrorCodes === '' ? 'Unknown api error' : uniqueErrorCodes

    props = { ...props, ...error }
  }

  return props
}

export const trackEndTourEvent = ({
  success,
  props
}: {
  success: boolean
  props: EndTourEventType
}) => {
  const event = success
    ? events.OCTOPUS_SYSTEM_END_TOUR_SUCCESS
    : events.OCTOPUS_SYSTEM_END_TOUR_FAIL
  trackEvent(event, props)
}

const getMarkAsExecutedErrorMessage = ({
  errors
}: {
  errors: { code: ActionValidationErrorCodes; stop_execution_id: UUID }[]
}): string => {
  let errorMessage = ''
  const timestampErrorCodes = [
    ActionValidationErrorCodes.ARRIVAL_TIMESTAMP_MISSING,
    ActionValidationErrorCodes.DEPARTURE_TIMESTAMP_MISSING
  ]
  const hasTimestampError = errors.some(err => timestampErrorCodes.includes(err.code))
  const hasFacilityActionError = errors.some(
    err => !timestampErrorCodes.includes(err.code)
  )

  if (hasTimestampError) {
    errorMessage = END_TOUR_VALIDATION_ERROR.message.timestamps
  } else if (hasFacilityActionError) {
    errorMessage = END_TOUR_VALIDATION_ERROR.message.facility_actions
  }
  return errorMessage
}

const handleMarkAsExecutedError = ({
  error,
  isExecutionBoard
}: {
  error: Error
  isExecutionBoard: boolean
}) => {
  try {
    const errorContent = JSON.parse(error.message)
    const alertModal = AlertModal(Swal)
    const { error_code, errors, detail } = errorContent
    if (error_code === END_TOUR_VALIDATION_ERROR.code) {
      if (isExecutionBoard) {
        alertModal.showErrorMessage('Error', getMarkAsExecutedErrorMessage({ errors }))
      }
    } else {
      alertModal.showErrorMessage('Error', detail)
    }
  } catch (parseError) {
    logger.error('Failed to parse error message:', parseError)
  }
}

export const closeOrderExecution = async ({
  orderId,
  isExecutionBoard = false
}: {
  orderId: number
  isExecutionBoard: boolean
}): Promise<boolean> => {
  let canCallMothership = true
  let isExecuted = false
  const { isActive } = useFeatureFlag()
  const canUseShipmentExecuted = isActive('shipment-executed')

  if (canUseShipmentExecuted.value && isSennderTheme) {
    const response = (await fetchShipmentByOrderId(orderId)) as Record<
      string,
      Array<{ [key: string]: string }>
    >
    const shipmentId = response?.shipments[0]?.id || null

    if (shipmentId) {
      const tourExecution = await getTourExecution(shipmentId)
      const { facilityActionsCollected } = tourExecution
      if (tourExecution?.status === TourExecutionStatus.IN_TRANSIT) {
        canCallMothership = false
        try {
          await markAsExecuted(tourExecution.id)
          isExecuted = true
          if (!isExecutionBoard) {
            const successEventProps = await getEventProps({
              featureFlag: facilityActionsCollected,
              orderId
            })
            trackEndTourEvent({ success: true, props: successEventProps })
          }
        } catch (error) {
          logger.error(
            `Transit error: Unable to execute shipment ID: ${shipmentId} error: ${error}`
          )
          handleMarkAsExecutedError({
            error,
            isExecutionBoard
          })
          if (!isExecutionBoard) {
            const errorResponse = error.response
              ? error.response
              : { status: null, data: { errors: [] } }
            const { status, data } = errorResponse
            const failEventsProps = await getEventProps({
              featureFlag: facilityActionsCollected,
              orderId,
              error: {
                errorSource,
                errorValues: JSON.stringify(data.errors),
                httpStatusCode: status
              }
            })
            trackEndTourEvent({ success: false, props: failEventsProps })
          }
        }
      }
    }
  }

  if (canCallMothership) {
    await executeTransition(orderId, STATE_TRANSITIONS.EXECUTE)
    isExecuted = true
  }
  return isExecuted
}

export const completeOrderOperations = async (orderId: number): Promise<void> => {
  await executeTransition(orderId, STATE_TRANSITIONS.COMPLETE_OPERATIONS)
}

export const fetchShipmentByOrderId = async (
  orderId: number
): Promise<boolean | Record<string, Array<{ [key: string]: string }>>> => {
  try {
    const response = (await runJsonGet(
      `${SHIPMENT_API_URL}/v2/shipments?meta.order_id=${orderId}`,
      null,
      {
        apiGatewayAuthorization: true
      },
      'https://api.cloud.sennder.com/shipments',
      'shipments:read'
    )) as AxiosResponse<unknown>
    return transformKeysToCamelCase<boolean>(response.data)
  } catch (error) {
    logger.error(`Shipment service failed for order_id: ${orderId} ${error}`)
  }
}

export const fetchShipperProfile = async (
  shipperId: string = null
): Promise<Array<IShipperProfileResponse>> => {
  const response = (await runJsonGet(
    `${SHIPPER_PROFILE_API_URL}/api/shippers?mothership_id=${shipperId}`,
    null,
    {
      apiGatewayAuthorization: true
    },
    'https://api.cloud.sennder.com/shipper-profile',
    'shipper-profile:read'
  )) as AxiosResponse<unknown>

  return transformKeysToCamelCase<Array<IShipperProfileResponse>>(response.data)
}

// TODO: use new carrier service kpi endpoint if it's provided (is it being tracked by carrier or ordering service)
export const fetchCarriersKpiPost = async (
  orderId: number,
  carrierIds: number[],
  customerId: number,
  originCompanyAddressId: number,
  destinationCompanyAddressId: number
): Promise<CarrierKpi[]> => {
  // TODO: add legacy id fetch by UUID

  const response = (await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/get-carrier-kpis`,
    transformKeysToSnakeCase({
      orderId,
      carrierIds,
      customerId,
      originCompanyAddressId,
      destinationCompanyAddressId
    }),
    { arrayFormat: 'repeat' }
  )) as AxiosResponse<unknown>
  return transformKeysToCamelCase<CarrierKpi[]>(response.data)
}

export const fetchCarriersKpi = async (
  orderId: number,
  carrierIds: number[],
  customerId: number,
  originCompanyAddressId: number,
  destinationCompanyAddressId: number
): Promise<CarrierKpi[]> => {
  // TODO: add legacy id fetch by UUID
  const response = (await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/get-carrier-kpis`,
    transformKeysToSnakeCase({
      orderId,
      carrierIds,
      customerId,
      originCompanyAddressId,
      destinationCompanyAddressId
    }),
    { arrayFormat: 'repeat' }
  )) as AxiosResponse<unknown>
  return transformKeysToCamelCase<CarrierKpi[]>(response.data)
}

// TODO: ask who manages it, ordering or carrier service
export const fetchCarrierTrackingRate = async (
  carrierId: number
): Promise<CarrierTrackingRate> => {
  // TODO: get legacy id using uuid
  return (
    (await runJsonGet(
      `${OPERATIONS_BACKEND_URL}/ordering-carrier/${carrierId}/queries/tracking-rate`
    )) as AxiosResponse<CarrierTrackingRate>
  ).data
}

export const executeTransition = async (
  orderId: number,
  state: OrderStateTransition
): Promise<void> => {
  await runJsonPut(
    `${OPERATIONS_BACKEND_URL}/ordering/orders/${orderId}/do_transition/${state}/`
  )
}

export const fetchTransferById = async (transferId: number): Promise<TransferDetail> => {
  const response = (await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/infrastructure/queries/get-transfer`,
    transformKeysToSnakeCase({ transferId })
  )) as AxiosResponse<unknown>
  return transformKeysToCamelCase<TransferDetail>(response.data)
}

export const deleteOrder = async (orderId: number): Promise<void> => {
  await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/ordering/commands/delete-order`,
    transformKeysToSnakeCase({ orderId })
  )
}

export const calculatePrice = async (
  lineCode: string,
  stops: OrderStop[],
  vehicleTypes: string[]
): Promise<CalculatedPrice> => {
  const response = (await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/order-templates/calculate-price`,
    transformKeysToSnakeCase({ lineCode, stops, vehicleTypes })
  )) as AxiosResponse<unknown>
  return transformKeysToCamelCase(response.data)
}

export const calculateCost = async (
  lineCode: string,
  stops: OrderStop[],
  vehicleTypes: string[]
): Promise<CalculatedPrice> => {
  const response = (await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/order-templates/calculate-cost`,
    transformKeysToSnakeCase({ lineCode, stops, vehicleTypes })
  )) as AxiosResponse<unknown>
  return transformKeysToCamelCase(response.data)
}

export const cancelOpsCompletedOrder = async (id: number): Promise<void> => {
  await runJsonPut(
    `${OPERATIONS_BACKEND_URL}/ordering/orders/${id}/cancel_completed_order`,
    {}
  )
}

export const cancelNotOpsCompletedOrder = async (id: number): Promise<void> => {
  await runJsonPut(
    `${OPERATIONS_BACKEND_URL}/ordering/orders/${id}/do_transition/cancel/`,
    {}
  )
}

export const fetchOrderId = async (
  orderGroupId: number,
  letter: string
): Promise<number> => {
  const response = (await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/get-order-id/${orderGroupId}${letter}`
  )) as AxiosResponse<unknown>
  return transformKeysToCamelCase<{ orderId: number }>(response.data).orderId
}

export const updateOrderCustomerRequirements = async (
  order: Partial<OrderDetail>,
  stops: OrderStop[]
): Promise<void> => {
  await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/ordering/commands/update-order-customer-requirements`,
    transformKeysToSnakeCase({ order, stopovers: stops.map(transformOutboundStop) })
  )
}

export const updateDispatchedOrder = async (
  order: Partial<OrderDetail>,
  stops: OrderStop[]
): Promise<void> => {
  await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/ordering/commands/edit-order-details-after-dispatched`,
    transformKeysToSnakeCase({
      orderId: order.id,
      orderReferenceNumber: order.referenceNumber,
      stopovers: stops.map(stop => ({
        id: stop.id,
        referenceNumber: stop.referenceNumber
      }))
    })
  )
}

export const undispatch = async (orderId: number): Promise<void> => {
  await runJsonPut(
    `${OPERATIONS_BACKEND_URL}/ordering/orders/${orderId}/do_transition/undispatch/`
  )
}

export const getOrderRouteDetails = async (
  orderId: number
): Promise<OrderDistanceTransitTime[]> => {
  try {
    const response = (await runJsonGet(
      `${OPERATIONS_BACKEND_URL}/ordering/queries/get-order-distance-transit-time`,
      { order_id: orderId }
    )) as AxiosResponse<unknown>
    return transformKeysToCamelCase<OrderDistanceTransitTime[]>(response.data)
  } catch (e) {
    if (e.response.status === 404) return []
    throw e
  }
}

export const getHereMapsParameters = async (): Promise<HereMapsParameters> => {
  const response = (await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/get-here-maps-parameters`
  )) as AxiosResponse<HereMapsParameters>
  return response.data
}

export const getContractTypes = async (): Promise<{ [key: string]: string }> => {
  const response = (await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/get-contract-type-options`
  )) as AxiosResponse<{ [key: string]: string }>
  return response.data
}

export const setImportantDispatchOrder = async (
  orderId: number,
  importantDispatch: boolean
): Promise<void> => {
  const action = importantDispatch ? 'flag' : 'unflag'
  await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/ordering/commands/${action}-important-order/${orderId}`
  )
}

export const saveCreditNotesSentStatus = (orderIds, isBilled) => {
  const data = orderIds.reduce((acc, id) => {
    acc[id] = isBilled
    return acc
  }, {})
  return runJsonPost(
    OPERATIONS_BACKEND_URL + '/ordering/orders/credit_note_was_sent',
    data
  )
}

export const fetchFilteredOrders = async (filters, transaction) => {
  const response = await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/filtering/queries/finance-board`,
    { filter_dictionary: filters },
    { transaction }
  )
  return response
}

<template>
  <div class="price-history-chart">
    <GChart
      ref="chart"
      type="ScatterChart"
      :data="chartData"
      :options="chartOptions"
      :events="chartEvents"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { DATE_FORMAT } from '@/global-setup/filters'
import { GChart } from 'vue-google-charts'
import { trackEvent } from '@/analytics/amplitude'

export default {
  components: {
    GChart
  },
  props: {
    history: { type: Array, required: false, default: () => [] }
  },
  computed: {
    chartData() {
      const dateString = this.$t('ordering/shared-date')
      const priceString = this.$t('ordering/shared-price')
      return [
        [dateString, priceString, { type: 'string', role: 'tooltip' }],
        ...this.history.map(entry => [
          new Date(entry.date),
          entry.price,
          // TODO: Unclear if this needs translation or not...
          `${moment(entry.date).format(DATE_FORMAT)} \u20AC${entry.price}`
        ])
      ]
    },
    chartOptions() {
      const minPrice = this.history.reduce((acc, entry) => Math.min(acc, entry.price), 0)
      const maxPrice = this.history.reduce((acc, entry) => Math.max(acc, entry.price), 0)
      const minDate =
        this.chartData.length > 1
          ? this.chartData[this.chartData.length - 1][0]
          : new Date()

      const ticks = this.history.length === 1 ? [new Date(this.history[0].date)] : 'auto'

      return {
        legend: { position: 'none' },
        width: '100%',
        height: '250px',
        hAxis: {
          baseline: minDate,
          baselineColor: 'transparent',
          format: 'MMM `dd',
          textStyle: { fontName: 'roboto', fontSize: 10, color: '#888' },
          gridlines: { count: -1, color: 'transparent' },
          ticks
        },
        vAxis: {
          minValue: minPrice,
          maxValue: maxPrice + (maxPrice - minPrice) / 2,
          format: '\u20AC#.##',
          baseline: 0,
          baselineColor: '#E2E2E2',
          textStyle: { fontName: 'roboto', fontSize: 10, color: '#888' },
          gridlines: { count: -1, color: 'transparent' }
        },
        timeline: {
          groupByRowLabel: true
        }
      }
    },
    chartEvents() {
      return {
        select: () => {
          trackEvent('octopus/carrier-suggestion/click/cost-graph')
          const selection = this.$refs.chart.chartObject.getSelection()
          if (selection.length > 0) {
            this.$emit('selected', selection[0].row)
          } else {
            this.$emit('selected', null)
          }
        }
      }
    }
  }
}
</script>

import { loadCarrierAssignmentsDetails } from '@/services/order-assignment-service'
import { ref, Ref } from '@vue/composition-api'
import { OrderAssignmentDetail } from '@/services'

const orderAssignments: Ref<OrderAssignmentDetail[]> = ref([])

export default function useAssignmentDetails() {
  const loadAssignmentsForStoreOrders = async orderIds => {
    if (orderIds.length === 0) return

    const carrierAssignmentsDetails = await loadCarrierAssignmentsDetails(orderIds)

    if (carrierAssignmentsDetails) {
      orderAssignments.value = carrierAssignmentsDetails
    }
  }

  const lastAssignmentForOrder = order => {
    const assignmentsList = orderAssignments.value.filter(o => o.orderId === order.id)

    if (assignmentsList.length > 0) {
      const lastAssignment = assignmentsList.reduce((a: OrderAssignmentDetail, b) => {
        if (!a) return b
        return new Date(a.created) > new Date(b.created) ? a : b
      }, null)
      return lastAssignment
    }
    return null
  }

  return {
    loadAssignmentsForStoreOrders,
    lastAssignmentForOrder
  }
}

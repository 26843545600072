export interface Contact {
  id: string
  name: string
  fullName: string
  title: string
  firstName: string
  lastName: string
  email: string
  phone: string
}

export interface ContactResponse {
  id: string
  salutation: string
  firstName: string
  lastName: string
  email: string
  phone: string
  mobilePhone: string
  owner: string
  notes: string
}

export const mapContactResponseToContact = (contact: ContactResponse): Contact => ({
  id: contact.id,
  name: `${contact.firstName || ''} ${contact.lastName || ''}`,
  // backwards compatibility
  fullName: `${contact.firstName || ''} ${contact.lastName || ''}`,
  email: contact.email,
  phone: contact.phone,
  firstName: contact.firstName,
  lastName: contact.lastName,
  title: contact.salutation
})

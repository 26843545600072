<template>
  <DropdownField
    :options="contacts"
    :placeholder="placeholder"
    :title="titleString"
    :validators="fieldValidators"
    v-bind="$attrs"
    optionLabel="name"
    optionKey="id"
    searchable
    class="fullwidth-dropdown customer-contact-field"
    data-test="contact-person-dropdown"
    @select="$emit('select', $event)"
  />
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import { fetchCustomerContacts } from '@/services/customer-service'

export default {
  model: { prop: 'value', event: 'select' },
  props: {
    customerId: { type: Number, required: false, default: null },
    placeholder: { type: String, default: 'Select contact' },
    title: { type: String, default: 'Contact person' },
    validators: { type: Array, default: () => [] },
    required: { type: Boolean, default: false },
    inlineError: { type: Boolean, default: false }
  },
  data() {
    return {
      contacts: []
    }
  },
  computed: {
    titleString() {
      return this.required ? this.title + '*' : this.title
    }
  },
  watch: {
    async customerId(newCustomerId, oldCustomerId) {
      if (newCustomerId && oldCustomerId !== newCustomerId) {
        this.$emit('input', null)
        this.contacts = await fetchCustomerContacts(newCustomerId)
      } else if (newCustomerId === null) {
        this.contacts = []
        this.$emit('input', null)
      }
    }
  },
  created() {
    this.fieldValidators = [...this.validators]
    if (this.required) {
      this.fieldValidators.push(this.getRequiredValidator())
    }
  },
  async mounted() {
    if (this.customerId !== null) {
      this.contacts = await fetchCustomerContacts(this.customerId)
    }
  },
  methods: {
    getRequiredValidator() {
      return {
        isInvalid: isEmpty,
        errorMessage: this.inlineError
          ? 'This field cannot be empty'
          : 'Customer contact cannot be empty',
        inline: this.inlineError
      }
    }
  }
}
</script>

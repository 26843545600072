import Model from '@/models/model'

export default class ChargeCorrection extends Model {
  get requiredFields() {
    return ['correction_amount']
  }
}

export class ExtraChargeCorrection extends ChargeCorrection {
  constructor(data) {
    super(data)
  }

  get requiredFields() {
    // extra_charge_type can be 'CUSTOMER' or 'CARRIER'
    return ['extra_charge_id', 'extra_charge_type']
  }
}

import moment from 'moment-timezone'
import { StopLocation, TrackingLocation } from '../../../types'
import { computed, Ref } from '@vue/composition-api'
import H, { ui as HereUi, map as HereMap } from '@here/maps-api-for-javascript'
import { getTimezoneAcronym, TIME_FORMAT } from '@/global-setup/filters'

const formatDate = (isoTimestamp: string, timezone: string): string =>
  moment.tz(isoTimestamp, timezone).format(`DD.MM.YY`)

const formatTime = (isoTimestamp: string, timezone: string): string =>
  moment.tz(isoTimestamp, timezone).format(`${TIME_FORMAT}`)

export default (
  trackingLocations: Ref<TrackingLocation[]>,
  licensePlate: Ref<string>,
  stopLocations: Ref<StopLocation[]>,
  multipleStopLetters: Ref<string[]>,
  ui: HereUi.UI
) => {
  const getMarkerIcon = (rotation: number, onClick: (event: Event) => void) => {
    const customMarker = `<div>
          <img class="location-icon" src="../images/ping_arrow.svg" style="transform: rotate(${rotation}deg)">
          </div>`

    const icon = new H.map.DomIcon(customMarker, {
      onAttach(clonedElement) {
        clonedElement?.addEventListener('click', onClick)
      },
      onDetach(clonedElement) {
        clonedElement?.removeEventListener('click', onClick)
      }
    })
    return icon
  }

  const hasSameTzAcronymStops = computed(() => {
    const tzList = [] as string[]
    stopLocations.value.forEach(stopLocation => {
      trackingLocations.value.forEach(trackingLocation => {
        tzList.push(getTimezoneAcronym(trackingLocation.time, stopLocation.timezone))
      })
    })
    return new Set(tzList).size === 1
  })

  const getTruckIcon = () => {
    const customMarker =
      '<div><img class="location-truck-icon" src="../images/truck.svg"></div>'

    const icon = new H.map.DomIcon(customMarker)
    return icon
  }

  const createRouteMarker = (
    location: TrackingLocation,
    isLastIcon: boolean,
    ui: HereUi.UI,
    hasSameTzAcronymStops: boolean
  ) => {
    let marker: HereMap.DomMarker | null = null
    let iconElement: HTMLElement | null = null
    const coords = {
      lng: location.lng,
      lat: location.lat
    }
    if (isLastIcon) {
      marker = new H.map.DomMarker(coords, { data: null, icon: getTruckIcon() })
    } else {
      marker = new H.map.DomMarker(coords, {
        data: null,
        icon: getMarkerIcon(location.rotation, event => {
          if (event.target instanceof HTMLElement) {
            event.target.style.width = '30px'
            iconElement = event.target
          }
        })
      })
    }

    let template = ''
    if (hasSameTzAcronymStops) {
      const commonTimezone = stopLocations.value[0].timezone
      template = `<div class="bubble">
        <div class="bubble-row">Vehicle license plate: ${licensePlate.value}</div>
        <div class="bubble-row">
          <table><tbody>
            <tr class="table-pad">
              <td>${formatDate(location.time, commonTimezone)}</td>
              <td>${formatTime(location.time, commonTimezone)}</td>
              <td>${getTimezoneAcronym(location.time, commonTimezone)}</td>
            </tr>
          </tbody></table>
        </div>
      </div>`
    } else {
      let stopListsRow = ``
      stopLocations.value.forEach((stopLocation, index) => {
        const alphabet = multipleStopLetters.value[index]
        stopListsRow += `<tr class="table-pad">
          <td>${formatDate(location.time, stopLocation.timezone)}</td>
          <td>${formatTime(location.time, stopLocation.timezone)}</td>
          <td>${getTimezoneAcronym(location.time, stopLocation.timezone)}</td>
          <td>(Stop ${alphabet} local time)</td>
        </tr>`
      })
      template = `<div class="bubble">
            <div class="bubble-row">Vehicle license plate: ${licensePlate.value}</div>
            <div class="bubble-row">
            <table >
              <tbody>
                ${stopListsRow}
              </tbody>
            </table>
            </div>
          </div>`
    }

    marker.setData(template)
    marker.addEventListener('tap', (event: { target: HereMap.DomMarker }) => {
      const bubble = new H.ui.InfoBubble(coords, {
        content: event.target.getData()
      })

      bubble.addEventListener('statechange', (evt: { target: HereUi.InfoBubble }) => {
        if (evt.target.getState() === H.ui.InfoBubble.State.CLOSED && iconElement) {
          iconElement.style.width = '16px'
        }
      })

      ui.getBubbles()?.forEach(bub => {
        if (bub) {
          bub.close()
          ui.removeBubble(bub)
        }
      })
      ui.addBubble(bubble)
    })

    return marker
  }

  const routeMarkers = computed(() =>
    trackingLocations.value.map((location, index) =>
      createRouteMarker(
        location,
        index === trackingLocations.value.length - 1,
        ui,
        hasSameTzAcronymStops.value
      )
    )
  )

  return routeMarkers
}

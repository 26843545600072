import { getTransferInExecution, updateSteps } from '@/services/infrastructure-service'
import { ref, computed, onMounted } from '@vue/composition-api'
import { TransferLocation, TransferStep, TransferStopover } from '@/services'
import useTransit from '@/compositions/transit/useTransit'

export default (transferId: number, useShipmentFlow = false) => {
  const steps = ref<TransferStep[]>([])
  let originalSteps = <TransferStep[]>[]

  const stops = ref<TransferStopover[]>([])

  const locations = ref<TransferLocation[]>([])

  const assignedTrackers = ref<string[]>([])

  const delayMinutes = ref<number | null>(null)

  let version = 0

  const isDataLoaded = computed<boolean>(() => {
    const stopsLoaded = stops.value.length > 0
    const stepsLoaded = steps.value.length === stops.value.length * 2
    return stopsLoaded && stepsLoaded
  })

  const mapDefaultStepState = (listOfSteps: TransferStep[]): TransferStep[] => {
    return listOfSteps.map((step: TransferStep) => ({
      ...step,
      wasSet: Boolean(step.actualTime),
      hasUnsavedActualTime: false,
      hasUnsavedStepDone: false
    }))
  }

  const loadTransfer = async (): Promise<void> => {
    const response = await getTransferInExecution(transferId)
    steps.value = mapDefaultStepState(response.steps)
    originalSteps = steps.value

    version = response.version
    delayMinutes.value = response.delay
    stops.value = response.stops
    locations.value = response.locations

    assignedTrackers.value =
      response.assignedTrackers?.map(tracker => tracker.trackerIdentifier) || []
  }

  const saveSteps = async (): Promise<void> => {
    const updatedSteps = steps.value.map((step: TransferStep) => {
      return {
        id: step.id,
        actualTime: step.actualTime,
        isDone: step.isDone,
        delayReason: null
      }
    })

    if (useShipmentFlow) {
      const { saveStopExecutions } = useTransit()
      await saveStopExecutions(
        steps.value,
        transferId,
        version,
        updatedSteps,
        originalSteps
      )
    } else {
      await updateSteps(transferId, version, updatedSteps)
    }
    await loadTransfer()
  }

  onMounted(loadTransfer)

  return {
    isDataLoaded,
    steps,
    stops,
    locations,
    assignedTrackers,
    delayMinutes,
    loadTransfer,
    saveSteps
  }
}

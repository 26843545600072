<template>
  <div class="next-load-details" v-if="load.order">
    <LocationAndTime
      :countryCode="load.order.originCountryCode"
      :postalCode="load.order.originPostalCode"
      :startDate="load.order.pickupStart"
      :endDate="load.order.pickupEnd"
    />
    <Arrow :width="100">
      <template #above>
        <span v-if="load.order.distance > 0">
          {{ Math.round(load.order.distance / 1000) }} km
        </span>
      </template>
      <template #below>
        <span v-if="load.order.stops.aggregate.count > 2">
          + {{ load.order.stops.aggregate.count - 2 }} stop(s)
        </span>
      </template>
    </Arrow>
    <LocationAndTime
      :countryCode="load.order.destinationCountryCode"
      :postalCode="load.order.destinationPostalCode"
      :startDate="load.order.deliveryStart"
      :endDate="load.order.deliveryEnd"
    />
    <div class="price">
      <div class="value">
        {{ load.order.basePrice | formatMoneyWithDashForNull }}
      </div>
      <div class="label">Net price</div>
    </div>
    <div class="navigate">
      <img src="./go-to-next-load.svg" />
    </div>
  </div>
</template>

<script>
import Arrow from './Arrow.vue'
import LocationAndTime from './LocationAndTime.vue'
import { formatMoneyWithDashForNull } from '@/global-setup/filters'

export default {
  components: {
    Arrow,
    LocationAndTime
  },
  filters: { formatMoneyWithDashForNull },
  props: {
    load: { type: Object, default: () => ({}) }
  }
}
</script>

<style lang="scss" scoped>
.next-load-details {
  display: flex;
  gap: 16px;
  margin-left: 16px;
  border: solid 1px $color-layout-card-background;
  box-shadow: 0 2px 8px 0 rgba(75, 75, 75, 0.15);
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 8px 0 rgba(75, 75, 75, 0.25);
  }
}

.price {
  display: flex;
  flex-direction: column;
  margin: auto;

  .value {
    font-weight: bold;
  }

  .label {
    color: $color-neutral-main;
  }
}

.navigate {
  width: 24px;
  display: flex;
  justify-content: flex-end;
  margin: auto;
  margin-right: 16px;
}
</style>

<template>
  <TypeAheadField
    :options="options"
    :placeholder="
      placeholder || $t('components/customer-type-ahead-field-default-placeholder')
    "
    :internalSearch="internalSearch"
    :title="titleString"
    :validators="fieldValidators"
    v-bind="$attrs"
    optionLabel="name"
    optionKey="id"
    data-test="typeahead-field"
    class="fullwidth-dropdown"
    v-on="$listeners"
    @fetchFilterOptions="onFetchFilterOptions"
    @clearFilterOptions="onClearFilterOptions"
  />
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import {
  fetchCustomersBySearchText,
  fetchClosedLiveCustomersBySearchText
} from '@/services/customer-service'

export default {
  props: {
    placeholder: { type: String, default: null },
    title: { type: String, default: null },
    validators: { type: Array, default: () => [] },
    required: { type: Boolean, default: false },
    closedLiveOnly: { type: Boolean, default: false },
    inlineError: { type: Boolean, default: false }
  },
  data() {
    return { options: [], internalSearch: false }
  },
  computed: {
    defaultTitle() {
      return this.title || this.$t('components/customer-type-ahead-field-default-title')
    },
    titleString() {
      return this.required ? this.defaultTitle + '*' : this.defaultTitle
    }
  },
  created() {
    this.fieldValidators = [...this.validators]
    if (this.required) {
      this.fieldValidators.push(this.getRequiredValidator())
    }
  },
  methods: {
    async onFetchFilterOptions(query) {
      if (this.closedLiveOnly) {
        this.options = await fetchClosedLiveCustomersBySearchText(query)
      } else {
        this.options = await fetchCustomersBySearchText(query)
      }
    },
    onClearFilterOptions() {
      this.options = []
    },
    getRequiredValidator() {
      return {
        isInvalid: isEmpty,
        errorMessage: this.inlineError
          ? this.$t('components/customer-type-ahead-field-required-company-msg-inline')
          : this.$t('components/customer-type-ahead-field-required-company-msg'),
        inline: this.inlineError
      }
    }
  }
}
</script>

<template>
  <NumericInputField
    v-model="filterHandler"
    :allowInteger="allowInteger"
    :allowDecimal="allowDecimal"
    v-bind="$attrs"
    fullWidth
  />
</template>

<script>
export default {
  inject: ['getFilterValue', 'setFilterValue'],
  props: {
    filterName: { type: String, required: true },
    transformer: { type: Function, default: null },
    allowDecimal: { type: Number, default: 2 },
    allowInteger: { type: Number, default: 3 },
    maxValue: { type: Number, default: Infinity }
  },
  computed: {
    filterHandler: {
      get() {
        if (this.getFilterValue(this.filterName) !== null) {
          return parseFloat(this.getFilterValue(this.filterName))
        } else {
          return null
        }
      },
      set(value) {
        if (value > this.maxValue) {
          value = this.maxValue - 1
        }
        // The user input transformation function is executed if possible
        if (typeof this.transformer === 'function') {
          value = this.transformer(value)
        }
        this.setFilterValue(this.filterName, value)
      }
    }
  }
}
</script>

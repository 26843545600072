import { VEHICLE_TYPES } from '@sennder/octopus-constants'
import { enabledOrderStatesFor } from '@/models/order-edition-rules'
import Person from '@/models/person'
import {
  CarrierFinancialBreakdown,
  CustomerFinancialBreakdown
} from '@/models/stakeholder-financial-breakdown'
import filter from 'lodash/filter'
import _ from 'lodash'
import {
  formatNullInteger,
  formatWithThousands,
  humanizeIsoDate,
  humanizeIsoDatetime,
  humanizeIsoTime,
  toSortableDateString
} from '@sennder/plankton'
import { OPERATIONS_BACKEND_URL } from '../config'
import Model from './model'
import { cleanLicensePlate } from '@/modules/common/filters/license-plate'
import { i18n } from '@/plugins/i18n'
import Transfer from '@/models/transfer'

export default class Order extends Model {
  constructor(params, state) {
    super(params, state)
    this.customerFinancialBreakdown = new CustomerFinancialBreakdown(this)
    this.carrierFinancialBreakdown = new CarrierFinancialBreakdown(this)
  }

  static get NAME() {
    return 'orders'
  }

  static get URL() {
    return `${OPERATIONS_BACKEND_URL}/ordering/orders/`
  }

  static get LOAD_UNIT_TYPES() {
    return {
      Europallets: 'Europallets 1200x800',
      Industrial_pallets: 'Industrial pallets 1200x1000',
      Colli: 'Colli',
      Cage_Pallets: 'Cage Pallets',
      Parcels: 'Parcels',
      Duesseldorfer_pallets: 'Düsseldorfer pallets (800x600)',
      Chep_pallets: 'Chep pallets'
    }
  }

  static get STATES() {
    return {
      NEW: 'NEW',
      REGISTERED: 'REGISTERED',
      UNREGISTERED: 'UNREGISTERED',
      CARRIER_LOCKED: 'CARRIER_LOCKED',
      DISPATCHED: 'DISPATCHED',
      EXECUTED: 'EXECUTED',
      OPERATIONS_COMPLETED: 'OPERATIONS_COMPLETED',
      COMPLETED: 'COMPLETED'
    }
  }

  static get STAGES_BY_STATES() {
    return {
      NEW: 'registration',
      REGISTERED: 'dispatching',
      CARRIER_LOCKED: 'dispatching/direct',
      DISPATCHED: 'execution',
      EXECUTED: 'closing',
      OPERATIONS_COMPLETED: 'closing',
      COMPLETED: 'closing'
    }
  }

  static get STATES_HUMAN() {
    return {
      NEW: i18n.t('models/order-state-new'),
      REGISTERED: i18n.t('models/order-state-registered'),
      UNREGISTERED: i18n.t('models/order-state-unregistered'),
      CARRIER_LOCKED: i18n.t('models/order-state-carrier-locked'),
      DISPATCHED: i18n.t('models/order-state-dispatched'),
      EXECUTED: i18n.t('models/order-state-executed'),
      OPERATIONS_COMPLETED: i18n.t('models/order-state-ops-completed'),
      COMPLETED: i18n.t('models/order-state-completed')
    }
  }

  static get STATES_HUMAN_SHORT() {
    return {
      NEW: 'NE',
      REGISTERED: 'RE',
      UNREGISTERED: 'UN',
      CARRIER_LOCKED: 'CL',
      DISPATCHED: 'DI',
      EXECUTED: 'EX',
      OPERATIONS_COMPLETED: 'OC',
      COMPLETED: 'CO'
    }
  }

  static get DISPATCHING_SUB_STATUSES() {
    return {
      TO_BE_DISPATCHED: 'To be dispatched',
      BEING_DISPATCHED: 'Being dispatched',
      UNCONFIRMED_DISPATCH: 'Unconfirmed dispatch'
    }
  }

  static get LOCKED_STATES() {
    return Object.values(this.STATES).filter(
      state =>
        state !== this.STATES.NEW &&
        state !== this.STATES.REGISTERED &&
        state !== this.STATES.UNREGISTERED
    )
  }

  static get ASSIGNMENT_STATES() {
    return {
      ACCEPTED: 'Accepted',
      PENDING: 'Pending',
      REJECTED: 'Rejected',
      EXPIRED: 'Expired',
      CANCELLED: 'Withdrawn'
    }
  }

  get originAddressId() {
    return (
      this.origin_company_address_id &&
      this.$state.company_addresses[this.origin_company_address_id]?.address.id
    )
  }

  get originAddress() {
    return this.$state.addresses != null
      ? this.$state.addresses[this.originAddressId]
      : undefined
  }

  get companyOriginAddress() {
    return (
      this.origin_company_address_id &&
      this.$state.company_addresses[this.origin_company_address_id]
    )
  }

  get originCountryCode() {
    return this.originAddress != null ? this.originAddress.country_code : undefined
  }

  get originPostalCode() {
    return this.originAddress != null ? this.originAddress.postal_code : undefined
  }

  get originLocality() {
    return this.originAddress != null ? this.originAddress.locality : undefined
  }

  get destinationAddressId() {
    return (
      this.destination_company_address_id &&
      this.$state.company_addresses[this.destination_company_address_id]?.address.id
    )
  }

  get destinationAddress() {
    return this.$state.addresses != null
      ? this.$state.addresses[this.destinationAddressId]
      : undefined
  }

  get companyDestinationAddress() {
    return this.$state.addresses != null
      ? this.$state.addresses[this.destinationAddressId]
      : undefined
  }

  get destinationCountryCode() {
    return this.destinationAddress != null
      ? this.destinationAddress.country_code
      : undefined
  }

  get destinationPostalCode() {
    return this.destinationAddress != null
      ? this.destinationAddress.postal_code
      : undefined
  }

  get destinationLocality() {
    return this.destinationAddress != null ? this.destinationAddress.locality : undefined
  }

  get customer_name() {
    return this.customer_company && this.customer_company.name
  }

  get customer_company() {
    return this.customer_company_id && this.$state.customers[this.customer_company_id]
  }

  get vehicle_type_human() {
    return VEHICLE_TYPES[this.vehicle_type]
  }

  get allowed_vehicle_types_human() {
    const types = this.allowed_vehicle_types
    return types
      .map(type => VEHICLE_TYPES[type])
      .map(type => {
        if (type === '40t Frigo' && this.temperature_indication)
          return `${type} ${this.temperature_indication}`
        return type
      })
      .sort()
      .join(', ')
  }

  get load_unit_type_human() {
    return Order.LOAD_UNIT_TYPES[this.load_unit_type]
  }

  get isTemperatureControlled() {
    return Boolean(this.is_temperature_controlled)
  }

  set isTemperatureControlled(value) {
    this.is_temperature_controlled = value
    this.loadMinimumTemperature = null
    this.loadMaximumTemperature = null
  }

  get loadMinimumTemperature() {
    return this.load_minimum_temperature
  }

  set loadMinimumTemperature(value) {
    this.load_minimum_temperature = value
  }

  get loadMaximumTemperature() {
    return this.load_maximum_temperature
  }

  set loadMaximumTemperature(value) {
    this.load_maximum_temperature = value
  }

  get pickup_start_date() {
    const pickup_date = this.pickup_start || this.pickup_end
    return pickup_date && humanizeIsoDate(pickup_date)
  }

  get carrier_pickup_start_date() {
    const carrier_pickup_date = this.carrier_pickup_start || this.carrier_pickup_end
    return carrier_pickup_date && humanizeIsoDate(carrier_pickup_date)
  }

  get formatted_pickup_start_date() {
    return toSortableDateString(this.pickup_start_date)
  }

  get pickup_start_time() {
    return this.pickup_start && humanizeIsoTime(this.pickup_start)
  }

  get pickup_end_time() {
    return this.pickup_end && humanizeIsoTime(this.pickup_end)
  }

  get delivery_start_date() {
    const delivery_date = this.delivery_start || this.delivery_end
    return delivery_date && humanizeIsoDate(delivery_date)
  }

  get formatted_delivery_start_date() {
    return toSortableDateString(this.delivery_start_date)
  }

  get delivery_start_time() {
    return this.delivery_start && humanizeIsoTime(this.delivery_start)
  }

  get delivery_end_time() {
    return this.delivery_end && humanizeIsoTime(this.delivery_end)
  }

  get pickupStartDateTime() {
    return humanizeIsoDatetime(this.pickup_start)
  }

  get pickupEndDateTime() {
    return humanizeIsoDatetime(this.pickup_end)
  }

  get deliveryStartDateTime() {
    return humanizeIsoDatetime(this.delivery_start)
  }

  get deliveryEndDateTime() {
    return humanizeIsoDatetime(this.delivery_end)
  }

  get carrier() {
    return this.carrier_company_id && this.$state.carriers[this.carrier_company_id]
  }

  get carrier_name() {
    return this.carrier != null ? this.carrier.name : undefined
  }

  get carrier_contact() {
    return this.carrier_contact_id && this.$state.contacts[this.carrier_contact_id]
  }

  get carrierContactEmail() {
    return this.carrier_contact != null ? this.carrier_contact.email : undefined
  }

  get state_human() {
    return Order.STATES_HUMAN[this.state]
  }

  get STATES_HUMAN_SHORT() {
    return Order.STATES_HUMAN_SHORT[this.state]
  }

  get stage() {
    return Order.STAGES_BY_STATES[this.state]
  }

  get priceCharge() {
    return this.$state.charges[this.base_price_charge_id]
  }

  get costCharge() {
    return this.$state.charges[this.base_cost_charge_id]
  }

  get extraCustomerCharges() {
    return filter(this.$state.customer_extra_charges, extra_charge => {
      return extra_charge.order_id === this.id
    })
  }

  get extraCarrierCharges() {
    return filter(this.$state.carrier_extra_charges, extra_charge => {
      return extra_charge.order_id === this.id
    })
  }

  get formattedTotalNetPrice() {
    return formatWithThousands(this.total_net_price)
  }

  get formattedTotalGrossPrice() {
    return formatWithThousands(this.total_gross_price)
  }

  get formattedTotalNetCost() {
    return formatWithThousands(this.total_net_cost)
  }

  get formattedTotalGrossCost() {
    return formatWithThousands(this.total_gross_cost)
  }

  get formatted_base_price_amount() {
    return formatWithThousands(this.base_price_amount)
  }

  get base_price_amount() {
    if (this.base_price) {
      return this.base_price
    } else {
      return 0.0
    }
  }

  get formattedBasePrice() {
    return formatNullInteger(this.base_price)
  }

  get formattedBaseCost() {
    return formatNullInteger(this.base_cost)
  }

  get base_cost_amount() {
    if (this.base_cost) {
      return this.base_cost
    } else {
      return 0
    }
  }

  get formatted_base_cost_amount() {
    return formatWithThousands(this.base_cost_amount)
  }

  get formatted_suggested_price() {
    return format_euros_rounded_without_decimals(this.suggested_price)
  }

  get hasProjectableRegularity() {
    return this.regularity === 'REGULAR'
  }

  get hasSpotRegularity() {
    return this.regularity === 'SPOT'
  }

  get isNotOperationsCompleted() {
    return this.state !== Order.STATES.OPERATIONS_COMPLETED
  }

  get isCancelledInRegisteredState() {
    return this.state_at_cancellation === Order.STATES.REGISTERED
  }

  get hasCarrierLockedState() {
    return this.state === Order.STATES.CARRIER_LOCKED
  }

  get isInRegisteredState() {
    return this.state === Order.STATES.REGISTERED
  }

  get isInExecutedState() {
    return this.state === Order.STATES.EXECUTED
  }

  get carrierVat() {
    if (this.carrier_vat_is_required) {
      return 19
    } else {
      return '-'
    }
  }

  get stageLink() {
    return `/ordering/${this.idForStaff}`
  }

  get stageLinkTestNewPage() {
    return `/ordering_page_test/${this.idForStaff}`
  }

  get financeDetailsPageLink() {
    return `/ordering/${this.idForStaff}`
  }

  get idForStaff() {
    return this.id_for_staff
  }

  //If we used "transfer" it would collide with the order's "transfer" field
  // that comes from the Rest API after any update
  get transferDetail() {
    return Transfer.getTransferDetail(this.transfer_id)
  }

  get partnerManager() {
    return Person.getPerson(this.transferOperatorId)
  }

  get accountManager() {
    return Person.getPerson(this.account_manager_id)
  }

  get juniorAccountManager() {
    return Person.getPerson(this.junior_account_manager_id)
  }

  get accountManagerFullName() {
    if (this.accountManager) {
      const { first_name, last_name } = this.accountManager
      if (!first_name && !last_name) return undefined
      return (first_name || '') + ' ' + (last_name || '')
    } else return null
  }

  get juniorAccountManagerFullName() {
    if (this.juniorAccountManager) {
      const { first_name, last_name } = this.juniorAccountManager
      if (!first_name && !last_name) return undefined
      return (first_name || '') + ' ' + (last_name || '')
    } else return null
  }

  get partnerManagerFullName() {
    if (this.partnerManager) {
      const { first_name, last_name } = this.partnerManager
      if (!first_name && !last_name) return undefined
      return (first_name || '') + ' ' + (last_name || '')
    } else return null
  }

  get driver() {
    if (this.transferDetail) {
      return Person.getPerson(this.transferDetail.driver_id)
    } else return null
  }

  get transferDriverId() {
    if (this.transferDetail) {
      return this.transferDetail.driver_id
    } else return null
  }
  set transferDriverId(driverId) {
    if (this.transferDetail) {
      this.transferDetail.driver_id = driverId
    }
  }

  get transferCode() {
    if (this.transferDetail) {
      return this.transferDetail.code
    } else return null
  }
  set transferCode(transferCode) {
    if (this.transferDetail) {
      this.transferDetail.code = transferCode
    }
  }

  get transferOperatorId() {
    if (this.transferDetail) {
      return this.transferDetail.operator_id
    } else return null
  }
  set transferOperatorId(userId) {
    if (this.transferDetail) {
      this.transferDetail.operator_id = userId
    }
  }

  get trailerLicensePlate() {
    if (this.transferDetail) {
      return this.transferDetail.trailer_license_plate
    } else return null
  }
  set trailerLicensePlate(plate) {
    if (this.transferDetail) {
      // removes every symbol that is not a letter or a digit
      const plateFormatted = cleanLicensePlate(plate)
      this.transferDetail.trailer_license_plate = plateFormatted
    }
  }

  get routeSummary() {
    if (!this.originAddress || !this.destinationAddress) {
      return 'sennder octopus'
    }
    return `${this.originAddress.country_code}-${this.originAddress.postal_code}->${this.destinationAddress.country_code}-${this.destinationAddress.postal_code}`
  }

  get trackers() {
    return this.transferDetail ? this.transferDetail.trackers : []
  }

  get loadingReference() {
    if (this.autofill_loading_and_unloading_reference) {
      return this.reference_number
    } else {
      return this.loading_reference
    }
  }

  set loadingReference(newValue) {
    this.loading_reference = newValue
  }

  get unloadingReference() {
    if (this.autofill_loading_and_unloading_reference) {
      return this.reference_number
    } else {
      return this.unloading_reference
    }
  }

  set unloadingReference(newValue) {
    this.unloading_reference = newValue
  }

  get not_deleted_transeu_loads_count() {
    return filter(this.$state.marketplace_loads, marketplace_load => {
      return (
        marketplace_load.order_id === this.id &&
        marketplace_load.marketplace === 'TRANSEU' &&
        !marketplace_load.is_removed_from_marketplace
      )
    }).length
  }

  get requirements() {
    const requirements_array = []
    if (this.needs_pallet_exchange) {
      requirements_array.push('Pallet Exchange')
    }

    if (this.arrival_notification) {
      requirements_array.push('Arrival Notice')
    }

    if (this.dock_loading) {
      requirements_array.push('Ramp Ability')
    }

    if (this.direct_transfer) {
      requirements_array.push('Do Not Reload')
    }

    if (this.sealable) {
      requirements_array.push('Seal')
    }

    if (this.side_loading) {
      requirements_array.push('Side Loading')
    }

    if (this.top_loading) {
      requirements_array.push('Top Loading')
    }

    if (this.code_xl) {
      requirements_array.push('Code XL')
    }

    if (requirements_array.length > 0) {
      return requirements_array.join(' | ')
    } else {
      return 'None'
    }
  }

  get specifications() {
    const specifications_array = []
    if (this.allowed_vehicle_types) {
      specifications_array.push(this.allowed_vehicle_types_human)
    } else {
      specifications_array.push('No Vehicle')
    }

    if (this.load_weight) {
      specifications_array.push(this.load_weight + ' ton(s)')
    } else {
      specifications_array.push('Weight not Specified')
    }

    specifications_array.push(this.initialize_dimensions())

    if (this.load_quantity) {
      const load_unit_string = this.load_unit_type_human || '(No unit specified)'
      specifications_array.push(this.load_quantity + ' ' + load_unit_string)
    } else {
      specifications_array.push('Quantity not Specified')
    }

    return specifications_array.join(' | ')
  }

  get carrierInvoices() {
    return this.filterBy(
      this.$state.carrier_invoices,
      invoice => invoice.order_id == this.id
    )
  }

  get transportRequests() {
    return filter(this.$state.transport_requests, transportRequest => {
      return transportRequest.order_id === this.id
    })
  }

  get assignments() {
    return filter(this.$state.order_assignments, assignment => {
      return assignment.order_id === this.id
    })
  }

  get lastAssignment() {
    if (this.assignments.length > 0) {
      return this.assignments.reduce((a, b) => {
        return new Date(a.created) > new Date(b.created) ? a : b
      }, this.assignments[0])
    } else return null
  }

  get pendingTransportRequests() {
    return filter(this.transportRequests, transportRequest => {
      return transportRequest.state === 'CREATED'
    })
  }

  get acceptedTransportRequests() {
    return filter(this.transportRequests, transportRequest => {
      return transportRequest.state === 'ACCEPTED'
    })
  }

  get isLastAssignmentExpired() {
    return this.lastAssignment && this.lastAssignment.state === 'EXPIRED'
  }

  get assignmentState() {
    return this.lastAssignment.state
  }

  get hasPendingAssignments() {
    return _.some(this.assignments, assignment => assignment.state === 'PENDING')
  }

  initialize_dimensions() {
    if (this.load_length || this.load_height || this.load_width) {
      const dimensions = []
      if (this.load_length) {
        dimensions.push(this.load_length)
      } else {
        dimensions.push('no length')
      }

      if (this.load_height) {
        dimensions.push(this.load_height)
      } else {
        dimensions.push('no height')
      }

      if (this.load_width) {
        dimensions.push(this.load_width)
      } else {
        dimensions.push('no width')
      }

      return Array.from(dimensions)
        .map(dimension => dimension + 'cm')
        .join(' x ')
    } else {
      return 'No Dimensions'
    }
  }

  toggleSelection() {
    return (this.selected = !this.selected)
  }

  toggleVisibility() {
    return (this.visible = !this.visible)
  }

  isEditable(stageStates) {
    if (!stageStates) {
      return false
    }
    return this.isEnabled(stageStates) && !this.is_cancelled
  }

  isEnabled(stageStates) {
    return Array.from(stageStates).includes(this.state)
  }

  // We bill be progressively replacing isEditable and isEnabled by disabled and editable
  // in the different order stage related components
  get editable() {
    return this.isEditable(enabledOrderStatesFor(this.$state.route.name))
  }

  get disabled() {
    return !this.enabled
  }

  get enabled() {
    return this.isEnabled(enabledOrderStatesFor(this.$state.route.name))
  }
}

var format_euros_rounded_without_decimals = function (amount) {
  if (!amount) {
    return 'n/a'
  }
  return `€ ${amount.toLocaleString('en', { maximumFractionDigits: 0 })}`
}

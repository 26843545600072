import { OrderStateTransition } from '@/services/'
import { i18n } from '@/plugins/i18n'

export const OFFER_LIST_REFRESH_RATE = 60000 // 1min in ms

export const MIN_IN_PROGRESS_ACTION_DURATION = 1000

export const ORDER_STATES = [
  'NEW',
  'REGISTERED',
  'CARRIER_LOCKED',
  'DISPATCHED',
  'EXECUTED',
  'OPERATIONS_COMPLETED'
]

export const REJECTION_REASONS = [
  {
    id: 'PRICE_TOO_HIGH',
    label: i18n.t('ordering/order-rejection-reasons-price-too-high')
  },
  {
    id: 'NOT_FITTING_THE_REQUIREMENTS',
    label: i18n.t('ordering/order-rejection-reasons-not-fitting-requirements')
  },
  { id: 'LOW_QUALITY', label: i18n.t('ordering/order-rejection-reasons-low-quality') },
  {
    id: 'DIDNT_SEE_OFFER_IN_TIME',
    label: i18n.t('ordering/order-rejection-reasons-didnt-see-offer-in-time')
  },
  {
    id: 'CANCELLED_TOUR',
    label: i18n.t('ordering/order-rejection-reasons-tour-cancelled')
  },
  {
    id: 'LOADING_UNLOADING_SPOT_CHANGED',
    label: i18n.t('ordering/order-rejection-reasons-loading-unloading-spot-change')
  },
  { id: 'OTHER', label: i18n.t('ordering/order-rejection-reasons-other') }
]

export const STOP_TYPES = [
  { label: 'Loading', value: 'LOADING' },
  { label: 'Unloading', value: 'UNLOADING' }
]

export const STATE_TRANSITIONS: { [key: string]: OrderStateTransition } = {
  REGISTER: 'register',
  UNREGISTER: 'unregister',
  LOCK_CARRIER: 'lock_carrier',
  REMOVE_CARRIER: 'remove_carrier',
  DISPATCH: 'dispatch',
  EXECUTE: 'execute',
  COMPLETE_OPERATIONS: 'complete_operations',
  COMPLETE: 'complete',
  UNDISPATCH: 'undispatch',
  CANCEL: 'cancel'
}

export const CARRIER_OFFER_STATES = {
  CREATED: 'CREATED',
  WITHDRAWN: 'WITHDRAWN',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED'
}

export const ASSIGNMENT_TYPES = {
  MANUAL: 'MANUAL',
  AUTOMATIC: 'AUTOMATIC'
}

export const DEFAULT_TIMEZONE = 'Europe/Berlin'

// TODO: Add translations to these status names
export const LOADING_STATUSES = [
  {
    id: 'ON_THE_WAY',
    name: 'On the way'
  },
  { id: 'LOADING_STARTED', name: 'Loading started' },
  { id: 'LOADING_COMPLETED', name: 'Loading completed' }
]

// TODO: Add translations to these status names
export const UNLOADING_STATUSES = [
  {
    id: 'ON_THE_WAY',
    name: 'On the way'
  },
  { id: 'UNLOADING_STARTED', name: 'Unloading started' },
  { id: 'UNLOADING_COMPLETED', name: 'Unloading completed' }
]

export const PALLET_EXCHANGE_TYPES = {
  NOT_REQUIRED: 'NOT_REQUIRED',
  REGULAR_PALLET_EXCHANGE: 'REGULAR_PALLET_EXCHANGE',
  DPL_VIRTUAL: 'DPL_VIRTUAL',
  DPL_STANDORT: 'DPL_STANDORT',
  NOT_FEASIBLE_FOR_CARRIER: 'NOT_FEASIBLE_FOR_CARRIER'
}

// TODO: Add translations to these pallet exchange names
export const PALLET_EXCHANGE_NAMES = {
  [PALLET_EXCHANGE_TYPES.NOT_REQUIRED]: 'Pallet exchange not required',
  [PALLET_EXCHANGE_TYPES.REGULAR_PALLET_EXCHANGE]: 'Regular pallet exchange',
  [PALLET_EXCHANGE_TYPES.DPL_VIRTUAL]: 'DPL virtual',
  [PALLET_EXCHANGE_TYPES.DPL_STANDORT]: 'DPL Standort',
  [PALLET_EXCHANGE_TYPES.NOT_FEASIBLE_FOR_CARRIER]:
    'Pallet exchange not feasible for carrier'
}

export const CARRIER_ASSIGNMENT_STATES = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  EXPIRED: 'EXPIRED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  NOT_ASSIGNED: 'NOT_ASSIGNED',
  NO_CAPACITY: 'NO_CAPACITY',
  CARRIER_BLACKLISTED: 'CARRIER_BLACKLISTED',
  DOMESTIC_CARRIER_RESTRICTED: 'DOMESTIC_CARRIER_RESTRICTED',
  AWAITING_MATCHING_CONFIRMATION: 'AWAITING_MATCHING_CONFIRMATION'
}

export const ORDER_SOURCE_OCTOPUS = 'OCTOPUS_MANUAL'

export const buildTransportRequestsQuery = (filters = '') => {
  return `transport_requests(state: "CREATED", ${filters}) {
    id
    price
    negotiated_price
    created
    state
    request_source
    carrier_id {
      name
      carrier_tier
    }
    carrier_contact_id {
      company_id
      first_name
      last_name
    }
    creator_id {
      first_name
      last_name
      email
      phone
      contact_id {
        carrier_id {
          name
        }
      }
    }
    order_id {
      account_manager_id
      junior_account_manager_id
      base_price
      base_cost
      delivery_start
      delivery_end
      letter
      pickup_start
      pickup_end
      reference_number
      state
      vehicle_type
      load_weight
      load_width
      load_height
      load_length
      load_quantity
      load_unit_type
      load_description
      needs_pallet_exchange
      arrival_notification
      dock_loading
      direct_transfer
      id_for_staff
      sealable
      side_loading
      top_loading
      code_xl
      transfer_id {
        code
        notes
        license_plate
        trailer_license_plate
        operator_id
      }
      urgent_dispatch
      carrier_contact_id {
          company_id
          first_name
          last_name
      }
      carrier_company_id {
        name
        contact_ids {
          company_id
          first_name
          last_name
        }
      }
      origin_company_address_id {
        company_id
        {
          name
        }
        country_code
        address_id {
          country_code
          country
          formatted
          locality
          postal_code
        }
      }
      destination_company_address_id {
        company_id
        {
          name
        }
        address_id {
          formatted
        }
      }
      customer_company_id {
        name
      }
    }
  }`
}

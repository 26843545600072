<!-- eslint-disable vue/no-v-html */ -->
<template>
  <BoardFooter>
    <template slot="footer-text-items">
      <div class="footer-text plankton-p">
        <div
          class="footer-item"
          v-html="
            $t('finance-board/finance-footer-selected-label', {
              count: selectedOrdersCount,
              total: totalOrdersCount
            })
          "
        />
        <div class="footer-item">
          <i class="material-icons">person</i>
          {{ $t('finance-board/finance-footer-total-net-customer') }}:
          <strong>{{ selectedTotalCustomerNet | formatMoneyWithDashForNull }}</strong>
        </div>
        <div class="footer-item">
          <i class="material-icons">local_shipping</i>
          {{ $t('finance-board/finance-footer-total-net-carrier') }}:
          <strong>{{ selectedTotalCarrierNet | formatMoneyWithDashForNull }}</strong>
        </div>
      </div>
    </template>

    <div slot="footer-action">
      <div class="p-2">
        <Button
          category="secondary"
          :text="$t('finance-board/finance-footer-download-carrier-doc-label')"
          materialIcon="cloud_download"
          class="action-btn"
          data-test="download-carrier-documents"
          @click="showCarrierDocumentsModal"
        />
        <!-- Settlement-controlled buttons start -->
        <Button
          category="primary"
          :text="$t('finance-board/finance-footer-issue-credit-note-label')"
          materialIcon="picture_as_pdf"
          class="action-btn"
          data-test="generate-credit-note"
          @click="showCarrierFinanceDocumentModal"
        />
        <!-- Settlement-controlled buttons end -->
      </div>
    </div>
  </BoardFooter>
</template>

<script>
import { BoardFooter } from '@/modules/boards'
import { formatMoneyWithDashForNull } from '@/global-setup/filters'

export default {
  components: {
    BoardFooter
  },
  filters: { formatMoneyWithDashForNull },
  props: {
    selectedOrdersCount: { type: Number, default: 0 },
    totalOrdersCount: { type: Number, required: true },
    selectedTotalCustomerNet: { type: Number, required: true },
    selectedTotalCarrierNet: { type: Number, required: true },
    toggleBilled: { type: Function, required: true }
  },
  data: () => ({
    togglingBilledStatus: false
  }),
  methods: {
    async markSelectedOrdersAsBilled() {
      this.togglingBilledStatus = true
      try {
        await this.toggleBilled()
      } finally {
        this.togglingBilledStatus = false
      }
    },
    showCarrierFinanceDocumentModal() {
      this.$emit('showCarrierFinanceDocumentModal')
    },
    showCarrierDocumentsModal() {
      this.$emit('showCarrierDocumentsModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-text {
  margin: 16px;
  display: flex;
  align-items: center;
}

.footer-item {
  padding-right: 24px;
}

.material-icons {
  vertical-align: bottom;
  font-size: 1.2rem;
  margin-right: 5px;
}

.action-btn:not(:last-child) {
  margin-right: 8px;
}
</style>

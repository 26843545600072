<template>
  <TypeAheadField
    :options="filterOptions"
    v-model="customerFilterHandler"
    :visible-selected-options-limit="visibleOptionsLimit"
    :height-fixed="false"
    optionLabel="name"
    :internalSearch="internalSearch"
    optionKey="id"
    :title="$t('filters/customer-filter-field-title')"
    multiple
    :placeholder="placeholder"
    data-test="select-customer"
    @fetchFilterOptions="onFetchFilterOptions"
    @clearFilterOptions="onClearFilterOptions"
  />
</template>

<script>
import { fetchCustomersBySearchText } from '@/services/customer-service'

export default {
  inject: ['getFilterValue', 'setFilterValue'],
  props: {
    placeholder: { type: String, default: '' },
    visibleOptionsLimit: { type: Number, default: 5 }
  },
  data: () => ({
    filterOptions: [],
    internalSearch: false
  }),
  computed: {
    customerFilterHandler: {
      get() {
        return this.getFilterValue('customers')
      },
      set(payload) {
        this.setFilterValue('customers', payload)
        this.$emit('applyFilters')
      }
    }
  },
  methods: {
    async onFetchFilterOptions(searchText) {
      this.filterOptions = await fetchCustomersBySearchText(searchText)
    },
    onClearFilterOptions() {
      this.filterOptions = []
    }
  }
}
</script>

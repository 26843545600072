<template>
  <BoardTableRow>
    <td>
      <router-link
        :to="order.stageLink"
        @mousedown.native="handleLinkClick"
        data-test="row"
      >
        {{ order.idForStaff }}
      </router-link>
    </td>
    <td data-test="account-manager-cell">
      <div class="account-manager-avatars">
        <Avatar
          class="operator-cell senior-account-manager"
          :class="{ 'urgent-dispatch': order.urgent_dispatch }"
          v-if="order.accountManager"
          :tooltip="$t('request-board/request-row-saom') + order.accountManagerFullName"
        >
          {{ order.accountManager.initials }}
        </Avatar>
        <Avatar
          class="operator-cell"
          :class="{ 'urgent-dispatch': order.urgent_dispatch }"
          v-if="order.juniorAccountManager"
          :tooltip="
            $t('request-board/request-row-aom') + order.juniorAccountManagerFullName
          "
        >
          {{ order.juniorAccountManager.initials }}
        </Avatar>
      </div>
    </td>

    <td>
      <GenericInfoCell>{{ order.originAddress | addressShortFormat }}</GenericInfoCell>
    </td>

    <td>
      <GenericInfoCell>
        {{ order.destinationAddress | addressShortFormat }}
      </GenericInfoCell>
    </td>

    <td data-test="pickup-datetimes">
      <DatetimeCell>
        {{ order.pickup_start_date }}
        <br />
        {{ order.pickup_start_time }} - {{ order.pickup_end_time }}
      </DatetimeCell>
    </td>

    <td class="cell-border-right" data-test="dropoff-datetimes">
      <DatetimeCell>
        {{ order.delivery_start_date }}
        <br />
        {{ order.delivery_start_time }} - {{ order.delivery_end_time }}
      </DatetimeCell>
    </td>

    <td>
      <CompanyCell data-test="carrier-info">
        {{ transportRequest.carrier.name }}
      </CompanyCell>
    </td>

    <td class="cell-name">
      <span data-test="contact-info">
        {{ transportRequest.creatorFullName }}
        <br />
        {{ transportRequest.creator.email }}
        <br />
        {{ transportRequest.creator.phone }}
      </span>
    </td>

    <td data-test="request-created-data">
      <DatetimeCell>{{ transportRequest.humanizedCreated }}</DatetimeCell>
    </td>

    <td data-test="base-price">
      <span>{{ order.base_price | formatMoneyWithDashForNull }}</span>
    </td>

    <td data-test="cost-indication">
      <span>{{ transportRequest.price | formatMoneyWithDashForNull }}</span>
    </td>
  </BoardTableRow>
</template>

<script>
import { addressShortFormat } from '@/models/address'
import {
  BoardTableRow,
  DatetimeCell,
  CompanyCell,
  GenericInfoCell
} from '@/modules/boards'
import { carrierRelevantFieldsAreEditableForOrder } from '@/modules/common/editability'
import Avatar from '@/modules/common/components/Avatar'
import { formatMoneyWithDashForNull } from '@/global-setup/filters'
import { trackEvent } from '@/analytics/amplitude'
import { events, trackingModule } from '../events'

export default {
  components: {
    GenericInfoCell,
    BoardTableRow,
    DatetimeCell,
    CompanyCell,
    Avatar
  },
  filters: {
    addressShortFormat,
    formatMoneyWithDashForNull
  },
  props: {
    transportRequest: { type: Object, default: null }
  },
  computed: {
    order() {
      return this.transportRequest.order
    },
    carrierRelevantFieldsAreEditable() {
      return carrierRelevantFieldsAreEditableForOrder(this.order)
    },
    requestIsFromCarrier() {
      return this.transportRequest.request_source === 'ORCAS'
    }
  },
  methods: {
    handleLinkClick() {
      trackEvent(events.REQUEST_BOARD_LINK_CLICK, {
        module: trackingModule
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cell-name {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.account-manager-info:not(.urgent-dispatch) {
  color: $color-primary-main;
}

.account-manager-avatars {
  display: flex;

  .senior-account-manager {
    border: 1px solid;
  }
}

.operator-cell {
  &:hover {
    color: $color-secondary-main;
    background-color: $color-secondary-lighter;
  }
}

.operator-cell:not(.urgent-dispatch) {
  color: $color-primary-main;
}
</style>

<template>
  <DropdownField
    v-model="filterHandler"
    :options="orderStates"
    :close-on-select="false"
    :clear-on-select="true"
    multiple
    searchable
    :title="$t('filters/order-state-filter-field-title')"
    data-test="state-filter"
    style="width: 100%"
  />
</template>

<script>
export default {
  inject: ['getFilterValue', 'setFilterValue'],
  props: {
    orderStates: { type: Array, default: () => [] }
  },
  computed: {
    filterHandler: {
      get() {
        return this.getFilterValue('orderStates')
      },
      set(payload) {
        this.setFilterValue('orderStates', payload)
        this.$emit('applyFilters')
      }
    }
  }
}
</script>

import { STAGE_ROUTE_NAMES } from '@/router/stage-route-names'
import { enabledOrderStatesFor } from '@/models/order-edition-rules'

export function carrierRelevantFieldsAreEditableForOrder(order) {
  const enabledStates = enabledOrderStatesFor(STAGE_ROUTE_NAMES.dispatching)
  return order.isEditable(enabledStates) && !hasActiveAssignment({ order })
}

function hasActiveAssignment({ order }) {
  return (
    order.lastAssignment &&
    (order.lastAssignment.state === 'PENDING' ||
      order.lastAssignment.state === 'ACCEPTED')
  )
}

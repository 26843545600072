import moment from 'moment-timezone'
import {
  humanizeIsoDate,
  humanizeIsoDateTimeWithDots,
  humanizeIsoTime
} from '@sennder/plankton'
import {
  formatDateWithTz,
  formatTimeWithTz,
  getTimezoneAcronym
} from '@/global-setup/filters'
import { ActualTimesCorrectedPostExecutionLog } from '@/modules/order-activity-sidebar/types'

export const dateFormat = (isoTimestamp: string) =>
  isoTimestamp ? humanizeIsoDate(isoTimestamp) : ''

export const dateTimeFormatWithDots = (isoTimestamp: string) =>
  isoTimestamp ? humanizeIsoDateTimeWithDots(isoTimestamp) : ''

export const timeFormat = (isoTimestamp: string) =>
  isoTimestamp ? humanizeIsoTime(isoTimestamp) : ''

export const formatDatetimeIntervalLong = (
  isoTimestamps: [string, string],
  showYear = false,
  timezone = ''
) => {
  const [localArrival, localDeparture] = isoTimestamps.map((ts: string) => {
    const momentTz = timezone ? moment(ts).tz(timezone) : moment(ts)
    return momentTz.format(`DD.MM.${showYear ? 'YYYY' : ''} HH:mm`)
  })
  const arrivalTzAcronym = timezone
    ? getTimezoneAcronym(isoTimestamps[0], timezone)
    : null
  const departureTzAcronym = timezone
    ? getTimezoneAcronym(isoTimestamps[1], timezone)
    : null

  if (arrivalTzAcronym && departureTzAcronym) {
    if (departureTzAcronym === arrivalTzAcronym) {
      return `${localArrival} - ${localDeparture} ${departureTzAcronym}`
    } else {
      return `${localArrival} ${arrivalTzAcronym} - ${localDeparture} ${departureTzAcronym}`
    }
  } else {
    return `${localArrival} - ${localDeparture}`
  }
}

export const formatDatetimeIntervalShort = (
  isoTimestamps: [string, string],
  showYear = false,
  timezone = ''
) => {
  let momentTzArrival = moment(isoTimestamps[0])
  let momentTzDeparture = moment(isoTimestamps[1])
  let timezoneAcronym = ''

  if (timezone) {
    momentTzArrival = momentTzArrival.tz(timezone)
    momentTzDeparture = momentTzDeparture.tz(timezone)
    timezoneAcronym = getTimezoneAcronym(isoTimestamps[0], timezone)
  }
  const localArrivalDateTime = momentTzArrival.format(
    `DD.MM.${showYear ? 'YYYY' : ''} HH:mm`
  )
  const localDepartureTime = momentTzDeparture.format('HH:mm')
  return `${localArrivalDateTime} - ${localDepartureTime} ${timezoneAcronym}`.trim()
}

export const dateRangeText = (
  isoTimestamps: [string, string],
  showYear = false,
  timezone = ''
) =>
  moment(isoTimestamps[0]).isSame(isoTimestamps[1], 'day')
    ? formatDatetimeIntervalShort(isoTimestamps, showYear, timezone)
    : formatDatetimeIntervalLong(isoTimestamps, showYear, timezone)

export const logOldDateFormatted = (
  log: ActualTimesCorrectedPostExecutionLog,
  warehouseTimezone?: string
): string => {
  return `${formatDateWithTz(log.logData.old, warehouseTimezone)} ${formatTimeWithTz(
    log.logData.old,
    warehouseTimezone
  )} ${getTimezoneAcronym(log.logData.old, warehouseTimezone)}`
}

export const logNewDateFormatted = (
  log: ActualTimesCorrectedPostExecutionLog,
  warehouseTimezone?: string
): string => {
  return `${formatDateWithTz(log.logData.new, warehouseTimezone)} ${formatTimeWithTz(
    log.logData.new,
    warehouseTimezone
  )} ${getTimezoneAcronym(log.logData.new, warehouseTimezone)}`
}

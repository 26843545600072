import { query } from '@/graphql'

const NEXT_LOADS_QUERY = `
  query NextLoads($orderIds: [Int!]) {
    orders(
      where: {
        order_id: { _in: $orderIds }
      },
    ) {
      id: order_id
      idForStaff: id_for_staff
      destinationCountryCode: destination_country_code
      destinationPostalCode: destination_postal_code
      deliveryStart: delivery_start
      deliveryEnd: delivery_end

      nextLoads: next_loads {
        nextOrderId: next_order_id
        distanceStraightLine: distance_straight_line
        returnDistanceStraightLine: return_distance_straight_line
        order {
          id: order_id
          idForStaff: id_for_staff
          deliveryStart: delivery_start
          deliveryEnd: delivery_end
          originCountryCode: origin_country_code
          originPostalCode: origin_postal_code
          destinationCountryCode: destination_country_code
          destinationPostalCode: destination_postal_code
          pickupStart: pickup_start
          pickupEnd: pickup_end
          distance: route_distance
          basePrice: base_price
          stops: stopover_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`

interface NextLoad {
  nextOrderId: string
  distanceStraightLine: number
  returnDistanceStraightLine: number

  order: {
    id: number
    idForStaff: string
    deliveryStart: string
    deliveryEnd: string
    originCountryCode: string
    originPostalCode: string
    destinationCountryCode: string
    destinationPostalCode: string
    pickupStart: string
    pickupEnd: string
    distance: number
    basePrice: number
    stops: {
      aggregate: {
        count
      }
    }
  }
}

interface NextLoadOrder {
  id: number
  destinationCountryCode: string
  destinationPostalCode: string
  deliveryStart: string
  deliveryEnd: string
  nextLoads: NextLoad[]
}

interface NextLoadsResponse {
  orders: NextLoadOrder[]
}

function queryDataServices<T>(statement, variables = {}) {
  return query<T>(
    process.env.VUE_APP_DATA_SERVICES_URL,
    {
      Authorization: process.env.VUE_APP_DATA_SERVICES_KEY
    },
    statement,
    variables
  )
}

export async function getNextLoadsForOrders(orderIds: number[]) {
  const response = await queryDataServices<NextLoadsResponse>(NEXT_LOADS_QUERY, {
    orderIds
  })

  return response.orders
}

export async function getNextLoadsForOrder(orderId: number) {
  const orders = await getNextLoadsForOrders([orderId])
  return orders.length > 0 ? orders[0] : { id: orderId, nextLoads: [] }
}

const NUMBER_OF_NEXT_LOADS_QUERY = `
  query NextLoads($orderIds: [Int!]) {
    orders(
      where: {
        order_id: { _in: $orderIds }
      },
    ) {
      id: order_id
      idForStaff: id_for_staff,
      nextLoads: next_loads_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

interface NextLoadsCountResponse {
  orders: {
    id: number
    idForStaff: string
    nextLoads: {
      aggregate: {
        count: number
      }
    }
  }[]
}

export async function getNumberOfNextLoadsForOrders(orderIds: number[]) {
  const response = await queryDataServices<NextLoadsCountResponse>(
    NUMBER_OF_NEXT_LOADS_QUERY,
    {
      orderIds
    }
  )

  return response.orders.map(entry => ({
    id: entry.id,
    idForStaff: entry.idForStaff,
    count: entry.nextLoads.aggregate.count
  }))
}

export async function getNumberOfNextLoadsForOrder(orderId: number) {
  const response = await getNumberOfNextLoadsForOrders([orderId])

  return response.length === 0 ? { id: orderId, idForStaff: '', count: 0 } : response[0]
}

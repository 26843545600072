<template>
  <button
    class="expand-filter-btn"
    type="button"
    data-test="expand-filters"
    @click="expandFiltersArea"
  >
    <div>
      <i class="material-icons">tune</i>

      <div class="applied-filters-counter" v-if="appliedFilterValues.length">
        {{ appliedFilterValues.length }}
      </div>
    </div>
  </button>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  inject: ['getAppliedValues'],
  computed: {
    appliedFilterValues() {
      return this.getAppliedValues()
    }
  },
  methods: {
    ...mapMutations(['expandFiltersArea'])
  }
}
</script>

<style lang="scss" scoped>
.expand-filter-btn {
  background: transparent;
  border: none;
  border-left: 1px solid $color-neutral-lightest;
  position: relative;
  float: right;
  height: 100%;
  text-align: center;
  color: $color-neutral-darkest;
  display: flex;
  align-items: center;
  padding-left: 20px;

  i {
    font-size: 1.5rem;
    float: left;
    color: $color-secondary-main;
  }

  span {
    float: left;
    margin: 0 5px;
  }

  &:hover,
  &:focus {
    background: lighten($color-neutral-lightest, 5);
  }

  .applied-filters-counter {
    padding: 1px 5px;
    border-radius: 40px;
    position: absolute;
    left: 42px;
    font-size: 9px;
    border-radius: 20px;
    background-color: $color-error-darker;
    color: $color-layout-white;
  }
}
</style>

import { runJsonGet, runJsonPost } from '@/models/backend-client'
import { SHIPMENT_INTERNAL_COMM_API_URL } from '@/config'
import { SINGLE_AUDIENCE } from '@/services/auth-service'
import { AxiosResponse } from 'axios'

import logger from '@/shell/console-logger'
import { transformKeysToSnakeCase } from '@/services/utils/casing'

export interface ShipmentInternalCommEntity {
  shipment_id: string
  front_link: string
}

export interface ShipmentInternalCommCreateEntity {
  shipment_id: string
  transport_offer_id: string
  shipper_profile_id: string
  facility_ids: string[]
  shipper_reference: string
  external_id: string
}

export const getFrontConversationLink = async (
  shipmentId: string
): Promise<ShipmentInternalCommEntity> => {
  try {
    const response = (await runJsonGet(
      `${SHIPMENT_INTERNAL_COMM_API_URL}/api/conversation/links/${shipmentId}`,
      undefined,
      { apiGatewayAuthorization: true },
      SINGLE_AUDIENCE,
      undefined
    )) as AxiosResponse<ShipmentInternalCommEntity>
    return {
      front_link: response.data?.front_link,
      shipment_id: response.data.shipment_id
    }
  } catch (error) {
    logger.error('Error fetching front link from shipment-internal-comm:', error)
    return { front_link: null, shipment_id: shipmentId }
  }
}

export const createFrontConversationLink = async (
  shipmentId: string,
  transportOfferId: string,
  operators: string[],
  shipperReference: string,
  externalId: string,
  subject: string
): Promise<ShipmentInternalCommEntity> => {
  try {
    const response = (await runJsonPost(
      `${SHIPMENT_INTERNAL_COMM_API_URL}/api/conversation/create`,
      transformKeysToSnakeCase({
        shipmentId,
        transportOfferId,
        subject,
        shipperReference,
        externalId,
        operators
      }),
      { apiGatewayAuthorization: true },
      SINGLE_AUDIENCE
    )) as AxiosResponse<ShipmentInternalCommEntity>
    return {
      front_link: response.data?.front_link,
      shipment_id: response.data.shipment_id
    }
  } catch (error) {
    logger.error('Error creating front link from shipment-internal-comm:', error)
    return { front_link: null, shipment_id: shipmentId }
  }
}

import logger from '@/shell/console-logger'
import { isUAT, isProductionUrl } from '@/controllers/environment-detection'
const localMicrofrontends = []

export const createRandomString = () =>
  Math.random()
    .toString(36)
    .substring(4)
    .replace(/[^a-zA-Z]+/g, '')

export const getEnvironmentAliasForFederatedLoader = (component = '') => {
  if (localMicrofrontends.includes(component)) {
    return 'hot'
  }
  if (isProductionUrl()) {
    return 'prod'
  }
  if (isUAT()) {
    return 'uat'
  }

  return 'dev'
}

export function getUserIdFromToken(token: string | undefined): string | undefined {
  if (token) {
    try {
      return JSON.parse(window.atob(token.split('.')[1]))['https://sennder.com/user_id']
    } catch (e) {
      logger.warn(e, 'token is not parsed property, user_id defaults to empty string')
    }
  }
}

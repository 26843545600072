import { computed, Ref, ComputedRef } from '@vue/composition-api'
import moment from 'moment-timezone'
import { DATE_FORMAT } from '@/global-setup/filters'
import useCurrentUser from '@/compositions/useCurrentUser'
import { OrderStop, OrderStopToCreate } from '@/services'

interface CompositionOptions {
  originalStops: Ref<OrderStop[]>
  stops: Ref<(OrderStop | OrderStopToCreate)[]>
}

export default ({
  originalStops,
  stops
}: CompositionOptions): { displayComponent: ComputedRef<boolean> } => {
  const { currentUserIsAccountManager } = useCurrentUser()

  const hasBeenRolled = computed<boolean>(() =>
    stops.value.some((stop: OrderStop | OrderStopToCreate, index: number) =>
      moment(stop.startDate, DATE_FORMAT).isAfter(
        moment(originalStops.value[index]?.startDate, DATE_FORMAT)
      )
    )
  )

  const displayComponent = computed<boolean>(
    () => currentUserIsAccountManager.value && hasBeenRolled.value
  )
  return {
    displayComponent
  }
}

import { OrderState } from '@/modules/common/order-states'
import { NavItemLabel } from '../types'
import { IShipperProfileResponse } from '@/services'

type NavItem = {
  label: NavItemLabel
  states: OrderState[]
  component: string
  tabComponent: string
  tracker: string
}

type InitialState = {
  visibleState: OrderState
  visibleTab: OrderState
  navItems: NavItem[]
  shipperProfile: IShipperProfileResponse | null
  // TODO add other properties types
}

const getInitialState: () => InitialState = () => ({
  visibleState: 'NEW',
  visibleTab: 'NEW',
  order: {
    id: null,
    referenceNumber: '',
    customerName: '',
    customerContact: null,
    customerBillingAddress: null,
    vehicleType: '',
    loadQuantity: null,
    loadUnitType: null,
    loadWeight: null,
    loadLength: null,
    loadHeight: null,
    loadWidth: null,
    loadMinimumTemperature: null,
    loadMaximumTemperature: null,
    loadDescription: null,
    state: null,

    sealable: false,
    codeXl: false,
    paletExchange: false,
    arrivalNotification: false,
    directTransfer: false,
    dockLoading: false,
    sideLoading: false,
    topLoading: false,
    isTemperatureControl: false,

    basePrice: null,
    registration_surcharge: null,
    registration_surcharge_description: null,
    totalNetPrice: null,

    accountManagerId: null,
    partnerManagerId: null,
    carrierId: null,
    carrierContactId: null,
    carrier: null,
    carrierContact: null,

    workflowManaged: null
  },
  spotCosting: {
    suggestedBaseCost: null,
    confidenceLevel: null,
    costSuggestionCategory: null,
    suggestedBaseCostIncentives: null,
    suggestedBaseCostOctopus: null,
    displayCost: null
  },
  orderStops: [],
  accountManagerOfferList: [],
  partnerManagerOfferList: [],
  carriersKpi: [],
  carriersTrackingRate: {},
  accountManager: null,
  juniorAccountManager: null,
  partnerManager: null,
  spotBidder: null,
  transfer: null,
  lineCode: null,
  trackingConfig: {
    gatehousePullOn: false,
    gatehousePushOn: false,
    amazonPushOn: false
  },
  navItems: [
    {
      label: 'Registration',
      states: ['NEW'],
      component: 'RegistrationStage',
      tabComponent: 'StringTab',
      tracker: 'registration'
    },
    {
      label: 'Facility Scheduling',
      states: ['PLANNING'],
      component: 'PlanningStage',
      tabComponent: 'StringTab',
      tracker: 'planning',
      unsavedWarning: true
    },
    {
      label: 'Dispatching',
      states: ['REGISTERED', 'CARRIER_LOCKED'],
      component: 'DispatchingStage',
      tabComponent: 'StringTab',
      tracker: 'dispatching'
    },
    {
      label: 'Execution',
      states: ['DISPATCHED'],
      component: 'ExecutionStage',
      tabComponent: 'StringTab',
      tracker: 'execution'
    },
    {
      label: 'Closing',
      states: ['EXECUTED'],
      component: 'ClosingStage',
      tabComponent: 'StringTab',
      tracker: 'closing'
    }
  ],
  assignments: [],
  shipmentData: null,
  executionPlanData: null,
  isShipmentFeasible: false,
  isShipmentFeasibleLoading: false,
  isShipmentReschedulingBlockedByChartering: false,
  dirtyPlanningForm: false,
  frontLink: null,
  shipperProfile: null
})

export default getInitialState

<template>
  <div data-test="gps-status-indicator">
    <i
      :class="status"
      class="material-icons gps-icon"
      data-test="gps-icon"
      v-tooltip="tooltip"
    >
      {{ state.icon }}
    </i>
    <span
      v-if="enableTextDisplay"
      class="paragraph gps-message"
      data-test="gps-status-message"
    >
      {{ $t(state.message, { elapsedTime: state.elapsedTime }) }}
    </span>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { i18n } from '@/plugins/i18n'

export default {
  props: {
    state: { type: Object, default: null },
    status: { type: String, default: null },
    enableTooltip: { type: Boolean, default: false },
    enableTextDisplay: { type: Boolean, default: false }
  },
  setup(props) {
    const tooltip = computed(() =>
      props.enableTooltip
        ? i18n.t(props.state.message, { elapsedTime: props.state.elapsedTime })
        : ''
    )

    return { tooltip }
  }
}
</script>

<style lang="scss" scoped>
.gps-icon {
  margin-right: 5px;
  font-size: 16px;
  width: 16px;
  height: 16px;
}

.gps-message {
  color: $color-neutral-main;
  vertical-align: text-top;
  line-height: 12px;
}

.no-signal {
  color: $color-neutral-main;
}

.active {
  color: $color-success-main;
}

.warning {
  color: $color-primary-main;
}

.delayed {
  color: $color-error-main;
}
</style>

import { OPERATIONS_BACKEND_URL } from '../config'
import Model from './model'

export default class TrackedGeolocation extends Model {
  static get NAME() {
    return 'locations'
  }
  static get URL() {
    return `${OPERATIONS_BACKEND_URL}/tracking/positions/`
  }
}

import Model from '@/models/model'
import {
  durationBetweenIsoDates,
  minutesBetweenIsoDates,
  humanizeIsoDatetime,
  humanizeIsoTime,
  humanizeIsoDate
} from '@sennder/plankton'
import Vue from 'vue'

export default class Step extends Model {
  constructor(data) {
    super(data)
    // We use this field to track user input change
    // state when editing actual datetime:
    Vue.set(this, 'editedActualTime', null)
  }

  get actual_time_date_formatted() {
    if (this.actual_time) return humanizeIsoDate(this.actual_time)
    else return null
  }

  get actual_time_hour_formatted() {
    if (this.actual_time) return humanizeIsoTime(this.actual_time)
    else return null
  }

  get actual_time_formatted() {
    if (this.actual_time) return humanizeIsoDatetime(this.actual_time)
    else return null
  }

  get estimated_time_formatted() {
    if (this.estimated_time) return humanizeIsoDatetime(this.estimated_time)
    else return null
  }

  get scheduled_time_formatted() {
    if (this.scheduled_time) return humanizeIsoDatetime(this.scheduled_time)
    else return null
  }

  get delay_time_in_minutes() {
    if (this.actual_time && this.scheduled_time)
      return minutesBetweenIsoDates(this.actual_time, this.scheduled_time)
    else return null
  }

  get delay() {
    if (this.actual_time && this.scheduled_time)
      return durationBetweenIsoDates(this.actual_time, this.scheduled_time)
    else if (this.estimated_time && this.scheduled_time)
      return durationBetweenIsoDates(this.estimated_time, this.scheduled_time)
    else return null
  }

  get hasEmptyEditedTime() {
    return this.editedActualTime === null
  }
}

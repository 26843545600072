import { render, staticRenderFns } from "./LocationAndTime.vue?vue&type=template&id=0ff68ee8&scoped=true&"
import script from "./LocationAndTime.vue?vue&type=script&lang=js&"
export * from "./LocationAndTime.vue?vue&type=script&lang=js&"
import style0 from "./LocationAndTime.vue?vue&type=style&index=0&id=0ff68ee8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ff68ee8",
  null
  
)

export default component.exports
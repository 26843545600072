







import Vue from 'vue'
import { defineComponent } from '@vue/composition-api'
import {
  IUser,
  Tenant,
  SharedDataType
} from '@sennder/senn-node-microfrontend-interfaces'
import {
  createRandomString,
  getEnvironmentAliasForFederatedLoader
} from '@/microfrontends/utils'
import useI18n from '@/compositions/useI18n'
import useCurrentUser from '@/compositions/useCurrentUser'
import { getAppTheme } from '@/controllers/environment-detection'
import { OPERATIONS_BACKEND_URL } from '@/config'
import { Location, RawLocation, Route } from 'vue-router'
import { getLogger } from '@/shell/datadog-logger'
import { fetchMicrofrontend } from '@/microfrontends/fetchMicrofrontend'
import { monitorError } from '@/analytics/monitoring'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import { getAuthHeader } from '@/services/auth-service'
import { CARRIER_CONTRACTS_FEATURE_FLAGS } from './constants'

const DEV_PORT = 9000
const NAMESPACE = 'carriercontractmanagementmf'
const NPM_PATH = 'carrier-contract-management-mf'
const REMOTE = './carriercontractmanagementmfApp'

export default defineComponent({
  name: 'CarrierContractsMf',
  props: {
    contractId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      id: `${createRandomString()}-v2-carrier-contract-management-mf`,
      isLoaded: null as boolean | null,
      syncChildRouter: null as ((route: RawLocation) => void) | null,
      unmount: null as (() => void) | null
    }
  },
  async mounted() {
    try {
      const { locale } = useI18n()
      const { isActive } = useFeatureFlag()
      const { user } = useCurrentUser()
      const syncParentRouter = (route: RawLocation) => {
        const currentPath = this.$route.fullPath
        if (
          (typeof route === 'string' && currentPath === route) ||
          (typeof route === 'object' && currentPath === (route as Location).path)
        ) {
          return
        }
        this.$router.push(route)
      }
      const mount = await fetchMicrofrontend({
        devPort: DEV_PORT,
        environment: getEnvironmentAliasForFederatedLoader(NAMESPACE),
        moduleFederationPluginName: NAMESPACE,
        npmName: NPM_PATH,
        mountExposedName: REMOTE,
        logger: getLogger()
      })

      const featureFlagsObject: Record<string, string | boolean> = {
        [CARRIER_CONTRACTS_FEATURE_FLAGS.ENABLE_NEW_CC]: isActive(
          CARRIER_CONTRACTS_FEATURE_FLAGS.ENABLE_NEW_CC
        ).value
      }

      const mfInstance = mount(`#${this.id}`, {
        data: {
          contractId: this.contractId,
          featureFlags: featureFlagsObject,
          env: { OPERATIONS_BACKEND_URL },
          type: SharedDataType.OCTOPUS,
          language: locale.value,
          user: user.value as IUser,
          tenant: getAppTheme() as Tenant
        },
        callbacks: {
          getAuthHeader,
          getAuthToken: getAuthHeader,
          syncParentRouter,
          onUnauthorized: () => {},
          onUnhandledError: error => {
            Vue.config.errorHandler(error, null, 'CarrierContractsMf')
          }
        },
        providers: {
          logger: getLogger(),
          notifications: null,
          translations: null,
          analytics: null,
          monitoring: null
        }
      })
      if (mfInstance.syncChildRouter) {
        this.syncChildRouter = mfInstance.syncChildRouter
        this.$watch(
          '$route',
          (to: Route) => {
            mfInstance.syncChildRouter(to.fullPath)
          },
          {
            immediate: true
          }
        )
      }
      if (mfInstance.unmount) {
        this.unmount = mfInstance.unmount
      }
      this.isLoaded = true
    } catch (e) {
      monitorError(e, 'CarrierContractsMf', {
        type: 'bootstrap'
      })
      this.isLoaded = false
    }
  }
})

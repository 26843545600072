// Check https://stackoverflow.com/questions/1382107/whats-a-good-way-to-extend-error-in-javascript
// to understand why we cannot extend Error after babel 6

export function ValidationError(message) {
  this.name = 'ValidationError'
  this.message = message
  this.stack = new Error().stack
}
ValidationError.prototype = new Error()

export const DETECTED_NETWORK_ERROR = 'Detected Network Error'

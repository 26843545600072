import { Ref, ref } from '@vue/composition-api'
import { getStepActualTimes } from '@/services/infrastructure-service'
import { TransferStepActualTime } from '@/services'

export function useSteps() {
  const steps: Ref<TransferStepActualTime[]> = ref([])
  const loading = ref(false)

  const clearSteps = () => {
    steps.value = []
  }

  const loadSteps = async (transferId: number) => {
    try {
      loading.value = true
      if (transferId) {
        steps.value = await getStepActualTimes(transferId)
      } else {
        clearSteps()
      }
    } catch (e) {
      clearSteps()
      throw e
    } finally {
      loading.value = false
    }
  }

  return {
    steps,
    loading,
    loadSteps,
    clearSteps
  }
}

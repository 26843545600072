<template>
  <div class="quick-filters-content plankton-grid">
    <SennderReferenceFilter
      filterName="idsForStaff"
      data-test="id-for-staff-filter"
      class="plankton-cell"
      span="6"
      :title="$t('request-board/request-filters-content-sen-ref')"
      v-on="$listeners"
      :showHint="false"
      :containerStyle="{ minHeight: '32px', maxHeight: '100px' }"
    />

    <div class="plankton-cell filter-container" span="6">
      <SeniorAccountManagerFilter v-on="$listeners" />
    </div>

    <div class="plankton-cell filter-container" span="6">
      <AccountManagerFilter v-on="$listeners" />
    </div>

    <div class="plankton-cell filter-container" span="6">
      <CarrierFilter :showHasNoCarrierFilter="false" v-on="$listeners" />
    </div>
  </div>
</template>

<script>
import {
  AccountManagerFilter,
  SeniorAccountManagerFilter,
  CarrierFilter
} from '@/modules/filters'
import SennderReferenceFilter from '@/modules/filters/components/ReferenceFilters/SennderReferenceFilter'
export default {
  components: {
    AccountManagerFilter,
    SeniorAccountManagerFilter,
    CarrierFilter,
    SennderReferenceFilter
  }
}
</script>

<style lang="scss" scoped>
@import 'src/modules/filters/styles/quick-filters';

.filter-container {
  &::v-deep .multiselect__tags {
    min-height: 42px;
  }
}
</style>

<template>
  <div class="filter-wrapper">
    <TypeAheadField
      :options="filterOptions"
      v-model="partnerManagerFilterHandler"
      :visible-selected-options-limit="5"
      :height-fixed="false"
      :internalSearch="internalSearch"
      optionLabel="username"
      optionKey="id"
      :title="$t('filters/partner-manager-filter-title')"
      multiple
      placeholder=""
      data-test="partner-manager-filter"
      @fetchFilterOptions="onFetchFilterOptions"
      @clearFilterOptions="onClearFilterOptions"
    />
    <Button
      v-if="$route.name !== 'es-execution-board' && $route.name !== 'es-search-board'"
      v-tooltip.bottom="$t('filters/partner-manager-filter-orders-without-pm-tooltip')"
      :category="hasNoOperator ? 'primary' : 'tertiary'"
      materialIcon="contacts"
      class="dropdown-filter-button"
      type="button"
      @click="onHasNoOperatorFilterClick"
    />
  </div>
</template>
<script>
import { fetchUsersByEmailPattern } from '@/services/user-service'

export default {
  inject: ['getFilterValue', 'setFilterValue'],
  data: () => ({
    filterOptions: [],
    internalSearch: false
  }),
  computed: {
    partnerManagerFilterHandler: {
      get() {
        return this.getFilterValue('partnerManagers')
      },
      set(payload) {
        this.setFilterValue('partnerManagers', payload)
        this.$emit('applyFilters')
      }
    },
    hasNoOperator() {
      return this.getFilterValue('hasNoOperator')
    }
  },
  methods: {
    onHasNoOperatorFilterClick() {
      this.setFilterValue('hasNoOperator', !this.hasNoOperator)
      this.$emit('applyFilters')
    },
    async onFetchFilterOptions(searchText) {
      this.filterOptions = await fetchUsersByEmailPattern(searchText)
    },
    onClearFilterOptions() {
      this.filterOptions = []
    }
  }
}
</script>
<style lang="scss" scoped>
.filter-wrapper {
  display: flex;
  position: relative;
}

.dropdown-filter-button {
  margin-left: 8px;
  margin-top: 16px;
}
</style>

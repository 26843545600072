import { SHIPMENT_ACTIVITY_COMMENTS_URL, ACTIVITY_API_URL } from '@/config'
import { AxiosResponse } from 'axios'
import { IShipmentActivity } from '../types/shipment-draft'
import { runJsonGet, runJsonPut } from '@/models/backend-client'
import { IActivityRecord } from '@/modules/order-activity-sidebar/types'
import { monitorError } from '@/analytics/monitoring'

export const useShipmentActivity = () => {
  const fetchShipmentActivity = async (id: string) => {
    try {
      const response = (await runJsonGet(
        `${ACTIVITY_API_URL}/v1/shipment/${id}`,
        null,
        {
          apiGatewayAuthorization: true
        },
        'https://api.cloud.sennder.com/activity-records',
        'activity-records:read-any'
      )) as AxiosResponse<{
        activity_records: Array<IActivityRecord>
      }>
      const { activity_records } = response.data
      return activity_records.reverse()
    } catch (e) {
      monitorError(new Error('Error loading shipment activities!'), null, {
        shipmentId: id
      })
    }
  }

  const addShipmentComment = async (payload: IShipmentActivity): Promise<void> => {
    try {
      await runJsonPut(
        `${SHIPMENT_ACTIVITY_COMMENTS_URL}/comments`,
        payload,
        { apiGatewayAuthorization: true },
        'https://api.cloud.sennder.com/comments-and-activity',
        'comments:write'
      )
      return
    } catch (e) {
      monitorError(new Error('Error creating comment for shipment draft'))
    }
  }

  return {
    fetchShipmentActivity,
    addShipmentComment
  }
}

import { fetchOrderId, fetchOrderStops, fetchOrder } from '@/services/ordering-service'
import { ref } from '@vue/composition-api'
import { fullName } from '@/modules/common/filters/person-filters'
import { sortVehicleTypes } from '@sennder/octopus-constants'
import { CarrierContact, OrderDetail } from '@/services'
import { fetchUserById, fetchUserByUuid } from '@/services/user-service'
import { REGULARITY_TYPE } from '@/modules/common/order-states'
import {
  getDesignatedOperators,
  OperatorRoleId
} from '@/services/designated-operators-service'

export interface FinanceOrder extends OrderDetail {
  carrierCompanyId: number
  carrierContactId: number
  carrierContact: CarrierContact & { name: string }
  baseCost?: number
  basePrice?: number
}

interface Person {
  firebaseToken: string
  firstName: string
  id: number
  inStaffGroup: boolean
  initials: string
  isConfirmed: boolean
  isDriver: boolean
  language: string
  lastName: string
  lastShownUpdatePaymentPopUp: string
  timezone: string
  username: string
  name: string
}

export interface ExtendedPerson extends Person {
  fullName: string
}

const orderId = ref<number>(null)
const order = ref<FinanceOrder>(null)
const orderStops = ref([])
const accountManager = ref<ExtendedPerson>(null)
const juniorAccountManager = ref<ExtendedPerson>(null)
const partnerManager = ref<ExtendedPerson>(null)
const spotBidder = ref<ExtendedPerson>(null)

export default function useOrder() {
  const loadNewOrder = async (orderGroupId: number, letter: string) => {
    orderId.value = await fetchOrderId(orderGroupId, letter)
    await Promise.all([loadOrderDetail(), loadOrderStops()])
  }

  const loadOrderSpotBidder = async () => {
    if (
      order.value.regularity === REGULARITY_TYPE.SCALED_SPOT ||
      order.value.regularity === REGULARITY_TYPE.SPOT
    ) {
      let spotBidderId
      const designatedOperators = await getDesignatedOperators(order.value.id, [
        OperatorRoleId.SpotBidder
      ])
      if (designatedOperators && designatedOperators[0]) {
        spotBidderId = designatedOperators[0].operatorId
        const SPOTBIDDERPerson: Person = await (spotBidderId
          ? fetchUserByUuid(spotBidderId)
          : Promise.resolve())

        spotBidder.value = {
          ...SPOTBIDDERPerson,
          fullName: fullName(SPOTBIDDERPerson),
          name: fullName(SPOTBIDDERPerson)
        }
      }
    }
  }
  const loadOrderManagers = async () => {
    if (order.value.accountManagerId) {
      const AMPerson: Person = await fetchUserById(order.value.accountManagerId, true)
      accountManager.value = {
        ...AMPerson,
        fullName: fullName(AMPerson),
        name: fullName(AMPerson)
      }
    }

    if (order.value.partnerManagerId) {
      const PMPerson: Person = await fetchUserById(order.value.partnerManagerId, true)
      partnerManager.value = {
        ...PMPerson,
        fullName: fullName(PMPerson),
        name: fullName(PMPerson)
      }
    }

    if (order.value.juniorAccountManagerId) {
      const JAMPerson: Person = await fetchUserById(
        order.value.juniorAccountManagerId,
        true
      )
      juniorAccountManager.value = {
        ...JAMPerson,
        fullName: fullName(JAMPerson),
        name: fullName(JAMPerson)
      }
    }
  }

  const loadOrderDetail = async (id: number = null) => {
    if (id) orderId.value = id
    const fetchedOrder = await fetchOrder(orderId.value)
    order.value = {
      ...fetchedOrder,
      carrierCompanyId: fetchedOrder?.carrierCompany?.id || null,
      carrierContactId: fetchedOrder?.carrierContact?.id || null,
      carrierContact: fetchedOrder?.carrierContact
        ? {
            ...fetchedOrder?.carrierContact,
            name: fullName(fetchedOrder?.carrierContact)
          }
        : null,
      customerContact: fetchedOrder?.customerContact
        ? {
            ...fetchedOrder?.customerContact,
            name: fullName(fetchedOrder?.customerContact)
          }
        : null,
      // display vehicle types everywhere with predefined sorting order
      allowedVehicleTypes: fetchedOrder?.allowedVehicleTypes
        ? sortVehicleTypes(fetchedOrder?.allowedVehicleTypes)
        : []
    }
  }

  const loadOrderStops = async () => {
    orderStops.value = await fetchOrderStops(orderId.value)
  }

  return {
    order,
    orderId,
    orderStops,
    loadNewOrder,
    loadOrderDetail,
    loadOrderStops,
    loadOrderManagers,
    loadOrderSpotBidder,
    accountManager,
    partnerManager,
    juniorAccountManager,
    spotBidder
  }
}

import { ref } from '@vue/composition-api'
import { readPersistedState, writePersistedState } from '@/services/local-storage-service'
import { trackEvent } from '@/analytics/amplitude'
import events from '@/modules/ordering/components/shared/header/events'

export const sidebarIsVisible = ref(false)

const setSidebarVisibility = (value: boolean) => {
  sidebarIsVisible.value = value
  writePersistedState('sidebarIsVisible', value)
}

export const toggleSidebar = () => {
  trackEvent(events.TOGGLE_COMMENTS_AND_ACTIVITY, {
    open: !sidebarIsVisible.value
  })
  setSidebarVisibility(!sidebarIsVisible.value)
}

export const expandSidebar = () => {
  setSidebarVisibility(true)
}

export const collapseSidebar = () => {
  setSidebarVisibility(false)
}

export const saveSidebarVisibilityState = () => {
  writePersistedState('sidebarIsVisible', sidebarIsVisible.value)
  sidebarIsVisible.value = false
}

export const restoreSidebarVisibiliyState = () => {
  sidebarIsVisible.value = readPersistedState('sidebarIsVisible', false)
}

export const STAGE_ROUTE_NAMES = {
  registration: 'registration',
  dispatching: 'dispatching',
  dispatchingDirect: 'dispatching-direct',
  dispatchingOffers: 'dispatching-offers',
  execution: 'execution',
  closing: 'closing',
  finance: 'finance',
  issues: 'issues'
}

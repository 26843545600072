import { Container } from '../layout'
import { FinanceOrderPage } from '@sennder/finance-frontend'
import { store } from '@/models/store'

export default [
  {
    path: '/finance',
    component: Container,
    name: 'finance',
    redirect: '/dashboard/finance',
    children: [
      {
        path: 'ordering/:orderGroupId(\\d+):letter([A-Z]+)',
        component: FinanceOrderPage,
        name: 'financeOrder',
        beforeEnter: async (to, from, next) => {
          if (to.params.financeView) return next()
          if (store.getters.currentUserIsFinanceAR) {
            return next(
              `/finance/ordering/${to.params.orderGroupId}${to.params.letter}/ar`
            )
          }
          if (!store.getters.currentUserIsInFinanceGroup) {
            return next(`/ordering/${to.params.orderGroupId}${to.params.letter}`)
          }
          return next(`/finance/ordering/${to.params.orderGroupId}${to.params.letter}/ap`)
        },
        children: [
          {
            path: ':financeView',
            name: 'financeOrderView',
            component: FinanceOrderPage,
            beforeEnter: async (to, from, next) => {
              if (!store.getters.currentUserIsInFinanceGroup) {
                return next(`/ordering/${to.params.orderGroupId}${to.params.letter}`)
              }
              return next()
            }
          }
        ]
      }
    ]
  }
]

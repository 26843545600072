import Model from '@/models/model'

export default class Carrier extends Model {
  get companyAddress() {
    if (Array.isArray(this.company_address_ids)) {
      const firstAddressId = this.company_address_ids[0]
      return this.$state.company_addresses[firstAddressId]
    } else return null
  }
}

import Model from '@/models/model'
import { OPERATIONS_BACKEND_URL } from '@/config'

export default class TemporaryDriver extends Model {
  static get NAME() {
    return 'temporary_drivers'
  }
  static get URL() {
    return `${OPERATIONS_BACKEND_URL}/accounts/temporary_drivers/create`
  }
}

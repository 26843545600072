import { OPERATIONS_BACKEND_URL } from '@/config'
import { runJsonGet } from '@/models/backend-client'
import { AxiosResponse } from 'axios'
import { transformKeysToCamelCase } from '@/services/utils/casing'

interface MothershipCarrierEntity {
  id: number
  carrierTier: number
  defaultDiscountPercentage?: string
  gatehouseCarrierId?: number
  name: string
  vatOnInvoice?: boolean
}

// This endpoint can be accessed by Account Manager and Partner Manager.
export const fetchFilteredCarriers = async (
  searchText: string
): Promise<MothershipCarrierEntity[]> => {
  const response = (await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/retrieve_carrier_companies?name_pattern=${encodeURIComponent(
      searchText
    )}`
  )) as AxiosResponse<unknown>

  return transformKeysToCamelCase(response.data)
}

export const fetchScreenedOrInVettingCarriers = async (
  searchText: string
): Promise<MothershipCarrierEntity[]> => {
  // TODO: get information from carrier service or prefill with new ids from legacy
  const response = (await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/retrieve_screened_or_in_vetting_companies?name_pattern=${encodeURIComponent(
      searchText
    )}`
  )) as AxiosResponse<unknown>
  return transformKeysToCamelCase(response.data)
}

export const fetchScreenedCarriers = async (
  searchText: string
): Promise<MothershipCarrierEntity[]> => {
  const response = (await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/retrieve_screened_companies?name_pattern=${encodeURIComponent(
      searchText
    )}`
  )) as AxiosResponse<unknown>
  return transformKeysToCamelCase(response.data)
}

export const fetchScreenedCarriersWithOrcasAccess = async (
  searchText: string
): Promise<MothershipCarrierEntity[]> => {
  const response = (await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/retrieve_screened_companies_with_orcas_access?name_pattern=${encodeURIComponent(
      searchText
    )}`
  )) as AxiosResponse<unknown>
  return transformKeysToCamelCase(response.data)
}

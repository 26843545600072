
















import { defineComponent, PropType, computed } from '@vue/composition-api'

export default defineComponent({
  props: {
    specifications: {
      type: Array as PropType<
        { label: string; value: string | number; measuringUnitLabel: string }[]
      >,
      required: true
    }
  },
  setup(props) {
    const filteredSpecifications = computed(() =>
      props.specifications.filter(spec => !!spec.value)
    )

    return { filteredSpecifications }
  }
})

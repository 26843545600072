// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import de from './locales/de.json'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import en from './locales/en.json'

export const messages = {
  de: { ...de },
  en: { ...en }
}

<template>
  <div>
    <CountryFilter v-on="$listeners" />

    <div class="plankton-grid">
      <TextInputFilter
        filterName="originWildCard"
        data-test="origin-wild-card"
        :title="$t('filters/address-filter-origin-field-title')"
        class="plankton-cell"
        span="12"
      />
      <TextInputFilter
        filterName="destinationWildCard"
        data-test="destination-wild-card"
        :title="$t('filters/address-filter-dest-field-title')"
        class="plankton-cell"
        span="11"
      />
      <Button
        v-tooltip.left="$t('filters/address-filter-info-button-tooltip')"
        category="tertiary"
        style="margin-top: 16px"
        materialIcon="help"
        type="button"
      />
    </div>
    <div class="plankton-grid" v-if="$route.name !== 'es-execution-board'">
      <NumericInputFilter
        filterName="radius"
        :maxValue="200"
        data-test="radius"
        :title="$t('filters/address-filter-radius-field-title')"
        :textIcon="$t('filters/address-filter-radius-field-text-icon')"
        class="plankton-cell"
        span="24"
      />
    </div>
  </div>
</template>

<script>
import CountryFilter from '../CountryFilter/CountryFilter'
import NumericInputFilter from '../NumericInputFilter/NumericInputFilter'
import TextInputFilter from '../TextInputFilter/TextInputFilter'
import { countryCodeByCountry } from '@/modules/common/country-codes'
export default {
  components: {
    CountryFilter,
    TextInputFilter,
    NumericInputFilter
  },
  inject: ['getFilterValue', 'setFilterValue'],
  computed: {
    countryCodes: () => Object.values(countryCodeByCountry),
    destinationCountryCode: function () {
      return this.getFilterValue('destinationCountryCode')
    },
    radius: function () {
      return this.getFilterValue('radius')
    },
    originFilterHandler: {
      get() {
        return this.getFilterValue('origins')
      },
      set(value) {
        this.setFilterValue('origins', value)
      }
    },
    destinationFilterHandler: {
      get() {
        return this.getFilterValue('destinations')
      },
      set(value) {
        this.setFilterValue('destinations', value)
      }
    }
  }
}
</script>

import { monitorError } from '@/analytics/monitoring'
import { AMPLITUDE_API_KEY, MICRO_FRONTEND_MODE } from '@/config'
import { amplitude } from '@sennder/senn-node-analytics-frontend'
import packageInfo from '../../package.json'
import { AmplitudeUserProperties } from '@sennder/senn-node-analytics-frontend/lib/types'
import { getAppTheme } from '@/controllers/environment-detection'
import logger from '@/shell/console-logger'
import { analytics } from '../../core-config/orchestrated/analytics'

const EVENT_SOURCE = 'octopus'

export const initAmplitude = () => {
  try {
    if (!AMPLITUDE_API_KEY || MICRO_FRONTEND_MODE) return

    const result = amplitude.init({
      token: AMPLITUDE_API_KEY,
      appName: 'octopus',
      amplitudeConfig: {
        minIdLength: 4,
        appVersion: packageInfo.version
      }
    })

    if (!result) {
      throw new Error('Amplitude is not initialized')
    }
  } catch (error) {
    // We don't expect Amplitude to fail!
    monitorError(error)
  }
}

export const identifyAmplitudeUser = (
  userData: AmplitudeUserProperties,
  onLogin: boolean
) => {
  try {
    amplitude.identifyUser(userData, onLogin)
  } catch (error) {
    // We don't expect this to fail
    monitorError(error)
  }
}

export const trackEvent = (
  eventName: string,
  properties: Record<string, unknown> = {}
) => {
  MICRO_FRONTEND_MODE
    ? analytics.trackEvent(eventName, properties)
    : trackEventStandalone(eventName, properties)
}

export const trackEventStandalone = (
  eventName: string,
  properties: Record<string, unknown> = {}
) => {
  try {
    const enrichedProperties = {
      module: 'genericModule',
      submodule: 'genericSubmodule',
      ...properties,
      tenant: getAppTheme(),
      source: EVENT_SOURCE
    }

    logger.debug('[AMPLITUDE]', eventName, enrichedProperties)

    amplitude.trackEvent({
      name: eventName,
      properties: enrichedProperties
    })
  } catch (error) {
    // We don't expect this to fail
    monitorError(error)
  }
}

export const resetAnalyticsSession = () => {
  try {
    amplitude.endSession()
    initAmplitude()
  } catch (error) {
    // We don't expect this to fail
    monitorError(error)
  }
}

import { ILogProvider } from '@sennder/senn-node-microfrontend-interfaces'
import { MICRO_FRONTEND_MODE } from '@/config'
import logger from '../../core-config/orchestrated/logger'
import consoleLogger from '@/shell/console-logger'

/* eslint-disable no-console */
const defaultLogger: ILogProvider = {
  debug(message: string, messageContext: object, customProperties?: Array<string>) {
    consoleLogger.debug(message, messageContext, customProperties)
  },
  info(message: string, messageContext: object, customProperties?: Array<string>) {
    consoleLogger.info(message, messageContext, customProperties)
  },
  warn(message: string, messageContext: object, customProperties?: Array<string>) {
    consoleLogger.warn(message, messageContext, customProperties)
  },
  error(message: string, messageContext: object, customProperties?: Array<string>) {
    consoleLogger.error(message, messageContext, customProperties)
  },
  setUserData: function (): void {
    throw new Error('[logger] setUserData function is not implemented.')
  },
  unsetUserData: function (): void {
    throw new Error('[logger] unsetUserData function is not implemented.')
  },
  setContext: function (): void {
    throw new Error('[logger] setContext function is not implemented.')
  }
}

export const getLogger = () => {
  return MICRO_FRONTEND_MODE ? logger : defaultLogger
}

import { DETECTED_NETWORK_ERROR } from '@/controllers/custom-errors'
import User from '@/models/user'
import axios from 'axios'
import Vue from 'vue'
import { OPERATIONS_BACKEND_URL } from '@/config'
import consoleLogger from '@/shell/console-logger'

const EMPTY_RESULT = { data: {} }

export function initNetworkGlobalErrorHandling(router) {
  return axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (axios.isCancel(error)) {
        // suppress the error if the axios request has been canceled explicitly
        return new Promise(() => {})
      }
      if (!error.response) {
        // Temporary solution until we define the proper alert (SEN-1813)
        consoleLogger.debug('Network error', error) // eslint-disable-line no-console
        error.message = DETECTED_NETWORK_ERROR
        throw error
      }
      if (isUnauthorized(error)) {
        return handleUnauthorizedError(error, router)
      }
      if (isNotFound(error)) {
        throw error
      }
      if (isServerError(error)) {
        throw error
      }
      if (concurrencyError(error)) {
        showConcurrencyError()
        throw error
      }
      throw error
    }
  )
}

function handleUnauthorizedError(error, router) {
  // We just logout when the unauthorized message comes from sennder's own endpoints
  // (so we avoid redirections from third-party 401's)
  if (isSennderAPIRequest(error)) {
    // The token on the front end appears to be invalid with the back end
    User.frontendLogout()
    router.push('/login').catch(() => {}) // See comment on routerMixin.navigateTo
    return EMPTY_RESULT
  } else {
    // We throw unknown 401 errors to have them in monitoring
    throw error
  }
}

async function showConcurrencyError() {
  const errorMessage = `This order has been edited by somebody else since you loaded the page.
  You cannot save unless you refresh the page. When you refresh, you will
  lose your changes since your last save.`
  Vue.swal({
    title: 'Error',
    html: errorMessage,
    type: 'error',
    confirmButtonColor: '#ff8300',
    confirmButtonText: 'Ok'
  })
}

function isNotFound(error) {
  return error.response.status === 404
}

function isUnauthorized(error) {
  return error.response.status === 401
}

function isSennderAPIRequest(error) {
  return error.config && error.config.url.includes(OPERATIONS_BACKEND_URL)
}

function isServerError(error) {
  return [500, 501, 502, 503].includes(error.response.status)
}

function concurrencyError(error) {
  return error.response.status === 409
}

import { MutationTree } from 'vuex'

import { getInitialState, ICommonState } from './state'
import { Currency } from '@sennder/design-system-core/dist/functions/formatCurrency/types'
import { ICurrencyExchangeRate } from '../models/currencies'

export type SetExchangeRateMutation = (
  state: ICommonState,
  payload: { currency: Currency; rate: ICurrencyExchangeRate }
) => void
const setExchangeRate: SetExchangeRateMutation = (state, payload): void => {
  const { currency, rate } = payload

  state.exchangeRates[currency] = rate
}

export type ResetStateMutation = (state: ICommonState) => void
export const resetState: ResetStateMutation = (state: ICommonState): void => {
  Object.assign(state, getInitialState())
}

export type MutationsType = {
  setExchangeRate: SetExchangeRateMutation
  resetState: ResetStateMutation
}

export const mutations: MutationTree<ICommonState> & MutationsType = {
  setExchangeRate,
  resetState
}

import { fetchFilteredOrders } from '@/services/ordering-service'
import ServiceTransaction from '@/services/utils/ServiceTransaction'
import { getLogger } from '@/shell/datadog-logger'

const LOGGER_KEY = '[ADO#84413]'

let currentTransaction = null

/**
 * Combines user filtering and pre-defined customer (shipper) filtering.
 * @param {*} fixedIds - list of customer ids always enabled for the user.
 * @param {*} filteredIds - list of customer ids selected by the user for filtering
 * @returns undefined or array of strings
 * Note: if customer_ids is defined as empty array, backend will return no results.
 */
const getCustomerFilter = (fixedIds, filteredIds) => {
  const isFixedIdsDefined = Array.isArray(fixedIds) && fixedIds.length
  const isFilteredIdsDefined = Array.isArray(filteredIds) && filteredIds.length

  // No customer (shipper) filtering needed - return undefined
  if (!isFixedIdsDefined && !isFilteredIdsDefined) return undefined

  if (isFixedIdsDefined) {
    if (isFilteredIdsDefined) {
      const result = filteredIds.filter(id => fixedIds.includes(id))
      getLogger().info(`${LOGGER_KEY} Filtering applied with selected shippers`, {
        filteredIds,
        resultIds: result
      })

      // If there are some user selected filters, check return only once available in preset
      return result
    } else {
      getLogger().info(`${LOGGER_KEY} Filtering applied with default preset`, {})

      // If there is no user selected filters, just return fixed preset
      return fixedIds
    }
  } else {
    // If there is no filtered preset, just return user selected filters
    return filteredIds
  }
}

export const loadOrdersPage = async (
  store,
  { offset, limit, clearBeforeUpdate, fixedCustomerIds, excludedCustomerIds }
) => {
  if (currentTransaction) await currentTransaction.cancel()
  store.commit('startLoadingPage', null, { root: true })
  let newOrders = []
  const apiFilters = store.getters['filters/getFiltersForApi']
  try {
    currentTransaction = new ServiceTransaction()
    if (clearBeforeUpdate) store.commit('clearFilteredOrders')
    newOrders = await fetchFilteredOrders(
      {
        ...apiFilters,
        customer_ids: getCustomerFilter(fixedCustomerIds, apiFilters.customer_ids),
        exclude_customer_ids: excludedCustomerIds,
        limit,
        offset
      },
      currentTransaction
    )
    store.commit('addFilteredOrders', newOrders.data.orders)
  } finally {
    currentTransaction = null
    store.commit('finishLoadingPage', null, { root: true })
  }
  return newOrders // to find out when we're out of orders
}

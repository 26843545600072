import Toaster from './Toaster.vue'
import MessageToaster from './MessageToaster.vue'

/**
 * Register plugin's components
 *
 * @param {*} Vue
 */
function registerComponents(Vue) {
  Vue.component('Toaster', Toaster)
}

/**
 * Create the container for toast messages
 *
 * @returns {HTMLDivElement} container for toast messages
 */
function createToastsContainer() {
  const container = document.createElement('div')
  container.classList = 'toaster-container'
  document.body.appendChild(container)

  return container
}

/**
 * This mixin adds cleanup code to the instance which will
 * then remove the toast message from the screen
 */
const dismissMixin = {
  methods: {
    async dismiss() {
      await this.$children[0].dismiss()
      this.$destroy()
      this.$el.remove()
    }
  }
}

/**
 * Create a method that shows the toast message. It is later on
 * bound to the view instance so it is available from
 *
 * @param {*} Vue instance
 * @param {HTMLDivElement} container for the toast messages
 */
const showToastMessage =
  (Vue, container) =>
  async (componentDefinitionOrMessage = 'Toast content', propsData = { timeout: 0 }) => {
    let componentDefinition = componentDefinitionOrMessage

    // allow for string toast to be displayed as a default behavior
    if (typeof componentDefinition === 'string') {
      propsData = { message: componentDefinitionOrMessage, ...propsData }
      componentDefinition = MessageToaster
    }

    // extend the given component with additional default behavior
    const definition = {
      ...componentDefinition,
      mixins: [...(componentDefinition.mixins || []), dismissMixin]
    }

    // construct component class
    const Component = Vue.extend(definition)

    // instantiate new component
    const component = new Component({
      el: document.createElement('div'),
      propsData
    })

    // calling $mount() without parameters creates an instance of
    // the component without mounting it to the DOM.
    // Sort of like the teleport component in Vue 3.0.
    // As an effect it creates a separate vue application instance
    // because in Vue even the application is a component that just
    // happens to be manually mounted (see main.ts:156)
    //
    // https://vuejs.org/v2/api/#vm-mount
    component.$mount()

    function isElement(o) {
      return typeof HTMLElement === 'object'
        ? o instanceof HTMLElement
        : o &&
            typeof o === 'object' &&
            o != null &&
            o.nodeType === 1 &&
            typeof o.nodeName === 'string'
    }

    if (isElement(component.$el)) {
      // this is why we're inserting that element manually here
      container.insertAdjacentElement('afterbegin', component.$el)
      await component.$children[0].show()
    }
  }

export let useToast = null

export default {
  install(Vue) {
    registerComponents(Vue)

    const container = createToastsContainer()
    const toast = showToastMessage(Vue, container)

    // make the toasts available for composition api
    useToast = toast

    // make the $toast message available to every component
    Vue.$toast = toast
    Vue.prototype.$toast = toast
  }
}

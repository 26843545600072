import Vue from 'vue'

export const addFilteredOrders = (state, orders) => {
  state.orders.push(...orders)
}

export const updateOrderState = (state, { id, state: orderState }) => {
  const orderIndex = state.orders.findIndex(order => order.id === id)
  state.orders[orderIndex].state = orderState
  Vue.set(state.orders, orderIndex, state.orders[orderIndex])
}

export const clearFilteredOrders = state => {
  state.orders = []
}

import moment from 'moment-timezone'
import { DATE_FORMAT } from '@/global-setup/filters'

export const sevenDaysAgo = () => moment().subtract(7, 'days').format(DATE_FORMAT)

export const inSevenDays = () => moment().add(7, 'days').format(DATE_FORMAT)

export const lastSunday = () => moment().startOf('week').format(DATE_FORMAT)

export const yesterday = () => moment().subtract(1, 'days').format(DATE_FORMAT)

export const today = () => moment().format(DATE_FORMAT)

export const currentWeekSaturday = () => moment().endOf('week').format(DATE_FORMAT)

export const tomorrow = () => moment().add(1, 'days').format(DATE_FORMAT)

export const dayAfterTomorrow = () => moment().add(2, 'days').format(DATE_FORMAT)

export const inOneMonth = () => moment().add(1, 'month').format(DATE_FORMAT)

export const inOneYear = () => moment().add(1, 'years').format(DATE_FORMAT)

export const inOneYearISOdate = () => moment().add(1, 'years').format()

export const todayISOdate = () => moment().format()

export const tomorrowISOdate = () => moment().add(1, 'days').format()








































import { PropType, defineComponent } from '@vue/composition-api'
import get from 'lodash/get'
import { placeholderIfEmpty } from '@/global-setup/filters'
import { OrderDetail } from '@/services'
import LabeledText from '@/modules/common/components/LabeledText.vue'

export default defineComponent({
  components: { LabeledText },
  props: {
    order: { type: Object as PropType<OrderDetail>, required: true }
  },
  setup() {
    return { get, placeholderIfEmpty }
  }
})

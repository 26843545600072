import Model from '@/models/model'
import Person from '@/models/person'
import moment from 'moment'

export default class OrderGroupLog extends Model {
  static get NAME() {
    return 'logs'
  }

  get formattedTime() {
    return moment(this.created).format('HH:mm | DD/MM/YY')
  }

  get userInitials() {
    const user = this.$state.users[this.user_id]
    if (user) return Person.getPersonInitials(user.person_id)
    else return null
  }

  get documentIsGenerated() {
    return this.log_type == 'DOCUMENT_GENERATED'
  }

  get documentIsSent() {
    return this.log_type == 'DOCUMENT_SENT'
  }
}

import { computed, ComputedRef, Ref } from '@vue/composition-api'
import uniq from 'lodash/uniq'
import groupBy from 'lodash/groupBy'
import getAlphabetEquivalent from '@/compositions/transfer/useStopLetter'
import H from '@here/maps-api-for-javascript'
import { StopoverType } from '@/services/enums'
import { StopLocation } from '../../../types'

interface StopsData {
  stopLocations: Ref<StopLocation[]>
  multipleStopLetters: Ref<string[]>
  stopsCompletion: Ref<boolean[]>
  isRoundTrip: Ref<boolean>
}

interface MappedStop {
  coords: {
    lng: number
    lat: number
  }
  type: StopoverType
  letter: string
  isDone: boolean
}

export default ({
  stopLocations,
  multipleStopLetters,
  stopsCompletion,
  isRoundTrip
}: StopsData) => {
  const getStopIcon = (letter: string, stopType: StopoverType) => {
    const loadingMarker = `<span class="map-marker-label loading">${letter}</span>
                              <img src="../images/point.svg" class="map-marker-image" />`
    const unloadingMarker = `<span class="map-marker-label unloading">${letter}</span>
                                  <img src="../images/inverted-point.svg" class="map-marker-image" />`

    const customMarker = `<div class="map-marker">${
      stopType === StopoverType.LOADING ? loadingMarker : unloadingMarker
    }</div>`

    const icon = new H.map.DomIcon(customMarker)
    return icon
  }

  const createStopMarker = (stop: MappedStop) => {
    const marker = new H.map.DomMarker(stop.coords, {
      data: null,
      icon: getStopIcon(stop.letter, stop.type)
    })

    return marker
  }

  const mappedStops = computed(() =>
    stopLocations.value.map((stop, index) => ({
      coords: {
        lng: stop.lng,
        lat: stop.lat
      },
      type: stop.type,
      letter: multipleStopLetters.value[index] ?? getAlphabetEquivalent(index),
      isDone: stopsCompletion.value[index] ?? null
    }))
  )

  const ongoingLocations: ComputedRef<MappedStop[]> = computed(() => {
    const uniqueLetters = uniq(multipleStopLetters.value)
    const groupedStops = groupBy(mappedStops.value, 'letter')

    const roundTripLocations = uniqueLetters.map(letter => {
      const stopsByLetter = groupedStops[letter]
      if (!stopsByLetter || stopsByLetter.length === 0)
        throw Error(`The letter ${letter} is not assigned to any stop`)
      const stopNotDone = stopsByLetter.find(stop => stop.isDone === false)
      const lastStopByLetter = stopsByLetter[stopsByLetter.length - 1]
      return stopNotDone || lastStopByLetter
    })

    return isRoundTrip.value ? roundTripLocations : mappedStops.value
  })

  const stopMarkers = computed(() =>
    ongoingLocations.value.map(stop => createStopMarker(stop))
  )

  return stopMarkers
}

import { getCookie, setCookie, removeCookie } from 'tiny-cookie'

export const USER_ID_COOKIE_NAME = 'user_id_octopus'

export const getCookieValue = () => getCookie(USER_ID_COOKIE_NAME)

export const setCookieValue = (userId: number) =>
  setCookie(USER_ID_COOKIE_NAME, String(userId), { expires: '7D' })

export const removeCookieValue = () => removeCookie(USER_ID_COOKIE_NAME)

const createInitialState = () => ({
  currentUser: {
    email: null,
    full_name: null,
    group_names: [],
    id: null,
    level: null,
    permissions: []
  }
})

export default createInitialState

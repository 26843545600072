<template>
  <DropdownField
    v-model="filterHandler"
    searchable
    taggable
    :visibleSelectedOptionsLimit="1"
    multiple
    :options="filterHandler"
    :title="$t('filters/multi-reference-filter-title')"
    :placeholder="$t('filters/multi-reference-filter-placeholder')"
    data-test="multi-reference-filter"
    class="plankton-cell"
    span="24"
    closingTag
    showDeselectOption
    :maxSelection="100"
    :closeOnSelect="false"
    v-tooltip.bottom="$t('filters/multi-reference-filter-tooltip')"
  />
</template>

<script>
export default {
  inject: ['getFilterValue', 'setFilterValue'],
  computed: {
    filterHandler: {
      get() {
        return this.getFilterValue('referenceNumbers')
      },
      set(options) {
        this.setFilterValue('referenceNumbers', options)
      }
    }
  }
}
</script>

import Model from '@/models/model'
import { OPERATIONS_BACKEND_URL } from '@/config'
export default class OrderGroup extends Model {
  static get NAME() {
    return 'order_groups'
  }
  static get URL() {
    return `${OPERATIONS_BACKEND_URL}/ordering/order_groups/`
  }
}

<template>
  <div data-test="dispatching-carrier-cell">
    <div v-if="shouldShowLastAssignmentCarrier">
      {{ carrierLastAssignmentForOrder }}
    </div>
    <div v-else>
      <span class="carrier-name">{{ carrierName }}</span>
    </div>
  </div>
</template>

<script>
import useAssignmentDetails from '@/modules/search-board/compositions/useAssignmentDetails'
export default {
  props: {
    order: { type: Object, default: null }
  },
  setup() {
    const { lastAssignmentForOrder } = useAssignmentDetails()

    return {
      lastAssignmentForOrder
    }
  },

  computed: {
    shouldShowLastAssignmentCarrier() {
      return (
        !this.order.carrier &&
        this.order.state === 'REGISTERED' &&
        this.lastAssignmentForOrder(this.order) &&
        this.lastAssignmentForOrder(this.order).state !== 'REJECTED' &&
        this.lastAssignmentForOrder(this.order).state !== 'CANCELLED'
      )
    },
    carrierName() {
      return this.order.carrier
        ? this.order.carrier.name || this.order.carrier.company_name
        : '-'
    },
    carrierLastAssignmentForOrder() {
      return (
        this.lastAssignmentForOrder(this.order).carrier.name ||
        this.lastAssignmentForOrder(this.order).carrier.company_name
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.carrier-name {
  display: block;
  max-height: 35px;
  overflow: hidden;
}
</style>

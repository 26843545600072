import {
  capitalize,
  formatDateWithTz,
  formatTimeWithTz,
  getTimezoneAcronym
} from '@/global-setup/filters'
import Order from '@/models/order.js'
import { VEHICLE_TYPES } from '@sennder/octopus-constants'
import { addressLongFormat } from '@/modules/common/filters/address-filters'
import getAlphabetEquivalent from '@/compositions/transfer/useStopLetter'
import { ORCAS_URL } from '@/config'

export const humanizeState = order => Order.STATES_HUMAN[order.state]

export const humanizeStateShort = order => Order.STATES_HUMAN_SHORT[order.state]

export const humanizeAssignmentState = order =>
  Order.ASSIGNMENT_STATES[order.lastAssignment.state]

export const humanizeVehicleType = vehicleType => VEHICLE_TYPES[vehicleType]

export const humanizeVehicleTypes = vehicleTypes => {
  let humanizedValue = vehicleTypes.reduce((accumulator, currentValue) => {
    return accumulator + humanizeVehicleType(currentValue) + ', '
  }, '')

  return humanizedValue ? humanizedValue.slice(0, -2) : humanizedValue
}
export const humanizeRequirements = requirements => {
  const formatted = []
  if (requirements.needsPalletExchange) formatted.push('Pallet Exchange')
  if (requirements.arrivalNotification) formatted.push('Arrival Notice')
  if (requirements.dockLoading) formatted.push('Ramp Ability')
  if (requirements.directTransfer) formatted.push('Do Not Reload')
  if (requirements.sealable) formatted.push('Seal')
  if (requirements.sideLoading) formatted.push('Side Loading')
  if (requirements.topLoading) formatted.push('Top Loading')
  if (requirements.codeXl) formatted.push('Code XL')

  return formatted.length > 0 ? formatted.join(' | ') : 'None'
}

export const humanizeSpecification = specification => {
  const formatted = []
  formatted.push(
    specification.vehicleType ? humanizeVehicle(specification) : 'No Vehicle'
  )
  if (specification.loadWeight) {
    formatted.push(specification.loadWeight + ' ton(s)')
  } else {
    formatted.push('Weight not Specified')
  }

  formatted.push(formatDimensions(specification))

  if (specification.loadQuantity) {
    const loadUnitString = humanizeLoadUnitType(specification) || '(No unit specified)'
    formatted.push(specification.loadQuantity + ' ' + loadUnitString)
  } else {
    formatted.push('Quantity not Specified')
  }

  return formatted.join(' | ')
}

export const getOrderDetailsForClipboard = order => {
  let content = ''

  if (order.stopovers) {
    order.stopovers.forEach((stop, index) => {
      content += getOrderStopsDetails(stop, getAlphabetEquivalent(index))
    })
  }

  content += getOrderVehicleDetails(order)

  if (order.specification.isTemperatureControlled) {
    content += getOrderTemperatureDetails(order)
  }

  content += getOrderSpecificationDetails(order.specification)

  content += getOrderRequirementsDetails(order)

  if (order.loadDescription) {
    content += `Description: ${order.loadDescription}${generateLineBreaks(2)}`
  }

  content += `Access the order in orcas here : ${getOrcasOrderLink(order.idForStaff)}`
  content += `${generateLineBreaks(1)}---${generateLineBreaks(2)}`

  return content
}

function getOrderStopsDetails(stop, letter) {
  let content = ''
  const timezone = stop.warehouseAddress.timezone
  content += `${letter}. ${capitalize(stop.stopoverType)}: ${addressLongFormat(
    stop.warehouseAddress
  )}${generateLineBreaks(1)}`
  content += `Date ${formatDateWithTz(
    stop.arrivalToWarehouse,
    timezone
  )} (${formatTimeWithTz(stop.arrivalToWarehouse, timezone)}-${formatTimeWithTz(
    stop.departureFromWarehouse,
    timezone
  )} ${getTimezoneAcronym(stop.arrivalToWarehouse, timezone)})${generateLineBreaks(1)}`
  return content
}

function getOrderVehicleDetails(order) {
  return `${generateLineBreaks(1)}Accepted Vehicles: ${humanizeVehicleTypes(
    order.allowedVehicleTypes
  )}${generateLineBreaks(1)}`
}

function getOrderTemperatureDetails(order) {
  return `Temperature: ${order.specification.loadMinimumTemperature}°C / ${
    order.specification.loadMaximumTemperature
  }°C${generateLineBreaks(1)}`
}

function getOrderSpecificationDetails(specification) {
  const formatted = []
  if (specification.loadQuantity) {
    const loadUnitString = humanizeLoadUnitType(specification) || '(No unit specified)'
    formatted.push(specification.loadQuantity + ' ' + loadUnitString)
  } else {
    formatted.push('Quantity not Specified')
  }

  if (specification.loadWeight) {
    formatted.push(specification.loadWeight + ' ton(s)')
  } else {
    formatted.push('Weight not Specified')
  }

  formatted.push(formatDimensions(specification))

  return `Load: ${formatted.join(' | ')}${generateLineBreaks(1)}`
}

function getOrderRequirementsDetails(order) {
  return `Other requirements: ${humanizeRequirements(
    order.requirements
  )}${generateLineBreaks(1)}`
}

function getOrcasOrderLink(idForStaff) {
  return `${ORCAS_URL}/order-details/${idForStaff}`
}

function generateLineBreaks(count) {
  return LINE_BREAK.repeat(count)
}

function humanizeVehicle(specification) {
  const { allowedVehicleTypes, vehicleType } = specification
  const tempControlled = specification.isTemperatureControlled
    ? temperatureIndication(specification)
    : ''

  if (!allowedVehicleTypes) {
    return tempControlled
      ? `${humanizeVehicleType(vehicleType)} ${tempControlled}`
      : humanizeVehicleType(vehicleType)
  } else if (allowedVehicleTypes.length >= 1) {
    const FRIGO = VEHICLE_TYPES['TRUCK_40_FRIGO']
    const types = allowedVehicleTypes.map(humanizeVehicleType)
    return types
      .sort()
      .map(type => {
        if (type === FRIGO) return `${type} ${tempControlled}`
        return type
      })
      .join(', ')
  }
}

function temperatureIndication(specification) {
  return (
    `from ${specification.loadMinimumTemperature} °C ` +
    `to ${specification.loadMaximumTemperature} °C`
  )
}

function humanizeLoadUnitType(specification) {
  return Order.LOAD_UNIT_TYPES[specification.loadUnitType]
}

function formatDimensions(specification) {
  if (noDimensions(specification)) return 'No Dimensions'

  const formatted = []
  formatted.push(
    specification.loadLength ? '(L)' + specification.loadLength + 'cm' : 'no length'
  )
  formatted.push(
    specification.loadHeight ? '(H)' + specification.loadHeight + 'cm' : 'no height'
  )
  formatted.push(
    specification.loadWidth ? '(W)' + specification.loadWidth + 'cm' : 'no width'
  )

  return formatted.join(' x ')
}

function noDimensions(specification) {
  return (
    !specification.loadLength && !specification.loadHeight && !specification.loadWidth
  )
}

const LINE_BREAK = '\r\n'

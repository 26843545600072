import { computed, Ref } from '@vue/composition-api'
import useExecutionStops from '@/compositions/transfer/useExecutionStops'
import useExecutionSteps from '@/compositions/transfer/useExecutionSteps'
import { StepType } from '@/services/enums'
import moment from 'moment-timezone'
import omit from 'lodash/omit'
import { Timestamp } from '@sennder/plankton'
import useMultipleLetters from '@/compositions/transfer/useMultipleLetters'
import { TransferStep, TransferStopover } from '@/services'
import { i18n } from '@/plugins/i18n'
import { TranslateResult } from 'vue-i18n'

export default (
  steps: Ref<TransferStep[]>,
  isOrderCancelled: Ref<boolean>,
  delayMinutes: Ref<number>,
  locations: Ref<unknown[]>, // we just check the array emptiness so we don't care much of its structure
  stops?: Ref<TransferStopover[]>
) => {
  const { getStopTypeByIndex } = useExecutionStops(stops)
  const {
    isFirstStepDone,
    arrivedToLastStop,
    isLastStepDone,
    willTransportStartLater,
    nextStep,
    nextArrivalStep,
    currentStopIndex,
    isArrivalEstimatedTimeInTheFuture
  } = useExecutionSteps(steps)

  const hasGpsReceived = computed(() => Boolean(locations.value.length))

  // fallback for execution board
  const getStopTypeByStepName = computed(() =>
    nextStep.value.name.match(/unloading/g) ? 'UNLOADING' : 'LOADING'
  )

  /**
   * @returns {computed<'UNLOADING'|'LOADING'>}
   */
  const currentStopType = computed(() =>
    stops ? getStopTypeByIndex(currentStopIndex.value) : getStopTypeByStepName.value
  )

  const { multipleStopLetters } = useMultipleLetters(steps || stops)

  const transportStatus = computed(() => {
    const stopLetter = multipleStopLetters.value[currentStopIndex.value]
    if (isOrderCancelled.value) {
      return {
        label: i18n.t('ordering/execution-transport-status-cancelled'),
        class: 'cancelled'
      }
    } else if (isLastStepDone.value) {
      return {
        label: i18n.t('ordering/execution-transport-status-completed'),
        class: 'completed'
      }
    } else if (!steps.value.length) {
      // fallback for execution board
      return {
        label: i18n.t('ordering/execution-transport-status-not-started'),
        class: 'not-started'
      }
    } else if (
      (!isFirstStepDone.value && !hasGpsReceived.value) ||
      willTransportStartLater.value
    ) {
      return {
        label: i18n.t('ordering/execution-transport-status-not-started'),
        class: 'not-started'
      }
    } else if (nextStep.value?.type === StepType.DEPARTURE) {
      const stopLabels = {
        LOADING: 'Loading',
        UNLOADING: 'Unloading'
      }

      const stopType = stopLabels[currentStopType.value]

      return {
        label: i18n.t('ordering/execution-transport-status-ongoing-departure', {
          stopType,
          stopLetter
        }),
        class: 'on-the-way'
      }
    } else if (nextStep.value?.type === StepType.ARRIVAL) {
      return {
        label: i18n.t('ordering/execution-transport-status-ongoing-arrival', {
          stopLetter
        }),
        class: 'on-the-way'
      }
    } else return { label: '-', class: '' } // a fallback that should not ever be called
  })

  const etaToNextStop = computed(() => {
    if (willTransportStartLater.value) {
      // more than 2 hours before scheduled start
      return i18n.t('ordering/execution-eta-next-stop-start-later')
    } else if (!hasGpsReceived.value || arrivedToLastStop.value) {
      // no GPS pos received or arrived to the last stop
      return i18n.t('ordering/execution-eta-next-stop-no-info')
    } else if (hasGpsReceived.value && !nextArrivalStep.value?.estimatedTime) {
      // if the ETA isn't calculated yet
      return i18n.t('ordering/execution-eta-next-stop-coming-soon')
    } else {
      // on it's way somewhere in the middle
      const estimatedTime = moment(nextArrivalStep.value.estimatedTime)
      /**
       * @type {string} HH:mm
       */
      //display the ETA in browser timezone
      const localNextStepEstimatedTime = new Timestamp(
        nextArrivalStep.value.estimatedTime,
        moment.tz.guess()
      ).time
      const duration = moment.duration(estimatedTime.diff(moment()))
      let formattedDuration: string | TranslateResult = ''
      const durationDays = Math.floor(duration.asDays())
      if (durationDays) formattedDuration += `${durationDays}d `
      const durationHours = duration.hours()
      if (durationHours) formattedDuration += `${durationHours}h `
      const durationMinutes = duration.minutes()
      if (durationMinutes) formattedDuration += `${durationMinutes}min`
      const durationSeconds = duration.asSeconds()

      if (durationSeconds < 60 && durationSeconds > 0)
        formattedDuration = i18n.t('ordering/execution-eta-next-stop-seconds')

      return isArrivalEstimatedTimeInTheFuture.value
        ? i18n.t('ordering/execution-eta-next-stop-timestamp', {
            formattedDuration,
            localNextStepEstimatedTime
          })
        : i18n.t('ordering/execution-eta-next-stop-now')
    }
  })

  const delayStatus = computed(() => {
    const delayConditions = [
      {
        expression: delayMinutes.value > 0,
        message: i18n.t('ordering/execution-delay-status-delayed', {
          delayTime: moment.duration(delayMinutes.value, 'minutes').humanize()
        }),
        class: 'delayed'
      },
      {
        expression: delayMinutes.value === 0,
        message: i18n.t('ordering/execution-delay-status-on-time'),
        class: 'on-time'
      }
    ]

    const foundCondition = delayConditions.find(
      condition => condition.expression === true
    )
    return foundCondition
      ? omit(foundCondition, ['expression'])
      : { message: '', class: 'no-info' }
  })

  return { transportStatus, etaToNextStop, delayStatus }
}

<template>
  <DropdownField
    v-model="vehicleTypeHandler"
    :options="vehicleTypeOptions"
    :visibleSelectedOptionsLimit="3"
    :close-on-select="false"
    multiple
    optionLabel="label"
    optionKey="id"
    openDirection="top"
    :title="$t('filters/vehicle-types-filter-field-title')"
    style="width: 100%"
    data-test="vehicle-types-filter"
  />
</template>

<script>
import { VEHICLE_TYPES } from '@sennder/octopus-constants'

export default {
  inject: ['getFilterValue', 'setFilterValue'],
  computed: {
    vehicleTypeOptions() {
      return Object.entries(VEHICLE_TYPES).map(([id, label]) => ({
        label,
        id
      }))
    },
    vehicleTypeHandler: {
      get() {
        return this.getFilterValue('vehicleTypes')
      },
      set(value) {
        this.setFilterValue('vehicleTypes', value)
        this.$emit('applyFilters')
      }
    }
  }
}
</script>

<template>
  <div ref="dropArea" class="upload-section">
    <p class="upload-text">
      <span class="material-icons">cloud_upload</span>
      <span>
        {{ $t('upload/drag-and-drop-uploader-message_1') }}
        <a ref="browseFilesButton" href="#">
          {{ $t('upload/drag-and-drop-uploader-message_2') }}
        </a>
      </span>
    </p>
  </div>
</template>

<script>
import Dropzone from 'dropzone'
import noop from 'lodash/noop'

export default {
  name: 'DragAndDropUploader',
  props: {
    url: { type: String, required: true },
    mimeTypes: { type: Array, required: false, default: () => [] },
    onFileAdded: { type: Function, required: false, default: noop },
    onFileError: { type: Function, required: false, default: noop },
    onValidateFile: { type: Function, required: false, default: noop }
  },
  data() {
    return { draggingFile: false }
  },
  mounted() {
    this.createDropzoneAndEventListeners()
  },
  methods: {
    createDropzoneAndEventListeners() {
      this.documentEventListeners = [
        { event: 'dragenter', handler: this.enableFileDragging },
        { event: 'dragover', handler: this.enableFileDragging },
        { event: 'dragleave', handler: this.disableFileDragging },
        { event: 'drop', handler: this.disableFileDragging }
      ]
      const dropzone = new Dropzone(this.$refs.dropArea, {
        url: this.url,
        previewsContainer: false,
        clickable: this.$refs.browseFilesButton,
        acceptedFiles: this.mimeTypes.join(','),
        accept: this.acceptFile,
        hiddenInputContainer: this.$refs.dropArea,
        dictInvalidFileType: 'INVALID_FILE_TYPE',
        dictFileTooBig: 'INVALID_FILE_SIZE',
        parallelUploads: 3,
        timeout: 0,
        autoProcessQueue: false
      })
      this.registerDropzoneEvents(dropzone)

      this.documentEventListeners.forEach(({ event, handler }) =>
        document.addEventListener(event, handler)
      )
    },
    enableFileDragging(e) {
      e.preventDefault()
      this.draggingFile = true
    },
    disableFileDragging(e) {
      e.preventDefault()
      this.draggingFile = false
    },
    async acceptFile(file, done) {
      try {
        await this.onValidateFile(file)
        done()
      } catch (error) {
        done(error)
      }
    },
    handleFileAdded(file, dropzone) {
      return this.onFileAdded({
        ...file,
        dropzone,
        cancelUpload: () => dropzone.removeFile(file)
      })
    },
    registerDropzoneEvents(dropzone) {
      dropzone.on('dragover', this.enableFileDragging)
      dropzone.on('dragleave', this.disableFileDragging)
      dropzone.on('drop', this.disableFileDragging)
      dropzone.on('addedfile', file => this.handleFileAdded(file, dropzone))
      dropzone.on('error', this.onFileError)
    }
  }
}
</script>

<style lang="css" scoped>
.upload-section {
  height: 115px;
}

.upload-text {
  display: flex;
  align-items: center;
}

.upload-text > span {
  padding-right: 8px;
}
</style>

























import { defineComponent } from '@vue/composition-api'
import { usePalletExchangeConfiguration } from '@/modules/ordering/components/shared/pallet-exchange'
import LabeledText from '@/modules/common/components/LabeledText.vue'

export default defineComponent({
  components: { LabeledText },
  props: {
    orderId: { type: Number, required: true }
  },
  setup(props) {
    const {
      isTypeWithAddress: palletExchangeAddressExists,
      typeStr: palletExchangeTypeText,
      addressStr: palletExchangeAddressText,
      connectConfigWithOrder: connectPalletExchangeConfigurationWithOrder
    } = usePalletExchangeConfiguration()

    connectPalletExchangeConfigurationWithOrder(() => props.orderId)

    return {
      palletExchangeTypeText,
      palletExchangeAddressText,
      palletExchangeAddressExists
    }
  }
})

import bus from '@/bus'
import logger from '@/shell/console-logger'

import { StepToUpdate, TransferStep } from '@/services'
import {
  getExecutionPlan,
  upsertStopExecutionsAfterExecutedState,
  upsertStopExecutionsByShipmentId
} from '@/services/transit-service'
import { useTransitMappers } from '@/compositions/transit/useTransitMappers'
import { isSennderTheme } from '@/controllers/environment-detection'
import useStore from '@/compositions/useStore'
import useCurrentUser from '@/compositions/useCurrentUser'
import { isOrderExecutedOrCompleted } from '@/modules/common/order-states'
import { AlertModal } from '@sennder/plankton'
import Swal from 'sweetalert2'

export default () => {
  const store = useStore()
  const orderId = store.getters['ordering/getOrderId']
  const orderState = store.getters['ordering/getOrderState']

  const orderContainsShipment = store.getters['ordering/orderContainsShipment']
  const { mapStopPlansToStopExecutions } = useTransitMappers()

  const getShipmentID = async () => {
    try {
      if (!orderContainsShipment) {
        await store.dispatch('ordering/fetchShipmentData', orderId)
      }
    } catch (error) {
      logger.error(
        `Unable to execute setShipmentData, shipment service failed for order_id: ${orderId} ${error}`
      )
    }
    return store.getters['ordering/getShipmentID']
  }

  const displayErrorModal = (error: Error): void => {
    const alertModal = AlertModal(Swal)
    alertModal.showErrorMessage('Error', JSON.parse(error.message).detail)
  }

  const isPostExecutionWriteEnabled = (): boolean => {
    const { currentUserIsOps } = useCurrentUser()
    return (
      currentUserIsOps.value && isOrderExecutedOrCompleted(orderState) && isSennderTheme
    )
  }

  /**
   * Maps Steps/StopPlans to Stop Executions and calls Transit service / mothership
   * triggered on 'Save'/'Close Execution' action
   * @param steps - for transit
   * @param transferId - for mothership
   * @param transferVersion - for mothership
   * @param stepsToUpdate - for mothership
   * @param originalSteps - fetched from mothership on first load
   */
  const saveStopExecutions = async (
    steps: TransferStep[],
    transferId: number,
    transferVersion: number,
    stepsToUpdate: StepToUpdate[],
    originalSteps: TransferStep[]
  ) => {
    if (isSennderTheme) {
      const shipmentId = await getShipmentID()
      if (shipmentId) {
        try {
          const executionPlan = await getExecutionPlan(shipmentId)
          const stopExecutions = mapStopPlansToStopExecutions(
            executionPlan.stopPlans,
            steps,
            originalSteps
          )
          if (isPostExecutionWriteEnabled()) {
            await upsertStopExecutionsAfterExecutedState(stopExecutions, shipmentId)
          } else {
            await upsertStopExecutionsByShipmentId(stopExecutions, shipmentId)
          }
          bus.emit('transfer-steps-actual-times-updated', transferId)
          return
        } catch (error) {
          logger.error(
            `Transit error: Unable to save stop executions: ${error} with params steps: ${JSON.stringify(
              steps
            )}, stepsToUpdate: ${JSON.stringify(stepsToUpdate)}`
          )
          if (error?.response?.status === 406) {
            displayErrorModal(error)
            return
          }
        }
      }
    }
  }

  return {
    saveStopExecutions,
    getShipmentID
  }
}

import Person from '@/models/person'
import moment from 'moment'
import { OPERATIONS_BACKEND_URL } from '../config'
import Model from './model'

export default class Note extends Model {
  static get NAME() {
    return 'notes'
  }
  static get URL() {
    return `${OPERATIONS_BACKEND_URL}/ordering/notes/`
  }

  get formatted_time() {
    return moment(this.created).format('HH:mm | DD/MM/YY')
  }

  get author_initials() {
    return Person.getPersonInitials(this.$state, this.author_id)
  }
}

<template>
  <DropdownField
    v-model="filterHandler"
    :options="orderSources"
    :close-on-select="false"
    :clear-on-select="true"
    multiple
    searchable
    :title="$t('filters/order-source-filter-field-title')"
    data-test="order-source-filter"
    fullWidth
  />
</template>

<script>
import { ORDER_SOURCES } from '../../constants'
export default {
  inject: ['getFilterValue', 'setFilterValue'],
  data: () => ({
    orderSources: ORDER_SOURCES
  }),
  computed: {
    filterHandler: {
      get() {
        return this.getFilterValue('orderSources')
      },
      set(payload) {
        this.setFilterValue('orderSources', payload)
        this.$emit('applyFilters')
      }
    }
  }
}
</script>

<template>
  <BoardTableRow types="nohover">
    <td colspan="100">
      <loading-spinner />
    </td>
  </BoardTableRow>
</template>

<script>
import BoardTableRow from './BoardTableRow'

export default {
  components: { BoardTableRow }
}
</script>

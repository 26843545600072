






















import { defineComponent, toRef, computed } from '@vue/composition-api'

import useOrderPaymentStatus from '@/modules/ordering/compositions/legacy/useOrderPaymentStatus'
import fetchDataOnMounted from '@/modules/ordering/compositions/legacy/useFetchDataOnMounted'

import useCurrentUser from '@/compositions/useCurrentUser'

import CarrierOrderPayment from '@/modules/ordering/components/legacy/carrier-order-payment/CarrierOrderPayment.vue'
import OctopusPaymentsWidget from '@/modules/ordering/components/legacy/OctopusPaymentsWidget.vue'
import {
  MicrofrontendEvents,
  PaymentStatusUpdateSideEffect,
  publish
} from '@sennder/senn-node-microfrontend-event-bus'

export default defineComponent({
  components: {
    CarrierOrderPayment,
    OctopusPaymentsWidget
  },
  props: {
    orderId: { type: Number, required: true },
    order: { type: Object, required: true },
    /**
     * Need it for watching for changes in the order
     */
    isCancelled: { type: Boolean, default: false },
    hideCustomerCharges: { type: Boolean, default: false }
  },
  setup(props, context) {
    const orderId = toRef(props, 'orderId')
    const { paymentInfo, fetchPaymentInformation } = useOrderPaymentStatus(orderId)
    const { currentUserIsCallCenter } = useCurrentUser()

    const loading = fetchDataOnMounted([fetchPaymentInformation])

    const canShowBreakdowns = computed(() => !currentUserIsCallCenter.value)
    const handleClosedBreakdown = () => context.emit('breakdown-closed')

    const updatePaymentInformation = () => {
      fetchPaymentInformation()

      publish<PaymentStatusUpdateSideEffect>({
        name: MicrofrontendEvents.PaymentStatusUpdateSideEffect,
        data: {}
      })
    }

    return {
      paymentInfo,
      loading,
      canShowBreakdowns,
      handleClosedBreakdown,
      updatePaymentInformation
    }
  }
})

import * as orderingService from '@/services/ordering-service'
import * as operatorsService from '@/services/operators-service'
import * as matchingService from '@/services/matching-service'
import * as customerService from '@/services/customer-service'
import * as userService from '@/services/user-service'
import * as orderTrackingService from '@/services/order-tracking-service'
import * as marketplaceService from '@/services/marketplace-service'
import * as transitService from '@/services/transit-service'
import {
  getFrontConversationLink,
  createFrontConversationLink
} from '@/services/shipment-internal-comm-service'
import {
  getDesignatedOperators,
  assignOperator,
  OperatorRoleId
} from '@/services/designated-operators-service'
import { hasHadState } from '@/modules/common/order-states'
import { refreshOrderLogs } from '@/modules/order-activity-sidebar'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import logger from '@/shell/console-logger'
import { REGULARITY_TYPE } from '@/modules/common/order-states'
import * as AllocationService from '@/microfrontends/widgets/fleet-asset-management-widget/AllocationService'

export const fetchCompleteOrder = async (store, orderId) => {
  return Promise.all([fetchOrderStops(store, orderId), fetchOrder(store, orderId)])
}

const PLANNING_STATE = {
  finance: false,
  am: true,
  pm: true,
  callCenter: false,
  orderState: 'NEW',
  state: 'PLANNING'
}

const VISIBLE_ORDER_STATES = [
  {
    finance: false,
    am: false,
    pm: false,
    callCenter: false,
    orderState: 'OPERATIONS_COMPLETED',
    state: 'EXECUTED'
  },
  {
    finance: false,
    am: true,
    pm: false,
    callCenter: false,
    orderState: 'OPERATIONS_COMPLETED',
    state: 'EXECUTED'
  },
  {
    finance: false,
    am: false,
    pm: true,
    callCenter: false,
    orderState: 'OPERATIONS_COMPLETED',
    state: 'EXECUTED'
  },
  {
    finance: false,
    am: true,
    pm: true,
    callCenter: false,
    orderState: 'OPERATIONS_COMPLETED',
    state: 'EXECUTED'
  },
  {
    finance: false,
    am: false,
    pm: false,
    callCenter: true,
    orderState: 'NEW',
    state: null // just render nothing cause it's not a valid scenario
  },
  {
    finance: false,
    am: false,
    pm: false,
    callCenter: true,
    orderState: null,
    state: 'DISPATCHED'
  }
]

const canGoToManualSchedulingState = (customerId, shipmentId) => {
  if (!shipmentId) {
    return false
  }
  const { getJSONValue } = useFeatureFlag()
  const permittedList = getJSONValue('ENABLE_FACILITY_MANUAL_SCHEDULING_NW').value
  return permittedList?.onboarded_shippers_id?.includes(customerId)
}
export function getVisibleOrderState(
  orderState,
  customerId,
  shipmentId,
  isUserInFinanceGroup,
  isAccountManager,
  isPartnerManager,
  isUserInCallCenterGroup,
  states = VISIBLE_ORDER_STATES
) {
  if (canGoToManualSchedulingState(customerId, shipmentId)) {
    states.splice(5, 0, PLANNING_STATE)
  }
  const userGroupBasedSelection = states.find(
    state =>
      state.finance === isUserInFinanceGroup &&
      state.am === isAccountManager &&
      state.pm === isPartnerManager &&
      state.callCenter === isUserInCallCenterGroup &&
      (!state.orderState || state.orderState === orderState)
  )
  // allow null-ish state to handle the edge case when
  // the call-center user tries to access the order in "NEW" state
  if (userGroupBasedSelection) {
    return userGroupBasedSelection.state
  } else {
    // There isn't an associated stage to OPERATIONS_COMPLETED; thus, it uses its predecessor.
    return orderState === 'OPERATIONS_COMPLETED' ? 'EXECUTED' : orderState
  }
}

export const updateDefaultVisibleOrderState = store => {
  const { order } = store.state
  store.commit(
    'setVisibleState',
    getVisibleOrderState(
      order.state,
      store.rootState.shipment?.shipmentV3?.shipment?.shipper?.profile_id,
      store.state.shipmentData?.[0]?.id,
      store.rootGetters.currentUserIsInFinanceGroup,
      store.rootGetters.currentUserIsAccountManager,
      store.rootGetters.currentUserIsPartnerManager,
      store.rootGetters.currentUserIsCallCenter
    )
  )
}

export const getOrder = async (_, orderId) => {
  return await orderingService.fetchOrder(orderId)
}

export const fetchOrder = async (store, orderId) => {
  const order = await orderingService.fetchOrder(orderId)
  store.commit('setOrder', order)

  if (order.carrierCompany) {
    store.commit('setTrackingConfig', {
      gatehousePullOn: Boolean(order.carrierCompany.gatehouseCarrierId)
    })
  }

  await fetchShipmentData(store, orderId)
  await fetchTransportOfferId(store, store.getters.getShipmentID)

  let partnerManagerId = null
  if (store.getters.getTransportOfferId) {
    const oras_transport_offer_operators = await operatorsService.getOperators(
      store.getters.getTransportOfferId,
      'transport_offer'
    )
    if (oras_transport_offer_operators && oras_transport_offer_operators['cm'])
      partnerManagerId = oras_transport_offer_operators['cm']
  }

  const oras_shipment_operators = await operatorsService.getOperators(
    store.getters.getShipmentID,
    'shipment'
  )
  let accountManagerId = null
  let juniorAccountManagerId = null

  if (oras_shipment_operators) {
    accountManagerId = oras_shipment_operators['snr_aom']
    juniorAccountManagerId = oras_shipment_operators['aom']
  }

  const { transferId, customerId } = order

  let spotBidderId = null
  if (
    (order.regularity === REGULARITY_TYPE.SCALED_SPOT ||
      order.regularity === REGULARITY_TYPE.SPOT) &&
    (store.rootGetters.currentUserIsAccountManager ||
      store.rootGetters.currentUserIsPartnerManager)
  ) {
    try {
      if (oras_shipment_operators && oras_shipment_operators['spot_bidder'])
        spotBidderId = oras_shipment_operators['spot_bidder']
      else {
        const designatedOperators = await getDesignatedOperators(order.id, [
          OperatorRoleId.SpotBidder
        ])
        if (designatedOperators && designatedOperators[0]) {
          spotBidderId = designatedOperators[0].operatorId
        }
      }
    } catch (e) {
      logger.warn(e)
    }
  }
  fetchShipperProfile(store, customerId)
  return Promise.allSettled([
    spotBidderId ? fetchSpotBidder(store, spotBidderId) : Promise.resolve(),
    accountManagerId ? fetchAccountManager(store, accountManagerId) : Promise.resolve(),
    juniorAccountManagerId
      ? fetchJuniorAccountManager(store, juniorAccountManagerId)
      : Promise.resolve(),
    partnerManagerId ? fetchPartnerManager(store, partnerManagerId) : Promise.resolve(),
    transferId ? fetchTransfer(store, transferId) : Promise.resolve(),
    transferId ? fetchOrderTrackingData(store, transferId) : Promise.resolve()
  ])
}

const fetchOrderStops = async (store, orderId) => {
  const stops = await orderingService.fetchOrderStops(orderId)
  store.commit('setOrderStops', stops)
}

const fetchShipperProfile = async (store, shipperId) => {
  const shipperProfiles = await orderingService.fetchShipperProfile(shipperId)

  if (shipperProfiles.length > 0) {
    store.commit('setShipperProfile', shipperProfiles[0])
  }
}

export const fetchCustomerAddresses = async (store, customerId) => {
  const addresses = await customerService.fetchCustomerAddresses(customerId)
  store.commit('setCustomerAddresses', { customerId, addresses })
}

export const updateOrder = async (store, payload) => {
  const { id, state } = store.state.order
  const payloadWithVersion = { ...payload, version: store.state.order.version }
  if (state === 'NEW') await updateNewOrder(store, payloadWithVersion)
  if (state === 'REGISTERED') await updateRegisteredOrder(store, payloadWithVersion)
  if (state === 'CARRIER_LOCKED')
    await updateCarrierLockedOrder(store, payloadWithVersion)

  await fetchCompleteOrder(store, id)
}

const updateNewOrder = async (store, payload) => {
  const { order, stops, version, orderReset, isClonedOrder } = payload
  const { id, state } = store.state.order

  /* Notes:
  - Because we are switching to a whole new template we need to delete the previous stops before create the new ones with updateStops
  */
  if (orderReset) {
    await orderingService.deleteAllStops(id)
  }

  /* Notes:
   - avoid updating order and stops in parallel because of concurrency issues in OrderGroup.
   - Update first stops since complete validations are set in the update order endpoint.
  */
  if (!isClonedOrder) {
    await orderingService.updateStops(id, version, stops)
  }
  const newOrder = await orderingService.fetchOrder(id)
  await orderingService.updateOrder(id, state, { ...order, version: newOrder.version })
}

const updateRegisteredOrder = async (store, changes) => {
  const { id, state, version } = store.state.order
  return orderingService.updateOrder(id, state, {
    ...store.state.order,
    ...changes.order,
    order: { version }
  })
}

export const updateFacilityPlan = async store => {
  const orderId = store.state.order.id
  await fetchOrder(store, orderId)
  updateDefaultVisibleOrderState(store)
}

const updateCarrierLockedOrder = async (store, changes) => {
  const { id, transferId, state, version } = store.state.order
  await Promise.all([
    orderingService.updateOrder(id, state, {
      ...store.state.order,
      ...changes.order,
      order: { version },
      transfer: { version: store.state.transfer.version }
    }),
    orderTrackingService.updateTrackingConfig(transferId, changes.tracking)
  ])
}

export const registerOrder = async (store, changes) => {
  const { id, version } = store.state.order
  await updateNewOrder(store, { version, ...changes })
  await orderingService.registerOrder(id)
  await fetchCompleteOrder(store, id)
  refreshOrderLogs()
  updateDefaultVisibleOrderState(store)
}

/**
 * Update order details (customer, reference number and contact)
 *
 * @param store
 * @param orderDetails
 */
export const updateOrderCustomerInformation = async (
  store,
  { orderId, customerCompanyId, referenceNumber, customerContactId, trackingIdForShipper }
) => {
  await orderingService.updateOrderCustomerInformation(orderId, {
    customerCompanyId,
    referenceNumber,
    customerContactId,
    trackingIdForShipper
  })

  await store.dispatch('fetchOrder', orderId)
  refreshOrderLogs()
}

export const lockCarrierForOrder = async (store, changes) => {
  const id = store.state.order.id
  await updateRegisteredOrder(store, changes)
  await orderingService.lockCarrierForOrder(id)
  await fetchCompleteOrder(store, id)
  refreshOrderLogs()
  updateDefaultVisibleOrderState(store)
}

export const refreshOrder = async store => fetchCompleteOrder(store, store.state.order.id)

export const dispatchFleetOrder = async store => {
  const id = store.state.order.id
  const workflowManaged = store.state.order.workflowManaged || false
  const shipmentId = store.state.shipmentData?.[0]?.id
  await fetchCompleteOrder(store, id)

  try {
    await AllocationService.dispatchOrderThroughTransportPlanning(
      id,
      shipmentId,
      workflowManaged
    )
  } catch (e) {
    //fleet service returns different format of error message
    //that's why we need to re-throw error in correct format
    throw {
      response: {
        status: 400,
        data: e?.response?.data?.error?.message
      }
    }
  }
  await fetchCompleteOrder(store, id)
  refreshOrderLogs()
  updateDefaultVisibleOrderState(store)
}

export const dispatchOrder = async (store, changes) => {
  const id = store.state.order.id
  await updateCarrierLockedOrder(store, changes)
  await fetchCompleteOrder(store, id)
  await orderingService.dispatchOrder(id)
  await fetchCompleteOrder(store, id)
  refreshOrderLogs()
  updateDefaultVisibleOrderState(store)
}

export const closeOrderExecution = async store => {
  const id = store.state.order.id
  await orderingService.closeOrderExecution({ orderId: id })
  finalizeOrderExecution(store)
}

export const finalizeOrderExecution = async store => {
  const id = store.state.order.id
  await fetchCompleteOrder(store, id)
  refreshOrderLogs()
  updateDefaultVisibleOrderState(store)
}

export const completeOrderOperations = async store => {
  const id = store.state.order.id
  await orderingService.completeOrderOperations(id)
  await fetchCompleteOrder(store, id)
  refreshOrderLogs()
  updateDefaultVisibleOrderState(store)
}

export const canGoToState = (store, stateViewToShow) => {
  const orderHasHadState = state => hasHadState(store.state.order.state, state)
  return (
    //allows to show Planning tab in any scenario
    stateViewToShow === 'PLANNING' ||
    //allows to show finance stage regardless order state restriction.
    stateViewToShow === 'OPERATIONS_COMPLETED' ||
    //allows to show closing stage after reaching REGISTERED state.
    (stateViewToShow === 'EXECUTED' && orderHasHadState('REGISTERED')) ||
    orderHasHadState(stateViewToShow)
  )
}

export const showOrderStateView = async (store, stateViewToShow) => {
  if (canGoToState(store, stateViewToShow)) {
    store.commit('setVisibleState', stateViewToShow)
  }
}

export const setDirtyPlanningForm = (store, value) => {
  store.commit('changeDirtyPlanningForm', value)
}

export const fetchOrderCarriersKpi = async ({ state, getters, commit }) => {
  const orderId = state.order.id
  const carrierIds = getters.orderCarriersId
  const originCompanyAddressId = state.orderStops[0].warehouseAddress.companyAddressId
  const destinationCompanyAddressId =
    state.orderStops[state.orderStops.length - 1].warehouseAddress.companyAddressId
  if (
    carrierIds &&
    carrierIds.length > 0 &&
    originCompanyAddressId &&
    destinationCompanyAddressId
  ) {
    const customerId = state.order.customerId

    const carriersKpi = await orderingService.fetchCarriersKpi(
      orderId,
      carrierIds,
      customerId,
      originCompanyAddressId,
      destinationCompanyAddressId
    )
    commit('setCarriersKpi', carriersKpi)
  } else {
    commit('setCarriersKpi', null)
  }
}

export const fetchCarrierTrackingRate = async (
  { commit, state },
  { carrierId, fetchAgain }
) => {
  // With fetchAgain set to false, we use the value saved in the store. Otherwise the request would be made
  // every time the assignments are updated in the OrderingRoot page. We want to avoid unecessary requests as
  // it is an heavy one.
  if (!fetchAgain && state.carriersTrackingRate[carrierId]) {
    return state.carriersTrackingRate[carrierId]
  }
  const { rate } = await orderingService.fetchCarrierTrackingRate(carrierId)
  commit('setCarrierTrackingRate', { carrierId, rate })
  return rate
}

const fetchAccountManager = (store, id) =>
  userService.fetchUserByUuid(id, true).then(am => store.commit('setAccountManager', am))

const fetchSpotBidder = (store, id) =>
  userService
    .fetchUserByUuid(id, true)
    .then(spotBidder => store.commit('setSpotBidder', spotBidder))

const fetchJuniorAccountManager = (store, id) =>
  userService
    .fetchUserByUuid(id, true)
    .then(jam => store.commit('setJuniorAccountManager', jam))

const fetchPartnerManager = (store, id) =>
  userService.fetchUserByUuid(id, true).then(pm => store.commit('setPartnerManager', pm))

const fetchTransfer = (store, id) =>
  orderingService
    .fetchTransferById(id)
    .then(transfer => store.commit('setTransfer', transfer))

const fetchOrderTrackingData = (store, transferId) =>
  orderTrackingService
    .fetchTrackingConfig(transferId)
    .then(trackingConfig => store.commit('setTrackingConfig', trackingConfig))

export const fetchShipmentData = (store, orderId) =>
  orderingService
    .fetchShipmentByOrderId(orderId)
    .then(shipmentData => store.commit('setShipmentData', shipmentData))

export const fetchTransportOfferId = (store, shipmentId) =>
  matchingService
    .getTransportOfferForShipment(shipmentId)
    .then(transportOfferId => store.commit('setTransportOfferId', transportOfferId))

export const fetchExecutionPlanData = (store, shipmentId) => {
  transitService
    .getExecutionPlan(shipmentId)
    .then(executionPlanData => store.commit('setExecutionPlanData', executionPlanData))
}

export const deleteStop = async (store, stop) => {
  await orderingService.deleteStops([stop.id], store.state.order.version)
  await fetchCompleteOrder(store, store.state.order.id)
}

export const deleteOrder = async store => {
  await orderingService.deleteOrder(store.state.order.id)
}

export const copyOrder = async store => {
  return await orderingService.copyOrder(store.state.order.id)
}

export const calculatePriceAndCost = async (store, { stops, vehicleTypes }) => {
  const price = await orderingService.calculatePrice(
    store.state.order.lineCode,
    stops,
    vehicleTypes
  )
  const cost = await orderingService.calculateCost(
    store.state.order.lineCode,
    stops,
    vehicleTypes
  )

  return {
    price: price.value,
    cost: cost.value,
    distance: price.distance || cost.distance,
    priceMessage: price.message,
    costMessage: cost.message
  }
}

export const cancelOrder = async store => {
  const { id, state } = store.state.order
  if (state === 'OPERATIONS_COMPLETED') {
    await orderingService.cancelOpsCompletedOrder(id)
  } else {
    await orderingService.cancelNotOpsCompletedOrder(id)
  }
  await fetchOrder(store, id)
  updateDefaultVisibleOrderState(store)
  refreshOrderLogs()
}

export const updateOrderCustomerRequirements = async (
  store,
  { order, stops, editableForVariations, removedStopIds, newPrice, newCost }
) => {
  const { id, version } = store.state.order

  if (editableForVariations) {
    await orderingService.saveOrderVariation({
      order: {
        id,
        version,
        referenceNumber: order.referenceNumber,
        loadMinimumTemperature: order.loadMinimumTemperature,
        loadMaximumTemperature: order.loadMaximumTemperature,
        allowedVehicleTypes: order.allowedVehicleTypes,
        loadType: order.loadType
      },
      deleteStopoverIds: removedStopIds,
      saveStopovers: stops.filter(stop => !removedStopIds.includes(stop.id)),
      updatedPrice: newPrice,
      updatedCost: newCost
    })
  } else {
    await orderingService.updateOrderCustomerRequirements(
      { ...order, id, version },
      stops
    )
  }
  await fetchCompleteOrder(store, id)
  await refreshOrderLogs()
}

export const updateDispatchedOrder = async (store, { order, stops }) => {
  const { id, version } = store.state.order
  await orderingService.updateDispatchedOrder({ ...order, id, version }, stops)
  await Promise.all([fetchOrder(store, id), fetchOrderStops(store, id)])
  refreshOrderLogs()
}

export const undispatchOrder = async store => {
  const { id } = store.state.order
  await orderingService.undispatch(id)
  await store.dispatch('fetchOrder', id)
}

export const submitTransEUOffer = async (store, offer) => {
  const orderId = store.state.order.id
  await marketplaceService.submitTransEUOffer(orderId, offer)
  refreshOrderLogs()
}

export const assignAccountManagers = async (
  { commit, getters, state },
  { accountManager, juniorAccountManager }
) => {
  if (getters.orderContainsShipment) {
    const promises = [
      { operator: accountManager, role: operatorsService.ROLES.SENIOR_ACCOUNT_MANAGER },
      { operator: juniorAccountManager, role: operatorsService.ROLES.ACCOUNT_MANAGER }
    ].map(({ operator, role }) => {
      if (operator === undefined) return Promise.resolve()

      return operatorsService.assignOperator(
        getters.getShipmentID,
        'shipment',
        operator.uuid,
        role
      )
    })

    await Promise.allSettled(promises)
  } else {
    const orderId = state.order.id
    await orderingService.assignAccountManagers(
      [orderId],
      accountManager?.id,
      juniorAccountManager?.id
    )
  }

  if (accountManager) {
    commit('setAccountManager', accountManager)
  }
  if (juniorAccountManager) {
    commit('setJuniorAccountManager', juniorAccountManager)
  }
}

export const checkFeasibilityWorkflow = async ({ commit, getters }) => {
  const payload = {
    entity: {
      id: getters.getShipmentID,
      name: 'shipment'
    },
    workflowName: 'rescheduling'
  }

  try {
    commit('setIsShipmentFeasibleLoading', true)
    const response = await orderingService.checkFeasibilityWorkflowStatus(
      payload.entity,
      payload.workflowName
    )
    if (response.data?.feasible) commit('setIsShipmentFeasible', true)
    else {
      // If the only problem blocking the rescheduling feasibility is Chartering,
      // then we want to allow edits for this Shipment in general, and only block
      // the actual rescheduling itself
      if (
        // Check that there is at least one case of a "Chartering" feasibility rejection reason
        // (to protect ourselves against situations where a feasibility rejection may contain *no* reasons)
        response.data.details.some(
          detail =>
            !detail.feasible && detail.workflow_name.toLowerCase().includes('chartering')
        ) &&
        // Check that *all* rejection reasons are related to Chartering
        response.data.details
          .filter(detail => !detail.feasible)
          .every(detail => detail.workflow_name.toLowerCase().includes('chartering'))
      ) {
        commit('setIsShipmentReschedulingBlockedByChartering', true)
        commit('setIsShipmentFeasible', true)
      } else commit('setIsShipmentFeasible', false)
    }
  } catch (error) {
    if (error?.response?.status === 422 || error?.response?.status === 423)
      commit('setIsShipmentFeasible', false)
    logger.warn(error)
  } finally {
    commit('setIsShipmentFeasibleLoading', false)
  }
}

export const assignSpotBidder = async ({ commit, getters, state }, { spotBidder }) => {
  if (getters.orderContainsShipment && spotBidder.uuid) {
    await operatorsService.assignOperator(
      getters.getShipmentID,
      'shipment',
      spotBidder.uuid,
      operatorsService.ROLES.SPOT_BIDDER
    )
  } else {
    const orderId = state.order.id
    await assignOperator(
      [orderId],
      OperatorRoleId.SpotBidder,
      spotBidder.uuid || spotBidder.id
    )
  }

  commit('setSpotBidder', spotBidder)
}

export const assignPartnerManager = async ({ commit, getters }, { partnerManager }) => {
  if (getters.orderContainsShipment && partnerManager.uuid) {
    await operatorsService.assignOperator(
      getters.getTransportOfferId,
      'transport_offer',
      partnerManager.uuid,
      operatorsService.ROLES.CARRIER_MANGER
    )
  }
  commit('setPartnerManager', partnerManager)
}

export const fetchFrontLink = async (store, shipmentId) => {
  const result = await getFrontConversationLink(shipmentId)
  if (result.front_link) store.commit('setFrontLink', result.front_link)
}

const getConversationSubject = async store => {
  const first_loading = store.state.orderStops[0]
  const last_unloading = store.state.orderStops[store.state.orderStops.length - 1]

  const facility_name = store.getters.shipperProfile.companyName

  return `${facility_name} - ${
    first_loading.warehouseAddress.countryCode
  }${first_loading.warehouseAddress.zipCode.substring(0, 2)}-${
    last_unloading.warehouseAddress.countryCode
  }${last_unloading.warehouseAddress.zipCode.substring(0, 2)}`
}

export const createFrontLink = async (store, shipmentId) => {
  try {
    const subject = await getConversationSubject(store)
    const result = await createFrontConversationLink(
      shipmentId,
      store.getters.getTransportOfferId,
      [
        ...new Set(
          [
            store.state.partnerManager?.uuid,
            store.state.juniorAccountManager?.uuid,
            store.state.accountManager?.uuid,
            store.state.spotBidder?.uuid
          ].filter(e => e)
        )
      ],
      store.rootState.shipment.shipmentV3.shipment.reference,
      store.rootState.shipment.shipmentV3.shipment.external_id,
      subject
    )
    if (result.front_link) store.commit('setFrontLink', result.front_link)
  } catch (e) {
    logger.warn(e)
  }
}

<template>
  <div class="credit-note-modal">
    <Dialog
      :show="isModalVisible"
      :title="$t('finance-board/credit-note-generation-modal-title-generate-send')"
      @close="hideModal"
      @hide="clearErrorMessage"
      class="generate-modal send-dispatch-confirmation-modal"
      @cancel="hideModal"
      @confirm="generate"
      :confirmButtonDisabled="!canGenerate || creatingDocument"
      :cancelButtonText="$t('finance-board/credit-note-generation-modal-cancel-label')"
      :confirmButtonText="
        $t('finance-board/credit-note-generation-modal-confirm-label-generate-send')
      "
      inPlace
    >
      <div>
        <div v-if="errorMessage" class="row error-message" data-test="error-messages">
          <AlertMessage type="error" class="col-md-12">{{ errorMessage }}</AlertMessage>
        </div>
        <div class="content mb-4">
          {{ $t('finance-board/credit-note-generation-modal-description') }}
        </div>
        <div class="form-row">
          <div class="col-md-12">
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-html="
                $t('finance-board/credit-note-generation-modal-selection-msg', {
                  count: selectedOrdersCount
                })
              "
            />
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { monitorError } from '@/analytics/monitoring'
import { capitalize } from '@/global-setup/filters'
export default {
  props: {
    isModalVisible: {
      type: Boolean,
      default: false
    },
    selectedOrdersCount: {
      type: Number,
      default: 0
    },
    submit: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      creatingDocument: false,
      errorMessage: '',
      show: false
    }
  },
  computed: {
    canGenerate() {
      return this.selectedOrdersCount > 0
    }
  },
  methods: {
    async generate() {
      this.creatingDocument = true
      try {
        await this.submit()
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error)
        monitorError(error)
        return
      } finally {
        this.creatingDocument = false
      }
      this.hideModal()
    },
    clearErrorMessage() {
      this.errorMessage = ''
    },
    hideModal() {
      this.$emit('hide')
      this.clearErrorMessage()
    },
    getErrorMessage(error) {
      if (error.status >= 400 && error.status < 500 && error.details) {
        return capitalize(error.details, true)
      }

      return this.$t('finance-board/credit-note-generation-modal-error')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/modules/ordering/variables';

.error-message {
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
</style>

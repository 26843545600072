<template>
  <div class="paragraph">
    <div class="eta-label">{{ $t('ordering/execution-eta-label') }}</div>
    <div class="subtitle" :class="delayStatus.class" data-test="eta-status">
      {{ etaToNextStop }} {{ delayStatus.message ? `| ${delayStatus.message}` : '' }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    etaToNextStop: { type: String, required: true },
    delayStatus: {
      type: Object,
      required: true,
      validator: delayStatus =>
        typeof delayStatus.message === 'string' && typeof delayStatus.class === 'string'
    }
  }
}
</script>

<style lang="scss" scoped>
.eta-label {
  color: $color-neutral-main;
  margin-bottom: 4px;
}

.no-info {
  color: $color-neutral-darker;
}

.delayed {
  color: $color-error-main;
}

.on-time {
  color: $color-success-main;
}
</style>

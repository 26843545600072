







import Vue from 'vue'
import {
  createRandomString,
  getEnvironmentAliasForFederatedLoader
} from '@/microfrontends/utils'
import { Location, RawLocation, Route } from 'vue-router'
import {
  IUser,
  Tenant,
  SharedDataType
} from '@sennder/senn-node-microfrontend-interfaces'
import useCurrentUser from '@/compositions/useCurrentUser'
import useI18n from '@/compositions/useI18n'
import { getAppTheme } from '@/controllers/environment-detection'
import { computed, defineComponent } from '@vue/composition-api'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import { OPERATIONS_BACKEND_URL } from '@/config'
import { getLogger } from '@/shell/datadog-logger'
import useStore from '@/compositions/useStore'
import { getAuthHeader, getCommonHeaders } from '@/services/auth-service'
import { monitorError } from '@/analytics/monitoring'
import { trackEvent } from '@/analytics/amplitude'
import { fetchMicrofrontend } from '@/microfrontends/fetchMicrofrontend'

const NAMESPACE = 'transitmfcomponent'
const REMOTE = './transitmfcomponentApp'
const NPM_PATH = 'transit-mf-component'
const DEV_PORT = 9123

const TRACKING_PROPERTIES = {
  module: 'ordering',
  submodule: 'execution'
}

export default defineComponent({
  name: 'ExecutionStageWidget',
  props: {
    displayMapOnly: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      id: `${createRandomString()}-ExecutionStageWidget`,
      isLoaded: null as boolean | null,
      syncChildRouter: null as ((route: RawLocation) => void) | null,
      unmount: null as (() => void) | null
    }
  },
  async mounted() {
    const store = useStore()
    const shipmentId = computed(() => store.getters['ordering/getShipmentID'])
    const syncParentRouter = (route: RawLocation) => {
      const currentPath = this.$route.fullPath
      if (
        (typeof route === 'string' && currentPath === route) ||
        (typeof route === 'object' && currentPath === (route as Location).path)
      ) {
        return
      }
      this.$router.push(route)
    }
    try {
      const { user } = useCurrentUser()
      const { locale } = useI18n()
      const { isActive } = useFeatureFlag()

      const mount = await fetchMicrofrontend({
        devPort: DEV_PORT,
        environment: getEnvironmentAliasForFederatedLoader(NAMESPACE),
        moduleFederationPluginName: NAMESPACE,
        npmName: NPM_PATH,
        mountExposedName: REMOTE,
        logger: getLogger()
      })

      const mfData = mount(`#${this.id}`, {
        data: {
          type: SharedDataType.OCTOPUS,
          user: user.value as IUser,
          language: locale.value,
          env: {
            OPERATIONS_BACKEND_URL
          },
          tenant: getAppTheme() as Tenant,
          featureFlags: {
            EXECUTION_MANDATORY_TIMESTAMPS: isActive('execution-mandatory-timestamps')
              .value,
            TRANSIT_POST_EXECUTION_WRITE: true,
            TRANSIT_FACILITY_ACTIONS: isActive('transit-facility-actions').value
          },
          displayMapOnly: this.displayMapOnly,
          shipmentId: shipmentId.value,
          inSingleView: false
        },
        callbacks: {
          getToken: getAuthHeader,
          getAuthToken: () => Promise.resolve(null),
          getAuthHeader,
          syncParentRouter,
          onUnauthorized: () => {},
          onUnhandledError: error => {
            Vue.config.errorHandler(error, null, 'Transit MF')
          },
          getCommonHeaders
        },
        providers: {
          logger: getLogger(),
          notifications: null,
          translations: null,
          monitoring: null,
          analytics: {
            trackEvent: (name, props) => {
              trackEvent(name, {
                ...TRACKING_PROPERTIES,
                ...props
              })
            },
            setContext: () => {
              getLogger().error('setContext not implemented', {})
            },
            trackPage: () => {
              getLogger().error('trackPage not implemented', {})
            }
          }
        }
      })
      if (mfData.syncChildRouter) {
        this.syncChildRouter = mfData.syncChildRouter
        this.$watch(
          '$route',
          (to: Route) => {
            mfData.syncChildRouter(to.fullPath)
          },
          {
            immediate: true
          }
        )
      }
      if (mfData.unmount) {
        this.unmount = mfData.unmount
      }
      this.isLoaded = true
    } catch (e) {
      monitorError(e, 'ExecutionStageWidget', {
        type: 'bootstrap'
      })
      this.isLoaded = false
    }
  },
  destroyed() {
    if (this.unmount) {
      this.unmount()
    }
  }
})

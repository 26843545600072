import { ref } from '@vue/composition-api'

interface Options {
  onBeforeOpen?: () => void
  onBeforeClose?: () => void
  isInvalid?: () => boolean
  onConfirm?: () => void | Promise<void>
}
const DEFAULT_OPTIONS: Options = {
  onBeforeOpen: () => {},
  onBeforeClose: () => {},
  isInvalid: () => false,
  onConfirm: () => {}
}

/**
 * Composition function to manage the state of Dialog/Modal.
 */
export default (options: Options = DEFAULT_OPTIONS) => {
  const { onBeforeOpen, onBeforeClose, isInvalid, onConfirm } = {
    ...DEFAULT_OPTIONS,
    ...options
  }
  const visible = ref(false)
  const loading = ref(false)
  let resolveModal: (result: boolean) => void
  let rejectModal: (reason: unknown) => void

  /**
   * @returns a Promise which will resolve with a boolean after the modal is hidden by closing or confirmation.
   */
  const open = () => {
    onBeforeOpen()
    visible.value = true
    return new Promise<boolean>((resolve, reject) => {
      resolveModal = resolve
      rejectModal = reject
    })
  }

  const close = () => {
    _close()
    if (resolveModal) resolveModal(false)
  }

  const _close = () => {
    onBeforeClose()
    visible.value = false
  }

  const confirm = async () => {
    try {
      if (isInvalid()) return
      loading.value = true
      await onConfirm()
      _close()
      resolveModal(true)
    } catch (err) {
      if (rejectModal) rejectModal(err)
    } finally {
      loading.value = false
    }
  }

  return { visible, loading, open, confirm, close }
}

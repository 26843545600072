import { store } from '@/models/store'

export const updateBrowserTabTitle = to => {
  if (to.meta.title) document.title = to.meta.title
  else document.title = 'sennder octopus'
}

export const isUnauthenticatedRoute = to => {
  return (
    to.path.startsWith('/login') ||
    to.path.startsWith('/error') ||
    to.path.startsWith('/auth/forgot-password') ||
    to.path.startsWith('/auth/email-sent') ||
    to.path.startsWith('/auth/reset-password') ||
    to.path.startsWith('/auth/ready')
  )
}

export const isCallCenterIllegalRoute = to => {
  // allow redirect for old order routes
  return (
    store.getters.currentUserIsCallCenter &&
    ![
      'call-center-board',
      'call-center-detail',
      'call-center-issues',
      'ordering',
      'financeOrder',
      'financeOrderView'
    ].includes(to.name)
  )
}

export const isAccountabilityIllegalRoute = to => {
  if (to.name === 'accountability') {
    return !store.getters.currentUserIsPartnerManagementAdmin
  }
  return false
}

export const isNonFinanceIllegalRoute = to => {
  const financeUrlRegex = /\/order\/\d+\/finance/
  return !store.getters.canUserAccessFinanceSection && to.path.match(financeUrlRegex)
}

export const isRequestBoardRoute = to => {
  return to.path.startsWith('/dashboard/requests')
}

export const isContractManagementRoute = to => {
  return to.path.startsWith('/contract-management')
}

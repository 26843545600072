import Axios from 'axios'
import { runLogin } from '@/models/backend-client'
import {
  removeJWTCookieValue,
  setAuthCookieValue,
  getAuthorizationHeaderWithParams,
  getJWTAuthToken,
  getJWTAuthTokenWithParams
} from '@sennder/auth'
import {
  GetTokenOptions,
  IAction,
  IActionPermission,
  OrgType,
  ICommonHeaders
} from '@sennder/senn-node-microfrontend-interfaces'
import {
  PERMISSIONS_SERVICE_URL,
  AUTH0_MOTHERSHIP_AUDIENCE,
  OPERATIONS_BACKEND_URL,
  MICRO_FRONTEND_MODE
} from '@/config'

import { getAuth } from '../../core-config/orchestrated/auth'

export const SINGLE_AUDIENCE = 'https://api.cloud.sennder.com'
export enum ENTITY_TYPE {
  ORGANIZATION = 'organization'
}
export enum ENTITY_ID {
  SENNDER = 'sennder'
}

export interface Credentials {
  email: string
  password: string
}

interface Token {
  token: string
}

export const login = async (credentials: Credentials | Token) => {
  const data: { key: string } = await runLogin(
    `${OPERATIONS_BACKEND_URL}/auth/login/`,
    credentials
  )
  setAuthCookieValue(data.key)
  removeJWTCookieValue()
}

/**
 * If Octopus is running in standalone mode, we're accessing Auth0 SDK directly
 */
const getStandaloneAuthHeader = async (options?: GetTokenOptions) => {
  if (!options?.audience) {
    return await getAuthorizationHeaderWithParams(
      AUTH0_MOTHERSHIP_AUDIENCE,
      options?.scope
    )
  } else {
    return await getAuthorizationHeaderWithParams(options.audience, options.scope)
  }
}

/**
 * If Octopus is running as a micro-frontend, we're getting the token from shared data.
 */
const getCoreAuthHeader = async (options?: GetTokenOptions) => {
  if (!options?.audience) {
    return await getAuth().getAuthHeader?.({
      audience: AUTH0_MOTHERSHIP_AUDIENCE,
      scope: options?.scope
    })
  } else {
    return await getAuth().getAuthHeader?.(options)
  }
}

export const getAuthHeader = async (options?: GetTokenOptions) => {
  return MICRO_FRONTEND_MODE
    ? await getCoreAuthHeader(options)
    : await getStandaloneAuthHeader(options)
}

/**
 * If Octopus is running in standalone mode, we're accessing Auth0 SDK directly
 */
const getStandaloneAuthToken = async (options?: GetTokenOptions) => {
  if (!options?.audience) {
    return await getJWTAuthToken()
  } else {
    return await getJWTAuthTokenWithParams(options.audience, options.scope)
  }
}

/**
 * If Octopus is running as a micro-frontend, we're getting the token from shared data.
 */
const getCoreAuthToken = async (options?: GetTokenOptions) => {
  return await getAuth().getAuthToken?.(options)
}

export const getAuthToken = async (options?: GetTokenOptions) => {
  return MICRO_FRONTEND_MODE
    ? await getCoreAuthToken(options)
    : await getStandaloneAuthToken(options)
}

const getSingleAudienceHeader = async () => {
  return await getAuthHeader({
    audience: SINGLE_AUDIENCE,
    scope: ''
  })
}

/**
 * If Octopus is running as a micro-frontend, we're getting the common headers from auth mfe.
 */
async function getCoreCommonHeaders(): Promise<ICommonHeaders> {
  return await getAuth().getCommonHeaders()
}

/**
 * If Octopus is running in standalone mode, we're accessing Auth0 SDK directly
 */
async function getStandaloneCommonHeaders(): Promise<ICommonHeaders> {
  const authHeader = await getSingleAudienceHeader()
  const orgId = 'sennder'
  return {
    Authorization: authHeader,
    'X-Org-Type': OrgType.ORGANIZATION,
    'X-Org-Id': orgId
  }
}

export async function getCommonHeaders(): Promise<ICommonHeaders> {
  return MICRO_FRONTEND_MODE
    ? await getCoreCommonHeaders()
    : await getStandaloneCommonHeaders()
}

/**
 * If Octopus is running as a micro-frontend, we're getting the permissions from auth mfe.
 */
async function getCorePermissions(actions: IAction[]): Promise<IActionPermission[]> {
  return await getAuth().getPermissions(actions)
}

/**
 * If Octopus is running in standalone mode, we're accessing Auth0 SDK directly
 */
async function getStandalonePermissions(
  actions: IAction[]
): Promise<IActionPermission[]> {
  const url = `${PERMISSIONS_SERVICE_URL}/api/v1/me/permissions`
  const commonHeaders = await getStandaloneCommonHeaders()

  const response = await Axios.post<IActionPermission[]>(url, actions, {
    headers: {
      ...commonHeaders
    }
  })

  return response.data
}

export async function getPermissions(actions: IAction[]): Promise<IActionPermission[]> {
  return MICRO_FRONTEND_MODE
    ? await getCorePermissions(actions)
    : await getStandalonePermissions(actions)
}

<template>
  <div class="plankton-grid">
    <div class="plankton-cell" span="12">
      <DateInputField
        :data-test="from.id"
        :title="from.title"
        v-model="fromFieldHandler"
        placeholder="DD.MM.YYYY"
        useDotAsSeparator
        fullWidth
      />
    </div>
    <div class="plankton-cell" span="12">
      <DateInputField
        :data-test="to.id"
        :title="to.title"
        v-model="toFieldHandler"
        placeholder="DD.MM.YYYY"
        useDotAsSeparator
        fullWidth
      />
    </div>
  </div>
</template>

<script>
const fromToFieldsValidator = obj =>
  typeof obj.id === 'string' && typeof obj.title === 'string'

export default {
  inject: ['getFilterValue', 'setFilterValue'],
  props: {
    from: { type: Object, required: true, validator: fromToFieldsValidator },
    to: { type: Object, required: true, validator: fromToFieldsValidator },
    filterName: { type: String, required: true }
  },
  computed: {
    fromFieldHandler: {
      get() {
        return this.getFilterValue(this.filterName)[0]
      },
      set(value) {
        this.setFilterValue(this.filterName, [value, this.toFieldHandler])
      }
    },
    toFieldHandler: {
      get() {
        return this.getFilterValue(this.filterName)[1]
      },
      set(value) {
        this.setFilterValue(this.filterName, [this.fromFieldHandler, value])
      }
    }
  }
}
</script>

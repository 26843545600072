import { createBasicListFilter } from './basic-list'

const emptyValue = []

/**
 * @typedef {object} EntityItem
 * @property {number} id - entity id
 */

/**
 * @typedef {EntityItem[]|undefined} FilterValue
 * @typedef {import('./filter').UrlQueryFragment} UrlQueryFragment
 */

/**
 * @param {object} data
 * @param {string} data.name - key used to generate API/GraphQL payload
 * @param {string=} data.urlParamName - URL query param name
 * @param {string=} data.optionLabel - is used to form the list for applied filters
 * @param {getEntityFromIdCallback=} data.getEntityFromId
 * @param {FilterValue} data.initialValue
 * @param {string=} data.updateName - name for getting/setting filter
 * @param {string=} data.filterLabel - label for appliedFilters
 * @returns {any} - vuex module instance
 */
export const createEntityListFilter = ({
  name,
  initialValue = emptyValue,
  updateName,
  filterLabel,
  esName = name,
  urlParamName = name,
  optionLabel = 'name',
  esWord = 'match',
  getEntityFromId = id => ({ id })
}) => {
  // helper functions
  const getIds = value => value.map(({ id }) => id)

  let filter = createBasicListFilter({
    name,
    initialValue,
    updateName,
    filterLabel,
    humanizeValue: filterValue => filterValue[optionLabel]
  })

  filter.state = {
    ...filter.state,
    urlParamName
  }

  filter.getters = {
    ...filter.getters,
    /**
     * @param {*} state
     * @param {*} getters
     * @returns {?object<string, FilterValue>} - a fragment of API payload object
     */
    getApiFragment: (state, getters) =>
      !getters.isFilterEmpty ? { [name]: getIds(state.value) } : null,
    /**
     * @param {*} state
     * @param {*} getters
     * @returns {?string} - a fragment of GraphQL payload object
     */
    getGraphqlValue: (state, getters) =>
      !getters.isFilterEmpty ? `${name}: ${JSON.stringify(getIds(state.value))}` : null,
    /**
     * @param {*} state
     * @param {*} getters
     * @returns {UrlQueryFragment} - a fragment of URL query object
     */
    getUrlQueryFragment: (state, getters) => {
      if (!getters.isFilterInitial) {
        return {
          [urlParamName]: !getters.isFilterEmpty ? getIds(state.value).join(',') : null
        }
      } else return null
    },
    getEsQueryFragment: (state, getters) => {
      if (getters.isFilterEmpty) {
        return []
      } else if (esWord === 'terms') {
        return [
          {
            [esWord]: {
              [esName]: [
                ...state.value.reduce((acc, nextValue) => {
                  acc.push(nextValue.id)
                  return acc
                }, [])
              ]
            }
          }
        ]
      } else if (state.value?.length === 1) {
        return [{ [esWord]: { [esName]: state.value[0].id } }]
      } else {
        return [
          {
            bool: {
              should: [
                ...state.value.map(value => ({ [esWord]: { [esName]: value.id } }))
              ]
            }
          }
        ]
      }
    },
    getAppliedValueForEs: (state, getters) =>
      getters.isFilterEmpty
        ? null
        : {
            identifier: name,
            value: state.value.map(item => item.id || item.uuid)
          }
  }

  filter.actions = {
    ...filter.actions,
    /**
     * @param {*} store
     * @param {UrlQueryFragment} urlQueryFragment
     */
    async setValueFromUrl({ commit }, urlQueryFragment) {
      const urlValue = urlQueryFragment[urlParamName]
      if (urlValue) {
        const entityIds = urlValue.split(',')
        const entitiesData = await Promise.all(entityIds.map(getEntityFromId))

        commit('update', entitiesData)
      } else if (urlValue === null) commit('update', emptyValue)
    }
  }

  return filter
}

/**
 * @callback getEntityFromIdCallback
 * @param {string} id
 * @returns {object[]}
 */

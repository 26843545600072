import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'
import { createFilters } from '@/modules/filters'

import { sevenDaysAgo, tomorrow } from '@/controllers/formatted-relative-dates'

export default {
  namespaced: true,
  state: () => ({
    orders: []
  }),
  getters,
  mutations,
  actions,
  modules: {
    filters: createFilters({
      pickupRange: {
        startDate: sevenDaysAgo() + ' 00:00',
        endDate: tomorrow() + ' 23:45'
      },
      orderStates: ['DISPATCHED'],
      isRemoved: false
    })
  }
}

import { render, staticRenderFns } from "./CarrierOffersCell.vue?vue&type=template&id=0d4fcd83&scoped=true&"
import script from "./CarrierOffersCell.vue?vue&type=script&lang=ts&"
export * from "./CarrierOffersCell.vue?vue&type=script&lang=ts&"
import style0 from "./CarrierOffersCell.vue?vue&type=style&index=0&id=0d4fcd83&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d4fcd83",
  null
  
)

export default component.exports
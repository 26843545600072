import { i18n } from '@/plugins/i18n'

export const getAssignmentStatesOptions = () => {
  return [
    {
      label: i18n.t('filters/constants-assignment-states-option-accepted'),
      value: 'ACCEPTED'
    },
    {
      label: i18n.t('filters/constants-assignment-states-option-pending'),
      value: 'PENDING'
    },
    {
      label: i18n.t('filters/constants-assignment-states-option-rejected'),
      value: 'REJECTED'
    },
    {
      label: i18n.t('filters/constants-assignment-states-option-expired'),
      value: 'EXPIRED'
    },
    {
      label: i18n.t('filters/constants-assignment-states-option-withdrawn'),
      value: 'CANCELLED'
    }
  ]
}

export const ORDER_SOURCES = [
  'SHIPPER_PORTAL',
  'OCTOPUS_MANUAL',
  'WEB_API_DIRECT',
  'BATCH_GENERAL',
  'BATCH_AMAZON',
  'BATCH_IRREGULAR',
  'REGULAR_TASK',
  'PROGRAMMATIC'
]

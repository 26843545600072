







import * as USER_PERMISSIONS from './user-permissions'
import useCurrentUser, { Permission } from '@/compositions/useCurrentUser'
import { computed, defineComponent, PropType } from '@vue/composition-api'

const permissionValidator = (permission: Permission | '') =>
  permission === '' || Object.values(USER_PERMISSIONS).includes(permission)

const permissionArrayValidator = (permissions: Permission[]) =>
  permissions.every(permissionValidator)

const renderWithPermission = defineComponent({
  props: {
    hasPermission: {
      type: String as PropType<Permission | ''>,
      default: () => '',
      validator: permissionValidator
    },
    doesNotHavePermission: {
      type: String as PropType<Permission | ''>,
      default: () => '',
      validator: permissionValidator
    },
    hasAnyOf: {
      type: Array as PropType<Permission[]>,
      default: () => [] as Permission[],
      validator: permissionArrayValidator
    },
    hasEveryOf: {
      type: Array as PropType<Permission[]>,
      default: () => [] as Permission[],
      validator: permissionArrayValidator
    }
  },
  setup(props) {
    const { hasPermission } = useCurrentUser()

    const permission = computed<boolean>(() => {
      const hasSinglePermission = props.hasPermission
        ? hasPermission(props.hasPermission)
        : true

      // "some" method always returns "false" for empty arrays
      // so I added a condition to make it truthy
      const hasAnyOfPermission = props.hasAnyOf.length
        ? props.hasAnyOf.some((permissionName: Permission) =>
            hasPermission(permissionName)
          )
        : true

      const hasEveryOfPermission = props.hasEveryOf.every((permissionName: Permission) =>
        hasPermission(permissionName)
      )

      const doesNotHaveSinglePermission = props.doesNotHavePermission
        ? !hasPermission(props.doesNotHavePermission)
        : true

      return (
        hasSinglePermission &&
        hasAnyOfPermission &&
        hasEveryOfPermission &&
        doesNotHaveSinglePermission
      )
    })

    return { permission }
  }
})

export default renderWithPermission

import Vue from 'vue'
import { getModels } from '@/models/models'

export const mutations = {
  delete: (state, { Model, id }) => {
    const collection = state[Model.NAME]
    Vue.delete(collection, id)
  },

  replace: (state, response) => {
    for (let [collection_name, response_items] of Object.entries(response)) {
      const state_collection = getCollection(state, collection_name)
      const ModelClass = getModelClass(collection_name)
      for (let [response_item_id, response_item] of Object.entries(response_items)) {
        if (response_item_id in state_collection)
          state_collection[response_item_id].load(response_item)
        else {
          Vue.set(
            state_collection,
            response_item_id,
            new ModelClass(response_item, state)
          )
        }
      }
    }
  },

  flush: (state, { Model }) => {
    const collection = state[Model.NAME]
    for (let value of Object.values(collection)) {
      Vue.delete(collection, value.id)
    }
  },

  resetOrders: state => (state.orders = {}),

  startLoadingPage: state => (state.loadingPage = true),

  finishLoadingPage: state => (state.loadingPage = false),

  expandFiltersArea: state => (state.filtersCollapsed = false),

  collapseFiltersArea: state => (state.filtersCollapsed = true),

  updateCreditNoteSentStatus: (state, { orderIds, isBilled }) =>
    Object.values(state.orders).map(o => {
      if (orderIds.includes(o.id)) o.credit_note_was_sent = isBilled
    })
}

export function getModelClass(collectionName) {
  const models = getModels()
  if (!Object.prototype.hasOwnProperty.call(models, collectionName))
    throw new Error(`${collectionName} is an invalid collection name`)
  return models[collectionName]
}

export function getCollection(state, collectionName) {
  if (!Object.prototype.hasOwnProperty.call(state, collectionName))
    throw new Error(`${collectionName} is an invalid collection name`)
  return state[collectionName]
}

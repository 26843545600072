<template>
  <div class="location-and-time paragraph">
    <div class="content">
      <div class="location">{{ countryCode }}, {{ postalCode }}</div>
      <div class="time">
        {{ formatDate(startDate) }}, {{ formatTime(startDate) }} -
        {{ formatTime(endDate) }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate, formatTime } from '@/global-setup/filters'

export default {
  props: {
    countryCode: { type: String, default: '' },
    postalCode: { type: String, default: '' },
    startDate: { type: String, default: () => new Date().toISOString() },
    endDate: { type: String, default: () => new Date().toISOString() }
  },
  methods: {
    formatDate,
    formatTime
  }
}
</script>

<style lang="scss" scoped>
.location-and-time {
  display: flex;
  flex-direction: row;
  padding: 16px;
}

.location {
  font-weight: bold;
}

.time {
  color: $color-neutral-main;
}
</style>













import moment from 'moment'
import sortBy from 'lodash/sortBy'
import last from 'lodash/last'
import {
  defineComponent,
  onBeforeMount,
  computed,
  toRef,
  ref
} from '@vue/composition-api'
import { getTrackerList } from '@/services/boards-service'
import { TrackerData } from '@/modules/boards/types'
import useI18n from '@/compositions/useI18n'

export default defineComponent({
  props: {
    transfer: {
      type: Object,
      default: function () {
        return {}
      },
      required: false
    }
  },
  setup(props) {
    const trackers = ref<TrackerData[]>([])
    const { t } = useI18n()

    const mostRecentUpdate = computed<string>(() =>
      trackersIds.value?.length
        ? last(sortBy(trackers.value, 'lastUpdate'))?.lastUpdate
        : null
    )

    const trackersIds = toRef(props.transfer, 'trackers')

    const steps = toRef(props.transfer, 'steps')

    const delay = toRef(props.transfer, 'delay')

    const hasActiveGps = computed<boolean>(() => {
      const previousHour = moment().subtract(1, 'hours')
      return moment(mostRecentUpdate.value).isSameOrAfter(previousHour) ?? false
    })

    const hasNextIncompleteStep = computed(() =>
      Boolean(steps.value?.find(step => step.is_done === false))
    )

    const humanizedDelay = computed(() => {
      if (delay.value === null) return 'n/a'
      return Number.isInteger(delay.value) && delay.value > 0
        ? moment.duration(delay.value, 'minutes').humanize()
        : t('boards/delay-status-on-time')
    })

    const transferCategory = computed<string>(() => {
      if (delay.value > 30) {
        return 'red'
      }
      if (delay.value > 0 && delay.value < 30) {
        return 'primary'
      }
      if (delay.value === 0) {
        return 'green'
      }
      return 'tertiary'
    })

    onBeforeMount(async (): Promise<void> => {
      if (trackersIds.value?.length > 0)
        trackers.value = await getTrackerList(trackersIds.value)
    })

    return {
      humanizedDelay,
      trackers,
      hasNextIncompleteStep,
      mostRecentUpdate,
      transferCategory,
      hasActiveGps
    }
  }
})

import qs from 'qs'

const DEFAULT_DASHBOARD_URL =
  'https://sennder.de.looker.com/dashboards-next/snowflake::carrier_search_dashboard'

export default class CarrierSearchDashboardLinkBuilder {
  constructor(url = DEFAULT_DASHBOARD_URL) {
    this.url = url
    this.params = {
      'Origin+Country+Code': '',
      'Origin+Postal+Code': '',
      'Origin+Locality': '',
      'Vehicle+Type(s)': '',
      'Load+Type(s)': '',
      'Destination+Country+Code': '',
      'Destination+Postal+Code': ' ',
      'Destination+Locality': '',
      'Origin+Radius+Parameter+[km]': '25',
      'Destination+Radius+Parameter+[km]': '25',
      'Pickup+Preferences': '',
      'Delivery+Preferences': ''
    }
  }

  get queryString() {
    return qs.stringify(this.params, { encodeValuesOnly: true }) + '&Date=2+years' // fix for Looker parsing problem
  }

  build() {
    return `${this.url}?${this.queryString}`
  }

  originCountryCode(value) {
    this.params['Origin+Country+Code'] = value
    return this
  }

  originPostalCode(value) {
    this.params['Origin+Postal+Code'] = value
    return this
  }

  originLocality(value) {
    this.params['Origin+Locality'] = value
    return this
  }

  originRadius(value) {
    this.params['Origin+Radius+Parameter+[km]'] = value
    return this
  }

  destinationCountryCode(value) {
    this.params['Destination+Country+Code'] = value
    return this
  }

  destinationPostalCode(value) {
    this.params['Destination+Postal+Code'] = value
    return this
  }

  destinationLocality(value) {
    this.params['Destination+Locality'] = value
    return this
  }

  destinationRadius(value) {
    this.params['Destination+Radius+Parameter+[km]'] = value
    return this
  }

  vehicleType(value) {
    this.params['Vehicle+Type(s)'] = value
    return this
  }

  loadType(value) {
    this.params['Load+Type(s)'] = value
    return this
  }
}

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
}
const timeOptions: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit'
}

export const formatTimestamps = (ts: string | number): string => {
  const dateObject = new Date(ts)
  const browserDate = dateObject.toLocaleDateString('de-DE', dateOptions)
  const browserTime = dateObject.toLocaleTimeString('de-DE', timeOptions)

  return `${browserDate} | ${browserTime}`
}

import {
  CarrierCompany,
  CarrierContact,
  OrderDetail,
  TransferDetail,
  VehicleType
} from '@/services'
import { ExtendedPerson } from '@/compositions/useOrder'
import { WritableComputedRef } from '@vue/composition-api'

// This enables to get enum key by value, e.g. Provider['driver-app']
/* eslint-disable @typescript-eslint/no-explicit-any */
export enum Provider {
  gatehouse = <any>'gatehouse',
  co3 = <any>'co3',
  driverApp = <any>'driver-app'
}

export interface Tracker {
  vehicle: string | boolean
  trailer: string | boolean
}

export type TrackerByLicensePlate =
  | {
      lastUpdate: string | null
    }
  | false

export interface TrackingConfig {
  gatehousePullOn: boolean
  gatehousePushOn: boolean
  amazonPushOn: boolean
}

export interface DispatchingTransferDetail extends TransferDetail {
  driverName: string
  driverPhoneNumber: string
}

export interface DispatchingOrderDetail extends OrderDetail {
  carrierCompanyId: number
}

export type TrackersInfo = {
  [key in keyof typeof Provider]: Tracker
}

export interface FormValues extends TrackersInfo {
  allowedVehicleTypes: string[] | null
  carrierCompany: CarrierCompany | null | Record<string, unknown>
  carrierContact: CarrierContact | null | Record<string, unknown>
  notes: string | null
  vehicleType: VehicleType | null
  licensePlate: WritableComputedRef<string>
  trailerLicensePlate: WritableComputedRef<string>
  transferCode: string | null
  driverName: string
  driverPhoneNumber: string
  gatehousePullOn: boolean
  gatehousePushOn: boolean
  amazonPushOn: boolean
  baseCost: number | null
  costSurcharge: unknown
  costSurchargeDescription: unknown
  partnerManager: null | ExtendedPerson
  dispatchedVehicle: null
}

export interface DirectDispatchingFormValues {
  carrierCompany: CarrierCompany | null
  carrierContact: CarrierContact | null
  notes: string | null
  baseCost: number | null
  costSurcharge: number | null
  costSurchargeDescription: string | null
  accountManager: ExtendedPerson | null
  juniorAccountManager: ExtendedPerson | null
  partnerManager: ExtendedPerson | null
  spotBidder: ExtendedPerson | null
}

export interface DirectDispatchingUpdate {
  order: {
    accountManagerId: number | null
    juniorAccountManagerId: number | null
    carrierCompanyId: number | null
    carrierContactId: number | null
    baseCost: number | null
  }
}

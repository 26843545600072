/* eslint-disable no-console */
import { ILogProvider } from '@sennder/senn-node-microfrontend-interfaces'

const consoleLogger: ILogProvider = {
  debug(message: string, messageContext: object, customProperties?: Array<string>) {
    console.debug(message, messageContext, customProperties)
  },
  info(message: string, messageContext: object, customProperties?: Array<string>) {
    console.log(message, messageContext, customProperties)
  },
  warn(message: string, messageContext: object, customProperties?: Array<string>) {
    console.warn(message, messageContext, customProperties)
  },
  error(message: string, messageContext: object, customProperties?: Array<string>) {
    console.error(message, messageContext, customProperties)
  },
  setUserData: function (): void {
    throw new Error('[logger] setUserData function is not implemented.')
  },
  unsetUserData: function (): void {
    throw new Error('[logger] unsetUserData function is not implemented.')
  },
  setContext: function (): void {
    throw new Error('[logger] setContext function is not implemented.')
  }
}

let logger = consoleLogger

export const setLogger = (log: ILogProvider) => {
  logger = log
}

export { logger as default }

import moment from 'moment-timezone'
import { DATE_TIME_FORMAT } from '@/global-setup/filters'

export const createUtcByWarehouseTimezone = (
  date: string,
  time: string,
  tz: string
): string => {
  return moment.tz(`${date} ${time}`, DATE_TIME_FORMAT, tz).utc().format()
}

export const formatUtcByTimezone = (utc: string, tz: string, format: string): string => {
  return moment.tz(utc, tz).format(format)
}

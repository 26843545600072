<script>
import { MICRO_FRONTEND_MODE } from '@/config'

export default {
  props: {
    listElement: { type: HTMLElement, default: null },
    limit: { type: Number, required: true },
    isLoading: { type: Boolean, required: true },
    areAllPagesFetched: { type: Boolean, default: false },
    resetOffset: { type: Boolean, default: false }
  },
  data: () => ({
    offset: 0
  }),
  watch: {
    '$route.query': {
      handler() {
        this.offset = 0
      },
      deep: true
    },
    resetOffset: {
      handler() {
        if (this.resetOffset === true) {
          this.offset = 0
        }
      }
    }
  },
  created() {
    /**
     * In the shell, dsf-page-layout will be scrollable instead of window.
     */
    const scrolledElement = MICRO_FRONTEND_MODE
      ? document.querySelector('dsf-page-layout [slot="content"]')
      : window

    scrolledElement.addEventListener('scroll', this.checkPaginationOnScroll)
  },
  destroyed() {
    const scrolledElement = MICRO_FRONTEND_MODE
      ? document.querySelector('dsf-page-layout [slot="content"]')
      : window

    scrolledElement.removeEventListener('scroll', this.checkPaginationOnScroll)
  },
  methods: {
    checkPaginationOnScroll() {
      if (this.isBottomOfBoardVisible()) {
        this.loadNextPage()
      }
    },
    isBottomOfBoardVisible() {
      return (
        this.listElement &&
        this.listElement.getBoundingClientRect().bottom < window.innerHeight + 100
      )
    },
    async loadNextPage() {
      if (!this.isLoading && !this.areAllPagesFetched) {
        this.offset += this.limit
        this.$emit('onNextPageLoad', this.offset)
        // We keep on loading pages while the bottom of the board is visible
        this.$nextTick(this.checkPaginationOnScroll)
      }
    }
  },
  render: () => null // no DOM needed here
}
</script>

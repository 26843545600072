<template>
  <div class="applied-filters">
    <div v-for="item in appliedFilterValues" :key="item.label" class="applied-filter">
      <FilterChip
        :text="item.label"
        class="filter-chip"
        @remove-filter-chip="removeFilter(item)"
      />
    </div>
  </div>
</template>

<script>
export default {
  inject: ['getAppliedValues', 'getFilterValue', 'setFilterValue'],
  computed: {
    appliedFilterValues() {
      return this.getAppliedValues()
    }
  },
  methods: {
    removeFilter(item) {
      this.setFilterValue(item.updateName, item.emptyValue, true)
      this.$emit('applyFilters')
    }
  }
}
</script>

<style lang="scss" scoped>
.applied-filters {
  margin-bottom: 16px;
}

.applied-filter {
  display: inline-block;
  margin-bottom: 3px;
  margin-right: 6px;
}

.filter-chip {
  max-width: 500px;

  &::v-deep span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>

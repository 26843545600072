import { computed, Ref } from '@vue/composition-api'
import { getHereMapsParameters } from '@/services/ordering-service'
import { HereMapsParameters } from '@/services'
import { StopLocation } from '../../../types'
import H from '@here/maps-api-for-javascript'

interface Point {
  latitude: number
  longitude: number
}

interface Waypoint {
  label: string
  linkId: string
  mappedPosition: Point
  mappedRoadName: string
  originalPosition: Point
  shapIndex: number
  sideOfStreet: string
  source: string
  spot: number
  type: string
}

interface Maneuver {
  position: Point
  instruction: string
  travelTime: number
  length: number
  shape: string[]
  note: unknown[]
  id: string
}

interface Route {
  waypoint: Waypoint[]
  mode: {
    type: string
    tranportModes: string[]
    trafficMode: string
    feature: unknown[]
  }
  shape: string[]
  leg: {
    maneuver: Maneuver[]
  }
}

export interface RouteResponse {
  response: {
    language: string
    route: Route[]
  }
}

export default async (points: Ref<StopLocation[]>) => {
  const routingParams: HereMapsParameters | null = await getHereMapsParameters()

  const waypoints: { [key: string]: string } = {}

  points.value.forEach((point, index) => {
    waypoints['waypoint' + index] = `geo!${point.lat},${point.lng}`
  })

  const routingParameters = computed(() =>
    routingParams
      ? {
          mode: routingParams.mode,
          alternatives: routingParams.alternatives,
          representation: 'display',
          truckType: routingParams.truckType,
          ...waypoints
        }
      : null
  )

  const getRouteLine = (result: RouteResponse) => {
    const route = result.response.route[0]
    // Create a linestring to use as a point source for the route line
    const linestring = new H.geo.LineString()
    // Push all the points in the shape into the linestring:
    route.shape.forEach(point => {
      const parts = point.split(',')
      linestring.pushLatLngAlt(Number(parts[0]), Number(parts[1]), undefined)
    })

    const routeLine = new H.map.Polyline(linestring, {
      data: null,
      style: { lineWidth: 6, strokeColor: '#1890FF' }
    })
    return routeLine
  }

  return { routingParameters, getRouteLine }
}

import {
  IStopExecution,
  IStopPlan,
  IStopActionType,
  IStopTimestampSource,
  TransferStep
} from '@/services'

export const useTransitMappers = () => {
  const validateStopPlansAndTransferSteps = (
    stopPlans: IStopPlan[],
    transferSteps: TransferStep[]
  ) => {
    if (!(Array.isArray(stopPlans) && Array.isArray(transferSteps))) {
      throw new Error(
        'Transit error: Received invalid stopPlans or transferSteps: ' +
          `stopPlans: ${JSON.stringify(stopPlans)}` +
          `transferSteps: ${JSON.stringify(transferSteps)}`
      )
    }
    if (stopPlans.length * 2 !== transferSteps.length) {
      throw new Error(
        'Transit error: expected transferStep count does ' +
          'not match stopPlan count. It should be twice as stopPlan ' +
          `transferSteps.length: ${transferSteps.length} ` +
          `stopPlans.length: ${stopPlans.length}`
      )
    }
  }

  const mapStopPlansToSteps = (
    stopPlans: IStopPlan[],
    transferSteps: TransferStep[]
  ): TransferStep[] => {
    validateStopPlansAndTransferSteps(stopPlans, transferSteps)

    return stopPlans.flatMap((sp, i) => {
      return [
        {
          ...transferSteps[i * 2],
          scheduledTime: sp.carrierCommunicatedArrivalStart
        },
        {
          ...transferSteps[i * 2 + 1],
          scheduledTime: sp.carrierCommunicatedArrivalEnd
        }
      ]
    })
  }

  const getArrivalDepartureSteps = (
    groupSteps: TransferStep[][],
    ordinal: string | number
  ) => {
    return groupSteps[ordinal]
  }

  /** use step.hasUnsavedActualTime to filter out if
   * 1) a step was overriden from geofence to user input
   * 2) to check if timestamp was changed or added
   */
  const generateStopTimestamps = (
    arrivalStep: TransferStep,
    departureStep: TransferStep,
    originalArrivalStep: TransferStep,
    originalDepartureStep: TransferStep
  ) => {
    const timestamps = []

    const handleStep = (
      step: TransferStep,
      originalStep: TransferStep,
      actionType: IStopActionType
    ) => {
      // step.hasUnsavedActualTime is set True, even in case user first enters it on a blank field and
      // then uses backspace, checking explicitly for step.actualTime
      if (
        step.hasUnsavedActualTime &&
        step.actualTime &&
        step.actualTime !== originalStep.actualTime
      ) {
        timestamps.push({
          timestamp: step.actualTime,
          actionType: actionType,
          source: IStopTimestampSource.USER_INPUTTED
        })
      }
    }

    handleStep(arrivalStep, originalArrivalStep, IStopActionType.ARRIVAL)
    handleStep(departureStep, originalDepartureStep, IStopActionType.DEPARTURE)

    return timestamps
  }

  const generateStopCompletedActions = (
    arrivalStep: TransferStep,
    departureStep: TransferStep
  ) => {
    return [arrivalStep, departureStep]
      .filter(step => step.hasUnsavedStepDone)
      .map(step => ({
        actionType:
          step === arrivalStep ? IStopActionType.ARRIVAL : IStopActionType.DEPARTURE
      }))
  }

  const generateStopExecution = (
    stopPlan: IStopPlan,
    groupSteps: TransferStep[][],
    originalSteps: TransferStep[]
  ): IStopExecution => {
    const [arrivalStep, departureStep] = getArrivalDepartureSteps(
      groupSteps,
      stopPlan.ordinal
    )

    const originalArrivalStep = originalSteps.find(step => step.id === arrivalStep.id)
    const originalDepartureStep = originalSteps.find(step => step.id === departureStep.id)

    return {
      stopPlanId: stopPlan.id,
      stopTimestamps: generateStopTimestamps(
        arrivalStep,
        departureStep,
        originalArrivalStep,
        originalDepartureStep
      ),
      stopCompletedActions: generateStopCompletedActions(arrivalStep, departureStep)
    }
  }

  const groupStepsBasedOnIndex = (transferSteps: TransferStep[]): TransferStep[][] => {
    return (
      transferSteps
        .map((step, index) => {
          if (index % 2 === 0) {
            return [step, transferSteps[index + 1]]
          }
        })
        // filter out items when the index is odd
        .filter(step => step)
    )
  }

  const mapStopPlansToStopExecutions = (
    stopPlans: IStopPlan[],
    transferSteps: TransferStep[],
    originalSteps: TransferStep[]
  ): IStopExecution[] => {
    validateStopPlansAndTransferSteps(stopPlans, transferSteps)

    const groupSteps = groupStepsBasedOnIndex(transferSteps)
    return stopPlans
      .map(stopPlan => generateStopExecution(stopPlan, groupSteps, originalSteps))
      .filter(
        stopExecution =>
          stopExecution.stopTimestamps.length > 0 ||
          stopExecution.stopCompletedActions.length > 0
      )
  }

  return {
    mapStopPlansToSteps,
    mapStopPlansToStopExecutions
  }
}

import {
  ShipmentState,
  ShipmentPartialUpdateStatus,
  ShipmentEditRequestMainState
} from '@/modules/shipment/types/shipment'
import {
  SHIPMENT_EDIT_REQUEST_PARTIAL_UPDATES_STATUS,
  SHIPMENT_EDIT_REQUEST_MAIN_STATUS
} from '@/modules/shipment/constants'

const getInitialState = (): ShipmentState => ({
  shipmentV3: {},
  shipmentStops: [],
  isShipmentStopsLoaded: false,
  shipmentRoute: { shipment_routes: [] },
  isFetchingShipmentEditRequestState: false,
  shipmentEditRequestMainState:
    SHIPMENT_EDIT_REQUEST_MAIN_STATUS.INITIAL as ShipmentEditRequestMainState,
  shipmentEditPartialUpdate: {
    scheduleUpdate:
      SHIPMENT_EDIT_REQUEST_PARTIAL_UPDATES_STATUS.NO_CHANGES as ShipmentPartialUpdateStatus,
    referenceUpdate:
      SHIPMENT_EDIT_REQUEST_PARTIAL_UPDATES_STATUS.NO_CHANGES as ShipmentPartialUpdateStatus,
    vehicleRequirementUpdate:
      SHIPMENT_EDIT_REQUEST_PARTIAL_UPDATES_STATUS.NO_CHANGES as ShipmentPartialUpdateStatus,
    trackingRequirementUpdate:
      SHIPMENT_EDIT_REQUEST_PARTIAL_UPDATES_STATUS.NO_CHANGES as ShipmentPartialUpdateStatus
  },
  hasShipmentEditAnyUpdates: false
})

export default getInitialState

<template>
  <Form class="form" @validated-submit="handleFormSubmit">
    <InputField
      v-model="email"
      :inputValidators="emailValidators"
      type="text"
      :title="$t('auth/login-by-email-form-email-field-label')"
      :placeholder="$t('auth/login-by-email-form-email-field-placeholder')"
      fullWidth
      data-test="login-email"
    />
    <InputField
      v-model="password"
      :inputValidators="passwordValidators"
      :type="passwordHidden ? 'password' : 'text'"
      :title="$t('auth/login-by-email-form-password-field-label')"
      :placeholder="$t('auth/login-by-email-form-password-field-placeholder')"
      materialIcon="remove_red_eye"
      fullWidth
      class="password-field"
      data-test="login-password"
      @click-on-icon="togglePasswordVisibility"
    />
    <div class="forgot-password-link-container">
      <LinkedText to="/auth/forgot-password">
        {{ $t('auth/login-by-email-form-forgot-password-link') }}
      </LinkedText>
    </div>
    <Button
      :category="isPosteTheme ? 'dark' : 'primary'"
      class="login-button"
      :text="$t('auth/login-by-email-form-login-btn')"
      type="submit"
      data-test="login-submit"
    />
  </Form>
</template>
<script>
import { isPosteTheme } from '@/controllers/environment-detection'

const VALID_EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default {
  data() {
    return {
      email: '',
      password: '',
      errorMessage: null,
      passwordHidden: true
    }
  },
  computed: {
    isPosteTheme() {
      return isPosteTheme
    }
  },
  created() {
    this.emailValidators = [
      {
        errorMessage: this.$t('auth/login-by-email-form-field-required-email-msg'),
        isInvalid: this.isValueMissing
      },
      {
        errorMessage: this.$t('auth/login-by-email-form-field-required-valid-email-msg'),
        isInvalid: this.isInvalidEmail
      }
    ]
    this.passwordValidators = [
      {
        errorMessage: this.$t('auth/login-by-email-form-field-required-password-msg'),
        isInvalid: this.isValueMissing
      }
    ]
  },
  methods: {
    async handleFormSubmit({ hasErrors }) {
      if (hasErrors) return
      this.$emit('submit', { email: this.email, password: this.password })
    },
    togglePasswordVisibility() {
      this.passwordHidden = !this.passwordHidden
    },
    isInvalidEmail(value) {
      if (this.isValueMissing(value)) return false
      return !VALID_EMAIL_REGEX.test(value)
    },
    isValueMissing(value) {
      return !value
    }
  }
}
</script>
<style scoped lang="scss">
.form {
  text-align: left;
}

.password-field {
  margin-bottom: 4px;
}

.forgot-password-link-container {
  text-align: right;
  line-height: 16px;
}

button.login-button {
  width: 240px;
  display: block;
  margin: 26px auto 0;
}
</style>

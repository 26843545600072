<template>
  <Form ref="form" :disabled="disabled">
    <div class="plankton-grid" v-if="showCarrier">
      <div class="plankton-cell" span="7">
        <CarrierTypeAheadField
          :source="fetchScreenedCarriers"
          :value="carrierCompany"
          @input="$emit('update:carrierCompany', $event)"
          :disabled="mode === 'REGULAR'"
          :required="mode === 'DIRECT_DISPATCHER'"
          title="Carrier company"
          class="fullwidth"
          data-test="dispatching-carrier-field"
        />
      </div>
      <div class="plankton-cell" span="5">
        <CarrierContactDropdownField
          :value="carrierContact"
          @select="$emit('update:carrierContact', $event)"
          :carrierId="get(carrierCompany, 'id', null)"
          :disabled="disabled || mode === 'REGULAR'"
          :required="mode === 'DIRECT_DISPATCHER'"
          title="Contact person"
          class="fullwidth"
          data-test="dispatching-carrier-contact-field"
        />
      </div>
      <div class="plankton-cell" span="5">
        <InputField
          :value="get(carrierContact, 'phone', null)"
          disabled
          title="Phone number"
          class="fullwidth"
          data-test="dispatching-carrier-phone-field"
        />
      </div>
      <div class="plankton-cell" span="7">
        <InputField
          :value="get(carrierContact, 'email', null)"
          disabled
          title="Email"
          class="fullwidth"
          data-test="dispatching-carrier-email-field"
        />
      </div>
    </div>
    <div class="plankton-grid">
      <div class="plankton-cell" span="17">
        <InputField
          :disabled="mode === 'DIRECT_DISPATCHER'"
          :value="notes"
          @input="$emit('update:notes', $event)"
          class="fullwidth"
          title="Notes to carrier"
          placeholder="Enter notes to carrier"
          data-test="dispatching-carrier-notes-field"
        />
      </div>
    </div>
  </Form>
</template>

<script>
import get from 'lodash/get'

import { FORM_MODE_REGULAR, isValidMode } from './modes'

import CarrierTypeAheadField from '@/modules/ordering/components/shared/CarrierTypeAheadField'
import { fetchScreenedCarriers } from '@/services/find-carriers-service'
import CarrierContactDropdownField from '@/modules/ordering/components/shared/CarrierContactDropdownField'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    CarrierTypeAheadField,
    CarrierContactDropdownField
  },
  props: {
    notes: { type: String, required: false, default: null },
    carrierContact: { type: Object, required: false, default: null },
    carrierCompany: { type: Object, required: false, default: null },
    disabled: { type: Boolean, required: true },
    mode: {
      type: String,
      required: false,
      default: FORM_MODE_REGULAR,
      validator: isValidMode
    },
    showCarrier: { type: Boolean, default: true }
  },
  methods: {
    fetchScreenedCarriers,
    get,
    submit() {
      return this.$refs.form.submit()
    }
  }
})
</script>

<style lang="scss" scoped>
.fullwidth {
  width: 100% !important;
}
</style>

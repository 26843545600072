<template>
  <LabeledText>
    <span class="bold">{{ count }}</span>
    {{ specName }}
    <span v-if="showSeparator">&nbsp;|&nbsp;</span>
  </LabeledText>
</template>

<script>
import LabeledText from '@/modules/common/components/LabeledText.vue'

export default {
  components: { LabeledText },
  props: {
    count: { type: Number, required: true },
    specName: { type: String, required: true },
    showSeparator: { type: Boolean, default: false }
  }
}
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>

import Vue from 'vue'

export const addFilteredOrders = (state, orders) => {
  state.orders.push(...orders)
}

export const clearFilteredOrders = state => {
  state.orders = []
}

export const toggleOrder = (state, orderId) => {
  const orderIndex = state.orders.findIndex(order => order.id === orderId)
  const modifiedOrder = state.orders[orderIndex]

  Vue.set(modifiedOrder, 'selected', !modifiedOrder.selected)
  Vue.set(state.orders, orderIndex, modifiedOrder)
}

export const toggleOrders = (state, isCheckedNew) => {
  const modifiedOrders = state.orders.map(order => {
    Vue.set(order, 'selected', isCheckedNew)
    return order
  })

  if (state.orders.length > 0) {
    state.orders = modifiedOrders
  }
}

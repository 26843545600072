<template>
  <fragment>
    <DropdownField
      :value="value"
      v-bind="$attrs"
      :options="addresses"
      :title="title + (required ? '*' : '')"
      :validators="validators"
      :placeholder="placeholder"
      searchable
      optionLabel="fullAddress"
      optionKey="companyAddressId"
      fullWidth
      @select="$emit('select', $event)"
      @search-change="updateSearchQuery"
    >
      <template #noResult>
        <div v-if="customerId" class="new-address-option">
          <span class="not-address-label">
            {{ $t('ordering/customer-address-dropdown-field-not-address-label') }}
          </span>
          <Button
            v-if="canAddNewAddress"
            type="button"
            category="link"
            materialIcon="add"
            text="Add new address"
            data-test="add-new-address"
            :data-track="null"
            @click="addNewAddress"
          />
        </div>
        <div v-else>
          {{ $t('ordering/customer-address-dropdown-field-empty-list') }}
        </div>
      </template>
    </DropdownField>
    <component
      :is="addAddressComponent"
      v-if="renderNewAddressDialog"
      ref="newAddressDialog"
      data-test="new-address-dialog"
    />
  </fragment>
</template>
<script>
import Vue from 'vue'
import isEmpty from 'lodash/isEmpty'
import memoize from 'lodash/memoize'
import { fetchCustomerAddresses } from '@/services/customer-service'
import NewCustomerAddressDialog from './NewCustomerAddressDialog'
import useCurrentUser from '@/compositions/useCurrentUser'

/*
 * Memoize fetch address results since multiple stops can have the same customer when rendering.
 * Function is exported for testing purpose.
 */
export const fetchAddressesMemoized = memoize(fetchCustomerAddresses)
let clearCacheTimeoutId = null

export default {
  components: {
    NewCustomerAddressDialog
  },
  model: { prop: 'value', event: 'select' },
  props: {
    value: { type: Object, default: null },
    customerId: { type: Number, default: null },
    required: { type: Boolean, default: false },
    inlineError: { type: Boolean, default: false },
    title: { type: String, default: 'Company address' },
    placeholder: { type: String, default: 'Select company address' }
  },
  data() {
    return { addresses: [], searchQuery: null, renderNewAddressDialog: false }
  },
  computed: {
    addAddressComponent() {
      return NewCustomerAddressDialog
    },
    validators() {
      if (!this.required) return []
      return [
        {
          isInvalid: isEmpty,
          errorMessage: this.inlineError
            ? 'This field cannot be empty'
            : 'Address cannot be empty',
          inline: this.inlineError
        }
      ]
    },
    canAddNewAddress() {
      const { currentUserIsAccountManager } = useCurrentUser()
      return currentUserIsAccountManager.value
    }
  },
  watch: {
    customerId(newCostumerId, oldCustomerId) {
      if (newCostumerId && newCostumerId !== oldCustomerId) {
        this.emitValueUpdate(null)
        this.fetchAddresses(newCostumerId)
      } else if (newCostumerId === null) {
        this.addresses = []
        this.emitValueUpdate(null)
      }
    }
  },
  async mounted() {
    if (this.customerId !== null) {
      await this.fetchAddresses(this.customerId)
    }
  },
  methods: {
    async fetchAddresses(costumerId) {
      if (!clearCacheTimeoutId) this.scheduleCacheCleanUp()
      this.addresses = await fetchAddressesMemoized(costumerId)
    },
    scheduleCacheCleanUp() {
      clearCacheTimeoutId = setTimeout(() => {
        fetchAddressesMemoized.cache.clear()
        clearCacheTimeoutId = null
      }, 1000)
    },
    emitValueUpdate(newValue) {
      if (this.value !== newValue) {
        this.$emit('select', newValue)
      }
    },
    updateSearchQuery($event) {
      this.searchQuery = $event
    },
    async addNewAddress() {
      //  Only render dialog when user tries to add a new address.
      //  This is to avoid having a dialog inside another dialog when component renders.
      this.renderNewAddressDialog = true
      await Vue.nextTick()
      const result = await this.$refs.newAddressDialog.show(
        this.customerId,
        this.searchQuery
      )
      if (result) {
        fetchAddressesMemoized.cache.clear()
        this.fetchAddresses(this.customerId)
        this.emitValueUpdate(result)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.new-address-option {
  display: flex;
  justify-content: space-between;
}

.not-address-label {
  color: $color-neutral-main;
}
</style>











































import { PropType, defineComponent } from '@vue/composition-api'
import { OrderDetail } from '@/services'
import { placeholderIfEmpty } from '@/global-setup/filters'
import LabeledText from '@/modules/common/components/LabeledText.vue'
import useFeatureFlag from '@/compositions/useFeatureFlag'

export default defineComponent({
  components: { LabeledText },
  props: {
    order: { type: Object as PropType<OrderDetail>, required: true },
    loadQuantityAndType: { type: String, required: true },
    loadDimensions: { type: String, required: true },
    loadTemperatures: { type: String, default: null },
    vehicleTypes: { type: String, default: null },
    getRoundedTime: { type: Function, required: true }
  },
  setup() {
    const { isActive } = useFeatureFlag()
    const isNewV3OrderSidebarEnabled = isActive('ENABLE_NEW-V3-ORDER-SIDEBAR')

    return { placeholderIfEmpty, isNewV3OrderSidebarEnabled }
  }
})

<template>
  <div class="current-load">
    <div class="subsection title">Current load</div>
    <div class="current-load-stop">
      <img src="./arrow-with-dots.svg" />
      <LocationAndTime
        :countryCode="order.destinationCountryCode"
        :postalCode="order.destinationPostalCode"
        :startDate="order.deliveryStart"
        :endDate="order.deliveryEnd"
      />
    </div>
  </div>
</template>

<script>
import LocationAndTime from './LocationAndTime.vue'

export default {
  components: {
    LocationAndTime
  },
  props: {
    order: { type: Object, required: true }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  color: $color-neutral-main;
}

.current-load-stop {
  display: flex;
  padding-left: 10px;
  background-color: $color-layout-card-background;
}
</style>

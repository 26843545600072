export function readPersistedState<T>(field: string, defaultValue: T) {
  const result = window.localStorage.getItem(`store.${field}`)
  return result === null ? defaultValue : (JSON.parse(result) as T)
}

export function writePersistedState<T>(field: string, value: T) {
  window.localStorage.setItem(`store.${field}`, JSON.stringify(value))
}

export function removePersistedState(field: string) {
  window.localStorage.removeItem(`store.${field}`)
}

export const searchOrdersQuery = `{
  account_manager_id
  junior_account_manager_id
  base_price
  base_cost
  total_net_price
  total_net_cost
  delivery_start
  delivery_end
  letter
  pickup_start
  pickup_end
  id_for_staff
  reference_number
  state
  is_cancelled
  vehicle_type
  allowed_vehicle_types
  transfer_id
  carrier_company_id {
    name
    contact_ids {
      company_id
      first_name
      last_name
    }
  }
  origin_company_address_id {
    company_id
    {
      name
    }
    country_code
    address_id {
      country_code
      country
      formatted
      locality
      postal_code
      timezone
    }
  }
  destination_company_address_id {
    company_id
    {
      name
    }
    address_id {
      formatted
      timezone
    }
  }
  customer_company_id {
    name
  }
}`

import Vue from 'vue'
import Router from 'vue-router'
import { Container, BackendError } from '@/modules/layout'
import { Login } from '../modules/auth'
import orderingRoutes from '@/modules/ordering/routes'
import { SearchPage } from '@/modules/search-board'
import { FinancePage } from '@/modules/finance-board'
import financeRoutes from '@/modules/finance/routes'
import { routeComponents } from '@sennder/contract-management'
import { i18n } from '@/plugins/i18n'
import CarrierContracts from '@/modules/carrier-contracts/CarrierContracts.vue'

Vue.use(Router)

// Replace Router.push to silence the rejection and make the promise resolve with the error instead:
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: [
    {
      name: 'login',
      path: '/login',
      component: Login,
      meta: {
        title: 'Login'
      }
    },
    {
      name: 'error',
      path: '/error',
      component: BackendError
    },
    ...financeRoutes,
    {
      path: '/',
      component: Container,
      children: [
        {
          name: 'search-board',
          path: 'dashboard/overview',
          alias: ['', '/#/'],
          component: SearchPage,
          meta: {
            title: 'Search Board'
          }
        },
        {
          name: 'finance-dashboard',
          path: 'dashboard/finance',
          alias: '',
          component: FinancePage,
          meta: {
            title: i18n.t('global/router-finance-board-title')
          }
        },
        ...orderingRoutes,
        {
          path: '/contract-management',
          name: 'contract-management',
          redirect: 'contract-management/contracts',
          component: routeComponents.Container,
          children: [
            {
              path: 'contracts',
              name: 'contracts',
              component: routeComponents.ContractList
            },
            {
              path: 'contracts/:id',
              name: 'contract-details',
              component: CarrierContracts
            }
          ]
        }
      ]
    }
  ]
})

export default router

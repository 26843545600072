import { createEntityListFilter } from './entity-list'
import { fetchTeamLeads } from '@/services/dispatching-board-service'

const emptyValue = []

/**
 * @typedef {import('./factories/entity-list').FilterValue} FilterValue
 * @typedef {import('./filter').UrlQueryFragment} UrlQueryFragment
 */

/**
 * @param {FilterValue} data
 * @returns {any} - vuex module instance
 */
export const createTeamLeadsFilter = (initialValue = emptyValue) => {
  const urlParamName = 'team_ids'

  const filter = createEntityListFilter({
    name: 'team_ids',
    urlParamName,
    optionLabel: 'name',
    getEntityFromId: fetchTeamLeads,
    initialValue,
    updateName: 'teamIds',
    filterLabel: 'filters/team-leads-filter-label'
  })

  filter.actions = {
    ...filter.actions,
    /**
     * @param {*} store
     * @param {UrlQueryFragment} urlQueryFragment
     */
    async setValueFromUrl({ commit }, urlQueryFragment) {
      const urlValue = urlQueryFragment[urlParamName]
      if (urlValue) {
        const teamLeadsIds = urlValue.split(',')
        const allTeamLeads = await fetchTeamLeads()
        const selectedTeamLeads = teamLeadsIds.map(id =>
          allTeamLeads.find(teamLeadData => teamLeadData.id === Number(id))
        )

        commit('update', selectedTeamLeads)
      } else if (urlValue === null) commit('update', emptyValue)
    }
  }

  return filter
}

import { runJsonPatch, runJsonPost, runQuery } from '@/models/backend-client'

const fetch = async (store, { query, url, transaction }) => {
  const result = await runQuery(url, query, { transaction })
  if (!result) return {}
  store.commit('replace', result.data)
  return result.data
}

const create = async (store, { Model, object }) => {
  const result = await runJsonPost(`${Model.URL}`, object)
  const data = result.data
  patchRestIdFields(data)

  // company_addresses are returned by the REST API as nested
  // JSON and are therefore not compatible to be inserted here
  if (Model.NAME !== 'company_addresses') {
    replaceModels(store, Model.NAME, data)
    return data.id
  }
}

const replaceModels = (store, modelName, data) => {
  store.commit('replace', { [`${modelName}`]: { [`${data.id}`]: data } })
}

const update = async (store, { Model, id, updated_fields }) => {
  // model_json = JSON.stringify(updated_fields)
  const url = `${Model.URL}${id}/`
  const result = await runJsonPatch(url, updated_fields)
  const data = result.data
  patchRestIdFields(data)
  // company_addresses are returned by the REST API as nested
  // JSON and are therefore not compatible to be inserted here
  if (Model.NAME !== 'company_addresses') replaceModels(store, Model.NAME, data)
}

const patchRestIdFields = data => {
  // Other than Django Rest API, fields in the graphql endpoint are
  // ending with '_id'. That is why we need to override the response
  // data to make sure that it is compatible with vuex store.
  const restIdFields = [
    'customer_company',
    'customer_company_address',
    'customer_contact',
    'destination_company',
    'destination_company_address',
    'destination_contact',
    'origin_company',
    'origin_company_address',
    'origin_contact',
    'account_manager',
    'carrier_company',
    'carrier_contact'
  ]

  for (const restIdField of restIdFields) {
    const graphqlField = restIdField + '_id'
    data[graphqlField] = data[restIdField]
    delete data[restIdField]
  }
}

export { fetch, create, update }

import { OPERATIONS_BACKEND_URL } from '@/config'
import { runJsonGet, runJsonPut } from '@/models/backend-client'
import { transformKeysToCamelCase, transformKeysToSnakeCase } from './utils/casing'
import { AxiosResponse } from 'axios'

interface PalletExchangeConfiguration {
  id: number
  orderId: number
  palletExchangeType: string
  address: number | null
}

const PALLET_EXCHANGE_COMMANDS_URL = `${OPERATIONS_BACKEND_URL}/pallet-exchange/commands`
const PALLET_EXCHANGE_QUERIES_URL = `${OPERATIONS_BACKEND_URL}/pallet-exchange/queries`

export const queryPalletExchangeConfiguration = async (orderId: number) => {
  const URL = `${PALLET_EXCHANGE_QUERIES_URL}/get_pallet_exchange_configuration/${orderId}`
  const NON_EXISTING_PALLET_EXCHANGE_CONFIGURATION = { pallet_exchange_type: null }

  try {
    const response = (await runJsonGet(URL)) as AxiosResponse<{
      type: string
      address: number | null
    }>
    return transformKeysToCamelCase(response.data)
  } catch (error) {
    if (error.response.status === 404) return NON_EXISTING_PALLET_EXCHANGE_CONFIGURATION
    throw error
  }
}

export const queryPalletExchangeAddresses = async () => {
  const URL = `${PALLET_EXCHANGE_QUERIES_URL}/list_pallet_exchange_locations`
  return transformKeysToCamelCase<{ id: number; formattedAddress: string }[]>(
    (
      (await runJsonGet(URL)) as AxiosResponse<{
        results: { id: number; formattedAddress: string }[]
      }>
    ).data.results
  )
}

export const updatePalletExchangeConfiguration = async (
  orderId: number,
  type: string,
  addressId?: number
) => {
  const URL = `${PALLET_EXCHANGE_COMMANDS_URL}/update_pallet_exchange_configuration/${orderId}`
  const data = transformKeysToSnakeCase({
    palletExchangeType: type,
    address: addressId
  })
  const response = (await runJsonPut(
    URL,
    data
  )) as AxiosResponse<PalletExchangeConfiguration>

  return response.data
}

import { ref } from '@vue/composition-api'
import { fetchPersonsByIds } from '@/services/user-service'
interface Person {
  firstName: string
  id: number
  initials: string
  lastName: string
  phone?: string
}

const personsData = ref<Person[]>([])
const alreadyFetchedIds = []
const LIMIT_PER_REQUEST = 30

export default () => {
  const getChunks = (idsToChunk: number[]) => {
    const chunks = []
    while (idsToChunk.length > 0) {
      chunks.push(idsToChunk.splice(0, LIMIT_PER_REQUEST))
    }
    return chunks
  }

  const handleRequest = async (ids: number[]) => {
    const newPersonsData = await fetchPersonsByIds(ids)
    personsData.value.push(...newPersonsData)
    alreadyFetchedIds.push(newPersonsData.map(person => person.id))
  }

  const fetchPersons = async (
    personsIds: Array<number>,
    forceUpdate = false
  ): Promise<void> => {
    const newPersonsIdsToBeFetched = forceUpdate
      ? personsIds
      : personsIds.filter(personId => personId && !alreadyFetchedIds.includes(personId))

    if (newPersonsIdsToBeFetched.length > 0) {
      if (newPersonsIdsToBeFetched.length > LIMIT_PER_REQUEST) {
        const chunks = getChunks(newPersonsIdsToBeFetched)
        await Promise.all(chunks.map(ids => handleRequest(ids)))
      } else {
        await handleRequest(newPersonsIdsToBeFetched)
      }
    }
  }

  const handleESData = esPersons => {
    personsData.value.push(...esPersons)
  }

  return {
    personsData,
    fetchPersons,
    handleESData
  }
}

import without from 'lodash/without'
import mapValues from 'lodash/mapValues'
import chunk from 'lodash/chunk'
import { computed, ComputedRef, ref } from '@vue/composition-api'
import { getTransferList } from '@/services/boards-service'
import { Order, Transfer } from '@/modules/boards/types'
import { transformKeysToCamelCase } from '@/services/utils/casing'

const transfersData = ref<Transfer[]>([])
const alreadyFetchedTransferIds = []
const LIMIT_PER_REQUEST = 25

export default (orders?: ComputedRef<Order[]>) => {
  const transfersByOrderId = computed<{ [key: number]: Transfer }>(() => {
    if (!orders) return {}
    const transfersObject = {}
    orders.value.forEach(order => {
      transfersObject[order.id] = transfersData.value.find(
        transfer => transfer.id === order.transfer_id
      )
    })
    return transfersObject
  })

  const stepsByOrderId = computed(() => {
    if (!orders) return {}
    return transformKeysToCamelCase(
      mapValues(transfersByOrderId.value, transferObject => transferObject?.steps)
    )
  })

  const trackerIdsByOrderId = computed<{ [key: number]: number[] }>(() => {
    if (!orders) return {}
    return mapValues(transfersByOrderId.value, transferObject => transferObject?.trackers)
  })

  const handleRequest = async (ids: number[]): Promise<void> => {
    const newTransfersData = await getTransferList(ids)
    transfersData.value = [...transfersData.value, ...newTransfersData]
    alreadyFetchedTransferIds.push(...ids)
  }

  const handleESTransfers = esTransfers => {
    transfersData.value = [...esTransfers]
  }

  const fetchTransfers = async (
    transferIds: Array<number>,
    forceUpdate = false
  ): Promise<void> => {
    const newTransferIds = forceUpdate
      ? transferIds
      : without(transferIds, ...alreadyFetchedTransferIds)

    if (!newTransferIds.length) return

    if (newTransferIds.length > LIMIT_PER_REQUEST) {
      const chunkedTransferIds = chunk(newTransferIds, LIMIT_PER_REQUEST)
      await Promise.all(chunkedTransferIds.map(ids => handleRequest(ids)))
    } else await handleRequest(newTransferIds)
  }

  return {
    transfersData,
    transfersByOrderId,
    stepsByOrderId,
    trackerIdsByOrderId,
    fetchTransfers,
    handleESTransfers
  }
}

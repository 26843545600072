import { runJsonGet, runJsonPost } from '@/models/backend-client'
import {
  transformKeysToSnakeCase,
  transformKeysToCamelCase
} from '@/services/utils/casing'
import { OPERATIONS_BACKEND_URL, CARRIER_CONFIRMATION_EMAIL_API_URL } from '@/config'
import { AxiosResponse } from 'axios'
import { AddOrderNotePayload, OrderNote, OrderLog } from '../types'

export const fetchOrderNotes = async (orderId: number): Promise<OrderNote[]> => {
  const response = (await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/get-order-notes`,
    transformKeysToSnakeCase({ orderId })
  )) as AxiosResponse<unknown>

  return transformKeysToCamelCase(response.data) as OrderNote[]
}

export const addOrderNote = async (
  orderId: number,
  noteData: AddOrderNotePayload
): Promise<void> => {
  await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/ordering/commands/create-order-note`,
    transformKeysToSnakeCase({
      orderId,
      ...noteData
    })
  )
}

export const deleteOrderNote = async (noteId: number): Promise<void> => {
  await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/ordering/commands/delete-order-note`,
    transformKeysToSnakeCase({
      noteId
    })
  )
}

export const fetchOrderLogs = async (orderId: number): Promise<OrderLog[]> => {
  const response = (await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/get-order-logs`,
    transformKeysToSnakeCase({ orderId })
  )) as AxiosResponse<unknown>

  return transformKeysToCamelCase(response.data) as OrderLog[]
}

export const getPresignedPdfDocumentUrl = async (
  documentType: string,
  fileName: string
): Promise<string> => {
  // Based on the documentType we have different sources of the download URL
  if (documentType === 'Dispatching Confirmation PDF' && !fileName.includes('.pdf')) {
    const url = `${CARRIER_CONFIRMATION_EMAIL_API_URL}/v1/confirmation-email/${fileName}`
    const audience = 'https://api.cloud.sennder.com/ca-confirmation-email'
    const scope = 'confirmation-email:read'
    const response = (await runJsonGet(url, {}, {}, audience, scope)) as AxiosResponse
    return response.data.url
  } else {
    const document_url = `${OPERATIONS_BACKEND_URL}/documents/get_presigned_url`
    const response = (await runJsonPost(
      document_url,
      transformKeysToSnakeCase({ fileName })
    )) as AxiosResponse
    return (transformKeysToCamelCase(response.data) as { documentUrl: string })
      .documentUrl
  }
}

import { OPERATIONS_BACKEND_URL } from '@/config'
import { runJsonPost, runJsonPut } from '@/models/backend-client'

const BASE_URL = process.env.VUE_APP_FLEET_API_URL
const TP_BASE_URL = process.env.VUE_APP_TRANSPORT_PLANNING_API_URL
const AUDIENCE = 'https://api.cloud.sennder.com/fleet'
const TP_AUDIENCE = 'https://api.cloud.sennder.com/transport-planning'
type ASSET_TYPE = 'TRUCK' | 'TRAILER' | 'DRIVER'

type DeleteAllocation = {
  command: 'delete'
  payload: {
    orderId: number
    allocationId: string
  }
}

type CreateAllocation = {
  command: 'create'
  payload: {
    orderId: number
    assetType: ASSET_TYPE
    assetSid: string
    effectiveAt: string
    expiresAt: string
  }
}

type ReplaceAllocation = {
  command: 'replace'
  payload: {
    allocationId: string
  } & CreateAllocation['payload']
}

export type Allocation = DeleteAllocation | CreateAllocation | ReplaceAllocation

type RawAllocationPayload = {
  order_id?: number
  asset_type?: ASSET_TYPE
  asset_sid?: string
  effective_at?: string
  expires_at?: string
  allocation_id?: string
}

const serializeCreate = (payload: CreateAllocation['payload']): RawAllocationPayload => ({
  order_id: payload.orderId,
  asset_type: payload.assetType,
  asset_sid: payload.assetSid,
  effective_at: payload.effectiveAt,
  expires_at: payload.expiresAt
})

const serializeReplace = (
  payload: ReplaceAllocation['payload']
): RawAllocationPayload => {
  return {
    ...serializeCreate(payload),
    allocation_id: payload.allocationId
  }
}

const serializeDelete = (payload: DeleteAllocation['payload']): RawAllocationPayload => ({
  order_id: payload.orderId,
  allocation_id: payload.allocationId
})

const serialize = ({ command, payload }: Allocation) => {
  let serialized: RawAllocationPayload = {}
  if (command === 'create') {
    serialized = serializeCreate(payload)
  }

  if (command === 'replace') {
    serialized = serializeReplace(payload)
  }

  if (command === 'delete') {
    serialized = serializeDelete(payload)
  }

  return {
    command,
    payload: serialized
  }
}

export const saveAllocation = async (
  carrierId: number,
  allocations: Allocation[] = []
) => {
  const scope = 'asset-allocations:write-all'
  const url = `${BASE_URL}/internal/ams/allocations/commands?carrier_id=${carrierId}`

  return runJsonPost(url, { commands: allocations.map(serialize) }, {}, AUDIENCE, scope)
}

export const dispatchOrderThroughTransportPlanning = async (
  orderId: number,
  shipmentId: string,
  workflowManaged: boolean
) => {
  const scope = 'dispatch:write'
  let url = `${TP_BASE_URL}/api/octopus/v1/manual-dispatch?order_id=${orderId}&workflow_managed=${workflowManaged}`
  if (shipmentId) {
    url = url + `&shipment_id=${shipmentId}`
  }

  return runJsonPut(url, null, { apiGatewayAuthorization: true }, TP_AUDIENCE, scope)
}

export const saveNotes = async (transferId: number, notes: string) => {
  const scope = 'transfers:update-any'
  const url = `${OPERATIONS_BACKEND_URL}/infrastructure/commands/update-transfer-notes/${transferId}`

  return runJsonPut(
    url,
    {
      transfer_notes: notes
    },
    {},
    null,
    scope
  )
}

export const savePartnerManager = async (
  transferId: number,
  partnerManagerId?: number
) => {
  const scope = 'transfers:update-any'
  const url = `${OPERATIONS_BACKEND_URL}/infrastructure/commands/update-transfer-operator/${transferId}`

  return runJsonPut(
    url,
    {
      operator_id: partnerManagerId
    },
    {},
    null,
    scope
  )
}

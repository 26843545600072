import { computed, ComputedRef, ref } from '@vue/composition-api'
import mapValues from 'lodash/mapValues'
import chunk from 'lodash/chunk'
import without from 'lodash/without'
import { TrackerData } from '@/modules/boards/types'
import { getTrackerList } from '@/services/boards-service'

const trackersData = ref<TrackerData[]>([])
const alreadyFetchedTrackerIds = []
const LIMIT_PER_REQUEST = 25

export default (trackerIdsByOrder: ComputedRef<{ [key: number]: number[] }>) => {
  const trackerIdsArray = computed<number[]>(() =>
    trackerIdsByOrder.value ? Object.values(trackerIdsByOrder.value).flat() : []
  )

  const enrichedTrackersByOrderId = computed<{ [key: number]: TrackerData[] }>(() => {
    return mapValues(trackerIdsByOrder.value, ids => mapTrackerData(ids))
  })

  const handleRequest = async (ids: number[]): Promise<void> => {
    const onlyNewTrackers = await getTrackerList(ids)
    trackersData.value = [...trackersData.value, ...onlyNewTrackers]
    alreadyFetchedTrackerIds.push(...ids)
  }

  const fetchTrackers = async (forceUpdate = false): Promise<void> => {
    const newTrackerIds = forceUpdate
      ? trackerIdsArray.value
      : without(trackerIdsArray.value, ...alreadyFetchedTrackerIds)

    if (!newTrackerIds.length) return

    if (newTrackerIds.length > LIMIT_PER_REQUEST) {
      const chunkedTrackerIds = chunk(newTrackerIds, LIMIT_PER_REQUEST)
      await Promise.all(chunkedTrackerIds.map(ids => handleRequest(ids)))
    } else await handleRequest(newTrackerIds)
  }

  const mapTrackerData = (idsArray: number[]): TrackerData[] => {
    return idsArray?.map(id => trackersData.value.find(data => data.id === id)) || []
  }

  return {
    enrichedTrackersByOrderId,
    trackerIdsArray, //for testing
    fetchTrackers //for testing
  }
}

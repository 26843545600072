


























































































































































import get from 'lodash/get'
import OrderStageFooter from '@/modules/ordering/components/shared/OrderStageFooter.vue'
import AsyncActionButton from '@/modules/ordering/components/shared/async-action-button/AsyncActionButton.vue'
import AsyncActionContext from '@/modules/ordering/components/shared/async-action-button/AsyncActionContext.vue'
import ConfirmationDialog from '@/modules/ordering/components/shared/ConfirmationDialog.vue'
import SendDispatchConfirmationModal from '@/components/SendDispatchConfirmationModal.vue'
import RouteForm from './dispatching-form/RouteForm.vue'
import CostForm from './dispatching-form/CostForm.vue'
import {
  TrackingConfig,
  DispatchingTransferDetail,
  DispatchingOrderDetail
} from '@/modules/ordering/components/dispatching/types'
import { hasHadState } from '@/modules/common/order-states'
import {
  defineComponent,
  defineAsyncComponent,
  computed,
  ref,
  watch,
  PropType,
  onMounted,
  onBeforeUnmount,
  toRef
} from '@vue/composition-api'
import { VehicleType } from '@/services'
import useCurrentUser from '@/compositions/useCurrentUser'
import useStore from '@/compositions/useStore'
import useDispatchingFormValidation from '../../compositions/useDispatchingFormValidation'
import useDispatchingFormValues from '../../compositions/useDispatchingFormValues'
import { ExtendedPerson } from '@/compositions/useOrder'
import * as AllocationService from '@/microfrontends/widgets/fleet-asset-management-widget/AllocationService'
import { updateTrackingConfig } from '@/services/order-tracking-service'
import useFeatureFlag from '@/compositions/useFeatureFlag'

export default defineComponent({
  components: {
    FleetAssetManagementWidget: defineAsyncComponent(
      () =>
        import(
          /* webpackPreload: true */
          '@/microfrontends/widgets/fleet-asset-management-widget/FleetAssetManagementWidget.vue'
        )
    ),
    OrderStageFooter,
    AsyncActionButton,
    AsyncActionContext,
    ConfirmationDialog,
    RouteForm,
    CostForm,
    SendDispatchConfirmationModal
  },
  props: {
    stops: { type: Array, default: () => [] },
    order: { type: Object as PropType<DispatchingOrderDetail>, required: true },
    accountManager: { type: Object as PropType<ExtendedPerson>, default: null },
    juniorAccountManager: { type: Object as PropType<ExtendedPerson>, default: null },
    partnerManager: { type: Object as PropType<ExtendedPerson>, default: null },
    spotBidder: { type: Object as PropType<ExtendedPerson>, default: null }
  },
  setup(props) {
    const wrapper = ref<HTMLDivElement | null>(null)
    const dispatchConfirmationModalShown = ref<boolean>(false)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const confirmationDialog = ref<any>(null)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const undispatchDialog = ref<any>(null)

    const { isActive } = useFeatureFlag()

    const { canDispatchAndUndispatchOrder } = useCurrentUser()
    const store = useStore()
    const lastAllocationSave = ref<Date>(new Date())
    const updateLastAllocationSave = () => {
      lastAllocationSave.value = new Date()
    }
    const trackingConfig = computed<TrackingConfig>(
      () => store.state.ordering.trackingConfig
    )

    const transfer = computed<DispatchingTransferDetail>(
      () => store.state.ordering.transfer
    )

    const allocationInformation = ref<{
      carrierId: number
      allocations: AllocationService.Allocation[]
    } | null>(null)

    const {
      formValues,
      usesDriverApp,
      debouncedVehicleLicensePlateWatcher,
      debouncedTrailerLicensePlateWatcher
    } = useDispatchingFormValues(transfer, toRef(props.order, 'carrierCompanyId'))

    const { routeForm, costForm, validateForm } = useDispatchingFormValidation(wrapper)

    const autoSaveNeeded = computed<boolean>(() => props.order.state === 'CARRIER_LOCKED')
    const disabled = computed<boolean>(
      () => props.order.state !== 'CARRIER_LOCKED' || !canDispatchAndUndispatchOrder.value
    )
    const undispatchButtonVisible = computed<boolean>(
      () =>
        hasHadState(props.order.state, 'DISPATCHED') &&
        canDispatchAndUndispatchOrder.value
    )

    const initializeVehicleType = (val: VehicleType): void => {
      if (!formValues.dispatchedVehicle) formValues.vehicleType = val
    }

    const closeDispatchConfirmationModal = (): void => {
      dispatchConfirmationModalShown.value = false
    }

    const enableCarrierInformation = isActive('ENABLE_DISPATCHING-ROUTE-FORM')

    const enableCarrierNotes = isActive('ENABLE_CARRIER-NOTES')

    const formUpdates = computed(() => {
      return {
        order: {
          preDispatchCarrierExtraChargeId: props.order.preDispatchCarrierExtraChargeId,
          preDispatchCarrierExtraChargeAmount: formValues.costSurcharge,
          preDispatchCarrierExtraChargeDescription:
            formValues.costSurchargeDescription || null,
          transferNotes: formValues.notes,
          dispatchedVehicle: formValues.vehicleType,
          licensePlate: formValues.licensePlate,
          trailerLicensePlate: formValues.trailerLicensePlate,
          transferCode: formValues.transferCode,
          driverId: get(formValues, 'driver.id', null),
          driverName: formValues.driverName,
          driverPhoneNumber: formValues.driverPhoneNumber,
          partnerManagerId: get(formValues, 'partnerManager.id', null)
        },
        tracking: {
          gatehousePushOn: formValues.gatehousePushOn,
          amazonPushOn: formValues.amazonPushOn
        }
      }
    })

    const updateTracking = (value: { gatehouse: boolean; amazon: boolean }) => {
      formValues.gatehousePushOn = value.gatehouse
      formValues.amazonPushOn = value.amazon
    }

    const updateOrder = async (payload): Promise<void> =>
      await store.dispatch('ordering/updateOrder', payload)

    const undispatchOrder = async () => await store.dispatch('ordering/undispatchOrder')

    const savePartnerManager = async () => {
      const { transferId, workflowManaged } = props.order
      // ADO: 81265: we only need to call mothership directly for legacy orders that are not workflow managed
      // otherwise this call is not needed & the operator will be automically saved and backfilled by the Operator Role Assignment Service
      if (transferId && !workflowManaged) {
        await AllocationService.savePartnerManager(
          transferId,
          formValues.partnerManager?.id
        )
      }
    }

    const saveNotes = async () => {
      const { transferNotes } = formUpdates.value.order
      const { transferId } = props.order

      if (transferId && transferNotes) {
        await AllocationService.saveNotes(transferId, transferNotes)
      }
    }

    const saveTracking = async () => {
      const transferId = props.order.transferId
      const tracking = formUpdates.value.tracking
      if (transferId && tracking) {
        await updateTrackingConfig(transferId, tracking)
      }
    }

    const shouldRenderFleetWidget = computed<boolean>(() =>
      Boolean(props.order.carrierCompany && props.order.carrierCompany.id)
    )

    const dispatchFleetSave = async () => {
      await saveNotes()
      await saveTracking()
      await savePartnerManager()
      await store.dispatch('ordering/refreshOrder')
    }

    const dispatchFleetOrder = async () => {
      await saveNotes()
      await saveTracking()
      await savePartnerManager()
      await store.dispatch('ordering/dispatchFleetOrder')
    }

    const save = async (): Promise<ReturnType<typeof updateOrder>> => {
      await dispatchFleetSave()
    }

    const dispatch = async (): Promise<void | null> => {
      if (!validateForm()) return
      const response = await confirmationDialog.value?.askConfirmation()

      if (response) {
        await dispatchFleetOrder()
      }
      return null
    }

    const undispatch = async (): Promise<void> => {
      const response = await undispatchDialog.value?.askConfirmation()
      if (response) {
        await undispatchOrder()
        updateLastAllocationSave()
      }
    }

    const sendDispatchConfirmation = async (): Promise<null> => {
      if (autoSaveNeeded.value) await save()
      dispatchConfirmationModalShown.value = true
      return null
    }

    watch(
      transfer,
      () => {
        if (!transfer.value) return
        formValues.notes = transfer.value.notes
        formValues.vehicleType =
          transfer.value.dispatchedVehicle || props.order.vehicleType
        formValues.transferCode = transfer.value.code
        formValues.driverName = transfer.value.driverName || ''
        formValues.driverPhoneNumber = transfer.value.driverPhoneNumber || ''
        formValues.dispatchedVehicle = transfer.value.dispatchedVehicle
      },
      { immediate: true }
    )

    watch(
      trackingConfig,
      () => {
        if (!trackingConfig.value) return
        formValues.gatehousePullOn = trackingConfig.value.gatehousePullOn
        formValues.gatehousePushOn = trackingConfig.value.gatehousePushOn
        formValues.amazonPushOn = trackingConfig.value.amazonPushOn
      },
      { immediate: true }
    )

    watch(
      () => props.order,
      async () => {
        formValues.allowedVehicleTypes = props.order.allowedVehicleTypes
        formValues.carrierCompany = props.order.carrierCompany
        formValues.carrierContact = props.order.carrierContact || {}
        formValues.baseCost = props.order.baseCost
        formValues.costSurcharge = props.order.preDispatchCarrierExtraChargeAmount
        formValues.costSurchargeDescription =
          props.order.preDispatchCarrierExtraChargeDescription
      },
      { immediate: true }
    )

    watch(
      () => props.partnerManager,
      () => {
        formValues.partnerManager = props.partnerManager
      },
      { immediate: true }
    )

    watch(
      () => formValues.gatehousePushOn,
      currentValue => {
        if (!currentValue) formValues.amazonPushOn = false
      }
    )

    onMounted(() => {
      if (wrapper.value?.scrollIntoView) {
        wrapper.value.scrollIntoView()
      }
    })

    onBeforeUnmount(() => {
      debouncedVehicleLicensePlateWatcher.cancel()
      debouncedTrailerLicensePlateWatcher.cancel()
    })

    const onAllocationUpdate = (update: {
      carrierId: number
      allocations: AllocationService.Allocation[]
    }) => {
      allocationInformation.value = update
    }

    return {
      wrapper,
      confirmationDialog,
      undispatchDialog,
      canDispatchAndUndispatchOrder,
      dispatchConfirmationModalShown,
      disabled,
      undispatchButtonVisible,
      initializeVehicleType,
      closeDispatchConfirmationModal,
      //form validation
      routeForm,
      costForm,
      validateForm,
      //actions
      save,
      dispatch,
      undispatch,
      sendDispatchConfirmation,
      //form values
      formValues,
      usesDriverApp,
      //widget
      onAllocationUpdate,
      updateTracking,
      lastAllocationSave,
      shouldRenderFleetWidget,
      enableCarrierInformation,
      enableCarrierNotes
    }
  }
})

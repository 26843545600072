







import Vue from 'vue'
import {
  createRandomString,
  getEnvironmentAliasForFederatedLoader
} from '@/microfrontends/utils'
import { Location, RawLocation, Route } from 'vue-router'
import {
  IUser,
  Tenant,
  SharedDataType
} from '@sennder/senn-node-microfrontend-interfaces'
import { getAuthHeader, getAuthToken } from '@/services/auth-service'
import { trackEvent } from '@/analytics/amplitude'
import { getAppTheme } from '@/controllers/environment-detection'
import useCurrentUser from '@/compositions/useCurrentUser'
import useI18n from '@/compositions/useI18n'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import logger from '@/shell/console-logger'
import { monitorError } from '@/analytics/monitoring'
import { getLogger } from '@/shell/datadog-logger'
import { fetchMicrofrontend } from '@/microfrontends/fetchMicrofrontend'

const DEV_PORT = 9109
const NAMESPACE = 'facilityplanning'
const NPM_PATH = 'facility-planning-mf-component'
const REMOTE = './facilityplanningApp'

export default Vue.extend({
  name: 'FacilityPlanningPage',
  props: {
    shipmentId: { type: String, default: '' }
  },
  data() {
    return {
      id: `${createRandomString()}-FacilityPlanningPage`,
      isLoaded: null as boolean | null,
      syncChildRouter: null as ((route: RawLocation) => void) | null,
      unmount: null as (() => void) | null
    }
  },
  async mounted() {
    const syncParentRouter = (route: RawLocation) => {
      const currentPath = this.$route.fullPath
      if (
        (typeof route === 'string' && currentPath === route) ||
        (typeof route === 'object' && currentPath === (route as Location).path)
      ) {
        return
      }
      this.$router.push(route)
    }
    try {
      const { user } = useCurrentUser()
      const { locale } = useI18n()
      const { isActive } = useFeatureFlag()

      const mount = await fetchMicrofrontend({
        devPort: DEV_PORT,
        environment: getEnvironmentAliasForFederatedLoader(NAMESPACE),
        moduleFederationPluginName: NAMESPACE,
        npmName: NPM_PATH,
        mountExposedName: REMOTE,
        logger: getLogger()
      })

      const mfData = mount(`#${this.id}`, {
        data: {
          type: SharedDataType.OCTOPUS,
          user: user.value as IUser,
          language: locale.value,
          env: {},
          tenant: getAppTheme() as Tenant,
          featureFlags: {
            ENABLE_FACILITY_MANUAL_SCHEDULING_NW: isActive(
              'ENABLE_FACILITY_MANUAL_SCHEDULING_NW'
            ).value
          },
          shipmentId: this.shipmentId
        },
        callbacks: {
          getToken: getAuthHeader,
          getAuthHeader,
          getAuthToken,
          syncParentRouter,
          // segment is deprecated, use Amplitude provider instead
          segmentTrackEvent: () => {
            logger.error('segmentTrackEvent is deprecated, use segment provider instead')
          },
          onUnauthorized: () => {},
          onUnhandledError: error => {
            Vue.config.errorHandler(error, null, 'Facility Planning Page')
          }
        },
        providers: {
          logger: getLogger(),
          notifications: null,
          translations: null,
          analytics: null,
          monitoring: null,
          // segment is deprecated, use Amplitude provider instead
          segment: {
            trackEvent,
            setContext: () => {
              logger.error('setContext not implemented')
            },
            trackPage: () => {
              logger.error('trackPage not implemented')
            }
          }
        }
      })
      if (mfData.syncChildRouter) {
        this.syncChildRouter = mfData.syncChildRouter
        this.$watch(
          '$route',
          (to: Route) => {
            mfData.syncChildRouter(to.fullPath)
          },
          {
            immediate: true
          }
        )
      }
      if (mfData.unmount) {
        this.unmount = mfData.unmount
      }
      this.isLoaded = true
    } catch (e) {
      monitorError(e, 'FacilityPlanningPage', {
        type: 'bootstrap'
      })
      this.isLoaded = false
    }
  },
  destroyed() {
    if (this.unmount) {
      this.unmount()
    }
  }
})

import { GetterTree } from 'vuex'
import { ICommonState } from './state'
import { Currency, ICurrencyExchangeRate } from '../models'

export type CurrencyExchangeRateGetter = (
  state: ICommonState
) => (currency: Currency) => ICurrencyExchangeRate | null
export const currencyExchangeRate: CurrencyExchangeRateGetter = state => currency => {
  return state.exchangeRates[currency] || null
}

export const Getters = Object.freeze({
  currencyExchangeRate: 'currencyExchangeRate'
})

export type GettersType = {
  currencyExchangeRate: CurrencyExchangeRateGetter
}

// TODO: type root store when it'll be typed
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getters: GetterTree<ICommonState, any> & GettersType = {
  currencyExchangeRate
}

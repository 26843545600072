



import { OrderState } from '@/modules/common/order-states'
import { computed, defineComponent, PropType } from '@vue/composition-api'

const statusIndicators = {
  NEW: { label: 'New', category: 'secondary' },
  REGISTERED: { label: 'Registered', category: 'primary' },
  CARRIER_LOCKED: { label: 'Carrier Locked', category: 'red' },
  DISPATCHED: { label: 'Dispatched', category: 'primary' },
  EXECUTED: { label: 'Executed', category: 'green' },
  OPERATIONS_COMPLETED: { label: 'Ops Completed', category: 'green' },
  CANCELLED_OPERATIONS_COMPLETED: { label: 'Cancelled (closed)', category: 'red' },
  CANCELLED_NOT_OPERATIONS_COMPLETED: { label: 'Cancelled (open)', category: 'red' }
}

export default defineComponent({
  props: {
    isCancelled: { type: Boolean, required: true },
    state: { type: String as PropType<OrderState>, required: true }
  },
  setup(props) {
    const statusIndicator = computed(() => {
      if (props.isCancelled) {
        if (props.state === 'OPERATIONS_COMPLETED')
          return statusIndicators.CANCELLED_OPERATIONS_COMPLETED
        else return statusIndicators.CANCELLED_NOT_OPERATIONS_COMPLETED
      }
      return statusIndicators[props.state]
    })

    return { statusIndicator }
  }
})

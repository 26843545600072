






import { computed } from '@vue/composition-api'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import { routeComponents } from '@sennder/contract-management'
import CarrierContract from '@/microfrontends/widgets/carrier-contracts/CarrierContract.vue'
import { CARRIER_CONTRACTS_FEATURE_FLAGS } from '@/microfrontends/widgets/carrier-contracts/constants'

export default {
  components: {
    ContractDetails: routeComponents.ContractDetails,
    CarrierContract
  },
  data() {
    const { getJSONValue } = useFeatureFlag()
    const whitelistedShipperContractNames = computed(
      () => getJSONValue(CARRIER_CONTRACTS_FEATURE_FLAGS.ENABLE_NEW_CC).value
    ).value as { data: string[] }
    return {
      contractId: this.$route.params.id,
      whitelistedShipperContractNames
    }
  }
}

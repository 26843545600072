import _ from 'lodash'

import { TRANSIT_API_URL } from '@/config'
import { runJsonGet, runJsonPost } from '@/models/backend-client'
import { transformKeysToCamelCase, transformKeysToSnakeCase } from './utils/casing'
import { AxiosResponse } from 'axios'
import {
  IExecutionPlan,
  IStopExecution,
  ITourExecution,
  TransitRampUpStatus,
  UUID
} from '.'
import logger from '@/shell/console-logger'
import { SINGLE_AUDIENCE } from '@/services/auth-service'

export const getExecutionPlan = async (shipmentId: UUID): Promise<IExecutionPlan> => {
  try {
    const response = (await runJsonGet(
      `${TRANSIT_API_URL}/api/v1/execution-plans`,
      {
        shipment_id: shipmentId
      },
      {},
      SINGLE_AUDIENCE
    )) as AxiosResponse<IExecutionPlan[]>

    const executionPlan = _.first(response.data)
    if (!executionPlan) throw new Error('Empty result')

    return transformKeysToCamelCase(executionPlan)
  } catch (error) {
    throw new Error(
      `Transit error: Unable to fetch Execution Plan for shipment ID: ${shipmentId} - ${error}`
    )
  }
}

export const upsertStopExecutionsAfterExecutedState = async (
  stopsExecutions: IStopExecution[],
  shipmentId: UUID
) => {
  try {
    return runJsonPost(
      `${TRANSIT_API_URL}/api/v1/post-execution/commands/upsert-stop-executions-by-shipment-id/${shipmentId}`,
      transformKeysToSnakeCase(stopsExecutions),
      {},
      SINGLE_AUDIENCE
    )
  } catch (e) {
    throw new Error(
      `Transit error: Unable to call Transit service and update Stop Executions for shipment ID: ${shipmentId} - ${e}`
    )
  }
}

export const upsertStopExecutionsByShipmentId = async (
  stopsExecutions: IStopExecution[],
  shipmentId: UUID
) => {
  if (!(stopsExecutions.length > 0)) return
  return await runJsonPost(
    `${TRANSIT_API_URL}/api/v1/commands/upsert-stop-executions-by-shipment-id/${shipmentId}`,
    transformKeysToSnakeCase(stopsExecutions),
    {},
    SINGLE_AUDIENCE
  )
}

export const getTourExecution = async (shipmentId: UUID): Promise<ITourExecution> => {
  try {
    const response = (await runJsonGet(
      `${TRANSIT_API_URL}/api/v1/tour-executions`,
      {
        shipment_id: shipmentId
      },
      {},
      SINGLE_AUDIENCE
    )) as AxiosResponse<unknown>

    return transformKeysToCamelCase(response.data)
  } catch (error) {
    if (error.response.status !== 404) {
      logger.warn(
        `Transit warning: Unable to get TourExecution for shipment ID: ${shipmentId} - ${error}`
      )
    }
  }
}

export const markAsExecuted = async (tourExecutionId: UUID) => {
  await runJsonPost(
    `${TRANSIT_API_URL}/api/v1/tour-executions/${tourExecutionId}/mark-as-executed`,
    {},
    {
      apiGatewayAuthorization: true
    },
    SINGLE_AUDIENCE
  )
}

export const getTransitRampUpStatusByShipmentId = async (
  shipmentId: UUID
): Promise<TransitRampUpStatus> => {
  const response = (await runJsonGet(
    `${TRANSIT_API_URL}/api/v1/transit-rampup-status/shipments/${shipmentId}`,
    null,
    {},
    SINGLE_AUDIENCE
  )) as AxiosResponse<unknown>

  return transformKeysToCamelCase(response.data)
}

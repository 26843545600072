import { DESIGNATED_OPERATORS_API_URL } from '@/config'
import { runJsonGet, runJsonPut } from '@/models/backend-client'
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase
} from '@/services/utils/casing'

import { UUID } from '@/services'

export enum OperatorRoleId {
  SeniorAccountManager = 1,
  AccountManager = 2,
  SpotBidder = 3
}

interface DesignatedOperatorResponse {
  order_id: number
  operator_id: UUID
  operator_id_old: number
  role: OperatorRoleId
}

export const getDesignatedOperators = async function (
  orderId: number,
  roles: OperatorRoleId[]
): Promise<DesignatedOperatorResponse | null> {
  const result = await runJsonGet(
    `${DESIGNATED_OPERATORS_API_URL}/api/designated-operators/${orderId}`,
    transformKeysToSnakeCase({
      roles
    }),
    { apiGatewayAuthorization: true, arrayFormat: 'repeat' },
    'https://api.cloud.sennder.com/do-api',
    'operators:get'
  )
  if (result && result.data) {
    return transformKeysToCamelCase(result.data)
  }
  return null
}

export const assignOperator = async function (
  orderIds: number[],
  role: OperatorRoleId,
  operatorId: UUID
): Promise<void> {
  await runJsonPut(
    `${DESIGNATED_OPERATORS_API_URL}/api/designated-operators/assign`,
    transformKeysToSnakeCase({
      orderIds,
      role,
      operatorId
    }),
    { apiGatewayAuthorization: true, arrayFormat: 'repeat' },
    'https://api.cloud.sennder.com/do-api',
    'operators:assign'
  )
}

<template>
  <AlertMessage v-if="shouldDisplayAlert" :type="alert.type">
    {{ alert.message }}
  </AlertMessage>
</template>

<script>
export default {
  props: {
    alert: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    shouldDisplayAlert() {
      return this.alert.condition
    }
  }
}
</script>


















import { defineComponent, inject, computed } from '@vue/composition-api'
import { CLAIM_STATUSES_LABELS } from '@sennder/finance-frontend'

const FILTER_KEY = 'claimStatuses'

export default defineComponent({
  setup(props, context) {
    const getFilterValue = inject<(string) => string>('getFilterValue')
    const setFilterValue =
      inject<(filterName: string, value, isFilterApplied?: boolean) => void>(
        'setFilterValue'
      )

    const claimStatusOptions = computed(() => {
      return Object.entries(CLAIM_STATUSES_LABELS).map(([id, label]) => ({
        label,
        id
      }))
    })
    const claimStatusHandler = computed({
      get() {
        return getFilterValue(FILTER_KEY)
      },
      set(value) {
        setFilterValue(FILTER_KEY, value)
        context.emit('applyFilters')
      }
    })

    return {
      claimStatusOptions,
      claimStatusHandler
    }
  }
})

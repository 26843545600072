import { createBasicListFilter } from './basic-list'

/**
 * @typedef {import('./basic-list').FilterValue} BasicListFilterValue
 * @typedef {import('./filter').UrlQueryFragment} UrlQueryFragment
 */

/**
 * @param {BasicListFilterValue} initialValue
 * @returns {any} - vuex module instance
 */
export const createOrderStateFilter = initialValue => {
  const name = 'states'
  let filter = createBasicListFilter({
    name,
    initialValue,
    updateName: 'orderStates',
    filterLabel: 'filters/order-state-filter-field-title',
    esName: 'workflowState.state'
  })

  filter.getters = {
    ...filter.getters,
    /**
     * @param {*} state
     * @param {*} getters
     * @returns {?object<string, FilterValue>} - a fragment of API payload object
     */
    getApiFragment: (state, getters) =>
      !getters.isFilterEmpty ? { [name]: getters.getApiValue } : null,
    /**
     * @param {*} state
     * @param {*} getters
     * @returns {?string} - a fragment of GraphQL payload object
     */
    getGraphqlValue: (state, getters) =>
      !getters.isFilterEmpty ? `${name}: ${JSON.stringify(getters.getApiValue)}` : null,
    /**
     * @private
     * @param {*} state
     * @returns {boolean}
     */
    getApiValue: state => (state.value.includes('ALL_STATES') ? null : state.value)
  }

  return filter
}

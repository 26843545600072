<template>
  <tr :class="tableRowClasses">
    <slot></slot>
  </tr>
</template>

<script>
const ROW_TYPES = ['header', 'nohover', 'empty', 'danger', 'warning', 'open', 'disabled']

export default {
  props: {
    types: {
      type: [String, Array],
      default: () => [],
      validator: types =>
        Array.isArray(types)
          ? types.every(type => ROW_TYPES.includes(type))
          : ROW_TYPES.includes(types)
    },
    active: { type: Boolean, default: false }
  },
  computed: {
    tableRowClasses() {
      let typesArr = this.types
      if (typeof this.types === 'string') typesArr = [this.types]
      else if (!Array.isArray(this.types)) typesArr = [] // handle null value

      if (this.types === 'header') return 'header-row'
      else
        return [
          'table-row',
          this.active ? 'table-row--active' : '',
          ...typesArr.map(type => `table-row--${type}`)
        ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/partials/header';

.header-row {
  &::v-deep th {
    position: sticky;
    top: $header-height;
    background: $color-layout-white;
    color: $color-neutral-main;
    z-index: 4;
    padding: 8px 10px;
    height: 33px;
    font-size: 11px;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 2px solid $color-neutral-lightest;
    line-height: 17px;

    &:first-child {
      padding-left: 20px;
      border-top-left-radius: 5px;
      padding-right: 0;
    }

    &:last-child {
      border-top-right-radius: 5px;
    }

    &.cell-border-right {
      border-right: 2px solid $color-neutral-lightest;

      &--search-row {
        font-size: 12px;
        line-height: 16px;
        color: #4b4b4b;
      }
    }
  }
}

.table-row {
  &::v-deep {
    background: $color-layout-white;

    td {
      position: relative;
      height: 50px;
      border-bottom: 1px solid $color-neutral-lightest;
      padding: 16px 10px;
      vertical-align: middle;

      &.padding-none {
        padding: 0;
      }

      &:first-child {
        padding-left: 20px;
      }

      &.cell-border-right {
        border-right: 2px solid $color-neutral-lightest;
      }
    }

    &:hover {
      background: $color-layout-background;
    }
  }

  &--active {
    background: $color-layout-background;
  }

  &--nohover {
    &::v-deep &:hover td {
      background: $color-layout-white;
    }
  }

  &--empty {
    &::v-deep td {
      text-align: center;
      background: $color-neutral-lightest;

      &:hover {
        background: $color-neutral-lightest;
      }
    }
  }

  &--warning {
    &::v-deep {
      background: lighten($color-primary-main, 25);

      td {
        border-bottom-color: lighten($color-primary-main, 20);

        &.cell-border-right {
          border-right-color: lighten($color-primary-main, 20);
        }
      }

      &:hover {
        background: lighten($color-primary-main, 15);
      }
    }
  }

  &--danger {
    &::v-deep td {
      color: $color-error-main;
    }
  }

  &--disabled {
    &::v-deep td {
      color: $color-neutral-main;
    }
  }

  &--open {
    &::v-deep {
      background-color: $color-neutral-lightest;

      td {
        border-bottom: none;
      }

      &.table-row--warning {
        background-color: lighten($color-primary-main, 15);
      }
    }
  }
}
</style>

import Vue from 'vue'

export const setTransportRequests = (state, orders) => {
  state.transportRequests = orders
}

export const removeTransportRequest = (state, requestId) => {
  const index = state.transportRequests.findIndex(
    transportRequest => transportRequest.id === requestId
  )
  Vue.delete(state.transportRequests, index)
}

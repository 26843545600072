























































import { PropType, defineComponent, toRefs } from '@vue/composition-api'
import { OrderDetail, OrderStop } from '@/services'
import useOrderSideBar from '@/modules/ordering/compositions/useOrderSideBar'
// sections
import LegacyPalletExchangeSection from './LegacyPalletExchangeSection.vue'
import CarrierSection from './CarrierSection.vue'
import VehicleDetails from './VehicleDetails.vue'
import TrackingInformation from './TrackingInformation.vue'
import LoadSection from './LoadSection.vue'
import StopsSection from './StopsSection.vue'
import RouteSection from './RouteSection.vue'

export default defineComponent({
  components: {
    LegacyPalletExchangeSection,
    CarrierSection,
    VehicleDetails,
    TrackingInformation,
    LoadSection,
    StopsSection,
    RouteSection
  },
  props: {
    order: { type: Object as PropType<OrderDetail>, required: true },
    stops: { type: Array as PropType<OrderStop[]>, required: true }
  },
  setup(props) {
    const orderSideBarData = useOrderSideBar(toRefs(props).order, toRefs(props).stops)
    return { ...orderSideBarData }
  }
})

import { i18n } from '@/plugins/i18n'

const defaultEmptyValue = null

/**
 * @typedef {number} FilterValue
 * @typedef {?object<string, string>} UrlQueryFragment
 */

/**
 * @param {object} data
 * @param {string} data.name - name for API/GraphQL key
 * @param {FilterValue} data.initialValue
 * @param {FilterValue} data.emptyValue
 * @param {string} data.urlParamName - name being used for URL and apply filter title
 * @param {string} data.updateName - name for getter/setter
 * @param {string} data.filterLabel - AppliedFilter's label
 * @returns {any} - vuex module instance
 */
export const createNumericFilter = ({
  name,
  initialValue = defaultEmptyValue,
  emptyValue = defaultEmptyValue,
  urlParamName = name,
  updateName,
  filterLabel
}) => {
  return {
    namespaced: true,
    state: {
      value: initialValue,
      urlParamName
    },
    getters: {
      /**
       * @param {*} state
       * @param {*} getters
       * @returns {?object<string, FilterValue>} - a fragment of API payload object
       */
      getApiFragment: (state, getters) =>
        !getters.isFilterEmpty ? { [name]: state.value } : 0,
      /**
       * @param {*} state
       * @param {*} getters
       * @returns {object} - data for AppliedFilters component
       */
      getAppliedValue: (state, getters) => {
        let appliedValue = null
        if (!getters.isFilterEmpty) {
          appliedValue = {
            label: `${i18n.t(filterLabel)}: ${state.value}`,
            updateName,
            emptyValue
          }
        }

        return appliedValue
      },
      /**
       * @param {*} state
       * @param {*} getters
       * @returns {?string} - a fragment of GraphQL payload object
       */
      getGraphqlValue: (state, getters) =>
        !getters.isFilterEmpty ? `${name}: ${state.value.toString()}` : 0,
      /**
       * @param {*} state
       * @param {*} getters
       * @returns {UrlQueryFragment} - a fragment of URL query object
       */
      getUrlQueryFragment: (state, getters) => {
        if (!getters.isFilterInitial) {
          return {
            [state.urlParamName]: !getters.isFilterEmpty ? String(state.value) : 0
          }
        } else return 0
      },
      /**
       * @param {*} state
       * @returns {boolean}
       */
      isFilterEmpty: state => state.value === emptyValue,
      /**
       * @private
       * @param {*} state
       * @returns {boolean}
       */
      isFilterInitial: state => state.value === initialValue
    },
    mutations: {
      update: (state, value) => {
        state.value = value
      },
      reset: state => {
        state.value = initialValue
      }
    },
    actions: {
      /**
       * @param {*} store
       * @param {UrlQueryFragment} urlQueryFragment
       */
      setValueFromUrl({ state, commit }, urlQueryFragment) {
        const urlValue = urlQueryFragment[state.urlParamName]
        if (urlValue) commit('update', urlValue)
        else if (urlValue === null) commit('update', emptyValue)
      }
    }
  }
}

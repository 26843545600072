<template>
  <Toaster class="dismissable-message">
    <div>{{ message }}</div>
    <Button inline @click="close">OK</Button>
  </Toaster>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    message: { type: String, default: 'Use message prop to set content of the message' },
    timeout: { type: Number, default: 0 }
  },
  mounted() {
    this.initializeAutomaticDismissal()
  },
  methods: {
    initializeAutomaticDismissal() {
      if (this.timeout > 0) {
        this.dismissTimer = setTimeout(this.dismiss, this.timeout)
      } else {
        this.dismissTimer = null
      }
    },
    close() {
      if (this.dismissTimer) {
        clearTimeout(this.dismissTimer)
        this.dismissTimer = null
      }
      this.dismiss()
    }
  }
})
</script>

<style lang="scss" scoped>
.dismissable-message {
  justify-content: space-between;

  &::v-deep button {
    padding: 8px 16px;
  }
}
</style>
























































import get from 'lodash/get'
import OrderStopComponent from './OrderStop.vue'
import { CustomerAddress, Company, OrderStop } from '@/services'
import { onMounted, defineComponent, PropType, ref } from '@vue/composition-api'
import { Form } from '@/modules/ordering/types'

export default defineComponent({
  components: { OrderStop: OrderStopComponent },
  model: { prop: 'stops', event: 'input' },
  props: {
    stops: { type: Array as PropType<OrderStop[]>, required: true },
    disabled: { type: Boolean, default: false },
    stopTypeDisabled: { type: Boolean, default: false },
    warehouseAddressDisabled: { type: Boolean, default: false },
    scheduleDisabled: { type: Boolean, default: false },
    compactView: { type: Boolean, default: false },
    isOrderTemplate: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    const orderStopForm = ref<Form | null>(null)
    const isOvernightLoad = ref<boolean>(true)

    const emitStopUpdate = (index: number, changes: Partial<OrderStop>): void => {
      emit('input', [
        ...props.stops.slice(0, index),
        { ...props.stops[index], ...changes },
        ...props.stops.slice(index + 1)
      ])
    }

    const isOrderTemplateStop = (index: number): boolean =>
      props.isOrderTemplate && (index === 0 || index === props.stops.length - 1)

    const updateStopCustomer = (index: number, newCustomer: Company): void => {
      emitStopUpdate(index, {
        customerCompany: newCustomer,
        customerCompanyId: newCustomer.id,
        contactPerson: null,
        warehouseAddress: null,
        warehouseAddressId: null
      })
      emit('removeTemplate', index)
    }

    const updateStopAddress = (index: number, newAddress: CustomerAddress): void => {
      emitStopUpdate(index, {
        warehouseAddress: newAddress,
        warehouseAddressId: get(newAddress, 'companyAddressId', null)
      })
      emit('removeTemplate', index)
    }

    const submit = (): { hasErrors: boolean } => orderStopForm.value?.submit()

    const toggleIsOvernightLoad = async () => {
      isOvernightLoad.value = !isOvernightLoad.value
      for (const [index, stop] of props.stops.entries()) {
        const updates: Partial<OrderStop> = { isOvernightLoad: isOvernightLoad.value }
        if (!isOvernightLoad.value && stop.startDate !== stop.endDate) {
          updates.endDate = stop.startDate
        }
        await emitStopUpdate(index, updates)
      }
    }

    onMounted(() => {
      isOvernightLoad.value = props.stops.some(stop => stop.isOvernightLoad)
    })

    return {
      orderStopForm,
      emitStopUpdate,
      isOrderTemplateStop,
      updateStopCustomer,
      updateStopAddress,
      submit,
      isOvernightLoad,
      toggleIsOvernightLoad
    }
  }
})

<template>
  <div class="next-loads-list">
    <div class="subsection title suggested-next-loads">
      {{ title }}
    </div>
    <div
      v-for="load in nextLoads"
      :key="load.nextOrderId"
      class="next-load-item"
      @click="navigateToLoad(load.order.idForStaff)"
    >
      <div class="distance-to-next-load" v-if="withDistanceToNextLoad">
        <Arrow>
          <template #above>{{ Math.round(load.distanceStraightLine) }} km</template>
        </Arrow>
      </div>
      <NextLoad :load="load" />
    </div>
  </div>
</template>

<script>
import { trackEvent } from '@/analytics/amplitude'
import Arrow from './Arrow.vue'
import NextLoad from './NextLoad.vue'

export default {
  components: {
    Arrow,
    NextLoad
  },
  props: {
    nextLoads: { type: Array, default: () => [] },
    withDistanceToNextLoad: { type: Boolean, default: true },
    suggestedNextLoadsTitle: {
      type: String,
      default: ''
    }
  },
  computed: {
    title() {
      return this.suggestedNextLoadsTitle
        ? this.suggestedNextLoadsTitle
        : this.$t('common/suggested-next-loads')
    }
  },
  methods: {
    navigateToLoad(idForStaff) {
      trackEvent('octopus/next-loads/next-load/click', { order: idForStaff })
      window.open(`/ordering/${idForStaff}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  color: $color-neutral-main;
  margin-bottom: -8px;
}

.distance-to-next-load {
  width: 100px;
}

.suggested-next-loads {
  margin-left: 116px;
}

.next-loads-list {
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  gap: 8px;
}

.next-load-item {
  display: flex;
  flex-direction: row;
}
</style>

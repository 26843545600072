import Fragment from 'vue-fragment'
import Vue from 'vue'
import plankton from '@sennder/plankton'
import VueHead from 'vue-head'

import Toasts from '@/plugins/toaster'
import VTooltip from 'v-tooltip'
import Collapsable from '../modules/common/components/Collapsable.vue'
import Delay from '../modules/common/components/Delay.vue'
import LoadingSpinner from '../modules/common/components/LoadingSpinner.vue'
import TextWithTooltip from '../modules/common/components/TextWithTooltip.vue'

export function registerGlobalComponents() {
  Vue.use(plankton)
  Vue.use(Toasts)
  Vue.component('LoadingSpinner', LoadingSpinner)
  Vue.component('Collapsable', Collapsable)
  Vue.component('Delay', Delay)
  Vue.component('TextWithTooltip', TextWithTooltip)
}

export function registerPlugins() {
  Vue.use(VueHead, {
    separator: '',
    complement: ''
  })
  Vue.use(VTooltip, {
    defaultHtml: false
  })
  Vue.use(Fragment.Plugin)
}

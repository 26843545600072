<template>
  <DropdownField
    v-model="selectedTeamLeadsHandler"
    :options="filterTeamLeads"
    optionKey="id"
    optionLabel="name"
    fullWidth
    multiple
    searchable
    data-test="team-leads-dropdown"
    :title="$t('filters/team-leads-filter-title')"
  />
</template>

<script>
import { fetchTeamLeads } from '@/services/dispatching-board-service'
export default {
  inject: ['getFilterValue', 'setFilterValue'],
  data: () => ({
    filterTeamLeads: []
  }),
  computed: {
    selectedTeamLeadsHandler: {
      get() {
        return this.getFilterValue('teamIds')
      },
      set(value) {
        this.setFilterValue('teamIds', value)
        this.$emit('applyFilters')
      }
    }
  },
  async created() {
    this.filterTeamLeads = await fetchTeamLeads()
  }
}
</script>

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import { dateTimeFormats } from '@/locale/dateFormat/format'
import { messages } from '@/locale/language'
import { Locale } from './types'
import {
  AVAILABLE_TRANSLATIONS,
  NODE_ENV,
  TRANSLATIONS_URL,
  USE_TRANSLATION_SERVICE
} from '@/config'
import logger from '@/shell/console-logger'

Vue.use(VueI18n)

const DEFAULT_LANGUAGE: Locale = 'en'

function validateLanguage(language: string): Locale {
  return AVAILABLE_TRANSLATIONS.includes(language)
    ? (language as Locale)
    : DEFAULT_LANGUAGE
}

const persistedLanguage: Locale = localStorage.getItem('language') as Locale
const browserLanguage: Locale = validateLanguage(navigator.language.split('-').shift())
const selectedLanguage = persistedLanguage || browserLanguage

export const i18n = new VueI18n({
  locale: selectedLanguage, // fetch and set browser language
  fallbackLocale: DEFAULT_LANGUAGE, // set fallback locale
  messages, // set locale messages,
  dateTimeFormats, // set locale date format
  silentFallbackWarn: true // shut up i18n in dev mode
})

export const getI18n = (locale: Locale) => {
  Vue.use(VueI18n)
  return new VueI18n({
    locale: validateLanguage(locale), // fetch and set browser language
    fallbackLocale: DEFAULT_LANGUAGE, // set fallback locale
    messages, // set locale messages,
    dateTimeFormats, // set locale date format
    silentFallbackWarn: true // shut up i18n in dev mode
  })
}

export const setLanguage = async newLanguage => {
  // For local development
  if (NODE_ENV == 'test') return

  const checkedLanguage = validateLanguage(newLanguage)

  if (USE_TRANSLATION_SERVICE === 'true') {
    try {
      const url = `${TRANSLATIONS_URL}/${checkedLanguage}.json`

      const messages = await axios({ url })
      i18n.setLocaleMessage(checkedLanguage, messages.data)
    } catch (error) {
      logger.error(
        `Unable to set locale from translation service for lang ${checkedLanguage}`,
        error
      )
      const messages = await import(`@/locale/language`)
      i18n.setLocaleMessage(checkedLanguage, messages.messages[checkedLanguage])
    }
  } else {
    const messages = await import(`@/locale/language`)
    i18n.setLocaleMessage(checkedLanguage, messages.messages[checkedLanguage])
  }

  localStorage.setItem('language', checkedLanguage)
}

setLanguage(selectedLanguage)

export enum Country {
  Albania = 'AL',
  Austria = 'AT',
  Belarus = 'BY',
  Belgium = 'BE',
  Bulgaria = 'BG',
  Croatia = 'HR',
  Czechia = 'CZ',
  Denmark = 'DK',
  Estonia = 'EE',
  Finland = 'FI',
  France = 'FR',
  Germany = 'DE',
  Greece = 'GR',
  Hungary = 'HU',
  Iceland = 'IS',
  Ireland = 'IE',
  Italy = 'IT',
  Latvia = 'LV',
  Liechtenstein = 'LI',
  Lithuania = 'LT',
  Luxembourg = 'LU',
  Malta = 'MT',
  Moldova = 'MD',
  Monaco = 'MC',
  Netherlands = 'NL',
  Norway = 'NO',
  Poland = 'PL',
  Portugal = 'PT',
  Romania = 'RO',
  Russia = 'RU',
  Serbia = 'RS',
  Slovakia = 'SK',
  Slovenia = 'SI',
  Spain = 'ES',
  Sweden = 'SE',
  Switzerland = 'CH',
  Turkey = 'TR',
  Ukraine = 'UA',
  UnitedKingdom = 'GB'
}

<template>
  <BoardTableRow :types="order.is_cancelled ? 'danger' : null">
    <td>
      <router-link :to="order.stageLink" data-test="row" target="_blank">
        {{ order.idForStaff }}
      </router-link>
      <RenderWithLaunchDarkly hasPermission="show-shipping-page">
        <div>
          <router-link :to="order.stageLinkTestNewPage" data-test="row" target="_blank">
            New order-link
          </router-link>
        </div>
      </RenderWithLaunchDarkly>
    </td>

    <td class="cell-border-right">
      <ReferenceCell :title="order.reference_number">
        {{ order.reference_number || '-' }}
      </ReferenceCell>
    </td>

    <td class="cell-border-right">
      <Chip :text="formattedOrderState" :category="stateCategory" />
    </td>

    <td data-test="account-manager-cell">
      <div class="account-manager-avatars">
        <Avatar
          class="operator-cell senior-account-manager"
          v-if="order.accountManager"
          :tooltip="
            $t(
              'search-board/search-order-row-senior-account-operation-manager-initials'
            ) +
            ': ' +
            order.accountManagerFullName
          "
        >
          {{ order.accountManager.initials }}
        </Avatar>
        <Avatar
          class="operator-cell"
          v-if="order.juniorAccountManager"
          :tooltip="
            $t('search-board/search-order-row-account-operation-manager-initials') +
            ': ' +
            order.juniorAccountManagerFullName
          "
        >
          {{ order.juniorAccountManager.initials }}
        </Avatar>
      </div>
    </td>

    <td class="cell-border-right">
      <TextWithTooltip
        :text="(order.partnerManager && order.partnerManager.initials) || '-'"
        :tooltip="order.partnerManagerFullName"
        data-test="partner-manager-cell"
      />
    </td>

    <td>
      <CompanyCell :title="hasCustomerCompany ? order.customer_name : ''">
        {{ hasCustomerCompany ? order.customer_name : '-' }}
      </CompanyCell>
    </td>

    <td>
      <CarrierCell :order="order" />
    </td>

    <td>
      <GenericInfoCell v-if="order.originAddress" :tooltipText="originAddress">
        {{ originAddress }}
      </GenericInfoCell>
      <GenericInfoCell v-else>-</GenericInfoCell>
    </td>

    <td class="cell-border-right">
      <GenericInfoCell v-if="order.destinationAddress" :tooltipText="destinationAddress">
        {{ destinationAddress }}
      </GenericInfoCell>
      <GenericInfoCell v-else>-</GenericInfoCell>
    </td>

    <DelayCell
      :order="order"
      type="PICKUP_DEPARTURE"
      class="cell-border-right--search-row"
    ></DelayCell>

    <DelayCell
      :order="order"
      type="DROPOFF_ARRIVAL"
      class="cell-border-right--search-row"
    ></DelayCell>

    <GpsCell :transfer="transfer" class="cell-border-right" />

    <td>
      {{ order.total_net_price | formatMoneyWithDashForNull }}
    </td>

    <td class="cell-border-right">
      {{ order.total_net_cost | formatMoneyWithDashForNull }}
    </td>

    <td>
      {{ allowedVehicleTypes }}
      <HintWithTooltip
        v-if="hasMultipleVehicleTypes"
        :tooltipText="tooltip"
        class="tooltip-hint"
      />
    </td>
  </BoardTableRow>
</template>

<script>
import {
  BoardTableRow,
  ReferenceCell,
  DelayCell,
  GpsCell,
  GenericInfoCell,
  CompanyCell,
  CarrierCell
} from '@/modules/boards'
import { addressShortFormat } from '@/models/address'
import HintWithTooltip from '@/modules/common/components/HintWithTooltip.vue'
import { humanizeState } from '@/modules/common/filters/order-filters'
import Avatar from '@/modules/common/components/Avatar'
import { formatMoneyWithDashForNull } from '@/global-setup/filters'
import RenderWithLaunchDarkly from '@/components/RenderWithLaunchDarkly.vue'

export default {
  components: {
    Avatar,
    GenericInfoCell,
    BoardTableRow,
    ReferenceCell,
    DelayCell,
    GpsCell,
    CompanyCell,
    CarrierCell,
    HintWithTooltip,
    RenderWithLaunchDarkly
  },
  filters: {
    addressShortFormat,
    formatMoneyWithDashForNull
  },
  props: {
    order: { type: Object, default: null },
    transfer: {
      type: [Object],
      default: function () {
        return {}
      },
      required: false
    }
  },
  computed: {
    hasMultipleVehicleTypes() {
      const { allowed_vehicle_types } = this.order
      return !!allowed_vehicle_types && allowed_vehicle_types.length > 1
    },
    allowedVehicleTypes() {
      const { allowed_vehicle_types } = this.order

      if (!allowed_vehicle_types || allowed_vehicle_types.length === 1) {
        return this.order.vehicle_type_human
      } else if (allowed_vehicle_types.length > 1) {
        const length = allowed_vehicle_types.length
        return this.$tc('search-board/search-order-row-vehicle-types-count', length)
      }
      return ''
    },
    tooltip() {
      return this.order.allowed_vehicle_types_human
    },
    hasCustomerCompany() {
      return this.order && this.order.customer_company
    },
    stateCategory() {
      const conditions = [
        { states: ['REGISTERED', 'DISPATCHED'], category: 'primary' },
        { states: ['NEW'], category: 'secondary' },
        { states: ['CARRIER_LOCKED'], category: 'red' },
        { states: ['EXECUTED', 'OPERATIONS_COMPLETED', 'COMPLETED'], category: 'green' }
      ]

      if (this.order.is_cancelled) {
        return 'red'
      } else {
        return (
          conditions.find(matcher => matcher.states.includes(this.order.state))
            ?.category ?? 'tertiary'
        )
      }
    },
    formattedOrderState() {
      if (this.order.is_cancelled) {
        return this.order.state === 'OPERATIONS_COMPLETED'
          ? this.$t('search-board/search-order-cancelled-closed-state')
          : this.$t('search-board/search-order-cancelled-open-state')
      } else {
        return humanizeState(this.order)
      }
    },
    originAddress() {
      return addressShortFormat(this.order.originAddress)
    },
    destinationAddress() {
      return addressShortFormat(this.order.destinationAddress)
    }
  }
}
</script>

<style lang="scss" scoped>
.account-manager-avatars {
  display: flex;

  .senior-account-manager {
    border: 1px solid;
  }
}

.operator-cell {
  &:hover {
    color: $color-secondary-main;
    background-color: $color-secondary-lighter;
  }
}
</style>

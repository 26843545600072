























































import { mapGetters } from 'vuex'
import orderingMappers from '@/modules/ordering/store/mappers'
import ClosingOrder from '@/modules/ordering/components/closing/ClosingOrder.vue'
import OrderStageFooter from '@/modules/ordering/components/shared/OrderStageFooter.vue'
import SendDispatchConfirmationModal from '@/components/SendDispatchConfirmationModal.vue'
import AsyncActionButton from '@/modules/ordering/components/shared/async-action-button/AsyncActionButton.vue'
import AsyncActionContext from '@/modules/ordering/components/shared/async-action-button/AsyncActionContext.vue'
import { refreshOrderLogs } from '@/modules/order-activity-sidebar'
import { hasHadState } from '@/modules/common/order-states'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    ClosingOrder,
    OrderStageFooter,
    SendDispatchConfirmationModal,
    AsyncActionButton,
    AsyncActionContext
  },
  props: {
    order: { type: Object, required: true },
    accountManager: { type: Object, default: null },
    juniorAccountManager: { type: Object, default: null },
    partnerManager: { type: Object, default: null },
    spotBidder: { type: Object, default: null }
  },
  data() {
    return {
      dispatchConfirmationModalShown: false
    }
  },
  computed: {
    ...mapGetters(['currentUserIsPartnerManager', 'currentUserIsCallCenter']),
    disabled() {
      return this.order.state !== 'EXECUTED'
    },
    canShowBreakdowns() {
      // hide breakdowns for call center users as
      // the breakdowns use graphql endpoints with
      // restricted permissions for call center.
      // see PES-112
      return !this.currentUserIsCallCenter
    },
    canShowDispatchConfirmationButton() {
      return (
        this.currentUserIsPartnerManager &&
        hasHadState(this.order.state, 'CARRIER_LOCKED')
      )
    }
  },
  methods: {
    ...orderingMappers.mapActions(['fetchOrder', 'completeOrderOperations']),
    async performOpsCompleted() {
      await this.completeOrderOperations()
      this.$refs['closing-order'].updatePaymentInformation()
      return null
    },
    sendDispatchConfirmation() {
      this.dispatchConfirmationModalShown = true
    },
    closeDispatchConfirmationModal() {
      this.dispatchConfirmationModalShown = false
      refreshOrderLogs()
    },
    handlePriceCostClosed() {
      this.fetchOrder(this.order.id)
      refreshOrderLogs()
    }
  }
})

import { AxiosResponse } from 'axios'

import { CARRIER_PROFILE_API_AUDIENCE, CARRIER_PROFILE_API_URL } from '@/config'

import { ContactResponse, PaginationResponse } from '../models'

import { IHttpClient, httpClient } from './http-client'
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase
} from '@/services/utils/casing'

export interface ICarrierProfileClient {
  getContacts: (
    carrierId?: string,
    offset?: number,
    limit?: number
  ) => Promise<PaginationResponse<ContactResponse>>
}

export class CarrierProfileClient implements ICarrierProfileClient {
  private baseUrl = () => `${CARRIER_PROFILE_API_URL}`
  private audience = () => CARRIER_PROFILE_API_AUDIENCE

  constructor(private client: IHttpClient) {}

  public getContacts = async (
    carrierId?: string,
    offset?: number,
    limit?: number
  ): Promise<PaginationResponse<ContactResponse>> => {
    const results = (await this.client.get(
      `${this.baseUrl()}/v1/contacts`,
      transformKeysToSnakeCase({
        carrierId,
        offset,
        limit: limit ?? 100,
        showDeleted: false
      }),
      { apiGatewayAuthorization: true, arrayFormat: 'repeat' },
      this.audience(),
      'carriers:list-contacts'
    )) as AxiosResponse<PaginationResponse<ContactResponse>>

    return transformKeysToCamelCase<PaginationResponse<ContactResponse>>(results.data)
  }
}

export const carrierProfileClient = new CarrierProfileClient(httpClient)

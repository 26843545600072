import { OPERATIONS_BACKEND_URL } from '@/config'
import { runJsonGet, runQuery } from '@/models/backend-client'

export const fetchCarriersBySearchText = async searchText => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/retrieve_carrier_companies?name_pattern=${encodeURIComponent(
      searchText
    )}`
  )
  return response.data
}

export const fetchCarrierInfo = async carrierId => {
  const query = `carriers (ids:[${carrierId}]){
    name
    salesforce_id
    gatehouse_carrier_id
    emergency_phone_number
    has_orcas_access
    carrier_tier
    vat_on_invoice
    contact_ids {
      company_id
      first_name
      last_name
      email
      phone
    }
    company_address_ids  {
      description
      company_id
      address_id {
        formatted
        latitude
        longitude
      }
    }
}`
  const result = await runQuery('', query)
  return result.data
}

export const fetchCarrierById = async id => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/carrier/${id}`
  )
  return response.data
}

export const queryCompanyContacts = async companyId => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/contacts_belonging_to_company`,
    { company_id: companyId }
  )
  return response.data
}

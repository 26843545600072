
























import { PropType, defineComponent } from '@vue/composition-api'
import { googleMapUrl } from '@/modules/common/googlemap-stops-helpers'
import { placeholderIfEmpty } from '@/global-setup/filters'
import { OrderStop } from '@/services'
import LabeledText from '@/modules/common/components/LabeledText.vue'

export default defineComponent({
  components: { LabeledText },
  props: {
    stops: { type: Array as PropType<OrderStop[]>, required: true },
    isTotalValid: { type: Boolean, required: true },
    totalDist: { type: String, required: true }
  },
  setup() {
    return { googleMapUrl, placeholderIfEmpty }
  }
})

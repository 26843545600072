



























































































































import clone from 'lodash/clone'
import isEqual from 'lodash/isEqual'
import uniqueId from 'lodash/uniqueId'
import OrderStopForm from '../stops/OrderStopsForm.vue'
import CustomerDetailsForm from './CustomerDetailsForm.vue'
import TemperatureRangeField from '../TemperatureRangeField.vue'
import ConfirmNewPriceModal from './ConfirmNewPriceModal.vue'
import RenderWithPermission from '@/components/RenderWithPermission.vue'
import * as permissions from '@/components/user-permissions'
import { RolledTransportForm } from '@sennder/incident-management'
import { FORMATTED_VEHICLE_TYPES, LOAD_TYPES } from '@sennder/octopus-constants'
import { hasHadState } from '@/modules/common/order-states'
import { defineComponent, ref, computed, nextTick } from '@vue/composition-api'
import {
  OrderDetailWithCustomerCompany,
  OrderStop,
  OrderStopToCreate,
  VehicleType,
  OrderDetail
} from '@/services'
import useStore from '@/compositions/useStore'
import useRolledTransport from '@/modules/ordering/compositions/useRolledTransport'
import Vue from 'vue'
import { TemperatureRange, CustomerDetails } from '../types'
import { Form } from '@/modules/ordering/types'
import useCurrentUser from '@/compositions/useCurrentUser'
import { CAN_EDIT_VARIABLE_ORDERS } from '@/components/user-permissions'
import { getOrderRouteDetails } from '@/services/ordering-service'
import sumBy from 'lodash/sumBy'

interface FormattedAllowedVehicleType {
  value: VehicleType
  label: string
}

interface PriceAndCost {
  price: number | null
  cost: number | null
  distance: number | null
  priceMessage: string | null
  costMessage: string | null
}

export default defineComponent({
  components: {
    RenderWithPermission,
    RolledTransportForm,
    OrderStopForm,
    CustomerDetailsForm,
    TemperatureRangeField,
    ConfirmNewPriceModal
  },
  props: {
    orderId: { type: Number, default: null }
  },
  setup(props, { emit }) {
    const options = FORMATTED_VEHICLE_TYPES

    const stopForm = ref<Form | null>(null)
    const loadForm = ref<Form | null>(null)
    const rolledTransportForm = ref<Form | null>(null)
    const vehicleDropdown = ref<Vue | null>(null)
    const editOrderModal = ref<Vue | null>(null)

    const allowedVehicleTypes = ref<VehicleType[]>([])
    const originalOrder = ref<OrderDetailWithCustomerCompany | null>(null)
    const originalStops = ref<OrderStop[]>([])
    const temperatureRange = ref<TemperatureRange | null>(null)
    const customerDetailsValue = ref<CustomerDetails | null>(null)
    const loadQuantity = ref<number | null>(null)
    const loadUnitType = ref<string | null>(null)
    const stops = ref<(OrderStop | OrderStopToCreate)[]>([])

    const showDialog = ref<boolean>(false)
    const inProgressRequest = ref<boolean>(false)

    const removedStopIds = ref<number[]>([])
    const newPriceAndCost = ref<PriceAndCost>({
      price: 0,
      cost: 0,
      distance: 0,
      priceMessage: null,
      costMessage: null
    })
    const isConfirmNewPriceModalOpen = ref<boolean>(false)
    const orderTotalDistance = ref<number>(0)
    const palletExchangeUpdated = ref<boolean>(false)

    const closeConfirmNewPriceModal: () => void = () => {
      isConfirmNewPriceModalOpen.value = false
    }
    const openConfirmNewPriceModal: () => void = async () => {
      const routeDetails = await getOrderRouteDetails(originalOrder.value.id)
      const total = sumBy(routeDetails, 'distanceToNext')
      orderTotalDistance.value = Number((total / 1000).toFixed(2))
      isConfirmNewPriceModalOpen.value = true
    }

    const store = useStore()
    const { hasPermission } = useCurrentUser()

    const { displayComponent } = useRolledTransport({
      originalStops,
      stops
    })

    const onIncidentReported: () => void = () => {
      emit('incident-reported')
    }

    const orderIsEditableForVariations = computed<boolean>(
      () =>
        originalOrder.value?.editableForVariations &&
        hasPermission(CAN_EDIT_VARIABLE_ORDERS)
    )

    const hasChangedReferenceNumber = computed<boolean>(
      () =>
        originalOrder.value.referenceNumber !== customerDetailsValue.value.referenceNumber
    )

    const hasChangedTrackingId = computed<boolean>(
      () =>
        originalOrder.value.trackingIdForShipper !==
        customerDetailsValue.value.trackingIdForShipper
    )

    const hasChangedTemperature = computed<boolean>(
      () =>
        originalOrder.value.loadMinimumTemperature !== temperatureRange.value.min ||
        originalOrder.value.loadMaximumTemperature !== temperatureRange.value.max
    )

    const hasChangedStops = computed<boolean>(() =>
      originalStops.value.some(
        (originalStop, index) => !isEqual(originalStop, stops.value[index])
      )
    )

    const hasChangedVehicleTypes = computed<boolean>(
      () => originalOrder.value.allowedVehicleTypes != allowedVehicleTypes.value
    )

    const canSubmit = computed<boolean>(() => {
      if (!originalOrder.value || !customerDetailsValue.value || !temperatureRange.value)
        return false
      return (
        hasChangedReferenceNumber.value ||
        hasChangedTemperature.value ||
        hasChangedStops.value ||
        hasChangedVehicleTypes.value ||
        hasChangedTrackingId.value ||
        palletExchangeUpdated.value
      )
    })

    const disableIfFrigo = computed(() => {
      const FRIGO = 'TRUCK_40_FRIGO'
      if (!originalOrder.value || !allowedVehicleTypes.value) return

      return (
        allowedVehicleTypes.value.includes(FRIGO) &&
        originalOrder.value.isTemperatureControlled
      )
    })

    const isAfterCarrierLocked = computed<boolean>(() => {
      return hasHadState(store.state.ordering.order.state, 'DISPATCHED')
    })

    const formattedAllowedVehicleTypes = computed<FormattedAllowedVehicleType[]>({
      get: () =>
        allowedVehicleTypes.value.map(type =>
          FORMATTED_VEHICLE_TYPES.find(formattedType => formattedType.value === type)
        ),
      set: newValue => {
        allowedVehicleTypes.value = newValue.map(type => type.value)
      }
    })

    const createEmptyStop = (stopoverType = null): OrderStopToCreate => ({
      id: null,
      localId: uniqueId('local-'),
      orderId: originalOrder.value.id,
      stopoverType,
      customerCompany: null,
      warehouseAddress: null,
      warehouseAddressId: null,
      referenceNumber: originalOrder.value.referenceNumber,
      startDate: null,
      endDate: null,
      startTime: '',
      endTime: '',
      isOvernightLoad: false,
      contactPerson: null,
      notes: null
    })

    const addNewStop = (): void => {
      const insertionIndex = stops.value.length - 1
      stops.value.splice(insertionIndex, 0, createEmptyStop())
    }

    const removeStop = async ({
      stop,
      index
    }: {
      stop: OrderStop | OrderStopToCreate
      index: number
    }): Promise<void> => {
      if (stop.id) {
        removedStopIds.value.push(stop.id)
      }
      stops.value.splice(index, 1)
    }

    const show = (
      initialOrder: OrderDetailWithCustomerCompany,
      initialStops: OrderStop[]
    ): void => {
      originalOrder.value = initialOrder
      originalStops.value = initialStops

      customerDetailsValue.value = {
        customer: initialOrder.customerCompany,
        referenceNumber: initialOrder.referenceNumber,
        contact: initialOrder.customerContact,
        trackingIdForShipper: initialOrder.trackingIdForShipper
      }
      temperatureRange.value = {
        min: initialOrder.loadMinimumTemperature,
        max: initialOrder.loadMaximumTemperature
      }
      allowedVehicleTypes.value = initialOrder.allowedVehicleTypes
      loadQuantity.value = initialOrder.loadQuantity
      loadUnitType.value = LOAD_TYPES.find(
        loadType => loadType.value === initialOrder.loadUnitType
      )?.label
      stops.value = initialStops.map(clone)

      showDialog.value = true
    }

    const confirm = async (): Promise<void> => {
      if (hasErrors()) {
        scrollToFirstError()
        return
      }

      try {
        inProgressRequest.value = true
        if (orderIsEditableForVariations.value) {
          newPriceAndCost.value = await store.dispatch('ordering/calculatePriceAndCost', {
            stops: stops.value.map(stop => ({
              latitude: stop.warehouseAddress.latitude,
              longitude: stop.warehouseAddress.longitude,
              addressId: stop.warehouseAddress.id
            })),
            vehicleTypes: allowedVehicleTypes.value
          })
          if (
            newPriceAndCost.value.price &&
            newPriceAndCost.value.cost &&
            (newPriceAndCost.value.price !== originalOrder.value.basePrice ||
              newPriceAndCost.value.cost !== originalOrder.value.baseCost)
          ) {
            openConfirmNewPriceModal()
          } else {
            await confirmOrder()
            close()
          }
        } else {
          await confirmOrder()
          close()
        }
      } finally {
        inProgressRequest.value = false
      }
    }

    const confirmOrder = async () => {
      const reportRolledTransportIncident: () => {
        hasErrors: boolean
      } = rolledTransportForm.value?.submit

      // The pallet exchange editing is temporarily disabled
      // for EditOrerModal component. See the commit below:
      // https://gitlab.com/sennder/cross-cutting/octopus/-/commit/a75a46674cff49a890cc982e4df89bb2e5f9009b

      const order: Partial<OrderDetail> = {
        allowedVehicleTypes: allowedVehicleTypes.value,
        loadMinimumTemperature: temperatureRange.value.min,
        loadMaximumTemperature: temperatureRange.value.max,
        referenceNumber: customerDetailsValue.value.referenceNumber,
        trackingIdForShipper: customerDetailsValue.value.trackingIdForShipper
      }

      await Promise.all([
        // don't report the rolled transport if the date is not postponed
        displayComponent.value ? reportRolledTransportIncident() : Promise.resolve(),
        isAfterCarrierLocked.value
          ? store.dispatch('ordering/updateDispatchedOrder', {
              order,
              stops: stops.value
            })
          : store.dispatch('ordering/updateOrderCustomerRequirements', {
              order,
              stops: stops.value,
              editableForVariations: orderIsEditableForVariations.value,
              removedStopIds: removedStopIds.value,
              newPrice: newPriceAndCost.value.price,
              newCost: newPriceAndCost.value.cost
            })
      ])
    }

    const confirmNewPriceModal: () => void = async () => {
      await confirmOrder()
      closeConfirmNewPriceModal()
      close()
    }

    const close = (): void => {
      showDialog.value = false
      removedStopIds.value = []
    }

    const hasErrors = (): boolean => {
      const hasStopErrors = stopForm.value?.submit().hasErrors
      const hasLoadErrors = loadForm.value?.submit().hasErrors
      const hasRolledTransportErrors = rolledTransportForm.value?.validate().hasErrors
      return hasStopErrors || hasLoadErrors || hasRolledTransportErrors
    }

    const scrollToFirstError = (): void =>
      nextTick(() => {
        const errorEl =
          editOrderModal.value?.$el.getElementsByClassName('form__errors')[0]
        errorEl?.scrollIntoView?.({ behavior: 'smooth' })
      })

    const scrollIntoView = (): void => {
      /*
        scroll to the dropdown when it is selected because it is hidden inside
        of the modal as the list is absolute positioning and the modal is static
      */
      if (!isAfterCarrierLocked.value) {
        vehicleDropdown.value?.$el.scrollIntoView({ behavior: 'smooth' })
      }
    }

    return {
      permissions,
      options,
      stopForm,
      loadForm,
      rolledTransportForm,
      vehicleDropdown,
      editOrderModal,
      originalOrder,
      temperatureRange,
      customerDetailsValue,
      stops,
      displayComponent,
      onIncidentReported,
      showDialog,
      inProgressRequest,
      canSubmit,
      disableIfFrigo,
      isAfterCarrierLocked,
      formattedAllowedVehicleTypes,
      orderIsEditableForVariations,
      addNewStop,
      removeStop,
      show,
      confirm,
      close,
      scrollIntoView,
      newPriceAndCost,
      isConfirmNewPriceModalOpen,
      closeConfirmNewPriceModal,
      confirmNewPriceModal,
      orderTotalDistance,
      loadQuantity,
      loadUnitType
    }
  }
})

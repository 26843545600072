





























































import { Icon } from '@sennder/plankton'
import { IncidentDisplay } from '@sennder/incident-management'
import Note from './Note.vue'
import Log from './Log.vue'
import {
  defineComponent,
  Ref,
  ref,
  toRef,
  onBeforeUnmount,
  onBeforeMount,
  PropType
} from '@vue/composition-api'
import useOrderActivities from '../compositions/useOrderActivities'
import {
  sidebarIsVisible,
  collapseSidebar,
  saveSidebarVisibilityState,
  restoreSidebarVisibiliyState
} from '../compositions/useSidebarVisibility'
import { ProcessedLog, ProcessedNote, isLog, isNote } from '../types'
import useMultipleLetters from '@/compositions/transfer/useMultipleLetters'
import { OrderStop } from '@/services'
import useI18n from '@/compositions/useI18n'

export default defineComponent({
  components: {
    Note,
    Log,
    Incident: IncidentDisplay,
    Icon
  },
  props: {
    orderId: { type: Number, required: true },
    currentUserId: { type: Number, required: true },
    stops: {
      type: Array as PropType<OrderStop[]>,
      required: false,
      default: () => []
    }
  },
  setup(props) {
    const { t } = useI18n()

    const content: Ref<HTMLElement | null> = ref(null)
    const input: Ref<HTMLInputElement | null> = ref(null)
    const noteText = ref('')
    const isSubmittingNote = ref(false)

    const { addressIdsLettersMap } = useMultipleLetters(toRef(props, 'stops'))

    const { orderActivitiesSorted, addNote, removeNote, openDocument } =
      useOrderActivities({
        orderId: toRef(props, 'orderId'),
        content,
        currentUserId: toRef(props, 'currentUserId'),
        stopLetters: addressIdsLettersMap,
        stops: toRef(props, 'stops')
      })

    const isLastLoadingNote = (
      item: ProcessedNote | ProcessedLog,
      itemIndex: number
    ): boolean => isNote(item) && itemIndex === 0 && isSubmittingNote.value

    const onFormSubmit = async (): Promise<void> => {
      if (noteText.value) {
        const textToAdd = noteText.value
        noteText.value = ''
        isSubmittingNote.value = true
        try {
          await addNote(textToAdd)
        } finally {
          isSubmittingNote.value = false
        }
        if (content.value) content.value.scrollTop = content.value.scrollHeight
      }
      input.value?.focus()
    }

    const onNoteRemove = async (item: ProcessedNote | ProcessedLog): Promise<void> => {
      if (isNote(item)) {
        await removeNote(item.noteId)
        input.value?.focus()
      }
    }

    const onDocumentClick = async (item: ProcessedNote | ProcessedLog): Promise<void> => {
      // item.message contains the type of the Document
      //  (e.g. 'Dispatching Confirmation PDF', 'Credit Note PDF', etc)
      if (isLog(item)) openDocument(item.message, item.fileName)
    }

    onBeforeMount(restoreSidebarVisibiliyState)

    onBeforeUnmount(saveSidebarVisibilityState)

    return {
      t,
      content,
      input,
      noteText,
      isSubmittingNote,
      orderActivitiesSorted,
      addressIdsLettersMap,
      onFormSubmit,
      onNoteRemove,
      collapseSidebar,
      onDocumentClick,
      sidebarIsVisible,
      isLastLoadingNote
    }
  }
})

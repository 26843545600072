import { render, staticRenderFns } from "./OrderInformationHeaderV3.vue?vue&type=template&id=0bf32474&scoped=true&"
import script from "./OrderInformationHeaderV3.vue?vue&type=script&lang=ts&"
export * from "./OrderInformationHeaderV3.vue?vue&type=script&lang=ts&"
import style0 from "./OrderInformationHeaderV3.vue?vue&type=style&index=0&id=0bf32474&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bf32474",
  null
  
)

export default component.exports






















































import { PropType, defineComponent } from '@vue/composition-api'
import get from 'lodash/get'
import { placeholderIfEmpty } from '@/global-setup/filters'
import { TransferDetail } from '@/services'
import LabeledText from '@/modules/common/components/LabeledText.vue'

export default defineComponent({
  components: { LabeledText },
  props: {
    transfer: { type: Object as PropType<TransferDetail>, required: true },
    carrierVehicleLabel: { type: String, default: '' }
  },
  setup() {
    return { get, placeholderIfEmpty }
  }
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accord__section",class:{
    nomargin: _vm.noMargin,
    'accord__section--breakdown': _vm.type === 'breakdown'
  }},[_c('div',{staticClass:"accord__header",class:{
      collapsed: _vm.collapsed,
      'accord__header--alwaysopen': _vm.disableCollapse,
      'accord__header--transparent': _vm.transparentHeader,
      'accord__header--breakdown': _vm.type === 'breakdown'
    },on:{"click":_vm.toggleCollapse}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"accord__content collapse",class:{ show: !_vm.collapsed, nopadding: _vm.noPadding }},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
import { humanizeTimeBetween, NOT_AVAILABLE_PLACEHOLDER } from '@sennder/plankton'

export const ON_TIME_LABEL = 'On time'
export const ONE_MINUTE_IN_MILLISECONDS = 60000

export const humanizeDelay = duration => {
  if (duration == null) return NOT_AVAILABLE_PLACEHOLDER

  const { roundedMinutes, hours, totalDaysPart } = durationParts(duration)

  if (totalDaysPart) return `${totalDaysPart} d ${hours} h`
  if (hours) return `${hours} h ${roundedMinutes} min`
  if (roundedMinutes) return `${roundedMinutes} min`
  else return ON_TIME_LABEL
}

function roundToLowerAbsolute(number) {
  return Math.sign(number) * Math.floor(Math.abs(number))
}

function durationParts(duration) {
  const roundedMinutes = Math.round(duration.asMinutes() % 60)
  const roundedMinutesAddUpToAnHour = Math.abs(roundedMinutes) === 60

  return {
    roundedMinutes: roundedMinutesAddUpToAnHour ? 0 : roundedMinutes,
    hours: duration.hours() + (roundedMinutesAddUpToAnHour ? roundedMinutes / 60 : 0),
    totalDaysPart: roundToLowerAbsolute(duration.asDays())
  }
}

export const humanizeElapsedTime = function (isoDate) {
  return humanizeTimeBetween(isoDate, Date.now(), ' ago')
}











import { defineComponent } from '@vue/composition-api'

// 🚨🚨🚨🚨 This component renders AcceptNow and ExpectedCost components from bidding microfrontend
export default defineComponent({
  name: 'CostSuggestions'
})

import Model from './model'

export default class Address extends Model {
  static get NAME() {
    return 'addresses'
  }
}

// [DEPRECATED] As we are moving away from using snake case, we should not be
// using this in new developments. Instead use the function located in
// src/modules/common/filters/address-filters.js
export const addressShortFormat = function (address) {
  if (!address) return '-'
  return `${address.country_code}-${address.postal_code}, ${address.locality}`
}

import { computed, reactive, Ref } from '@vue/composition-api'
import {
  DispatchingTransferDetail,
  FormValues,
  Provider,
  Tracker,
  TrackerByLicensePlate
} from '@/modules/ordering/components/dispatching/types'
import { getTrackerByLicensePlate } from '@/services/order-tracking-service'
import debounce from 'lodash/debounce'

export default (
  transfer: Ref<DispatchingTransferDetail>,
  carrierCompanyId: Ref<number>
) => {
  const debouncedVehicleLicensePlateWatcher = debounce(async function (
    vehicleLicensePlate
  ) {
    await fetchTrackersByField(vehicleLicensePlate, 'vehicle')
  },
  1000)

  const debouncedTrailerLicensePlateWatcher = debounce(async function (
    trailerLicensePlate
  ) {
    await fetchTrackersByField(trailerLicensePlate, 'trailer')
  },
  1000)

  const licensePlate = computed<string>({
    get: () => {
      if (transfer.value?.licensePlate)
        debouncedVehicleLicensePlateWatcher(transfer.value.licensePlate)
      return transfer.value?.licensePlate?.trim() || ''
    },
    set: val => {
      if (transfer.value) transfer.value.licensePlate = val
      debouncedVehicleLicensePlateWatcher(val)
    }
  })

  const trailerLicensePlate = computed<string>({
    get: (): string => {
      if (transfer.value?.trailerLicensePlate)
        debouncedTrailerLicensePlateWatcher(transfer.value.trailerLicensePlate)
      return transfer.value?.trailerLicensePlate?.trim() || ''
    },
    set: val => {
      if (transfer.value) transfer.value.trailerLicensePlate = val
      debouncedTrailerLicensePlateWatcher(val)
    }
  })

  const formValues = reactive<FormValues>({
    allowedVehicleTypes: null,
    carrierCompany: null,
    carrierContact: null,
    notes: null,
    vehicleType: null,
    licensePlate: licensePlate,
    trailerLicensePlate: trailerLicensePlate,
    transferCode: null,
    driverName: '',
    driverPhoneNumber: '',
    gatehousePullOn: false,
    gatehousePushOn: false,
    amazonPushOn: false,
    baseCost: null,
    costSurcharge: null,
    costSurchargeDescription: null,
    partnerManager: null,
    dispatchedVehicle: null,
    co3: {
      vehicle: false,
      trailer: false
    },
    gatehouse: {
      vehicle: false,
      trailer: false
    },
    driverApp: {
      vehicle: false,
      trailer: false
    }
  })

  const usesDriverApp = computed<boolean>(() => Boolean(formValues.licensePlate))

  async function fetchTrackersByField(
    licensePlate: string,
    vehicleField: keyof Tracker
  ): Promise<void> {
    const providers: Provider[] = [Provider.co3, Provider.driverApp, Provider.gatehouse]

    if (licensePlate.trim()) {
      await Promise.all(
        providers.map(async (name: Provider) => {
          const response: TrackerByLicensePlate = await getTrackerByLicensePlate(
            licensePlate,
            name,
            name === Provider.gatehouse ? carrierCompanyId.value : null
          )

          const validatedResponse = response ? response.lastUpdate : false
          formValues[Provider[name]][vehicleField] = validatedResponse
        })
      )
    } else {
      providers.forEach((name: Provider) => {
        formValues[Provider[name]][vehicleField] = false
      })
    }
  }

  return {
    formValues,
    usesDriverApp,
    debouncedVehicleLicensePlateWatcher,
    debouncedTrailerLicensePlateWatcher,
    //for testing
    licensePlate,
    trailerLicensePlate
  }
}

<template>
  <DropdownField
    v-model="assignmentStateFilterHandler"
    :options="assignmentStates"
    :close-on-select="false"
    :clear-on-select="true"
    optionLabel="label"
    optionKey="value"
    :title="$t('filters/assignment-states-filter-title')"
    multiple
    searchable
    data-test="assignment-states-filter"
  />
</template>

<script>
import { getAssignmentStatesOptions } from '../../constants'

export default {
  inject: ['getFilterValue', 'setFilterValue'],
  computed: {
    assignmentStates() {
      return getAssignmentStatesOptions()
    },
    assignmentStateFilterHandler: {
      get() {
        return this.getFilterValue('assignmentStates').map(selectedOption =>
          this.assignmentStates.find(option => option.value === selectedOption)
        )
      },
      set(options) {
        this.setFilterValue(
          'assignmentStates',
          options.map(option => option.value)
        )
        this.$emit('applyFilters')
      }
    }
  }
}
</script>

import { ComputedRef } from '@vue/composition-api'
import * as LegacyFML from '@sennder/federated-module-loader-no-cache'
import {
  FederatedModule,
  Config,
  SharedData,
  CacheType
} from '@sennder/federated-module-loader'
import { clearWebpackChunks } from '@sennder/federated-module-loader/src/bundle-cache'
import useFeatureFlags from '../compositions/useFeatureFlag'

import {
  IOctopusSharedData,
  MountFunc
} from '@sennder/senn-node-microfrontend-interfaces'
import { monitorError } from '@/analytics/monitoring'

type FeatureFlag = {
  type: CacheType | null
}

type getJSONValue = (flag: string) => ComputedRef<FeatureFlag> | undefined

const mountFnCache = new Map<string, MountFunc<unknown>>()
let getJSONValue: getJSONValue = undefined

export const fetchMicrofrontend = async <S extends SharedData = IOctopusSharedData>(
  config: Config
): Promise<MountFunc<S>> => {
  if (!getJSONValue) {
    getJSONValue = useFeatureFlags().getJSONValue as getJSONValue
  }

  const { type } = getJSONValue('ENABLE_FML-CACHE').value

  if (type === null) {
    const module = new LegacyFML.FederatedModule<S>(config)
    const identifier = `${config.npmName}-${config.environment}-${config.moduleFederationPluginName}-${config.mountExposedName}`

    const cachedEntry = mountFnCache.get(identifier)
    if (cachedEntry) {
      return cachedEntry as MountFunc<S>
    }

    try {
      clearWebpackChunks(config.moduleFederationPluginName)
    } catch (e) {
      monitorError(e, 'fetchMicrofrontend', {
        cacheType: type,
        module: config.moduleFederationPluginName
      })
    }

    // This will only happen on first visit
    const mount = await module.getMountFn()

    // Cache indefinitely
    mountFnCache.set(identifier, mount)

    return mount
  } else {
    const module = new FederatedModule<S>({
      ...config,
      cacheType: type
    })

    return module.getMountFn()
  }
}

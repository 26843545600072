




















































import { defineComponent, PropType } from '@vue/composition-api'

import { OrderMetrics } from '../../types'

export default defineComponent({
  props: {
    orderMetrics: { type: Object as PropType<OrderMetrics>, default: () => {} },
    expectedCost: { type: Number, default: 0 }
  },
  computed: {
    averageCost(): number {
      return this.orderMetrics?.averageCost
    },
    loadsCompleted(): number {
      return this.orderMetrics?.loadsCompleted
    }
  }
})

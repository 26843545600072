export const initials = person => {
  if (person.firstName && person.lastName) {
    return (firstCharOf(person.firstName) + firstCharOf(person.lastName)).toUpperCase()
  }
  if (person.name) {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')

    let initials = [...person.name.matchAll(rgx)] || []

    return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase()
  }
  return ''
}

function firstCharOf(string) {
  return string ? string.charAt(0) : ''
}

export const fullName = person => {
  const { fullName, firstName, lastName, name } = person
  return fullName || [firstName, lastName].join(' ').trim() || name
}

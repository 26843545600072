import Model from '@/models/model'
import { OPERATIONS_BACKEND_URL } from '@/config'
import { runJsonPost } from '@/models/backend-client'
import { store } from '@/models/store'
import {
  getAuthCookieValue,
  removeAuthCookieValue,
  removeJWTCookieValue,
  getInstance
} from '@sennder/auth'
import { removeCookieValue as removeUserIdCookie } from '@/modules/auth/useUserId'
import { Credentials, login } from '@/services/auth-service'
import useCurrentUser from '@/compositions/useCurrentUser'
import { removePersistedState } from '@/services/local-storage-service'

const TRANSIT_MF_MAP_VIEW_KEY = 'transit-mf:heremap-view'
const { user, loadCurrentUser } = useCurrentUser()

class User extends Model {
  static get URL(): string {
    return `${OPERATIONS_BACKEND_URL}/accounts/users`
  }

  static async me(): Promise<User> {
    // This is called by fetchCurrentUser action just once when the user is not present in the store
    await loadCurrentUser()
    const result = user.value
    const resolution = `${window.screen.availWidth}x${window.screen.availHeight}`
    return new User({ ...result, resolution })
  }

  static async login(credentials: Credentials): Promise<void> {
    await login(credentials)
  }

  static async loginWithGoogle(token: string): Promise<void> {
    await login({ token })
  }

  static frontendLogout(): void {
    // Log out the user on the front end without invalidating
    // the auth token in the backend.
    removeAuthCookieValue()
    removeJWTCookieValue()
    removeUserIdCookie()
    sessionStorage.removeItem('auth0Permissions')
    store.commit('resetCurrentUser')

    // Reset HereMap view for transit MF
    removePersistedState(TRANSIT_MF_MAP_VIEW_KEY)
  }

  static logout(): Promise<void> {
    return runJsonPost(`${OPERATIONS_BACKEND_URL}/auth/logout/`).then(() => {
      this.frontendLogout()
    })
  }

  static async isLoggedIn(): Promise<boolean> {
    // If the cookie has expired the token will be automatically empty
    if (getAuthCookieValue()) return true
    const auth0Instance = getInstance()
    const isAuthenticated = await auth0Instance?.auth0Client?.isAuthenticated()
    return isAuthenticated
  }
}

export default User

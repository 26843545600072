export const setFiltersFromUrl = async ({ state, commit, dispatch }, queryObj) => {
  // group URL params by filter name (e.g. earliest_pickup and latest_pickup are both
  // related to dates filter and should be handled together)
  const groupedQueryObj = Object.entries(queryObj).reduce(
    (groupedQueryObj, [queryKey, queryValue]) => {
      const filterName = Object.keys(state).find(filterName => {
        const urlParamNameValue = state[filterName].urlParamName
        // most filters have only one urlParamName but some of them
        // (e.g. dates filter) could have multiple
        const urlParamNamesArray = Array.isArray(urlParamNameValue)
          ? urlParamNameValue
          : [urlParamNameValue]
        return urlParamNamesArray?.includes(queryKey)
      })

      if (filterName) {
        const queryGroup = groupedQueryObj[filterName]
        if (queryGroup) {
          queryGroup[queryKey] = queryValue
        } else {
          groupedQueryObj[filterName] = { [queryKey]: queryValue }
        }
      }

      return groupedQueryObj
    },
    {}
  )

  const promises = Object.entries(groupedQueryObj).map(([filterName, urlQueryFragment]) =>
    dispatch(`${filterName}/setValueFromUrl`, urlQueryFragment)
  )

  await Promise.all(promises)
  commit('setFiltersInitialized')
}

export const resetFilters = ({ state, commit }) => {
  const allFilterNames = Object.keys(state).filter(
    filterName =>
      filterName !== 'areFiltersInitialized' &&
      filterName !== 'options' &&
      filterName !== 'config'
  )
  allFilterNames.forEach(filterName => commit(`${filterName}/reset`))
}

<template>
  <Dialog
    :show="visible"
    :disabled="requestInProgress"
    title="Add new company address"
    confirmButtonText="save"
    @confirm="save"
    @close="cancel"
    @cancel="cancel"
    v-bind="$attrs"
    inPlace
  >
    <p class="description">
      {{ $t('ordering/new-customer-address-dialog-description') }}
    </p>
    <Form ref="form">
      <InputField
        v-model="fullAddress"
        :inputValidators="fullAddressValidators"
        title="Company address"
        fullWidth
        placeholder="Enter company address"
        data-test="full-address"
      />
      <InputField
        v-model="description"
        title="Description"
        fullWidth
        placeholder="Enter description"
        convertEmptyToNull
        data-test="address-description"
      />
    </Form>
  </Dialog>
</template>
<script>
import isEmpty from 'lodash/isEmpty'
import { createCustomerAddress } from '@/services/customer-service'

export default {
  data() {
    return {
      visible: false,
      requestInProgress: false,
      customerCompanyId: null,
      fullAddress: null,
      description: null,
      resolvePromise: null,
      fullAddressValidators: [
        { errorMessage: 'This field cannot be empty', isInvalid: isEmpty }
      ]
    }
  },
  methods: {
    show(customerCompanyId, fullAddress) {
      this.customerCompanyId = customerCompanyId
      this.fullAddress = fullAddress
      this.description = null
      this.visible = true
      return new Promise(resolve => (this.resolve = resolve))
    },
    async save() {
      if (this.$refs.form.submit().hasErrors) return
      try {
        this.requestInProgress = true
        const address = await createCustomerAddress(
          this.customerCompanyId,
          this.fullAddress,
          this.description
        )
        this.visible = false
        this.resolve(address)
      } finally {
        this.requestInProgress = false
      }
    },
    cancel() {
      this.visible = false
      this.resolve(null)
    }
  }
}
</script>
<style lang="scss" scoped>
.description {
  margin-bottom: 24px;
}
</style>

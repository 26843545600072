<template>
  <div>
    <UnsavedStepChangesModal
      v-if="order && showUnsavedChangesModal"
      :onConfirm="confirmMethod"
      :onCancel="closeMethod"
      :order="order"
    ></UnsavedStepChangesModal>
    <ul v-if="order" class="state-tabs subtitle">
      <component
        v-for="(item, index) in visibleTabs"
        :key="item.label"
        :class="{
          'state-tabs__item': true,
          'state-tabs__item--active': isNavItemActive(item),
          'state-tabs__item--alone': isJustOneTabVisible
        }"
        :is="item.tabComponent"
        :active="isNavItemActive(item)"
        data-test="state-tab-item"
        @click="goToTab(item.states[0])"
      >
        <div class="flex-item">
          <span v-if="isNavItemCompleted(item)">
            <i class="material-icons check-icon">done</i>
          </span>
          <span v-else class="tab-number">
            {{ index + 1 }}
          </span>
          <span class="tab-title">{{ item.label }}</span>
        </div>

        <div class="flex-item">
          <span v-if="index != visibleTabs.length - 1" class="tab-arrow">
            <i class="material-icons dots">arrow_forward</i>
          </span>
        </div>
      </component>
    </ul>
  </div>
</template>

<script>
import orderingMappers from '@/modules/ordering/store/mappers'
import StringTab from './tabs/StringTab'
import { hasHadState, ORDER_STATES } from '@/modules/common/order-states'
import UnsavedStepChangesModal from '@/modules/ordering/components/shared/header/UnsavedStepChangesModal'
const planningTab = 'PLANNING'

export default {
  components: {
    StringTab,
    UnsavedStepChangesModal
  },
  props: {
    order: { type: Object, required: false, default: null }
  },
  data() {
    return {
      tabType: StringTab,
      showUnsavedChangesModal: false,
      tabWaitingToLoad: null
    }
  },
  computed: {
    ...orderingMappers.mapState(['visibleState', 'navItems', 'dirtyPlanningForm']),
    ...orderingMappers.mapGetters(['isJustOneTabVisible', 'visibleTabs'])
  },

  methods: {
    ...orderingMappers.mapActions([
      'showOrderStateView',
      'canGoToState',
      'fetchCompleteOrder',
      'setDirtyPlanningForm'
    ]),
    async goToTab(stateViewToShow) {
      if (!this.activeItemHasUnsavedWarning(stateViewToShow)) {
        this.switchToState(stateViewToShow)
      } else if (await this.canGoToState(stateViewToShow)) {
        this.showUnsavedChangesModal = true
        this.tabWaitingToLoad = stateViewToShow
      }
    },
    async switchToState(stateViewToShow) {
      if (await this.showOrderStateView(stateViewToShow)) {
        await this.fetchCompleteOrder(this.order.id)
      }
    },
    async confirmMethod() {
      if (this.visibleState === planningTab) {
        this.setDirtyPlanningForm(false)
      }

      this.showUnsavedChangesModal = false

      if (this.tabWaitingToLoad) {
        this.switchToState(this.tabWaitingToLoad)
        this.tabWaitingToLoad = null
      }
    },
    closeMethod() {
      this.tabWaitingToLoad = false
      this.showUnsavedChangesModal = false
    },
    activeItemHasUnsavedWarning(stateViewToShow) {
      if (
        stateViewToShow === this.visibleState ||
        !this.navItems.find(this.isNavItemActive)?.unsavedWarning
      ) {
        return false
      }

      return this.visibleState !== planningTab || this.dirtyPlanningForm
    },
    isNavItemActive(navItem) {
      return navItem.states.includes(this.visibleState)
    },
    // returns the states that navItem is going to achieve and will be considered
    // as completed if the order has had that states, i.e. one step after navItem.states
    navItemAchievingStates(navItem) {
      return navItem.states.reduce((result, state) => {
        result.push(ORDER_STATES[ORDER_STATES.indexOf(state) + 1])
        return result
      }, [])
    },
    isNavItemCompleted(navItem) {
      return this.navItemAchievingStates(navItem).every(state => {
        return hasHadState(this.order.state, state)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/modules/ordering/variables';

.state-tabs {
  display: flex;
  padding: 0;
  margin: 0;
  background: $color-layout-white;
  border-top: 1px solid rgba($color-layout-divider, 0.6);
  min-width: $ordering-content-min-width;
}

.state-tabs__item {
  width: 100%;
  height: $ordering-tabs-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $color-neutral-main;
  cursor: pointer;
  list-style-type: none;
  background-color: $color-layout-card-background;
  margin-right: 2px;
  padding: 10px 15px;
  box-shadow: 0 6px 6px -6px inset rgba(0, 0, 0, 0.19);

  .flex-item {
    display: inline-flex;
    word-wrap: normal;
    align-items: inherit;
  }

  .tab-number {
    color: $color-layout-white;
    background-color: $color-neutral-lighter;
    text-align: center;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 100%;
    margin-right: 6px;
    display: inline-block;
  }

  .tab-title {
    font-weight: bold;
    font-size: 18px;
    color: $color-neutral-lighter;
    line-height: 24px;
  }

  .tab-arrow {
    color: $color-neutral-lighter;
    position: relative;
    margin-left: 35px;

    .material-icons {
      vertical-align: bottom;

      &::before {
        content: '...';
        top: -11px;
        right: 21px;
        color: $color-neutral-lighter;
        position: absolute;
      }
    }
  }

  .check-icon {
    font-size: 16px;
    color: $color-layout-white;
    background-color: $color-neutral-lighter;
    padding: 4px;
    border-radius: 100%;
    margin-right: 6px;
  }

  &--active {
    background-color: $color-layout-white;
    border-bottom: none;
    color: $color-neutral-darkest;

    .tab-number {
      background-color: $color-primary-main;
    }

    .tab-title {
      color: $color-primary-main;
    }

    .check-icon {
      background-color: $color-primary-main;
    }

    .tab-arrow {
      color: $color-primary-main;

      .material-icons {
        &::before {
          color: $color-primary-main;
        }
      }
    }
  }

  &--alone {
    display: none;
  }
}
</style>

<script>
const PROVIDER_KEY = Symbol('AsyncActionContext')
export const provider = {
  from: PROVIDER_KEY,
  default: {
    isAnyActionRunning: () => false,
    setRunningAction: () => {}
  }
}

export default {
  provide() {
    return {
      [PROVIDER_KEY]: {
        setRunningAction: value => (this.actionRunning = value),
        isAnyActionRunning: () => this.actionRunning
      }
    }
  },
  data() {
    return { actionRunning: false }
  },
  render(createElement) {
    return createElement('fragment', this.$slots.default)
  }
}
</script>

import { ComputedRef, computed, reactive, set } from '@vue/composition-api'
import { FeatureFlagService } from '@/services/feature-flag-service'
import { VUE_APP_MOCKLAUNCHDARKLY, E2E_MODE, MICRO_FRONTEND_MODE } from '@/config'
import overrideMockedFlags from './overrideMockedFlags'
import sharedData from '../../core-config/orchestrated/shared-data'

let serviceSingleton: FeatureFlagService = null

export default (service?: FeatureFlagService) => {
  if (!serviceSingleton && !MICRO_FRONTEND_MODE) {
    if (!service)
      throw new Error(
        'useFeatureFlag error: No feature-flag-service instance was provided.'
      )
    serviceSingleton = service
  }

  const reactiveFeatureFlags = reactive({})

  const isActiveStatic = (flag: string): boolean =>
    serviceSingleton?.isActive(flag) ?? false

  const getJSONValueStatic = (flag: string): object =>
    serviceSingleton?.getJSONValue(flag) ?? {}

  const getJSONValue = (flag: string): ComputedRef<object> =>
    computed(() => {
      // Read shared data in case if it's a micro-frontend
      if (MICRO_FRONTEND_MODE) {
        return sharedData().featureFlags[flag] ?? {}
      }

      if (reactiveFeatureFlags[flag] === undefined) {
        addListener(flag)
        set(reactiveFeatureFlags, flag, getJSONValueStatic(flag))
      }

      return reactiveFeatureFlags[flag]
    })

  const isActive = (flag: string): ComputedRef<boolean> =>
    computed(() => {
      // Read shared data in case if it's a micro-frontend
      if (MICRO_FRONTEND_MODE) {
        return sharedData().featureFlags[flag] ?? false
      }

      // TODO: Move mocking logic out of this
      if (VUE_APP_MOCKLAUNCHDARKLY && !E2E_MODE) {
        return flag in overrideMockedFlags ? overrideMockedFlags[flag] : true
      }

      if (reactiveFeatureFlags[flag] === undefined) {
        addListener(flag)
        // We need to use explicit "set" method to add a new key to the reactive object
        set(reactiveFeatureFlags, flag, isActiveStatic(flag))
      }

      return reactiveFeatureFlags[flag]
    })

  const updateReactiveFeatureFlag = function (
    this: string, // flag name
    value: boolean,
    previous: boolean
  ): void {
    if (value !== previous) {
      reactiveFeatureFlags[this] = value
    }
  }

  const addListener = (flag: string): void => {
    serviceSingleton?.addListener(flag, updateReactiveFeatureFlag)
  }

  const removeListener = (flag: string): void => {
    serviceSingleton?.removeListener(flag, updateReactiveFeatureFlag)
  }

  return {
    isActive,
    getJSONValue,
    removeListener,
    async identifyAnonymous() {
      return await serviceSingleton.identifyAnonymous()
    }
  }
}

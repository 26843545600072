import cloneDeep from 'lodash/cloneDeep'
import identity from 'lodash/identity'
import { i18n } from '@/plugins/i18n'

const defaultEmptyValue = ''
const isEqual = (val1, val2) => JSON.stringify(val1) === JSON.stringify(val2)

/**
 * @typedef {undefined|null|string|boolean|Array} FilterValue
 * @typedef {?object<string, string>} UrlQueryFragment
 *
 * @callback HumanizeFilterValue
 * @param {FilterValue} value - filter value
 * @returns {string} - human readable representation
 */

/**
 * @param {object} data
 * @param {string} data.name - name for API/GraphQL key
 * @param {FilterValue} data.initialValue
 * @param {FilterValue} data.emptyValue
 * @param {string} data.urlParamName - name being used for URL and apply filter title
 * @param {string} data.updateName - name for getter/setter
 * @param {string} data.filterLabel - AppliedFilter's label
 * @param {HumanizeFilterValue} data.humanizeValue - Function that convert the filter value to a human readable representation
 * @returns {any} - vuex module instance
 */
export const createFilter = ({
  name,
  initialValue = defaultEmptyValue,
  emptyValue = defaultEmptyValue,
  urlParamName = name,
  updateName,
  filterLabel,
  humanizeValue = identity,
  esField = name,
  esFields = [],
  esHasPrefix = false,
  esWord = 'match',
  esPrefixFields = []
}) => {
  return {
    namespaced: true,
    state: {
      value: cloneDeep(initialValue),
      urlParamName
    },
    getters: {
      /**
       * @param {*} state
       * @param {*} getters
       * @returns {?object<string, FilterValue>} - a fragment of API payload object
       */
      getApiFragment: (state, getters) =>
        !getters.isFilterEmpty ? { [name]: state.value } : null,
      /**
       * @param {*} state
       * @param {*} getters
       * @returns {object} - data for AppliedFilters component
       */
      getAppliedValue: (state, getters) =>
        getters.isFilterEmpty
          ? null
          : {
              label: `${i18n.t(filterLabel)}: ${humanizeValue(state.value)}`,
              updateName,
              emptyValue
            },
      /**
       * @param {*} state
       * @param {*} getters
       * @returns {object} - data for AppliedFilters component
       */
      getAppliedValueForEs: (state, getters) =>
        getters.isFilterEmpty
          ? null
          : {
              identifier: name,
              value: state.value
            },
      /**
       * @param {*} state
       * @param {*} getters
       * @returns {?string} - a fragment of GraphQL payload object
       */
      getGraphqlValue: (state, getters) =>
        !getters.isFilterEmpty ? `${name}: ${JSON.stringify(state.value)}` : null,
      /**
       * @param {*} state
       * @param {*} getters
       * @returns {UrlQueryFragment} - a fragment of URL query object
       */
      getUrlQueryFragment: (state, getters) => {
        if (!getters.isFilterInitial) {
          return {
            [state.urlParamName]: !getters.isFilterEmpty ? String(state.value) : null
          }
        } else return null
      },
      getEsQueryFragment: (state, getters) => {
        if (getters.isFilterEmpty) {
          return []
        } else {
          if (esFields.length) {
            const esValues = []
            if (esHasPrefix) {
              for (const pref of esPrefixFields) {
                esValues.push({
                  prefix: {
                    [pref]: state.value
                  }
                })
              }
            }
            esValues.push(
              ...esFields.map(field => {
                return { match: { [field]: state.value } }
              })
            )

            return [
              {
                bool: {
                  [esWord]: esValues
                }
              }
            ]
          }
          return [{ [esWord]: { [esField]: state.value } }]
        }
      },
      /**
       * @param {*} state
       * @returns {boolean}
       */
      isFilterEmpty: state => isEqual(state.value, emptyValue),
      /**
       * @private
       * @param {*} state
       * @returns {boolean}
       */
      isFilterInitial: state => isEqual(state.value, initialValue)
    },
    mutations: {
      update: (state, value) => {
        state.value = cloneDeep(value)
      },
      reset: state => {
        state.value = cloneDeep(initialValue)
      }
    },
    actions: {
      /**
       * @param {*} store
       * @param {UrlQueryFragment} urlQueryFragment
       */
      setValueFromUrl({ state, commit }, urlQueryFragment) {
        const urlValue = urlQueryFragment[state.urlParamName]
        if (urlValue) commit('update', urlValue)
        else if (urlValue === null) commit('update', emptyValue)
      }
    }
  }
}

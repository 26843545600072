import { ActionTree, ActionContext, CommitOptions } from 'vuex'
import { ICommonState } from './state'
import { MutationsType } from './mutations'
import { GettersType } from './getters'
import { Currency } from '../models'
import { fetchExchangeRate } from '@/services/currency-service'

type CommonActionContext<S = ICommonState> = Omit<
  ActionContext<S, never>,
  'getters' | 'commit' | 'dispatch'
> & {
  commit<K extends keyof MutationsType, P extends Parameters<MutationsType[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<MutationsType[K]>
} & {
  dispatch<K extends keyof ActionsType>(
    key: K,
    payload: Parameters<ActionsType[K]>[1],
    options?: ActionsType
  ): ReturnType<ActionsType[K]>
} & {
  getters: {
    [K in keyof GettersType]: ReturnType<GettersType[K]>
  }
}

type PartialActionContextOfCommonState = Partial<CommonActionContext>

export type UpdateExchangeRatesAction = (
  { commit }: PartialActionContextOfCommonState,
  currency: Currency
) => Promise<void>
export const updateExchangeRate: UpdateExchangeRatesAction = async (
  { commit },
  currency
) => {
  const response = await fetchExchangeRate(currency)
  commit('setExchangeRate', { currency, rate: response })
}

export const Actions = Object.freeze({
  updateExchangeRate: 'updateExchangeRate'
})

export type ActionsType = {
  updateExchangeRate: UpdateExchangeRatesAction
}

export const actions: ActionTree<ICommonState, never> & ActionsType = {
  updateExchangeRate
}

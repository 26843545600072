import { onMounted, ref } from '@vue/composition-api'
const fetchDataOnMounted = (fetchCallbacks: Array<() => void> = []) => {
  const loading = ref(true)
  onMounted(async () => {
    try {
      await Promise.all(fetchCallbacks.map(cb => cb()))
    } finally {
      loading.value = false
    }
  })

  return loading
}

export default fetchDataOnMounted

export default {
  EDIT_ORDER_MODAL_OPENED: 'Ordering: Edit order modal opened',
  CLONE_ORDER: 'Ordering: Clone order',
  CANCEL_ORDER_MODAL_OPENED: 'Ordering: Cancel order modal opened',
  TOGGLE_COMMENTS_AND_ACTIVITY: 'Ordering: Toggle comments and activity',
  GO_TO_FINANCE: 'Ordering: Switch to finance view',
  OPENED_CUSTOMER_TOOLTIP: 'Ordering Header: Customer tooltip opened',
  CUSTOMER_TOOLTIP_CLICK_EVENT:
    'Ordering Header: User clicked on email or phone number inside customer tooltip'
}
































































































































































































































import { formatCurrency } from '@sennder/design-system-core'
import { IncidentCounter } from '@sennder/incident-management'
import OrderHeaderActions from './OrderHeaderActions.vue'
import LabeledText from '@/modules/common/components/LabeledText.vue'
import CostSuggestions from '@/microfrontends/pages/bidding/CostSuggestions.vue'
import OrderStateChip from './OrderStateChip.vue'
import { expandSidebar } from '@/modules/order-activity-sidebar'
import { defineComponent, computed, ref, PropType } from '@vue/composition-api'
import { formatMoneyWithDashForNull } from '@/global-setup/filters'
import { OrderDetail, OrderStop } from '@/services'
import { trackEvent } from '@/analytics/amplitude'
import EVENTS from './events'
import { Currency } from '@/modules/common'
import useStore from '@/compositions/useStore'
import { monitorError } from '@/analytics/monitoring'
import SpotterLinkWidget from '@/microfrontends/widgets/spotter-link-widget/Spotter.vue'
import useFeatureFlag from '@/compositions/useFeatureFlag'

interface RouteSummary {
  fullRoute: string
  firstStop: string
  lastStop: string
  extraCount: number
}

const SPOTTER_WIDGET_MODE = 'create_link_widget_only'
const SPOTTER_INTEGRATION_SOURCE = 'ordering'

export default defineComponent({
  components: {
    LabeledText,
    OrderHeaderActions,
    OrderStateChip,
    IncidentCounter,
    CostSuggestions,
    SpotterLinkWidget
  },
  props: {
    order: { type: Object as PropType<OrderDetail>, required: true },
    stops: { type: Array as PropType<OrderStop[]>, required: true },
    routeSummary: { type: Object as PropType<RouteSummary>, required: true },
    subContractingPolicy: { type: String, required: false, default: null },
    referenceNumber: { type: String, default: '' },
    trackingIdForShipper: { type: String, default: '' },
    regularity: { type: String, default: '' },
    shipperSensitivity: { type: String, default: null },
    shipmentId: { type: String, default: '' }
  },
  setup(props) {
    const store = useStore()

    const { isActive } = useFeatureFlag()

    const exchangeRate = computed(() => {
      return store.getters['common/currencyExchangeRate'](Currency.EUR)
    })

    const incidentCounter = ref<{ fetchIncidentsArray: () => void } | null>(null)
    const isTooltipVisible = ref(false)

    const getRegularityInTitleCase = computed<string>(() => {
      if (props.regularity) {
        return props.regularity[0].toUpperCase() + props.regularity.slice(1).toLowerCase()
      }
      return '-'
    })

    const getLinkOpportunityWidgetVisibility = computed<boolean>(() => {
      return ['SPOT', 'SCALED_SPOT'].includes(props.order.regularity)
    })

    const hideCustomerInfo = isActive('HIDE_CUSTOMER_INFO_ORDER_HEADER').value

    const onShowEvent = () => {
      isTooltipVisible.value = true
      setTimeout(function () {
        if (isTooltipVisible.value == true) {
          trackEvent(EVENTS.OPENED_CUSTOMER_TOOLTIP, {})
        }
      }, 500)
    }

    const trackTooltipClick = (source: string) => {
      trackEvent(EVENTS.CUSTOMER_TOOLTIP_CLICK_EVENT, { source })
    }

    const marginPercentage = computed<string>(() => {
      const sign = Math.sign(props.order.marginPercentage) > 0 ? '+' : ''
      return props.order.marginPercentage || props.order.marginPercentage === 0
        ? `${sign}${Math.round(props.order.marginPercentage)}%`
        : '-'
    })

    const marginPercentageClass = computed<string>(() => {
      const percentage = props.order.marginPercentage
      const roundedPercentage = Math.round(percentage)
      const ranges = [
        { condition: percentage === null, class: '' },
        { condition: roundedPercentage >= 5, class: 'margin-ok' },
        { condition: roundedPercentage > 0, class: 'margin-warning' },
        { condition: roundedPercentage <= 0, class: 'margin-error' }
      ]
      return ranges.find(range => range.condition === true)?.class || ''
    })

    const formattedTrackingIdForShipper = computed<string>(() => {
      const id = props.trackingIdForShipper
      if (id) {
        return id.length > 6 ? `${id.slice(0, 6)}...` : id
      }
      return '-'
    })

    const openCommentActivity = (): void => expandSidebar()

    const fetchCompleteOrder = async (): Promise<void> => {
      await store.dispatch('ordering/fetchCompleteOrder', props.order.id)
    }

    const updateCounter = (): void => {
      if (!incidentCounter.value)
        throw Error('[Incident counter] The component not found')
      incidentCounter.value.fetchIncidentsArray?.()
    }

    const isCostConverted = computed<boolean>(() => {
      return (
        !!props.order.carrierPaymentCurrency &&
        props.order.carrierPaymentCurrency !== Currency.EUR
      )
    })

    const costInCarrierCurrency = computed<string | null>(() => {
      /**
       * Do nothing if order is not converted or doesn't have a cost yet.
       */
      if (
        !isCostConverted.value ||
        !props.order.totalNetCost ||
        !props.order.carrierPaymentCurrency
      )
        return null

      /**
       * This is not expected.
       */
      if (
        !exchangeRate.value ||
        !exchangeRate.value.rates[props.order.carrierPaymentCurrency]
      ) {
        monitorError(new Error('Exchange rate was not found when it was required'))

        return null
      }

      return formatCurrency(
        props.order.totalNetCost *
          exchangeRate.value.rates[props.order.carrierPaymentCurrency],
        props.order.carrierPaymentCurrency
      )
    })

    return {
      incidentCounter,
      getRegularityInTitleCase,
      marginPercentage,
      marginPercentageClass,
      formattedTrackingIdForShipper,
      openCommentActivity,
      updateCounter,
      formatMoneyWithDashForNull,
      onShowEvent,
      isTooltipVisible,
      trackTooltipClick,
      isCostConverted,
      costInCarrierCurrency,
      fetchCompleteOrder,
      getLinkOpportunityWidgetVisibility,
      SPOTTER_WIDGET_MODE,
      SPOTTER_INTEGRATION_SOURCE,
      hideCustomerInfo
    }
  }
})

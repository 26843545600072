import { isRef, isReactive } from '@vue/composition-api'
import isPlainObject from 'lodash/isPlainObject'
import isArray from 'lodash/isArray'

/**
 * Composition function to reset the state of multiple compositions to their initial values.
 * @param {Object|Array} compositions
 * @return {Function} resetState - callback to reset the state of the compositions received as parameter.
 */
export default compositions => {
  const resetValueCallbacks = []
  const createResetRefDataCallback = refObject => {
    const initialValue = refObject.value
    return () => (refObject.value = initialValue)
  }
  const createResetReactiveDataCallback = reactiveObject => {
    const initialObjectValue = Object.keys(reactiveObject).reduce(
      (acc, key) => ({ ...acc, [key]: reactiveObject[key] }),
      {}
    )
    return () => {
      Object.entries(initialObjectValue).forEach(
        ([key, initialValue]) => (reactiveObject[key] = initialValue)
      )
    }
  }

  const processValue = data => {
    if (isPlainObject(data)) processObject(data)
    else if (isArray(data)) processArray(data)

    if (isReactive(data)) resetValueCallbacks.push(createResetReactiveDataCallback(data))
    else if (isRef(data)) resetValueCallbacks.push(createResetRefDataCallback(data))
  }
  const processArray = array => array.forEach(processValue)
  const processObject = obj => Object.values(obj).forEach(processValue)

  processValue(compositions)

  return () => {
    resetValueCallbacks.forEach(callback => callback())
  }
}

import { OPERATIONS_BACKEND_URL } from '@/config'
import { runJsonPost } from '@/models/backend-client'
import { buildTransportRequestsQuery } from './transport-requests-query'
import TransportRequest from '@/models/transport-request'
import usePersons from '@/compositions/usePersons'

export const fetchRequestBoardData = async store => {
  let newTransportRequests = []
  store.commit('startLoadingPage', null, { root: true })
  store.commit('flush', { Model: TransportRequest }, { root: true })
  const filters = store.getters['filters/generateGraphqlString']
  const query = buildTransportRequestsQuery(filters)
  try {
    // TODO: replace graphQL with a REST API and get rid of store.rootState.transport_requests
    newTransportRequests = await store.dispatch('fetch', { query }, { root: true })

    if (
      newTransportRequests.persons &&
      Object.keys(newTransportRequests.persons).length > 0
    ) {
      const { fetchPersons } = usePersons()
      await fetchPersons(Object.keys(newTransportRequests.persons))
    }

    store.commit(
      'setTransportRequests',
      Object.values(store.rootState.transport_requests)
    )
  } finally {
    store.commit('finishLoadingPage', null, { root: true })
  }
}

export const rejectRequest = async (store, { requestId, reason, note }) => {
  await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/orders-matching/transport_request_actions`,
    {
      command: 'reject',
      data: {
        transport_request_id: requestId,
        rejection_reason: reason,
        rejection_note: note
      }
    }
  )
  store.commit('removeTransportRequest', requestId)
}

export const acceptRequest = async (store, requestId) => {
  await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/orders-matching/transport_request_actions`,
    { command: 'accept', data: { transport_request_id: requestId } }
  )
  store.commit('removeTransportRequest', requestId)
}

import { transformKeysToCamelCase } from '@/services/utils/casing'

/**
 * Definition of headers for GraphQL
 */
export interface GraphQLHeaders {
  [key: string]: string
}

/**
 * Definition of variables for GraphQL
 */
export interface GraphQLVariables {
  [key: string]: number | number[] | string | string[] | boolean | boolean[]
}

/**
 * Execute a query against a GraphQL endpoint
 *
 * @param query query to execute
 * @param variables query variables
 * @returns {Promise<T>} with the data or throws an error if errors are sent back
 */
export async function query<T>(
  endpoint: string,
  headers: GraphQLHeaders,
  query: string,
  variables: GraphQLVariables
): Promise<T> {
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    body: JSON.stringify({ query, variables })
  })

  const json = await response.json()

  if (json.errors) {
    throw new Error(json.errors[0]?.message)
  }

  return transformKeysToCamelCase<T>(json.data)
}

import { ICarrierProfileClient, carrierProfileClient } from './carrier-profile-client'

import { Contact, ContactResponse, mapContactResponseToContact } from '../models'

export interface ICarrierProfileService {
  getCarrierContacts: (carrierId: string) => Promise<Contact[]>
}

export class CarrierProfileService implements ICarrierProfileService {
  constructor(private client: ICarrierProfileClient) {}

  public getCarrierContacts = async (carrierId: string): Promise<Contact[]> => {
    const firstPage = await this.client.getContacts(carrierId)

    const numberOfPages = Math.ceil(firstPage.size / firstPage.limit)

    const otherPages = await (numberOfPages > 1
      ? Promise.all(
          // eslint-disable-next-line prefer-spread
          Array.apply(null, Array(numberOfPages - 1)).map((_, index) =>
            this.client.getContacts(carrierId, firstPage.limit * (index + 1))
          )
        )
      : Promise.resolve([]))

    const allResults = [
      ...firstPage.results,
      ...otherPages.reduce((all, x) => [...all, ...x.results], [] as ContactResponse[])
    ]

    return allResults.map(mapContactResponseToContact)
  }
}

export const carrierProfileService = new CarrierProfileService(carrierProfileClient)

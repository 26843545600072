
































































































































import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import StepStatus from './StepStatus.vue'
import {
  computed,
  ref,
  watch,
  inject,
  defineComponent,
  PropType
} from '@vue/composition-api'
import { Timestamp, TIME_FORMAT } from '@sennder/plankton'
import { TransferStep } from '@/services'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import { FormFieldValidators } from '@/modules/ordering/types'
import { getTimezoneAcronym } from '@/global-setup/filters'
import useTooltipManagment from '@/compositions/useTooltipManagment'
import bus from '@/bus'

export default defineComponent({
  components: {
    StepStatus
  },
  props: {
    stopId: { type: Number, required: true },
    arrival: { type: Object as PropType<TransferStep>, required: true },
    departure: { type: Object as PropType<TransferStep>, required: true },
    isOrderExecuted: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    const isOpsOrDirectDispatcher = inject('isOpsOrDirectDispatcher', true)

    const isDatetimeFieldDisabled = inject('isDatetimeFieldDisabled', false)

    const asyncActionInProgress = inject('asyncActionInProgress', false)

    const arrivalActualTime = computed<Timestamp>(
      () => new Timestamp(props.arrival.actualTime, props.arrival.timezone)
    )

    const departureActualTime = computed<Timestamp>(
      () => new Timestamp(props.departure.actualTime, props.departure.timezone)
    )

    const arrivalSheduledTime = new Timestamp(
      props.arrival.scheduledTime,
      props.arrival.timezone
    ).date

    const arrivalDate = ref<string>(arrivalActualTime.value.date)
    const arrivalTime = ref<string>(arrivalActualTime.value.time)
    const departureDate = ref<string>(departureActualTime.value.date)
    const departureTime = ref<string>(departureActualTime.value.time)
    const isShowAcronymDeparture = ref<boolean>(false)
    const isShowAcronymArrival = ref<boolean>(false)
    const isShowAcronymEta = ref<boolean>(false)
    const arrivalTimezoneAcronym = ref<string>('')
    const departureTimezoneAcronym = ref<string>('')
    const etaTimezoneAcronym = ref<string>('')

    const {
      isShowArrivalTooltip,
      isShowDepartureTooltip,
      hideArrivalTooltip,
      showArrivalTooltip,
      hideDepartureTooltip,
      showDepartureTooltip
    } = useTooltipManagment()

    // update input values while receiving new BE data
    watch(
      () => props.arrival,
      newValue => {
        if (!newValue.hasUnsavedActualTime) {
          // if props data come from the backend
          arrivalDate.value = arrivalActualTime.value.date
          arrivalTime.value = arrivalActualTime.value.time
        }
      }
    )

    watch(
      () => props.departure,
      newValue => {
        if (!newValue.hasUnsavedActualTime) {
          departureDate.value = departureActualTime.value.date
          departureTime.value = departureActualTime.value.time
        }
      }
    )

    const dateTimeToTimestamp = (
      date: string,
      time: string,
      timezone: string
    ): Timestamp => {
      const timestamp = new Timestamp(null)
      timestamp.timezone = timezone
      timestamp.date = date
      timestamp.time = time
      return timestamp
    }

    const setArrivalTimezoneAcronym = (date?) => {
      const dateTimeData = dateTimeToTimestamp(
        date ?? arrivalDate.value,
        '00:00',
        arrivalActualTime.value.timezone
      )
      isShowAcronymArrival.value = dateTimeData.isValid
      arrivalTimezoneAcronym.value = getTimezoneAcronym(
        dateTimeData.isoTimeStamp,
        arrivalActualTime.value.timezone
      )
    }

    const setDepartureTimezoneAcronym = (date?) => {
      const dateTimeData = dateTimeToTimestamp(
        date ?? departureDate.value,
        '00:00',
        departureActualTime.value.timezone
      )
      isShowAcronymDeparture.value = dateTimeData.isValid
      departureTimezoneAcronym.value = getTimezoneAcronym(
        dateTimeData.isoTimeStamp,
        departureActualTime.value.timezone
      )
    }

    const arrivalDateHandler = computed<string>({
      get: () => {
        setArrivalTimezoneAcronym()
        return arrivalDate.value
      },
      set: newDate => {
        arrivalDate.value = newDate
        const timestamp = dateTimeToTimestamp(
          newDate,
          arrivalTime.value,
          arrivalActualTime.value.timezone
        )
        setArrivalTimezoneAcronym(newDate)
        emit('updateStepDatetime', {
          timestamp: timestamp.isoTimeStamp ?? null,
          type: 'arrival'
        })
      }
    })

    const arrivalTimeHandler = computed<string>({
      get: () => arrivalTime.value,
      set: newTime => {
        arrivalTime.value = newTime
        const timestamp = dateTimeToTimestamp(
          arrivalDate.value,
          newTime,
          arrivalActualTime.value.timezone
        )
        emit('updateStepDatetime', {
          timestamp: timestamp.isoTimeStamp ?? null,
          type: 'arrival'
        })
      }
    })

    const isDirtyArrivalTimestamp = ref(false)
    const isDirtyDepartureTimestamp = ref(false)

    let originalArrivalTimestamp = dateTimeToTimestamp(
      arrivalDate.value,
      arrivalTime.value,
      arrivalActualTime.value.timezone
    )

    let originalDepartureTimestamp = dateTimeToTimestamp(
      departureDate.value,
      departureTime.value,
      departureActualTime.value.timezone
    )

    watch(
      [arrivalDateHandler, arrivalTimeHandler],
      ([newArrivalDate, newArrivalTime]) => {
        isDirtyArrivalTimestamp.value =
          originalArrivalTimestamp.isoTimeStamp !=
          dateTimeToTimestamp(
            newArrivalDate,
            newArrivalTime,
            arrivalActualTime.value.timezone
          ).isoTimeStamp
        emit('updateIsDirty', {
          isDirty: isDirtyArrivalTimestamp.value,
          type: 'arrival'
        })
      }
    )

    const departureDateHandler = computed<string>({
      get: () => {
        setDepartureTimezoneAcronym()
        return departureDate.value
      },
      set: newDate => {
        departureDate.value = newDate
        const timestamp = dateTimeToTimestamp(
          newDate,
          departureTime.value,
          departureActualTime.value.timezone
        )
        setDepartureTimezoneAcronym(newDate)
        emit('updateStepDatetime', {
          timestamp: timestamp.isoTimeStamp ?? null,
          type: 'departure'
        })
      }
    })

    const departureTimeHandler = computed<string>({
      get: () => departureTime.value,
      set: newTime => {
        departureTime.value = newTime
        const timestamp = dateTimeToTimestamp(
          departureDate.value,
          newTime,
          departureActualTime.value.timezone
        )
        emit('updateStepDatetime', {
          timestamp: timestamp.isoTimeStamp ?? null,
          type: 'departure'
        })
      }
    })

    watch(
      [departureDateHandler, departureTimeHandler],
      ([newDepartureDate, newDepartureTime]) => {
        isDirtyDepartureTimestamp.value =
          originalDepartureTimestamp.isoTimeStamp !=
          dateTimeToTimestamp(
            newDepartureDate,
            newDepartureTime,
            departureActualTime.value.timezone
          ).isoTimeStamp
        emit('updateIsDirty', {
          isDirty: isDirtyDepartureTimestamp.value,
          type: 'departure'
        })
      }
    )

    const isArrivalDateInvalid = computed<boolean>(() => {
      const inputArrivalDateTime = dateTimeToTimestamp(
        arrivalDate.value,
        moment(arrivalTime.value, TIME_FORMAT, true).isValid()
          ? arrivalTime.value
          : '00:00',
        arrivalActualTime.value.timezone
      )
      const inputDateTime = moment(inputArrivalDateTime.isoTimeStamp)
      const scheduledDateTime = moment(props.departure.scheduledTime)
      if (inputArrivalDateTime.isValid) {
        return inputDateTime.isAfter(scheduledDateTime, 'days')
      }
      return Boolean(arrivalDate.value)
    })

    const isArrivalTimeInvalid = computed<boolean>(() => {
      const inputArrivalDateTime = dateTimeToTimestamp(
        arrivalDate.value,
        arrivalTime.value,
        arrivalActualTime.value.timezone
      )
      const inputDateTime = moment(inputArrivalDateTime.isoTimeStamp)
      const scheduledDateTime = moment(props.departure.scheduledTime)
      if (inputArrivalDateTime.isTimeValid) {
        return inputDateTime.isAfter(scheduledDateTime)
      }
      return Boolean(arrivalTime.value)
    })

    const isArrivalDoneHandler = computed<boolean>({
      get: () => props.arrival.isDone,
      set: () => {
        emit('markStepAsDone', 'arrival')
      }
    })

    const isDepartureDoneHandler = computed<boolean>({
      get: () => props.departure.isDone,
      set: () => {
        emit('markStepAsDone', 'departure')
      }
    })

    const dateValidators: FormFieldValidators<Date | null> = computed(() => {
      if (isExecutionTimestampFlagActive.value) {
        return [
          { isInvalid: isEmpty, errorMessage: 'Date cannot be empty', inline: true }
        ]
      }
      return []
    })

    const timeValidators: FormFieldValidators<string> = computed(() => {
      if (isExecutionTimestampFlagActive.value) {
        return [
          { isInvalid: isEmpty, errorMessage: 'Time cannot be empty', inline: true }
        ]
      }
      return []
    })

    const { isActive } = useFeatureFlag()
    const isExecutionTimestampFlagActive = isActive('execution-mandatory-timestamps')

    bus.on('transfer-steps-actual-times-updated', () => {
      originalArrivalTimestamp = dateTimeToTimestamp(
        arrivalDate.value,
        arrivalTime.value,
        arrivalActualTime.value.timezone
      )
      originalDepartureTimestamp = dateTimeToTimestamp(
        departureDate.value,
        departureTime.value,
        departureActualTime.value.timezone
      )
    })

    return {
      isOpsOrDirectDispatcher,
      isArrivalDateInvalid,
      isArrivalTimeInvalid,
      isArrivalDoneHandler,
      isDepartureDoneHandler,
      arrivalDateHandler,
      arrivalTimeHandler,
      departureDateHandler,
      departureTimeHandler,
      arrivalSheduledTime,
      dateValidators,
      timeValidators,
      isShowAcronymArrival,
      isShowAcronymDeparture,
      isShowAcronymEta,
      arrivalTimezoneAcronym,
      departureTimezoneAcronym,
      etaTimezoneAcronym,
      isShowArrivalTooltip,
      isShowDepartureTooltip,
      hideArrivalTooltip,
      showArrivalTooltip,
      hideDepartureTooltip,
      showDepartureTooltip,
      isDatetimeFieldDisabled,
      isDirtyArrivalTimestamp,
      originalArrivalTimestamp,
      originalDepartureTimestamp,
      asyncActionInProgress
    }
  }
})

<template>
  <BoardTableRow :types="orderTypes">
    <td>
      <Checkbox
        :checked="Boolean(order.selected)"
        data-test="order-select-checkbox"
        @input="toggleChecked"
      />
    </td>

    <td data-test="link-cell">
      <router-link :to="orderStageLink" data-test="row">
        {{ normalizedOrder.id_for_staff }}
      </router-link>
    </td>

    <td>
      <ReferenceCell :title="normalizedOrder.reference_number">
        {{ normalizedOrder.customer_ref }}
      </ReferenceCell>
    </td>

    <td data-test="company-cell">
      <CompanyCell :title="normalizedOrder.customer_name">
        {{ normalizedOrder.customer_name }}
      </CompanyCell>
    </td>

    <td class="cell-border-right" data-test="carrier-cell">
      <span :title="normalizedOrder.carrier_name">
        {{ normalizedOrder.carrier_name }}
      </span>
    </td>

    <td>
      <span :title="normalizedOrder.origin">{{ normalizedOrder.origin }}</span>
    </td>

    <td>
      <span :title="normalizedOrder.destination">
        {{ normalizedOrder.destination }}
      </span>
    </td>

    <td class="cell-border-right" data-test="pickup-start-column">
      <DatetimeCell>{{ normalizedOrder.pick_up_date }}</DatetimeCell>
    </td>

    <td>
      <div data-test="base-price-cell" class="cell-icon">
        <i class="material-icons">person</i>
        <div>{{ normalizedOrder.base_price }}</div>
      </div>
      <div data-test="base-cost-cell" class="cell-icon">
        <i class="material-icons">local_shipping</i>
        <div>{{ normalizedOrder.base_cost }}</div>
      </div>
    </td>

    <td>
      <div data-test="customer-surcharge-cell" class="cell-icon">
        <i class="material-icons">person</i>
        <div>
          {{ normalizedOrder.customer_surcharges }}
        </div>
      </div>
      <div data-test="carrier-surcharge-cell" class="cell-icon">
        <i class="material-icons">local_shipping</i>
        <div>
          {{ normalizedOrder.carrier_surcharges }}
        </div>
      </div>
    </td>

    <td>
      <div data-test="customer-extra-charge-cell" class="cell-icon">
        <i class="material-icons">person</i>
        <div>
          {{ normalizedOrder.customer_extra_charges }}
        </div>
      </div>
      <div data-test="carrier-extra-charge-cell" class="cell-icon">
        <i class="material-icons">local_shipping</i>
        <div>
          {{ normalizedOrder.carrier_extra_charges }}
        </div>
      </div>
    </td>

    <td>
      <div data-test="total-net-price-cell" class="cell-icon">
        <i class="material-icons">person</i>
        <div>{{ normalizedOrder.total_net_price }}</div>
      </div>
      <div data-test="total-net-cost-cell" class="cell-icon">
        <i class="material-icons">local_shipping</i>
        <div>{{ normalizedOrder.total_net_cost }}</div>
      </div>
    </td>

    <td>
      <div data-test="customer-vat-cell" class="cell-icon">
        <i class="material-icons">person</i>
        <div>{{ normalizedOrder.customer_vat }}</div>
      </div>
      <div data-test="carrier-vat-cell" class="cell-icon">
        <i class="material-icons">local_shipping</i>
        <div>{{ normalizedOrder.carrier_vat }}</div>
      </div>
    </td>

    <td data-test="discount-percentage-cell">
      <div class="cell-icon">&nbsp;</div>
      <div class="cell-icon">
        <i class="material-icons">local_shipping</i>
        <span>{{ normalizedOrder.discount }} %</span>
      </div>
    </td>
    <td>
      <div data-test="total-gross-price-cell" class="cell-icon">
        <i class="material-icons">person</i>
        <div>{{ normalizedOrder.total_gross_price }}</div>
      </div>
      <div data-test="total-gross-cost-cell" class="cell-icon">
        <i class="material-icons">local_shipping</i>
        <div>{{ normalizedOrder.total_gross_cost }}</div>
      </div>
    </td>

    <td>
      <div class="cell-icon" title="Customer is billed">
        <div v-if="normalizedOrder.is_customer_billed">
          <i class="material-icons">person</i>
          <i class="material-icons">check_circle</i>
        </div>
        <div v-else>
          <i class="material-icons">person</i>
          <i class="material-icons">cancel</i>
        </div>
      </div>
      <div
        :title="
          normalizedOrder.credit_note_was_sent
            ? $t('finance-board/finance-order-row-credit-note-sent')
            : $t('finance-board/finance-order-row-credit-note-not-sent')
        "
        class="cell-icon"
      >
        <div v-if="normalizedOrder.credit_note_was_sent">
          <i class="material-icons">local_shipping</i>
          <i class="material-icons">check_circle</i>
        </div>
        <div v-else>
          <i class="material-icons">local_shipping</i>
          <i class="material-icons">cancel</i>
        </div>
      </div>
    </td>
  </BoardTableRow>
</template>

<script>
import { BoardTableRow, ReferenceCell, DatetimeCell, CompanyCell } from '@/modules/boards'
import { formatMoneyWithDashForNull } from '@/global-setup/filters'
import { addressShortFormat } from '@/models/address'

export default {
  components: {
    BoardTableRow,
    ReferenceCell,
    DatetimeCell,
    CompanyCell
  },
  filters: {
    addressShortFormat
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    canUserAccessFinanceSection: {
      type: Boolean,
      required: true
    },
    canUseCarrierTimesFeature: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    normalizedOrder() {
      return {
        ...this.order,
        customer_name: this.order.customer_name || '-',
        destination: this.order.destination || '-',
        pick_up_date:
          (this.canUseCarrierTimesFeature
            ? this.order.carrier_pick_up_date
            : this.order.pick_up_date) || '-',
        base_price: formatMoneyWithDashForNull(this.order.base_price),
        base_cost: formatMoneyWithDashForNull(this.order.base_cost),
        customer_surcharges: formatMoneyWithDashForNull(this.order.customer_surcharges),
        carrier_surcharges: formatMoneyWithDashForNull(this.order.carrier_surcharges),
        customer_extra_charges: formatMoneyWithDashForNull(
          this.order.customer_extra_charges
        ),
        carrier_extra_charges: formatMoneyWithDashForNull(
          this.order.carrier_extra_charges
        ),
        total_net_price: formatMoneyWithDashForNull(this.order.total_net_price),
        total_net_cost: formatMoneyWithDashForNull(this.order.total_net_cost),
        customer_vat: formatMoneyWithDashForNull(this.order.customer_vat),
        carrier_vat: formatMoneyWithDashForNull(this.order.carrier_vat),
        total_gross_price: formatMoneyWithDashForNull(this.order.total_gross_price),
        total_gross_cost: formatMoneyWithDashForNull(this.order.total_gross_cost),
        ops: !this.order.ops ? '-' : this.order.ops,
        origin: !this.order.origin ? '-' : this.order.origin,
        discount: !this.order.discount ? '-' : this.order.discount
      }
    },
    orderStageLink() {
      return `/finance/ordering/${this.order.id_for_staff}`
    },
    orderTypes() {
      let types = []
      if (this.order.is_cancelled_in_register_state) types.push('disabled')
      else if (this.order.is_cancelled) types.push('danger')
      if (this.order.selected) types.push('warning')
      return types
    },
    orderGroupIdAndLetter() {
      return this.order.id_for_staff.match(/[a-z]+|[^a-z]+/gi)
    }
  },
  methods: {
    toggleChecked() {
      this.$emit('toggleChecked', this.order.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.cell-icon {
  display: flex;
  align-items: center;

  .material-icons {
    font-size: 14px;
    margin-right: 3px;
  }
}
</style>

import Model from './model'
import { OPERATIONS_BACKEND_URL } from '../config'

export default class Contact extends Model {
  static get NAME() {
    return 'contacts'
  }
  static get URL() {
    return `${OPERATIONS_BACKEND_URL}/ordering/contacts/`
  }

  get formatted() {
    const first_name = this.first_name || ''
    return `${first_name} ${this.last_name}`
  }
}

<template>
  <DropdownField
    v-bind="attrs"
    :options="languages"
    optionLabel="label"
    optionKey="value"
    v-on="listeners"
  />
</template>
<script>
import { DropdownField } from '@sennder/plankton'
export default {
  model: DropdownField.model,
  computed: {
    attrs() {
      const value = this.$attrs.value
      return {
        ...this.$attrs,
        value: value ? this.findSelectedOption(value) : value
      }
    },
    listeners() {
      return {
        ...this.$listeners,
        select: option => this.$emit('select', option?.value || null)
      }
    }
  },
  created() {
    this.languages = [
      { label: this.$t('components/language-dropdown-field-languange-en'), value: 'EN' },
      { label: this.$t('components/language-dropdown-field-languange-fr'), value: 'FR' },
      { label: this.$t('components/language-dropdown-field-languange-de'), value: 'DE' },
      { label: this.$t('components/language-dropdown-field-languange-it'), value: 'IT' },
      { label: this.$t('components/language-dropdown-field-languange-pl'), value: 'PL' },
      { label: this.$t('components/language-dropdown-field-languange-es'), value: 'ES' }
    ]
  },
  methods: {
    findSelectedOption(value) {
      return this.languages.find(option => option.value === value)
    }
  }
}
</script>

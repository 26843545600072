<template>
  <TypeAheadField
    :options="options"
    v-bind="$attrs"
    optionLabel="name"
    optionKey="id"
    :internalSearch="internalSearch"
    :title="title"
    openDirection="top"
    v-on="$listeners"
    @fetchFilterOptions="onFetchFilterOptions"
    @clearFilterOptions="onClearFilterOptions"
  />
</template>
<script>
import { fetchTeamsByName } from '@/services/team-management-service'

export default {
  props: {
    title: { type: String, required: true }
  },
  data() {
    return { options: [], internalSearch: false }
  },
  methods: {
    async onFetchFilterOptions(query) {
      this.options = await fetchTeamsByName(query)
    },
    onClearFilterOptions() {
      this.options = []
    }
  }
}
</script>

import { ref, computed } from '@vue/composition-api'
import { runJsonGet, runJsonPost } from '@/models/backend-client'
import * as USER_GROUPS from '@/modules/auth/user-groups'
import { setCookieValue } from '@/modules/auth/useUserId'
import * as USER_PERMISSIONS from '@/components/user-permissions'
import { OPERATIONS_BACKEND_URL, USER_MANAGEMENT_API_URL } from '@/config'
import { transformKeysToCamelCase } from '@/services/utils/casing'
import { Locale } from '../plugins/types'
import { getInstance } from '@sennder/auth'
import { AxiosResponse } from 'axios'
import { getUserIdFromToken } from '@/microfrontends/utils'
import { getAuthHeader } from '@/services/auth-service'

type UserGroupKey = keyof typeof USER_GROUPS
type UserGroup = typeof USER_GROUPS[UserGroupKey]
export type Permission = typeof USER_PERMISSIONS[keyof typeof USER_PERMISSIONS]

export interface User {
  id: number
  language?: Locale
  groupNames: UserGroup[]
  permissions: Permission[]
}

type CanSeeUploadPermission =
  | 'canUseNewUpload'
  | 'canUseOrderTemplateGeneration'
  | 'canUseUploadAmazonCSV'
  | 'canUseGeneralCsvUpload'

const user = ref<User>(null)

export default function useCurrentUser() {
  const loadCurrentUser = async (sharedUser?: User): Promise<void> => {
    /**
     * If user is passed from the shell, use it instead of remote data
     */
    const response = sharedUser
      ? {
          data: sharedUser
        }
      : await runJsonGet(`${OPERATIONS_BACKEND_URL}/accounts/users/me`)

    const auth0Instance = getInstance()
    const isAuthenticated = await auth0Instance?.auth0Client?.isAuthenticated()
    let auth0Permissions: Array<string> = []
    const auth0PermissionsFromStorage = JSON.parse(
      sessionStorage.getItem('auth0Permissions')
    )

    /**
     * We can safely ignore auth status if we're loading from the shell
     */
    if (isAuthenticated || !!sharedUser) {
      if (!auth0PermissionsFromStorage) {
        try {
          const permissions = (await runJsonGet(
            `${USER_MANAGEMENT_API_URL}/api/permissions/my`,
            null,
            {},
            process.env.VUE_APP_AUTH0_USER_MANAGEMENT_AUDIENCE
          )) as AxiosResponse<{ permissions: string[] }>
          auth0Permissions = permissions?.data?.permissions || []
        } catch (err) {
          auth0Permissions = []
        }
        sessionStorage.setItem('auth0Permissions', JSON.stringify(auth0Permissions))
      } else {
        auth0Permissions = auth0PermissionsFromStorage
      }
    }
    if (!response) return
    setCookieValue(response.data.id)

    response.data.permissions = response.data?.permissions?.concat(auth0Permissions)
    // adding uuid from the token for now, once we fetch users from µService instead of mothership this can be removed #55624
    response.data.uuid = getUserIdFromToken(await getAuthHeader())
    user.value = transformKeysToCamelCase(response.data)
  }

  const changeCurrentUserLanguage = async (language: Locale): Promise<void> => {
    await runJsonPost(
      `${OPERATIONS_BACKEND_URL}/accounts/commands/change-user-language`,
      { language }
    )
  }

  const currentUserId = computed<number>(() => user.value?.id)

  const currentUserIsFinance = computed<boolean>(() =>
    user.value?.groupNames.includes(USER_GROUPS.FINANCE)
  )

  const currentUserIsClaimAccessorialManager = computed<boolean>(() =>
    user.value?.groupNames.includes(USER_GROUPS.CLAIM_ACCESSORIAL_MANAGER)
  )

  const currentUserIsAccountManager = computed<boolean>(() =>
    user.value?.groupNames.includes(USER_GROUPS.ACCOUNT_MANAGERS)
  )

  const currentUserIsPartnerManager = computed<boolean>(() =>
    user.value?.groupNames.includes(USER_GROUPS.PARTNER_MANAGERS)
  )

  const currentUserIsStaff = computed<boolean>(() =>
    user.value?.groupNames.includes(USER_GROUPS.STAFF)
  )

  const currentUserIsPMTeamAssigner = computed(() =>
    user.value?.groupNames.includes(USER_GROUPS.PARTNER_MANAGERS_TEAM_ASSIGNER)
  )

  const currentUserIsCallCenter = computed(() =>
    user.value?.groupNames.includes(USER_GROUPS.CALL_CENTER)
  )

  const currentUserIsDirectDispatcher = computed<boolean>(() =>
    user.value?.groupNames.includes(USER_GROUPS.DIRECT_DISPATCHER)
  )

  const currentUserIsPmaReadOnlyUser = computed<boolean>(() =>
    user.value?.groupNames.includes(USER_GROUPS.PMA_READ_ONLY_USER)
  )

  const currentUserIsOps = computed<boolean>(
    () => currentUserIsAccountManager.value || currentUserIsPartnerManager.value
  )

  const currentUserLanguage = computed<Locale>(() => user.value?.language || 'en')

  const canSeeUploadAction = (permission: CanSeeUploadPermission) =>
    computed<boolean>(() => {
      const userGroups = user.value?.groupNames

      switch (permission) {
        case 'canUseNewUpload':
          return userGroups.includes(USER_GROUPS.NEW_CSV_UPLOAD)
        case 'canUseOrderTemplateGeneration':
          return (
            userGroups.includes(USER_GROUPS.ORDER_TEMPLATE) ||
            userGroups.includes(USER_GROUPS.ENGINEERING) ||
            userGroups.includes(USER_GROUPS.PRODUCT_OWNER)
          )
        case 'canUseUploadAmazonCSV':
          return true
        case 'canUseGeneralCsvUpload':
          return (
            !userGroups.includes(USER_GROUPS.NEW_CSV_UPLOAD) &&
            (userGroups.includes(USER_GROUPS.CSV_UPLOAD) ||
              userGroups.includes(USER_GROUPS.ENGINEERING) ||
              userGroups.includes(USER_GROUPS.PRODUCT_OWNER))
          )
        default:
          return true
      }
    })

  const hasPermission = (permission: Permission): boolean =>
    user.value.permissions.includes(permission)

  const canDispatchAndUndispatchOrder = computed<boolean>(
    () =>
      user.value?.groupNames.includes(USER_GROUPS.PARTNER_MANAGERS) ||
      user.value?.groupNames.includes(USER_GROUPS.ACCOUNT_MANAGERS)
  )

  return {
    user,
    loadCurrentUser,
    changeCurrentUserLanguage,
    currentUserId,
    currentUserLanguage,
    currentUserIsAccountManager,
    currentUserIsPartnerManager,
    currentUserIsPMTeamAssigner,
    currentUserIsStaff,
    currentUserIsDirectDispatcher,
    currentUserIsOps,
    currentUserIsFinance,
    currentUserIsCallCenter,
    currentUserIsClaimAccessorialManager,
    currentUserIsPmaReadOnlyUser,
    canDispatchAndUndispatchOrder,
    hasPermission,
    canSeeUploadAction
  }
}

import { TrackerData, Transfer } from '@/modules/boards/types'
import { OPERATIONS_BACKEND_URL } from '@/config'
import { runJsonGet } from '@/models/backend-client'
import { AxiosResponse } from 'axios'
import { transformKeysToCamelCase } from '@/services/utils/casing'

export const getTrackerList = async (trackerIds: number[]): Promise<TrackerData[]> => {
  const url = `${OPERATIONS_BACKEND_URL}/tracking/queries/get-tracker-list`
  const response = (await runJsonGet(url, { id: trackerIds })) as AxiosResponse<unknown>
  return transformKeysToCamelCase(response.data)
}

export const getTransferList = async (transferIds: number[]): Promise<Transfer[]> => {
  const url = `${OPERATIONS_BACKEND_URL}/infrastructure/get-transfer-list`
  const response = (await runJsonGet(url, { id: transferIds })) as AxiosResponse<unknown>
  return response.data as Transfer[]
}

<template>
  <Dialog
    v-bind="$attrs"
    class="unsaved-stage-change-dialog"
    :title="$t(title)"
    :confirmButtonText="$t(confirmButtonText)"
    :cancelButtonText="$t(cancelButtonText)"
    inPlace
    @close="cancel"
    @cancel="cancel"
    @confirm="confirm"
  >
    <div>{{ $t(text) }}</div>
  </Dialog>
</template>

<script>
export default {
  props: {
    order: { type: Object, required: false, default: null },
    title: {
      type: String,
      required: false,
      default: 'facility-planning/unsaved-changes-modal/title'
    },
    confirmButtonText: {
      type: String,
      required: false,
      default: 'facility-planning/unsaved-changes-modal/confirm'
    },
    cancelButtonText: {
      type: String,
      required: false,
      default: 'facility-planning/unsaved-changes-modal/cancel'
    },
    text: {
      type: String,
      required: false,
      default: 'facility-planning/unsaved-changes-modal/text'
    },
    onConfirm: {
      type: Function,
      required: false,
      default: null
    },
    onCancel: {
      type: Function,
      required: false,
      default: null
    }
  },
  methods: {
    cancel() {
      if (this.onCancel) {
        this.onCancel()
      }
    },
    async confirm() {
      if (this.onConfirm) {
        this.onConfirm()
      }
    }
  }
}
</script>
<style scoped lang="scss">
.unsaved-stage-change-dialog::v-deep {
  .modal-dialog {
    text-align: center;
    min-width: 500px;
  }

  .modal-title {
    width: 100%;
    text-align: center;
  }

  .modal-dialog {
    transform: translateY(calc(50vh - 50%)) !important;
  }

  .modal-footer {
    display: flex;
    justify-content: space-evenly;
    border-top: 1px solid #e4e5e7;
    margin-top: 16px;

    > div {
      width: 100%;
    }

    button {
      width: 49%;
      font-weight: 500;
      font-size: 1rem;
      text-transform: none;
    }
  }

  .modal-body + .modal-footer {
    padding-top: 10px;
  }
}
</style>

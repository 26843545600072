<template>
  <Form ref="form" :disabled="disabled">
    <div class="plankton-grid">
      <div class="plankton-cell" span="3">
        <MoneyInputField
          :value="baseCost"
          @input="$emit('update:baseCost', $event)"
          :disabled="mode === 'REGULAR'"
          :inputValidators="baseCostValidators"
          :title="mode === 'REGULAR' ? 'Base cost' : 'Base cost*'"
          :allowInteger="5"
          :allowDecimal="2"
          placeholder="0.0"
          class="fullwidth"
          data-test="dispatching-base-cost-field"
        />
      </div>
    </div>
  </Form>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import { FORM_MODE_REGULAR, isValidMode } from './modes'

export default {
  props: {
    baseCost: { type: Number, required: false, default: null },
    disabled: { type: Boolean, required: true },
    mode: {
      type: String,
      required: false,
      default: FORM_MODE_REGULAR,
      validator: isValidMode
    }
  },
  computed: {
    netCost() {
      const baseCost = this.baseCost || 0
      const costSurcharge = this.costSurcharge || 0
      const result = baseCost + costSurcharge
      return result || null
    }
  },
  created() {
    if (this.mode === FORM_MODE_REGULAR) {
      this.baseCostValidators = []
    } else {
      this.baseCostValidators = [
        {
          isInvalid: value => {
            return isEmpty(value) || value == 0
          },
          errorMessage: 'Base cost is required',
          inline: false
        }
      ]
    }
  },
  methods: {
    submit() {
      return this.$refs.form.submit()
    }
  }
}
</script>

<style lang="scss" scoped>
.fullwidth {
  width: 100%;
}
</style>

<script>
import events from '../events'
import { trackEvent } from '@/analytics/amplitude'

export default {
  provide() {
    return {
      getFilterValue: filterName => {
        return this.$store.state[this.storeName].filters[filterName].value
      },
      setFilterValue: (filterName, value, isFilterApplied = false) => {
        this.$store.commit(`${this.storeName}/filters/${filterName}/update`, value)
        trackEvent(events.FILTER_SET, {
          boardName: this.storeName,
          filterName,
          value,
          isFilterApplied
        })
      },
      getAppliedValues: () => {
        return this.$store.getters[`${this.storeName}/filters/getAppliedValues`]
      },
      getFiltersForApi: () => {
        return this.$store.getters[`${this.storeName}/filters/getFiltersForApi`]
      },
      generateGraphqlStringWithPagination:
        this.$store.getters[
          `${this.storeName}/filters/generateGraphqlStringWithPagination`
        ],
      noFiltersSelected: () =>
        this.$store.getters[`${this.storeName}/filters/noFiltersSelected`]
    }
  },
  props: {
    storeName: {
      type: String,
      required: true
    }
  },
  render(createElement) {
    return createElement('div', this.$slots.default)
  }
}
</script>

import * as actions from './actions'
import * as mutations from './mutations'
import { createFilters } from '@/modules/filters'

export default {
  namespaced: true,
  state: () => ({
    transportRequests: []
  }),
  mutations,
  actions,
  modules: {
    filters: createFilters()
  }
}

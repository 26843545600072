import { ValidationError } from '@/controllers/custom-errors'
import { STAGE_ROUTE_NAMES } from '@/router/stage-route-names'

// Enabled means that the order can be updated in the current stage's scope
const ENABLED_ORDER_STATES_FOR_A_GIVEN_A_ROUTE = {
  [STAGE_ROUTE_NAMES.registration]: ['NEW'],
  [STAGE_ROUTE_NAMES.dispatching]: ['REGISTERED', 'CARRIER_LOCKED'],
  [STAGE_ROUTE_NAMES.dispatchingOffers]: ['REGISTERED', 'CARRIER_LOCKED'],
  [STAGE_ROUTE_NAMES.dispatchingDirect]: ['REGISTERED', 'CARRIER_LOCKED'],
  [STAGE_ROUTE_NAMES.execution]: ['DISPATCHED'],
  [STAGE_ROUTE_NAMES.closing]: ['REGISTERED', 'DISPATCHED', 'EXECUTED'],
  [STAGE_ROUTE_NAMES.finance]: [
    'REGISTERED',
    'DISPATCHED',
    'EXECUTED',
    'OPERATIONS_COMPLETED'
  ],
  [STAGE_ROUTE_NAMES.issues]: ['REGISTERED', 'DISPATCHED', 'EXECUTED'],
  /**
   * This is only used for the breakdowns shown in the Closing and Finance stage
   * in the ordering module. After replacing the breakdowns in PES-112, this can
   * be removed.
   */
  ordering: [
    'REGISTERED',
    'CARRIER_LOCKED',
    'DISPATCHED',
    'EXECUTED',
    'OPERATIONS_COMPLETED'
  ]
}

export function enabledOrderStatesFor(currentRouteName) {
  if (
    !Object.prototype.hasOwnProperty.call(
      ENABLED_ORDER_STATES_FOR_A_GIVEN_A_ROUTE,
      currentRouteName
    )
  )
    throw new ValidationError(currentRouteName + ' is an invalid route name')
  return ENABLED_ORDER_STATES_FOR_A_GIVEN_A_ROUTE[currentRouteName]
}
























import { defineComponent, toRef, computed } from '@vue/composition-api'
import { formatDate } from '@/global-setup/filters'
export default defineComponent({
  props: {
    paymentInfo: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const paymentInfo = toRef(props, 'paymentInfo')
    const formattedPaymentStartedDate = computed(() =>
      formatDate(paymentInfo.value?.automaticPaymentStartTimestamp)
    )
    const formattedPaymentDate = computed(() =>
      formatDate(paymentInfo.value?.paymentDate)
    )
    return {
      formattedPaymentStartedDate,
      formattedPaymentDate
    }
  }
})

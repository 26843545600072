import axios, { AxiosRequestConfig } from 'axios'
import { getAuthHeader } from '@/services/auth-service'
import { getAppTheme } from '@/controllers/environment-detection'

import {
  EXCHANGE_RATES_API_URL,
  POD_UPLOAD_SERVICE_URL,
  OPERATIONS_BACKEND_URL,
  FLEET_SERVICE_URL
} from '@/config'

export const api = axios.create()
export const fleetServiceApi = axios.create()
export const exchangeServiceApi = axios.create()
export const podUploadServiceApi = axios.create()

const getAuthHeaderAdapter = async (audience?: string, scope?: string) => {
  return getAuthHeader({
    audience,
    scope
  })
}

api.interceptors.request.use(addAuthenticationInterceptor, error => Promise.reject(error))

fleetServiceApi.interceptors.request.use(fleetAuthInterceptor, error =>
  Promise.reject(error)
)

podUploadServiceApi.interceptors.request.use(podUploadAuthInterceptor, error =>
  Promise.reject(error)
)

exchangeServiceApi.interceptors.request.use(exchangeAuthInterceptor, error =>
  Promise.reject(error)
)

async function fleetAuthInterceptor(config: AxiosRequestConfig) {
  if (!FLEET_SERVICE_URL) {
    throw Error('[Finance Frontend] fleet service Url is not defined.')
  }
  config.baseURL = FLEET_SERVICE_URL
  config.headers = {
    ...config.headers,
    authorization: await getAuthHeaderAdapter(),
    'Authorization-Host': OPERATIONS_BACKEND_URL
  }

  return config
}

async function exchangeAuthInterceptor(config: AxiosRequestConfig) {
  if (!EXCHANGE_RATES_API_URL) {
    throw Error('[Finance Frontend] exchange service Url is not defined.')
  }
  config.baseURL = EXCHANGE_RATES_API_URL
  config.headers = {
    ...config.headers,
    authorization: await getAuthHeaderAdapter(),
    'Authorization-Host': OPERATIONS_BACKEND_URL
  }

  return config
}

async function podUploadAuthInterceptor(config: AxiosRequestConfig) {
  if (!POD_UPLOAD_SERVICE_URL) {
    throw Error('[Finance Frontend] pod upload service Url is not defined.')
  }

  config.baseURL = POD_UPLOAD_SERVICE_URL
  config.headers = {
    ...config.headers,
    authorization: await getAuthHeaderAdapter(
      'https://api.cloud.sennder.com/pod-upload',
      'pod:manage pod:read-all'
    ),
    'Authorization-Host': OPERATIONS_BACKEND_URL,
    'x-tenant': getAppTheme()
  }

  return config
}

async function addAuthenticationInterceptor(config: AxiosRequestConfig) {
  if (!OPERATIONS_BACKEND_URL) throw Error('[Finance Frontend] baseURL is not defined.')

  config.baseURL = OPERATIONS_BACKEND_URL
  config.headers = {
    ...config.headers,
    authorization: await getAuthHeaderAdapter()
  }

  return config
}

import createInitialState from './state'

export const updateCurrentUser = (
  state,
  { id, username, fullNameWithoutTitle, permissions, groupNames }
) => {
  state.currentUser.id = id
  state.currentUser.email = username
  state.currentUser.fullNameWithoutTitle = fullNameWithoutTitle
  state.currentUser.permissions = permissions || []
  state.currentUser.group_names = groupNames
}

export const resetCurrentUser = state => {
  state.currentUser = createInitialState().currentUser
}

export const updateCurrentUserLevel = (state, level) => {
  state.currentUser.level = level
}

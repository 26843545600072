<template>
  <FilterStoreProvider storeName="financeBoard">
    <BoardContainer
      :loadingPage="loadingPage"
      :title="$t('finance-board/finance-page-title')"
    >
      <template slot="aux-section">
        <div class="kpi-section">
          <BoardKPIs ref="boardKPIs" />

          <RenderWithPermission :hasEveryOf="dieselFloaterPermissions">
            <RenderWithLaunchDarkly
              doesNotHavePermission="ENABLE_ADD-SURCHARGE-BUTTON-AR"
            >
              <Button
                category="tertiary"
                class="diesel-floater-btn"
                data-test="diesel-floater-btn"
                @click="showDieselFloaterModal"
              >
                {{ $t('finance-board/finance-page-add-surcharge-label') }}
              </Button>
            </RenderWithLaunchDarkly>
          </RenderWithPermission>
          <OctopusPaymentsWidget :mode="'csv'" />
        </div>
      </template>

      <template v-if="areFiltersInitialized" slot="applied-filters">
        <AppliedFilters @applyFilters="onFiltersApply" />
      </template>

      <FinanceBoardHeader
        slot="table-header"
        :loadingPage="loadingPage"
        v-model="areAllOrdersCheckedHandler"
      />

      <template slot="table-rows">
        <FinanceOrderRow
          v-for="order in sortedOrders"
          :key="order.id"
          :order="order"
          :canUserAccessFinanceSection="canUserAccessFinanceSection"
          :canUseCarrierTimesFeature="canUseCarrierTimesFeature"
          :data-test="`finance-order-${order.id}`"
          @toggleChecked="toggleOrder"
        />
      </template>

      <template #pagination="{ listElement }">
        <ScrollPagination
          :limit="paginationLimit"
          :listElement="listElement"
          :isLoading="loadingPage"
          :areAllPagesFetched="areAllPagesFetched"
          @onNextPageLoad="getFilteredOrders($event, false)"
        />
      </template>

      <FinanceFooter
        v-show="selectedOrdersIds.length"
        slot="footer"
        :selectedOrdersCount="selectedOrdersCount"
        :totalOrdersCount="totalOrdersCount"
        :selectedOrdersIds="selectedOrdersIds"
        :selectedTotalCarrierNet="selectedTotalCarrierNet"
        :selectedTotalCustomerNet="selectedTotalCustomerNet"
        :toggleBilled="toggleBilled"
        class="footer-container"
        @showCarrierFinanceDocumentModal="toggleCarrierFinanceDocumentModal(true)"
        @showCarrierDocumentsModal="showCarrierDocumentsModal"
        @generateShipperInvoices="showGenerateShipperInvoiceModal"
      />

      <BoardFilters
        v-if="areFiltersInitialized"
        slot="filters"
        :loadingPage="loadingPage"
        @applyFilters="onFiltersApply"
        @resetFilters="resetFilters"
      >
        <FinanceFiltersContent @applyFilters="onFiltersApply" />
      </BoardFilters>
    </BoardContainer>
    <!-- Settlement-controlled modals start -->
    <CarrierFinanceDocumentModal
      :isModalVisible="isCarrierFinanceDocumentModalVisible"
      :selectedOrdersCount="selectedOrdersCount"
      :submit="generateForOrders"
      data-test="carrier-finance-document-modal"
      @hide="toggleCarrierFinanceDocumentModal(false)"
    />
    <!-- Settlement-controlled modals end -->
    <DieselFloaterModal ref="dieselFloaterModal" />
    <DownloadCarrierDocumentsModal
      ref="downloadCarrierDocumentsModal"
      :orderIds="selectedOrdersIds"
    />
  </FilterStoreProvider>
</template>

<script>
import debounce from 'lodash/debounce'

import { saveCreditNotesSentStatus } from '@/services/ordering-service'
import { fetchCreditNoteLanguages } from '@/services/language-service'
import {
  generateCreditNoteLink,
  generateCreditNoteAndSend
} from '@/services/document-generation-service'
import { generateForOrders } from '@/services/carrier-fin-docs-service'
import { BoardContainer, ScrollPagination } from '@/modules/boards'
import BoardKPIs from './BoardKPIs'
import CarrierFinanceDocumentModal from './CarrierFinanceDocumentModal'
import DieselFloaterModal from './DieselFloaterModal'
import DownloadCarrierDocumentsModal from './DownloadCarrierDocumentsModal'
import FinanceBoardHeader from './FinanceBoardHeader'
import FinanceOrderRow from './FinanceOrderRow'
import FinanceFooter from './FinanceFooter'
import FinanceFiltersContent from './FinanceFiltersContent'
import {
  BoardFilters,
  AppliedFilters,
  createFiltersMappers,
  FilterStoreProvider
} from '@/modules/filters'
import RenderWithPermission from '@/components/RenderWithPermission'
import * as permissions from '@/components/user-permissions'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

import { formatJSONError } from '@/global-setup/global-error-handler'
import { BOARD_PAGINATION_LIMIT } from '../constants'
import { isPosteTheme } from '@/controllers/environment-detection'
import OctopusPaymentsWidget from '@/microfrontends/widgets/payments-widget/PaymentsWidget.vue'

const filtersMappers = createFiltersMappers('financeBoard')
import RenderWithLaunchDarkly from '@/components/RenderWithLaunchDarkly.vue'
import useFeatureFlag from '@/compositions/useFeatureFlag'

export default {
  components: {
    BoardContainer,
    FinanceBoardHeader,
    FinanceOrderRow,
    FinanceFooter,
    CarrierFinanceDocumentModal,
    DieselFloaterModal,
    DownloadCarrierDocumentsModal,
    BoardFilters,
    FinanceFiltersContent,
    ScrollPagination,
    AppliedFilters,
    RenderWithPermission,
    BoardKPIs,
    FilterStoreProvider,
    OctopusPaymentsWidget,
    RenderWithLaunchDarkly
  },
  data: () => ({
    allFilteredOrdersChecked: false,
    paginationLimit: BOARD_PAGINATION_LIMIT,
    areAllPagesFetched: false,
    isCarrierFinanceDocumentModalVisible: false,
    creditNoteLanguages: []
  }),
  computed: {
    ...mapState(['loadingPage']),
    ...mapGetters(['canUserAccessFinanceSection', 'canUseCarrierTimesFeature']),
    ...mapGetters('financeBoard', [
      'sortedOrders',
      'selectedOrdersIds',
      'selectedForStaffIds',
      'selectedOrdersCount',
      'totalOrdersCount',
      'selectedTotalCustomerNet',
      'selectedTotalCarrierNet'
    ]),
    ...filtersMappers.mapState(['areFiltersInitialized']),
    ...filtersMappers.mapGetters(['getFiltersForApi', 'getQueryFromFilters']),
    areAllOrdersCheckedHandler: {
      get() {
        return this.allFilteredOrdersChecked
      },
      set(value) {
        this.allFilteredOrdersChecked = value
        this.toggleOrders(value)
      }
    },
    dieselFloaterPermissions() {
      return [permissions.CAN_SET_DIESEL_FLOATER]
    },
    isPosteTheme() {
      return isPosteTheme
    }
  },
  async created() {
    await this.setFiltersFromUrl(this.$route.query)
    this.getFilteredOrders()
    this.creditNoteLanguages = await fetchCreditNoteLanguages()
  },
  methods: {
    ...mapMutations(['updateCreditNoteSentStatus']),
    ...mapMutations('financeBoard', ['toggleOrder', 'toggleOrders']),
    ...mapActions('financeBoard', ['loadOrdersPage']),
    ...filtersMappers.mapActions(['setFiltersFromUrl', 'resetFilters']),
    async toggleBilled() {
      try {
        await saveCreditNotesSentStatus(this.selectedOrdersIds, true)
        await this.updateCreditNoteSentStatus({
          orderIds: this.selectedOrdersIds,
          isBilled: true
        })
        this.alertModal.showSuccessMessage(
          this.$t('finance-board/finance-page-credit-note-sent-confirmation')
        )
      } catch (error) {
        this.alertModal.showErrorMessage('Error', formatJSONError(error))
      }
    },
    toggleCarrierFinanceDocumentModal(isVisible) {
      this.isCarrierFinanceDocumentModalVisible = isVisible
    },
    showCarrierDocumentsModal() {
      this.$refs.downloadCarrierDocumentsModal.show()
    },
    async generateCreditNotes(language) {
      const link = await generateCreditNoteLink({
        language,
        order_ids: this.selectedOrdersIds
      })
      window.open(link, '_blank')
    },
    async generateCreditNotesAndSend(language) {
      await generateCreditNoteAndSend({
        language,
        order_ids: this.selectedOrdersIds
      })
    },
    async generateForOrders() {
      await generateForOrders({
        external_ids: this.selectedForStaffIds
      })
    },
    async getFilteredOrders(offset = 0, clearBeforeUpdate = true) {
      /**
       * This code is implemented for this ticker as a temporary solution: https://dev.azure.com/sennder/sennder/_workitems/edit/84413
       * We want to enable pre-filtering by shipper id based on the list of shipper ids coming from the feature flag.
       */
      const { getJSONValue } = useFeatureFlag()
      const shipperPreset = getJSONValue('ENABLE_FINANCE-BOARD-SHIPPER-PRESET').value

      const newOrders = await this.loadOrdersPage({
        offset,
        limit: this.paginationLimit,
        clearBeforeUpdate,
        fixedCustomerIds: shipperPreset?.customer_mothership_ids,
        excludedCustomerIds: shipperPreset?.excluded_customer_mothership_ids
      })
      this.allFilteredOrdersChecked = false
      this.areAllPagesFetched = newOrders.length === 0
    },
    onFiltersApply() {
      this.octopusRouter.setQuery(this.getQueryFromFilters)
      this.getFilteredOrdersAndKPIsDebounced()
    },
    showDieselFloaterModal() {
      this.$refs.dieselFloaterModal.show()
    },
    getFilteredOrdersAndKPIsDebounced: debounce(function (...args) {
      this.getFilteredOrders(...args)
    }, 500),
    showGenerateShipperInvoiceModal() {
      this.$refs.sendShipperInvoicesModal.open(this.selectedOrdersIds)
    }
  }
}
</script>
<style lang="scss" scoped>
.button-link:hover {
  text-decoration: none;
}

.diesel-floater-btn {
  margin-right: 8px;
}

.upload-surcharges-btn {
  margin-left: 8px;
}

.kpi-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  column-gap: 10px;
}
</style>

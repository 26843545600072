import Swal, { SweetAlertOptions, SweetAlertIcon } from 'sweetalert2'

export const showToast = (
  text: string,
  icon: SweetAlertIcon = 'info',
  timer = 3000
): void => {
  const toastOptions: SweetAlertOptions = {
    position: 'top-right',
    icon,
    text,
    showConfirmButton: false,
    timer
  }

  Swal.fire(toastOptions)
}

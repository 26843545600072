



























import { PropType, defineComponent } from '@vue/composition-api'
import { placeholderIfEmpty } from '@/global-setup/filters'
import LabeledText from '@/modules/common/components/LabeledText.vue'

interface FormattedTracker {
  label: string
  trackerIds: string[]
}

export default defineComponent({
  components: { LabeledText },
  props: {
    formattedTrackerInfo: {
      type: Array as PropType<FormattedTracker[]>,
      default: null
    }
  },
  setup() {
    return { placeholderIfEmpty }
  }
})

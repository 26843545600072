import { ISODateString, VehicleType } from '@/services'
import { StopoverType } from '@/services/enums'
import { StoredIncident } from '@sennder/incident-management'

export enum OrderLogType {
  DocumentGenerated = 'DOCUMENT_GENERATED',
  DocumentSent = 'DOCUMENT_SENT',
  TransEuOfferPosted = 'TRANS_EU_OFFER_POSTED',
  OrderCopied = 'ORDER_COPIED',
  SurchargeAdded = 'SURCHARGE_ADDED',
  CorrectionAdded = 'CORRECTION_ADDED',
  New = 'NEW',
  Registered = 'REGISTERED',
  Unregistered = 'UNREGISTERED',
  CarrierLocked = 'CARRIER_LOCKED',
  CarrierRemoved = 'CARRIER_REMOVED',
  Cancelled = 'CANCELLED',
  Dispatched = 'DISPATCHED',
  Undispatched = 'UNDISPATCHED',
  Executed = 'EXECUTED',
  OperationsCompleted = 'OPERATIONS_COMPLETED',
  Completed = 'COMPLETED',
  MissingDocumentsRequested = 'MISSING_DOCUMENTS_REQUESTED',
  OrderReferenceNumberChanged = 'ORDER_REFERENCE_NUMBER_CHANGED',
  StopoverReferenceNumberChanged = 'STOPOVER_REFERENCE_NUMBER_CHANGED',
  OrderAllowedVehicleTypesChanged = 'ORDER_ALLOWED_VEHICLE_TYPES_CHANGED',
  StopoverTimeChanged = 'STOPOVER_TIME_CHANGED',
  StopoverAddressChanged = 'STOPOVER_ADDRESS_CHANGED',
  StopoverAdded = 'STOPOVER_ADDED',
  StopoverRemoved = 'STOPOVER_REMOVED',
  ShipperInvoiceCreated = 'SHIPPER_INVOICE_CREATED',
  ActualTimesCorrectedPostExecution = 'ACTUAL_TIMES_CORRECTED_POST_EXECUTION',
  StopActionSuccessful = 'STOP_ACTION_SUCCESSFUL',
  ALLOCATED_ASSETS_CHANGED = 'ALLOCATED_ASSETS_CHANGED'
}

export interface AddOrderNotePayload {
  content: string
  noteType: string
  authorId: number
}

export interface OrderNote {
  authorId: number
  authorName: string
  content: string
  date: string
  deleted: boolean
  id: number
  noteType: string
  orderId: number
}

export interface OrderLogGeneral {
  date: ISODateString
  fileName?: string
  id: number
  logType: OrderLogType
  message: string
  user: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logData?: { [key: string]: any }
}

export interface OrderCopiedLog extends OrderLogGeneral {
  logType: OrderLogType.OrderCopied
  logData?: {
    idForStaff: string
  }
}

export interface OrderReferenceNumberChangedLog extends OrderLogGeneral {
  logType: OrderLogType.OrderReferenceNumberChanged
  logData: {
    new?: string
    old?: string
  }
}

export interface StopoverReferenceNumberChangedLog extends OrderLogGeneral {
  logType: OrderLogType.StopoverReferenceNumberChanged
  logData: {
    index: number
    new?: string
    old?: string
    stopoverId: number
    type: StopoverType
  }
}

export interface ActualTimesCorrectedPostExecutionLog extends OrderLogGeneral {
  logType: OrderLogType.ActualTimesCorrectedPostExecution
  logData: {
    new: string
    old: string
    index: number
    stopoverId: number
    actionType: 'ARRIVAL' | 'DEPARTURE'
  }
}

export interface StopActionSuccessfulLog extends OrderLogGeneral {
  logType: OrderLogType.StopActionSuccessful
  logData: {
    stopIndex: number
    stopoverId: number
    actionType: 'LOADING' | 'UNLOADING'
  }
}

export interface OrderAllowedVehicleTypesChangedLog extends OrderLogGeneral {
  logType: OrderLogType.OrderAllowedVehicleTypesChanged
  logData: {
    diff: VehicleType[]
    type: 'ADDED' | 'REMOVED'
  }
}

export interface StopoverTimeChangedLog extends OrderLogGeneral {
  logType: OrderLogType.StopoverTimeChanged
  logData: {
    index: number
    new: {
      start: string
      end: string
    }
    old: {
      start: string
      end: string
    }
    stopoverId: number
    type: StopoverType
  }
}

export interface MissingDocumentLog extends OrderLogGeneral {
  logType: OrderLogType.MissingDocumentsRequested
  logData: {
    documentType: string
  }
}

export interface SurchargeAddedLog extends OrderLogGeneral {
  logType: OrderLogType.SurchargeAdded
  logData: {
    surchargeAmount: string
    surchargeSide: 'CARRIER' | 'SHIPPER'
  }
}

export interface CorrectionAddedLog extends OrderLogGeneral {
  logType: OrderLogType.CorrectionAdded
  logData: {
    correctionAmount: string
    correctionSide: 'CARRIER' | 'SHIPPER'
    correctionType: 'SURCHARGE' | 'BASE_COST' | 'BASE_PRICE'
  }
}

export interface ShipperInvoiceCreatedLog extends OrderLogGeneral {
  logType: OrderLogType.ShipperInvoiceCreated
  logData: {
    orderId: number
    invoiceNumber: string
  }
}

export interface AssetChangedLog extends OrderLogGeneral {
  logType: OrderLogType.ALLOCATED_ASSETS_CHANGED
  logData: {
    assetType: string
    changeType: string
    newValue?: string
    oldValue?: string
  }
}

export type OrderLog =
  | OrderLogGeneral
  | OrderReferenceNumberChangedLog
  | OrderCopiedLog
  | StopoverReferenceNumberChangedLog
  | OrderAllowedVehicleTypesChangedLog
  | StopoverTimeChangedLog
  | MissingDocumentLog
  | ActualTimesCorrectedPostExecutionLog
  | AssetChangedLog

export interface ProcessedNote extends Omit<OrderNote, 'id'> {
  type: 'Note'
  noteId: number
  id: string
}

export interface ProcessedLog extends Omit<OrderLog, 'id'> {
  type: 'Log'
  logId: number
  id: string
  content: string
  tooltipText?: string
  logData?: { [key: string]: unknown }
}

export interface ProcessedIncident {
  type: 'Incident'
  id: string
  content: StoredIncident
  user: string
  date: string
  stopLetter?: string
}

export const isNote = (item: ProcessedNote | ProcessedLog): item is ProcessedNote =>
  item.type === 'Note'

export const isLog = (item: ProcessedNote | ProcessedLog): item is ProcessedLog =>
  item.type === 'Log'

// Activity Records types
export interface IActivityPayload {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export interface IActivityRecord {
  id: string
  domain: string
  type: string
  version: number
  payload: IActivityPayload
  owner: string
  timestamp: number
}

<template>
  <table ref="suggestions" class="suggestions">
    <thead>
      <tr>
        <th class="send-offers" v-if="canSendOffers" />
        <th class="carrier">
          {{ $t('ordering/dispatching-carrier-suggestions-carrier-suggestions-carrier') }}
        </th>
        <th class="tier">
          {{ $t('ordering/dispatching-carrier-suggestions-carrier-suggestions-tier') }}
        </th>
        <th class="gps">
          {{ $t('ordering/dispatching-carrier-suggestions-carrier-suggestions-gps') }}
        </th>
        <th class="quality">
          {{ $t('ordering/dispatching-carrier-suggestions-carrier-suggestions-quality') }}
        </th>
        <th class="carrier-contact">
          {{
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-carrier-contact'
            )
          }}
        </th>
        <th class="partner-manager">
          {{
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-tagged-manager'
            )
          }}
        </th>
        <th class="line">
          {{ $t('ordering/dispatching-carrier-suggestions-carrier-suggestions-line') }}
        </th>
        <th class="last-transfer">
          {{
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-last-transfer'
            )
          }}
        </th>
        <th class="euro-per-km">
          {{
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-last-euro-per-km'
            )
          }}
        </th>
        <th class="distance">
          {{
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-last-distance'
            )
          }}
        </th>
        <th class="base-price">
          {{
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-last-base-price'
            )
          }}
        </th>
        <th class="base-cost">
          {{
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-last-base-cost'
            )
          }}
        </th>
        <th class="number-of-line-transfers">
          {{
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-num-line-transfers'
            )
          }}
        </th>
        <th class="offers-sent">
          {{
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-num-offers-sent'
            )
          }}
        </th>
      </tr>
    </thead>
    <LoadingTableFooter v-if="loading" />
    <tbody v-else-if="suggestions.length === 0">
      <td class="no-data-cell" colspan="20" align="center">
        {{
          $t(
            'ordering/dispatching-carrier-suggestions-carrier-suggestions-no-suggestions'
          )
        }}
      </td>
    </tbody>
    <tbody v-else>
      <tr
        v-for="(suggestion, index) in suggestions"
        :key="suggestion.id"
        data-test="offer"
        :class="{ selected: selectedIndex === index }"
      >
        <td class="send-offers" v-if="canSendOffers">
          <div v-if="suggestion.carrierContactId" class="offers-sent-checkbox-wrapper">
            <Checkbox
              v-model="carrierContactIdList"
              :value="String(suggestion.carrierContactId)"
              data-test="offers-sent-checkbox"
            />
          </div>
          <div v-else>
            {{
              $t(
                'ordering/dispatching-carrier-suggestions-carrier-suggestions-no-contact'
              )
            }}
          </div>
        </td>
        <td class="carrier">
          {{ index + 1 }}.
          <a
            :href="createLinkToExecutionDashboard(suggestion)"
            target="_blank"
            data-test="carrierName"
          >
            {{ suggestion.name }}
          </a>
        </td>
        <td class="tier" data-test="tier">{{ suggestion.carrierTier }}</td>
        <td class="gps" data-test="gps">{{ formatBool(suggestion.gpsIntegrated) }}</td>
        <td class="quality" data-test="quality">{{ formatQuality(suggestion) }}</td>
        <td class="carrier-contact" data-test="carrier-contact">
          {{ suggestion.contactPerson }}
        </td>
        <td class="partner-manager" data-test="partner-manager">
          {{ suggestion.contactOwner }}
        </td>
        <td class="line">
          <div v-for="line in suggestion.lines" :key="line.number" class="line-item">
            <div class="line-country" data-test="line-country">
              {{ line.country }}
            </div>
            <div class="line-postal" data-test="line-postal">
              {{ line.postal }}
            </div>
            <div class="line-city" data-test="line-city">
              {{ line.city }}
            </div>
          </div>
        </td>
        <!-- TODO: Ensure the dates are also in locale format -->
        <td class="last-transfer" data-test="last-transfer">
          {{ suggestion.mostRecentTrip.date }}
        </td>
        <td class="euro-per-km" data-test="euro-per-km">
          &euro;{{ formatCurrency(computeCostPerKmForSuggestion(suggestion)) }}
        </td>
        <td class="distance" data-test="distance">{{ suggestion.distance }} km</td>
        <!-- TODO: Replace hard-encoded € if multicurrency is needed -->
        <td class="base-price" data-test="base-price">
          &euro;{{ formatCurrency(suggestion.basePrice) }}
        </td>
        <td class="base-cost" data-test="base-cost">
          &euro;{{ formatCurrency(suggestion.baseCost) }}
        </td>
        <td class="number-of-line-transfers" data-test="number-of-line-transfers">
          {{ suggestion.count }}
        </td>
        <td class="offers-sent" data-test="offers-sent">
          {{ suggestion.offerSentCount }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { trackEvent } from '@/analytics/amplitude'
import { LoadingTableFooter } from '@/modules/boards'

export default defineComponent({
  components: {
    LoadingTableFooter
  },
  props: {
    orderId: { type: Number, required: true },
    suggestions: { type: Array, default: () => [] },
    canSendOffers: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  data() {
    return {
      selectedIndex: null,
      carrierContactIdList: []
    }
  },
  watch: {
    carrierContactIdList(value) {
      trackEvent('octopus/carrier-suggestion/click/carrier-similar-lines-table', {
        selected: value,
        orderId: this.orderId
      })
      this.$emit('selection-changed', value)
    }
  },
  methods: {
    highlightRow(index) {
      this.selectedIndex = index
      const body = this.$refs.suggestions.querySelector('tbody')
      const row = this.$refs.suggestions.querySelector(`tr:nth-of-type(${index + 1})`)
      this.scrollRowToView(body, row, index)
    },
    scrollRowToView(body, row, rowIndex) {
      // prevent scroll when row is hidden by filters
      if (!row) {
        return
      }

      const bodyRect = body.getBoundingClientRect()
      const bodyViewableArea = {
        height: body.clientHeight,
        width: body.clientWidth
      }

      const rowRect = row.getBoundingClientRect()
      const rowHeight = rowRect.bottom - rowRect.top

      const isViewable =
        rowRect.top >= bodyRect.top &&
        rowRect.top <= bodyRect.top + bodyViewableArea.height

      if (!isViewable) {
        body.scrollTop = rowHeight * rowIndex
      }
    },
    computeCostPerKmForSuggestion(suggestion) {
      return suggestion.distance !== 0
        ? Math.round((suggestion.baseCost / suggestion.distance) * 100) / 100
        : 0
    },
    formatCurrency(value) {
      return Number.parseFloat(value).toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
    formatBool(value) {
      return value === true
        ? this.$t('global/Yes')
        : value === false
        ? this.$t('global/No')
        : ''
    },
    formatQuality(suggestion) {
      if (suggestion.pickupPerformance && suggestion.deliveryPerformance) {
        const otp = this.$t(
          'ordering/dispatching-carrier-suggestions-carrier-suggestions-otp',
          [suggestion.pickupPerformance.toFixed(0)]
        )
        const otd = this.$t(
          'ordering/dispatching-carrier-suggestions-carrier-suggestions-otd',
          [suggestion.deliveryPerformance.toFixed(0)]
        )
        return this.$t(
          'ordering/dispatching-carrier-suggestions-carrier-suggestions-otp-otd',
          { otp: otp, otd: otd }
        )
      } else {
        return ''
      }
    },
    createLinkToExecutionDashboard(suggestion) {
      return `/dashboard/execution?carriers=${suggestion.id}&states=DISPATCHED,COMPLETED,OPERATIONS_COMPLETED,EXECUTED`
    }
  }
})
</script>

<style lang="scss" scoped>
.suggestions {
  thead,
  tbody {
    display: block;
    width: 100%;
  }

  tbody {
    height: 220px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  th {
    padding: 0 0 4px;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 16px;
  }

  th,
  td {
    &.send-offers {
      width: 29px;
    }

    &.carrier {
      width: 115px;
    }

    &.tier {
      width: 20px;
    }

    &.gps {
      width: 30px;
      text-align: center;
    }

    &.quality {
      width: 128px;
      text-align: center;
    }

    &.carrier-contact {
      width: 120px;
    }

    &.partner-manager {
      width: 130px;
    }

    &.line {
      width: 120px;
    }

    &.last-transfer {
      width: 96px;
    }

    &.euro-per-km {
      width: 84px;
    }

    &.distance {
      width: 84px;
    }

    &.base-price {
      width: 84px;
    }

    &.base-cost {
      width: 84px;
    }

    &.number-of-line-transfers {
      width: 96px;
    }

    &.offers-sent {
      width: 84px;
    }
  }

  tr.selected {
    background-color: #f8f8f8;
  }

  td {
    padding: 8px 8px 8px 0;
    margin: 0;
    border-top: solid 1px $color-neutral-lightest;
    font-size: 12px;
    line-height: 16px;

    &.no-data-cell {
      display: block;
    }

    &.partner-manager {
      color: $color-neutral-main;
    }

    &.line {
      .line-item {
        display: flex;

        .line-country {
          width: 1.5em;
        }

        .line-postal {
          width: 3.5em;
        }
      }
    }

    &.number-of-line-transfers {
      text-align: center;
    }

    &.offers-sent {
      text-align: center;
    }
  }
}
</style>

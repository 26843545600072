const isTruthyAndNotEmpty = value => (Array.isArray(value) ? value.length : value)
const getAllFilterNames = state =>
  Object.keys(state).filter(
    filterName =>
      filterName !== 'areFiltersInitialized' &&
      filterName !== 'options' &&
      filterName != 'config'
  )

export const getAppliedValues = (state, getters) =>
  getAllFilterNames(state)
    .map(filterName => getters[`${filterName}/getAppliedValue`])
    .reduce((acc, val) => acc.concat(val), []) // TODO: replace with "flat" after REF-72 is deployed
    .filter(isTruthyAndNotEmpty)
    .filter(filter => {
      return !state.config.hiddenChips.some(chipName => chipName === filter.updateName)
    })

export const getEsAppliedFilterValues = function (state, getters) {
  return getAllFilterNames(state)
    .map(filterName => getters[`${filterName}/getAppliedValueForEs`])
    .reduce((acc, val) => acc.concat(val), []) // TODO: replace with "flat" after REF-72 is deployed
    .filter(isTruthyAndNotEmpty)
}

export const noFiltersSelected = (state, getters) =>
  getAllFilterNames(state).every(filterName => getters[`${filterName}/isFilterEmpty`])

export const getQueryFromFilters = (state, getters) => {
  const result = getAllFilterNames(state).reduce((urlQueryObj, filterName) => {
    const urlQueryFragment = getters[`${filterName}/getUrlQueryFragment`]
    if (urlQueryFragment) Object.assign(urlQueryObj, urlQueryFragment)
    return urlQueryObj
  }, {})

  return result
}

export const getFiltersForApi = (state, getters) =>
  getAllFilterNames(state).reduce((apiQueryObj, filterName) => {
    const apiFragment = getters[`${filterName}/getApiFragment`]
    if (apiFragment) Object.assign(apiQueryObj, apiFragment)
    return apiQueryObj
  }, {})

export const generateGraphqlString = (state, getters) =>
  getAllFilterNames(state)
    .map(filterName => getters[`${filterName}/getGraphqlValue`])
    .filter(isTruthyAndNotEmpty)
    .join('\n, ')

export const generateGraphqlStringWithPagination =
  (state, getters) => (paginationOffest, paginationLimit, sortOrder) =>
    `${getters.generateGraphqlString}
, offset: ${paginationOffest}
, limit: ${paginationLimit}
, sort_order: ${JSON.stringify(sortOrder.join(','))},`

export const generateElasticSearchQuery = (state, getters) => ({
  bool: {
    must: getAllFilterNames(state)
      .map(filterName => getters[`${filterName}/getEsQueryFragment`])
      .reduce((acc, filters) => [...(acc || []), ...(filters || [])], [])
  }
})

export const generateElasticSearchQueryWithPagination =
  (state, getters) =>
  (paginationOffset = 0, paginationLimit = 50, sortOrder = []) => {
    const result = {
      query: getters.generateElasticSearchQuery,
      from: paginationOffset,
      size: paginationLimit,
      sort: sortOrder.map(column => ({ [column]: { order: 'asc' } }))
    }

    return result
  }

export const generateElasticInitials = name => {
  if (name) {
    let nameArr = name.split(' ')
    return `${nameArr[0].charAt(0).toUpperCase()}.${nameArr
      .at(-1)
      .charAt(0)
      .toUpperCase()}`
  }
}

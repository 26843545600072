import { runFileGet, runJsonPost, runFilePost } from '@/models/backend-client'
import { OPERATIONS_BACKEND_URL, POD_UPLOAD_SERVICE_URL } from '@/config'
import { transformKeysToCamelCase } from '@/services/utils/casing'
import { getTenant } from '@/controllers/environment-detection'

export const generateCreditNoteLink = async ({ language, order_ids }) => {
  try {
    const response = await runJsonPost(
      `${OPERATIONS_BACKEND_URL}/credit-notes/generate/${language}`,
      { order_ids }
    )
    return response.data.pdf
  } catch (error) {
    throw transformKeysToCamelCase(error.response.data)
  }
}

export const generateCreditNoteAndSend = async ({ language, order_ids }) => {
  try {
    const response = await runJsonPost(
      `${OPERATIONS_BACKEND_URL}/credit-notes/generate-and-send`,
      { language, order_ids }
    )
    return response.data.pdf
  } catch (error) {
    throw transformKeysToCamelCase(error.response.data)
  }
}

export const downloadCarrierDocuments = async ({ order_ids }) => {
  const getDocumentsUrl = `${OPERATIONS_BACKEND_URL}/finance/queries/get-documents-archive/`
  try {
    const response = await runFileGet(`${getDocumentsUrl}${order_ids.join(',')}`)
    downloadFile(response)
  } catch (error) {
    const textContent = await readBlobAsText(error.response.data)
    throw transformKeysToCamelCase(JSON.parse(textContent))
  }
}

export const downloadCarrierDocumentsNew = async ({ order_ids }) => {
  const postDocumentsUrl = `${POD_UPLOAD_SERVICE_URL}/finance/orders/documents/download-zip`
  try {
    const response = await runFilePost(
      postDocumentsUrl,
      {
        order_ids: order_ids
      },
      {
        headers: {
          'x-tenant': getTenant()
        }
      },
      'https://api.cloud.sennder.com/pod-upload',
      'pod:manage pod:read-all'
    )
    downloadFile(response)
  } catch (error) {
    const textContent = await readBlobAsText(error.response.data)
    const mappedTextContent = JSON.parse(textContent)
    // map pod-upload error to expected error format
    mappedTextContent.errorCode = mappedTextContent.code
    throw transformKeysToCamelCase(mappedTextContent)
  }
}

// responseType = 'blob' is used in request so any error message will be saved as blob too
// This function is used to transform this blob into the error message
async function readBlobAsText(blob) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.addEventListener('loadend', e => resolve(e.srcElement.result))
    fileReader.addEventListener('error', e => reject(e.srcElementeader.error))
    fileReader.readAsText(blob)
  }).catch(error => {
    throw Error(
      `Error while reading the file, please contact support if this error persists. ${error}`
    )
  })
}

// "createObjectURL" method creates its own file name so
// we should get the original filename from the header
// and pass it to the link object
export const downloadFile = response => {
  const fileNameRegexRes =
    response.headers['content-disposition'].match(/filename="(.+)"/)
  const fileName = fileNameRegexRes && fileNameRegexRes[1]
  const archiveFile = new Blob([response.data])
  const url = window.URL.createObjectURL(archiveFile)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}









import {
  ref,
  onMounted,
  defineComponent,
  PropType,
  Ref,
  toRef,
  toRefs
} from '@vue/composition-api'
import useRouting, { RouteResponse } from './compositions/useRouting'
import getHereMap from './compositions/useHereMapInit'
import getRouteMarkers from './compositions/useRouteMarkers'
import getStopMarkers from './compositions/useStopMarkers'
import Swal from 'sweetalert2'
import { AlertModal } from '@sennder/plankton'
import H, { geo as HereGeo } from '@here/maps-api-for-javascript'
import { StopLocation, TrackingLocation } from '../../types'

interface GeoPoint {
  lat: number
  lng: number
}

export default defineComponent({
  props: {
    licensePlate: { type: String, default: '' },
    height: { type: String, default: '376px' },
    stopLocations: { type: Array as PropType<StopLocation[]>, default: () => [] },
    trackingLocations: { type: Array as PropType<TrackingLocation[]>, default: () => [] },
    center: { type: Object as PropType<GeoPoint>, required: true },
    multipleStopLetters: { type: Array as PropType<string[]>, default: () => [] },
    stopsCompletion: {
      type: Array as PropType<boolean[]>,
      default: () => []
    },
    isRoundTrip: { type: Boolean, default: false }
  },
  setup(props) {
    const hereMap = ref<HTMLElement | null>(null)

    onMounted(async () => {
      if (hereMap.value) {
        const hereMapHelpers = getHereMap(hereMap as Ref<HTMLElement>, props.center)
        const { map, ui, router } = hereMapHelpers

        new H.mapevents.Behavior(new H.mapevents.MapEvents(map))

        ui.removeControl('mapsettings')

        map.getViewPort().setPadding(30, 10, 30, 20)

        const stopMarkers = getStopMarkers(toRefs(props))
        if (stopMarkers.value.length) {
          const group = new H.map.Group()
          stopMarkers.value.forEach(marker => group.addObject(marker))

          map.addObject(group)
          map.getViewModel().setLookAtData({
            // Here Maps type declarations are somewhat broken
            // their own code throws the error after being migrated to TypeScript
            // see https://github.com/heremaps/maps-api-for-javascript-examples/blob/master/zoom-to-set-of-markers/demo.js#L20
            bounds: group.getBoundingBox() as unknown as HereGeo.Polygon
          })
        }

        const routeMarkers = getRouteMarkers(
          toRef(props, 'trackingLocations'),
          toRef(props, 'licensePlate'),
          toRef(props, 'stopLocations'),
          toRef(props, 'multipleStopLetters'),
          ui
        )
        routeMarkers.value.forEach(routeMarker => map.addObject(routeMarker))

        const { routingParameters, getRouteLine } = await useRouting(
          toRef(props, 'stopLocations')
        )

        router.calculateRoute(
          routingParameters.value,
          // HERE Maps API uses "Object" here which is not assignable to most types
          // so we need to cast it to "unkown" beforehand
          (routeResponse: unknown) => {
            if (routeResponse) {
              const routeLine = getRouteLine(routeResponse as RouteResponse)
              map.addObject(routeLine)
            }
          },
          (error: Error | null) => {
            const alertModal = AlertModal(Swal)
            if (error)
              alertModal.showErrorMessage(
                'Route calculating error',
                error.message || 'Unkown error, please contact the administrator'
              )
          }
        )
      }
    })

    return { hereMap }
  }
})

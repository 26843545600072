var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-test":"order-stops"}},_vm._l((_vm.stops),function(stop,index){return _c('VerticalStepper',{key:stop.id,staticClass:"stops",attrs:{"pointLabel":_vm.multipleStopLetters[index],"showLine":index !== _vm.stops.length - 1,"invertedColorPoint":stop.stopoverType === 'UNLOADING',"data-test":"order-stop-item"}},[_c('p',{staticClass:"paragraph"},[_c('b',{attrs:{"data-test":"stop-type"}},[_vm._v(" "+_vm._s(_vm.$t( stop.stopoverType === 'LOADING' ? 'ordering/order-sidebar-loading-label' : 'ordering/order-sidebar-unloading-label' ))+"  ")]),_c('span',{attrs:{"data-test":"stop-customer"}},[_vm._v(_vm._s(stop.customerCompany.name))])]),_c('p',{staticClass:"paragraph stop-address",attrs:{"data-test":"stop-address"}},[_vm._v(" "+_vm._s(stop.warehouseAddress.fullAddress)+" ")]),_c('Chip',{staticClass:"chip-timezone",attrs:{"data-test":"stop-address-timezone","text":_vm.getTzChipText(stop),"category":"neutral"}}),_c('div',{staticClass:"plankton-grid",attrs:{"columns":"12"}},[_c('LabeledText',{staticClass:"label-text-element",attrs:{"label":_vm.$t('ordering/order-sidebar-referenceno-label'),"data-test":"customer-reference-number","span":"12"}},[_c('div',{staticClass:"ref-number"},[_vm._v(_vm._s(stop.referenceNumber))])])],1),_c('div',{staticClass:"plankton-grid",attrs:{"columns":"12"}},[_c('LabeledText',{staticClass:"label-text-element",attrs:{"data-test":"customer-start-date","label":_vm.$t('ordering/order-sidebar-start-date-label'),"span":"6"}},[_c('div',{staticClass:"label-wrapper"},[_c('p',{staticClass:"label-item"},[_vm._v(_vm._s(_vm.formatDate(stop.startDate)))]),_c('p',{staticClass:"label-item"},[_vm._v(" "+_vm._s(stop.startTime)+" "),_c('TimezoneAcronym',{attrs:{"text":_vm.getTzAcronym(
                  stop.arrivalToWarehouse.originalIsoTimeStamp,
                  stop.warehouseAddress.timezone
                ),"tooltipText":stop.warehouseAddress.timezone}})],1)])]),_c('LabeledText',{staticClass:"label-text-element",attrs:{"data-test":"customer-end-date","label":_vm.$t('ordering/order-sidebar-end-date-label'),"span":"6"}},[_c('div',{staticClass:"label-wrapper"},[_c('p',{staticClass:"label-item"},[_vm._v(_vm._s(_vm.formatDate(stop.endDate)))]),_c('p',{staticClass:"label-item"},[_vm._v(" "+_vm._s(stop.endTime)+" "),_c('TimezoneAcronym',{attrs:{"text":_vm.getTzAcronym(
                  stop.departureFromWarehouse.originalIsoTimeStamp,
                  stop.warehouseAddress.timezone
                ),"tooltipText":stop.warehouseAddress.timezone}})],1)])])],1),(_vm.isOrderDispatched && _vm.hasStepsLoaded)?_c('div',{staticClass:"plankton-grid",attrs:{"columns":"12"}},[_c('LabeledText',{staticClass:"label-text-element",attrs:{"data-test":"actual-arrival","label":_vm.$t('ordering/order-sidebar-arrival-label'),"span":"6"}},[(_vm.steps[index * 2].actualTime.isValid)?_c('div',{staticClass:"label-wrapper"},[_c('p',{staticClass:"label-item"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.steps[index * 2].actualTime.date))+" ")]),_c('p',{staticClass:"label-item"},[_vm._v(" "+_vm._s(_vm.steps[index * 2].actualTime.time)+" "),_c('TimezoneAcronym',{attrs:{"text":_vm.getTzAcronym(
                  _vm.steps[index * 2].actualTime.originalIsoTimeStamp,
                  _vm.steps[index * 2].timezone
                ),"tooltipText":_vm.steps[index * 2].timezone}})],1)]):_c('div',[_vm._v("-")])]),_c('LabeledText',{staticClass:"label-text-element",attrs:{"data-test":"actual-departure","label":_vm.$t('ordering/order-sidebar-departure-label'),"span":"6"}},[(_vm.steps[index * 2 + 1].actualTime.isValid)?_c('div',{staticClass:"label-wrapper"},[_c('p',{staticClass:"label-item"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.steps[index * 2 + 1].actualTime.date))+" ")]),_c('p',{staticClass:"label-item"},[_vm._v(" "+_vm._s(_vm.steps[index * 2 + 1].actualTime.time)+" "),_c('TimezoneAcronym',{attrs:{"text":_vm.getTzAcronym(
                  _vm.steps[index * 2 + 1].actualTime.originalIsoTimeStamp,
                  _vm.steps[index * 2 + 1].timezone
                ),"tooltipText":_vm.steps[index * 2 + 1].timezone}})],1)]):_c('div',[_vm._v("-")])])],1):_vm._e(),(index !== _vm.stops.length - 1)?_c('div',{staticClass:"plankton-grid",attrs:{"columns":"12"}},[_c('LabeledText',{staticClass:"label-text-element",attrs:{"data-test":"next-stop-distance","label":_vm.$t('ordering/order-sidebar-next-stop-distance-label'),"span":"6"}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.$t('ordering/order-sidebar-next-stop-distance-tooltip')),expression:"$t('ordering/order-sidebar-next-stop-distance-tooltip')",modifiers:{"bottom":true}}],staticClass:"distance",attrs:{"href":_vm.googleMapUrl(stop, _vm.stops[index + 1]),"target":"_blank","data-test":"google-map-link"}},[_vm._v(" "+_vm._s(_vm.isTotalValid && _vm.stopsRouteDetails[index] ? ((_vm.getRoundedDistance(_vm.stopsRouteDetails[index].distanceToNext)) + "km") : 'N/A')+" ")])])],1):_vm._e(),(stop.notes)?_c('div',{staticClass:"plankton-grid",attrs:{"columns":"12"}},[_c('LabeledText',{staticClass:"label-text-element",attrs:{"data-test":"notes","label":_vm.$t('ordering/order-sidebar-notes-label'),"span":"12","text":stop.notes}})],1):_vm._e(),(stop.contactPerson)?_c('div',{staticClass:"plankton-grid",attrs:{"columns":"4"}},[_c('LabeledText',{staticClass:"label-text-element",attrs:{"data-test":"contact","label":_vm.$t('ordering/order-sidebar-contact-label'),"text":((_vm.get(stop, 'contactPerson.firstName')) + " " + (_vm.get(
          stop,
          'contactPerson.lastName'
        )))}})],1):_vm._e()],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { MATCHING_API_URL, MATCHING_API_AUDIENCE } from '@/config'
import { runJsonGet } from '@/models/backend-client'
import logger from '@/shell/console-logger'

export const getTransportOfferForShipment = async (
  shipmentId: string
): Promise<string | null> => {
  const url = `${MATCHING_API_URL}/api/transport-offer/`

  logger.debug(
    `Calling Matching Service API with PATH: ${url} to retrieve TO for shipmentID ${shipmentId}`
  )
  try {
    const response = await runJsonGet(
      url,
      { shipmentId: shipmentId },
      { apiGatewayAuthorization: true },
      MATCHING_API_AUDIENCE,
      undefined
    )

    if (response && response.status === 200 && response.data.length === 1) {
      return response.data[0].id
    }
  } catch (error) {
    logger.warn(`Matching service failed for shipment_id: ${shipmentId} ${error}`)
  }

  return null
}

import * as LD from 'launchdarkly-js-client-sdk'
import { VUE_APP_MOCKLAUNCHDARKLY } from '@/config'
import { LAUNCH_DARKLY_MOCK_URL } from '@/config'
import axios from 'axios'
import { E2E_MODE } from '@/config'

export interface User {
  id: number
  email: string
  fullNameWithoutTitle: string
}

const staticAnonymousUuid = 'a44e43e3-dc21-4001-9b44-e78afd80d1f8'

export class FeatureFlagService {
  constructor(private tenant: string, private apiKey?: string) {}

  private client: LD.LDClient | null = null
  private _featureFlags

  async init(): Promise<void> {
    if (E2E_MODE) {
      await this.getFeatureFlags()
    }
    if (!this.apiKey || VUE_APP_MOCKLAUNCHDARKLY) return Promise.resolve()

    this.client = LD.initialize(this.apiKey, {
      anonymous: false,
      key: staticAnonymousUuid
    })

    return new Promise(resolve => this.client?.on('ready', resolve))
  }
  private async getFeatureFlags(): Promise<void> {
    const config = this.assembleCall('get', '/feature-flags', null)
    const result = await axios(config)
    this._featureFlags = result.data
  }
  private assembleCall(method, path, data) {
    const headers = {
      Authorization: '',
      'Content-Type': 'application/json'
    }

    const url = LAUNCH_DARKLY_MOCK_URL + path

    return { headers, method, url, data }
  }
  async identify(user: User): Promise<void> {
    if (!this.client) return Promise.resolve()

    return new Promise(resolve => {
      this.client?.identify(
        {
          name: user.fullNameWithoutTitle,
          email: user.email,
          custom: {
            tenant: this.tenant
          },
          key: `${user.id}`
        },
        `${user.id}`,
        () => resolve()
      )
    })
  }

  async identifyAnonymous(): Promise<void> {
    if (!this.client) return Promise.resolve()

    return new Promise(resolve => {
      this.client?.identify(
        {
          anonymous: false,
          key: staticAnonymousUuid
        },
        null,
        () => resolve()
      )
    })
  }

  isActive(flag: string): boolean {
    if (E2E_MODE) return this._featureFlags[flag] ?? false
    return this.client?.variation(flag, false) ?? false
  }

  getJSONValue(flag: string): object {
    if (E2E_MODE) return this._featureFlags[flag] ?? {}
    return this.client?.variation(flag, {})
  }

  addListener(
    flags: string | string[],
    callback: (value: boolean, previous: boolean) => void
  ): void {
    if (typeof flags === 'string') this.client?.on(`change:${flags}`, callback, flags)
    else if (Array.isArray(flags))
      flags.forEach(flag => this.client?.on(`change:${flag}`, callback, flag))
  }

  removeListener(
    flags: string | string[],
    callback: (value: boolean, previous: boolean) => void
  ): void {
    // we have to pass the flag name as a callback "this" context
    // because the callback accepts just two arguments: current and previous values
    if (typeof flags === 'string') this.client?.off(`change:${flags}`, callback, flags)
    else if (Array.isArray(flags))
      flags.forEach(flag => this.client?.off(`change:${flag}`, callback, flag))
  }

  wasInitialized(): boolean {
    return Boolean(this.client)
  }

  isUserIdentifiedWithId(id: number): boolean {
    return Boolean(this.client && this.client.getUser().key === `${id}`)
  }
}

<template>
  <LimitedChipsTextAreaField
    v-model="filterHandler"
    v-bind="$attrs"
    fullWidth
    :containerStyle="{ maxHeight: '134px', ...containerStyle }"
    :maxLength="100"
    :displayHint="displayHint"
    :allowDuplicates="false"
  />
</template>

<script>
export default {
  inject: ['getFilterValue', 'setFilterValue'],
  props: {
    filterName: { type: String, required: true },
    showHint: { type: Boolean, default: true },
    containerStyle: { type: Object, default: null }
  },
  data() {
    return { chips: [] }
  },
  computed: {
    displayHint() {
      if (this.showHint) {
        return this.$t('filters/sennder-reference-filter-hint')
      }
      return ''
    },
    filterHandler: {
      get() {
        return this.getFilterValue(this.filterName)
      },
      set(value) {
        this.setFilterValue(this.filterName, value)
        this.$emit('applyFilters')
      }
    }
  }
}
</script>

<template>
  <div>
    {{ getReferenceTime() }}
    <br />
    <span v-tooltip.bottom-start="getTooltip()">
      <span>({{ getDelay() }})</span>
    </span>
  </div>
</template>

<script>
import { NOT_AVAILABLE_PLACEHOLDER } from '@sennder/plankton'

export default {
  props: {
    delay: { type: String, default: null },
    scheduledTime: { type: String, default: null },
    referenceTime: { type: String, default: null }
  },
  methods: {
    getTooltip() {
      const scheduledTime = this.scheduledTime || NOT_AVAILABLE_PLACEHOLDER
      return `<strong>Scheduled for:</strong><br>${scheduledTime}`
    },
    getReferenceTime() {
      return this.referenceTime || NOT_AVAILABLE_PLACEHOLDER
    },
    getDelay() {
      return this.delay || NOT_AVAILABLE_PLACEHOLDER
    }
  }
}
</script>

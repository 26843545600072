import { Ref, ref } from '@vue/composition-api'
import { readPersistedState, writePersistedState } from '@/services/local-storage-service'

export function useCollapsedSidebar() {
  const isCollapsed: Ref<boolean> = ref(
    readPersistedState('orderSidebarCollapsed', false)
  )

  const toggleIsCollapsed = () => {
    isCollapsed.value = !isCollapsed.value
    writePersistedState('orderSidebarCollapsed', isCollapsed.value)
  }

  return {
    isCollapsed,
    toggleIsCollapsed
  }
}

<template>
  <Dialog
    :show="visible"
    :lazy="true"
    :inPlace="true"
    v-bind="$attrs"
    :cancelButtonText="
      $t('ordering-share-header/edit-new-order-modal-dialog-cancel-btn-text')
    "
    class="dialog"
    @close="close"
    @confirm="confirm"
    @cancel="close"
  >
    <p class="subtitle">
      <slot />
    </p>
    <CustomerDetailsForm
      ref="customerDetailsForm"
      v-model="customerDetailsValue"
      :templateVisible="templateVisible && !shouldCreateShipmentDraft"
      data-test="customer-details-form"
      :contractTypeVisible="contractTypeVisible"
      :isCopied="isCopied"
    />
  </Dialog>
</template>

<script>
import CustomerDetailsForm from './CustomerDetailsForm'
import { ref, computed } from '@vue/composition-api'
import useStateReset from '@/compositions/useStateReset'

export default {
  components: { CustomerDetailsForm },
  setup() {
    const customerDetailsForm = ref(null)
    const visible = ref(false)
    const templateVisible = ref(false)
    const isCopied = ref(false)
    const customerDetailsValue = ref({
      customer: null,
      address: null,
      referenceNumber: null,
      contact: null,
      regularity: null,
      template: null,
      trackingIdForShipper: null
    })
    const contractTypeVisible = ref(false)
    /**
     * [Shipment Draft]
     */
    const enableShipmentDraftCreation = ref(false)
    let callback = null

    const show = ({
      customer,
      referenceNumber,
      contact,
      regularity,
      showContractTypeField,
      showTemplateField,
      template,
      trackingIdForShipper,
      isCopiedParam,
      /** [Shipment Draft] */
      isShipmentDraftEnabled
    } = {}) => {
      visible.value = true
      templateVisible.value = showTemplateField
      contractTypeVisible.value = showContractTypeField
      enableShipmentDraftCreation.value = isShipmentDraftEnabled
      isCopied.value = isCopiedParam
      customerDetailsValue.value = {
        customer,
        referenceNumber,
        contact,
        regularity,
        template,
        trackingIdForShipper
      }
      return new Promise(resolve => (callback = resolve))
    }

    const confirm = () => {
      const { hasErrors } = customerDetailsForm.value.submit()
      if (hasErrors) return

      const payload = {
        customerCompanyId: customerDetailsValue.value.customer?.id,
        referenceNumber: customerDetailsValue.value.referenceNumber,
        trackingIdForShipper: customerDetailsValue.value.trackingIdForShipper,
        customerContactId: customerDetailsValue.value.contact?.id,
        lineCode: customerDetailsValue.value.template?.lineCode,
        shouldCreateShipmentDraft: shouldCreateShipmentDraft.value
      }

      if (customerDetailsValue.value.regularity) {
        payload.regularity = customerDetailsValue.value.regularity.id
      }
      callback(payload)
      visible.value = false
      reset()
    }

    const close = () => {
      visible.value = false
      callback(null)
    }

    const reset = () => useStateReset(['customerDetailsValue'])

    /**
     * [Shipment Draft]
     */
    const shouldCreateShipmentDraft = computed(() => {
      return enableShipmentDraftCreation.value
    })

    return {
      customerDetailsValue,
      customerDetailsForm,
      visible,
      contractTypeVisible,
      close,
      confirm,
      show,
      templateVisible,
      isCopied,
      shouldCreateShipmentDraft
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog {
  text-align: left;
}
</style>

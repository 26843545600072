import Model from './model'
import { OPERATIONS_BACKEND_URL } from '@/config'
import { humanizeIsoDatetime } from '@sennder/plankton'
import usePersons from '@/compositions/usePersons'
const { personsData } = usePersons()

export default class TransportRequest extends Model {
  static get NAME() {
    return 'transport_requests'
  }

  static get URL() {
    return `${OPERATIONS_BACKEND_URL}/ordering/transport_requests/`
  }

  get order() {
    return this.$state.orders[this.order_id]
  }

  get creator() {
    return personsData.value?.find(p => p.id === this.creator_id)
  }

  get contact() {
    return this.carrier_contact_id
      ? this.$state.contacts[this.carrier_contact_id]
      : this.$state.contacts[this.creator.contact_id]
  }

  get carrier() {
    return this.carrier_id
      ? this.$state.carriers[this.carrier_id]
      : this.$state.carriers[this.contact.carrier_id]
  }

  get humanizedCreated() {
    return humanizeIsoDatetime(this.created)
  }

  get creatorFullName() {
    return `${this.creator.first_name} ${this.creator.last_name}`
  }

  get sennderContact() {
    return this.order.partnerManager
      ? this.order.partnerManager
      : this.order.accountManager
  }
}

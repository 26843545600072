<template>
  <FilterStoreProvider storeName="searchBoard">
    <BoardContainer
      :loadingPage="loadingPage"
      :title="$t('search-board/search-page-title')"
    >
      <template v-if="areFiltersInitialized" slot="aux-section">
        <QuickFilters
          :loadingPage="loadingPage"
          @applyFilters="onFiltersApply"
          @resetFilters="resetFilters"
        >
          <SearchQuickFiltersContent @applyFilters="onFiltersApply" />
        </QuickFilters>
      </template>
      <template v-if="areFiltersInitialized" slot="applied-filters">
        <AppliedFilters @applyFilters="onFiltersApply" />
      </template>

      <BoardTableRow slot="table-header" types="header">
        <th v-for="columnName in columnNames" :key="columnName">
          <span>{{ columnName }}</span>
        </th>
      </BoardTableRow>

      <template slot="table-rows">
        <SearchOrderRow
          v-for="order in sortedOrders"
          :key="order.id"
          :order="order"
          :transfer="transfers[order.id]"
        />
      </template>

      <template #pagination="{ listElement }">
        <ScrollPagination
          slot="pagination"
          :limit="paginationLimit"
          :listElement="listElement"
          :isLoading="loadingPage"
          :areAllPagesFetched="areAllPagesFetched"
          @onNextPageLoad="getFilteredOrders($event, false)"
        />
      </template>

      <BoardFilters
        v-if="areFiltersInitialized"
        slot="filters"
        :loadingPage="loadingPage"
        @applyFilters="onFiltersApply"
        @resetFilters="resetFilters"
      >
        <SearchFiltersContent @applyFilters="onFiltersApply" />
      </BoardFilters>
    </BoardContainer>
  </FilterStoreProvider>
</template>

<script>
import debounce from 'lodash/debounce'
import { BoardContainer, BoardTableRow, ScrollPagination } from '@/modules/boards'
import SearchOrderRow from './SearchOrderRow'
import SearchFiltersContent from './SearchFiltersContent'
import SearchQuickFiltersContent from './SearchQuickFiltersContent'
import {
  BoardFilters,
  QuickFilters,
  AppliedFilters,
  FilterStoreProvider,
  createFiltersMappers
} from '@/modules/filters'

import { mapActions, mapState } from 'vuex'
import { BOARD_PAGINATION_LIMIT } from '../constants'
import useAssignmentDetails from '../compositions/useAssignmentDetails'
import useStore from '@/compositions/useStore'
import { computed } from '@vue/composition-api'
import useTransfersList from '@/compositions/transfer/useTransfersList'
import { trackEvent } from '@/analytics/amplitude'
import { events, trackingModule } from '../events'

const filtersMappers = createFiltersMappers('searchBoard')

export default {
  components: {
    BoardContainer,
    BoardTableRow,
    AppliedFilters,
    SearchOrderRow,
    ScrollPagination,
    BoardFilters,
    QuickFilters,
    SearchFiltersContent,
    SearchQuickFiltersContent,
    FilterStoreProvider
  },
  setup() {
    const { loadAssignmentsForStoreOrders } = useAssignmentDetails()

    const store = useStore()
    const sortedOrders = computed(() => store.getters['searchBoard/sortedOrders'])
    const { transfersByOrderId: transfers } = useTransfersList(sortedOrders)

    return {
      sortedOrders,
      transfers,
      loadAssignmentsForStoreOrders
    }
  },
  data() {
    return {
      paginationLimit: BOARD_PAGINATION_LIMIT,
      columnNames: [
        this.$t('search-board/search-page-sennder-ref-header'),
        this.$t('search-board/search-page-customer-ref-header'),
        this.$t('search-board/search-page-state-header'),
        this.$t('search-board/search-page-account-operation-mananager-header'),
        this.$t('search-board/search-page-operators-header'),
        this.$t('search-board/search-page-customer-header'),
        this.$t('search-board/search-page-carrier-header'),
        this.$t('search-board/search-page-origin-header'),
        this.$t('search-board/search-page-destination-header'),
        this.$t('search-board/search-page-pickup-departure-header'),
        this.$t('search-board/search-page-dropoff-arrival-header'),
        this.$t('search-board/search-page-gps-header'),
        this.$t('search-board/search-page-revenue-header'),
        this.$t('search-board/search-page-cost-header'),
        this.$t('search-board/search-page-vehicle-header')
      ],
      areAllPagesFetched: false
    }
  },
  computed: {
    ...mapState(['loadingPage']),
    ...mapState('searchBoard', ['orders']),
    ...filtersMappers.mapState(['areFiltersInitialized']),
    ...filtersMappers.mapGetters(['getFiltersForApi', 'getQueryFromFilters'])
  },
  async created() {
    await this.setFiltersFromUrl(this.$route.query)
    this.getFilteredOrders()
  },
  async mounted() {
    trackEvent(events.SEARCH_BOARD_VIEW, {
      module: trackingModule
    })
  },
  methods: {
    ...mapActions('searchBoard', ['loadOrdersPage']),
    ...filtersMappers.mapActions(['setFiltersFromUrl', 'resetFilters']),
    async getFilteredOrders(offset = 0, clearBeforeUpdate = true) {
      const newOrders = await this.loadOrdersPage({
        offset,
        limit: this.paginationLimit,
        clearBeforeUpdate
      })
      this.areAllPagesFetched = newOrders.length === 0

      this.loadAssignmentsForStoreOrders(this.orders.map(order => order.id))
    },
    onFiltersApply() {
      this.octopusRouter.setQuery(this.getQueryFromFilters)
      this.getFilteredOrdersDebounced()
    },
    getFilteredOrdersDebounced: debounce(function (...args) {
      this.getFilteredOrders(...args)
    }, 500)
  }
}
</script>

import Model from './model'
import { OPERATIONS_BACKEND_URL } from '../config'
import { isValidDate, getDateHuman, getDateInApiFormat } from '@sennder/plankton'

export class Invoice extends Model {
  get requiredFields() {
    return ['number', 'amount', 'issue_date']
  }

  get invoiceDateHuman() {
    return getDateHuman(this.issue_date)
  }

  set invoiceDateHuman(invoiceDateHuman) {
    this.issue_date = getDateInApiFormat(invoiceDateHuman)
  }

  get isCreditNote() {
    return this.is_credit_note
  }

  set isCreditNote(isCreditNote) {
    this.is_credit_note = isCreditNote
  }

  hasValidDate() {
    return this.issue_date && isValidDate(this.invoiceDateHuman)
  }
}

export class CarrierInvoice extends Invoice {
  static get NAME() {
    return 'carrier_invoices'
  }
  static get URL() {
    return `${OPERATIONS_BACKEND_URL}/ordering/carrier_invoices/`
  }
}

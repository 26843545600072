import {
  fetchOrderNotes,
  addOrderNote,
  deleteOrderNote,
  fetchOrderLogs,
  getPresignedPdfDocumentUrl
} from '../services/sidebar-service'
import { financeService } from '@sennder/finance-frontend'
import { ref, Ref, computed, onBeforeMount } from '@vue/composition-api'
import {
  OrderNote,
  ProcessedNote,
  ProcessedLog,
  ProcessedIncident,
  IActivityRecord,
  OrderLog,
  OrderLogType
} from '../types'
import moment from 'moment'
import { getLogText } from './log-text-builder'
import { getLogTooltipText } from './log-tooltip-builder'
import { StoredIncident, fetchIncidentsPerOrder } from '@sennder/incident-management'
import { fetchUserById } from '@/services/user-service'
import { AxiosError } from 'axios'
import useI18n from '@/compositions/useI18n'
import { OrderStop } from '@/services'
import { useShipment } from '@/modules/shipment/compositions/useShipment'
import { useShipmentActivity } from '@/modules/shipment/compositions/useShipmentActivity'

// these types are wrongly created as NOTEs not activities, we need to filter them out for now
const ACTIVITY_LOGS_TYPES_TO_FILTER = ['comment']

type activity = ProcessedNote | ProcessedLog | ProcessedIncident
type addressToLetterMap = {
  [key: number]: string
}

export let refreshOrderLogs: () => Promise<void> = async () => {}
export let refreshOrderIncidents: () => Promise<void> = async () => {}

export default ({
  orderId,
  content,
  currentUserId,
  stopLetters,
  stops
}: {
  orderId: Ref<number>
  content: Ref<HTMLElement>
  currentUserId: Ref<number>
  stopLetters: Ref<addressToLetterMap>
  stops?: Ref<OrderStop[]>
}) => {
  const { t } = useI18n()

  const notes: Ref<ProcessedNote[]> = ref([])
  const logs: Ref<ProcessedLog[]> = ref([])
  const incidents: Ref<ProcessedIncident[]> = ref([])

  const orderActivitiesSorted = computed(() => {
    // the order is reversed because of
    // flex-direction: column-reverse in Sidebar.vue
    const compareByDate = (o1: activity, o2: activity) => {
      if (moment(o2.date).isBefore(o1.date)) return -1
      else if (moment(o1.date).isBefore(o2.date)) return 1
      else return 0
    }
    return [...notes.value, ...logs.value, ...incidents.value].sort(compareByDate)
  })

  const processOrderNote = (note: OrderNote) =>
    ({
      ...note,
      type: 'Note',
      noteId: note.id,
      id: `note-${note.id}`
    } as ProcessedNote)

  const processOrderLog = log => {
    const stop = stops.value?.find(stop => stop.id === log?.logData?.stopoverId)

    return {
      ...log,
      type: 'Log',
      logId: log.id,
      id: `log-${log.id}`,
      content: getLogText(log, stop),
      tooltipText: getLogTooltipText(log, stop)
    } as ProcessedLog
  }

  const convertActivityToOrderLog = (activity: IActivityRecord) => {
    // create Orderlog from activityrecord
    const convertedActivity: OrderLog = {
      date: new Date(activity.timestamp).toISOString(),
      fileName: activity.payload?.document_id,
      // id of log is not needed will use timestamp as a placeholder
      id: activity.timestamp,
      logType: activity.type as OrderLogType,
      message: activity.payload?.message || JSON.stringify(activity.payload, null, 4),
      user: activity.owner || 'sennder Automation',
      logData: activity.payload
    }

    return convertedActivity
  }
  const processIncident = async (incident: StoredIncident) => {
    let userName = ''

    try {
      const userData = await fetchUserById(incident.userId, true)
      userName = `${userData.firstName} ${userData.lastName}`
    } catch (error) {
      if ((error as AxiosError).response.status === 404)
        userName = t('order-activity-sidebar/log-deleted-user')
    }

    const processed: ProcessedIncident = {
      type: 'Incident',
      id: `incident-${incident.id}`,
      user: userName,
      date: incident.createdAt.toISOString(),
      content: incident,
      stopLetter: stopLetters.value[incident?.warehouseId] ?? null
    }
    return processed
  }

  const loadIncidents = async () => {
    const incidentsList = await fetchIncidentsPerOrder(orderId.value)
    if (incidentsList) {
      const response: ProcessedIncident[] = await Promise.all(
        incidentsList.map(await processIncident)
      )
      incidents.value = response
    }
    scrollToBottom()
  }

  const scrollToBottom = () => {
    content.value?.scroll?.({ top: 0 })
  }

  const fetchNotes = async () => {
    const response = await fetchOrderNotes(orderId.value)
    notes.value = response?.map(processOrderNote) || []
    scrollToBottom()
  }

  const addNote = async (content: string) => {
    const syntheticNote: ProcessedNote = {
      authorId: currentUserId.value,
      authorName: '',
      content,
      date: new Date().toISOString(),
      deleted: false,
      id: 'note-synthetic',
      noteId: -1,
      noteType: 'STANDARD_COMMENT',
      orderId: orderId.value,
      type: 'Note'
    }

    notes.value.push(syntheticNote)
    await addOrderNote(orderId.value, {
      content,
      noteType: 'STANDARD_COMMENT',
      authorId: currentUserId.value
    })
    await fetchNotes()
  }

  const removeNote = async (noteId: number) => {
    await deleteOrderNote(noteId)
    await fetchNotes()
  }

  const fetchLogs = async () => {
    const [orderLogs, financeOrderLogs] = await Promise.all([
      fetchOrderLogs(orderId.value),
      financeService.fetchFinanceOrderLogs(orderId.value)
    ])

    let shipmentActivityRecords = []
    let logsFromActivities = []
    let filteredLogsFromActivities = []

    const { getShipmentIdByOrderId } = useShipment()
    const shipmentId = await getShipmentIdByOrderId(orderId.value)
    if (shipmentId) {
      const { fetchShipmentActivity } = useShipmentActivity()
      shipmentActivityRecords = await fetchShipmentActivity(shipmentId)
      // reshape shipmentActivityRecords as orderLogs
      logsFromActivities = shipmentActivityRecords?.map(convertActivityToOrderLog) || []
      // Avoid duplication: filter out orderLogs that that already exist in as legacy logs
      filteredLogsFromActivities = logsFromActivities?.filter(activity => {
        const isDuplicate = orderLogs.some(
          log =>
            log.logType.toUpperCase() === activity.logType.toUpperCase() ||
            ACTIVITY_LOGS_TYPES_TO_FILTER.includes(activity.logType)
        )
        return !isDuplicate
      })
    }
    logs.value =
      [...orderLogs, ...filteredLogsFromActivities, ...financeOrderLogs].map(
        processOrderLog
      ) || []
    scrollToBottom()
  }

  const openDocument = async (documentType: string, fileName: string) => {
    const url = await getPresignedPdfDocumentUrl(documentType, fileName)
    window.location.replace(url)
  }

  onBeforeMount(() => {
    refreshOrderLogs = fetchLogs
    refreshOrderIncidents = loadIncidents
    const fetchActions = [fetchLogs(), fetchNotes(), loadIncidents()]
    Promise.all(fetchActions)
  })

  return {
    orderActivitiesSorted,
    incidents,
    notes,
    logs,
    addNote,
    removeNote,
    openDocument
  }
}

<template>
  <i v-tooltip="{ content: tooltipText, classes: customClass }" class="material-icons">
    error
  </i>
</template>

<script>
export default {
  props: {
    tooltipText: { type: String, required: true },
    customClass: { type: String, default: '' }
  }
}
</script>

<style lang="scss" scoped>
.material-icons {
  color: $color-secondary-main;
  font-size: 16px;
  cursor: pointer;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{class:{
    filters: true,
    paragraph: true,
    'filters--core': _vm.isMicrofrontend
  },style:({
    top: _vm.topOffset + 'px',
    height: _vm.dynamicHeight + 'px'
  }),attrs:{"data-test":"filters"},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilters.apply(null, arguments)}}},[_c('header',{staticClass:"filters__header"},[_c('h3',{staticClass:"filters__title"},[_vm._v(_vm._s(_vm.$t('filters/board-filters-title')))]),_c('button',{staticClass:"filters__close-btn",attrs:{"type":"button","data-test":"collapse-filters"},on:{"click":_vm.collapseFiltersArea}},[_c('i',{staticClass:"material-icons"},[_vm._v("close")])])]),_vm._t("default"),_c('footer',{staticClass:"filters__footer"},[_c('Button',{attrs:{"disabled":_vm.loadingPage,"loading":_vm.loadingPage,"category":"link","type":"button","text":_vm.$t('filters/board-filters-clear-filters-label'),"materialIcon":"close","data-test":"clear-filters"},on:{"click":_vm.resetFilters}}),_c('Button',{attrs:{"disabled":_vm.loadingPage,"loading":_vm.loadingPage,"category":"primary","text":_vm.$t('filters/board-filters-apply-filter-button-label'),"type":"submit","data-test":"apply-filters"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
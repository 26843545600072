export const getWaypoints = stops => {
  if (stops.length > 0) {
    return stops
      .slice(1, -1)
      .map(s => s.warehouseAddress.fullAddress)
      .join('|')
  }
  return ''
}

export const googleMapUrl = (currentStop, nextStop, stops = []) => {
  const currentStopAddress = currentStop.warehouseAddress.fullAddress
  const waypoints = getWaypoints(stops)
  const destinationAddress = nextStop?.warehouseAddress?.fullAddress
  const GOOGLE_MAPS_DIRECTIONS_URL =
    'http://maps.google.com/maps/dir/?api=1&travelmode=driving'
  return `${GOOGLE_MAPS_DIRECTIONS_URL}&origin=${currentStopAddress}&destination=${destinationAddress}&waypoints=${waypoints}`
}

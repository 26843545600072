








































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { formatTimestamps } from '../timestamps'
import { ProcessedNote } from '../types'

const UUID_PATTERN =
  /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/

export default defineComponent({
  props: {
    entryData: { type: Object as PropType<ProcessedNote>, required: true },
    currentUserId: { type: Number, required: true },
    isLoading: { type: Boolean, default: false }
  },
  setup(props) {
    const noteClasses = computed(() => {
      const classes = []
      if (props.entryData.deleted) {
        classes.push('note-deleted')
      }
      return classes
    })

    const canDeleteNote = computed(
      () => props.entryData.authorId === props.currentUserId && !props.entryData.deleted
    )

    const formattedDateTime = computed(() => formatTimestamps(props.entryData.date))

    // Temporary solution. Instead of identifying by UUID we should fetch logs
    const isUUID = computed(() => UUID_PATTERN.test(props.entryData.content))

    return { noteClasses, canDeleteNote, formattedDateTime, isUUID }
  }
})

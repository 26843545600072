<template>
  <FilterStoreProvider storeName="requestBoard">
    <BoardContainer
      :loadingPage="loadingPage"
      :title="$t('request-board/request-page-title')"
    >
      <QuickFilters
        v-if="areFiltersInitialized"
        slot="aux-section"
        :loadingPage="loadingPage"
        @applyFilters="onFiltersApply"
        @resetFilters="resetFilters"
      >
        <RequestFiltersContent @applyFilters="onFiltersApply" />
      </QuickFilters>

      <BoardTableRow slot="table-header" types="header">
        <th data-test="reference-col-header">
          {{ $t('request-board/request-page-columns-header-sen-ref') }}
        </th>
        <th data-test="ops-aom-col-header">
          <span>{{ $t('request-board/request-page-columns-header-saom-aom') }}</span>
        </th>
        <th data-test="origin-col-header">
          {{ $t('request-board/request-page-columns-header-origin') }}
        </th>
        <th data-test="destination-col-header">
          {{ $t('request-board/request-page-columns-header-destination') }}
        </th>
        <th data-test="pickup-col-header">
          {{ $t('request-board/request-page-columns-header-pickup') }}
        </th>
        <th data-test="dropoff-col-header">
          {{ $t('request-board/request-page-columns-header-dropoff') }}
        </th>
        <th data-test="carrier-col-header">
          {{ $t('request-board/request-page-columns-header-carrier') }}
        </th>
        <th data-test="contact-col-header">
          {{ $t('request-board/request-page-columns-header-contact') }}
        </th>
        <th data-test="requested-at-col-header">
          {{ $t('request-board/request-page-columns-header-requested-at') }}
        </th>
        <th data-test="base-price-col-header">
          {{ $t('request-board/request-page-columns-header-base-price') }}
        </th>
        <th data-test="cost-col-header">
          {{ $t('request-board/request-page-columns-header-cost-indication') }}
        </th>
      </BoardTableRow>

      <template slot="table-rows">
        <RequestRow
          v-for="transportRequest in filteredTransportRequests"
          :key="transportRequest.id"
          :transportRequest="transportRequest"
        />
      </template>
    </BoardContainer>
  </FilterStoreProvider>
</template>

<script>
import debounce from 'lodash/debounce'

import { BoardContainer, BoardTableRow } from '@/modules/boards'
import RequestRow from './RequestRow'
import RequestFiltersContent from './RequestFiltersContent'
import {
  QuickFilters,
  FilterStoreProvider,
  createFiltersMappers
} from '@/modules/filters'

import { mapActions, mapState } from 'vuex'
import TransportRequest from '@/models/transport-request'
import { createPersonEventForVisitingRequestBoard } from '@/services/person-actions-service'
import { trackEvent } from '@/analytics/amplitude'
import { events, trackingModule } from '../events'

const filtersMappers = createFiltersMappers('requestBoard')

export default {
  components: {
    BoardContainer,
    BoardTableRow,
    RequestRow,
    QuickFilters,
    RequestFiltersContent,
    FilterStoreProvider
  },
  computed: {
    ...mapState(['loadingPage']),
    ...mapState('requestBoard', ['transportRequests']),
    ...filtersMappers.mapState(['areFiltersInitialized']),
    ...filtersMappers.mapGetters(['getFiltersForApi', 'getQueryFromFilters']),
    filteredTransportRequests() {
      return Object.values(this.transportRequests).filter(
        transportRequest =>
          transportRequest.order && transportRequest.order.state === 'REGISTERED'
      )
    }
  },
  beforeCreate() {
    this.$store.commit('flush', { Model: TransportRequest })
  },
  async created() {
    createPersonEventForVisitingRequestBoard()
    await this.setFiltersFromUrl(this.$route.query)
    this.getFilteredOrders()
  },
  async mounted() {
    trackEvent(events.REQUEST_BOARD_VIEW, {
      module: trackingModule
    })
  },
  methods: {
    ...mapActions('requestBoard', ['fetchRequestBoardData']),
    ...filtersMappers.mapActions(['setFiltersFromUrl', 'resetFilters']),
    getFilteredOrders() {
      this.fetchRequestBoardData()
    },
    onFiltersApply() {
      this.octopusRouter.setQuery(this.getQueryFromFilters)
      this.getFilteredOrdersDebounced()
    },
    getFilteredOrdersDebounced: debounce(function (...args) {
      this.getFilteredOrders(...args)
    }, 500)
  }
}
</script>


































































































































































































import CustomerTypeAheadField from '@/components/CustomerTypeAheadField.vue'
import CustomerContactDropdownField from '@/modules/ordering/components/shared/CustomerContactDropdownField.vue'
import CustomerAddressDropdownField from '@/modules/ordering/components/shared/CustomerAddressDropdownField.vue'
import { STOP_TYPES } from '@/modules/ordering/constants'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import moment from 'moment-timezone'
import OrderStopLayoutFull from './OrderStopLayoutFull.vue'
import OrderStopLayoutCompact from './OrderStopLayoutCompact.vue'
import { defineComponent, PropType, computed, ref } from '@vue/composition-api'
import { StopoverType } from '@/services/enums'
import { Company, CustomerAddress, ContactBase, OrderStop } from '@/services'
import { FormFieldValidators } from '@/modules/ordering/types'
import useCurrentUser from '@/compositions/useCurrentUser'
import * as USER_PERMISSIONS from '@/components/user-permissions'
import { DATE_TIME_FORMAT, getTimezoneAcronym } from '@/global-setup/filters'
import { TimezoneAcronym, Timestamp } from '@sennder/plankton'
import useTooltipManagment from '@/compositions/useTooltipManagment'

interface StopTypeOption {
  label: string
  value: string
}

interface DateRange {
  startDate: string
  endDate: string
}

export const dateTimeMomentInWarehouse = (
  date: string,
  time: string,
  tz: string
): moment.Moment => {
  return moment.tz(`${date} ${time}`, DATE_TIME_FORMAT, tz).utc()
}

export default defineComponent({
  components: {
    CustomerTypeAheadField,
    CustomerContactDropdownField,
    CustomerAddressDropdownField,
    TimezoneAcronym
  },
  props: {
    // TODO: update default values to get full TS support. See https://v3.vuejs.org/guide/typescript-support.html#annotating-props
    type: {
      type: String as PropType<StopoverType | null>,
      default: null
    },
    customer: { type: Object as PropType<Company | null>, default: null },
    scheduleDisabled: { type: Boolean, default: false },
    warehouseAddress: {
      type: Object as PropType<CustomerAddress | null>,
      default: null
    },
    referenceNumber: { type: String, default: null },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    startTime: { type: String, required: true },
    endTime: { type: String, required: true },
    isDefaultStop: { type: Boolean, default: true },
    prevStop: {
      type: Object as PropType<OrderStop | null>,
      default: null
    },
    nextStop: { type: Object as PropType<OrderStop | null>, default: null },
    disabled: { type: Boolean, default: false },
    contact: {
      type: Object as PropType<ContactBase | null>,
      default: null
    },
    notes: { type: String, default: null },
    stopTypeDisabled: { type: Boolean, default: false },
    warehouseAddressDisabled: { type: Boolean, default: false },
    compactView: { type: Boolean, default: false },
    isOvernightLoad: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    const dateTimeToTimestamp = (
      date: string,
      time: string,
      timezone: string
    ): Timestamp => {
      const timestamp = new Timestamp(null)
      timestamp.timezone = timezone
      timestamp.date = date
      timestamp.time = time
      return timestamp
    }

    const {
      isShowArrivalTooltip: isShowStartTimeTooltip,
      isShowDepartureTooltip: isShowEndTimeTooltip,
      hideArrivalTooltip: hideStartTimeTooltip,
      showArrivalTooltip: showStartTimeTooltip,
      hideDepartureTooltip: hideEndTimeTooltip,
      showDepartureTooltip: showEndTimeTooltip
    } = useTooltipManagment()

    const isShowEndTimeAcronym = computed(() => {
      const endDate = props.isOvernightLoad
        ? selectedEndDate.value.endDate
        : selectedStartDate.value.startDate
      return dateTimeToTimestamp(
        endDate,
        '00:00',
        props.warehouseAddress?.timezone ?? null
      ).isValid
    })

    const endTimeAcronym = computed(() => {
      const endDate = props.isOvernightLoad
        ? selectedEndDate.value.endDate
        : selectedStartDate.value.startDate
      const dateTimeData = dateTimeToTimestamp(
        endDate,
        '00:00',
        props.warehouseAddress?.timezone ?? null
      )
      return getTimezoneAcronym(
        dateTimeData.isoTimeStamp,
        props.warehouseAddress?.timezone ?? null
      )
    })

    const isShowStartTimeAcronym = computed(() => {
      return dateTimeToTimestamp(
        selectedStartDate.value.startDate,
        '00:00',
        props.warehouseAddress?.timezone ?? null
      ).isValid
    })

    const startTimeAcronym = computed(() => {
      const dateTimeData = dateTimeToTimestamp(
        selectedStartDate.value.startDate,
        '00:00',
        props.warehouseAddress?.timezone ?? null
      )
      return getTimezoneAcronym(
        dateTimeData.isoTimeStamp,
        props.warehouseAddress?.timezone ?? null
      )
    })

    const showContactAndNotes = ref(Boolean(props.contact) || Boolean(props.notes))

    const { hasPermission } = useCurrentUser()

    const ComponentLayout = computed(() =>
      props.compactView ? OrderStopLayoutCompact : OrderStopLayoutFull
    )

    const selectedStopType = computed<StopTypeOption | null>({
      get: () => STOP_TYPES.find(v => v.value === props.type),
      set: (option: StopTypeOption): void => emit('update:type', option.value)
    })

    const selectedCustomer = computed<Company | null>({
      get: () => props.customer,
      set: (value: Company | null): void => {
        if (!value) return
        const { id, name } = value
        emit('update:customer', { id, name })
      }
    })

    const selectedAddress = computed<CustomerAddress | null>({
      get: () => props.warehouseAddress,
      set: (value: CustomerAddress | null): void => emit('update:warehouseAddress', value)
    })

    const selectedStartDate = computed<DateRange | null>({
      get: () => ({
        startDate: props.startDate,
        endDate: props.startDate
      }),
      set: async (value: DateRange): Promise<void> => {
        if (props.isOvernightLoad) {
          await emit('update:startDate', value.startDate)
        } else {
          await emit('update:startDate', value.startDate)
          await emit('update:endDate', value.startDate)
        }
      }
    })

    const selectedEndDate = computed<DateRange | null>({
      get: () => ({
        startDate: props.endDate,
        endDate: props.endDate
      }),
      set: async (value: DateRange): Promise<void> =>
        await emit('update:endDate', value.startDate)
    })

    const dateFormat = (classes: { disabled: boolean }, date: Date) => {
      date.setHours(0, 0, 0, 0)
      let currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0)
      if (
        !classes.disabled &&
        !hasPermission(USER_PERMISSIONS.CAN_EDIT_PICKUP_DATE_WITHOUT_LIMITATIONS)
      ) {
        classes.disabled =
          moment(date.getTime()).unix() <
            moment(currentDate).subtract(1, 'months').unix() ||
          moment(date.getTime()).unix() > moment(currentDate).add(6, 'months').unix()
      }
      return classes
    }

    const stopTypeValidators: FormFieldValidators<StopTypeOption | null> = computed(
      () => [
        { isInvalid: isEmpty, errorMessage: 'Stop type cannot be empty', inline: false }
      ]
    )

    const customerValidators: FormFieldValidators<Company | null> = computed(() => [
      { isInvalid: isEmpty, errorMessage: 'Company name cannot be empty', inline: false }
    ])

    const dateValidators: FormFieldValidators<DateRange | null> = computed(() => [
      {
        isInvalid: value => {
          return isEmpty(value.startDate)
        },
        errorMessage: 'Date cannot be empty',
        inline: false
      }
    ])

    const startTimeValidators: FormFieldValidators<string> = computed(() => [
      { isInvalid: isEmpty, errorMessage: 'Time cannot be empty', inline: false },
      {
        isInvalid: value => {
          if (
            isEmpty(value) ||
            isEmpty(props.startDate) ||
            isEmpty(props.endDate) ||
            isEmpty(get(props.prevStop, 'startDate')) ||
            isEmpty(get(props.prevStop, 'endTime'))
          )
            return false
          return dateTimeMomentInWarehouse(
            props.startDate,
            value,
            props.warehouseAddress.timezone
          ).isSameOrBefore(
            dateTimeMomentInWarehouse(
              props.prevStop.startDate,
              props.prevStop.endTime,
              props.prevStop.warehouseAddress.timezone
            )
          )
        },
        errorMessage:
          'Start time and end time of consecutive stops cannot overlap during the same day',
        inline: false
      }
    ])

    const endTimeValidators: FormFieldValidators<string> = computed(() => [
      { isInvalid: isEmpty, errorMessage: 'Time cannot be empty', inline: false },
      {
        isInvalid: endTime => {
          if (isEmpty(endTime) && isEmpty(props.startTime)) return false
          return dateTimeMomentInWarehouse(
            props.endDate,
            endTime,
            props.warehouseAddress.timezone
          ).isBefore(
            dateTimeMomentInWarehouse(
              props.startDate,
              props.startTime,
              props.warehouseAddress.timezone
            )
          )
        },
        errorMessage: 'End time cannot be before start time',
        inline: false
      },
      {
        isInvalid: endTime => {
          if (
            isEmpty(endTime) ||
            isEmpty(props.startDate) ||
            isEmpty(get(props.nextStop, 'startDate')) ||
            isEmpty(get(props.nextStop, 'startTime'))
          )
            return false
          return dateTimeMomentInWarehouse(
            props.endDate,
            endTime,
            props.warehouseAddress.timezone
          ).isSameOrAfter(
            dateTimeMomentInWarehouse(
              props.nextStop.startDate,
              props.nextStop.startTime,
              props.nextStop.warehouseAddress.timezone
            )
          )
        },
        errorMessage:
          'Start time and end time of consecutive stops cannot overlap during the same day',
        inline: false
      }
    ])

    return {
      showContactAndNotes,
      ComponentLayout,
      selectedStopType,
      selectedCustomer,
      selectedAddress,
      selectedStartDate,
      selectedEndDate,
      STOP_TYPES,
      stopTypeValidators,
      customerValidators,
      dateFormat,
      dateValidators,
      startTimeValidators,
      endTimeValidators,
      get,
      isShowEndTimeAcronym,
      endTimeAcronym,
      isShowStartTimeAcronym,
      startTimeAcronym,
      isShowStartTimeTooltip,
      isShowEndTimeTooltip,
      hideStartTimeTooltip,
      showStartTimeTooltip,
      hideEndTimeTooltip,
      showEndTimeTooltip
    }
  }
})

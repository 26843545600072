<template>
  <h1 :class="{ 'title-dark': isPosteTheme }">
    <slot></slot>
  </h1>
</template>

<script>
import { isPosteTheme } from '@/controllers/environment-detection'
export default {
  computed: {
    isPosteTheme() {
      return isPosteTheme
    }
  }
}
</script>

<style scoped lang="scss">
.title-dark {
  color: $color-neutral-darkest;
}
</style>

import { computed, Ref } from '@vue/composition-api'
import { TransferLocation } from '@/services'

interface Marker {
  latitude: number
  longitude: number
}

export default (locations: Ref<TransferLocation[]>) => {
  const getRotation = (marker1: Marker, marker2: Marker) => {
    const deltaLat = marker2.latitude - marker1.latitude
    const deltaLng = marker2.longitude - marker1.longitude
    return (Math.atan(deltaLng / deltaLat) * 180) / Math.PI + (deltaLat < 0 ? 180 : 0)
  }

  const trackingLocations = computed(() => {
    const sortedLocations = [...locations.value].sort((a, b) =>
      a.time > b.time ? 1 : -1
    )
    return sortedLocations.map((location, index, allLocations) => {
      const nextLocation = allLocations[index + 1]
      const rotation = nextLocation ? getRotation(location, nextLocation) : 0
      return {
        id: index,
        lat: location.latitude,
        lng: location.longitude,
        time: location.time,
        rotation
      }
    })
  })

  const latestLocationTimeStamp = computed(() => locations.value[0]?.time)
  const hasGpsReceived = computed(() => Boolean(locations?.value.length))

  return { trackingLocations, latestLocationTimeStamp, hasGpsReceived }
}












































































































































































































import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import { DropdownField } from '@sennder/plankton'

import { FORMATTED_VEHICLE_TYPES, LOAD_TYPES } from '@sennder/octopus-constants'
import { ref, computed, watch, defineComponent } from '@vue/composition-api'
import { i18n } from '@/plugins/i18n'

export default defineComponent({
  components: {
    DropdownField
  },
  props: {
    allowedVehicleTypes: { type: Array, default: () => [] },
    vehicleType: { type: String, default: null },
    loadQuantity: { type: [Number, String], default: null },
    loadUnitType: { type: String, default: null },
    loadWeight: { type: Number, default: null },
    loadLength: { type: Number, default: null },
    loadHeight: { type: Number, default: null },
    loadWidth: { type: Number, default: null },
    loadDescription: { type: String, default: null },
    sealable: { type: Boolean, default: false },
    codeXl: { type: Boolean, default: false },
    arrivalNotification: { type: Boolean, default: false },
    directTransfer: { type: Boolean, default: false },
    dockLoading: { type: Boolean, default: false },
    sideLoading: { type: Boolean, default: false },
    topLoading: { type: Boolean, default: false },
    useTemperature: { type: Boolean, default: false },
    orderStatus: { type: String, required: true },
    disabled: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    const vehicleTypeValidators = computed(() => [
      {
        isInvalid: isEmpty,
        errorMessage: i18n.t('ordering/load-section-vehicle-type-not-empty'),
        inline: false
      }
    ])

    const loadForm = ref(null)

    const submit = () => {
      return loadForm.value?.submit()
    }

    const additionalVehicleTypeValidators = computed(() => [
      {
        isInvalid: (option: typeof FORMATTED_VEHICLE_TYPES) => {
          if (isEmpty(option)) return false
          if (option.length) {
            return (
              props.useTemperature &&
              (option.length !== 1 || option[0]?.value !== 'TRUCK_40_FRIGO')
            )
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return props.useTemperature && option.value !== 'TRUCK_40_FRIGO'
          }
        },
        errorMessage: i18n.t(
          'ordering/load-section-when-temp-control-selected-frigo-only'
        ),
        inline: false
      }
    ])

    const lowestTemperatureValidators = computed(() => [
      {
        isInvalid: (value: number) => props.useTemperature && isEmpty(value),
        errorMessage: i18n.t(
          'ordering/load-section-when-temp-control-selected-lowest-temp-not-empty'
        ),
        inline: false
      }
    ])

    const highestTemperatureValidators = computed(() => [
      {
        isInvalid: (value: number) => props.useTemperature && isEmpty(value),
        errorMessage: i18n.t(
          'ordering/load-section-when-temp-control-selected-highest-temp-not-empty'
        ),
        inline: false
      }
    ])

    const loadTypeSelected = computed<typeof LOAD_TYPES[number]>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      get() {
        return find(LOAD_TYPES, { value: props.loadUnitType })
      },
      set(option: typeof LOAD_TYPES[number] | null) {
        emit('update:loadUnitType', option?.value || null)
      }
    })

    const legacyLoadType = computed<string | null>(() =>
      loadTypeSelected.value ? loadTypeSelected.value?.label : null
    )

    const areVehicleTypeSelectorsDisabled = computed<boolean>(
      () =>
        props.disabled ||
        !(
          props.orderStatus === 'REGISTERED' ||
          props.orderStatus === 'CARRIER_LOCKED' ||
          props.orderStatus === 'DISPATCHED'
        )
    )

    const allowedVehicleTypesSelected = computed<typeof FORMATTED_VEHICLE_TYPES>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      get() {
        if (!props.allowedVehicleTypes) return []
        else {
          return props.allowedVehicleTypes
            .map(type =>
              FORMATTED_VEHICLE_TYPES.find(formattedType => formattedType?.value === type)
            )
            .filter(item => !!item)
        }
      },
      set(value: typeof FORMATTED_VEHICLE_TYPES) {
        emit(
          'update:allowedVehicleTypes',
          value.map(type => type?.value)
        )
      }
    })

    const preferedVehicleTypeSelected = computed<typeof FORMATTED_VEHICLE_TYPES>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      get() {
        return find(FORMATTED_VEHICLE_TYPES, { value: props.vehicleType })
      },
      set(option: typeof FORMATTED_VEHICLE_TYPES[number] | null) {
        emit('update:vehicleType', option?.value)
      }
    })

    watch(preferedVehicleTypeSelected, (newV, oldV) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const newValue = newV?.value
      if (!newValue) return
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const filteredTypes = props.allowedVehicleTypes.filter(el => el !== oldV?.value)

      if (!filteredTypes.includes(newValue)) {
        emit('update:allowedVehicleTypes', [...filteredTypes, newValue])
      }
    })

    return {
      LOAD_TYPES,
      FORMATTED_VEHICLE_TYPES,
      vehicleTypeValidators,
      preferedVehicleTypeSelected,
      lowestTemperatureValidators,
      loadTypeSelected,
      legacyLoadType,
      allowedVehicleTypesSelected,
      highestTemperatureValidators,
      additionalVehicleTypeValidators,
      areVehicleTypeSelectorsDisabled,
      loadForm,
      submit
    }
  }
})









import { defineComponent } from '@vue/composition-api'
import Vue from 'vue'
import { PropType } from '@vue/composition-api'
import {
  createRandomString,
  getEnvironmentAliasForFederatedLoader
} from '@/microfrontends/utils'
import { Location, RawLocation, Route } from 'vue-router'
import {
  IUser,
  Tenant,
  SharedDataType
} from '@sennder/senn-node-microfrontend-interfaces'
import { trackEvent } from '@/analytics/amplitude'
import { OPERATIONS_BACKEND_URL } from '@/config'
import { getAuthHeader } from '@/services/auth-service'
import useI18n from '@/compositions/useI18n'
import useCurrentUser from '@/compositions/useCurrentUser'
import { getAppTheme } from '@/controllers/environment-detection'
import { getLogger } from '@/shell/datadog-logger'
import { monitorError } from '@/analytics/monitoring'
import { fetchMicrofrontend } from '@/microfrontends/fetchMicrofrontend'
import { OrderStop, SpotCosting } from '@/services'

const DEV_PORT = 9115
const NAMESPACE = 'assignmentsbuttonmf'
const NPM_PATH = 'assignments-button-mf'
const REMOTE = './assignmentsbuttonmfApp'

const EMITTERS = {
  onSubmit: 'onSubmit'
}

export default defineComponent({
  name: 'AssignmentsButtonMf',
  props: {
    selectedIds: { type: Array as PropType<string[] | number[]>, required: true },
    isAssignmentsManagementEnabled: { type: Boolean, required: true },
    order: { type: Object, required: true },
    orderStops: { type: Array as PropType<OrderStop[]>, required: true },
    spotCosting: { type: Object as PropType<SpotCosting>, required: true },
    hasBeenCarrierLocked: { type: Boolean, required: true },
    shipmentId: { type: String, required: true }
  },
  emits: [EMITTERS.onSubmit],
  setup(_, { emit }) {
    const onSubmit = () => {
      emit(EMITTERS.onSubmit)
    }
    return {
      onSubmit
    }
  },

  data() {
    return {
      id: `${createRandomString()}-assignments-button`,
      isLoaded: null as boolean | null,
      syncChildRouter: null as ((route: RawLocation) => void) | null,
      unmount: null as (() => void) | null
    }
  },
  async mounted() {
    const { locale } = useI18n()
    try {
      const { user } = useCurrentUser()

      const syncParentRouter = (route: RawLocation) => {
        const currentPath = this.$route.fullPath
        if (
          (typeof route === 'string' && currentPath === route) ||
          (typeof route === 'object' && currentPath === (route as Location).path)
        ) {
          return
        }
        this.$router.push(route)
      }

      const mount = await fetchMicrofrontend({
        devPort: DEV_PORT,
        environment: getEnvironmentAliasForFederatedLoader(NAMESPACE),
        moduleFederationPluginName: NAMESPACE,
        npmName: NPM_PATH,
        mountExposedName: REMOTE,
        logger: getLogger()
      })

      const mfInstance = mount(`#${this.id}`, {
        data: {
          selectedIds: this.selectedIds,
          isAssignmentsManagementEnabled: this.isAssignmentsManagementEnabled,
          order: this.order,
          orderStops: this.orderStops,
          spotCosting: this.spotCosting,
          hasBeenCarrierLocked: this.hasBeenCarrierLocked,
          shipmentId: this.shipmentId,
          type: SharedDataType.OCTOPUS,
          user: user.value as IUser,
          language: locale.value,
          env: { OPERATIONS_BACKEND_URL },
          tenant: getAppTheme() as Tenant,
          onSubmitedForm: this.onSubmit,
          featureFlags: {}
        },
        callbacks: {
          getAuthHeader,
          getAuthToken: getAuthHeader,
          getToken: getAuthHeader,
          syncParentRouter,
          segmentTrackEvent: trackEvent,
          onUnauthorized: () => {},
          onUnhandledError: error => {
            let errorObject = error.response.data

            if (
              errorObject.detail &&
              errorObject.detail.error_code ===
                'carrier-assign.domestic_carrier_restricted' &&
              errorObject.detail.arguments &&
              errorObject.detail.arguments.carrier_company_cc
            ) {
              const vatCountry = errorObject.detail.arguments.carrier_company_cc

              this.alertModal.showErrorMessage(
                'Error',
                this.$t(
                  'ordering/dispatching-allocation-list-account-manager-assign-carrier-restricted',
                  { vatCountry }
                )
              )

              return
            }
            Vue.config.errorHandler(error, null, 'AssignmentsButton Microfrontend')
          }
        },
        providers: {
          logger: getLogger(),
          notifications: null,
          translations: null,
          analytics: null,
          monitoring: null,
          // segment is deprecated, use Amplitude provider instead
          segment: {
            trackEvent,
            setContext: () => {
              getLogger().error('setContext is not implemented', {})
            },
            trackPage: () => {
              getLogger().error('trackPage is not implemented', {})
            }
          }
        }
      })

      if (mfInstance.syncChildRouter) {
        this.syncChildRouter = mfInstance.syncChildRouter
        this.$watch(
          '$route',
          (to: Route) => {
            mfInstance.syncChildRouter(to.fullPath)
          },
          {
            immediate: true
          }
        )
      }
      if (mfInstance.unmount) {
        this.unmount = mfInstance.unmount
      }
      this.isLoaded = true
    } catch (e) {
      monitorError(e, 'AssignmentsButtonMf', {
        type: 'bootstrap'
      })
      this.isLoaded = false
    }
  },
  destroyed() {
    if (this.unmount) {
      this.unmount()
    }
  }
})

import { Currency } from '@/modules/common'
import { formatIntegerWithThousands, formatWithThousands } from '@sennder/plankton'
import moment from 'moment'

export const DATE_FORMAT = 'DD.MM.YYYY'
export const TIME_FORMAT = 'HH:mm'
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`

export const getCurrencyIcon = (currency: Currency) => {
  switch (currency) {
    case Currency.PLN:
      return 'zł'
    case Currency.EUR:
    default:
      return '€'
  }
}

// Move this into plankton.
const basicFormatMoneyWithDashForNull = function (
  number: number | null | undefined,
  formattingFunction: (arg: number) => string,
  currency: Currency = Currency.EUR
): string {
  if (number === null) return '-'
  // In case of bugs we show an X to our users so they can easily spot/report it
  if (number === undefined || isNaN(number)) return 'X'
  else {
    const sign = number < 0 ? '-' : ''
    const formattedValue = formattingFunction(Math.abs(number))
    return `${sign}${getCurrencyIcon(currency)}${formattedValue}`
  }
}

export const formatMoneyWithDashForNull = function (
  number: number,
  currency: Currency = Currency.EUR
): string {
  return basicFormatMoneyWithDashForNull(number, formatWithThousands, currency)
}

export const formatRoundedMoneyWithDashForNull = function (number: number): string {
  return basicFormatMoneyWithDashForNull(number, formatIntegerWithThousands)
}

export const formatMoneyWithZeroForNull = function (number: number | null): string {
  return formatMoneyWithDashForNull(number === null ? 0 : number)
}

export const formatDate = (date: string | Date): string => {
  return moment(date).format(DATE_FORMAT)
}

export const formatTime = (time: string | Date): string => {
  return moment(time).format(TIME_FORMAT)
}

export const formatDateTime = date => {
  return moment(date).format(DATE_TIME_FORMAT)
}

export const formatDateWithTz = (isoTimestamp: string, timezone: string): string =>
  moment.tz(isoTimestamp, timezone).format(`${DATE_FORMAT}`)

export const formatTimeWithTz = (isoTimestamp: string, timezone: string): string =>
  moment.tz(isoTimestamp, timezone).format(`${TIME_FORMAT}`)

export const getTimezoneAcronym = (isoTimestamp: string, timezone: string): string => {
  const missingTzRegex = new RegExp(/(?<sign>[+-])(?<utc>\d{1,2})/)
  let tzAcronym = moment(isoTimestamp).tz(timezone).format('z')

  if (tzAcronym === '+00') {
    tzAcronym = 'UTC'
  } else if (missingTzRegex.test(tzAcronym)) {
    tzAcronym = tzAcronym.replace('0', '')
    tzAcronym = 'UTC' + tzAcronym
  }

  return tzAcronym
}

export const capitalize = (value: string | null, onlyFirst = false): string => {
  if (!value) return ''
  value = value.toString()
  if (!onlyFirst) {
    value = value.toLowerCase()
  }
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const placeholderIfEmpty = (value: string | null): string =>
  String(value || '').trim() || '-'

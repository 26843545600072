







import { isProductionUrl } from '@/controllers/environment-detection'

export default {
  name: 'App',
  computed: {
    isProduction() {
      return isProductionUrl() || window.location.href.includes('localhost')
    }
  }
}

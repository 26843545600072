<template>
  <div class="quick-filters-content plankton-grid">
    <TextInputFilter
      filterName="referenceNumber"
      :title="$t('search-board/search-quick-filters-content-reference-filter-title')"
      data-test="reference-number-filter"
      class="plankton-cell"
      span="4"
    />
    <SennderReferenceFilter
      filterName="idsForStaff"
      data-test="id-for-staff-filter"
      class="plankton-cell"
      span="4"
      :title="$t('search-board/search-quick-filters-content-id-for-staff-filter-title')"
      v-on="$listeners"
      :showHint="false"
      :containerStyle="{ minHeight: '32px', maxHeight: '100px' }"
    />
    <TextInputFilter
      filterName="originCity"
      :title="$t('search-board/search-quick-filters-content-origin-city-filter-title')"
      class="plankton-cell"
      span="4"
    />

    <TextInputFilter
      filterName="originPostalCode"
      :title="$t('search-board/search-quick-filters-content-origin-zip-filter-title')"
      class="plankton-cell"
      span="4"
    />

    <TextInputFilter
      filterName="destinationCity"
      :title="
        $t('search-board/search-quick-filters-content-destination-city-filter-title')
      "
      class="plankton-cell"
      span="4"
    />

    <TextInputFilter
      filterName="destinationPostalCode"
      :title="
        $t('search-board/search-quick-filters-content-destination-zip-filter-title')
      "
      class="plankton-cell"
      span="4"
    />
  </div>
</template>

<script>
import { TextInputFilter } from '@/modules/filters'
import SennderReferenceFilter from '@/modules/filters/components/ReferenceFilters/SennderReferenceFilter'

export default {
  components: { TextInputFilter, SennderReferenceFilter }
}
</script>

<style lang="scss" scoped>
@import 'src/modules/filters/styles/quick-filters';
</style>

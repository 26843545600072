import {
  IMicrofrontendData,
  IOctopusSharedData,
  OrgType
} from '@sennder/senn-node-microfrontend-interfaces'
export interface IAuth {
  getAuthToken: IMicrofrontendData<IOctopusSharedData>['callbacks']['getAuthToken']
  getAuthHeader: IMicrofrontendData<IOctopusSharedData>['callbacks']['getAuthHeader']
  getCommonHeaders: IMicrofrontendData<IOctopusSharedData>['callbacks']['getCommonHeaders']
  getPermissions: IMicrofrontendData<IOctopusSharedData>['callbacks']['getPermissions']
}

let auth: IAuth = {
  getAuthHeader: () => new Promise(r => r('Bearer token')),
  getAuthToken: () => new Promise(r => r('token')),
  getCommonHeaders: () =>
    new Promise(r =>
      r({
        Authorization: 'Bearer Token',
        'X-Org-Type': OrgType.ORGANIZATION,
        'X-Org-Id': 'orgId'
      })
    ),
  getPermissions: () => new Promise(r => r([]))
}

export const setAuth = (a: IAuth): void => {
  auth = a
}

export const getAuth = (): IAuth => {
  return auth
}

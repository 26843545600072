





























import { computed, defineComponent, PropType } from '@vue/composition-api'
import { OrderLogType, ProcessedLog } from '../types'
import { formatTimestamps } from '@/modules/order-activity-sidebar/timestamps'

export default defineComponent({
  props: {
    entryData: { type: Object as PropType<ProcessedLog>, required: true }
  },
  setup(props) {
    const formattedDateTime = computed(() => formatTimestamps(props.entryData.date))
    const orderIdForStaff = computed(() => {
      return (
        props.entryData.logData?.idForStaff ?? props.entryData.message.split(' ').pop()
      )
    })

    return { formattedDateTime, OrderLogType, orderIdForStaff }
  }
})

<template>
  <Button
    v-bind="$attrs"
    :disabled="disabled || inProgress || asyncActionProvider.isAnyActionRunning()"
    :materialIcon="inProgress ? 'autorenew' : $attrs.materialIcon"
    :class="{ 'rotate-icon': inProgress }"
    class="async-action-button"
    v-on="$listeners"
    @click="executeAsyncAction"
  />
</template>
<script>
import throttle from 'lodash/throttle'
import { provider as asyncActionProvider } from './AsyncActionContext'
import { MIN_IN_PROGRESS_ACTION_DURATION } from '@/modules/ordering/constants'

export default {
  inject: { asyncActionProvider },
  inheritAttrs: false,
  props: {
    asyncAction: { type: Function, required: true },
    beforeAction: { type: Function, default: () => true },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return { inProgress: false }
  },
  methods: {
    async executeAsyncAction() {
      if (!this.beforeAction()) return
      this.setInProgress(true)
      try {
        await this.asyncAction()
      } finally {
        this.setInProgress(false)
      }
    },
    setInProgress: throttle(function (value) {
      this.inProgress = value
      this.asyncActionProvider.setRunningAction(value)
    }, MIN_IN_PROGRESS_ACTION_DURATION)
  }
}
</script>
<style lang="scss" scoped>
.async-action-button {
  &.rotate-icon {
    &::v-deep .material-icons {
      animation: rotating 2s linear infinite;
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>

import { IAnalyticsProvider } from '@sennder/senn-node-microfrontend-interfaces'

const analyticsDefaultProvider: IAnalyticsProvider = {
  trackEvent: function (): void {
    throw new Error('[analytics] trackEvent function is not implemented.')
  },
  trackPage: function (): void {
    throw new Error('[analytics] trackPage function is not implemented.')
  },
  setContext: function (): void {
    throw new Error('[analytics] trackPage function is not implemented.')
  }
}

export let analytics = analyticsDefaultProvider

export const setAnalyticsProvider = (analyticsProvider: IAnalyticsProvider) => {
  analytics = analyticsProvider
}




































import ExecutionRowHeader from './ExecutionRowHeader.vue'
import ExecutionRow from './ExecutionRow.vue'
import getAlphabetEquivalent from '@/compositions/transfer/useStopLetter'
import { defineComponent, PropType } from '@vue/composition-api'
import { TransferStep, TransferStopover } from '@/services'
import range from 'lodash/range'

type StepType = 'departure' | 'arrival'

export default defineComponent({
  components: { ExecutionRowHeader, ExecutionRow },
  props: {
    steps: { type: Array as PropType<TransferStep[]>, required: true },
    multipleStopLetters: { type: Array as PropType<string[]>, required: true },
    stops: { type: Array as PropType<TransferStopover[]>, required: true },
    latestLocationTimeStamp: { type: String, default: null },
    isOrderExecuted: { type: Boolean, required: true }
  },
  setup(_props, { emit }) {
    const setStepsAsDone = (type: StepType, index: number): void => {
      let markedStepAsDone = type === 'arrival' ? index * 2 : index * 2 + 1
      emit('markStepAsDone', markedStepAsDone)
    }

    const updateStepDatetime = (
      { timestamp, type }: { timestamp: string; type: StepType },
      index: number
    ): void => {
      emit('updateStepDatetime', {
        timestamp,
        index: type === 'arrival' ? index * 2 : index * 2 + 1
      })
    }

    const onUpdateIsDirty = (
      data: { isDirty: boolean; type: 'arrival' | 'departure' },
      index: number
    ) => {
      emit('updateIsDirty', { isDirty: data.isDirty, type: data.type, index })
    }

    return {
      getAlphabetEquivalent,
      setStepsAsDone,
      updateStepDatetime,
      onUpdateIsDirty,
      range
    }
  }
})























import { defineComponent, ref, computed, onMounted, PropType } from '@vue/composition-api'

import useCurrentUser from '@/compositions/useCurrentUser'
import { CarrierSuggestionsRecommendedService } from './CarrierSuggestionsRecommendedService'

import CarrierSuggestionsRecommendedTable from './CarrierSuggestionsRecommendedTable.vue'
import CarrierSuggestionsRecommendedTiles from './CarrierSuggestionsRecommendedTiles.vue'
import Filters from '../Filters.vue'
import { Order, Suggestion, OrderMetrics } from '../../types'

export default defineComponent({
  components: {
    CarrierSuggestionsRecommendedTable,
    CarrierSuggestionsRecommendedTiles,
    Filters
  },
  props: {
    order: { type: Object as PropType<Order>, required: true },
    canSendOffers: { type: Boolean, default: true }
  },
  setup(props) {
    const { currentUserId } = useCurrentUser()

    const loading = ref(false)
    const suggestions = ref<Suggestion[]>([])
    const orderMetrics = ref<OrderMetrics>({})
    const expectedCost = ref(0)
    const filters = ref({
      myCarriers: false,
      hideCarriersWithCM: false
    })

    const loadCarrierSuggestions = async () => {
      suggestions.value = []

      if (props.order) {
        loading.value = true

        try {
          const service = new CarrierSuggestionsRecommendedService()
          const [orderMetricsRes, expectedCostRes, suggestionsRes] = await service.search(
            props.order.id
          )

          orderMetrics.value = orderMetricsRes as OrderMetrics
          expectedCost.value = expectedCostRes as number
          suggestions.value = suggestionsRes as Suggestion[]
          loading.value = false
        } catch (e) {
          loading.value = false
        }
      }
    }

    const filteredSuggestions = computed(() => {
      return suggestions.value.filter(order => {
        const owner = order?.contacts?.[0]?.owner

        if (filters.value.myCarriers) {
          return owner?.contactOwnerId === currentUserId.value
        } else if (filters.value.hideCarriersWithCM) {
          const isActiveContact = order?.contacts?.[0]?.isActive

          return !owner?.contactOwnerId || !isActiveContact
        } else {
          return true
        }
      })
    })

    onMounted(loadCarrierSuggestions)

    return {
      loading,
      orderMetrics,
      expectedCost,
      filters,
      filteredSuggestions
    }
  }
})

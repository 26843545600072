<template>
  <TypeAheadField
    :options="filterOptions"
    :visible-selected-options-limit="5"
    :height-fixed="false"
    v-model="juniorAccountManagerFilterHandler"
    :internalSearch="internalSearch"
    optionLabel="email"
    optionKey="id"
    :title="$t('filters/account-manager-filter-title')"
    multiple
    placeholder
    optionLabelWhenSelected="email"
    data-test="account-manager-filter"
    style="width: 100%"
    @fetchFilterOptions="onFetchFilterOptions"
    @clearFilterOptions="onClearFilterOptions"
  />
</template>
<script>
import { fetchOperatorByName } from '@/services/user-service'

export default {
  inject: ['getFilterValue', 'setFilterValue'],
  data: () => ({
    filterOptions: [],
    internalSearch: false
  }),
  computed: {
    juniorAccountManagerFilterHandler: {
      get() {
        return this.getFilterValue('accountManagers')
      },
      set(payload) {
        this.setFilterValue('accountManagers', payload)
        this.$emit('applyFilters')
      }
    }
  },
  methods: {
    async onFetchFilterOptions(searchText) {
      this.filterOptions = await fetchOperatorByName(searchText)
    },
    onClearFilterOptions() {
      this.filterOptions = []
    }
  }
}
</script>

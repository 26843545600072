<template>
  <div class="filters-content">
    <DateFilters
      :from="{
        id: 'earliestPickup',
        title: $t('finance-board/finance-filters-content-pickup-date-from')
      }"
      :to="{
        id: 'latestPickup',
        title: $t('finance-board/finance-filters-content-pickup-date-to')
      }"
      filterName="pickupDates"
    />

    <DateFilters
      :from="{
        id: 'earliestDropoff',
        title: $t('finance-board/finance-filters-content-dropoff-date-from')
      }"
      :to="{
        id: 'latestDropoff',
        title: $t('finance-board/finance-filters-content-dropoff-date-to')
      }"
      filterName="dropoffDates"
    />

    <AddressFilter v-on="$listeners" />

    <CustomerFilter data-test="finance-board-customer-filter" v-on="$listeners" />

    <CarrierFilter v-on="$listeners" />

    <ReferenceFilters :fields="['referenceNumber', 'idsForStaff']" v-on="$listeners" />

    <OrderStateFilter :orderStates="orderStates" v-on="$listeners" />

    <VehicleTypesFilter v-on="$listeners" />

    <div class="plankton-grid">
      <TextInputFilter
        filterName="invoiceNumber"
        :title="$t('finance-board/finance-filters-content-invoice-filter-title')"
        class="invoice-filter plankton-cell"
        span="12"
      />
    </div>

    <OrderSourceFilter v-on="$listeners" />

    <RadioFilter
      filterName="needsToBeValidated"
      :title="$t('finance-board/finance-filters-content-to-validate-filter-title')"
      v-on="$listeners"
    />
    <RadioFilter
      filterName="hasDiscount"
      :title="$t('finance-board/finance-filters-content-has-discount-filter-title')"
      v-on="$listeners"
    />
    <RadioFilter filterName="orderCancelled" title="Cancelled orders" v-on="$listeners" />
    <RadioFilter
      filterName="creditNoteWasSent"
      :title="$t('finance-board/finance-filters-content-credit-note-sent-filter-title')"
      v-on="$listeners"
    />
    <RadioFilter
      filterName="isCustomerBilled"
      :title="$t('finance-board/finance-filters-content-customer-billed-filter-title')"
      v-on="$listeners"
    />
    <RadioFilter
      filterName="isCarrierBilled"
      :title="$t('finance-board/finance-filters-content-carrier-billed-filter-title')"
      v-on="$listeners"
    />

    <RadioFilter
      filterName="isEligibleForAutomaticCreditNotes"
      :title="
        $t('finance-board/finance-filters-content-eligible-credit-note-filter-title')
      "
      v-on="$listeners"
    />

    <RadioFilter
      filterName="hasMissingDocumentRequests"
      :title="
        $t('finance-board/finance-filters-content-missing-doc-requested-filter-title')
      "
      v-on="$listeners"
    />
    <RadioFilter
      filterName="hasCarrierInvoices"
      :title="
        $t('finance-board/finance-filters-content-has-carrier-invoice-filter-title')
      "
      v-on="$listeners"
    />
  </div>
</template>

<script>
import {
  DateFilters,
  AddressFilter,
  CustomerFilter,
  CarrierFilter,
  OrderStateFilter,
  VehicleTypesFilter,
  ReferenceFilters,
  TextInputFilter,
  RadioFilter,
  OrderSourceFilter
} from '@/modules/filters'
import { BOARD_FILTER_ORDER_STATES } from '../constants'

export default {
  components: {
    ReferenceFilters,
    AddressFilter,
    CustomerFilter,
    CarrierFilter,
    OrderStateFilter,
    VehicleTypesFilter,
    DateFilters,
    TextInputFilter,
    RadioFilter,
    OrderSourceFilter
  },
  computed: {
    orderStates: () => BOARD_FILTER_ORDER_STATES
  }
}
</script>

<style lang="scss" scoped>
@import 'src/modules/filters/styles/filters';

.invoice-filter.s-order-field {
  margin-bottom: 16px;
}
</style>

import { i18n } from '@/plugins/i18n'

export const MESSAGE_TOASTER_TIMEOUT = 5000
export const SUCCESS_TOAST_MESSAGE = i18n.t('shipment/edit-modal-success-toast-message')
export const IN_PROGRESS_TOAST_MESSAGE = i18n.t(
  'shipment/edit-modal-in-progress-toast-message'
)
export const TIMEOUT_TOAST_MESSAGE = i18n.t('shipment/edit-modal-timeout-toast-message')
export const POLL_API_INTERVAL = 2000
export const MAX_POLL_API_INTERVAL = 1800000

export const SHIPMENT_CANCELLATION_POLL_API_INTERVAL = 1000
export const SHIPMENT_CANCELLATION_TIMEOUT_TOAST_MESSAGE = 'Timeout error'
export const SHIPMENT_CANCELLATION_IN_PROGRESS_TOAST_MESSAGE =
  'Shipment cancellation in progress'
export const SHIPMENT_CANCELLATION_SUCCESS_TOAST_MESSAGE =
  'The shipment has been successfully cancelled'

export const SHIPMENT_CREATION_MODE = {
  MANUAL: 'MANUAL',
  CLONE: 'CLONED'
}

export const SHIPMENT_EDIT_REQUEST_MAIN_STATUS = {
  INITIAL: 'initial',
  PENDING: 'pending',
  UPDATED: 'updated',
  ERROR: 'error'
}

export const SHIPMENT_EDIT_REQUEST_PARTIAL_UPDATES_STATUS = {
  NO_CHANGES: 'no_changes',
  PENDING: 'pending',
  DONE: 'done',
  DENIED: 'denied'
}

export const OLD_SHIPMENT_CREATION_DATE = '2023-11-07'

export const SHIPPER_PROFILE_CLOSED_LIVE_ACCOUNT_STATUS = 'Closed Live'

import consoleLogger from '@/shell/console-logger'
import { MICRO_FRONTEND_MODE } from '@/config'
import { monitoring } from '../../core-config/orchestrated/monitoring'

export const monitorError = (error: Error, application = 'octopus', context = {}) => {
  if (MICRO_FRONTEND_MODE) {
    monitoring.sendError(error, {
      application,
      ...context
    })

    consoleLogger.debug(error)
  } else {
    consoleLogger.error(error)
  }
}

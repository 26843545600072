import VueRouter from 'vue-router'

// Wrapper over VueRouter
export const OctopusRouterMixin = (router, store) => {
  if (!router) router = new VueRouter()

  return {
    getParam,
    getQuery,
    setQuery,
    isLoginRoute,
    goToCallCenterBoard,
    navigateTo,
    openInNewTab
  }

  function getQuery() {
    return store.state.route.query
  }

  function goToCallCenterBoard() {
    navigateTo('/dashboard/call-center')
  }

  function setQuery(query) {
    router.replace({ query }).catch(() => {}) // see comment on navigateTo
  }

  function getParam(id) {
    return store.state.route.params[id]
  }

  function isLoginRoute() {
    return store.state.route.name === '/login'
  }

  function openInNewTab(url) {
    window.open(url, '_blank')
  }

  function navigateTo(route) {
    // If the route is the same as the previous one the navigation is going to
    // return a rejected promise, we will just ignore it so it does not appear on
    // Sentry (https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378)
    router.push(route).catch(() => {})
  }
}

export const octopusRouterMixin = {
  created() {
    this.octopusRouter = OctopusRouterMixin(this.$router, this.$store)
  }
}

import { CONTRACT_MANAGEMENT_API_URL, ORDER_INGESTION_API_URL } from '@/config'
import { runJsonGet, runJsonPut } from '@/models/backend-client'
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase
} from '@/services/utils/casing'

import {
  ShipperContract,
  VehicleType,
  ShipperContractDetail
} from '@/modules/ordering/types'

export const orderMatch = async function (params: {
  addressIds: number[]
  vehicleType: VehicleType
  shipperId: string
}): Promise<ShipperContract> {
  const { addressIds, vehicleType, shipperId } = params
  const result = await runJsonGet(
    `${CONTRACT_MANAGEMENT_API_URL}/api/shipper-contracts/order-match/`,
    transformKeysToSnakeCase({
      addressIds,
      vehicleType,
      shipperId
    }),
    { apiGatewayAuthorization: true, arrayFormat: 'repeat' }
  )
  if (result && result.data) {
    return transformKeysToCamelCase(result.data)
  }
  return null
}

export const matchContract = async function (params: {
  shipperId: string
  vehicleType: VehicleType
  postalCodes: string[]
}): Promise<ShipperContractDetail | null> {
  const { postalCodes, vehicleType, shipperId } = params
  const result = await runJsonGet(
    `${CONTRACT_MANAGEMENT_API_URL}/api/shipper-contracts/match-contract/`,
    transformKeysToSnakeCase({
      postalCodes,
      vehicleType,
      shipperId
    }),
    { apiGatewayAuthorization: true, arrayFormat: 'repeat' },
    'https://api.cloud.sennder.com/contractmanagement',
    'contracts:read-any contracts:import'
  )
  if (result && result.data) {
    return transformKeysToCamelCase(result.data)
  }
  return null
}

export const persistOrderContractMatches = async function (
  orderId: number,
  shipperContractId: number,
  isLinked: boolean,
  userId: number
): Promise<void> {
  await runJsonPut(
    `${ORDER_INGESTION_API_URL}/api/order-contract-match/persist/`,
    transformKeysToSnakeCase([
      {
        orderId,
        shipperContractId,
        isLinked,
        userId
      }
    ]),
    { apiGatewayAuthorization: true }
  )
}

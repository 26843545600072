import identity from 'lodash/identity'
import { createFilter } from './filter'
import { i18n } from '@/plugins/i18n'

const emptyValue = []

/**
 * @typedef {string[]|undefined} FilterValue
 * @typedef {import('./filter').UrlQueryFragment} UrlQueryFragment
 */

/**
 * @param {string} name - name for API/GraphQL key, URL and apply filter value
 * @param {FilterValue} initialValue
 * @param {string} updateName
 * @param {string} filterLabel
 * @param {Function} humanizeValue - Function that convert each filter list value to a human readable representation
 * @returns {any} - vuex module instance
 */
export const createBasicListFilter = ({
  name,
  initialValue = emptyValue,
  updateName,
  filterLabel,
  humanizeValue = identity,
  esName = name,
  esWord = ''
}) => {
  let filter = createFilter({
    name,
    initialValue,
    emptyValue,
    updateName,
    filterLabel,
    humanizeValue,
    esName,
    esWord
  })

  filter.getters = {
    ...filter.getters,
    /**
     * @param {*} state
     * @param {*} getters
     * @returns {?object<string, FilterValue>} - a fragment of API payload object
     */
    getApiFragment: (state, getters) =>
      !getters.isFilterEmpty ? { [name]: state.value } : null,
    /**
     * @param {*} state
     * @param {*} getters
     * @returns {array<object>} - data for AppliedFilters component
     */
    getAppliedValue: (state, getters) => {
      let appliedValues = []
      if (!getters.isFilterEmpty) {
        state.value.forEach((filterValue, index) => {
          const filterStateAfterRemoval = [...state.value]
          filterStateAfterRemoval.splice(index, 1)

          appliedValues.push({
            label: `${i18n.t(filterLabel)}: ${humanizeValue(filterValue)}`,
            updateName,
            emptyValue: filterStateAfterRemoval
          })
        })
      }

      return appliedValues
    },

    /**
     * @param {*} state
     * @param {*} getters
     * @returns {?string} - a fragment of GraphQL payload object
     */
    getGraphqlValue: (state, getters) =>
      !getters.isFilterEmpty ? `${name}: ${JSON.stringify(state.value)}` : null,
    /**
     * @param {*} state
     * @param {*} getters
     * @returns {UrlQueryFragment} - a fragment of URL query object
     */
    getUrlQueryFragment: (state, getters) => {
      if (!getters.isFilterInitial) {
        return {
          [state.urlParamName]: !getters.isFilterEmpty ? state.value.join(',') : null
        }
      } else return null
    },
    getEsQueryFragment: (state, getters) => {
      if (getters.isFilterEmpty) {
        return []
      } else if (esWord === 'terms') {
        return [
          {
            [esWord]: {
              [esName]: [
                ...state.value.reduce((acc, nextValue) => {
                  acc.push(nextValue)
                  return acc
                }, [])
              ]
            }
          }
        ]
      } else if (state.value?.length === 1) {
        return [{ match: { [esName]: state.value[0] } }]
      } else {
        return [
          {
            bool: {
              should: [...state.value.map(value => ({ match: { [esName]: value } }))]
            }
          }
        ]
      }
    }
  }

  filter.actions = {
    ...filter.actions,
    /**
     * @param {*} store
     * @param {UrlQueryFragment} urlQueryFragment
     */
    setValueFromUrl({ state, commit }, urlQueryFragment) {
      const urlValue = urlQueryFragment[state.urlParamName]
      if (urlValue) commit('update', urlValue === '' ? initialValue : urlValue.split(','))
      else if (urlValue === null) commit('update', emptyValue)
    }
  }

  return filter
}

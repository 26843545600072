import { DETECTED_NETWORK_ERROR, ValidationError } from '@/controllers/custom-errors'
import { AxiosError } from 'axios'
import { AlertModal } from '@sennder/plankton'
import VueRouter from 'vue-router'

export function handleError(param: {
  alertModal: ReturnType<typeof AlertModal>
  error: Error
  router: VueRouter
}) {
  const { alertModal, error } = param
  if (
    isConcurrencyErrorMessage(error) ||
    isAlreadyDetectedNetworkError(error) ||
    isServerError(error as AxiosError)
  )
    return

  if (
    isFrontendValidationError(error) ||
    isControlledBackendError(error as AxiosError) ||
    isPermissionDeniedBackendError(error as AxiosError)
  ) {
    showAlertForOrderErrorResponse(alertModal, error)
    return
  }

  throw error
}

function isAlreadyDetectedNetworkError(error: Error) {
  return error.message === DETECTED_NETWORK_ERROR
}

function isConcurrencyErrorMessage(error: Error) {
  const ConcurrencyErrorMessage = 'ConcurrentUpdate'
  return error?.message?.includes(ConcurrencyErrorMessage)
}

function isControlledBackendError(error: AxiosError) {
  return isBackendError(error) && [400, 409].includes(error.response.status)
}

function isPermissionDeniedBackendError(error: AxiosError) {
  return isBackendError(error) && error.response.status === 403
}

function isBackendError(error: AxiosError) {
  return error?.response?.data
}

function isFrontendValidationError(error: Error) {
  return error instanceof ValidationError
}

function isServerError(error: AxiosError) {
  return isBackendError(error) && [500, 501, 502, 503].includes(error.response.status)
}

export function prepareBackendErrorJsonMessageIfNeeded(error: AxiosError) {
  if (isBackendError(error)) error.message = JSON.stringify(error.response.data)
}

export function formatJSONError(error: Error) {
  prepareBackendErrorJsonMessageIfNeeded(error as AxiosError)
  let message: string
  try {
    const json = JSON.parse(error.message)
    if (Array.isArray(json)) {
      message = json.join('/n')
    } else if (typeof json === 'string') {
      message = json
    } else message = humanize(json)
  } catch (e) {
    message = error.message
  }

  return message
}

function humanize<P>(json: { [Key in keyof P]: string[] }) {
  return Object.keys(json)
    .map(key => `${key}: ${json[key].join(', ')}`)
    .join('<br><br>')
}

function showAlertForOrderErrorResponse(
  alertModal: ReturnType<typeof AlertModal>,
  error: Error
) {
  prepareBackendErrorJsonMessageIfNeeded(error as AxiosError)
  let errorObject
  try {
    errorObject = JSON.parse(error.message)
  } catch (e) {
    return alertModal.showErrorMessage('Error', formatJSONError(error))
  }
  if (Object.prototype.hasOwnProperty.call(errorObject, 'order_letter')) {
    return alertModal.showErrorMessage(
      `Missing data for order ${errorObject.order_letter}`,
      errorObject.message
    )
  }

  return alertModal.showErrorMessage('Error', formatJSONError(error))
}

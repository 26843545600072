<template>
  <table ref="suggestions" class="suggestions">
    <thead>
      <tr>
        <th class="send-offers" v-if="canSendOffers" />
        <th class="carrier">
          {{ $t('ordering/dispatching-carrier-suggestions-carrier-suggestions-carrier') }}
        </th>
        <th class="gps">
          {{ $t('ordering/dispatching-carrier-suggestions-carrier-suggestions-gps') }}
        </th>
        <th class="quality">
          {{ $t('ordering/dispatching-carrier-suggestions-carrier-suggestions-quality') }}
        </th>
        <th class="carrier-contact">
          {{
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-carrier-contact'
            )
          }}
        </th>
        <th class="carrier-manager">
          {{
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-carrier-manager'
            )
          }}
        </th>
        <th class="cost-per-km">
          {{
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-last-cost-per-km'
            )
          }}
        </th>
        <th class="truck-available">
          {{
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-last-truck-available'
            )
          }}
        </th>
        <th class="reason-for-blacklist">
          {{
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-reason-for-blacklist'
            )
          }}
        </th>
        <th
          class="calls-made"
          v-tooltip="
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-by-location-table-calls-made-tooltip'
            )
          "
        >
          {{
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-num-calls-made'
            )
          }}
        </th>
        <th class="offers-request-sent">
          {{
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-num-offers-request-sent'
            )
          }}
        </th>
        <th
          class="recent-load-volume"
          v-tooltip="
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-recent-tooltip'
            )
          "
        >
          {{
            $t(
              'ordering/dispatching-carrier-suggestions-carrier-suggestions-num-recent-load-volume'
            )
          }}
        </th>
        <th class="empty-cell"></th>
      </tr>
    </thead>
    <LoadingTableFooter v-if="loading" />
    <tbody v-else-if="suggestions.length === 0">
      <td class="no-data-cell" colspan="20" align="center">
        {{
          $t(
            'ordering/dispatching-carrier-suggestions-carrier-suggestions-no-suggestions'
          )
        }}
      </td>
    </tbody>
    <tbody v-else>
      <tr
        v-for="(suggestion, index) in suggestions"
        :key="suggestion.id"
        data-test="suggestions"
      >
        <td class="send-offers" v-if="canSendOffers">
          <div v-if="getContactID(suggestion)" class="offers-sent-checkbox-wrapper">
            <Checkbox
              v-model="carrierContactIdList"
              :value="String(getContactID(suggestion))"
              data-test="offers-sent-checkbox"
            />
          </div>
          <div v-else>
            {{
              $t(
                'ordering/dispatching-carrier-suggestions-carrier-suggestions-no-contact'
              )
            }}
          </div>
        </td>
        <td class="carrier">
          {{ index + 1 }}.
          <a
            :href="createLinkToExecutionDashboard(suggestion)"
            target="_blank"
            data-test="carrier-name"
          >
            {{ getCarrierName(suggestion) }}
          </a>
        </td>
        <td class="gps" data-test="gps">
          {{ formatBool(isGPS(suggestion)) }}
        </td>
        <td class="quality">
          <div v-if="suggestion.carrierMetrics">
            <div v-if="suggestion.carrierMetrics.otp" data-test="quality-otp">
              {{
                $t('ordering/dispatching-carrier-suggestions-carrier-suggestions-otp', [
                  formatQuality(suggestion.carrierMetrics.otp)
                ])
              }}
            </div>
            <div v-if="suggestion.carrierMetrics.otd" data-test="quality-otd">
              {{
                $t('ordering/dispatching-carrier-suggestions-carrier-suggestions-otd', [
                  formatQuality(suggestion.carrierMetrics.otd)
                ])
              }}
            </div>
            <div
              v-if="suggestion.carrierMetrics.trackingRate"
              data-test="quality-tracking-rate"
            >
              {{
                $t('ordering/dispatching-carrier-suggestions-carrier-suggestions-tr', [
                  formatQuality(suggestion.carrierMetrics.trackingRate)
                ])
              }}
            </div>
          </div>
          <span v-else data-test="no-quality-data">-</span>
        </td>
        <td class="carrier-contact">
          <PhoneLink
            v-if="getCarrierContactPhone(suggestion)"
            :phone="getCarrierContactPhone(suggestion)"
            @click="phoneLinkClicked(suggestion)"
          >
            <span data-test="contact-name">{{ getCarrierContactName(suggestion) }}</span>
          </PhoneLink>
          <span v-else data-test="no-contact-name">-</span>
        </td>
        <td class="carrier-manager">
          <span v-if="contactOwnerName(suggestion)" data-test="carrier-manager">
            {{ contactOwnerName(suggestion) }}
          </span>
          <span v-else data-test="no-carrier-manager">-</span>
        </td>
        <td class="cost-per-km" data-test="cost-per-km">
          {{ formatCurrency(suggestion) }}
        </td>
        <td class="truck-available">
          <img
            v-if="isTruckAvailable(suggestion)"
            src="@/assets/svgs/truck.svg"
            width="14"
            height="14"
            data-test="truck-available"
          />
        </td>
        <td class="reason-for-blacklist" data-test="reason-for-blacklist">
          {{ getReasonForBlacklist(suggestion) }}
        </td>
        <td class="calls-made" data-test="calls-made">
          {{ getCallsNumber(suggestion) }}
        </td>
        <td class="offers-request-sent" data-test="offers-request-sent">
          {{ getRequestSent(suggestion) }}
        </td>
        <td class="recent-load-volume" data-test="recent-load-volume">
          {{ suggestion.loadVolume || 0 }}
        </td>
        <td class="empty-cell"></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import { formatMoneyWithDashForNull } from '@/global-setup/filters'

import { LoadingTableFooter } from '@/modules/boards'

import PhoneLink from '../PhoneLink.vue'
import { trackEvent } from '@/analytics/amplitude'

export default defineComponent({
  components: {
    LoadingTableFooter,
    PhoneLink
  },
  props: {
    orderId: { type: Number, required: true },
    suggestions: { type: Array, default: () => [] },
    canSendOffers: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  data() {
    return {
      carrierContactIdList: []
    }
  },
  watch: {
    carrierContactIdList(value) {
      trackEvent('octopus/carrier-suggestion/click/carrier-recommended-table', {
        selected: value,
        orderId: this.orderId
      })
      this.$emit('selection-changed', value)
    }
  },
  methods: {
    formatCurrency(suggestion) {
      return formatMoneyWithDashForNull(suggestion?.euroPerKm)
    },
    isGPS(suggestion) {
      const { gpsCo3, gpsP44, gpsSennder } = suggestion?.carrierInformation

      return gpsCo3 || gpsP44 || gpsSennder
    },
    formatBool(value) {
      return value === true
        ? this.$t('global/Yes')
        : value === false
        ? this.$t('global/No')
        : ''
    },
    formatQuality(value) {
      return value ? (value * 100).toFixed(0) : ''
    },
    createLinkToExecutionDashboard(suggestion) {
      return `/dashboard/execution?carriers=${suggestion?.id}&states=DISPATCHED,COMPLETED,OPERATIONS_COMPLETED,EXECUTED`
    },
    getContactID(suggestion) {
      return suggestion?.contacts[0]?.carrierContactId
    },
    getCarrierName(suggestion) {
      return suggestion?.carrierInformation?.name
    },
    contactOwnerName(suggestion) {
      if (!suggestion?.contacts[0]?.isActive) {
        return null
      }

      const { firstName = '', lastName = '' } = suggestion?.contacts[0]?.owner ?? {}
      return `${firstName} ${lastName}`
    },
    isTruckAvailable(suggestion) {
      return suggestion?.availableTrucks?.length
    },
    getCallsNumber(suggestion) {
      return suggestion?.callsMade?.length ?? 0
    },
    getReasonForBlacklist(suggestion) {
      return suggestion?.carrierData?.reasonForBlacklist ?? '-'
    },
    getRequestSent(suggestion) {
      const offersSent = suggestion?.offersSent ? cloneDeep(suggestion?.offersSent) : []

      if (offersSent.length === 0) {
        return '0'
      }

      const sent = offersSent.reduce((acc, val) => {
        if (val?.wasSuccessful) {
          return (acc += 1)
        }

        return acc
      }, 0)

      return `${sent} (${offersSent.length})`
    },
    phoneLinkClicked(suggestion) {
      this.$emit('phone-link-clicked', suggestion.carrierInformation, 0)
      trackEvent('octopus/carrier-suggestion/click/phone-link', {
        carrier: suggestion.carrierInformation,
        contact: suggestion?.contacts[0],
        orderId: this.orderId
      })
    },
    getCarrierContactPhone(suggestion) {
      if (!suggestion?.contacts[0]?.isActive) {
        return null
      }

      return suggestion?.contacts[0]?.phone
    },
    getCarrierContactName(suggestion) {
      const { firstName = '', lastName = '' } = suggestion?.contacts[0] ?? {}
      return `${firstName} ${lastName}`
    }
  }
})
</script>

<style lang="scss" scoped>
.suggestions {
  thead,
  tbody {
    display: block;
    width: 100%;
  }

  tbody {
    height: 220px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  th {
    padding: 0 0 4px;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 16px;
  }

  th,
  td {
    &.send-offers {
      width: 29px;
    }

    &.carrier {
      width: 115px;
    }

    &.gps {
      width: 30px;
      text-align: center;
    }

    &.quality {
      width: 128px;
      text-align: center;
    }

    &.carrier-contact {
      width: 120px;
    }

    &.partner-manager {
      width: 130px;
    }

    &.cost-per-km {
      width: 84px;
    }

    &.truck-available {
      width: 84px;
    }

    &.reason-for-blacklist {
      width: 100px;
    }

    &.calls-made {
      width: 60px;
    }

    &.offers-request-sent {
      width: 80px;
    }

    &.recent-load-volume {
      width: 80px;
    }

    &.carrier-manager {
      width: 200px;
    }

    &.offers-sent {
      width: 84px;
    }

    &.empty-cell {
      width: 177px;
    }
  }

  tr.selected {
    background-color: #f8f8f8;
  }

  td {
    padding: 8px 8px 8px 0;
    margin: 0;
    border-top: solid 1px $color-neutral-lightest;
    font-size: 12px;
    line-height: 16px;

    &.no-data-cell {
      display: block;
    }

    &.partner-manager {
      color: $color-neutral-main;
    }

    &.offers-sent {
      text-align: center;
    }
  }
}
</style>

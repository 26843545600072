<template>
  <div class="plankton-grid">
    <DropdownField
      v-model="originCountryCodesHandler"
      :searchable="true"
      :options="countryDropdownOptions"
      :visibleSelectedOptionsLimit="1"
      multiple
      :noResultMessage="$t('filters/country-filter-no-result-message')"
      :title="$t('filters/country-filter-origin-title')"
      data-test="origin-country-codes"
      class="plankton-cell"
      span="12"
    />

    <DropdownField
      v-model="destinationCountryCodesHandler"
      :searchable="true"
      :options="countryDropdownOptions"
      :visibleSelectedOptionsLimit="1"
      multiple
      :noResultMessage="$t('filters/country-filter-no-result-message')"
      :title="$t('filters/country-filter-destination-title')"
      data-test="destination-country-codes"
      class="plankton-cell"
      span="12"
    />
  </div>
</template>

<script>
import { countryCodes } from '@/modules/common/country-codes'

export default {
  inject: ['getFilterValue', 'setFilterValue'],
  computed: {
    countryDropdownOptions() {
      return countryCodes
    },

    originCountryCodesHandler: {
      get() {
        return this.getFilterValue('originCountryCodes')
      },
      set(payload) {
        this.setFilterValue('originCountryCodes', payload)
        this.$emit('applyFilters')
      }
    },

    destinationCountryCodesHandler: {
      get() {
        return this.getFilterValue('destinationCountryCodes')
      },
      set(payload) {
        this.setFilterValue('destinationCountryCodes', payload)
        this.$emit('applyFilters')
      }
    }
  }
}
</script>

import { FeatureFlagService } from '@/services/feature-flag-service'
import User from '@/models/user'
import * as USER_PERMISSIONS from '@/components/user-permissions'
import { updateMicroFrontendsApi } from '@/global-setup/microfrontends-init'
import { getInstance } from '@sennder/auth'
import { watchEffect } from '@vue/composition-api'

export function buildAuthentificationGuard(
  store,
  UserClass: typeof User,
  featureFlagService: FeatureFlagService,
  {
    updateBrowserTabTitle,
    isUnauthenticatedRoute,
    isCallCenterIllegalRoute,
    isContractManagementRoute
  }
) {
  return async (to, from, next) => {
    const auth0Instance = getInstance()

    const verify = async () => {
      updateBrowserTabTitle(to)
      const userIsLoggedIn = await UserClass.isLoggedIn()

      if (isUnauthenticatedRoute(to)) {
        if (userIsLoggedIn) return next('/')
        return next()
      }

      if (!userIsLoggedIn) {
        updateMicroFrontendsApi()
        return next(`/login?path=${encodeURIComponent(to.fullPath)}`)
      }

      await store.dispatch('fetchCurrentUser')
      const currentUser = store.getters['currentUser']

      if (!featureFlagService.isUserIdentifiedWithId(currentUser.id)) {
        await featureFlagService.identify(currentUser)
        updateMicroFrontendsApi()
      }

      if (isCallCenterIllegalRoute(to)) return next('/dashboard/call-center')

      updateBrowserTabTitle(to)
      next()

      if (isContractManagementRoute(to)) {
        return currentUser.permissions.includes(
          USER_PERMISSIONS.CAN_VIEW_CONTRACT_MANAGEMENT
        ) ||
          currentUser.permissions.includes(
            USER_PERMISSIONS.CAN_VIEW_CONTRACT_MANAGEMENT_AUTH0
          )
          ? next()
          : next('/')
      }
    }

    if (!auth0Instance?.loading) {
      return verify()
    }

    const stop = watchEffect(() => {
      if (auth0Instance.loading === false) {
        stop()
        return verify()
      }
    })
  }
}

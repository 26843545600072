const formatError = validationErrors => {
  let fileOutput = ''
  for (let [key, value] of Object.entries(validationErrors)) {
    fileOutput += `Row ${key}:\n`
    for (let [errorKey, errorValue] of Object.entries(value)) {
      fileOutput += `  ${errorKey.split('_').join(' ')}: ${errorValue}\n`
    }
  }

  return fileOutput
}

const generateErrorReport = (document, validationErrors) => {
  const element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(formatError(validationErrors))
  )
  element.setAttribute('download', `errors`)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

export default generateErrorReport

<template>
  <td>
    <div v-if="state.done" class="d-flex align-items-top info-done">
      <StepStateIcon :state="state" />
      <delay
        :delay="state.delay"
        :scheduledTime="state.step.scheduled_time_formatted"
        :referenceTime="state.step.actual_time_formatted"
      />
    </div>
    <div v-if="state.estimated" class="d-flex align-items-top">
      <StepStateIcon :state="state" />
      <delay
        :delay="state.delay"
        :scheduledTime="state.step.scheduled_time_formatted"
        :referenceTime="state.step.estimated_time_formatted"
      />
    </div>
    <div v-if="state.scheduled" class="d-flex align-items-top">
      <StepStateIcon :state="state" />
      <div>
        {{ state.step.scheduled_time_formatted }}
      </div>
    </div>
    <div v-if="state.notYetDispatched" class="d-flex align-items-top">
      <StepStateIcon :state="state" />
      <div>
        {{ state.unDispatchedDatetime }}
      </div>
    </div>
    <div v-if="state.notRegistered">-</div>
  </td>
</template>

<script>
import { stateFor, validateDelayCellType } from './step-states'
import StepStateIcon from './StepStateIcon.vue'

export default {
  components: {
    StepStateIcon
  },
  props: {
    order: { type: Object, default: null },
    type: { type: String, required: true, validator: validateDelayCellType }
  },
  data() {
    return {
      state: stateFor(this.type, this.order)
    }
  }
}
</script>

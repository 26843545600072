import { countryByCode } from '@/modules/common/country-codes'

export const addressShortFormat = function (address) {
  if (!address) return '-'
  return `${address.countryCode}-${address.postalCode}, ${address.city}`
}

export const addressLongFormat = function (address) {
  if (!address) return '-'
  return `${address.city} ${address.zipCode}, ${countryByCode[address.countryCode]} `
}

import { OPERATIONS_BACKEND_URL } from '@/config'
import { runJsonPost, runUnauthenticatedJsonPost } from '@/models/backend-client'

export const postPasswordResetRequestByEmail = async email => {
  await runUnauthenticatedJsonPost(
    `${OPERATIONS_BACKEND_URL}/accounts/commands/send-password-reset-email-for-staff-by-username`,
    { username: email }
  )
}

export const postPasswordResetRequestById = async userId => {
  await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/accounts/commands/send-password-reset-email-for-staff-by-id`,
    { user_id: userId }
  )
}

export async function fetchPasswordResetTokenIsValid(personId, token) {
  const { data } = await runUnauthenticatedJsonPost(
    `${OPERATIONS_BACKEND_URL}/accounts/queries/password-reset-token-validity/${personId}`,
    { token }
  )
  return data.token_is_valid
}

export async function changeUserPassword({ uid, token, newPassword, confirmedPassword }) {
  await runUnauthenticatedJsonPost(
    `${OPERATIONS_BACKEND_URL}/auth/password/reset/confirm/`,
    {
      uid,
      token,
      new_password1: newPassword,
      new_password2: confirmedPassword
    }
  )
}

<template>
  <fragment>
    <CarrierSuggestionsModal
      ref="modal"
      :order="order"
      :stops="stops"
      @offers-sent="$emit('offers-sent')"
    />
    <Button
      @click="$refs.modal.open()"
      :category="category"
      :disabled="disabled"
      data-test="carrier-suggestion-button"
    >
      {{ $t('ordering/dispatching-carrier-suggestions-carrier-suggestions-button') }}
    </Button>
  </fragment>
</template>

<script>
import CarrierSuggestionsModal from './CarrierSuggestionsModal'

export default {
  components: {
    CarrierSuggestionsModal
  },
  props: {
    category: { type: String, required: false, default: '' },
    order: { type: Object, required: true },
    stops: { type: Array, required: true },
    disabled: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
.button::v-deep {
  margin-left: 10px;
}
</style>

<template>
  <TeamTypeAheadField
    :options="teams"
    :value="selectedTeam"
    placeholder="Select a team"
    :title="$t('ordering/dispatching-team-dropdown-field-title')"
    openDirection="top"
    class="field"
    :disabled="!currentUserIsPMTeamAssigner"
    data-test="carrier-management-team"
    @select="onSelect"
  />
</template>
<script>
import { mapGetters } from 'vuex'
import {
  ACCOUNTABILITY_ENTITY_TYPES,
  fetchAccountableTeams,
  assignAccountableTeams
} from '@/services/accountability-service'
import { fetchTeamsById } from '@/services/team-management-service'
import orderingMappers from '@/modules/ordering/store/mappers'
import TeamTypeAheadField from '@/modules/ordering/components/shared/TeamTypeAheadField.vue'

export default {
  components: {
    TeamTypeAheadField
  },
  props: {
    orderId: { type: Number, required: true }
  },
  data() {
    return {
      selectedTeam: null,
      teams: []
    }
  },
  computed: {
    ...mapGetters(['currentUserIsPMTeamAssigner']),
    ...orderingMappers.mapGetters(['getTransportOfferId'])
  },
  async created() {
    if (this.getTransportOfferId) {
      const orderResponsibleTeam = await fetchAccountableTeams([
        {
          entity_id: this.getTransportOfferId,
          entity_type: ACCOUNTABILITY_ENTITY_TYPES.TRANSPORT_OFFER
        }
      ])
      if (orderResponsibleTeam && orderResponsibleTeam[this.getTransportOfferId]) {
        this.teams = await fetchTeamsById(orderResponsibleTeam[this.getTransportOfferId])
        const filter_team = this.teams.filter(
          e => e.id === orderResponsibleTeam[this.getTransportOfferId]
        )
        this.selectedTeam = filter_team.length > 0 ? filter_team[0] : null
      }
    }
  },
  methods: {
    async onSelect(event) {
      if (event?.id) {
        this.selectedTeam = event
        await assignAccountableTeams(
          [
            {
              entity_id: this.getTransportOfferId,
              entity_type: ACCOUNTABILITY_ENTITY_TYPES.TRANSPORT_OFFER
            }
          ],
          event.id
        )
      }
    }
  }
}
</script>

import {
  capitalize,
  formatDateWithTz,
  formatTimeWithTz,
  getTimezoneAcronym
} from '@/global-setup/filters'
import Order from '@/models/order.js'
import { VEHICLE_TYPES } from '@sennder/octopus-constants'
import { addressLongFormat } from '@/modules/common/filters/address-filters'
import getAlphabetEquivalent from '@/compositions/transfer/useStopLetter'
import { ORCAS_URL } from '@/config'
import { ShipmentStop, LoadRequestSnakeCase } from '@/modules/shipment/types/shipment'
import { transformKeysToSnakeCase } from '@/services/utils/casing'

const humanizeVehicleType = vehicleType => VEHICLE_TYPES[vehicleType]

const humanizeVehicleTypes = vehicleTypes => {
  const humanizedValue = vehicleTypes.reduce((accumulator, currentValue) => {
    return accumulator + humanizeVehicleType(currentValue.toUpperCase()) + ', '
  }, '')

  return humanizedValue ? humanizedValue.slice(0, -2) : humanizedValue
}
const humanizeRequirements = (shipment: LoadRequestSnakeCase) => {
  const formatted = []
  if (shipment.requirements.pallet_exchange?.required) formatted.push('Pallet Exchange')
  if (shipment.mothership_compatibility?.arrival_notification)
    formatted.push('Arrival Notice')
  if (shipment.requirements.vehicle?.securing?.sealable) formatted.push('Seal')
  if (shipment.requirements.certification?.code_xl) formatted.push('Code XL')

  return formatted.length > 0 ? formatted.join(' | ') : 'None'
}

export const getShipmentDetailsForClipboard = (
  shipment: LoadRequestSnakeCase,
  shipmentStops: ShipmentStop[],
  user_id: string
) => {
  let content = ''

  if (shipmentStops) {
    shipmentStops.forEach((shipmentStop, index) => {
      content += getOrderStopsDetails(shipmentStop, getAlphabetEquivalent(index))
    })
  }

  content += getOrderVehicleDetails(shipment)

  if (shipment.loads[0].requirements.temperature.minimum_degrees_celsius) {
    content += getOrderTemperatureDetails(shipment.loads[0].requirements.temperature)
  }

  content += getOrderSpecificationDetails(shipment)

  content += getOrderRequirementsDetails(shipment)

  if (shipment.requirements.unstructured_requirements) {
    content += `Description: ${
      shipment.requirements.unstructured_requirements
    }${generateLineBreaks(2)}`
  }

  content += `Access the order in orcas here : ${getOrcasOrderLink(
    shipment.external_id,
    user_id
  )}`
  content += `${generateLineBreaks(1)}---${generateLineBreaks(2)}`

  return content
}

function getOrderStopsDetails(stop: ShipmentStop, letter: string) {
  let content = ''
  const timezone = stop.warehouseAddress.timezone
  content += `${letter}. ${capitalize(stop.stopoverType)}: ${addressLongFormat(
    stop.warehouseAddress
  )}${generateLineBreaks(1)}`

  content += `Date ${formatDateWithTz(stop.start, timezone)} (${formatTimeWithTz(
    stop.start,
    timezone
  )}-${formatTimeWithTz(stop.end, timezone)} ${getTimezoneAcronym(
    stop.start,
    timezone
  )})${generateLineBreaks(1)}`
  return content
}

function getOrderVehicleDetails(shipment: LoadRequestSnakeCase) {
  const acceptedVehicles = Object.keys(
    shipment.requirements.vehicle.acceptable_replacement_vehicle_types
  )
    .filter(
      key => shipment.requirements.vehicle.acceptable_replacement_vehicle_types[key]
    )
    ?.map(t => transformKeysToSnakeCase(t))

  return `${generateLineBreaks(1)}Accepted Vehicles: ${humanizeVehicleTypes(
    [shipment.requirements.vehicle.preferred_vehicle_type].concat(
      (acceptedVehicles || []) as string[]
    )
  )}${generateLineBreaks(1)}`
}

function getOrderTemperatureDetails(temperature: Record<string, never>) {
  return `Temperature: ${temperature.minimum_degrees_celsius}°C / ${
    temperature.maximum_degrees_celsius
  }°C${generateLineBreaks(1)}`
}

function getOrderSpecificationDetails(shipment: LoadRequestSnakeCase) {
  const formatted = []

  if (shipment.loads[0].quantity) {
    const loadUnitString =
      humanizeLoadUnitType(shipment.loads[0].pallet_type) || '(No unit specified)'
    formatted.push(shipment.loads[0].quantity + ' ' + loadUnitString)
  } else {
    formatted.push('Quantity not Specified')
  }

  if (shipment.loads[0].weight_in_kg) {
    formatted.push(shipment.loads[0].weight_in_kg / 1000 + ' ton(s)')
  } else {
    formatted.push('Weight not Specified')
  }

  formatted.push(formatDimensions(shipment))

  return `Load: ${formatted.join(' | ')}${generateLineBreaks(1)}`
}

function getOrderRequirementsDetails(shipment: LoadRequestSnakeCase) {
  return `Other requirements: ${humanizeRequirements(shipment)}${generateLineBreaks(1)}`
}

function getOrcasOrderLink(idForStaff: string, Uuid: string) {
  return `${ORCAS_URL}/order-details/${idForStaff}?referral_id=${Uuid}`
}

function generateLineBreaks(count: number) {
  return LINE_BREAK.repeat(count)
}

function humanizeLoadUnitType(unitType: string) {
  return Order.LOAD_UNIT_TYPES[unitType]
}

function formatDimensions(shipment: LoadRequestSnakeCase) {
  if (noDimensions(shipment)) return 'No Dimensions'

  const formatted = []
  formatted.push(
    shipment.loads[0].length_in_meters
      ? '(L)' + shipment.loads[0].length_in_meters * 100 + 'cm'
      : 'no length'
  )
  formatted.push(
    shipment.loads[0].height_in_meters
      ? '(H)' + shipment.loads[0].height_in_meters * 100 + 'cm'
      : 'no height'
  )
  formatted.push(
    shipment.loads[0].width_in_meters
      ? '(W)' + shipment.loads[0].width_in_meters * 100 + 'cm'
      : 'no width'
  )

  return formatted.join(' x ')
}

function noDimensions(shipment: LoadRequestSnakeCase) {
  return (
    !shipment.loads[0].length_in_meters &&
    !shipment.loads[0].height_in_meters &&
    !shipment.loads[0].width_in_meters
  )
}

const LINE_BREAK = '\r\n'

import { runJsonPost } from '@/models/backend-client'
import { OPERATIONS_BACKEND_URL, ASSIGNMENTS_API_URL } from '@/config'
import { transformKeysToCamelCase } from './utils/casing'

export const loadCarrierAssignmentsDetails = async orderIds => {
  const result = await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/order-assignments/queries/get-order-assignment-details`,
    { order_ids: orderIds }
  )

  return transformKeysToCamelCase(result.data)
}

export const cancelCarrierAssignment = async assignmentId => {
  await runJsonPost(
    `${ASSIGNMENTS_API_URL}/api/assignments/cancel-assignment`,
    {
      assignment_id: assignmentId
    },
    { apiGatewayAuthorization: true },
    'https://api.cloud.sennder.com/carrier-assign',
    'carrier_assignment:cancel'
  )
}

<template>
  <div v-tooltip.bottom="tooltipOptions" class="avatar">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    tooltip: { type: String, default: '' }
  },
  computed: {
    tooltipOptions() {
      return { content: this.tooltip, delay: { show: 500 } }
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: $color-layout-card-background;
  border: 2px solid transparent;
  width: 30px;
  height: 30px;
  font-size: 10px;
  font-weight: bold;
  color: $color-neutral-main;
}

.avatar:first-child {
  position: relative;
}

.avatar:not(:first-child) {
  margin-left: -6px;
}
</style>

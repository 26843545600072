







import Vue from 'vue'

import { Location, RawLocation, Route } from 'vue-router'
import {
  IUser,
  Tenant,
  SharedDataType
} from '@sennder/senn-node-microfrontend-interfaces'
import { getAppTheme } from '@/controllers/environment-detection'
import useCurrentUser from '@/compositions/useCurrentUser'
import useI18n from '@/compositions/useI18n'
import { getAuthHeader, getAuthToken, getCommonHeaders } from '@/services/auth-service'
import { IPODReviewWidgetSharedData } from './types'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import {
  createRandomString,
  getEnvironmentAliasForFederatedLoader
} from '@/microfrontends/utils'
import { fetchMicrofrontend } from '@/microfrontends/fetchMicrofrontend'
import { getLogger } from '@/shell/datadog-logger'

const NAMESPACE = 'podReviewWidget'
const REMOTE = './podReviewWidgetApp'
const NPM_PATH = 'podReviewWidget'
const DEV_PORT = 8083

export default Vue.extend({
  name: 'PODReviewWidget',
  props: {
    disabled: Boolean,
    includeTracking: Boolean,
    orderId: {
      type: Number,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      id: `${createRandomString()}-podReviewLoader`,
      isLoaded: null as boolean | null,
      syncChildRouter: null as ((route: RawLocation) => void) | null,
      unmount: null as (() => void) | null,
      localDisabled: this.disabled,
      syncProps: null as ((props: unknown) => void) | null
    }
  },
  async mounted() {
    const syncParentRouter = (route: RawLocation) => {
      const currentPath = this.$route.fullPath
      if (
        (typeof route === 'string' && currentPath === route) ||
        (typeof route === 'object' && currentPath === (route as Location).path)
      ) {
        return
      }
      this.$router.push(route)
    }

    try {
      const { locale } = useI18n()
      const { user } = useCurrentUser()
      const { isActive } = useFeatureFlag()

      const mount = await fetchMicrofrontend<IPODReviewWidgetSharedData>({
        devPort: DEV_PORT,
        environment: getEnvironmentAliasForFederatedLoader(NAMESPACE),
        moduleFederationPluginName: NAMESPACE,
        npmName: NPM_PATH,
        mountExposedName: REMOTE,
        logger: getLogger()
      })
      const mfData = mount(`#${this.id}`, {
        data: {
          type: SharedDataType.OCTOPUS,
          env: {},
          user: user.value as IUser,
          language: locale.value,
          tenant: getAppTheme() as Tenant,
          props: {
            includeTracking: this.includeTracking,
            orderId: this.orderId,
            editable: this.editable
          },
          callbacks: {},
          featureFlags: {
            ENABLE_V4_POD_UPLOAD_FINANCE: isActive('enable-v4-pod-upload-finance').value
          }
        },
        callbacks: {
          getAuthHeader,
          getAuthToken,
          syncParentRouter,
          // segment is deprecated, use Amplitude provider instead
          segmentTrackEvent: null,
          onUnauthorized: () => {},
          onUnhandledError: error => {
            Vue.config.errorHandler(error, null, 'Octopus Payments Widget')
          },
          getCommonHeaders
        },
        providers: {
          // TODO: define appropriate handlers in Octopus or make them optional
          logger: getLogger(),
          notifications: null,
          translations: null,
          analytics: null,
          monitoring: null
        }
      })

      if (mfData.onSharedDataChanged) {
        this.$watch(
          '$props',
          newProps => {
            this.syncProps && mfData.onSharedDataChanged(newProps)
          },
          {
            deep: true,
            immediate: true
          }
        )

        this.syncProps = (args: unknown) =>
          mfData.onSharedDataChanged(args as IPODReviewWidgetSharedData)
      }

      if (mfData.syncChildRouter) {
        this.syncChildRouter = mfData.syncChildRouter
        this.$watch(
          '$route',
          (to: Route) => {
            mfData.syncChildRouter(to.fullPath)
          },
          {
            immediate: true
          }
        )
      }
      if (mfData.unmount) {
        this.unmount = mfData.unmount
      }
      this.isLoaded = true
    } catch (e) {
      Vue.config.errorHandler(
        new Error('PODReviewWidget MF bootstrap error: '),
        this,
        e as string
      )
      this.isLoaded = false
    }
  }
})

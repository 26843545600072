import Vue from 'vue'
import pickBy from 'lodash/pickBy'

export default class Model {
  constructor(data, state) {
    this._state = state
    this.validateRequiredFields(data)
    this.load(data)
    this.selected = false
  }

  get $state() {
    if (!this._state)
      throw Error(
        'You are trying to use this.$state in a model but $state is undefined ' +
          '(you probably forgot to pass store.state as second parameter in the constructor)'
      )
    return this._state
  }

  get requiredFields() {
    return []
  }

  validateRequiredFields(data) {
    if (!this.requiredFields) return
    this.requiredFields.forEach(field => {
      if (!data || (data && !Object.prototype.hasOwnProperty.call(data, field)))
        this.throwValidationError()
    })
  }

  throwValidationError() {
    throw new Error(
      'Cannot create a ' +
        this.constructor.name +
        ' without required fields ' +
        `(${this.requiredFields})`
    )
  }

  load(data) {
    for (const key in data) {
      const value = data[key]
      Vue.set(this, key, value)
    }
  }

  filterBy(object, predicate) {
    return pickBy(object, predicate)
  }
}

<template>
  <div class="root">
    <OrderStatusTabs :order="order" />
    <component
      :is="activeOrderStageComponent"
      :class="{
        'step-content--without-tabs': isJustOneTabVisible
      }"
      :order="order"
      v-bind="$attrs"
      v-on="$listeners"
      class="step-content"
    />
  </div>
</template>

<script>
import orderingMappers from '@/modules/ordering/store/mappers'
import OrderStatusTabs from './OrderStatusTabs'
import RegistrationStage from '../registration/RegistrationStage'
import DispatchingStage from '../dispatching/DispatchingStage'
import ExecutionStage from '../execution/ExecutionStage'
import ClosingStage from '../closing/ClosingStage'
import PlanningStage from '../planning/PlanningStage'
import { trackEvent } from '@/analytics/amplitude'
import { events, trackingModule } from '../../events'

export default {
  components: {
    OrderStatusTabs,
    RegistrationStage,
    DispatchingStage,
    ExecutionStage,
    ClosingStage,
    PlanningStage
  },
  props: {
    order: { type: Object, required: false, default: null }
  },
  computed: {
    ...orderingMappers.mapState(['visibleState', 'navItems']),
    ...orderingMappers.mapGetters(['isJustOneTabVisible']),
    activeOrderStageComponent() {
      return this.navItems.find(this.isNavItemActive)?.component
    },
    activeOrderStageTracker() {
      return this.navItems.find(this.isNavItemActive)?.tracker
    }
  },
  watch: {
    activeOrderStageTracker(newTracker, oldTracker) {
      if (newTracker === oldTracker) return

      this.trackActiveStage()
    }
  },
  async mounted() {
    this.trackActiveStage()
  },
  methods: {
    isNavItemActive(navItem) {
      return navItem.states.includes(this.visibleState)
    },
    trackActiveStage() {
      if (this.activeOrderStageTracker) {
        trackEvent(events.ORDERING_PAGE_VIEW, {
          orderId: this.order.idForStaff,
          module: trackingModule,
          section: this.activeOrderStageTracker
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/modules/ordering/variables';

.root {
  flex-grow: 3;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative; // required for absolute position of footer.
  overflow-x: auto;
}

.step-content {
  min-width: $ordering-content-min-width;
  overflow-y: auto;
  background-color: $color-layout-white;
}
</style>

<template>
  <div>
    <DateRangeInputField
      v-model="value"
      locale="en-US"
      :title="titleLabel"
      :input-date-filter="displayTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'"
      :open-direction="openDirection"
      :placeholder="$t('filters/date-range-filter-placeholder')"
      data-test="select-date-range"
      full-width
      time-picker
      :time-picker-increment="15"
      :ranges="defaultRanges"
      :append-to-body="appendToBody"
    />
  </div>
</template>

<script>
import moment from 'moment-timezone'

export default {
  inject: ['getFilterValue', 'setFilterValue'],
  props: {
    filterName: { type: String, required: true },
    titleLabel: { type: String, default: '' },
    openDirection: { type: String, default: 'left' },
    displayTime: { type: Boolean, default: true },
    appendToBody: { type: Boolean, default: true }
  },
  computed: {
    defaultRanges() {
      return {
        [this.$t('filters/date-range-filter-today-only')]: [
          moment().startOf('day').toISOString(),
          moment().endOf('day').toISOString()
        ],
        [this.$t('filters/date-range-filter-tomorrow-only')]: [
          moment().startOf('day').add(1, 'days').toISOString(),
          moment().endOf('day').add(1, 'days').toISOString()
        ],
        [this.$t('filters/date-range-filter-next-week')]: [
          moment()
            .add(1, 'week')
            .startOf('week')
            .startOf('day')
            .add(1, 'days')
            .toISOString(),
          moment().add(1, 'week').endOf('week').endOf('day').add(1, 'days').toISOString()
        ],
        [this.$t('filters/date-range-filter-next-seven-days')]: [
          moment().startOf('day').add(1, 'days').toISOString(),
          moment().endOf('day').add(7, 'days').toISOString()
        ]
      }
    },
    value: {
      get() {
        return {
          startDate: this.getFilterValue(this.filterName)[0],
          endDate: this.getFilterValue(this.filterName)[1]
        }
      },
      set(value) {
        this.setFilterValue(this.filterName, [
          value.startDate ? value.startDate : null,
          value.endDate ? value.endDate : null
        ])
        this.$emit('applyFilters')
      }
    }
  }
}
</script>

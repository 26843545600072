import { ref, onMounted } from '@vue/composition-api'

/**
 * Creates a lazy-initialized ref having the actual value as a result of a call
 * to an asychronous method (e.g. an ajax call)
 *
 * @template T
 * @param {T} value initial value, before calling the initializer
 * @param {Function<Promise<T>>} initializer asynchronous initializer that will get the actual value
 * @return {Function<ref<T>>} ref that will be lazy initialized when component using it will be mounted
 */
export function lazyRef(value, initializer = () => null) {
  let data = null
  return () => {
    if (data === null) {
      data = ref(value)
      onMounted(async () => {
        data.value = await initializer()
      })
    }
    return data
  }
}

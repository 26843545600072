import { searchOrdersQuery } from './search-orders-query'
import ServiceTransaction from '@/services/utils/ServiceTransaction'
import usePersons from '@/compositions/usePersons'
import useTransfersList from '@/compositions/transfer/useTransfersList'

let currentTransaction = null
export const loadOrdersPage = async (store, { offset, limit, clearBeforeUpdate }) => {
  let newOrders = []

  if (currentTransaction) await currentTransaction.cancel()
  store.commit('startLoadingPage', null, { root: true })

  if (clearBeforeUpdate) store.commit('clearFilteredOrders')
  if (store.getters['filters/noFiltersSelected']) {
    store.commit('clearFilteredOrders')
    store.commit('finishLoadingPage', null, { root: true })
    return []
  }

  // Clear the data container for graphQL requests
  store.commit('resetOrders', null, { root: true })

  const generateFilters = store.getters['filters/generateGraphqlStringWithPagination']
  const filters = generateFilters(offset, limit, ['pickup_end'])
  const query = `orders ( ${filters} ) ${searchOrdersQuery}`
  try {
    // TODO: replace graphQL with a REST API and get rid of store.rootState.orders
    currentTransaction = new ServiceTransaction()
    newOrders = await store.dispatch(
      'fetch',
      { query, transaction: currentTransaction },
      { root: true }
    )

    const { fetchPersons } = usePersons()
    const { fetchTransfers, transfersData } = useTransfersList()

    const personIds = newOrders.persons ? Object.keys(newOrders.persons) : []
    const transferIds = newOrders.transfers ? Object.keys(newOrders.transfers) : []
    await fetchTransfers(transferIds)

    //add transfers persons ids so they are included in the global persons array list accross the app

    transfersData.value?.forEach(transfer => {
      if (transfer.operator_id && !personIds.includes(transfer.operator_id)) {
        personIds.push(transfer.operator_id)
      }
    })

    await fetchPersons(personIds)
    store.commit('addFilteredOrders', Object.values(store.rootState.orders))
  } finally {
    currentTransaction = null
    store.commit('finishLoadingPage', null, { root: true })
  }
  return newOrders.orders ? Object.values(newOrders.orders) : [] // to find out when we're out of orders
}

<template>
  <div>
    <div v-if="isDone" class="step-completed" data-test="step-completed">
      <Icon materialIcon="check" class="step-completed-icon" />
      {{ $t('ordering/execution-step-status-completed') }}
    </div>
    <Button
      v-else
      :disabled="disabled"
      category="link"
      class="mark-as-completed-btn"
      data-test="complete-btn"
      @click="markStepAsCompleted"
    >
      {{ $t('ordering/execution-step-status-button') }}
    </Button>
  </div>
</template>
<script>
import { Icon } from '@sennder/plankton'

export default {
  components: { Icon },
  model: {
    prop: 'isDone',
    event: 'update:isDone'
  },
  props: {
    isDone: { type: Boolean, required: true },
    disabled: { type: Boolean, default: false }
  },
  setup(_props, { emit }) {
    const markStepAsCompleted = () => emit('update:isDone', true)

    return {
      markStepAsCompleted
    }
  }
}
</script>
<style lang="scss" scoped>
.step-completed {
  color: $color-success-main;
}

.step-completed-icon {
  font-size: 12px;
}

.mark-as-completed-btn,
.mark-as-completed-btn:hover {
  /* we need !important here to override Plankton styles */
  text-decoration: underline !important;
}
</style>

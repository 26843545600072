
























import { defineComponent, PropType, computed } from '@vue/composition-api'
import FacilityPlanningPage from '@/microfrontends/pages/facility-planning/FacilityPlanningPage.vue'
import { ExtendedPerson } from '@/compositions/useOrder'
import useStore from '@/compositions/useStore'
import orderingMappers from '@/modules/ordering/store/mappers'
import { OrderDetail } from '@/services'
import OrderStageFooter from '@/modules/ordering/components/shared/OrderStageFooter.vue'
import {
  subscribe,
  MicrofrontendEvents
} from '@sennder/senn-node-microfrontend-event-bus'
import { SubscriptionResult } from '@sennder/senn-node-microfrontend-event-bus/lib/eventBus/index'
import { polling } from '@sennder/senn-node-toolkit'
import { PollingReturnType } from '@sennder/senn-node-toolkit/lib/tools/polling/types'
import * as orderingService from '@/services/ordering-service'

interface PlanningStageData {
  facilityPlanningUnsavedChangesSubscription: SubscriptionResult | null
  facilityPlanningUpdateSubscription: SubscriptionResult | null
  pollingFacilityPlanningUpdateSubscription: SubscriptionResult | null
  stopRefetchingOrder: PollingReturnType | null
}

export default defineComponent({
  components: {
    FacilityPlanningPage,
    OrderStageFooter
  },
  props: {
    order: { type: Object as PropType<OrderDetail>, required: true },
    accountManager: { type: Object as PropType<ExtendedPerson>, default: null },
    juniorAccountManager: { type: Object as PropType<ExtendedPerson>, default: null },
    partnerManager: { type: Object as PropType<ExtendedPerson>, default: null },
    spotBidder: { type: Object as PropType<ExtendedPerson>, default: null }
  },
  setup() {
    const store = useStore()
    const shipmentId = computed(() => store.getters['ordering/getShipmentID'])

    return { shipmentId }
  },
  data(): PlanningStageData {
    return {
      facilityPlanningUnsavedChangesSubscription: null,
      facilityPlanningUpdateSubscription: null,
      pollingFacilityPlanningUpdateSubscription: null,
      stopRefetchingOrder: null
    }
  },
  async mounted() {
    this.facilityPlanningUnsavedChangesSubscription = subscribe(
      MicrofrontendEvents.onFacilityPlanUnsavedChanges,
      () => {
        this.setDirtyPlanningForm(true)
      }
    )

    this.facilityPlanningUpdateSubscription = subscribe(
      MicrofrontendEvents.onUpdateFacilityPlan,
      () => {
        this.setDirtyPlanningForm(false)
      }
    )

    this.pollingFacilityPlanningUpdateSubscription = subscribe(
      MicrofrontendEvents.onUpdateFacilityPlan,
      () => {
        const pollingResult = polling(
          orderingService.fetchOrder.bind(null, this.order.id),
          {
            delay: 1000,
            timeout: 15000,
            stopCallback: result => result.payload.state !== 'NEW',
            onPollingCompleted: () => {
              this.updateFacilityPlan()
            }
          }
        )
        this.stopRefetchingOrder = pollingResult
      }
    )
  },
  unmounted() {
    this.facilityPlanningUnsavedChangesSubscription?.unsubscribe()
    this.facilityPlanningUpdateSubscription?.unsubscribe()
    this.pollingFacilityPlanningUpdateSubscription?.unsubscribe()

    if (this.stopRefetchingOrder?.stopPolling) {
      this.stopRefetchingOrder.stopPolling()
    }
  },
  methods: {
    ...orderingMappers.mapActions(['setDirtyPlanningForm', 'updateFacilityPlan'])
  }
})

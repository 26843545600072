export const FINANCE = 'Finance'
export const FINANCE_AR = 'FinanceAccountsReceivable'
export const STAFF = 'Staff'
export const CALL_CENTER = 'CallCenter'
export const FOUNDER = 'Founder'
export const ENGINEERING = 'Engineer'
export const PRODUCT_OWNER = 'Product'
export const ACCOUNT_MANAGERS = 'AccountManager'
export const PARTNER_MANAGERS = 'PartnerManager'
export const PARTNER_MANAGEMENT_ADMINS = 'PartnerManagementAdmins'
export const CSV_UPLOAD = 'OctopusGeneralCSVUploadTester'
export const NEW_CSV_UPLOAD = 'OctopusCSVUploadTester'
export const ORDER_TEMPLATE = 'OrderTemplateUploadTester'
export const DIRECT_DISPATCHER = 'DirectDispatcher'
export const CLAIM_ACCESSORIAL_MANAGER = 'ClaimAccessorialManager'
export const PARTNER_MANAGERS_TEAM_ASSIGNER = 'PMTeamAssigner'
export const PMA_READ_ONLY_USER = 'PmaReadOnlyUser'
























import moment from 'moment'
import { humanizeTimeBetween } from '@sennder/plankton'
import { defineComponent, computed, toRef } from '@vue/composition-api'
import { OrderState } from '@/modules/common/order-states'
export default defineComponent({
  props: {
    order: { type: Object, required: true }
  },
  setup(props) {
    const order = toRef(props, 'order')

    const carrierName = computed(() => {
      return order.value.carrier ? order.value.carrier.name : '-'
    })
    const orderIsLocked = computed(() => {
      return (
        (order.value.state as OrderState) !== 'NEW' &&
        (order.value.state as OrderState) !== 'REGISTERED'
      )
    })
    const computeStatus = computed(() => {
      const lastAssignment = order.value.assignments.reduce((a, b) => {
        return new Date(a.created) > new Date(b.created) ? a : b
      }, {})

      if (lastAssignment?.state === 'PENDING') {
        return {
          text: 'Pending assignment',
          tooltip: `Sent to ${lastAssignment.carrier.name}<br/>
          Remaining: ${humanizeTimeBetween(
            lastAssignment.validUntil,
            moment(Date.now())
          )} left`,
          visualStatus: 'warning'
        }
      }

      const openedOffers = order.value.transportRequests.filter(
        t => t.state === 'CREATED' || t.state === 'ACCEPTED'
      )

      if (openedOffers.length > 0) {
        const orcasCount = openedOffers.filter(t => t.requestSource === 'ORCAS').length
        const octopusCount = openedOffers.filter(
          t => t.requestSource === 'OCTOPUS'
        ).length
        return {
          text: `${openedOffers.length} offer(s) posted`,
          tooltip: `${orcasCount} orcas offer(s)</br>${octopusCount} PM offer(s)`
        }
      }

      if (lastAssignment?.state === 'CANCELLED') {
        const cancelledBy = lastAssignment.cancelledBy
          ? `By ${lastAssignment.cancelledBy.name}`
          : ''
        return {
          text: 'Withdrawn assignment',
          tooltip: cancelledBy,
          visualStatus: 'disabled'
        }
      }

      if (lastAssignment?.state === 'REJECTED') {
        return {
          text: 'Rejected assignment',
          tooltip: `By ${lastAssignment.carrier.name}`,
          visualStatus: 'error'
        }
      }
      return { text: '-', tooltip: '', visualStatus: '' }
    })
    return { carrierName, computeStatus, orderIsLocked }
  }
})

<template>
  <div class="filters-content">
    <DateRangeFilter
      v-on="$listeners"
      titleLabel="Pickup date"
      filterName="pickupRange"
    />
    <DateRangeFilter
      v-on="$listeners"
      titleLabel="Dropoff date"
      filterName="dropoffRange"
    />

    <CountryFilter v-on="$listeners" />

    <PartnerManagerFilter v-on="$listeners" />

    <SeniorAccountManagerFilter v-on="$listeners" />

    <AccountManagerFilter v-on="$listeners" />

    <CarrierFilter v-on="$listeners" />

    <CustomerFilter v-on="$listeners" />

    <ReferenceFilters
      :fields="['transferCode', 'licensePlateNumber', 'idsForStaff']"
      v-on="$listeners"
    />

    <OrderStateFilter :orderStates="orderStates" v-on="$listeners" />

    <VehicleTypesFilter v-on="$listeners" />

    <ClaimStatusFilter v-on="$listeners" />

    <OrderSourceFilter v-on="$listeners" />
  </div>
</template>

<script>
import {
  DateRangeFilter,
  CountryFilter,
  PartnerManagerFilter,
  AccountManagerFilter,
  SeniorAccountManagerFilter,
  CarrierFilter,
  CustomerFilter,
  ReferenceFilters,
  OrderStateFilter,
  VehicleTypesFilter,
  ClaimStatusFilter,
  OrderSourceFilter
} from '@/modules/filters'

import { BOARD_FILTER_ORDER_STATES } from '../constants'
export default {
  components: {
    DateRangeFilter,
    CountryFilter,
    PartnerManagerFilter,
    AccountManagerFilter,
    SeniorAccountManagerFilter,
    CarrierFilter,
    CustomerFilter,
    ReferenceFilters,
    OrderStateFilter,
    VehicleTypesFilter,
    ClaimStatusFilter,
    OrderSourceFilter
  },
  setup() {
    return {
      orderStates: BOARD_FILTER_ORDER_STATES
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/modules/filters/styles/filters';
</style>

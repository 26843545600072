import { runJsonGet, runJsonPost } from '@/models/backend-client'
import { OPERATIONS_BACKEND_URL } from '@/config'
import { transformKeysToCamelCase, transformKeysToSnakeCase } from './utils/casing'
// Imports below required for the proper code autocomplete until the file will be replaced with .ts one
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OrderTemplate, CustomerTemplate } from '.'

export const fetchCustomersBySearchText = async searchText => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/retrieve_customer_companies?name_pattern=${encodeURIComponent(
      searchText
    )}`
  )
  return response.data
}

export const fetchClosedLiveCustomersBySearchText = async searchText => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/retrieve_closed_live_customer_companies?name_pattern=${encodeURIComponent(
      searchText
    )}`
  )
  return response.data
}

export const fetchCustomerById = async id => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/customer/${id}`
  )
  return response.data
}

/**
 * @param {number} customerId
 * @returns {Promise<CustomerAddress[]>}
 */
export const fetchCustomerAddresses = async customerId => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/get-customer-addresses`,
    { customer_id: customerId }
  )
  return transformKeysToCamelCase(response.data)
}

/**
 * @param {number} customerId
 * @returns {Promise<CustomerContact[]>}
 */
export const fetchCustomerContacts = async customerId => {
  const { data = [] } = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/get-customer-contacts?customer_id=${customerId}`
  )

  const normalizedData = data.map(customerContact => ({
    id: customerContact.id,
    name: `${customerContact.first_name || ''} ${customerContact.last_name || ''}`,
    email: customerContact.email,
    phone: customerContact.phone
  }))

  return normalizedData
}

/**
 * @param {number} customerId
 * @returns {Promise<CustomerTemplate[]>}
 */
export const fetchCustomerTemplates = async customerId => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/get-customer-templates`,
    transformKeysToSnakeCase({
      customerId
    })
  )
  if (!Array.isArray(response.data)) {
    return []
  }
  return transformKeysToCamelCase(response.data)
}

/**
 * @param {string} lineCode
 * @returns {Promise<OrderTemplate>}
 */
export const fetchTemplate = async lineCode => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/get-order-template`,
    transformKeysToSnakeCase({ lineCode })
  )

  return transformKeysToCamelCase(response.data)
}

export const createCustomerAddress = async (customerId, fullAddress, description) => {
  const response = await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/ordering/company_addresses/`,
    { company: customerId, address: fullAddress, description }
  )
  return {
    companyAddressId: response.data.id,
    fullAddress: `${response.data.address.formatted}${
      description ? ', ' + description : ''
    }`,
    timezone: response.data.address.timezone
  }
}

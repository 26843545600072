import filter from 'lodash/filter'
import pickBy from 'lodash/pickBy'

// Abstractions to manage customer and carrier breakdown charges (price/cost charges and surcharges) and invoices
// The backend returns this data inside the order model, that we wrap to manage symmetry and avoid duplication
// between closing and finance components for customers and carriers
class StakeholderFinancialBreakdown {
  constructor(order) {
    this.order = order
  }

  get orderId() {
    return this.order.id
  }

  get $state() {
    return this.order.$state
  }
}

export class CustomerFinancialBreakdown extends StakeholderFinancialBreakdown {
  get type() {
    return 'CUSTOMER'
  }

  get baseCharge() {
    return this.priceCharge
  }

  get priceCharge() {
    return this.$state.charges[this.order.base_price_charge_id]
  }

  get baseFee() {
    // This property should be avoided when we migrate old base_price field to charges model
    // so that would be replaced by this.baseCharge.currentAmount
    return this.order.base_price
  }

  get baseChargeInitialAmount() {
    if (this.priceCharge) return this.priceCharge.initial_amount
    // This won't be needed when we migrate price_charge_id property to all orders
    // so this field should be merged with baseFee
    return this.baseFee
  }

  get baseChargeCorrections() {
    return this.priceChargeCorrections
  }

  get priceChargeCorrections() {
    return filter(this.$state.charge_corrections, correction => {
      if (this.priceCharge && this.priceCharge.charge_corrections_ids)
        return this.priceCharge.charge_corrections_ids.includes(correction.id)
    })
  }

  get extraCharges() {
    return filter(this.$state.customer_extra_charges, extra_charge => {
      return extra_charge.order_id === this.order.id
    })
  }

  get totalNet() {
    return this.order.total_net_price
  }
}

export class CarrierFinancialBreakdown extends StakeholderFinancialBreakdown {
  get type() {
    return 'CARRIER'
  }

  get baseCharge() {
    return this.costCharge
  }

  get costCharge() {
    return this.$state.charges[this.order.base_cost_charge_id]
  }

  get baseFee() {
    // This property should be avoided when we migrate old base_price field to charges model
    // so that would be replaced by this.baseCharge.currentAmount
    return this.order.base_cost
  }

  get baseChargeInitialAmount() {
    if (this.costCharge) return this.costCharge.initial_amount
    // This won't be needed when we migrate cost_charge_id property to all orders
    // so this field should be merged with baseFee
    return this.baseFee
  }

  get baseChargeCorrections() {
    return this.costChargeCorrections
  }

  get costChargeCorrections() {
    return filter(this.$state.charge_corrections, correction => {
      if (this.costCharge && this.costCharge.charge_corrections_ids)
        return this.costCharge.charge_corrections_ids.includes(correction.id)
    })
  }

  get extraCharges() {
    return filter(this.$state.carrier_extra_charges, extra_charge => {
      return extra_charge.order_id === this.order.id
    })
  }

  get totalNet() {
    return this.order.total_net_cost
  }

  get invoices() {
    return pickBy(
      this.$state.carrier_invoices,
      invoice => invoice.order_id == this.order.id
    )
  }

  get totalInvoiceAmount() {
    return this.order.total_carrier_invoice_amount
  }
}

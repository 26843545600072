<template>
  <div class="toaster" v-on="$listeners" v-bind="$attrs" v-show="isActive">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    async show() {
      this.isActive = true
      await this.$nextTick()
    },
    dismiss() {
      this.isActive = false
      return new Promise(resolve => {
        setTimeout(resolve, 1000)
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.toaster {
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  border-radius: 4px;
  margin-top: 8px;
  min-width: 300px;
  max-width: 600px;
  width: fit-content;
  color: $color-layout-white;
  background-color: rgba(75, 75, 75, 0.96);
  transition: top 1s ease;
}
</style>

<style lang="scss">
.toaster-container {
  position: fixed;
  inset: 16px;
  pointer-events: none;
  z-index: 1054;
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 0;
  transition: all ease 0.5s;
}
</style>

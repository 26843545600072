













import { defineComponent } from '@vue/composition-api'
import ExpandingCardSkeleton from '@/modules/common/components/ExpandingCardSkeleton.vue'

export default defineComponent({
  components: { ExpandingCardSkeleton }
})

<template>
  <fragment>
    <slot v-if="isSennderTheme" name="sennder"></slot>
    <slot v-if="isPosteTheme" name="poste"></slot>
  </fragment>
</template>

<script>
import { isSennderTheme, isPosteTheme } from '@/controllers/environment-detection'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup() {
    return {
      isSennderTheme,
      isPosteTheme
    }
  }
})
</script>

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

/**
 * This logger allows to use console log in more controlled manner.
 * Some types of logs will be displayed or not be displayed based on the current log level.
 */
import bus from '../bus'
import process from 'process'

const LOG_LEVELS = Object.freeze(['trace', 'debug', 'info', 'warn', 'error'] as const)

export type LogLevel = typeof LOG_LEVELS[number]

/**
 * Definition of a stacktrace frame split into pieces
 */
class StacktraceFrame {
  func: string
  file: string
  line: number
  column: number

  constructor(func, file, line, column) {
    this.func = func
    this.file = file
    this.line = line
    this.column = column
  }

  /**
   * Stacktrace frame parser
   *
   * @param {String} line stacktrace line to parse
   */
  static parse(line) {
    const PARTS_RX = /at (.+) \(((.*:\/+)?(.+)):(\d+):(\d+)\)/

    const parts = PARTS_RX.exec(line)
    if (!parts) return
    const func = parts[1]
    const files = parts[4].split('?!')
    const file = files[files.length - 1].split('?')[0]
    const lineNumber = parts[5]
    const columnNumber = parts[6]

    if (func) {
      if (func === 'eval') {
        return new StacktraceFrame('(root)', file, lineNumber, columnNumber)
      } else if (func === '_callee$') {
        return new StacktraceFrame('(async)', file, lineNumber, columnNumber)
      } else {
        return new StacktraceFrame(func, file, lineNumber, columnNumber)
      }
    } else {
      return new StacktraceFrame('(script)', file, lineNumber, columnNumber)
    }
  }
}

/**
 * Definition of stacktrace that delivers certain frames in a usable format
 */
class Stacktrace {
  stack: string[]

  constructor() {
    this.stack = new Error().stack.split('\n')
  }

  at(index: number) {
    return Object.freeze(StacktraceFrame.parse(this.stack[index]))
  }
}

/**
 * Logger class
 */
class Logger {
  level: LogLevel

  constructor() {
    this.level = process.env.NODE_ENV === 'development' ? 'debug' : 'error'
  }

  private get caller() {
    const stacktrace = new Stacktrace()
    const item = stacktrace.at(5)
    if (!item) return ''
    return `${item.file}/${item.func}`
  }

  private get prefix() {
    if (process.env.NODE_ENV === 'development') {
      return `[${this.caller}]`
    } else {
      return ''
    }
  }

  trace(...args: any[]) {
    if (LOG_LEVELS.indexOf('trace') >= LOG_LEVELS.indexOf(this.level)) {
      console.trace(...[this.prefix, ...args])
    }
  }

  debug(...args: any[]) {
    if (LOG_LEVELS.indexOf('debug') >= LOG_LEVELS.indexOf(this.level)) {
      console.debug(...[this.prefix, ...args])
    }
  }

  info(...args: any[]) {
    if (LOG_LEVELS.indexOf('info') >= LOG_LEVELS.indexOf(this.level)) {
      console.log(...[this.prefix, ...args])
    }
  }

  warn(...args: any[]) {
    if (LOG_LEVELS.indexOf('warn') >= LOG_LEVELS.indexOf(this.level)) {
      console.warn(...[this.prefix, ...args])
    }
  }

  error(...args: any[]) {
    if (LOG_LEVELS.indexOf('error') >= LOG_LEVELS.indexOf(this.level)) {
      console.error(...[this.prefix, ...args])
    }
  }
}

const logger = new Logger()

bus.on('setLogLevel', (level: LogLevel) => {
  logger.level = level
})

export default logger

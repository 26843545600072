<template>
  <div class="plankton-grid">
    <div class="plankton-cell" span="2" v-if="!hideBasePrice">
      <MoneyInputField
        :value="basePrice"
        :inputValidators="priceValidators"
        :allowInteger="5"
        :allowDecimal="2"
        :title="$t('ordering/old-price-section-title')"
        placeholder="0.0"
        fullWidth
        data-test="registration-stage--base-price"
        @input="$emit('update:basePrice', $event)"
      />
    </div>
  </div>
</template>
<script>
import isEmpty from 'lodash/isEmpty'

export default {
  props: {
    basePrice: { type: Number, default: null },
    registrationSurcharge: { type: Number, default: null },
    registrationSurchargeDescription: { type: String, default: '' },
    hideBasePrice: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      priceValidators: [
        {
          isInvalid: isEmpty,
          errorMessage: this.$t('ordering/old-price-section-base-price-not-empty'),
          inline: false
        }
      ]
    }
  },
  computed: {
    netPrice() {
      return this.basePrice + this.registrationSurcharge
    }
  }
}
</script>

import { SENNTEAMS_API_URL } from '@/config'
import { SINGLE_AUDIENCE } from '@/services/auth-service'
import { runJsonGet, runJsonPost } from '@/models/backend-client'
import logger from '@/shell/console-logger'

interface Team {
  id: string
  name: string
}

export const fetchTeamsById = async (team_id: string): Promise<Array<object> | []> => {
  const url = `${SENNTEAMS_API_URL}/api/search/team`

  logger.debug(
    `Calling fetch teams in team management (sennteams) API with PATH: ${url} by team_id: ${team_id}`
  )
  try {
    const response = await runJsonPost(
      url,
      {
        team_id: [team_id]
      },
      { apiGatewayAuthorization: true },
      SINGLE_AUDIENCE
    )

    if (response && response.status === 200 && response.data.length) {
      return response.data.map((team: Team) => ({
        id: team.id,
        name: team.name
      }))
    }
  } catch (error) {
    logger.warn(
      `Team management service (sennteams) failed to fetch teams by team_id: ${team_id}: ${error}`
    )
  }
  return []
}

export const fetchTeamsByName = async (query: string): Promise<Array<object> | []> => {
  const url = `${SENNTEAMS_API_URL}/api/autocomplete/team`

  logger.debug(
    `Calling fetch team management (sennteams) API with PATH: ${url} with query: ${query}`
  )
  try {
    const response = await runJsonGet(
      url,
      {
        team_type: 'TEAM',
        query: query
      },
      { apiGatewayAuthorization: true },
      SINGLE_AUDIENCE
    )

    if (response && response.status === 200 && response.data.length) {
      return response.data.map((team: object) => ({
        id: team['id'],
        name: team['label']
      }))
    }
  } catch (error) {
    logger.warn(
      `Team management service (sennteams) failed to fetch teams with query: ${query}: ${error}`
    )
  }
  return []
}

import { AxiosResponse } from 'axios'
import {
  runJsonGet,
  runJsonPost,
  runJsonPut,
  runJsonDelete
} from '@/models/backend-client'

export interface IHttpClient {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get: (url, query?, options?, audience?, scope?) => Promise<void | AxiosResponse<any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post: (url, data?, options?, audience?, scope?) => Promise<void | AxiosResponse<any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put: (url, data?, options?, audience?, scope?) => Promise<void | AxiosResponse<any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete: (url, data?, options?, audience?, scope?) => Promise<void | AxiosResponse<any>>
}

export const httpClient: IHttpClient = {
  get: runJsonGet,
  post: runJsonPost,
  put: runJsonPut,
  delete: runJsonDelete
}

<template>
  <TypeAheadField
    :options="options"
    :internalSearch="internalSearch"
    v-bind="$attrs"
    :title="title"
    optionLabel="name"
    optionKey="id"
    placeholder="Select operator"
    openDirection="top"
    v-on="$listeners"
    @fetchFilterOptions="onFetchFilterOptions"
    @clearFilterOptions="onClearFilterOptions"
  />
</template>
<script>
import { fetchOperatorByName } from '@/services/user-service'

export default {
  props: {
    title: { type: String, default: 'Carrier Manager' }
  },
  data() {
    return { options: [], internalSearch: false }
  },
  methods: {
    async onFetchFilterOptions(query) {
      this.options = await fetchOperatorByName(query)
    },
    onClearFilterOptions() {
      this.options = []
    }
  }
}
</script>

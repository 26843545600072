



























































































































































































import moment from 'moment-timezone'
import { PropType, defineComponent } from '@vue/composition-api'
import get from 'lodash/get'
import { OrderStop, OrderDistanceTransitTime, TransferStepActualTime } from '@/services'
import { googleMapUrl } from '@/modules/common/googlemap-stops-helpers'
import LabeledText from '@/modules/common/components/LabeledText.vue'
import { getTimezoneAcronym } from '@/global-setup/filters'

export default defineComponent({
  components: { LabeledText },
  props: {
    stops: { type: Array as PropType<OrderStop[]>, required: true },
    steps: { type: Array as PropType<TransferStepActualTime[]>, required: true },
    stopsRouteDetails: {
      type: Array as PropType<OrderDistanceTransitTime[]>,
      required: true
    },
    multipleStopLetters: { type: Array as PropType<string[]>, required: true },
    isOrderDispatched: { type: Boolean, required: true },
    isTotalValid: { type: Boolean, required: true },
    hasStepsLoaded: { type: Boolean, required: true }
  },
  setup() {
    const getRoundedTime = transitTimeToNext => {
      if (transitTimeToNext) {
        const hours = Math.floor(transitTimeToNext / 3600)
        const minutes = Math.floor((transitTimeToNext % 3600) / 60)
        return `${hours}h ${minutes}min`
      } else {
        return null
      }
    }
    const getRoundedDistance = distanceToNext => {
      let roundedDistance
      if (distanceToNext) {
        roundedDistance = Math.floor(distanceToNext / 1000)
      } else if (distanceToNext === 0) {
        roundedDistance = 0
      } else {
        roundedDistance = null
      }
      return roundedDistance
    }

    const getTzAcronym = (isoTimestamp: string, timezone: string) => {
      let timezoneAcronym: string
      try {
        timezoneAcronym = getTimezoneAcronym(isoTimestamp, timezone)
      } catch (e) {
        timezoneAcronym = 'N/A'
      }
      return timezoneAcronym
    }

    const getTzChipText = (stop: OrderStop) => {
      const warehouseTimezone = stop.arrivalToWarehouse.timezone
      const tzAcronym = getTzAcronym(
        stop.arrivalToWarehouse.originalIsoTimeStamp,
        warehouseTimezone
      )
      return `${warehouseTimezone} Time (${tzAcronym})`
    }

    const formatDate = (date: string) => {
      // This format is specifically for this sidebar
      return moment(date, 'DD.MM.YYYY').format('DD.MM.YY')
    }

    return {
      get,
      googleMapUrl,
      getRoundedTime,
      getRoundedDistance,
      getTzChipText,
      getTzAcronym,
      formatDate
    }
  }
})

<template>
  <div>
    <AccountManagerAssignments />
    <CarrierManagerAssignments />
    <BidsPanelComponent data-test="bids-panel" />
    <OrderStageFooter
      :accountManager="accountManager"
      :juniorAccountManager="juniorAccountManager"
      :spotBidder="spotBidder"
      :partnerManager="partnerManager"
      :accountManagerDisabled="true"
      :partnerManagerDisabled="true"
      :orderRegularity="order.regularity"
      :orderState="order.state"
    >
      <template #left-actions>
        <TeamAssignmentTrigger class="field" openDirection="top" :orderId="order.id" />
      </template>
      <template #actions>
        <CarrierSuggestionsButton
          v-if="isSennderOrPoste"
          :order="order"
          :stops="stops"
          :disabled="disabled"
          category="tertiary"
          @offers-sent="fetchCompleteOrder(order.id)"
        />
        <NextLoadsButton
          data-test="next-loads-button"
          :orderId="order.id"
          v-if="isSennderOrPoste && !hideNextLoadsButton"
        />
        <AssignmentsButtonComponent class="action-button" />
        <SubmitNewBid data-test="submit-new-bid-button" />
      </template>
    </OrderStageFooter>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import orderingStoreMappers from '@/modules/ordering/store/mappers'
import OrderStageFooter from '@/modules/ordering/components/shared/OrderStageFooter'
import TeamAssignmentTrigger from '@/modules/ordering/components/shared/TeamAssignmentTrigger'
import { humanizeIsoDateTimeWithDots } from '@sennder/plankton'

import NextLoadsButton from './NextLoadsButton'
import { formatMoneyWithDashForNull } from '@/global-setup/filters'
import CarrierSuggestionsButton from './carrier-suggestions/CarrierSuggestionsButton'
import useStore from '@/compositions/useStore'
import useFeatureFlag from '@/compositions/useFeatureFlag'

import { isSennderTheme, isPosteTheme } from '@/controllers/environment-detection'
import AccountManagerAssignments from '@/microfrontends/pages/assignments-button/AccountManagerAssignments.vue'

import BidsPanelComponent from '@/microfrontends/pages/bidding/BidsPanelComponent.vue'
import CarrierManagerAssignments from '@/microfrontends/pages/assignments-button/CarrierManagerAssignments.vue'
import SubmitNewBid from '@/microfrontends/pages/bidding/SubmitNewBid.vue'

import { HIDE_NEXT_LOADS_BUTTON } from './constants.ts'
import AssignmentsButtonComponent from '@/microfrontends/pages/assignments-button/AssignmentsButtonComponent.vue'

export default {
  components: {
    OrderStageFooter,
    NextLoadsButton,
    CarrierSuggestionsButton,
    TeamAssignmentTrigger,
    AccountManagerAssignments,
    AssignmentsButtonComponent,
    CarrierManagerAssignments,
    SubmitNewBid,
    BidsPanelComponent
  },
  props: {
    order: { type: Object, required: true },
    stops: { type: Array, required: true }
  },
  setup() {
    const { isActive } = useFeatureFlag()
    const hideNextLoadsButton = isActive(HIDE_NEXT_LOADS_BUTTON)

    const store = useStore()
    const assignments = computed(() => store.state.assignments.assignments)

    return {
      assignments,
      hideNextLoadsButton
    }
  },

  computed: {
    ...mapGetters(['currentUserId', 'doesUserHavePermission']),
    ...orderingStoreMappers.mapState([
      'accountManager',
      'juniorAccountManager',
      'partnerManager',
      'spotBidder',
      'partnerManager',
      'orderStops',
      'spotCosting',
      'shipmentData'
    ]),
    ...orderingStoreMappers.mapGetters([
      'hasAcceptedAssignment',
      'pendingAssignment',
      'carrierKpi',
      'hasHadState'
    ]),
    disabled() {
      return this.order.state !== 'REGISTERED'
    },
    isSennderOrPoste() {
      return isSennderTheme || isPosteTheme
    }
  },
  methods: {
    humanizeIsoDateTimeWithDots,
    formatMoneyWithDashForNull,
    ...orderingStoreMappers.mapActions(['fetchCompleteOrder']),
    assignCarrier() {
      this.$refs.accountManagerAssignmentsRef?.assignCarrier()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 32px 24px;
}

.assignment-date-container {
  color: $color-neutral-main;
}

.allocation-offer-list {
  padding-top: 20px;
}

.assignment-info-tooltip {
  font-size: 15px;
  cursor: pointer;
  margin-left: 2px;

  &:hover {
    color: $color-secondary-main;
  }
}

.expected-percentage {
  margin-left: 4px;
}

.margin-ok,
.target-deviation-ok {
  color: $color-success-main;
}

.margin-error,
.target-deviation-error {
  color: $color-error-main;
}

.bid-info-alert,
.bid-delay-info-alert,
.assignments-locked-info-alert {
  width: fit-content;
  margin-bottom: 2em;
}

.percentage-label {
  color: $color-neutral-main;
}

.plankton-cell.pex-cell {
  display: flex;
  align-items: center;
}

.action-button {
  display: inline-block;
  margin-left: 10px;
  text-align: left;
}
</style>

import Person from '@/models/person'
import { OPERATIONS_BACKEND_URL } from '../config'
import Model from './model'
import _ from 'lodash'
import { humanizeDelay } from '@/controllers/duration-formatting'
import useTransfersList from '@/compositions/transfer/useTransfersList'
const { transfersData } = useTransfersList()

export default class Transfer extends Model {
  static get NAME() {
    return 'transfers'
  }
  static get URL() {
    return `${OPERATIONS_BACKEND_URL}/infrastructure/transfers/`
  }

  static getTransferDetail(transferId) {
    return transfersData.value?.find(t => t.id === transferId)
  }

  get steps() {
    if (this.steps_ids && this.steps_ids.length !== 0) {
      return _.sortBy(
        Array.from(this.steps_ids).map(id => this.$state.steps[id]),
        'scheduled_time'
      )
    } else {
      return []
    }
  }

  get hasGeoTracker() {
    if (!this.trackers) return false
    return this.trackers.length > 0
  }

  get next_not_completed_step() {
    return _.first(_.filter(this.steps, step => step.is_done === false))
  }

  get pickup_arrival_step() {
    const arrival_steps = _.filter(this.steps, step => step.type === 'ARRIVAL')
    if (arrival_steps.length > 1) {
      return _.first(arrival_steps)
    } else {
      return null
    }
  }

  get pickup_departure_step() {
    return _.first(_.filter(this.steps, step => step.type === 'DEPARTURE'))
  }

  get dropoff_arrival_step() {
    return _.last(_.filter(this.steps, step => step.type === 'ARRIVAL'))
  }

  get dropoff_departure_step() {
    const departure_steps = _.filter(this.steps, step => step.type === 'DEPARTURE')
    if (departure_steps.length > 1) {
      return _.last(departure_steps)
    } else {
      return null
    }
  }

  get pickupArrivalDelay() {
    return humanizeDelay(this.pickup_arrival_step.delay)
  }

  get pickupDepartureDelay() {
    return humanizeDelay(this.pickup_departure_step.delay)
  }

  get dropoffArrivalDelay() {
    return humanizeDelay(this.dropoff_arrival_step.delay)
  }

  get dropoffDepartureDelay() {
    return humanizeDelay(this.dropoff_departure_step.delay)
  }

  get nextStepDelay() {
    if (this.next_not_completed_step) {
      return this.next_not_completed_step.delay // may be null
    } else {
      return null
    }
  }

  get is_on_time() {
    return !this.is_delayed
  }

  get is_delayed() {
    const delay = this.nextStepDelay
    if (delay) {
      return delay._data.minutes >= 1
    } else {
      return false
    }
  }

  get is_delayed_more_than_half_an_hour() {
    const delay = this.nextStepDelay
    if (delay) {
      return delay._data.minutes >= 30
    } else {
      return false
    }
  }

  //If we used "driver" it would collide with the "driver" field
  // that comes from the Rest API after any update
  get driverDetails() {
    return Person.getPerson(this.driver_id)
  }
}

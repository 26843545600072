import usePersons from '@/compositions/usePersons'
import useTransfersList from '@/compositions/transfer/useTransfersList'
import Order from '@/models/order'
import { SEARCH_API_URL } from '@/config'
import pkg from '@/../package.json'
import { generateElasticInitials } from '@/modules/filters/store/getters'
import uuidv4 from 'uuid/v4'
import { getAuthHeader } from '@/services/auth-service'
import { monitorError } from '@/analytics/monitoring'

async function search(query, abortController) {
  const token = await getAuthHeader({
    audience: 'https://api.cloud.sennder.com/search-proxy',
    scope: 'search:any'
  })

  const response = await fetch(`${SEARCH_API_URL}/active_order/?enrichment=transfer`, {
    method: 'POST',
    headers: {
      authorization: token,
      'content-type': 'application/json',
      'x-client-name': pkg.name,
      'x-client-version': pkg.version
    },
    body: JSON.stringify(query),
    signal: abortController.signal
  })

  if (response.status === 200) {
    const hits = await response.json()
    return hits.hits.hits.map(hit => {
      hit._source.id = parseInt(hit._id)
      return hit._source
    })
  } else {
    throw new Error(response.statusText)
  }
}

let abortController = null

export const loadOrdersPage = async (store, { offset, limit, clearBeforeUpdate }) => {
  let aggregatedOrders = []
  const esPersons = []
  const esTransfers = []
  if (abortController) {
    abortController.abort()
  }
  store.commit('startLoadingPage', null, { root: true })

  if (clearBeforeUpdate) store.commit('clearFilteredOrders')

  if (store.getters['filters/noFiltersSelected']) {
    store.commit('clearFilteredOrders')
    store.commit('finishLoadingPage', null, { root: true })
    return []
  }

  const generateFilters =
    store.getters['filters/generateElasticSearchQueryWithPagination']

  // Query ElasticSearch
  try {
    abortController = new AbortController()
    const query = generateFilters(offset, limit, ['order.id_for_staff.keyword'])
    const results = await search(query, abortController)

    aggregatedOrders = results.map(el => {
      let juniorAmFullName = null
      let seniorAmFullName = null

      let juniorAmInitials = null
      let seniorAmInitials = null
      let pmInitials = null

      juniorAmFullName = el.designatedOperators?.account_manager?.user?.name
      seniorAmFullName = el.designatedOperators?.senior_account_manager?.user?.name

      seniorAmInitials = generateElasticInitials(seniorAmFullName)
      juniorAmInitials = generateElasticInitials(juniorAmFullName)

      const pmNameArr = el.transfer?.user?.name.split(' ')
      pmInitials = generateElasticInitials(el.transfer?.user?.name)

      esPersons.push({
        email: el.transfer?.user?.email,
        first_name: pmNameArr?.at(0),
        last_name: pmNameArr?.at(-1),
        id: el.transfer?.user?.mothership_id,
        initials: pmInitials,
        is_active: el.transfer?.user?.active,
        phone: null,
        uuid: el.transfer?.user?.id
      })

      esTransfers.push({
        code: el.transfer?.code,
        delay: el.transfer?.delay,
        id: el.transfer?.transfer_id,
        license_plate: el.transfer?.license_plate,
        operator_id: el.transfer?.operator_id,
        steps: el.transfer?.steps,
        trackers: [],
        trailer_license_plate: null
      })
      // note: constructing same order as from c14. To-Do remove unused properies below
      return {
        orderRowId: uuidv4(),
        seniorAmFullName,
        seniorAmInitials,
        juniorAmFullName,
        juniorAmInitials,
        id: el.id,
        customerName: el.order?.shipper?.company_name,
        carrier_company_id: el.carrierMatch?.carrier_id,
        customer_company_id: el.order?.shipper_id,
        orderCarrier: el.carrierMatch?.carrier,
        carrierName: el.carrierMatch?.carrier?.company_name,
        delivery_start: el.order?.unloading_stopover?.arrival_to_warehouse,
        delivery_end: el.order?.unloading_stopover?.departure_from_warehouse,
        id_for_staff: el.order?.id_for_staff,
        is_cancelled: el.workflowState?.is_cancelled,
        startAddress: el.order?.loading_stopover?.company_address?.address,
        endAddress: el.order?.unloading_stopover?.company_address?.address,
        letter: el.order.id_for_staff.at(-1),
        pickup_start: el.order?.loading_stopover?.arrival_to_warehouse,
        pickup_end: el.order?.loading_stopover?.departure_from_warehouse,
        reference_number: el.order.reference_number,
        state: el.workflowState?.state,
        transfer_id: el.transfer?.transfer_id,
        hasETA: Object.hasOwn(el, 'nextStopEta'),
        estimatedTimeOfArrival: el.nextStopEta?.stopovers?.sort(
          (a, b) => a.stopover_index - b.stopover_index
        )[0]?.estimated_time_of_arrival,
        lastestGpsTimestamp: el.nextStopEta?.last_ping_timestamp
      }
    })
    // overlap order's data with Order model, Person model, Transfer model
    aggregatedOrders = aggregatedOrders.map(order => new Order(order, store.rootState))

    const { handleESData } = usePersons()
    const { handleESTransfers } = useTransfersList()

    handleESData(esPersons)
    handleESTransfers(esTransfers)
    store.commit('addFilteredOrders', Object.values(aggregatedOrders))
    return aggregatedOrders
  } catch (e) {
    monitorError(new Error('Unable to search for orders in ES', { cause: e }))
  } finally {
    abortController = null
    store.commit('finishLoadingPage', null, { root: true })
  }

  return aggregatedOrders
}

<template>
  <li @click="$emit('click')"><slot /></li>
</template>

<script>
export default {
  props: {
    active: { type: Boolean, default: false }
  }
}
</script>

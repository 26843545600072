import { ShipperInvoiceGenerationRequirements, ShipperInvoiceAggregate } from './types'
import { api } from './api'
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase
} from '@/services/utils/casing'
import { amendResponseErrorMessage } from '.'

export const fetchShipperInvoiceGenerationRequirements = async (
  orderIds: number[]
): Promise<ShipperInvoiceGenerationRequirements> => {
  const errorCodeToTranslationKey: Record<string, string> = {
    ORDER_HAS_NO_SHIPPER_COMPANY:
      'finance/service-shipper-invoice-requirements-error-order-has-not-shipper',
    SHIPPER_INVOICES_ORDERS_DIFFERENT_COMPANY:
      'finance/service-shipper-invoice-requirements-error-orders-belong-different-shippers',
    ORDER_DOES_NOT_EXIST:
      'finance/service-shipper-invoice-requirements-error-order-not-exist'
  }
  const amendErrorMessageOnFailure = amendResponseErrorMessage(errorCodeToTranslationKey)

  const response = await amendErrorMessageOnFailure(
    api.get('/finance/queries/get-shipper-invoice-input-data', {
      params: transformKeysToSnakeCase({ orderIds: orderIds.join(',') })
    })
  )
  return transformKeysToCamelCase<ShipperInvoiceGenerationRequirements>(response.data)
}

export const sendShipperInvoices = async (
  orderIds: number[],
  language: string,
  invoiceDueDate: string
) => {
  const translationMessageMapper = {
    SHIPPER_INVOICES_ORDERS_DIFFERENT_COMPANY:
      'finance/service-generate-shipper-invoice-error-different-shippers',
    MISSING_VAT_ID: 'finance/service-generate-shipper-invoice-error-shipper-missing-vat',
    MISSING_BILLING_ADDRESS:
      'finance/service-generate-shipper-invoice-error-shipper-missing-billing-address',
    MISSING_BILLING_EMAIL_ADDRESS:
      'finance/service-generate-shipper-invoice-error-shipper-missing-billing-email',
    ORDER_BALANCE_ZERO:
      'finance/service-generate-shipper-invoice-error-order-balance-zero',
    NEGATIVE_TOTAL_AMOUNT:
      'finance/service-generate-shipper-invoice-error-negative-invoice',
    ORDER_WRONG_STATE: 'finance/service-generate-shipper-invoice-error-order-wrong-state',
    PAYMENT_DATE_IN_THE_PAST:
      'finance/service-generate-shipper-invoice-error-past-payment-date',
    DOCUMENT_COULD_NOT_BE_GENERATED:
      'finance/service-generate-shipper-invoice-error-document-not-generated'
  }
  const amendErrorMessageOnFailure = amendResponseErrorMessage(translationMessageMapper)

  await amendErrorMessageOnFailure(
    api.post(
      '/finance/commands/generate-shipper-invoice',
      transformKeysToSnakeCase({ orderIds, language, paymentDate: invoiceDueDate })
    )
  )
}

export const fetchShipperInvoices = async (orderId: number) => {
  const response = await api.get('/finance/queries/get-shipper-invoices', {
    params: transformKeysToSnakeCase({ orderId })
  })
  return transformKeysToCamelCase<ShipperInvoiceAggregate>(response.data)
}

import { createFilter } from './filter'
import { i18n } from '@/plugins/i18n'

const emptyValue = null

/**
 * @typedef {?boolean=} FilterValue
 * @typedef {import('./filter').UrlQueryFragment} UrlQueryFragment
 */

/**
 * @param {string} name - name for API/GraphQL key, URL and apply filter title
 * @param {FilterValue} initialValue
 * @param {string} updateName - name for getter/setter
 * @param {string} filterLabel - name for filter label
 * @returns {any} - vuex module instance
 */
export const createRadioFilter = (name, initialValue = null, updateName, filterLabel) => {
  const filter = createFilter({ name, initialValue, emptyValue, filterLabel })

  filter.getters = {
    ...filter.getters,
    /**
     * @param {*} state
     * @param {*} getters
     * @returns {object} - data for AppliedFilters component
     */
    getAppliedValue: (state, getters) => {
      let appliedValue = null
      if (!getters.isFilterEmpty) {
        const labels = {
          true: i18n.t('filters/three-way-radio-yes'),
          false: i18n.t('filters/three-way-radio-no'),
          null: i18n.t('filters/three-way-radio-na')
        }

        appliedValue = {
          label: `${i18n.t(filterLabel)}: ${labels[String(state.value)]}`,
          updateName,
          emptyValue
        }
      }

      return appliedValue
    }
  }
  filter.actions = {
    ...filter.actions,
    /**
     * @param {*} store
     * @param {UrlQueryFragment} urlQueryFragment
     */
    setValueFromUrl({ state, commit }, urlQueryFragment) {
      const urlValue = urlQueryFragment[state.urlParamName]
      if (urlValue === 'true' || urlValue === 'false') {
        const booleanValue = urlValue === 'true'
        commit('update', booleanValue)
      } else commit('update', null)
    }
  }

  return filter
}

import OrderingRoot from './components/OrderingRoot.vue'
import { store } from '@/models/store'
import { Route } from 'vue-router'

export default [
  {
    name: 'ordering',
    path: 'ordering/:orderGroupId(\\d+):letter([A-Z]+)',
    component: OrderingRoot,
    beforeEnter: async (to: Route, from: Route, next: (path?: string) => void) => {
      if (store.getters.currentUserIsInFinanceGroup && !store.getters.currentUserIsOps) {
        return next(`/finance/ordering/${to.params.orderGroupId}${to.params.letter}`)
      }
      return next()
    }
  },
  {
    // Definition of old order URL. Need it in case of emails that still point to this URL.
    name: 'old_order_url',
    path: '/order/:orderGroupId/*',
    redirect: (route: Route) => {
      const orderGroupId = route.params.orderGroupId
      const letter = route.query.letter || 'A'
      return { name: 'ordering', params: { orderGroupId, letter }, query: null }
    }
  }
]

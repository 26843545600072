




















/**
 * The idea of this component is to be a container for micro-frontend portals that may be used for Octopus decomposition.
 * Please don't put anything here besides portals themselves.
 * This component is integrated to an app Container.
 */
import OctopusPaymentsWidget from '@/microfrontends/widgets/payments-widget/PaymentsWidget.vue'
import OctopusPODReviewWidget from '@/microfrontends/widgets/pod-review-widget/PODReviewWidget.vue'

export default {
  components: {
    OctopusPaymentsWidget,
    OctopusPODReviewWidget
  }
}

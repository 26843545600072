<template>
  <div class="filter-wrapper">
    <TypeAheadField
      :options="filterOptions"
      :visible-selected-options-limit="5"
      :height-fixed="false"
      :internalSearch="internalSearch"
      v-model="saomUsersFilterHandler"
      optionLabel="email"
      optionKey="id"
      :title="$t('filters/senior-account-manager-filter-title')"
      multiple
      placeholder
      optionLabelWhenSelected="email"
      data-test="senior-account-manager-filter"
      fullWidth
      @fetchFilterOptions="onFetchFilterOptions"
      @clearFilterOptions="onClearFilterOptions"
    />
  </div>
</template>

<script>
import { inject, computed, ref, onMounted } from '@vue/composition-api'
import SennderSearch from '@sennder/sennsearch-core-es'
import { PrefixFilter } from '@sennder/sennsearch-core-es/lib/filters'
import { SEARCH_API_URL } from '@/config'
import { getAuthHeader } from '@/services/auth-service'

export default {
  inject: ['getFilterValue', 'setFilterValue'],
  setup(props, context) {
    let sennderSearch = null
    onMounted(async () => {
      sennderSearch = new SennderSearch({
        host: SEARCH_API_URL,
        index: 'active_user',
        fields: [],
        filters: [new PrefixFilter('Saom user', 'saom_users', 'name')],
        token: await getAuthHeader({
          audience: 'https://api.cloud.sennder.com/search-proxy',
          scope: 'search:any'
        })
      })
    })

    const getFilterValue = inject('getFilterValue')
    const setFilterValue = inject('setFilterValue')
    const filterOptions = ref([])
    const internalSearch = ref(false)

    const saomUsersFilterHandler = computed({
      get() {
        return getFilterValue('esSeniorAccountManagers')
      },
      set(value) {
        setFilterValue('esSeniorAccountManagers', value)
        context.emit('applyFilters')
      }
    })
    const onFetchFilterOptions = async searchText => {
      sennderSearch.setFilters([{ identifier: 'saom_users', value: searchText }])
      const response = await sennderSearch.search()
      filterOptions.value = response.results
    }
    const onClearFilterOptions = () => {
      filterOptions.value = []
    }
    return {
      onFetchFilterOptions,
      onClearFilterOptions,
      saomUsersFilterHandler,
      filterOptions,
      internalSearch
    }
  }
}
</script>
<style lang="scss" scoped>
.filter-wrapper {
  display: flex;
  position: relative;
}

.dropdown-filter-button {
  margin-left: 8px;
  margin-top: 16px;
}
</style>

import { SHIPMENT_API_URL } from '@/config'
import { showToast } from '@/plugins/toaster/swalToaster'
import { runJsonGet } from '@/models/backend-client'
import logger from '@/shell/console-logger'
import { SHIPMENT_EDIT_REQUEST_PARTIAL_UPDATES_STATUS } from '@/modules/shipment/constants'
import getInitialState from './state'
import { fetchShipmentRoutes } from '@/services/shipment-routes-service'
import { getParsedShipmentStopoversForOrderSidebar } from '@/modules/shipment/helpers/shipmentEditHelpers'

const mutationMap = {
  schedule_update: 'updateSchedule',
  reference_update: 'updateReference',
  vehicle_requirement_update: 'updateVehicleRequirement',
  tracking_requirement_update: 'updateTrackingRequirement'
}

const applyPartialUpdates = ({ commit }, partialUpdates) => {
  partialUpdates.forEach(update => {
    const mutationName = mutationMap[update.key]
    if (mutationName) {
      commit(mutationName, update.state)
    }
  })
}

const hasShipmentEditUpdate = response => {
  return (
    response?.data?.state === SHIPMENT_EDIT_REQUEST_PARTIAL_UPDATES_STATUS.DONE &&
    response.data?.partial_updates?.some(
      update => update.state === SHIPMENT_EDIT_REQUEST_PARTIAL_UPDATES_STATUS.DONE
    )
  )
}

const shouldShowTimoutAlert = response => {
  return (
    response?.data?.state === SHIPMENT_EDIT_REQUEST_PARTIAL_UPDATES_STATUS.DONE &&
    response.data?.partial_updates?.some(
      update => update.state === SHIPMENT_EDIT_REQUEST_PARTIAL_UPDATES_STATUS.PENDING
    )
  )
}

export const fetchShipmentEditRequestState = async ({ commit, rootGetters }) => {
  const shipmentId = rootGetters['ordering/getShipmentID']

  try {
    commit('setIsFetchingShipmentEditRequestState', true)
    const response = await runJsonGet(
      `${SHIPMENT_API_URL}/v2/shipments/${shipmentId}/shipment-edit-request-state`,
      null,
      {
        apiGatewayAuthorization: true
      },
      'https://api.cloud.sennder.com/shipments',
      'shipments:read'
    )

    if (response.status === 204) commit('setShipmentEditRequestMainState', 'updated')
  } catch (e) {
    if (e?.response?.status === 423) {
      commit('setShipmentEditRequestMainState', 'pending')
    } else {
      commit('setShipmentEditRequestMainState', 'error')
      showToast(
        'Something went wrong while fetching the shipment edit request status',
        'warning'
      )
      logger.warn(`An error ocurred while loading shipment's edit request state: ${e}`)
    }
  } finally {
    commit('setIsFetchingShipmentEditRequestState', false)
  }
}

export const resetShipmentPartialUpdate = ({ commit }) => {
  commit('setShipmentPartialUpdate', getInitialState().shipmentEditPartialUpdate)
}

export const fetchShipmentEditRequest = async ({ commit }, id) => {
  commit('updateHasShipmentEditAnyUpdates', false)

  try {
    const response = await runJsonGet(
      `${SHIPMENT_API_URL}/v2/shipment-edit-requests/${id}`,
      null,
      {
        apiGatewayAuthorization: true
      },
      'https://api.cloud.sennder.com/shipments',
      'shipments:read'
    )

    if (response?.data?.partial_updates) {
      applyPartialUpdates({ commit }, response.data.partial_updates)

      if (hasShipmentEditUpdate(response)) {
        commit('updateHasShipmentEditAnyUpdates', true)
      }

      if (shouldShowTimoutAlert(response)) {
        showToast(
          'Timeout error occurred on the server while updating the shipment.',
          'warning',
          5000
        )
      }
    }
  } catch (e) {
    logger.error(
      `An error ocurred while fetching shipment's partial edit request state: ${e}`
    )
  }
}

export const fetchShipmentV3Routes = async ({ commit, state }, shipmentId) => {
  try {
    commit('setIsShipmentStopsLoaded', false)
    const shipmentRoutesResponse = await fetchShipmentRoutes(shipmentId)
    if (shipmentRoutesResponse?.shipment_routes?.[0]) {
      commit('setShipmentRoute', shipmentRoutesResponse?.shipment_routes?.[0])
    }

    const computedStopsFromShipmentV3 = await getParsedShipmentStopoversForOrderSidebar(
      state.shipmentV3.shipment,
      state.shipmentRoute?.route_legs || []
    )

    commit('setShipmentStops', computedStopsFromShipmentV3)
  } catch (e) {
    logger.error(`An error ocurred while fetching shipment routes: ${e}`)
  } finally {
    commit('setIsShipmentStopsLoaded', true)
  }
}

export const fetchShipmentV3 = async ({ commit, dispatch }, shipmentId) => {
  try {
    const response = await runJsonGet(
      `${SHIPMENT_API_URL}/v3/shipments/${shipmentId}`,
      null,
      {
        apiGatewayAuthorization: true
      },
      'https://api.cloud.sennder.com/shipments',
      'shipments:read'
    )

    if (response?.data) {
      commit('setShipmentV3', response.data)
      dispatch('fetchShipmentV3Routes', shipmentId)
    }
  } catch (e) {
    logger.error(`An error ocurred while fetching shipment v3: ${e}`)
  }
}

export const BOARD_PAGINATION_LIMIT = 150

export const BOARD_FILTER_ORDER_STATES = [
  'NEW',
  'REGISTERED',
  'CARRIER_LOCKED',
  'DISPATCHED',
  'EXECUTED',
  'OPERATIONS_COMPLETED',
  'COMPLETED'
]

export const CREDIT_NOTE_LANGUAGES = [
  { name: 'English', id: 'en' },
  { name: 'French', id: 'fr' },
  { name: 'German', id: 'de' },
  { name: 'Italian', id: 'it' },
  { name: 'Polish', id: 'pl' },
  { name: 'Czech', id: 'cz' }
]

import { computed, Ref } from '@vue/composition-api'
import { TransferStopover } from '@/services'
import uniqBy from 'lodash/uniqBy'

const DEFAULT_MAP_CENTER = { lat: 50, lng: 12 }

export default (stops: Ref<TransferStopover[]>) => {
  const mapCenter = computed(() => {
    const result = DEFAULT_MAP_CENTER
    if (stops.value.length > 0) {
      const sum = (a: number, b: number) => a + b
      const count = stops.value.length
      result.lat = stops.value.map(stop => stop.latitude).reduce(sum) / count
      result.lng = stops.value.map(stop => stop.longitude).reduce(sum) / count
    }

    return result
  })

  const stopLocations = computed(() =>
    stops.value.map(stop => ({
      id: stop.id,
      lat: stop.latitude,
      lng: stop.longitude,
      type: stop.type,
      timezone: stop.timezone
    }))
  )

  const isRoundTrip = computed(
    () => uniqBy(stops.value, 'warehouseLocation').length !== stops.value.length
  )

  const getStopTypeByIndex = (index: number) => stops.value[index]?.type

  return {
    mapCenter,
    stopLocations,
    isRoundTrip,
    getStopTypeByIndex
  }
}

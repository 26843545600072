





























































































































import OrderStageFooter from '@/modules/ordering/components/shared/OrderStageFooter.vue'
import HereMap from './HereMap.vue'
import AsyncActionButton from '@/modules/ordering/components/shared/async-action-button/AsyncActionButton.vue'
import AsyncActionContext from '@/modules/ordering/components/shared/async-action-button/AsyncActionContext.vue'
import EstimatedTimeArrival from './EstimatedTimeArrival.vue'
import GpsStatusTile from './GpsStatusTile.vue'
import TransportStatus from './TransportStatus.vue'
import ExecutionRows from './ExecutionRows.vue'
import DriverAppLink from './DriverAppLink.vue'
import useTransfer from '@/modules/ordering/compositions/useTransfer'
import useExecutionStops from '@/compositions/transfer/useExecutionStops'
import useExecutionSteps from '@/compositions/transfer/useExecutionSteps'
import useExecutionLocations from '@/compositions/transfer/useExecutionLocations'
import useTransportData from '@/compositions/transfer/useTransportData'
import useStore from '@/compositions/useStore'
import useExecutionStageValidation from '@/compositions/transfer/useExecutionStageValidation'
import {
  computed,
  defineComponent,
  onUnmounted,
  PropType,
  provide,
  ref
} from '@vue/composition-api'
import useGpsIndicator from '@/compositions/transfer/useGpsIndicator'
import { hasHadState, isOrderExecutedOrCompleted } from '@/modules/common/order-states'
import useMultipleLetters from '@/compositions/transfer/useMultipleLetters'
import { Form } from '@/modules/ordering/types'
import { AlertModal } from '@sennder/plankton'
import Swal from 'sweetalert2'
import useCurrentUser from '@/compositions/useCurrentUser'
import { OrderDetail } from '@/services'
import { ExtendedPerson } from '@/compositions/useOrder'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import ExecutionStageWidget from '@/microfrontends/widgets/execution-stage/ExecutionStageWidget.vue'
import {
  MicrofrontendEvents,
  onLoadingComplete,
  onRefreshOrderLogs,
  onTourExecuted,
  subscribe
} from '@sennder/senn-node-microfrontend-event-bus'
import { isSennderTheme } from '@/controllers/environment-detection'
import { refreshOrderIncidents, refreshOrderLogs } from '@/modules/order-activity-sidebar'
import useTransitRampedUp from '@/compositions/transit/useTransitRampedUp'
import FullPageExpandingCardSkeleton from '@/modules/common/components/FullPageExpandingCardSkeleton.vue'

export default defineComponent({
  components: {
    FullPageExpandingCardSkeleton,
    ExecutionStageWidget,
    GpsStatusTile,
    EstimatedTimeArrival,
    TransportStatus,
    OrderStageFooter,
    AsyncActionButton,
    AsyncActionContext,
    ExecutionRows,
    HereMap,
    DriverAppLink
  },
  props: {
    order: { type: Object as PropType<OrderDetail>, required: true },
    accountManager: { type: Object as PropType<ExtendedPerson>, default: null },
    juniorAccountManager: { type: Object as PropType<ExtendedPerson>, default: null },
    partnerManager: { type: Object as PropType<ExtendedPerson>, default: null },
    spotBidder: { type: Object as PropType<ExtendedPerson>, default: null }
  },
  setup(props) {
    const { isActive } = useFeatureFlag()
    const isExecutionTimestampFlagActive = isActive('execution-mandatory-timestamps')

    const isTransitRampedUpDataLoaded = ref(false)
    const savingInProgress = ref(false)
    const closingInProgress = ref(false)
    const executionForm = ref<Form | null>(null)
    const saveActionButton = ref(null)
    const closeActionButton = ref(null)

    const eventsAbortController = new AbortController()

    subscribe<onRefreshOrderLogs>(
      MicrofrontendEvents.onRefreshOrderLogs,
      async () => {
        await refreshOrderLogs()
        await refreshOrderIncidents()
      },
      {
        signal: eventsAbortController.signal
      }
    )

    subscribe<onTourExecuted>(
      MicrofrontendEvents.onTourExecuted,
      async () => {
        await store.dispatch('ordering/finalizeOrderExecution')
      },
      {
        signal: eventsAbortController.signal
      }
    )

    subscribe<onLoadingComplete>(
      MicrofrontendEvents.onLoadingComplete,
      () => {
        isTransitRampedUpDataLoaded.value = true
      },
      { signal: eventsAbortController.signal }
    )

    const store = useStore()

    const { transitRampUp } = useTransitRampedUp(props.order.workflowManaged)

    const useTransferWithShipmentFlow = (transferId: number) => {
      return useTransfer(transferId, true)
    }

    const { steps, stops, locations, delayMinutes, saveSteps, isDataLoaded } =
      useTransferWithShipmentFlow(props.order.transferId)

    const { mapCenter, stopLocations, isRoundTrip } = useExecutionStops(stops)

    const { multipleStopLetters } = useMultipleLetters(steps)

    const {
      isLastStepDone,
      firstStepScheduledTime,
      lastStepScheduledTime,
      stopsCompletion,
      setStepsCompletion,
      setStepActualTime,
      findLastIndexMarkedAsCompleted
    } = useExecutionSteps(steps)

    const { trackingLocations, latestLocationTimeStamp, hasGpsReceived } =
      useExecutionLocations(locations)

    const { currentUserIsDirectDispatcher, currentUserIsOps } = useCurrentUser()

    const canCopyLink = computed<boolean>(() => currentUserIsOps.value)

    const isOpsOrDirectDispatcher = computed<boolean>(
      () => currentUserIsOps.value || currentUserIsDirectDispatcher.value
    )

    const isDatetimeFieldDisabled = computed<boolean>(() => {
      if (isSennderTheme && isOrderExecutedOrCompleted(props.order.state)) {
        return !isOpsOrDirectDispatcher.value || isOrderCancelled.value
      } else {
        return isOrderExecuted.value || !isOpsOrDirectDispatcher.value
      }
    })

    const asyncActionInProgress = computed(
      () => savingInProgress.value || closingInProgress.value
    )

    provide('isOpsOrDirectDispatcher', isOpsOrDirectDispatcher)
    provide('isDatetimeFieldDisabled', isDatetimeFieldDisabled)
    provide('asyncActionInProgress', asyncActionInProgress)

    const isSaveButtonDisabled = computed(() => {
      return asyncActionInProgress.value || isDatetimeFieldDisabled.value
    })

    const canDisplaySaveAndCloseButtons = computed(() => {
      if (transitRampUp.value.isLoading || transitRampUp.value.isError) {
        return false
      }
      if (transitRampUp.value.loadMF && !isTransitRampedUpDataLoaded.value) {
        return false
      }
      return (
        (isOpsOrDirectDispatcher.value && isDataLoaded.value) ||
        (transitRampUp.value.loadMF && isTransitRampedUpDataLoaded.value)
      )
    })

    const isOrderCancelled = computed<boolean>(() => props.order.isCancelled)

    const { transportStatus, etaToNextStop, delayStatus } = useTransportData(
      steps,
      isOrderCancelled,
      delayMinutes,
      locations,
      stops
    )

    const { executionStageIndicatorState, executionStageIndicatorStatus } =
      useGpsIndicator(steps, latestLocationTimeStamp)

    const isOrderExecuted = computed<boolean>(() =>
      hasHadState(props.order.state, 'EXECUTED')
    )

    const validateSteps = (): boolean => {
      const errors = useExecutionStageValidation(steps)
      const hasErrors = errors.length > 0
      if (hasErrors) {
        const alertModal = AlertModal(Swal)
        alertModal.showErrorMessage('Error', errors.join('<br />'))
      }
      return !hasErrors
    }

    const hasFormErrors = (): boolean => {
      return executionForm.value?.submit().hasErrors
    }

    const validateStepsAndCompulsoryTimestamps = (): boolean => {
      if (isExecutionTimestampFlagActive.value) {
        const isFormValid = !hasFormErrors()
        return isFormValid && validateSteps()
      }
      return validateSteps()
    }

    const saveOrder = async (): Promise<void> => {
      const noErrors = validateSteps()
      if (noErrors) {
        savingInProgress.value = true
        try {
          await saveSteps()
        } finally {
          savingInProgress.value = false
          clearListUpdatedRows()
        }
      }
      await refreshOrderLogs()
    }

    const onMarkStepAsDone = async (markedStepAsDone: number): Promise<void> => {
      const noErrors = validateSteps()
      if (noErrors) {
        savingInProgress.value = true
        closingInProgress.value = true
        const lastIndexMarkedAsDone: number | null = findLastIndexMarkedAsCompleted()
        try {
          setStepsCompletion(markedStepAsDone, true)
          await saveSteps()
        } catch (error) {
          setStepsCompletion(markedStepAsDone, false, lastIndexMarkedAsDone)
        } finally {
          savingInProgress.value = false
          closingInProgress.value = false
        }
      }
    }

    const canCloseOrderExecution = (): boolean => {
      return !(isExecutionTimestampFlagActive.value && hasFormErrors())
    }

    const handleCloseOrderExecution = async (): Promise<void> => {
      if (transitRampUp.value.loadMF) {
        return Promise.resolve()
      }

      if (canCloseOrderExecution()) {
        closingInProgress.value = true
        closeActionButton.value?.setInProgress(true)

        try {
          await saveSteps()
          await store.dispatch('ordering/closeOrderExecution')
        } finally {
          closingInProgress.value = false
          closeActionButton.value?.setInProgress(false)
          clearListUpdatedRows()
        }
      }
    }

    const listUpdatedRows = ref<
      { isDirty: boolean; index: number; type: 'arrival' | 'departure' }[]
    >([])

    const isSecondarySaveButton = computed(() => {
      return listUpdatedRows.value.length > 0
    })

    const detectChanges = (data: {
      isDirty: boolean
      index: number
      type: 'arrival' | 'departure'
    }) => {
      const rowIndex = listUpdatedRows.value.findIndex(
        elem =>
          elem['index'] === data.index && elem['isDirty'] && elem['type'] === data.type
      )

      if (rowIndex >= 0) {
        !data.isDirty && listUpdatedRows.value.splice(rowIndex, 1)
      } else {
        data.isDirty && listUpdatedRows.value.push(data)
      }
    }

    const clearListUpdatedRows = () => {
      listUpdatedRows.value.splice(0, listUpdatedRows.value.length)
    }

    onUnmounted(() => {
      eventsAbortController.abort()
    })

    return {
      canDisplaySaveAndCloseButtons,
      transitRampUp,
      // form ref
      executionForm,
      // template refs
      // boolean refs
      savingInProgress,
      closingInProgress,
      asyncActionInProgress,
      isOrderExecuted,
      isOpsOrDirectDispatcher,
      canCopyLink,
      // transfer data
      steps,
      stops,
      isDataLoaded,
      // steps data
      isLastStepDone,
      firstStepScheduledTime,
      lastStepScheduledTime,
      stopsCompletion,
      // transport data
      transportStatus,
      etaToNextStop,
      delayStatus,
      // stops data
      mapCenter,
      stopLocations,
      multipleStopLetters,
      isRoundTrip,
      // locations data
      trackingLocations,
      latestLocationTimeStamp,
      hasGpsReceived,
      // gps indicator data
      executionStageIndicatorState,
      executionStageIndicatorStatus,
      // methods
      onMarkStepAsDone,
      setStepActualTime,
      saveOrder,
      handleCloseOrderExecution,
      validateSteps,
      validateStepsAndCompulsoryTimestamps,
      saveActionButton,
      closeActionButton,
      isDatetimeFieldDisabled,
      isSaveButtonDisabled,
      detectChanges,
      isSecondarySaveButton,
      listUpdatedRows
    }
  }
})

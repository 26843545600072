import { computed, ref, Ref } from '@vue/composition-api'
import moment from 'moment'
import useExecutionSteps from '@/compositions/transfer/useExecutionSteps'
import { TransferStep } from '@/services'

export default (
  steps: Ref<TransferStep[]>,
  latestGpsTimestamp?: Ref<string>,
  trackers: Ref<unknown[]> = ref([]) // we just check the array emptiness so we don't care much of its structure
) => {
  const currentTimestamp = moment()

  const { isLastStepDone, firstStepScheduledTime, lastStepScheduledTime } =
    useExecutionSteps(steps)

  const transportDone = computed(
    () =>
      isLastStepDone.value ||
      (lastStepScheduledTime.value &&
        currentTimestamp.diff(lastStepScheduledTime.value, 'hours') >= 10)
  )

  const firstStopTimeDiff = firstStepScheduledTime.value
    ? moment(firstStepScheduledTime.value).diff(moment().utc(), 'minutes')
    : ''

  const transportNotStarted = computed(() => firstStopTimeDiff > 120)

  const lastTimeWithOffset = moment(lastStepScheduledTime.value).add(4, 'hours')

  const isLastStepInFuture = lastTimeWithOffset.diff(moment().utc(), 'minutes') > 0

  const isWithin2hBeforeStart = firstStopTimeDiff > 0 && firstStopTimeDiff < 120

  const isMissingTimestamps = steps?.value
    ? steps.value.some(item => !item.actualTime)
    : true

  const executionStageIndicatorState = computed<{
    icon: string
    message: string
    elapsedTime?: string
  }>(() => {
    const transportStates = [
      {
        condition: transportDone.value,
        data: {
          icon: 'gps_off',
          message: 'common/gps-status-indicator-state-transport-done'
        }
      },
      {
        condition: transportNotStarted.value,
        data: {
          icon: 'gps_off',
          message: 'common/gps-status-indicator-state-transport-not-started'
        }
      },
      {
        condition: Boolean(latestGpsTimestamp?.value),
        data: {
          icon: 'gps_fixed',
          message: 'common/gps-status-indicator-state-latest-gps',
          elapsedTime: moment(latestGpsTimestamp?.value).fromNow()
        }
      }
    ]
    return (
      transportStates.find(state => state.condition)?.data || {
        icon: 'gps_off',
        message: 'common/gps-status-indicator-state-no-gps'
      }
    )
  })

  const executionBoardIndicatorState = computed<{
    icon: string
    message: string
    elapsedTime?: string
  }>(() => {
    const transportStates = [
      {
        condition: !steps.value,
        data: {
          icon: 'gps_off',
          message: 'common/gps-status-indicator-state-no-gps'
        }
      },
      {
        condition: transportDone.value && isMissingTimestamps,
        data: {
          icon: 'schedule',
          message: 'common/gps-status-indicator-state-timestamp-missing'
        }
      },
      {
        condition: transportDone.value && !isMissingTimestamps,
        data: {
          icon: 'watch_later',
          message: 'common/gps-status-indicator-state-tour-completed'
        }
      },
      {
        condition:
          !transportDone.value &&
          isWithin2hBeforeStart &&
          trackers.value.length &&
          !latestGpsTimestamp.value,
        data: {
          icon: 'gps_fixed',
          message: 'common/gps-status-indicator-state-gps-pending'
        }
      },
      {
        condition: !transportDone.value && isLastStepInFuture && !trackers.value.length,
        data: {
          icon: 'gps_off',
          message: 'common/gps-status-indicator-state-no-gps-enabled'
        }
      },
      {
        condition:
          !transportDone.value &&
          isLastStepInFuture &&
          trackers.value.length &&
          !latestGpsTimestamp.value,
        data: { icon: 'gps_off', message: 'common/gps-status-indicator-state-no-gps' }
      },
      {
        condition:
          !transportDone.value &&
          isLastStepInFuture &&
          trackers.value.length &&
          latestGpsTimestamp.value,
        data: {
          icon: 'gps_fixed',
          message: 'common/gps-status-indicator-state-latest-gps',
          elapsedTime: `${moment(latestGpsTimestamp?.value).fromNow()}`
        }
      },
      {
        condition: !transportDone.value && !isLastStepInFuture && !isMissingTimestamps,
        data: {
          icon: 'watch_later',
          message: 'common/gps-status-indicator-state-tour-completed'
        }
      },
      {
        condition: !transportDone.value && !isLastStepInFuture && isMissingTimestamps,
        data: {
          icon: 'schedule',
          message: 'common/gps-status-indicator-state-timestamp-missing'
        }
      }
    ]

    return (
      transportStates.find(state => state.condition)?.data || {
        icon: 'gps_off',
        message: 'common/gps-status-indicator-state-no-gps'
      }
    )
  })

  const timeConditions = [
    { min: 0, max: 15, status: 'active' },
    { min: 15, max: 45, status: 'warning' },
    { min: 45, max: 600, status: 'delayed' }
  ]

  const timeDiff = computed(() => moment().diff(latestGpsTimestamp?.value, 'minutes'))

  const timeRange = computed(
    () =>
      timeConditions.find(
        condition => timeDiff.value >= condition.min && timeDiff.value < condition.max
      ) || { status: 'no-signal' }
  )

  const executionBoardIndicatorStatus = computed(() => {
    if (!steps.value) {
      return 'no-signal'
    }

    if (transportDone.value) {
      return isMissingTimestamps ? 'warning' : 'active'
    }

    if (isLastStepInFuture) {
      return trackers.value.length ? timeRange.value.status : 'no-signal'
    } else {
      return isMissingTimestamps ? 'warning' : 'active'
    }
  })

  const executionStageIndicatorStatus = computed(() => {
    if (transportDone.value || transportNotStarted.value || !latestGpsTimestamp?.value)
      return 'no-signal'

    return timeRange.value.status
  })

  return {
    executionStageIndicatorState,
    executionStageIndicatorStatus,
    executionBoardIndicatorState,
    executionBoardIndicatorStatus
  }
}



















import GpsStatusIndicator from '@/modules/common/components/GpsStatusIndicator.vue'

export default {
  components: {
    GpsStatusIndicator
  },
  props: {
    state: { type: Object, default: null },
    status: { type: String, default: null }
  }
}

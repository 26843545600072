import { transformKeysToCamelCase, transformKeysToSnakeCase } from './utils/casing'
import { OPERATIONS_BACKEND_URL } from '@/config'
import { runJsonGet, runJsonPost } from '@/models/backend-client'
import {
  Provider,
  TrackerByLicensePlate,
  TrackingConfig
} from '@/modules/ordering/components/dispatching/types'
import { AxiosResponse } from 'axios'

interface DriverAppLink {
  dynamicLink: string
}

export const fetchTrackingConfig = async (transferId: number) => {
  const gatehousePushQueryUrl = `${OPERATIONS_BACKEND_URL}/gatehouse-push/queries/get-push-configured/`
  const amazonPushQueryUrl = `${OPERATIONS_BACKEND_URL}/amazon-push/queries/get-preregistration-configured/`
  const queryParam = transformKeysToSnakeCase({ transferId })

  return Promise.all([
    runJsonGet(gatehousePushQueryUrl, queryParam),
    runJsonGet(amazonPushQueryUrl, queryParam)
  ]).then(([gatehousePushResponse, amazonPushResponse]) => ({
    gatehousePushOn: gatehousePushResponse
      ? (gatehousePushResponse.data.gatehouseTrackingConfigured as boolean)
      : false,
    amazonPushOn: amazonPushResponse
      ? (amazonPushResponse.data.amazonPreregistrationConfigured as boolean)
      : false
  }))
}

export const updateTrackingConfig = async (
  transferId: number,
  trackingConfig: Omit<TrackingConfig, 'gatehousePullOn'>
) => {
  const { gatehousePushOn, amazonPushOn } = trackingConfig
  await Promise.all([
    updateGatehouseTrackingConfig(transferId, gatehousePushOn),
    updateAmazonTrackingConfig(transferId, amazonPushOn)
  ])
}

const updateGatehouseTrackingConfig = (transferId: number, gatehousePushOn: boolean) => {
  const command = gatehousePushOn ? 'create-push' : 'remove-push'
  return runJsonPost(
    `${OPERATIONS_BACKEND_URL}/gatehouse-push/commands/${command}`,
    transformKeysToSnakeCase({ transferId })
  )
}

const updateAmazonTrackingConfig = (transferId: number, amazonPushOn: boolean) => {
  const command = amazonPushOn ? 'create-preregistration' : 'remove-preregistration'
  return runJsonPost(
    `${OPERATIONS_BACKEND_URL}/amazon-push/commands/${command}`,
    transformKeysToSnakeCase({ transferId })
  )
}

export const generateDriverAppTrackingLink = async (
  idForStaff: string
): Promise<DriverAppLink> => {
  const response = (await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/drivers/generate-dynamic-link`,
    transformKeysToSnakeCase({ idForStaff })
  )) as AxiosResponse<unknown>
  return transformKeysToCamelCase(response.data)
}

export const getTrackerByLicensePlate = async (
  vehicleLicense: string,
  provider: Provider,
  carrierCompanyId?: number | null
): Promise<TrackerByLicensePlate> => {
  const url = `${OPERATIONS_BACKEND_URL}/integrations/${provider}/queries/get-tracker-by-license-plate/${vehicleLicense}`
  const queryParam = carrierCompanyId
    ? transformKeysToSnakeCase({ carrierCompanyId })
    : null

  try {
    const response = (await runJsonGet(url, queryParam)) as AxiosResponse<unknown>
    return transformKeysToCamelCase(response.data)
  } catch (error) {
    if (error.response.status === 404) return false
  }
}

import { Tenant } from '@sennder/senn-node-microfrontend-interfaces'

export function isProductionUrl() {
  return process.env.VUE_APP_IS_PRODUCTION === 'true'
}

export function isUAT() {
  return process.env.VUE_APP_IS_UAT === 'true'
}

export function isProductionOrStagingUrl() {
  return isServerBuildType() && !isQa()
}

export function isServerBuildType() {
  return process.env.NODE_ENV === 'production'
}

export function isQa() {
  return process.env.VUE_APP_QA_ENV === 'true'
}

export function getAppTheme() {
  return process.env.VUE_APP_THEME
}

export const getTenant = (): Tenant => {
  return process.env.VUE_APP_TENANT as Tenant
}

export const THEME = {
  SENNDER: 'sennder',
  POSTE: 'poste'
}

export const isSennderTheme: boolean = getAppTheme() === THEME.SENNDER
export const isPosteTheme: boolean = getAppTheme() === THEME.POSTE

<template>
  <div class="filters">
    <label>{{ $t('ordering/dispatching-carrier-suggestions-filters') }}</label>
    <Checkbox
      :label="$t('ordering/dispatching-carrier-suggestions-filters-my-carrier')"
      :checked="myCarriers"
      @input="toggleMyCarriers($event)"
      data-test="myCarriersCheckbox"
    />
    <Checkbox
      :label="$t('ordering/dispatching-carrier-suggestions-filters-hide-carrier')"
      :checked="hideCarriersWithCM"
      @input="toggleHideCarriersWithCM($event)"
      data-test="hideCarriersWithCMCheckbox"
    />
    <div class="right">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
import { trackEvent } from '@/analytics/amplitude'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    myCarriers: { type: Boolean, default: false },
    hideCarriersWithCM: { type: Boolean, default: false }
  },
  methods: {
    toggleMyCarriers(enabled) {
      this.$emit('update:myCarriers', enabled)
      trackEvent('octopus/carrier-suggestion/click/my-carriers', { enabled })
    },
    toggleHideCarriersWithCM(enabled) {
      this.$emit('update:hideCarriersWithCM', enabled)
      trackEvent('octopus/carrier-suggestion/click/hide-carriers-with-cm', { enabled })
    }
  }
})
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
  flex-direction: row;
  user-select: none;
  font-size: 12px;
  align-items: center;
  margin: 16px 0;

  label {
    font-weight: 700;
    padding: 0;
    margin: 0;
  }

  label,
  .checkbox-content {
    margin-right: 20px;
    font-size: 10px;
    line-height: 10px;
  }
}

.right {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>

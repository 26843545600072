import { ref } from '@vue/composition-api'

export default () => {
  const isShowArrivalTooltip = ref<boolean>(false)
  const isShowDepartureTooltip = ref<boolean>(false)

  const hideArrivalTooltip = () => {
    isShowArrivalTooltip.value = false
  }

  const showArrivalTooltip = () => {
    isShowArrivalTooltip.value = true
  }

  const hideDepartureTooltip = () => {
    isShowDepartureTooltip.value = false
  }

  const showDepartureTooltip = () => {
    isShowDepartureTooltip.value = true
  }

  return {
    isShowArrivalTooltip,
    isShowDepartureTooltip,
    hideArrivalTooltip,
    showArrivalTooltip,
    hideDepartureTooltip,
    showDepartureTooltip
  }
}

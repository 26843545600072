<template>
  <Dialog
    :show="show"
    :title="
      $t('ordering/dispatching-carrier-suggestions-carrier-suggestions-modal-title')
    "
    class="dialog carrier-suggestions"
    inPlace
    @close="close"
  >
    <Tabs :tabs="tabs" v-model="selectedTab">
      <template #similar>
        <SimilarLanesTab
          :order="order"
          :stops="stops"
          :canSendOffers="canSendOffers"
          @selection-changed="carrierContactIdList = $event"
        />
      </template>

      <template #recommended>
        <RecommendedTab
          :order="order"
          :stops="stops"
          :canSendOffers="canSendOffers"
          @selection-changed="carrierContactIdList = $event"
        />
      </template>

      <template #preferences>
        <PreferencesTab
          :order="order"
          :stops="stops"
          :canSendOffers="canSendOffers"
          @selection-changed="carrierContactIdList = $event"
        />
      </template>
    </Tabs>
    <template #footer>
      <CarrierSearchDashboardLink
        class="carrier-suggestions__dashboard-link"
        :origin="origin"
        :destination="destination"
        :vehicleType="order.vehicleType"
      />
      <PdfLanguageSearchSelect
        v-if="canSendOffers"
        v-model="templateLanguage"
        noLabel
        data-test="template-language"
        class="carrier-suggestions__language"
      />
      <Button
        v-if="canSendOffers"
        :disabled="!emailCanBeSent"
        category="primary"
        :text="
          $t('ordering/dispatching-carrier-suggestions-carrier-suggestions-modal-button')
        "
        data-test="send-offers-button"
        @click="sendOffersViaEmail"
      />
    </template>
    <ConfirmationDialog
      class="carrier-suggestions__confirmation"
      ref="sendingConfirmation"
      :title="
        $t('ordering/dispatching-carrier-suggestions-carrier-suggestions-modal-button')
      "
      :confirmButtonText="$t('global/yes')"
      :cancelButtonText="$t('global/cancel')"
      inPlace
      data-test="confirmation-dialog"
    >
      <span class="confirmation-text subsection">
        {{
          $t(
            'ordering/dispatching-carrier-suggestions-carrier-suggestions-modal-confirmation-content'
          )
        }}
      </span>
    </ConfirmationDialog>
  </Dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { trackEvent } from '@/analytics/amplitude'
import PdfLanguageSearchSelect from './PdfLanguageSearchSelect'
import ConfirmationDialog from '@/modules/ordering/components/shared/ConfirmationDialog'
import { postOfferToCarriersViaEmail } from '@/services/suggestions-service'
import CarrierSearchDashboardLink from './CarrierSearchDashboardLink'
import { isPosteTheme } from '@/controllers/environment-detection'
import Tabs from './Tabs.vue'
import RecommendedTab from './recommended/RecommendedTab.vue'
import SimilarLanesTab from './SimilarLanesTab.vue'
import PreferencesTab from './PreferencesTab.vue'

export default {
  components: {
    PdfLanguageSearchSelect,
    ConfirmationDialog,
    CarrierSearchDashboardLink,
    Tabs,
    RecommendedTab,
    SimilarLanesTab,
    PreferencesTab
  },
  props: {
    order: { type: Object, required: true },
    stops: { type: Array, required: true }
  },
  data() {
    return {
      show: false,
      templateLanguage: null,
      selectedTab: '',
      carrierContactIdList: [],
      filters: {
        myCarriers: false,
        hideTaggedCarriers: false
      }
    }
  },
  computed: {
    ...mapGetters(['currentUserIsPartnerManager', 'currentUserIsDirectDispatcher']),
    tabs() {
      return [
        {
          id: 'similar',
          title: this.$t(
            'ordering/dispatching-carrier-suggestions-carrier-suggestions-modal-tabs-similar'
          )
        },
        {
          id: 'recommended',
          title: this.$t(
            'ordering/dispatching-carrier-suggestions-carrier-suggestions-modal-tabs-recommended'
          ),
          hidden: !!isPosteTheme
        },
        {
          id: 'preferences',
          title: this.$t(
            'ordering/dispatching-carrier-suggestions-carrier-suggestions-modal-tabs-preferences'
          )
        }
      ]
    },
    emailCanBeSent() {
      return this.carrierContactIdList.length !== 0 && this.templateLanguage
    },
    canSendOffers() {
      return this.currentUserIsPartnerManager || this.currentUserIsDirectDispatcher
    },
    origin() {
      return this.stops[0].warehouseAddress
    },
    destination() {
      return this.stops[this.stops.length - 1].warehouseAddress
    }
  },
  watch: {
    selectedTab() {
      this.carrierContactIdList = []
    },
    templateLanguage(value = '') {
      if (this.show) {
        trackEvent('octopus/carrier-suggestion/select/language-dropdown', {
          orderId: this.order.id,
          templateLanguage: value ? value.toUpperCase() : null
        })
      }
    }
  },
  methods: {
    updateCarrierSelection(selection) {
      this.carrierContactIdList = selection
    },
    async open() {
      this.selectedTab = 'similar'
      this.show = true
    },
    close() {
      this.show = false
    },
    async sendOffersViaEmail() {
      const response = await this.$refs.sendingConfirmation.askConfirmation()
      if (response) {
        await postOfferToCarriersViaEmail({
          orderId: this.order.id,
          contactIdsList: this.carrierContactIdList.map(Number),
          templateLanguage: this.templateLanguage.toUpperCase()
        })
        trackEvent('octopus/carrier-suggestion/click/send-offers', {
          orderId: this.order.id,
          contactIdsList: this.carrierContactIdList.map(Number),
          templateLanguage: this.templateLanguage.toUpperCase(),
          selectedTab: this.selectedTab
        })
        this.alertModal.showSuccessMessage(
          this.$t(
            'ordering/dispatching-carrier-suggestions-carrier-suggestions-modal-email-sent'
          )
        )
        this.carrierContactIdList = []
        this.$emit('offers-sent')
        this.close()
        this.templateLanguage = null // should be after close, to prevent tracking clear after close modal
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  &::v-deep .modal-dialog {
    text-align: left;
    max-width: 1320px;
    align-self: center;
  }
}

.carrier-suggestions {
  &__footer {
    display: flex;
  }

  &__dashboard-link {
    margin-right: auto;
    margin-top: 20px;
  }

  &__language {
    flex-basis: 240px;
    margin-top: 20px;

    &::v-deep .s-order-field {
      margin: 0;
    }
  }

  &__confirmation {
    &::v-deep .modal-dialog {
      max-width: 560px;
    }
  }
}

.subtitle {
  line-height: 24px;
  font-size: 15px;
  color: $color-neutral-main;
}

.offers-sent-checkbox-wrapper {
  display: flex;
  justify-content: space-around;
}

.chart {
  margin-bottom: 24px;
}

.filters {
  display: flex;
  flex-direction: row;
}
</style>

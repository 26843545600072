import orderBy from 'lodash/orderBy'

export const getSorted = (list, sortingInstructions) => {
  const lodashInstructions = getLodashSortingInstructions(sortingInstructions)
  return orderBy(list, lodashInstructions.fieldNames, lodashInstructions.sortDirections)
}

export const getLodashSortingInstructions = sortOrder => {
  // The parameter sortOrder should be an array like: ['-status', 'id'].
  // The result will be two arrays like: ['status', 'id'], ['desc', 'asc'].

  return sortOrder.reduce(
    (result, sortingInstruction) => {
      if (sortingInstruction.startsWith('-')) {
        result.fieldNames.push(sortingInstruction.substr(1))
        result.sortDirections.push('desc')
      } else {
        result.fieldNames.push(sortingInstruction)
        result.sortDirections.push('asc')
      }
      return result
    },
    { fieldNames: [], sortDirections: [] }
  )
}

<template>
  <DropdownField
    v-model="filterHandler"
    :options="[$t('filters/open-offers-yes'), $t('filters/open-offers-no')]"
    :close-on-select="true"
    :clear-on-select="true"
    :title="$t('filters/open-offers-title')"
    data-test="open-offers-filter"
    fullWidth
  />
</template>

<script>
export default {
  inject: ['getFilterValue', 'setFilterValue'],
  computed: {
    filterHandler: {
      get() {
        if (this.getFilterValue('openOffers') !== null) {
          return this.getFilterValue('openOffers')
            ? this.$t('filters/open-offers-yes')
            : this.$t('filters/open-offers-no')
        }

        return null
      },
      set(payload) {
        this.setFilterValue(
          'openOffers',
          payload ? payload === this.$t('filters/open-offers-yes') : null
        )
        this.$emit('applyFilters')
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="plankton-grid">
      <div class="plankton-cell" span="2">
        <slot name="type"></slot>
      </div>
      <div class="plankton-cell" span="4">
        <slot name="customer"></slot>
      </div>
      <div class="plankton-cell" span="6">
        <slot name="address"></slot>
      </div>
      <div class="plankton-cell" span="2">
        <slot name="referenceNumber"></slot>
      </div>
      <div class="plankton-cell" span="9">
        <slot name="date"></slot>
      </div>
      <div class="plankton-cell" span="1">
        <slot name="menu"></slot>
      </div>
    </div>
    <div v-if="showContactAndNotes" class="plankton-grid">
      <div class="plankton-cell" span="4">
        <slot name="contact"></slot>
      </div>
      <div class="plankton-cell" span="12">
        <slot name="notes"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showContactAndNotes: { type: Boolean, default: false }
  }
}
</script>

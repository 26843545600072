export const loginInGoogleWebsite = async redirectUrl => {
  const auth = await getAuthObject()
  auth.signIn({ ux_mode: 'redirect', redirect_uri: redirectUrl })
}

export const getAuthToken = async () => {
  const auth = await getAuthObject()
  const googleUser = auth.currentUser.get()
  if (!googleUser.isSignedIn()) return null
  return googleUser.getAuthResponse().id_token
}

export const logout = async () => {
  const auth = await getAuthObject()
  await auth.signOut()
}

const getAuthObject = async () => {
  const gapi = await loadGoogleApi()
  const auth2 = await gapiLoadPromise(gapi, 'auth2')
  const initParam = { client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID }

  return auth2.init(initParam)
}

const gapiLoadPromise = (gapi, moduleName) =>
  new Promise((resolve, reject) => {
    gapi.load(moduleName, () => {
      if (gapi[moduleName]) resolve(gapi[moduleName])
      else reject(new Error('gapi.load error'))
    })
  })

const loadGoogleApi = () =>
  new Promise(resolve => {
    if (window.gapi) return resolve(window.gapi)
    document
      .getElementById('google-api-script')
      .addEventListener('load', () => resolve(window.gapi))
  })

<template>
  <FormField :id="title" :title="title">
    <RadioSet
      v-model="radioFilterHandler"
      v-bind="$attrs"
      :options="optionsForRadioFilters"
      :data-test="`${filterName}-filter`"
      inline
    />
  </FormField>
</template>
<script>
export default {
  inject: ['getFilterValue', 'setFilterValue'],
  props: {
    filterName: { type: String, required: true },
    title: { type: String, required: true }
  },
  computed: {
    optionsForRadioFilters() {
      return [
        { label: this.$t('filters/constants-radio-option-na'), value: null },
        { label: this.$t('filters/constants-radio-option-yes'), value: true },
        { label: this.$t('filters/constants-radio-option-no'), value: false }
      ]
    },
    radioFilterHandler: {
      get() {
        return this.getFilterValue(this.filterName)
      },
      set(value) {
        this.setFilterValue(this.filterName, value)
        this.$emit('applyFilters')
      }
    }
  }
}
</script>

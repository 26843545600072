import {
  computed,
  ComponentInternalInstance,
  getCurrentInstance,
  WritableComputedRef
} from '@vue/composition-api'
import VueI18n from 'vue-i18n'
import { i18n as newI18n } from '../plugins/i18n'

/**
 * Why not just using i18n from plugins/i18n?
 *
 * 1. i18n cannot be destructured (because of lost context)
 * 2. Code syntax here is closer to vue-i18n-next one
 */

interface Composer {
  t: typeof VueI18n.prototype.t
  tc: typeof VueI18n.prototype.tc
  locale: WritableComputedRef<string>
}

export default (): Composer => {
  const instance = getCurrentInstance() as ComponentInternalInstance & {
    $i18n: VueI18n
  }
  const vm = instance?.proxy
  const i18n = vm?.$i18n || newI18n

  if (!i18n) throw new Error('vue-i18n not initialized')

  const locale = computed({
    get() {
      return i18n.locale
    },
    set(v: string) {
      i18n.locale = v
    }
  })

  return {
    locale,
    t: i18n.t.bind(i18n),
    tc: i18n.tc.bind(i18n)
  }
}

import { Ref } from '@vue/composition-api'
import { ExtraChargeType } from '@/modules/ordering/legacy/services/types'

import useInvoices from '@/modules/ordering/compositions/legacy/useInvoices'
import { refreshOrderLogs } from '@/modules/order-activity-sidebar'

import { fetchOrder } from '@/modules/ordering/store/actions'

export default (orderId: Ref<number>) => {
  const { loadCarrierInvoices, loadShipperInvoices } = useInvoices(orderId)

  const triggerChargeCorrectionSideEffects = (refreshType: ExtraChargeType | null) => {
    // refresh order logs
    refreshOrderLogs()

    // NOTE:
    // reloads order detail since it contains the totalNetCost value.
    // We might get this value from a difference source in order to avoid reloading
    // the whole order object whenever a change is done in the price/cost.
    fetchOrder()

    // Refresh remaining total for invoices
    if (refreshType === 'CARRIER') {
      loadCarrierInvoices()
    } else if (refreshType === 'CUSTOMER') {
      loadShipperInvoices()
    }
  }

  return {
    triggerChargeCorrectionSideEffects
  }
}

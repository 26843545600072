import Model from './model'
import { OPERATIONS_BACKEND_URL } from '../config'

export default class Customer extends Model {
  get NAME() {
    return 'customers'
  }
  get URL() {
    return `${OPERATIONS_BACKEND_URL}/ordering/customers/`
  }
}

<template>
  <div>
    <div class="file-uploader subtitle">{{ $t('upload/main-upload-title') }}</div>
    <DragAndDropUploader
      v-if="!uploadFileAdded"
      :mimeTypes="['.csv', 'text/csv']"
      :onFileAdded="handleAddedFile"
      :onFileError="handleFileTypeError"
      :url="uploadUrl"
    />
    <div v-else class="file-preview">
      <div class="file-to-upload">
        <div class="file-to-upload-filename">
          <i class="material-icons">filter_none</i>
          <span>{{ uploadFilename }}</span>
        </div>
        <Button
          materialIcon="delete"
          category="tertiary"
          data-test="delete-upload-file"
          :inline="true"
          @click="removeFile"
        />
      </div>
      <div>
        <Button
          :disabled="uploading"
          :loading="uploading"
          data-test="submit-upload-csv-button"
          :text="buttonText"
          @click="submit"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref, onBeforeUnmount } from '@vue/composition-api'

import { OPERATIONS_BACKEND_URL } from '@/config'
import { uploadCsv } from '@/models/backend-client'
import { useStateReset } from '@/compositions'
import { orderCsvUploadStatus } from '@/services/csv-upload'

import DragAndDropUploader from './DragAndDropUploader'
import { UPLOAD_RECEIVED, ERRORS, UPLOAD_PROCESSED } from './statuses'

export default {
  components: { DragAndDropUploader },
  props: {
    urlString: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    }
  },
  setup(props, { emit, root }) {
    const uploadUrl = computed(() => `${OPERATIONS_BACKEND_URL}${props.urlString}`)
    const uploadFileAdded = ref(false)
    const uploading = ref(false)
    const uploadFilename = ref(null)
    const uploadErrorMessage = ref(null)
    const dropzoneRef = ref(null)
    let statusPolling = null
    const resetState = useStateReset([
      uploadErrorMessage,
      uploadFileAdded,
      uploading,
      uploadFilename,
      dropzoneRef
    ])

    const handleAddedFile = ({ status, upload: { filename }, dropzone }) => {
      clearUpload()

      uploadFilename.value = filename
      dropzoneRef.value = dropzone

      if (status === 'added') {
        uploadFileAdded.value = true
      }
    }
    const removeFile = () => {
      dropzoneRef.value.removeAllFiles()
      clearUpload()
    }
    const clearUpload = () => {
      resetState()
      emit('clearUpload')
    }
    const submit = async () => {
      const formData = dropzoneRef.value.getAcceptedFiles()[0]
      uploading.value = true
      try {
        const response = await uploadCsv(uploadUrl.value, formData)
        handleSubmitResponse(response)
      } catch (error) {
        emit('setGeneralError', {
          errorMessage: root.$t('upload/main-upload-error-msg')
        })
        clearInterval(statusPolling)
      }
    }
    const handleSubmitResponse = ({ data: { upload } }) => {
      const uploadId = upload.upload_identifier
      statusPolling = setInterval(() => {
        queryUploadStatus(uploadId)
      }, 5000)
    }
    const queryUploadStatus = async uploadId => {
      try {
        const response = await orderCsvUploadStatus(uploadId)
        handleUploadStatusResponse(response)
      } catch (error) {
        emit('setGeneralError', {
          errorMessage: root.$t('upload/main-upload-error-message')
        })
        uploading.value = false
      }
    }
    const handleFileTypeError = () => {
      setUploadError({
        errorMessage: root.$t('upload/main-upload-error-message-invalid-file')
      })
    }
    const setUploadError = errorObj => {
      uploadErrorMessage.value = errorObj.errorMessage
      uploadFileAdded.value = false
      emit('uploadErrorEvent', errorObj)
    }
    const formatExceptions = exceptions => {
      let exceptionErrorMessage = root.$t('upload/main-upload-exceptions') + ' '
      exceptions.forEach(
        ({ exception_message }, i) =>
          (exceptionErrorMessage += `${i + 1}: ${exception_message}.`)
      )

      return exceptionErrorMessage
    }
    const handleUploadStatusResponse = ({
      uploadStatus: {
        status,
        validationErrorCount,
        exceptions,
        exceptionCount,
        uploadIdentifier,
        processingResult,
        validationErrors
      }
    }) => {
      if (status !== UPLOAD_RECEIVED) clearInterval(statusPolling)

      if (status === UPLOAD_PROCESSED) {
        uploading.value = false
        emit('uploadProcessed', processingResult)
      }
      if (status === ERRORS) {
        let errorObj
        if (exceptionCount) {
          const exceptionErrorMessage = formatExceptions(exceptions)
          const errorMessage = root.$t('upload/main-upload-error-message-job', {
            uploadIdentifier,
            exceptionErrorMessage
          })
          errorObj = {
            errorMessage,
            validationErrors
          }
          setUploadError(errorObj)
        } else {
          const errorMessage = root.$t('upload/main-upload-error-exceptions-count', {
            validationErrorCount,
            uploadFilename: uploadFilename.value
          })
          errorObj = {
            errorMessage,
            validationErrors
          }
          setUploadError(errorObj)
        }
        uploading.value = false
      }
    }
    onBeforeUnmount(() => clearInterval(statusPolling))
    return {
      uploadFilename,
      uploadErrorMessage,
      uploadFileAdded,
      uploadUrl,
      uploading,
      dropzoneRef,
      handleAddedFile,
      handleFileTypeError,
      submit,
      removeFile
    }
  }
}
</script>
<style scoped lang="scss">
.file-uploader {
  color: $color-neutral-darkest;
  font-weight: bold;
  margin-bottom: 10px;
}

.file-to-upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  height: 51px;
  width: 613px;
  border: 1px solid $color-layout-divider;
  border-radius: 4px;
  background-color: $color-layout-white;
  margin-bottom: 15px;
}

.file-to-upload-filename {
  display: flex;
  align-items: center;
}

.file-to-upload-filename > span {
  padding-left: 8px;
  padding-right: 8px;
}
</style>

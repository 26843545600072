import {
  OrderLog,
  OrderReferenceNumberChangedLog,
  StopoverReferenceNumberChangedLog,
  StopoverTimeChangedLog,
  OrderLogType,
  ActualTimesCorrectedPostExecutionLog,
  AssetChangedLog
} from '../types'
import {
  dateRangeText,
  logOldDateFormatted
} from '@/modules/common/filters/date-time-filters'
import useI18n from '@/compositions/useI18n'
import { OrderStop } from '@/services'

export const getLogTooltipText = (log: OrderLog, orderStop?: OrderStop): string => {
  const { t } = useI18n()

  const oldValueFormatted = (
    log:
      | OrderReferenceNumberChangedLog
      | StopoverReferenceNumberChangedLog
      | ActualTimesCorrectedPostExecutionLog
  ): string => log.logData.old || '-'

  const oldDateRangeFormatted = (
    log: StopoverTimeChangedLog,
    warehouseTimezone: string
  ): string =>
    dateRangeText([log.logData.old.start, log.logData.old.end], true, warehouseTimezone)

  const getOrderReferenceNumberChangedTooltipText = (
    log: OrderReferenceNumberChangedLog
  ): string => `${t('order-activity-sidebar/log-old-value')}: ${oldValueFormatted(log)}`

  const getStopoverReferenceNumberChangedTooltipText = (
    log: StopoverReferenceNumberChangedLog
  ): string => `${t('order-activity-sidebar/log-old-value')}: ${oldValueFormatted(log)}`

  const getStopoverTimeChangedTooltipText = (log: StopoverTimeChangedLog): string => {
    return `${t('order-activity-sidebar/log-old-value')}: ${oldDateRangeFormatted(
      log,
      orderStop?.warehouseAddress?.timezone
    )}`
  }

  const getAssetChangeTooltipText = (log: AssetChangedLog): string => {
    const { oldValue } = log.logData
    return `${t('order-activity-sidebar/log-old-value')}: ${oldValue || '-'}`
  }

  const getActualTimesCorrectedPostExecutionTooltipText = (
    log: ActualTimesCorrectedPostExecutionLog
  ): string | null => {
    const isOldTimestampMissing = log.logData.old.length === 0
    if (isOldTimestampMissing) return null
    const oldDateFormatted = logOldDateFormatted(
      log,
      orderStop?.warehouseAddress?.timezone
    )
    return `${t('order-activity-sidebar/log-old-value')}: ${oldDateFormatted}`
  }

  const LOG_TOOLTIP_TEXTS: { [key: string]: (log: OrderLog) => string | null } = {
    [OrderLogType.OrderReferenceNumberChanged]: getOrderReferenceNumberChangedTooltipText,
    [OrderLogType.StopoverReferenceNumberChanged]:
      getStopoverReferenceNumberChangedTooltipText,
    [OrderLogType.OrderAllowedVehicleTypesChanged]: () =>
      `${t('order-activity-sidebar/log-old-value')}: -`,
    [OrderLogType.StopoverTimeChanged]: getStopoverTimeChangedTooltipText,
    [OrderLogType.ActualTimesCorrectedPostExecution]:
      getActualTimesCorrectedPostExecutionTooltipText,
    [OrderLogType.ALLOCATED_ASSETS_CHANGED]: getAssetChangeTooltipText
  }

  return (LOG_TOOLTIP_TEXTS[log.logType] || (() => null))(log)
}

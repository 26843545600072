




























import isEmpty from 'lodash/isEmpty'
import { fetchCustomerTemplates } from '@/services/customer-service'
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { CustomerTemplate } from '@/services'

interface CustomerTemplateWithTitle extends CustomerTemplate {
  formatedTitle: string
}

export default defineComponent({
  model: { prop: 'value', event: 'select' },
  props: {
    value: { type: String, default: null },
    customerId: { type: Number, default: null },
    required: { type: Boolean, default: false },
    inlineError: { type: Boolean, default: false },
    title: { type: String, default: 'Order template' },
    placeholder: { type: String, default: 'Select template' },
    disabled: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    const templates = ref<CustomerTemplate[]>([])

    const validators = computed(() => {
      if (!props.required) return []
      return [
        {
          isInvalid: isEmpty,
          errorMessage: props.inlineError
            ? 'This field cannot be empty'
            : 'Order template cannot be empty',
          inline: props.inlineError
        }
      ]
    })

    const selectedOption = computed<CustomerTemplateWithTitle>(() => {
      const templateSelected = templates.value.find(
        template => template.lineCode === props.value
      )
      if (templateSelected) {
        return {
          ...templateSelected,
          formatedTitle: formatTemplateTitle(templateSelected.lineCode)
        }
      }
      return null
    })

    const templateOptions = computed<CustomerTemplateWithTitle[]>(() => {
      return templates.value.map(template => ({
        ...template,
        formatedTitle: formatTemplateTitle(template.lineCode)
      }))
    })

    const formatTemplateTitle = (title: string): string => {
      const index = title.indexOf('-')
      if (index > -1) return title.substr(index + 1)
      return title
    }

    const fetchTemplates = async (customerId: number): Promise<void> => {
      templates.value = await fetchCustomerTemplates(customerId)
      emit('optionsFetched', templates.value)
    }

    const emitValueUpdate = (newValue: string): void => {
      if (props.value !== newValue) {
        emit('select', newValue)
      }
    }

    watch(
      () => props.customerId,
      (newCustomerId, oldCustomerId) => {
        if (newCustomerId && newCustomerId !== oldCustomerId) {
          emitValueUpdate(null)
          fetchTemplates(newCustomerId)
        } else if (newCustomerId === null) {
          templates.value = []
          emitValueUpdate(null)
        }
      }
    )

    onMounted(async (): Promise<void> => {
      if (props.customerId !== null) {
        await fetchTemplates(props.customerId)
      }
    })

    return {
      templates,
      validators,
      selectedOption,
      templateOptions
    }
  }
})

<template>
  <div
    :class="{
      nomargin: noMargin,
      'accord__section--breakdown': type === 'breakdown'
    }"
    class="accord__section"
  >
    <div
      :class="{
        collapsed: collapsed,
        'accord__header--alwaysopen': disableCollapse,
        'accord__header--transparent': transparentHeader,
        'accord__header--breakdown': type === 'breakdown'
      }"
      class="accord__header"
      @click="toggleCollapse"
    >
      {{ title }}
    </div>
    <div
      :class="{ show: !collapsed, nopadding: noPadding }"
      class="accord__content collapse"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    startCollapsed: { type: Boolean, default: false },
    disableCollapse: { type: Boolean, default: false },
    noMargin: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
    transparentHeader: { type: Boolean, default: false },
    type: {
      type: String,
      default: 'default',
      validator: type => ['default', 'breakdown'].includes(type)
    }
  },
  data: () => ({
    collapsed: false
  }),
  created() {
    this.collapsed = this.startCollapsed
  },
  methods: {
    toggleCollapse() {
      if (!this.disableCollapse) {
        this.collapsed = !this.collapsed
      } else {
        this.collapsed = false
      }
      this.$emit('toggled', this.collapsed)
    }
  }
}
</script>
<style lang="scss" scoped>
.accord__section {
  margin: 0 15px 17px;
  border-radius: 5px;
  background: $color-layout-white;

  &.nomargin {
    margin: 0 0 17px;
  }

  &--breakdown {
    background: transparent;
    border: 1px solid $color-neutral-lightest;
  }
}

.accord__header {
  position: relative;
  background: $color-neutral-main;
  color: $color-layout-white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 7px 10px;
  cursor: pointer;

  &::after {
    content: 'keyboard_arrow_down';
    position: absolute;
    top: 2px;
    right: 5px;
    font-family: 'Material Icons';
    font-size: 20px;
    color: $color-neutral-darkest;
    transition: transform 250ms ease;
  }

  &.collapsed {
    border-radius: 5px;

    &::after {
      transform: rotate(180deg);
    }
  }

  &--alwaysopen {
    cursor: default;

    &::after {
      display: none;
    }
  }

  &--transparent {
    background: transparent;
    color: $color-neutral-darkest;
    border-bottom: 1px solid $color-neutral-lightest;
    font-weight: bold;
  }

  &--breakdown {
    font-weight: bold;
    font-size: 11px;
    color: $color-neutral-darkest;
    background-color: $color-neutral-lightest;
  }
}

.accord__content:not(.nopadding) {
  padding: 10px;
}
</style>

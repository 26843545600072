<template>
  <dl class="billing-info paragraph">
    <div class="billing-info__row">
      <dt class="billing-info__title">
        {{ $t('finance/billing-info-billing-address-label') }}:
      </dt>
      <dd data-test="billing-address">
        {{ billingAddress }}
      </dd>
    </div>
    <div class="billing-info__row">
      <dt class="billing-info__title">
        {{ $t('finance/billing-info-billing-email-label') }}:
      </dt>
      <dd data-test="billing-email">
        {{ billingEmail }}
      </dd>
    </div>
    <div class="billing-info__row">
      <dt class="billing-info__title">IBAN/BIC:</dt>
      <dd data-test="billing-iban-bic">
        {{ ibanBic }}
      </dd>
    </div>
  </dl>
</template>
<script>
import useCarrierBilling from '@/modules/ordering/compositions/legacy/useCarrierBilling'
import { toRef } from '@vue/composition-api'

export default {
  props: {
    carrierId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const carrierId = toRef(props, 'carrierId')
    const { billingAddress, billingEmail, ibanBic } = useCarrierBilling({
      carrierId
    })

    return {
      billingAddress,
      billingEmail,
      ibanBic
    }
  }
}
</script>
<style lang="scss" scoped>
.billing-info {
  display: table;
  margin-bottom: 8px;

  &__row {
    display: table-row;
  }

  &__title {
    display: table-cell;
    padding-right: 8px;
  }
}
</style>

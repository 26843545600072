import { EXCHANGE_RATES_API_URL } from '@/config'
import { runJsonGet } from '@/models/backend-client'
import { Currency, ICurrencyExchangeRate } from '@/modules/common'
import { AxiosResponse } from 'axios'

export const fetchExchangeRate = async (
  currency: Currency
): Promise<ICurrencyExchangeRate> => {
  try {
    const response = (await runJsonGet(
      `${EXCHANGE_RATES_API_URL}/${currency}`
    )) as AxiosResponse<ICurrencyExchangeRate>

    return response.data
  } catch (error) {
    throw new Error(`Unable to get exchange rate for the following currency: ${currency}`)
  }
}

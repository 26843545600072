import getAlphabetEquivalent from '@/compositions/transfer/useStopLetter'
import { VEHICLE_TYPES } from '@sennder/octopus-constants'
import {
  OrderLog,
  OrderReferenceNumberChangedLog,
  StopoverReferenceNumberChangedLog,
  StopoverTimeChangedLog,
  OrderAllowedVehicleTypesChangedLog,
  OrderLogType,
  CorrectionAddedLog,
  MissingDocumentLog,
  SurchargeAddedLog,
  ShipperInvoiceCreatedLog,
  ActualTimesCorrectedPostExecutionLog,
  StopActionSuccessfulLog,
  AssetChangedLog
} from '../types'
import {
  dateRangeText,
  logNewDateFormatted
} from '@/modules/common/filters/date-time-filters'
import { OrderStop, VehicleType } from '@/services'
import useI18n from '@/compositions/useI18n'

export const getLogText = (log: OrderLog, orderStop?: OrderStop): string => {
  const { t, tc } = useI18n()

  const getLogLetter = (
    log:
      | StopoverReferenceNumberChangedLog
      | StopoverTimeChangedLog
      | ActualTimesCorrectedPostExecutionLog
  ): string => getAlphabetEquivalent(log.logData.index)

  const getStopoverType = (
    log: StopoverReferenceNumberChangedLog | StopoverTimeChangedLog
  ): string => {
    const STOPOVER_TYPE_LABELS = {
      LOADING: t('order-activity-sidebar/log-loading'),
      UNLOADING: t('order-activity-sidebar/log-unloading')
    }

    return STOPOVER_TYPE_LABELS[log.logData.type]
  }

  const getStepType = (log: ActualTimesCorrectedPostExecutionLog): string => {
    const STEP_TYPE = {
      ARRIVAL: t('order-activity-sidebar/log-arrival'),
      DEPARTURE: t('order-activity-sidebar/log-departure')
    }
    return STEP_TYPE[log.logData.actionType]
  }

  const newValueFormatted = (
    log: OrderReferenceNumberChangedLog | StopoverReferenceNumberChangedLog
  ): string => log.logData.new || '-'

  const vehicleTypesFormatted = (log: OrderAllowedVehicleTypesChangedLog): string =>
    log.logData.diff.map((value: VehicleType) => VEHICLE_TYPES[value]).join(', ')

  const newDateRangeFormatted = (
    log: StopoverTimeChangedLog,
    warehouseTimezone: string
  ): string =>
    dateRangeText([log.logData.new.start, log.logData.new.end], true, warehouseTimezone)

  const getOrderReferenceNumberChangedLogText = (
    log: OrderReferenceNumberChangedLog
  ): string =>
    t(
      log.logData.old
        ? 'order-activity-sidebar/log-order-reference-number-changed'
        : 'order-activity-sidebar/log-order-reference-number-added',
      {
        value: newValueFormatted(log)
      }
    )

  const getStopoverReferenceNumberChangedLogText = (
    log: StopoverReferenceNumberChangedLog
  ): string =>
    t(
      log.logData.old
        ? 'order-activity-sidebar/log-order-stop-reference-number-changed'
        : 'order-activity-sidebar/log-order-stop-reference-number-added',
      {
        stopType: getStopoverType(log),
        letter: getLogLetter(log),
        value: newValueFormatted(log)
      }
    )

  const getOrderAllowedVehicleTypesChangedLogText = (
    log: OrderAllowedVehicleTypesChangedLog
  ): string =>
    tc(
      log.logData.type === 'ADDED'
        ? 'order-activity-sidebar/log-vehicle-type-added'
        : 'order-activity-sidebar/log-vehicle-type-removed',
      log.logData.diff.length,
      {
        vehicleTypes: vehicleTypesFormatted(log)
      }
    )

  const getStopoverTimeChangedLogText = (log: StopoverTimeChangedLog): string => {
    return t('order-activity-sidebar/log-order-stop-time-changed', {
      stopType: getStopoverType(log),
      letter: getLogLetter(log),
      value: newDateRangeFormatted(log, orderStop?.warehouseAddress?.timezone)
    })
  }

  const getStopoverAddressChangedLogText = (log: StopoverTimeChangedLog): string =>
    t('order-activity-sidebar/log-order-stop-time-changed', {
      stopType: getStopoverType(log),
      letter: getLogLetter(log)
    })

  const getStopoverAddedLogText = (log: StopoverTimeChangedLog): string =>
    t('order-activity-sidebar/log-order-stop-added', {
      stopType: getStopoverType(log),
      letter: getLogLetter(log)
    })

  const getStopoverRemovedLogText = (log: StopoverTimeChangedLog): string =>
    t('order-activity-sidebar/log-order-stop-removed', {
      stopType: getStopoverType(log),
      letter: getLogLetter(log)
    })

  const getMissingDocumentLogText = (log: MissingDocumentLog): string =>
    t('order-activity-sidebar/log-missing-documents-requested', {
      documentType: log.logData.documentType
    })

  const surchargeSideTranslationKeys = {
    CARRIER: 'order-activity-sidebar/log-surcharge-side-carrier',
    SHIPPER: 'order-activity-sidebar/log-surcharge-side-shipper'
  }
  const correctionTypeTranslationKeys = {
    BASE_PRICE: 'order-activity-sidebar/log-correction-type-base-price',
    BASE_COST: 'order-activity-sidebar/log-correction-type-base-cost',
    SURCHARGE: 'order-activity-sidebar/log-correction-type-surcharge'
  }

  const getSurchargeLogText = (log: SurchargeAddedLog): string =>
    t('order-activity-sidebar/log-surcharge-added', {
      surchargeAmount: log.logData.surchargeAmount,
      surchargeSide: t(surchargeSideTranslationKeys[log.logData.surchargeSide])
    })

  const getCorrectionLogText = (log: CorrectionAddedLog): string =>
    t('order-activity-sidebar/log-correction-added', {
      correctionAmount: log.logData.correctionAmount,
      correctionType: t(correctionTypeTranslationKeys[log.logData.correctionType], {
        correctionSide: t(surchargeSideTranslationKeys[log.logData.correctionSide])
      })
    })

  const getShipperInvoiceCreatedLogText = (log: ShipperInvoiceCreatedLog): string =>
    t('order-activity-sidebar/log-shipper-invoice-created', log.logData)

  const getActualTimesCorrectedPostExecutionLogLogText = (
    log: ActualTimesCorrectedPostExecutionLog
  ) => {
    const isOldTimestampMissing = log.logData.old.length === 0
    const logTextKey = isOldTimestampMissing
      ? 'order-activity-sidebar/log-actual-times-corrected-post-execution-added'
      : 'order-activity-sidebar/log-actual-times-corrected-post-execution'

    return t(logTextKey, {
      stepType: getStepType(log),
      letter: getLogLetter(log),
      value: logNewDateFormatted(log, orderStop?.warehouseAddress?.timezone)
    })
  }

  const getActionType = (log: StopActionSuccessfulLog): string => {
    const ACTION_TYPE = {
      LOADING: t('order-activity-sidebar/log-loaded'),
      UNLOADING: t('order-activity-sidebar/log-unloaded')
    }
    return ACTION_TYPE[log.logData.actionType]
  }

  const getActualTimesCorrectedPostExecutionLogText = (log: StopActionSuccessfulLog) => {
    return t('order-activity-sidebar/log-stop-action-successful', {
      actionType: getActionType(log),
      letter: getAlphabetEquivalent(log.logData.stopIndex)
    })
  }

  const getAllocatedAssetsChangedLogText = (log: AssetChangedLog) => {
    if (log.logData.changeType === 'DELETED') {
      return t('order-activity-sidebar/asset-deallocated', {
        assetType: log.logData.assetType,
        oldValue: log.logData.oldValue
      })
    }

    if (log.logData.changeType === 'CREATED') {
      return t('order-activity-sidebar/asset-allocated', {
        assetType: log.logData.assetType,
        newValue: log.logData.newValue
      })
    }

    if (log.logData.changeType === 'UPDATED') {
      return t('order-activity-sidebar/asset-updated', {
        assetType: log.logData.assetType,
        newValue: log.logData.newValue
      })
    }
    return 'error'
  }

  const LOG_TEXTS: { [key: string]: (log: OrderLog) => string } = {
    [OrderLogType.DocumentGenerated]: () =>
      t('order-activity-sidebar/log-order-document-generated'),
    [OrderLogType.DocumentSent]: () =>
      t('order-activity-sidebar/log-order-document-sent'),
    [OrderLogType.New]: () => t('order-activity-sidebar/log-order-created'),
    [OrderLogType.Registered]: () => t('order-activity-sidebar/log-order-registered'),
    [OrderLogType.Unregistered]: () => t('order-activity-sidebar/log-order-unregistered'),
    [OrderLogType.CarrierLocked]: () =>
      t('order-activity-sidebar/log-order-carrier-locked'),
    [OrderLogType.CarrierRemoved]: () =>
      t('order-activity-sidebar/log-order-carrier-removed'),
    [OrderLogType.Cancelled]: () => t('order-activity-sidebar/log-order-cancelled'),
    [OrderLogType.Dispatched]: () => t('order-activity-sidebar/log-order-dispatched'),
    [OrderLogType.Undispatched]: () => t('order-activity-sidebar/log-order-undispatched'),
    [OrderLogType.Executed]: () => t('order-activity-sidebar/log-order-executed'),
    [OrderLogType.OperationsCompleted]: () =>
      t('order-activity-sidebar/log-order-ops-completed'),
    [OrderLogType.Completed]: () => t('order-activity-sidebar/log-order-completed'),
    [OrderLogType.TransEuOfferPosted]: () =>
      t('order-activity-sidebar/log-trans-eu-offer-posted'),
    [OrderLogType.OrderCopied]: () => t('order-activity-sidebar/log-order-copied'),
    [OrderLogType.MissingDocumentsRequested]: getMissingDocumentLogText,
    [OrderLogType.SurchargeAdded]: getSurchargeLogText,
    [OrderLogType.CorrectionAdded]: getCorrectionLogText,
    [OrderLogType.OrderReferenceNumberChanged]: getOrderReferenceNumberChangedLogText,
    [OrderLogType.StopoverReferenceNumberChanged]:
      getStopoverReferenceNumberChangedLogText,
    [OrderLogType.OrderAllowedVehicleTypesChanged]:
      getOrderAllowedVehicleTypesChangedLogText,
    [OrderLogType.StopoverTimeChanged]: getStopoverTimeChangedLogText,
    [OrderLogType.StopoverAddressChanged]: getStopoverAddressChangedLogText,
    [OrderLogType.StopoverAdded]: getStopoverAddedLogText,
    [OrderLogType.StopoverRemoved]: getStopoverRemovedLogText,
    [OrderLogType.ShipperInvoiceCreated]: getShipperInvoiceCreatedLogText,
    [OrderLogType.ActualTimesCorrectedPostExecution]:
      getActualTimesCorrectedPostExecutionLogLogText,
    [OrderLogType.StopActionSuccessful]: getActualTimesCorrectedPostExecutionLogText,
    [OrderLogType.ALLOCATED_ASSETS_CHANGED]: getAllocatedAssetsChangedLogText
  }

  return (LOG_TEXTS[log.logType] || (() => log.message))(log)
}

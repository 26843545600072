




























































import { PropType, defineComponent, toRefs } from '@vue/composition-api'
import { OrderDetail, OrderStop } from '@/services'
import useOrderSideBarV3 from '@/modules/ordering/compositions/useOrderSideBarV3'
// sections
import LegacyPalletExchangeSection from './LegacyPalletExchangeSection.vue'
import CarrierSection from './CarrierSection.vue'
import VehicleDetails from './VehicleDetails.vue'
import TrackingInformation from './TrackingInformation.vue'
import LoadSectionV3 from './LoadSectionV3.vue'
import StopsSectionV3 from './StopsSectionV3.vue'
import RouteSection from './RouteSection.vue'
import DescriptionSection from './DescriptionSection.vue'
import VehicleSpecifications from './VehicleSpecifications.vue'

export default defineComponent({
  components: {
    LegacyPalletExchangeSection,
    CarrierSection,
    VehicleDetails,
    TrackingInformation,
    LoadSectionV3,
    StopsSectionV3,
    RouteSection,
    DescriptionSection,
    VehicleSpecifications
  },
  props: {
    order: { type: Object as PropType<OrderDetail>, required: true },
    stops: { type: Array as PropType<OrderStop[]>, required: true },
    totalDistance: { type: Number, default: null },
    loading: { type: Boolean, default: false }
  },
  setup(props) {
    const orderSideBarData = useOrderSideBarV3(
      toRefs(props).order,
      toRefs(props).stops,
      toRefs(props).totalDistance
    )
    return { ...orderSideBarData }
  }
})

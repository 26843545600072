const mutations = {
  setShipmentV3(state, payload) {
    state.shipmentV3 = payload
  },
  setShipmentRoute(state, payload) {
    state.shipmentRoute = payload
  },
  setShipmentStops(state, payload) {
    state.shipmentStops = payload
  },
  setIsShipmentStopsLoaded(state, payload) {
    state.isShipmentStopsLoaded = payload
  },
  setIsFetchingShipmentEditRequestState(state, payload) {
    state.isFetchingShipmentEditRequestState = payload
  },
  setShipmentEditRequestMainState(state, payload) {
    state.shipmentEditRequestMainState = payload
  },
  setShipmentPartialUpdate(state, payload) {
    state.shipmentEditPartialUpdate = { ...state.shipmentEditPartialUpdate, ...payload }
  },
  updateSchedule(state, payload) {
    state.shipmentEditPartialUpdate.scheduleUpdate = payload
  },
  updateReference(state, payload) {
    state.shipmentEditPartialUpdate.referenceUpdate = payload
  },
  updateVehicleRequirement(state, payload) {
    state.shipmentEditPartialUpdate.vehicleRequirementUpdate = payload
  },
  updateTrackingRequirement(state, payload) {
    state.shipmentEditPartialUpdate.trackingRequirementUpdate = payload
  },
  updateHasShipmentEditAnyUpdates(state, payload) {
    state.hasShipmentEditAnyUpdates = payload
  }
}

export default mutations

import { createFilter } from './filter'
import moment from 'moment-timezone'
import { DATE_FORMAT, TIME_FORMAT } from '@/global-setup/filters'
import { i18n } from '@/plugins/i18n'

const emptyValue = [null, null]

const getIsoStringFromDateTime = date => {
  return date ? moment(date, `${DATE_FORMAT} ${TIME_FORMAT}`).toISOString() : null
}

const getBeginningOf = date => moment(date, DATE_FORMAT).startOf('day').toISOString()

const getEndOf = date => moment(date, DATE_FORMAT).endOf('day').toISOString()

/**
 * @typedef {string[]|undefined} FilterValue
 * @typedef {import('./filter').UrlQueryFragment} UrlQueryFragment
 */

/**
 * @param {object} data
 * @param {string[]} data.names - keys used to generate API/GraphQL payload (array of 2 elements)
 * @param {string[]|undefined} data.appliedValueLabels - applied filter titles (array of 2 elements)
 * @param {string[]|undefined} data.urlParamNames - URL query param names (array of 2 elements)
 * @param {FilterValue} data.initialValues - array of 2 elements
 * @param {FilterValue} data.emptyValues - array of 2 elements
 * @param {string} data.updateName - name for setter/getter
 * @param {string} data.isRangeFilter - if this is true it means that the value stored in the store is necessarily per pair and when removing one filter we will have to clear the list of dates
 * @param {string} data.keepTime - if false we set the time of the date with 00:00 and 23:59:59 otherwise we keep the hours defined in the date
 * @returns {any} - vuex module instance
 */
export const createDatesFilter = ({
  names,
  urlParamNames = names,
  initialValues = emptyValue,
  emptyValues = emptyValue,
  updateName,
  filterLabels,
  isRangeFilter = false,
  keepTime = false,
  esNames = names
}) => {
  let filter = createFilter({
    name: names.join(','),
    initialValue: initialValues,
    emptyValue: emptyValues
  })

  filter.state = {
    ...filter.state,
    urlParamName: urlParamNames
  }

  filter.getters = {
    ...filter.getters,
    /**
     * @param {*} state
     * @returns {object<string, string>} - a fragment of API payload object
     */
    getApiFragment: state => {
      let apiFragment = {}

      if (state.value[0])
        apiFragment[names[0]] = keepTime
          ? getIsoStringFromDateTime(state.value[0])
          : getBeginningOf(state.value[0])
      if (state.value[1])
        apiFragment[names[1]] = keepTime
          ? getIsoStringFromDateTime(state.value[1])
          : getEndOf(state.value[1])

      return apiFragment
    },
    /**
     * @param {*} state
     * @returns {array<object>} - data for AppliedFilters component
     */
    getAppliedValue: state => {
      let appliedValues = []

      state.value.forEach((value, index) => {
        if (value) {
          const filterStateAfterRemoval = [...state.value]
          filterStateAfterRemoval[index] = emptyValue[index]

          appliedValues.push({
            label: `${i18n.t(filterLabels[index])}: ${value}`,
            updateName,
            emptyValue: isRangeFilter ? emptyValue : filterStateAfterRemoval
          })
        }
      })

      return appliedValues.length ? appliedValues : null
    },
    /**
     * @param {*} state
     * @returns {?string} - a fragment of GraphQL payload object
     */
    getGraphqlValue: state => {
      let graphqlValues = []

      if (state.value[0])
        graphqlValues.push(
          `${names[0]}: ${JSON.stringify(
            keepTime
              ? getIsoStringFromDateTime(state.value[0])
              : getBeginningOf(state.value[0])
          )}`
        )

      if (state.value[1])
        graphqlValues.push(
          `${names[1]}: ${JSON.stringify(
            keepTime ? getIsoStringFromDateTime(state.value[1]) : getEndOf(state.value[1])
          )}`
        )

      return graphqlValues.length ? graphqlValues.join('\n, ') : null
    },
    /**
     * @param {*} state
     * @returns {UrlQueryFragment} - a fragment of URL query object
     */
    getUrlQueryFragment: state => {
      /*
        It's important to return dates even if they are the same as initial ones
        because otherwise users will get different orders even if the URL is the same
        if they check it e.g. a week later because initial dates will be different
      */
      let urlFragment = {}

      state.value.forEach((value, index) => {
        const defaultValueSet = initialValues[index] !== emptyValues[index]
        if (defaultValueSet || value !== emptyValues[index]) {
          urlFragment[urlParamNames[index]] = value === emptyValue[index] ? null : value
        }
      })

      return urlFragment
    },
    getEsQueryFragment: (state, getters) => {
      if (getters.isFilterEmpty) {
        return []
      } else {
        const start = new Date(getIsoStringFromDateTime(state.value[0])).getTime()
        const end = new Date(getIsoStringFromDateTime(state.value[1])).getTime()
        return [
          { range: { [esNames[0]]: { gte: start } } },
          { range: { [esNames[1]]: { lte: end } } }
        ]
      }
    }
  }

  filter.actions = {
    ...filter.actions,
    /**
     * @param {*} store
     * @param {UrlQueryFragment} urlQueryFragment
     */
    setValueFromUrl({ commit }, urlQueryFragment) {
      const urlValues = urlParamNames.map((urlParam, index) => {
        if (urlQueryFragment[urlParam] === null) return emptyValues[index]
        else return urlQueryFragment[urlParam] || initialValues[index]
      })
      if (urlValues[0] !== initialValues[0] || urlValues[1] !== initialValues[1])
        commit('update', urlValues)
    }
  }

  return filter
}

import { CARRIER_FIN_DOCS_API_URL } from '@/config'
import { prepareBackendErrorJsonMessageIfNeeded } from '@/global-setup/global-error-handler'
import axios from 'axios'
import { getCommonHeaders } from './auth-service'

export const generateForOrders = async ({ external_ids }) => {
  try {
    const response = await runJsonPost(
      `${CARRIER_FIN_DOCS_API_URL}/api/carrier-finance-documents/generate-for-orders`,
      { external_ids }
    )
    return response
  } catch (error) {
    throw {
      ...error.response.data,
      status: error.response.status
    }
  }
}

const configJSON = async (config = {}) => {
  const commonHeaders = await getCommonHeaders()

  return {
    headers: {
      ...commonHeaders
    },
    ...config
  }
}

export async function runJsonPost(url, data) {
  const config = await configJSON({ method: 'post', url, data })
  return run(config)
}

function run(config) {
  //We globally intercept all network errors in initNetworkGlobalErrorHandling
  return axios(config).catch(error => {
    if (axios.isCancel(error)) return
    prepareBackendErrorJsonMessageIfNeeded(error)
    throw error
  })
}

<template>
  <Dialog
    :show="show"
    :disabled="sendingConfirmation"
    class="send-dispatch-confirmation-modal"
    :title="$t('send-dispatch-confirmation-modal-dialog-title')"
    :confirmButtonText="$t('send-dispatch-confirmation-modal-dialog-confirm-text')"
    :cancelButtonText="$t('send-dispatch-confirmation-modal-dialog-cancel-text')"
    inPlace
    @confirm="$refs.form.submit()"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <p class="send-dispatch-confirmation-modal__description subtitle">
      {{ $t('send-dispatch-confirmation-modal-dialog-send-warning-text') }}
    </p>
    <Form ref="form" @validated-submit="handleFormSubmit">
      <LanguageDropdownField
        v-model="language"
        :validators="languageValidators"
        :disabled="sendingConfirmation"
        :title="$t('send-dispatch-confirmation-modal-dialog-language-title')"
        :placeholder="$t('send-dispatch-confirmation-modal-dialog-select-language')"
        class="send-dispatch-confirmation-modal__field"
        data-test="dispatch-confirmation-modal--language-field"
      />
    </Form>
  </Dialog>
</template>
<script>
import isEmpty from 'lodash/isEmpty'
import LanguageDropdownField from '@/components/LanguageDropdownField'
import { sendDispatchConfirmation } from '@/services//ordering-service'
import { refreshOrderLogs } from '@/modules/order-activity-sidebar'

export default {
  components: { LanguageDropdownField },
  props: {
    show: { type: Boolean, required: true },
    orderIds: { type: Array, required: true }
  },
  data() {
    return { language: null, sendingConfirmation: false }
  },
  watch: {
    show(value) {
      if (value) this.language = null
    }
  },
  created() {
    this.languageValidators = [
      {
        isInvalid: isEmpty,
        errorMessage: this.$t('global/validations-field-cannot-be-empty')
      }
    ]
  },
  methods: {
    async handleFormSubmit($event) {
      if ($event.hasErrors) return
      try {
        this.sendingConfirmation = true
        await sendDispatchConfirmation(this.orderIds, this.language)
        refreshOrderLogs()
        this.$emit('close')
      } finally {
        this.sendingConfirmation = false
      }

      return null
    }
  }
}
</script>
<style lang="scss" scoped>
.send-dispatch-confirmation-modal {
  text-align: left;

  &__description {
    margin-bottom: 20px;
    text-align: left;
  }

  &__field {
    width: 100%;
  }
}
</style>

<template>
  <a :href="link" class="carrier-search-dashboard-link" target="_blank" @click="track">
    <img src="./assets/carrier-search-dashboard-icon.png" />
    <span class="text">
      {{ $t('ordering/dispatching-carrier-suggestions-carrier-search-dashboard-link') }}
    </span>
  </a>
</template>

<script>
import { trackEvent } from '@/analytics/amplitude'
import CarrierSearchDashboardLinkBuilder from './CarrierSearchDashboardLinkBuilder'

export default {
  props: {
    origin: { type: Object, required: true },
    destination: { type: Object, required: true },
    vehicleType: { type: String, required: true }
  },
  computed: {
    link() {
      return new CarrierSearchDashboardLinkBuilder()
        .originCountryCode(this.origin.countryCode)
        .originPostalCode(this.origin.zipCode)
        .destinationCountryCode(this.destination.countryCode)
        .destinationPostalCode(this.destination.zipCode)
        .vehicleType(this.vehicleType)
        .build()
    }
  },
  methods: {
    track() {
      trackEvent('octopus/carrier-suggestion/click/dashboard-link', {
        origin: this.origin,
        destination: this.destination,
        vehicleType: this.vehicleType
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.carrier-search-dashboard-link {
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: baseline;

  &:hover {
    text-decoration: none;
  }

  .text {
    display: block;
    margin: 0;
    margin-left: 8px;
  }
}
</style>

import { INFRASTRUCTURE_URL } from '@/config'
import { runJsonGet, runJsonPost } from '@/models/backend-client'
import { transformKeysToCamelCase, transformKeysToSnakeCase } from './utils/casing'
import { DEFAULT_TIMEZONE } from '@/modules/ordering/constants'
import { Timestamp } from '@sennder/plankton'
import bus from '@/bus'
import { AxiosResponse } from 'axios'
import {
  ExecutionTransfer,
  StepActualTimeResponse,
  StepToUpdate,
  TransferInExecution,
  TransferStep,
  TransferStepActualTime
} from '.'

export const getTransferInExecution = async (
  transferId: number
): Promise<ExecutionTransfer> => {
  const response = (await runJsonGet(
    `${INFRASTRUCTURE_URL}/queries/get-transfer-in-execution?transfer_id=${transferId}`
  )) as AxiosResponse<unknown>
  const data = transformKeysToCamelCase(response.data) as TransferInExecution

  const version = data.transfer.version
  const locations = data.trackedGpsPositions || []
  const stops = data.stopovers || []
  const assignedTrackers = data.assignedTrackers || []
  const delay = data.delay

  const getTimezoneForNthStep = (index: number) => {
    return data.steps.length === Math.floor(stops.length * 2)
      ? stops[Math.floor(index / 2)].timezone
      : DEFAULT_TIMEZONE
  }

  const mapServerSideStep = (step: TransferStep, index: number) => {
    const timezone = getTimezoneForNthStep(index)
    return {
      ...step,
      timezone
    }
  }
  const steps = data.steps ? data.steps.map(mapServerSideStep) : []

  return {
    version,
    steps,
    locations,
    stops,
    assignedTrackers,
    delay
  }
}

export const updateSteps = async (
  transferId: number,
  transferVersion: number,
  steps: StepToUpdate[]
) => {
  await runJsonPost(
    `${INFRASTRUCTURE_URL}/commands/save-transfer-steps-actual-times`,
    transformKeysToSnakeCase({
      transfer: { version: transferVersion },
      steps
    })
  )

  bus.emit('transfer-steps-actual-times-updated', transferId, steps)
}

export const updateStepsPostExecution = async (
  transferId: number,
  transferVersion: number,
  steps: StepToUpdate[]
): Promise<void> => {
  await runJsonPost(
    `${INFRASTRUCTURE_URL}/commands/save-transfer-steps-actual-times-post-execution`,
    transformKeysToSnakeCase({
      transfer: { version: transferVersion },
      steps
    })
  )

  bus.emit('transfer-steps-actual-times-updated', transferId, steps)
}

export const getStepActualTimes = async (
  transferId: number
): Promise<TransferStepActualTime[]> => {
  const response = (await runJsonGet(
    `${INFRASTRUCTURE_URL}/queries/get-step-actual-times?transfer_id=${transferId}`
  )) as AxiosResponse<unknown>

  const responseData = transformKeysToCamelCase<StepActualTimeResponse>(response.data)
  const data = responseData?.steps || []

  return data.map(step => ({
    ...step,
    actualTime: new Timestamp(step.actualTime, step.timezone || DEFAULT_TIMEZONE)
  }))
}









import Vue from 'vue'
import { Location, RawLocation, Route } from 'vue-router'

import {
  createRandomString,
  getEnvironmentAliasForFederatedLoader
} from '@/microfrontends/utils'
import { trackEvent } from '@/analytics/amplitude'
import {
  SharedDataType,
  Tenant,
  IUser
} from '@sennder/senn-node-microfrontend-interfaces'
import useCurrentUser from '@/compositions/useCurrentUser'
import { getLogger } from '@/shell/datadog-logger'
import useI18n from '@/compositions/useI18n'
import { getAppTheme } from '@/controllers/environment-detection'
import { getAuthHeader } from '@/services/auth-service'
import { monitorError } from '@/analytics/monitoring'
import { fetchMicrofrontend } from '@/microfrontends/fetchMicrofrontend'
import consoleLogger from '@/shell/console-logger'

const DEV_PORT = 9150
const NAMESPACE = 'spotter'
const NPM_PATH = 'spotter'
const REMOTE = './spotterAppLinkOpportunityWidget'

export default Vue.extend({
  name: 'SpotterLinkWidget',
  props: {
    mode: {
      type: String,
      default: 'create_link_widget_only'
    },
    source: {
      type: String,
      default: ''
    },
    shipmentId: {
      type: String,
      required: true
    },
    orderId: {
      type: String,
      default: ''
    },
    orderState: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      id: `${createRandomString()}-SpotterLinkWidget`,
      isLoaded: null as boolean | null,
      syncChildRouter: null as ((route: RawLocation) => void) | null,
      unmount: null as (() => void) | null
    }
  },
  async mounted() {
    const syncParentRouter = (route: RawLocation) => {
      const currentPath = this.$route.fullPath
      if (
        (typeof route === 'string' && currentPath === route) ||
        (typeof route === 'object' && currentPath === (route as Location).path)
      ) {
        return
      }
      this.$router.push(route)
    }
    try {
      const { user } = useCurrentUser()
      const { locale } = useI18n()

      const mount = await fetchMicrofrontend({
        devPort: DEV_PORT,
        environment: getEnvironmentAliasForFederatedLoader(NAMESPACE),
        moduleFederationPluginName: NAMESPACE,
        npmName: NPM_PATH,
        mountExposedName: REMOTE,
        logger: getLogger()
      })

      const mfData = mount(`#${this.id}`, {
        data: {
          type: SharedDataType.OCTOPUS,
          user: user.value as IUser,
          language: locale.value,
          env: {},
          tenant: getAppTheme() as Tenant,
          mode: this.mode,
          shipment_id: this.shipmentId,
          order_id: this.orderId,
          order_state: this.orderState,
          integration_source: this.source,
          featureFlags: {}
        },
        callbacks: {
          getToken: getAuthHeader,
          // TODO: Remove legacy data
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          getAuth0Token: async (audience: string, scope?: string) => {
            return getAuthHeader({
              audience,
              scope
            })
          },
          syncParentRouter,
          // segment is deprecated, use Amplitude provider instead
          segmentTrackEvent: trackEvent,
          onUnauthorized: () => {},
          onUnhandledError: error => {
            Vue.config.errorHandler(error, null, 'Spotter Link Widget Microfrontend')
          }
        },
        providers: {
          logger: getLogger(),
          notifications: null,
          translations: null,
          analytics: null,
          monitoring: null,
          // segment is deprecated, use Amplitude provider instead
          segment: {
            trackEvent,
            setContext: () => {
              consoleLogger.error('setContext not implemented')
            },
            trackPage: () => {
              consoleLogger.error('trackPage not implemented')
            }
          }
        }
      })
      if (mfData.syncChildRouter) {
        this.syncChildRouter = mfData.syncChildRouter
        this.$watch(
          '$route',
          (to: Route) => {
            mfData.syncChildRouter(to.fullPath)
          },
          {
            immediate: true
          }
        )
      }
      if (mfData.unmount) {
        this.unmount = mfData.unmount
      }
      this.isLoaded = true
    } catch (e) {
      monitorError(e, 'Spotter', {
        type: 'bootstrap'
      })
      this.isLoaded = false
    }
  },
  destroyed() {
    if (this.unmount) {
      this.unmount()
    }
  }
})

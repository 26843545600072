












































































import { financeService } from '@sennder/finance-frontend'
import { formatRoundedMoneyWithDashForNull } from '@/global-setup/filters'
import { defineComponent, reactive, computed, inject, toRefs } from '@vue/composition-api'
import { ref } from '@vue/composition-api'
import { Filters } from '../types'
import { AggregationValues } from '@/modules/finance-board/types'

export default defineComponent({
  setup() {
    const noFiltersSelected: () => boolean = inject('noFiltersSelected')
    const getFiltersForApi: () => Filters = inject('getFiltersForApi')

    const state = reactive<AggregationValues>({
      overallRevenue: null,
      overallCost: null,
      count: null
    })

    const initialized = ref(false)

    const totalMargin = computed(() => {
      return state.overallRevenue - state.overallCost
    })

    const totalMarginPercentage = computed(() => {
      if (totalMargin.value && state.overallRevenue) {
        const percentage = (totalMargin.value / state.overallRevenue) * 100
        return percentage.toFixed(1) + '%'
      } else {
        return '-'
      }
    })

    const updateTotalRevenueCostAndCount = () => {
      const apiFilters = getFiltersForApi()
      return financeService.fetchOrdersAggregation(
        apiFilters
      ) as Promise<AggregationValues>
    }

    const updateTotalValues = async () => {
      if (noFiltersSelected()) return clearValues()
      const updatedResult = await updateTotalRevenueCostAndCount()

      if (updatedResult) {
        state.overallRevenue = updatedResult.overallRevenue
        state.overallCost = updatedResult.overallCost
        state.count = updatedResult.count
        initialized.value = true
      }
    }

    const clearValues = () => {
      state.overallRevenue = null
      state.overallCost = null
      state.count = null
    }

    return {
      ...toRefs(state),
      formatRoundedMoneyWithDashForNull,
      totalMargin,
      totalMarginPercentage,
      updateTotalValues,
      initialized
    }
  }
})

<template>
  <TypeAheadField
    :options="options"
    :placeholder="placeholder"
    :internalSearch="internalSearch"
    :title="titleString"
    :validators="fieldValidators"
    v-bind="$attrs"
    :optionLabel="optionLabel"
    optionKey="id"
    data-test="typeahead-field"
    class="fullwidth-dropdown"
    v-on="$listeners"
    @fetchFilterOptions="onFetchFilterOptions"
    @clearFilterOptions="onClearFilterOptions"
  />
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import { fetchCarriersBySearchText } from '@/services/carrier-service'

export default {
  props: {
    placeholder: { type: String, default: 'Enter carrier company' },
    title: { type: String, default: 'Carrier company' },
    validators: { type: Array, default: () => [] },
    required: { type: Boolean, default: false },
    inlineError: { type: Boolean, default: false },
    source: { type: Function, default: fetchCarriersBySearchText },
    optionLabel: { type: String, default: 'name' }
  },
  data() {
    return { options: [], internalSearch: false }
  },
  computed: {
    titleString() {
      return this.required ? this.title + '*' : this.title
    }
  },
  created() {
    if (this.required) {
      this.fieldValidators = [
        ...this.validators,
        {
          isInvalid: isEmpty,
          errorMessage: 'Carrier cannot be empty',
          inline: this.inlineError
        }
      ]
    } else {
      this.fieldValidators = [...this.validators]
    }
  },
  methods: {
    async onFetchFilterOptions(query) {
      this.options = await this.source(query)
    },
    onClearFilterOptions() {
      this.options = []
    }
  }
}
</script>

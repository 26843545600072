























import { trackEvent } from '@/analytics/amplitude'
import { defineComponent } from '@vue/composition-api'
import Divider from './Divider.vue'

interface Tab {
  id: string
  title: string
}

export default defineComponent({
  components: {
    Divider
  },
  props: {
    tabs: { type: Array, default: () => [] },
    value: { type: String, default: null }
  },
  methods: {
    selectTab(tab: Tab) {
      trackEvent(`octopus/carrier-suggestion/click/${tab.id}-tab`, {})
      this.$emit('input', tab.id)
    }
  }
})

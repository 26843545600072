<template>
  <span
    v-tooltip.bottom="{ content: tooltip, delay: { show: 300 } }"
    v-if="text"
    v-bind="$attrs"
  >
    {{ text }}
  </span>
  <span v-else v-bind="$attrs">-</span>
</template>

<script>
export default {
  props: {
    text: { type: String, default: null },
    tooltip: { type: String, default: null }
  }
}
</script>

<template>
  <div class="filter-wrapper">
    <TypeAheadField
      :options="filterOptions"
      v-model="carrierFilterHandler"
      :height-fixed="false"
      :visible-selected-options-limit="5"
      :internalSearch="internalSearch"
      data-test="carrier-filter"
      optionLabel="name"
      optionKey="id"
      multiple
      :title="$t('filters/carrier-filter-field-title')"
      placeholder=""
      @fetchFilterOptions="onFetchFilterOptions"
      @clearFilterOptions="onClearFilterOptions"
    />
    <Button
      v-tooltip.bottom="$t('filters/carrier-filter-not-carrier-button-label')"
      v-if="showHasNoCarrierFilter"
      :category="hasNoCarrier ? 'primary' : 'tertiary'"
      materialIcon="local_shipping"
      data-test="orders-without-carrier-button"
      class="dropdown-filter-button"
      @click="onHasNoCarrierFilterClick"
    />
  </div>
</template>

<script>
import { fetchCarriersBySearchText } from '@/services/carrier-service'

export default {
  inject: ['getFilterValue', 'setFilterValue'],
  props: {
    showHasNoCarrierFilter: { type: Boolean, default: true }
  },
  data: () => ({
    filterOptions: [],
    internalSearch: false
  }),
  computed: {
    carrierFilterHandler: {
      get() {
        return this.getFilterValue('carriers')
      },
      set(payload) {
        this.setFilterValue('carriers', payload)
        this.$emit('applyFilters')
      }
    },
    hasNoCarrier() {
      return this.getFilterValue('hasNoCarrier')
    }
  },
  methods: {
    onHasNoCarrierFilterClick() {
      this.setFilterValue('hasNoCarrier', !this.hasNoCarrier)
      this.$emit('applyFilters')
    },
    async onFetchFilterOptions(searchText) {
      this.filterOptions = await fetchCarriersBySearchText(searchText)
    },
    onClearFilterOptions() {
      this.filterOptions = []
    }
  }
}
</script>
<style lang="scss" scoped>
.filter-wrapper {
  display: flex;
  position: relative;
}

.dropdown-filter-button {
  margin-left: 8px;
  margin-top: 16px;
}
</style>

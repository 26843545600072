<template>
  <Form ref="form" v-bind="$attrs">
    <div class="plankton-grid">
      <div class="plankton-cell" span="11">
        <CustomerTypeAheadField
          :value="get(value, 'customer', null)"
          :disabled="customerDisabled"
          required
          inlineError
          fullWidth
          closedLiveOnly
          :title="$t('ordering/customer-details-form-type-ahed-field-company-title')"
          :noResultMessage="
            $t('ordering/customer-details-form-type-ahed-company-no-result-msg')
          "
          :noOptionsMessage="
            $t('ordering/customer-details-form-type-ahed-company-no-option-msg')
          "
          class="customer-company-field"
          data-test="customer-company-field"
          @select="emitUpdate({ customer: $event, contact: null })"
        />
      </div>
      <div class="plankton-cell" span="7">
        <InputField
          :value="get(value, 'referenceNumber', null)"
          :disabled="referenceNumberDisabled"
          maxlength="100"
          fullWidth
          convertEmptyToNull
          :title="$t('ordering/customer-details-form-field-reference-num-label')"
          :placeholder="
            $t('ordering/customer-details-form-field-reference-num-placeholder')
          "
          data-test="reference-number-field"
          @input="emitUpdate({ referenceNumber: $event })"
        />
      </div>
      <div class="plankton-cell" span="6">
        <InputField
          :value="get(value, 'trackingIdForShipper', null)"
          :disabled="trackingIdForShipperDisabled"
          fullWidth
          convertEmptyToNull
          :title="$t('ordering/customer-details-form-field-tracking-id-label')"
          :placeholder="
            $t('ordering/customer-details-form-field-tracking-id-placeholder')
          "
          data-test="shipper-tracking-id-field"
          @input="emitUpdate({ trackingIdForShipper: $event })"
        />
      </div>
    </div>

    <div class="plankton-grid">
      <div class="plankton-cell" span="8">
        <CustomerContactDropdownField
          :customerId="get(value, 'customer.id', null)"
          :value="get(value, 'contact', null)"
          :disabled="contactDisabled"
          required
          inlineError
          fullWidth
          :title="
            $t('ordering-share-header/customer-details-form-field-contact-person-label')
          "
          class="customer-contact-field"
          data-test="contact-person-dropdown"
          @select="emitUpdate({ contact: $event })"
        />
      </div>
      <div class="plankton-cell" span="9">
        <InputField
          :value="get(value, 'contact.email', null)"
          disabled
          fullWidth
          :title="
            $t('ordering-share-header/customer-details-form-field-contact-email-title')
          "
          class="contact-email-input"
          data-test="email-field"
        />
      </div>
      <div class="plankton-cell" span="7">
        <InputField
          :value="get(value, 'contact.phone', null)"
          disabled
          fullWidth
          :title="
            $t('ordering-share-header/customer-details-form-field-contact-phone-title')
          "
          class="contact-phone-input"
          data-test="phone-field"
        />
      </div>
    </div>

    <div class="plankton-grid">
      <div class="plankton-cell" span="16" v-if="templateVisible">
        <OrderTemplateDropdownField
          :value="get(value, 'template.lineCode', null)"
          :customerId="get(value.customer, 'id', null)"
          :title="$t('ordering/customer-details-form-field-order-template-title')"
          :placeholder="
            $t('ordering/customer-details-form-field-order-template-placeholder')
          "
          inlineError
          data-test="template-field"
          @select="onTemplateSelected({ template: $event })"
          @optionsFetched="onTemplateOptionsFetched"
        />
      </div>
      <div class="plankton-cell" span="8" v-if="contractTypeVisible">
        <DropdownField
          :options="contractTypes"
          :value="get(value, 'regularity', null)"
          optionLabel="label"
          optionKey="id"
          required
          :validators="getRequiredValidator()"
          :title="
            $t('ordering-share-header/customer-details-form-field-contact-type-title')
          "
          data-test="contract-type"
          :placeholder="
            $t('ordering/customer-details-form-field-contact-type-placeholder')
          "
          :disabled="isContractTypeDisabled"
          @select="emitUpdate({ regularity: $event })"
        />
      </div>
    </div>
    <div class="plankton-grid">
      <div class="plankton-cell" span="22">
        <div v-if="get(value, 'template.lineCode', null)">
          <div
            class="paragraph template-loading-address"
            data-test="loading-template-address"
          >
            <b>{{ $t('ordering/customer-details-form-loading-label') }}</b>
            <span>
              {{
                `${value.template.originCompany}, ${value.template.originCompanyAddress}`
              }}
            </span>
          </div>
          <div class="paragraph" data-test="unloading-template-address">
            <b>{{ $t('ordering/customer-details-form-unloading-label') }}</b>
            <span>
              {{
                `${value.template.destinationCompany}, ${value.template.destinationCompanyAddress}`
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import get from 'lodash/get'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import CustomerTypeAheadField from '@/components/CustomerTypeAheadField'
import CustomerContactDropdownField from '../CustomerContactDropdownField'
import OrderTemplateDropdownField from '../OrderTemplateDropdownField'
import { ref, onBeforeMount, computed } from '@vue/composition-api'
import { getContractTypes } from '@/services/ordering-service'
import { makeOptions } from '@/controllers/options-formatter'

export default {
  components: {
    CustomerTypeAheadField,
    CustomerContactDropdownField,
    OrderTemplateDropdownField
  },
  props: {
    value: { type: Object, default: null },
    customerDisabled: { type: Boolean, default: false },
    contactDisabled: { type: Boolean, default: false },
    referenceNumberDisabled: { type: Boolean, default: false },
    trackingIdForShipperDisabled: { type: Boolean, default: false },
    addressDisabled: { type: Boolean, default: false },
    templateVisible: { type: Boolean, default: false },
    contractTypeVisible: { type: Boolean, default: false },
    isCopied: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    const form = ref(null)
    const contractTypes = ref([])
    const customerHaveTemplates = ref(false)
    const initialRegularity =
      props?.value?.regularity === null
        ? null
        : new String(props?.value?.regularity).toString()

    const findContractType = idValue =>
      contractTypes.value.find(type => type.id === idValue)

    //compute and prefill the "regularity type” based on some Customer information
    const computeRegularity = template => {
      if (template) {
        if (props.isCopied) {
          return template.contractType
            ? findContractType(template.contractType)
            : findContractType('REGULAR')
        }
        if (template.contractType) {
          return findContractType(template.contractType)
        }

        return findContractType('REGULAR')
      }

      if (props.value.regularity && props.isCopied) {
        return findContractType(get(props.value, 'regularity', null))
      }

      if (!customerHaveTemplates.value) {
        return findContractType('SPOT')
      }
      return null
    }
    const isContractTypeDisabled = computed(() => {
      const isContractTypeInTemplate = props?.value.template?.contractType != null
      const isTemplateBasedOrder = props?.value.template?.lineCode != null

      const isdiabledWhenCopied =
        isTemplateBasedOrder &&
        props.isCopied &&
        initialRegularity != null &&
        isContractTypeInTemplate
      const isDisabledWhenAddingOrder = isContractTypeInTemplate && !props.isCopied

      return isDisabledWhenAddingOrder || isdiabledWhenCopied
    })

    const onTemplateSelected = changes => {
      if (!props.isCopied && changes.template === null) {
        changes.regularity = null
      } else if (props.isCopied || changes.template) {
        changes.regularity = computeRegularity(changes.template)
      }
      emitUpdate(changes)
    }
    const onTemplateOptionsFetched = options => {
      customerHaveTemplates.value = options.length > 0

      const lineCode = props.value.template?.lineCode

      if (props.isCopied && lineCode) {
        emitUpdate({
          template: options.find(t => t.lineCode === lineCode),
          regularity: computeRegularity(null)
        })
      } else {
        emitUpdate({ regularity: computeRegularity(null) })
      }
    }

    onBeforeMount(async () => {
      if (props.contractTypeVisible) {
        const types = await getContractTypes()
        contractTypes.value = makeOptions(types, 'label')
      }
    })

    const emitUpdate = changes => {
      emit('input', { ...props.value, ...changes })
    }
    function getRequiredValidator() {
      return {
        isInvalid: isEmpty,
        errorMessage: this.$t(
          'ordering-share-header/customer-details-form-field-required-msg'
        ),
        inline: true
      }
    }
    const submit = () => form.value.submit()

    return {
      getRequiredValidator,
      onTemplateOptionsFetched,
      onTemplateSelected,
      isContractTypeDisabled,
      get,
      has,
      emitUpdate,
      submit,
      contractTypes,
      form,
      findContractType
    }
  }
}
</script>
<style lang="scss" scoped>
.template-loading-address {
  padding: 5px 0;
}
</style>

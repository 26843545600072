























































































































































import get from 'lodash/get'
import pick from 'lodash/pick'
import cloneDeep from 'lodash/cloneDeep'
import uniqueId from 'lodash/uniqueId'
import {
  computed,
  defineComponent,
  nextTick,
  PropType,
  reactive,
  ref,
  watch
} from '@vue/composition-api'

import OrderStopsForm from '@/modules/ordering/components/shared/stops/OrderStopsForm.vue'
import LoadSection from './LoadSection.vue'
import OldPriceSection from './OldPriceSection.vue'
import OrderStageFooter from '@/modules/ordering/components/shared/OrderStageFooter.vue'
import OrderTemplateDropdownField from '@/modules/ordering/components/shared/OrderTemplateDropdownField.vue'
import AsyncActionButton from '@/modules/ordering/components/shared/async-action-button/AsyncActionButton.vue'
import AsyncActionContext from '@/modules/ordering/components/shared/async-action-button/AsyncActionContext.vue'

import { updatePalletExchangeConfiguration } from '@/services/pallet-exchange-service'
import { fetchTemplate } from '@/services/customer-service'
import { PALLET_EXCHANGE_TYPES } from '@/modules/ordering/constants'
import { fetchUserById } from '@/services/user-service'

import useFeatureFlag from '@/compositions/useFeatureFlag'
import useCurrentUser from '@/compositions/useCurrentUser'
import useStore from '@/compositions/useStore'
import { persistOrderContractMatches } from '@/services/contract-matching-service'

import {
  CustomerTemplate,
  OrderDetail,
  OrderStop,
  OrderStopToCreate,
  VehicleType
} from '@/services'
import { ShipperContract } from '@/modules/ordering/types'
import { StopoverType } from '@/services/enums'
import { ExtendedPerson } from '@/compositions/useOrder'
import { fullName } from '@/modules/common/filters/person-filters'
import { REGULARITY_TYPE } from '@/modules/common/order-states'

const DEFAULT_TEMPLATE_VALUES: Partial<
  Record<typeof ORDER_EDITABLE_FIELDS[number], unknown>
> = {
  basePrice: null,
  allowedVehicleTypes: null,
  loadQuantity: null,
  loadUnitType: null,
  loadWeight: null,
  loadLength: null,
  loadHeight: null,
  loadWidth: null,
  loadMinimumTemperature: null,
  loadMaximumTemperature: null,
  sealable: false,
  codeXl: false,
  needsPalletExchange: false,
  arrivalNotification: false,
  directTransfer: false,
  dockLoading: false,
  sideLoading: false,
  topLoading: false,
  isTemperatureControlled: false,
  loadDescription: null
}

const ORDER_EDITABLE_FIELDS = [
  'allowedVehicleTypes',
  'vehicleType',
  'loadQuantity',
  'loadUnitType',
  'loadWeight',
  'loadLength',
  'loadHeight',
  'loadWidth',
  'loadMinimumTemperature',
  'loadMaximumTemperature',
  'loadDescription',

  'sealable',
  'codeXl',
  'arrivalNotification',
  'directTransfer',
  'dockLoading',
  'sideLoading',
  'topLoading',
  'isTemperatureControlled',

  'basePrice',
  'preSaleCustomerExtraChargeId',
  'preSaleCustomerExtraChargeAmount',
  'preSaleCustomerExtraChargeDescription',
  'lineCode',
  'regularity',
  'referenceNumber',
  'needsPalletExchange'
] as const

export default defineComponent({
  components: {
    OrderStopsForm,
    LoadSection,
    OldPriceSection,
    OrderStageFooter,
    OrderTemplateDropdownField,
    AsyncActionButton,
    AsyncActionContext
  },
  props: {
    order: { type: Object as PropType<OrderDetail>, required: true },
    stops: { type: Array as PropType<OrderStop[]>, required: true },
    accountManager: { type: Object as PropType<ExtendedPerson>, default: null },
    juniorAccountManager: { type: Object as PropType<ExtendedPerson>, default: null },
    partnerManager: { type: Object as PropType<ExtendedPerson>, default: null },
    spotBidder: { type: Object as PropType<ExtendedPerson>, default: null }
  },
  setup(props, { emit }) {
    const routesForm = ref(null)
    const loadForm = ref(null)
    const priceForm = ref(null)
    const loadSection = ref(null)
    const displaySpotBidderErrorMandatory = ref(false)
    const store = useStore()

    const orderContainsShipment = store.getters['ordering/orderContainsShipment']

    const { isActive } = useFeatureFlag()

    const isEnabledPartnerManager = isActive(
      'ENABLE_ONE-VIEW-CM-TEAM-LEAD-ASSIGNMENT-PRE-CARRIER-MATCH'
    )

    const { currentUserId, currentUserIsAccountManager, currentUserIsDirectDispatcher } =
      useCurrentUser()

    const currentOrder = reactive(pick(props.order, ORDER_EDITABLE_FIELDS))

    const preferredVehicleType = ref<VehicleType>(null)

    watch(
      () => props.order,
      (newValue, oldValue) => {
        const fields = pick(newValue, ORDER_EDITABLE_FIELDS)
        Object.entries(fields).forEach(([name, value]) => {
          currentOrder[name] = value
        })

        if (!currentOrder.isTemperatureControlled) {
          currentOrder.loadMinimumTemperature = null
          currentOrder.loadMaximumTemperature = null
        }

        if (
          (!oldValue.allowedVehicleTypes || !oldValue.allowedVehicleTypes.length) &&
          newValue.allowedVehicleTypes?.length === 1
        ) {
          preferredVehicleType.value = newValue.allowedVehicleTypes[0]
        }

        emit('update-order-regularity', newValue.regularity)
      }
    )

    watch(
      () => currentOrder.isTemperatureControlled,
      newValue => {
        if (!newValue) {
          currentOrder.loadMinimumTemperature = null
          currentOrder.loadMaximumTemperature = null
        }
      }
    )

    watch(
      () => currentOrder.allowedVehicleTypes,
      newValue => {
        if (newValue?.length === 1) {
          preferredVehicleType.value = newValue[0]
        }
      },
      { immediate: true }
    )

    watch(
      () => currentOrder.regularity,
      newValue => {
        emit('update-order-regularity', newValue)
      }
    )

    const accountManagerData = ref<ExtendedPerson>(cloneDeep(props.accountManager))

    watch(
      () => props.accountManager,
      newValue => {
        accountManagerData.value = cloneDeep(newValue)
      }
    )

    const juniorAccountManagerData = ref<ExtendedPerson>(
      cloneDeep(props.juniorAccountManager)
    )

    watch(
      () => props.juniorAccountManager,
      newValue => {
        juniorAccountManagerData.value = cloneDeep(newValue)
      }
    )

    const partnerManagerData = ref<ExtendedPerson>(cloneDeep(props.partnerManager))

    watch(
      () => props.partnerManager,
      newValue => {
        partnerManagerData.value = cloneDeep(newValue)
      }
    )

    const spotBidderData = ref<ExtendedPerson>(cloneDeep(props.spotBidder))

    watch(
      () => props.spotBidder,
      newValue => {
        displaySpotBidderErrorMandatory.value = false
        spotBidderData.value = cloneDeep(newValue)
      }
    )

    const lineCode = ref<string | null>(props.order.lineCode || null)
    const matchedContractId = ref(null)

    watch(lineCode, (newValue, oldValue) => {
      if (newValue !== oldValue) onLineCodeChange(newValue)
    })

    const userChangedTemplate = ref<boolean>(false)

    const isLinkedWithContract = ref<boolean>(false)

    /**
     * This flag shows that the order is in the NEW state temporary and
     * waiting until Workflow finishes its registration.
     */
    const isAwaitingRegistration = computed<boolean>(
      () => props.order.state === 'NEW' && orderContainsShipment
    )

    const disabled = computed<boolean>(
      () => props.order.state !== 'NEW' || isAwaitingRegistration.value
    )

    const isOrderCreatedFromTemplate = computed<boolean>(() => {
      if (lineCode.value && !isLinkedWithContract.value) {
        return true
      }
      return false
    })

    const netPrice = computed<number>(
      () => currentOrder.basePrice + currentOrder.preSaleCustomerExtraChargeAmount
    )

    const isTemplateSelectionDisabled = computed<boolean>(() => {
      if (disabled.value) return true

      return !currentUserIsAccountManager && !currentUserIsDirectDispatcher
    })

    const stopAddressIds = computed<number[]>(() =>
      stopsData.value.map(stop => stop.warehouseAddress?.id)
    )

    const createEmptyStop = (
      stopoverType: StopoverType = null,
      payload: Partial<OrderStop> = null
    ): OrderStopToCreate => ({
      id: null,
      localId: uniqueId('local-'),
      orderId: props.order.id,
      stopoverType,
      customerCompany: null,
      warehouseAddress: null,
      warehouseAddressId: null,
      referenceNumber: props.order.referenceNumber,
      startDate: null,
      endDate: null,
      startTime: '',
      endTime: '',
      isOvernightLoad: false,
      contactPerson: null,
      notes: null,
      ...payload
    })

    const createDefaultStops = (): OrderStopToCreate[] => [
      createEmptyStop(StopoverType.LOADING),
      createEmptyStop(StopoverType.UNLOADING)
    ]

    const stopsData = ref<(OrderStop | OrderStopToCreate)[]>(
      props.stops.length ? cloneDeep(props.stops) : createDefaultStops()
    )

    watch(
      () => props.stops,
      newValue => {
        stopsData.value = newValue.length ? cloneDeep(newValue) : createDefaultStops()
      }
    )

    const addNewStop = (): void => {
      const insertionIndex = stopsData.value.length - 1
      stopsData.value.splice(insertionIndex, 0, createEmptyStop())
    }

    const removeStop = async ({
      stop,
      index
    }: {
      stop: OrderStop
      index: number
    }): Promise<void> => {
      if (stop.id) {
        await saveOrder()
        await store.dispatch('ordering/deleteStop', stop)
      } else {
        stopsData.value.splice(index, 1)
      }
    }

    const processPalletExchange = async (): Promise<void> => {
      // needs_pallet_exchange field is not directly settable on the Mothership side
      // so we need to call updatePalletExchangeConfiguration to make it visible in Orcas
      await updatePalletExchangeConfiguration(
        props.order.id,
        currentOrder.needsPalletExchange
          ? PALLET_EXCHANGE_TYPES.REGULAR_PALLET_EXCHANGE
          : PALLET_EXCHANGE_TYPES.NOT_REQUIRED
      )
    }

    const hasFormErrors = (): boolean => {
      if (currentOrder.regularity === REGULARITY_TYPE.SCALED_SPOT) {
        displaySpotBidderErrorMandatory.value = !spotBidderData.value
      }

      const formRefs = [routesForm, loadForm, priceForm]
      return (
        formRefs.reduce(
          (result, formRef) => formRef.value.submit().hasErrors || result,
          false
        ) || displaySpotBidderErrorMandatory.value
      )
    }

    const scrollToFirstError = (): void =>
      nextTick(() => {
        const formRefs = [routesForm, loadForm, priceForm]
        const errorEl = formRefs.reduce(
          (result, formRef) => result || formRef.value.$el.querySelector('.form__errors'),
          null
        )
        if (errorEl?.scrollIntoView) {
          errorEl.scrollIntoView({ behavior: 'smooth' })
        }
      })

    const validateForm = (): boolean => {
      if (hasFormErrors()) {
        scrollToFirstError()
        return false
      }
      return true
    }

    const formUpdates = computed(() => {
      const order = cloneDeep(currentOrder)
      if (order.allowedVehicleTypes?.length) {
        // This is for backwards compatibility, this will soon be stripped out
        order.vehicleType = order.allowedVehicleTypes[0]
      }

      return {
        order: {
          ...order,
          accountManagerId: get(accountManagerData.value, 'id', null)
        },
        stops: stopsData.value
      }
    })

    const saveOrder = async (): Promise<void> => {
      await processPalletExchange()
      await store.dispatch('ordering/updateOrder', {
        ...formUpdates.value,
        orderReset: userChangedTemplate.value
      })
      userChangedTemplate.value = false
      if (isLinkedWithContract.value) {
        await persistOrderContractMatches(
          props.order.id,
          matchedContractId.value,
          isLinkedWithContract.value,
          currentUserId.value
        )
      }
    }

    const confirmOrder = async (): Promise<void> => {
      await processPalletExchange()
      await store.dispatch('ordering/registerOrder', {
        ...formUpdates.value,
        orderReset: userChangedTemplate.value
      })
      if (isLinkedWithContract.value) {
        await persistOrderContractMatches(
          props.order.id,
          matchedContractId.value,
          isLinkedWithContract.value,
          currentUserId.value
        )
      }
    }

    const removeTemplate = (index: number): void => {
      if (index == 0 || index + 1 == stopsData.value.length) {
        lineCode.value = null
      }
    }

    const onTemplateChange = (selectedTemplate: { template: CustomerTemplate }): void => {
      lineCode.value = selectedTemplate?.template?.lineCode
    }

    const onLineCodeChange = async (lineCode: string): Promise<void> => {
      if (!lineCode || isLinkedWithContract.value) {
        return
      }
      const orderTemplate = await fetchTemplate(lineCode)

      if (orderTemplate) {
        Object.keys(DEFAULT_TEMPLATE_VALUES).forEach(key => {
          //the orderTemplate field allowedVehicleTypes in django only accept only value so we need to transform it into an array
          if (key === 'allowedVehicleTypes') {
            currentOrder.allowedVehicleTypes = [orderTemplate.vehicleType]
          } else {
            currentOrder[key] = orderTemplate[key]
          }
        })

        stopsData.value = [
          createEmptyStop(StopoverType.LOADING, {
            customerCompany: {
              id: orderTemplate.originCompanyId,
              name: orderTemplate.originCompany
            },
            notes: orderTemplate.loadingNotes,
            customerCompanyId: orderTemplate.originCompanyId,
            warehouseAddress: {
              id: null,
              fullAddress: orderTemplate.originCompanyAddress,
              companyAddressId: orderTemplate.originCompanyAddressId,
              zipCode: null,
              timezone: null
            },
            warehouseAddressId: orderTemplate.originCompanyAddressId,
            referenceNumber: orderTemplate.loadingReference,
            contactPerson: orderTemplate.originContactId
              ? {
                  id: orderTemplate.originContactId,
                  name: orderTemplate.originContact
                }
              : null
          }),
          createEmptyStop(StopoverType.UNLOADING, {
            customerCompany: {
              id: orderTemplate.destinationCompanyId,
              name: orderTemplate.destinationCompany
            },
            notes: orderTemplate.unloadingNotes,
            customerCompanyId: orderTemplate.destinationCompanyId,
            warehouseAddress: {
              id: null,
              fullAddress: orderTemplate.destinationCompanyAddress,
              companyAddressId: orderTemplate.destinationCompanyAddressId,
              zipCode: null,
              timezone: null
            },
            warehouseAddressId: orderTemplate.destinationCompanyAddressId,
            referenceNumber: orderTemplate.unloadingReference,
            contactPerson: orderTemplate.destinationContactId
              ? {
                  id: orderTemplate.destinationContactId,
                  name: orderTemplate.destinationContact
                }
              : null
          })
        ]
        userChangedTemplate.value = true
      }
    }

    const onToggleContractLink = async (
      value: boolean,
      matchedContract: ShipperContract,
      triggeredFromUser: boolean
    ): Promise<void> => {
      isLinkedWithContract.value = value
      if (matchedContract) {
        currentOrder.regularity = !isLinkedWithContract.value
          ? 'SPOT'
          : matchedContract.type
      }
      if (triggeredFromUser && !isLinkedWithContract.value) {
        await persistOrderContractMatches(
          props.order.id,
          matchedContractId.value,
          isLinkedWithContract.value,
          currentUserId.value
        )
      }
    }

    const onContractMatch = async (contract: ShipperContract): Promise<void> => {
      isLinkedWithContract.value = true
      currentOrder.allowedVehicleTypes = [
        contract.preferredVehicleType,
        ...contract.fallbackVehicleTypes
      ]
      lineCode.value = contract.lineCode
      matchedContractId.value = contract.id
      currentOrder.basePrice = contract.basePrice
      currentOrder.loadDescription = contract.loadDescription
      currentOrder.loadUnitType = contract.loadUnitType
      currentOrder.loadWeight = contract.loadWeight
      currentOrder.loadWidth = contract.loadWidth
      currentOrder.loadHeight = contract.loadHeight
      currentOrder.loadLength = contract.loadLength
      currentOrder.loadQuantity = contract.loadQuantity
      currentOrder.loadMinimumTemperature = contract.loadMinimumTemperature
      currentOrder.loadMaximumTemperature = contract.loadMaximumTemperature
      currentOrder.regularity = contract.type
      currentOrder.referenceNumber = contract.orderReferenceNumber

      const booleanFields = [
        'dockLoading',
        'directTransfer',
        'sealable',
        'topLoading',
        'sideLoading',
        'codeXl',
        'isTemperatureControlled',
        'arrivalNotification',
        'needsPalletExchange'
      ]

      booleanFields.forEach(key => {
        if (contract[key] !== null) {
          // avoids prop validation errors
          currentOrder[key] = contract[key] as boolean
        }
      })

      if (contract.manager?.id) {
        const accountManager = await fetchUserById(contract.manager?.id, true)

        await store.dispatch('ordering/assignAccountManagers', {
          accountManager: {
            ...accountManager,
            fullName: fullName(accountManager)
          }
        })
      }
    }

    return {
      routesForm,
      loadForm,
      priceForm,
      loadSection,
      currentOrder,
      stopsData,
      accountManagerData,
      juniorAccountManagerData,
      partnerManagerData,
      spotBidderData,
      lineCode,
      preferredVehicleType,
      currentUserIsAccountManager,
      isLinkedWithContract,
      isOrderCreatedFromTemplate,
      disabled,
      netPrice,
      isTemplateSelectionDisabled,
      stopAddressIds,
      addNewStop,
      removeStop,
      validateForm,
      saveOrder,
      confirmOrder,
      removeTemplate,
      onTemplateChange,
      onToggleContractLink,
      onContractMatch,
      displaySpotBidderErrorMandatory,
      isAwaitingRegistration,
      isEnabledPartnerManager
    }
  }
})









import Vue from 'vue'
import {
  createRandomString,
  getEnvironmentAliasForFederatedLoader
} from '@/microfrontends/utils'
import { Location, RawLocation, Route } from 'vue-router'

import {
  IUser,
  Tenant,
  SharedDataType,
  IMicrofrontendData,
  IOctopusSharedData
} from '@sennder/senn-node-microfrontend-interfaces'
import { OPERATIONS_BACKEND_URL } from '@/config'
import useCurrentUser from '@/compositions/useCurrentUser'
import useI18n from '@/compositions/useI18n'
import { getAppTheme } from '@/controllers/environment-detection'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import { getLogger } from '@/shell/datadog-logger'
import { getAuthHeader, getCommonHeaders, getPermissions } from '@/services/auth-service'
import { trackEvent } from '@/analytics/amplitude'
import { BIDDING_FEATURE_FLAGS } from '@/microfrontends/pages/bidding/constants'
import { monitorError } from '@/analytics/monitoring'
import { handleError } from '@/global-setup/global-error-handler'
import { AlertModal } from '@sennder/plankton'
import { fetchMicrofrontend } from '@/microfrontends/fetchMicrofrontend'

const NAMESPACE = 'octopusBiddingMfComponent'
const REMOTE = './octopusBiddingMicroFrontend'
const NPM_PATH = 'octopus-bidding-mf-component'
const DEV_PORT = 9113

let abortBiddingMicroFrontendController: AbortController | undefined

const MICROFRONTEND_NAME = 'BiddingMicrofrontend'
const TRACKING_PROPERTIES = {
  module: 'ordering',
  section: 'dispatching'
}

export default Vue.extend({
  name: 'BiddingMicroFrontend',
  props: {
    orderId: {
      type: Number,
      default: 0
    },
    transportOfferId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      id: `${createRandomString()}-BiddingMicroFrontend`,
      isLoaded: null as boolean | null,
      syncChildRouter: null as ((route: RawLocation) => void) | null,
      unmount: null as (() => void) | null
    }
  },
  beforeDestroy() {
    // TEMPORARY: Clean up all events in Microfrontend
    // Not needed when MF is able to call Destroy lifecycle hooks properly
    abortBiddingMicroFrontendController?.abort()
  },
  async mounted() {
    abortBiddingMicroFrontendController = new AbortController()
    const syncParentRouter = (route: RawLocation) => {
      const currentPath = this.$route.fullPath
      if (
        (typeof route === 'string' && currentPath === route) ||
        (typeof route === 'object' && currentPath === (route as Location).path)
      ) {
        return
      }
      this.$router.push(route)
    }
    try {
      const { user } = useCurrentUser()
      const { locale } = useI18n()
      const { isActive } = useFeatureFlag()

      const getBiddingFeatureFlags = () => {
        return {
          [BIDDING_FEATURE_FLAGS.SHOW_MANUAL_SUGGESTED_BASE_COST]: isActive(
            BIDDING_FEATURE_FLAGS.SHOW_MANUAL_SUGGESTED_BASE_COST
          ).value,
          [BIDDING_FEATURE_FLAGS.MAKE_COUNTEROFFER_MANDATORY]: isActive(
            BIDDING_FEATURE_FLAGS.MAKE_COUNTEROFFER_MANDATORY
          ).value,
          [BIDDING_FEATURE_FLAGS.IS_SUBMIT_OFFER_ENABLED_FOR_DOMESTIC_CARRIERS_PAID_IN_LOCAL_CURRENCY]:
            isActive(
              BIDDING_FEATURE_FLAGS.IS_SUBMIT_OFFER_ENABLED_FOR_DOMESTIC_CARRIERS_PAID_IN_LOCAL_CURRENCY
            ).value,
          [BIDDING_FEATURE_FLAGS.ENABLE_ORDER_DETAILS_TARGET_DEVIATION]: isActive(
            BIDDING_FEATURE_FLAGS.ENABLE_ORDER_DETAILS_TARGET_DEVIATION
          ).value,
          [BIDDING_FEATURE_FLAGS.ENABLE_RATING_WIDGET_FOR_BIDDING]: isActive(
            BIDDING_FEATURE_FLAGS.ENABLE_RATING_WIDGET_FOR_BIDDING
          ).value
        }
      }

      const mount = await fetchMicrofrontend({
        devPort: DEV_PORT,
        environment: getEnvironmentAliasForFederatedLoader(NAMESPACE),
        moduleFederationPluginName: NAMESPACE,
        npmName: NPM_PATH,
        mountExposedName: REMOTE,
        logger: getLogger()
      })

      let data: IOctopusSharedData = {
        type: SharedDataType.OCTOPUS,
        env: {
          OPERATIONS_BACKEND_URL
        },
        user: user.value as IUser,
        language: locale.value,
        tenant: getAppTheme() as Tenant,
        // unsubscribe listeners
        abortController: abortBiddingMicroFrontendController,
        // switch widget modes
        mode: this.mode,
        featureFlags: {
          ...getBiddingFeatureFlags()
        },
        props: {
          orderId: this.orderId,
          transportOfferId: this.transportOfferId,
          fromSingleView: false
        }
      }

      const fullSharedData: IMicrofrontendData<IOctopusSharedData> = {
        data,
        callbacks: {
          getAuthToken: () => Promise.resolve(null),
          // we only use this getAuthHeader in bidding µFE for now
          getAuthHeader,
          getCommonHeaders,
          getPermissions,
          syncParentRouter,
          // segment is deprecated, use Amplitude provider instead
          segmentTrackEvent: null,
          onUnauthorized: () => {},
          onUnhandledError: error => {
            Vue.config.errorHandler(error, null, MICROFRONTEND_NAME)
          }
        },
        providers: {
          logger: getLogger(),
          translations: null,
          analytics: null,
          monitoring: null,
          notifications: {
            error: (...args) => {
              // log it to DD
              getLogger().error(...args, {})

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const error: any = {
                response: {
                  status: 403,
                  data: `<b style="font-size: 20px;">${MICROFRONTEND_NAME}</b> </br></br> ${args[0]}`
                }
              }
              // Hack to show an alert model in octopus
              handleError({ alertModal: AlertModal(Vue.swal), error, router: null })
            },
            success: () => {}
          },
          // segment is deprecated, use Amplitude provider instead
          segment: {
            trackEvent: (name, props) => {
              trackEvent(name, {
                ...TRACKING_PROPERTIES,
                ...props
              })
            },
            setContext: () => {
              getLogger().error('setContext not implemented', {})
            },
            trackPage: () => {
              getLogger().error('trackPage not implemented', {})
            }
          }
        }
      }

      const mfData = mount(`#${this.id}`, fullSharedData)

      if (mfData.syncChildRouter) {
        this.syncChildRouter = mfData.syncChildRouter
        this.$watch(
          '$route',
          (to: Route) => {
            mfData.syncChildRouter(to.fullPath)
          },
          {
            immediate: true
          }
        )
      }
      if (mfData.unmount) {
        this.unmount = mfData.unmount
      }

      // TODO: Not working need to check:
      // Impact Feature Flags won't be reactive inside Bidding µFE and other things which we get from shared data
      if (mfData.onSharedDataChanged) {
        this.$watch(
          () => data,
          data => {
            mfData.onSharedDataChanged({
              ...data,
              featureFlags: {
                ...getBiddingFeatureFlags()
              }
            })
          },
          { deep: true }
        )
      }

      this.isLoaded = true
    } catch (e) {
      getLogger().error(`${MICROFRONTEND_NAME} MF bootstrap error`, e)
      monitorError(e, MICROFRONTEND_NAME)
      this.isLoaded = false
    }
  },
  destroyed() {
    if (this.unmount) {
      this.unmount()
    }
  }
})

import { CARRIER_PROFILE_API_URL } from '@/config'
import { runJsonGet } from '@/models/backend-client'
export const fetchCarrierById = async carrierId => {
  const url = `${CARRIER_PROFILE_API_URL}/v1/carriers/${carrierId}`
  const response = await runJsonGet(
    url,
    null,
    { apiGatewayAuthorization: true, arrayFormat: 'repeat' },
    `https://api.cloud.sennder.com/carrier-profile`,
    'carriers:read'
  )

  const data = response.data

  if (!data) return null
  return data
}

import { IMonitoringProvider } from '@sennder/senn-node-microfrontend-interfaces'

const monitoringDefaultProvider: IMonitoringProvider = {
  sendError: function (): void {
    // eslint-disable-next-line no-console
    console.error('[monitoring] sendError function is not implemented.')
  }
}

export let monitoring = monitoringDefaultProvider

export const setMonitoring = (monitoringProvider: IMonitoringProvider) => {
  monitoring = monitoringProvider
}

import get from 'lodash/get'
import isNil from 'lodash/isNil'
import find from 'lodash/find'
import sumBy from 'lodash/sumBy'
import {
  computed,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  Ref,
  toRef,
  watch
} from '@vue/composition-api'
import { LOAD_TYPES, VEHICLE_TYPES } from '@sennder/octopus-constants'
import { useSteps } from '@/modules/ordering/compositions/steps'
import { useCollapsedSidebar } from '@/modules/ordering/compositions/useCollapsedSidebar'
import useTransfer from '@/modules/ordering/compositions/useTransfer'
import { getOrderRouteDetails } from '@/services/ordering-service'
import { googleMapUrl } from '@/modules/common/googlemap-stops-helpers'
import { hasHadState } from '@/modules/common/order-states'
import useMultipleLetters from '@/compositions/transfer/useMultipleLetters'
import { placeholderIfEmpty } from '@/global-setup/filters'
import { OrderDetail, OrderDistanceTransitTime, OrderStop } from '@/services'
import useStore from '@/compositions/useStore'
import {
  DispatchingTransferDetail,
  TrackingConfig
} from '../components/dispatching/types'
import {
  MicrofrontendEvents,
  onTransferStepsActualTimesUpdated,
  subscribe
} from '@sennder/senn-node-microfrontend-event-bus'

interface FormattedTracker {
  label: string
  trackerIds: string[]
}

export default (order: Ref<OrderDetail>, stops: Ref<OrderStop[]>) => {
  const store = useStore()
  const { steps, loadSteps } = useSteps()
  const { assignedTrackers, loadTransfer } = useTransfer(order.value.transferId)
  const { isCollapsed, toggleIsCollapsed } = useCollapsedSidebar()
  const { multipleStopLetters } = useMultipleLetters(
    toRef({ order: order.value, stops: stops.value }, 'stops')
  )

  // reactive variables
  const stopsRouteDetails = ref<OrderDistanceTransitTime[]>([])

  // methods
  const getRoundedTime = transitTimeToNext => {
    if (transitTimeToNext) {
      const hours = Math.floor(transitTimeToNext / 3600)
      const minutes = Math.floor((transitTimeToNext % 3600) / 60)
      return `${hours}h ${minutes}min`
    } else {
      return null
    }
  }
  const getRoundedDistance = distanceToNext => {
    let roundedDistance
    if (distanceToNext) {
      roundedDistance = Math.floor(distanceToNext / 1000)
    } else if (distanceToNext === 0) {
      roundedDistance = 0
    } else {
      roundedDistance = null
    }
    return roundedDistance
  }

  const fetchRouteDetails = async () => {
    stopsRouteDetails.value = await getOrderRouteDetails(order.value.id)
  }

  let distanceIntervalId: ReturnType<typeof setInterval>
  const getDistanceInfo = async () => {
    // Time and distance is calculated asynchronously using 3rd party service
    // therefore might be not available after first call.
    // Agreed try to request this info 3 times
    let callsCount = 3
    stopsRouteDetails.value = []
    await fetchRouteDetails()
    distanceIntervalId = setInterval(async () => {
      callsCount--
      if (callsCount === 0 || stopsRouteDetails.value.length > 0)
        clearInterval(distanceIntervalId)
      else await fetchRouteDetails()
    }, 2000)
  }

  const getVehicleLabel = (value: string) => {
    return VEHICLE_TYPES[value]
  }

  // computed
  const trackingConfig = computed<TrackingConfig>(
    () => store.state.ordering.trackingConfig
  )
  const transfer = computed<DispatchingTransferDetail>(
    () => store.state.ordering.transfer
  )

  const joinSpecificationValues = (
    values: (number | string)[],
    separator = 'x'
  ): string => {
    return values.filter(v => !isNil(v)).join(` ${separator} `)
  }

  const loadTypeLabel = computed<string | null>(() => {
    if (!order.value.loadUnitType) return null
    const type = find(LOAD_TYPES, { value: order.value.loadUnitType })
    return type?.label as string
  })

  const loadQuantityAndType = computed<string>(() =>
    joinSpecificationValues([
      order.value.loadQuantity as number,
      loadTypeLabel.value as string
    ])
  )

  const loadDimensions = computed<string>(() =>
    joinSpecificationValues([
      order.value.loadLength,
      order.value.loadHeight,
      order.value.loadWidth
    ])
  )

  const loadTemperatures = computed<string | null>(() => {
    if (!order.value.isTemperatureControlled) return null
    return joinSpecificationValues(
      [order.value.loadMinimumTemperature, order.value.loadMaximumTemperature],
      'to'
    )
  })

  const sortTrackersByVehicleLicense = (trackersArray: string[]): string[] =>
    trackersArray.sort((a, b) => {
      if (a.includes(order.value.licensePlate)) return -1
      if (b.includes(order.value.licensePlate)) return 1
      return 0
    })

  const formattedTrackerInfo = computed<FormattedTracker[] | null>(() => {
    if (assignedTrackers.value.length === 0) return null

    const providers = [
      { label: 'CO3', matcher: (str: string) => str.startsWith('CO3') },
      {
        label: 'project44',
        matcher: (str: string) =>
          str.startsWith('Gatehouse') || str.toLowerCase().startsWith('p44')
      },
      {
        label: 'sennder Driver App',
        matcher: (str: string) =>
          !str.startsWith('CO3') &&
          !(str.startsWith('Gatehouse') || str.toLowerCase().startsWith('p44'))
      }
    ]

    const mappedTrackersByProviders = providers.map(provider => {
      return {
        label: provider.label,
        trackerIds: sortTrackersByVehicleLicense(
          assignedTrackers.value.filter(tracker => provider.matcher(tracker))
        )
      }
    })

    const providersWithTrackers = mappedTrackersByProviders.filter(
      provider => provider.trackerIds.length > 0
    )
    return providersWithTrackers
  })

  const isTotalValid = computed<boolean>(
    () =>
      !!(
        stopsRouteDetails.value?.length &&
        stopsRouteDetails.value[0].distanceToNext !== null
      )
  )

  const totalDist = computed<string>(() =>
    isTotalValid.value
      ? `${getRoundedDistance(sumBy(stopsRouteDetails.value, 'distanceToNext'))}`
      : 'N/A'
  )

  const totalTime = computed<string>(() =>
    isTotalValid.value
      ? getRoundedTime(sumBy(stopsRouteDetails.value, 'transitTimeToNext'))
      : 'N/A'
  )

  const vehicleTypes = computed<string | null>(() => {
    const { allowedVehicleTypes } = order.value
    if (!allowedVehicleTypes) return
    return allowedVehicleTypes.map(getVehicleLabel).join(', ')
  })

  const carrierVehicleLabel = computed<string>(
    () => VEHICLE_TYPES[transfer.value.dispatchedVehicle]
  )

  const isOrderDispatched = computed<boolean>(() =>
    hasHadState(store.state.ordering.order.state, 'DISPATCHED')
  )

  const isCarrierLocked = computed<boolean>(() =>
    hasHadState(store.state.ordering.order.state, 'CARRIER_LOCKED')
  )

  const hasStepsLoaded = computed<boolean>(
    () => steps.value.length === stops.value.length * 2
  )

  const sharingTrackingOptions = computed<string>(() => {
    const enabledOptions = []
    if (trackingConfig.value.gatehousePushOn) enabledOptions.push('Gatehouse')
    return enabledOptions.join(' | ')
  })

  const eventsAbortController = new AbortController()

  // hooks
  onMounted(() => {
    subscribe<onTransferStepsActualTimesUpdated>(
      MicrofrontendEvents.onTransferStepsActualTimesUpdated,
      async () => {
        await loadSteps(order.value.transferId)
      },
      { signal: eventsAbortController.signal }
    )
  })

  onUnmounted(() => {
    eventsAbortController.abort()
  })

  watch(
    () => order.value,
    () => {
      loadSteps(order.value.transferId)
      loadTransfer()
    },
    { immediate: true }
  )

  let getDistanceCalledAtLeastOneTime = false
  watch(steps, (value, oldValue) => {
    // simple equality check. Since we don't have thousands of stops,
    // the performance of JSON.stringify shouldn't be an issue

    if (
      JSON.stringify(value) === JSON.stringify(oldValue) &&
      getDistanceCalledAtLeastOneTime
    )
      return
    else {
      getDistanceCalledAtLeastOneTime = true
      getDistanceInfo()
    }
  })

  onBeforeUnmount(() => {
    clearInterval(distanceIntervalId)
  })

  return {
    // variables
    stopsRouteDetails,

    // hooks data
    steps,
    multipleStopLetters,
    isCollapsed,
    toggleIsCollapsed,

    // computed
    isTotalValid,
    totalDist,
    totalTime,
    loadQuantityAndType,
    loadDimensions,
    loadTemperatures,
    formattedTrackerInfo,
    vehicleTypes,
    carrierVehicleLabel,
    isOrderDispatched,
    isCarrierLocked,
    hasStepsLoaded,
    sharingTrackingOptions,
    trackingConfig,
    transfer,

    // filters
    placeholderIfEmpty,

    // methods
    get,
    getRoundedTime,
    getDistanceInfo,
    getRoundedDistance,
    getVehicleLabel,
    googleMapUrl
  }
}

<template>
  <body>
    <section
      v-if="isLoggedIn"
      :class="{
        'filters-open': !filtersCollapsed,
        'sidebar-open': sidebarIsVisible
      }"
      class="page-container"
      data-test="dashboard-section"
    >
      <NavBar></NavBar>
      <div id="current-page" class="page-container__content">
        <MicrofrontendPortals />
        <router-view></router-view>
      </div>
    </section>
  </body>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import NavBar from './NavBar'
import { sidebarIsVisible } from '@/modules/order-activity-sidebar'
import useCurrentUser from '@/compositions/useCurrentUser'
import MicrofrontendPortals from './MicrofrontendPortals.vue'

export default {
  components: {
    NavBar,
    MicrofrontendPortals
  },
  setup() {
    const { currentUserId } = useCurrentUser()

    return {
      sidebarIsVisible,
      currentUserId
    }
  },
  computed: {
    ...mapState(['filtersCollapsed']),
    ...mapGetters(['isLoggedIn'])
  }
}
</script>

<style scoped lang="scss">
@import 'src/styles/partials/variables';

.scroll-disabled {
  overflow: hidden;
}

.page-container {
  width: 100%;

  .navigation-menu {
    z-index: 15;
  }

  .page-container__content {
    background: rgb(var(--dsf-color-neutral-100));
    min-height: 100vh;
    transition-duration: 0.2s, 0.2s, 0.35s, 0.35s;
    transition-property: top, bottom, width, margin;
    transition-timing-function: linear, linear, ease, ease;
    width: calc(100% - #{$octopus-menu-width});
    float: right;

    .header {
      position: fixed;
      width: calc(100% - #{$octopus-menu-width});
      top: 0;
      right: 0;
      z-index: 5;
    }

    .footer {
      position: fixed;
      width: calc(100% - #{$octopus-menu-width});
      bottom: 0;
      right: 0;
      z-index: 5;
    }
  }
}

.page-container.filters-open {
  .page-container__content {
    width: calc(100% - #{$octopus-menu-width + $filters-width});
    margin-right: $filters-width;

    .header,
    .footer {
      position: fixed;
      width: calc(100% - #{$octopus-menu-width + $filters-width});
      right: $filters-width;
    }
  }
}

.page-container.filters-open {
  .page-container__content {
    width: calc(100% - #{$octopus-menu-width + $filters-width});
    margin-right: $filters-width;

    .header,
    .footer {
      position: fixed;
      width: calc(100% - #{$octopus-menu-width + $filters-width});
      right: $filters-width;
    }
  }
}

.page-container.sidebar-open {
  > .page-container__content {
    width: calc(100% - #{$octopus-menu-width + $sidebar-width});
    margin-right: $sidebar-width;

    .header,
    .footer {
      position: fixed;
      width: calc(100% - #{$octopus-menu-width + $sidebar-width});
      right: $sidebar-width;
    }
  }
}

.page-container.sidebar-open {
  > .page-container__content {
    width: calc(100% - #{$octopus-menu-width + $sidebar-width});
    margin-right: $sidebar-width;

    .header,
    .footer {
      position: fixed;
      width: calc(100% - #{$octopus-menu-width + $sidebar-width});
      right: $sidebar-width;
    }
  }
}

@media (max-width: 992px) {
  .page-container {
    .sidebar {
      transform: translateX(#{$filters-mobile-width-offset});
    }

    > .page-container__content {
      width: 100% !important;
      padding-left: $octopus-menu-width;
      float: none;

      .header,
      .footer {
        width: 100% !important;
        right: 0 !important;
      }
    }
  }
}

.notifications {
  position: fixed;
  z-index: 10;
  top: 28px;
  right: 28px;
}

.overlay {
  position: fixed;
  z-index: 13;
  top: 0;
  bottom: 0;
  left: $octopus-menu-width;
  right: 0;
  background-color: rgba($color-layout-black, 0.5);
}

.notification-center {
  position: fixed;
  z-index: 14;
  top: 0;
  bottom: 0;
  left: $octopus-menu-width;
  box-shadow: 0 2px 8px 0 transparentize($color-neutral-darkest, 0.85);
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform ease-out 0.25s;
}

.slide-left-enter,
.slide-left-leave-to {
  transform: translateX(-100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity ease-out 0.25s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
